import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { Card, CardBody, CardSubtitle, CardTitle, Col, Form, Input, Label, Row } from "reactstrap"
import PropTypes from "prop-types"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient } from "../../Authentication/ApolloClient"
import {
  GET_APPLICANT_CONTROL_NUMBER,
  GET_APPLICANT_DETAILS,
  GET_APPLICATION_LEVELS,
  GET_APPLICATION_METHOD_BY_LEVEL
} from "../Queries/ApplicantQueries"
import { useFormik } from "formik"
import * as Yup from "yup"
import showToast from "../../../helpers/ToastHelper"
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"
import { UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD } from "../Mutations/ApplicantMutation"
import { LoaderPage } from "../../../helpers/LoaderPage"
import { Link, useNavigate } from "react-router-dom"
import { showWaringConf } from "../../../helpers/DeleteWaringHelper"
import { GET_APPLICANT_NEXT_OF_KINS } from "../../OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries"

const breadcrumbItem = [
  {
    title: "Update Entry Qualification",
    path: "",
    isActive: true
  }
]
const UpdateQualifications = () => {
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data

  const navigate = useNavigate()
  const [appLevel, setAppLevel] = useState("")
  const [appMethod, setAppMethod] = useState("")
  const { loading: applicantLoading, error: applicantError, data: applicantData } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    variables: {
      userUid: ExtractUserAuth?.uid
    },
    fetchPolicy: "network-only"
  })
  const applicant = applicantData?.getApplicantDetails.data

  // console.log(applicant)
  const { loading, error, data } = useQuery(GET_APPLICATION_LEVELS, {
    client: onlineApplicationGraphQLClient,
    fetchPolicy: "no-cache"
  })

  const [update] = useMutation(UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD)
  const levels = data?.getApplicationLevel?.data
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      qualificationMethodUid: applicant?.qualificationMethodUid,
      applicationLevelUid: applicant?.applicationLevelUid,
      applicantUid: applicant?.uid
    },

    validationSchema: Yup.object().shape({
      applicationLevelUid: Yup.string().required("Qualification Level Is Required"),
      qualificationMethodUid: Yup.string().required("Qualification Method Is Required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      update({
        client: onlineApplicationGraphQLClient,
        variables: {
          inputs: { ...values }
        },
        onCompleted: ({ updateApplicantLevelAndQualificationMethod }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (updateApplicantLevelAndQualificationMethod.code === 8000) {
            setSubmitting(false)
            validation.resetForm()
            showWaringConf(() => {
                navigate("/applicant/payment")
              }, `Are you applying for the ${appLevel} Level application Control Number 
                                        using your ${appMethod} certificate?`, `If Yes, Click Proceed to apply`, "warning", "Proceed",
              () => {
                // setPayType(null)
              })


          } else {
            setSubmitting(false)
          }
          showToast(
            updateApplicantLevelAndQualificationMethod.message,
            updateApplicantLevelAndQualificationMethod.code === 8000 ? "success" : "error"
          )
        },
        refetchQueries: [
          {
            variables: {
              userUid: ExtractUserAuth?.uid
            },
            query: GET_APPLICANT_DETAILS
          }
        ],
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        }
      })
    }
  })

  const { loading: kinLoading, error: kinError, data: kinData } = useQuery(GET_APPLICANT_NEXT_OF_KINS, {
    client: onlineApplicationGraphQLClient,
    skip: !applicant?.uid,
    variables: {
      applicantUid: applicant?.uid
    },
    fetchPolicy: "network-only"
  })

  const [
    searchApplicationMethod,
    { loading: methodLoading, error: searchError, data: methodData }
  ] = useLazyQuery(GET_APPLICATION_METHOD_BY_LEVEL, { client: onlineApplicationGraphQLClient })

  const getLevelMethod = (levelUid) => {
    searchApplicationMethod({
      variables: { levelUid: levelUid },
      fetchPolicy: "network-only"
    })
  }
  let methods = methodData?.getApplicationMethodByLevel?.data
  const {
    levelMethodLoading,
    error: levelMethodError,
    data: levelMethodData
  } = useQuery(GET_APPLICATION_METHOD_BY_LEVEL, {
    client: onlineApplicationGraphQLClient,
    fetchPolicy: "network-only",
    skip: !applicant?.qualificationMethodUid,
    variables: { levelUid: applicant?.applicationLevelUid }
  })
  const {
    loading: controlNumberLoading,
    error: controlNumberError,
    data: controlNumberData
  } = useQuery(GET_APPLICANT_CONTROL_NUMBER, {
    client: onlineApplicationGraphQLClient,
    skip: !applicant?.uid,
    variables: {
      applicantUid: applicant?.uid
    },
    nextFetchPolicy: "network-only"
  })
  const kinResults = kinData?.getApplicantNextOfKins.data
  if (applicantLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                           description={"Fetching Applicant Details"} />
  if (loading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                  description={"Fetching Application Level Information"} />
  if (kinLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                     description={"Fetching Other Information's"} />
  if (levelMethodLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                             description={"Fetching Other Information's"} />
  if (!kinResults) {
    navigate("/applicant/profile-information")
  }

  if (!methods) {
    methods = levelMethodData?.getApplicationMethodByLevel?.data
  }
  const controlNumber = controlNumberData?.getApplicantControlNumber.data


  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={"Applicant"}>
      <Row>
        <Col>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <Card>
              <CardBody>
                <CardSubtitle>
                  <h4>Update Study level and qualification.</h4>
                </CardSubtitle>
                <CardBody>
                  <CardTitle>Please Select Level Of Study which you are applying for and qualification which you want to
                    use. </CardTitle>
                  {loading || methodLoading && <div className="text-center"><i
                    className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2" /></div>}
                  {
                    levels &&
                    <Row>
                      {levels?.map((level, index) => (
                        <Col key={level?.uid} xl="2" sm="4">
                          <div className="mb-3">
                            <Label className="card-radio-label mb-2">
                              <Input
                                type="radio"
                                name="applicationLevelUid"
                                // id="applicationLevelUid2"
                                className="card-radio-input"
                                disabled={!!controlNumber}

                                defaultChecked={applicant && applicant?.applicationLevelUid === level?.uid}
                                value={level?.uid}
                                onChange={(e) => {
                                  validation.setFieldValue("qualificationMethodUid", "")
                                  validation.setFieldValue("applicationLevelUid", e.target.value)
                                  setAppLevel(level?.name)
                                  getLevelMethod(e.target.value)
                                }}
                              />

                              <div className="card-radio">
                                <div>
                                  <i
                                    className={`mdi ${applicant && applicant?.applicationLevelUid === level?.uid ? "mdi-check-all" : "mdi-certificate"} font-size-24 text-primary align-middle me-2`} />{" "}
                                  <span>{level?.name}</span>
                                </div>
                              </div>
                            </Label>
                          </div>
                        </Col>

                      ))}
                    </Row>
                  }

                  {validation.values.applicationLevelUid &&
                    <Row>

                      <Col lg={6}>
                        <Label>Qualification Method</Label>
                        {
                          methodLoading ? (
                            <div className="text-center"><i
                              className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2" /></div>
                          ) : !methodLoading &&(
                            <>
                              <select
                                id="qualificationMethodUid"
                                name="qualificationMethodUid"
                                className="form-control"
                                disabled={!!controlNumber}
                                value={validation.values.qualificationMethodUid}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              >
                                <option value="" label="-- Select Qualification --" />
                                {methods?.map((method, index) => (
                                  <option onClick={() => setAppMethod(method?.name)} key={method?.uid}
                                          value={method?.uid}
                                          label={method?.name} />
                                ))}
                              </select>
                              {validation.touched.qualificationMethodUid && validation.errors.qualificationMethodUid ? (
                                <small className="text-danger"><b>{validation.errors.qualificationMethodUid}</b></small>
                              ) : null}
                            </>
                          )
                        }
                      </Col>
                      {(!controlNumber && !controlNumberLoading) ?
                        <Col className={"mt-4"} lg={3}>
                          <Label style={{ color: "white" }}>SEARCH</Label>
                          <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                        </Col> : <Col className={"mt-4"} lg={3}>
                          {applicant?.qualificationMethodUid &&
                            <div className="text-center mt-5">
                              <br />
                              <Link
                                to="/applicant/profile-information"
                                className="btn btn-primary btn-sm mx-2"
                              >
                                <i className="mdi mdi-arrow-left ms-1"></i> Previous
                              </Link>
                              <Link
                                to="/applicant/payment"
                                className="btn btn-primary btn-sm mx-2"
                              >
                                Proceed <i className="mdi mdi-arrow-right ms-1"></i>
                              </Link>
                            </div>

                          }
                        </Col>
                      }
                    </Row>
                  }


                </CardBody>
              </CardBody>
            </Card>
          </Form>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default UpdateQualifications