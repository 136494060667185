import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { GET_DISABILITIES } from "../Queries/DisabilityQueries"
import { DisabilityContext } from "./DissabiltyList"
import { CREATE_DISABILITY } from "../Mutations/DisabilityMutations"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const DisabilityModal = () => {
  const { updateDisabilityData, setUpdateDisabilityData } =
    useContext(DisabilityContext)
  const [Disability] = useMutation(CREATE_DISABILITY,{
    client:uaaGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_DISABILITIES
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateDisabilityData ? updateDisabilityData.name : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Disability name is required"),
    }),

    onSubmit: values => {
      Disability({
        client:uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateDisabilityData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_DISABILITIES,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerDisabilities }) => {
          //updateFunction(client.cache, { data: { registerDisabilities } });
          console.log("DS", registerDisabilities);
          if (registerDisabilities.code === 8000) {
            resetForm()
            setModalOpen(false)
            setUpdateDisabilityData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateDisabilityData(true) // Close the modal
          }
          showToast(
            registerDisabilities.message,
            registerDisabilities.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Disability
      </Button>
      <Modal
        isOpen={modalOpen || !!updateDisabilityData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateDisabilityData
              ? "Edit Disability"
              : "Add New Disability"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || updateDisabilityData?.name || ""
                }
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            {/* <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                name="desc"
                id="message-text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.desc}
                onInvalid={() => {
                  validation.touched.desc && validation.errors.desc
                    ? true
                    : false
                }}
              />
              {validation.touched.desc && validation.errors.desc ? (
                <FormFeedback type="invalid">
                  {validation.errors.desc}
                </FormFeedback>
              ) : null}
            </div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateDisabilityData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default DisabilityModal
