import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import { Provider } from "react-redux"

import store from "./store"
import { ApolloProvider } from "@apollo/client"
// import client from "Modules/Authentication/ApolloClient"
import {registrationGraphQLClient, uaaGraphQLClient} from "Modules/Authentication/ApolloClient"

const root = ReactDOM.createRoot(document.getElementById("root"));

const clients = [registrationGraphQLClient, uaaGraphQLClient];
root.render(
  <ApolloProvider client={clients}>
     <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
  </ApolloProvider>
);

serviceWorker.unregister()
