// export default RoomList;
import React, { createContext, useEffect, useState } from "react"
import {useLocation} from 'react-router-dom';
import { Row, Col, Card, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning, showWaringConf } from "helpers/DeleteWaringHelper"
import { GET_ALL_ROOMS, GET_BLOCK_ROOMS } from "../Queries/AccommodationQueries"
import { DELETE_ROOM, ACTIVATE_ROOM } from "../Mutations/AccommodationMutations"
import RoomModal from "./RoomModal"

import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {accomodationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"

export const RoomContext = createContext() //Create data to transfer to child modal

const RoomList = () => {

  // Try to read parent hostel passed in
  const { state } = useLocation();
  const parentBlock = state?.block

  const breadcrumbItem = [
    {
      title: parentBlock? parentBlock.name+" Rooms" : "Room List",
      path: "",
      isActive: true,
    },
  ]

  // const get_rooms_query = parentBlock? GET_BLOCK_ROOMS : GET_ALL_ROOMS

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedRoom, setSelectedRoom] = useState(null) //Get data for deleting
  const [updateRoom, setUpdateRoom] = useState(null) //Get data for editing

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = room => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [room.uid]: !prevState[room.uid],
    }))
  }

  const { loading, error, data, refetch } = useQuery(GET_BLOCK_ROOMS, {
    client:accomodationGraphQLClient,
    variables: {
      blockUid: parentBlock.uid
    },
    fetchPolicy: 'cache-first'
  })

  const [searchCountries,{ loading: searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_BLOCK_ROOMS,{
    client:accomodationGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {
      // console.log("DataReceived", data)

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected;
   // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteRoom] = useMutation(DELETE_ROOM, {
    client: accomodationGraphQLClient,
    variables: { uid: selectedRoom?.uid },
    refetchQueries: [
      {
        query: GET_BLOCK_ROOMS,
        variables: {
          blockUid: parentBlock.uid
        },
      },
    ],
  })

  const [activateRoom] = useMutation(ACTIVATE_ROOM, {
    client: accomodationGraphQLClient,
    variables: { uid: selectedRoom?.uid },
    refetchQueries: [
      {
        query: GET_BLOCK_ROOMS,
        variables: {
          blockUid: parentBlock.uid,
        },
      },
    ],
  })

  const getFloorName = (number) => {
    const words = [
      'ground', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth',
      'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'
    ];
  
    const tens = [
      '', '', 'twentieth', 'thirtieth', 'fortieth', 'fiftieth', 'sixtieth', 'seventieth', 'eightieth', 'ninetieth'
    ];
  
    let floor_name = ''

    if (number < 20) {
      floor_name = words[number];
    } else {
      const tensPlace = Math.floor(number / 10);
      const onesPlace = number % 10;
      floor_name = tens[tensPlace] + (onesPlace !== 0 ? '-' + words[onesPlace] : '');
    }

    return floor_name.charAt(0).toUpperCase() + floor_name.slice(1) + ' Floor'

  };

  const changeAvailability = (room) => {
    setSelectedRoom(room)
    const title = room?.isAvailable? 'Close Room' : 'Open Room'
    const text = 'Are you sure to ' + (room?.isAvailable? 'close' : 'open') + ' this room?'
    showWaringConf(async () => {
        try {
          const response = await activateRoom()
          showToast(response?.data?.activateRoom.message, response?.data?.activateRoom.code === 8000 ? "success" : 'error');
        } catch (error) {
          console.error("Error deleting a room:", error)
        }
      }, title, text,'warning', room?.isAvailable? 'Close' : 'Open',
      () => {
          // console.log("Canceled")
      }
    )
  }

  const handleDeleteRoom = (room) => {
    setSelectedRoom(room)
    showDeleteWarning(() => {
      performRoomDeletion()
    })
  }

  const performRoomDeletion = async () => {
    try {
      const response = await deleteRoom()
      showToast(response.data.removeRoom.message, response.data.removeRoom.code === 8000 ? "success" : 'error');
    } catch (error) {
      console.error("Error deleting room:", error)
    }
  }

  if (loading) {
    return (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
    )
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchCountries({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }

  const dataArrray = data?.getRoomsByBlock.data?.items
  const searchedData = searchData?.getRoomsByBlock?.data?.items
  const result = searchedData ? searchedData : dataArrray
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getRoomsByBlock.data.totalCount || searchData?.getRoomsByBlock?.data?.items || 0

  const HTMLTableCell = ({ htmlContent }) => (
    <td dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );

  return (
    <RoomContext.Provider 
        value={{ 
              updateRoom, 
              setUpdateRoom,
              parentBlock
              }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={parentBlock? parentBlock.name+" Rooms" : "Room List"}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <RoomModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      // className="table-responsive mb-0"
                      className="mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table-sm table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="2">Code</Th>
                            <Th data-priority="2">Description</Th>
                            {
                              parentBlock?.hostel?
                              '' :
                              <Th data-priority="3">Hostel</Th>
                            }
                            {
                              parentBlock?
                              '' :
                              <Th data-priority="4">Block</Th>
                            }
                            <Th data-priority="5">Floor No</Th>
                            <Th data-priority="7">Availability</Th>
                            <Th data-priority="8">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            ?.slice(startIndex, endIndex)
                            .map((room, index) => (
                              <Tr key={room?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{room?.code}</Td>
                                <Td>{room?.description}</Td>
                                {
                                  parentBlock?
                                  '' :
                                  <Td>{
                                    room?.block?.hostel?
                                    room?.block?.hostel?.name + ' [' + room?.block?.hostel?.code + ']' : 'None'
                                  }</Td>
                                }
                                {
                                  parentBlock?
                                  '' :
                                  <Td>{
                                    room?.block?
                                    room?.block?.name + ' [' + room?.block?.code + ']' : 'None'
                                  }</Td>
                                }
                                <Td>{getFloorName(room.floorNumber)}</Td>
                                <HTMLTableCell htmlContent={
                                    room?.isAvailable? 
                                    '<span class="btn btn-rounded btn-sm btn-info">Available</span>' 
                                    : 
                                    '<span class="btn btn-rounded btn-sm btn-warning">Unvailable</span>'
                                }/>
                                <Td>
                                  <Dropdown
                                    isOpen={dropdownOpen[room.uid]}
                                    toggle={() => toggleDropdown(room)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateRoom(room)
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeAvailability(room)
                                        }}
                                      >
                                        <i
                                          className={
                                            room?.isAvailable? 'fas fa-lock' : 'fas fa-lock-open'
                                          }
                                          style={{
                                            color: room?.isAvailable? "orange" : 'blue',
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>
                                          {
                                            room?.isAvailable? 'Close' : 'Open'
                                          }
                                        </span>
                                      </DropdownItem>

                                      {/*<DropdownItem
                                        onClick={() => {
                                          setUpdateRoom(room)
                                        }}
                                      >
                                        <i
                                          className="fas fa-warehouse"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Capacity</span>
                                        </DropdownItem>*/}
                                      
                                      {/*<DropdownItem
                                        onClick={() => {
                                          setActivateUser(room)
                                        }}
                                      >
                                        <i
                                          className="fas fa-chair"
                                          style={{
                                            color: room?.user?.isActive
                                              ? "red"
                                              : "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Items</span>
                                        </DropdownItem>*/}

                                      <DropdownItem
                                        onClick={() => {
                                          handleDeleteRoom(room)
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </RoomContext.Provider>
  )
}

export default RoomList
