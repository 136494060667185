import { Link } from "react-router-dom"
import React from "react"

const HODLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_HOD_MENU") && (
        <>
          {hasAuthority("VIEW_COURSE_CATEGORY_MENU") && (
              <li>
                <Link to="/examination/course-categories">
                  <i className="bx bx-list-ul"></i>
                  <span>Course Categories</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_PROGRAM_OFFERED_MENU") && (
              <li>
                <Link to="/examination/programs">
                  <i className="bx bx-book-content"></i>
                  <span>Programmes Offered</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_COURSE_OFFERED_MENU") && (
              <li>
                <Link to="/examination/courses">
                  <i className="bx bx-list-ul"></i>
                  <span>Courses Offered</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_COURSE_OFFERED_MENU") && (
              <li>
                <Link to="/examination/courses/allocation">
                  <i className="bx bx-transfer"></i>
                  <span>Course Allocation</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_COURSE_OFFERED_MENU") && (
              <li>
                <Link to="/examination/courses/registration">
                  <i className="bx bx-edit"></i>
                  <span>Course Registration</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_PROGRAM_OFFERED_MENU") && (
              <li>
                <Link to="/examination/hod/hod-program-change">
                  <i className="bx bx-book-open"></i>
                  <span>Program Change</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_PROGRAM_OFFERED_MENU") && (
            <li>
              <Link to="/examination/hod/course_result_forwarding">
                <i className="bx bx-mouse"></i>
                <span>Forward result (HOD)</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_PROGRAM_OFFERED_MENU") && (
            <li>
              <Link to="/examination/core-course-registration">
                <i className="bx bxs-book-open"></i>
                <span>Force Course Registration</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_PROGRAM_OFFERED_MENU") && (
              <li>
                <Link to="/examination/hod/hod-postpone">
                  <i className="bx bx-time-five"></i>
                  <span>Postpone</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_MY_STUDENTS_MENU") && (
              <li>
                <Link to="/examination/students">
                  <i className="bx bx-group"></i>
                  <span>My Students</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_HOD_MENU") && (
              <li>
                <Link to="/examination/supervisors">
                  <i className="bx bx-user-check"></i>
                  <span>Research Supervisors</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_HOD_MENU") && (
              <li>
                <Link to="/examination/examiners">
                  <i className="bx bx-user-x"></i>
                  <span>Research Examiners</span>
                </Link>
              </li>
          )}

          {hasAuthority("VIEW_HOD_MENU") && (
              <li>
                <Link to="/examination/hod/upload_results_deadline">
                  <i className="bx bx-calendar-event"></i>
                  <span>Upload Result Deadline</span>
                </Link>
              </li>
          )}
        </>
      )}
    </>
  )
}

export default HODLinks
