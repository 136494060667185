import React, { createContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { useFormik } from "formik"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  GET_APPLICANT_CERTIFICATE_RESULT,
  GET_APPLICANT_DETAILS, GET_APPLICANT_PAYMENT_STATUS,
  GET_AVN_APPLICANT_RESULTS,
  GET_NECTA_APPLICANT_RESULTS
} from "../../Queries/ApplicantQueries"
import { onlineApplicationGraphQLClient } from "../../../Authentication/ApolloClient"
import showToast from "../../../../helpers/ToastHelper"
import OALevelResultConfirmation from "./Tanzanian/OALevelResultConfirmation"
import ApplicantCertificateResults from "./ApplicantCertificateResults"
import { CREATE_APPLICANT_CERTIFICATE } from "../../Mutations/ApplicantMutation"
import AVNResultConfirmation from "./Tanzanian/AVNResultConfirmation"
import FetchResultsModel from "./Tanzanian/FetchResultsModel"
import { LoaderPage } from "../../../../helpers/LoaderPage"
import CertificateResultConfirmation from "./Tanzanian/CertificateResultConfirmation"


const breadcrumbItem = [
  {
    title: "Certificate / Results",
    path: "",
    isActive: true
  }
]
export const EntryQualificationConext = createContext()

const EntryQualification = () => {
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data
  const navigate = useNavigate()
  const [create] = useMutation(CREATE_APPLICANT_CERTIFICATE)
  const user = ExtractUserAuth
  const { loading: applicantLoading, error, data: applicantData } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    variables: {
      userUid: user?.uid
    },
    fetchPolicy: "no-cache"
  })
  const applicant = applicantData?.getApplicantDetails.data

  const {
    loading: paymentStatusLoading,
    error: controlNumberError,
    data: paymentStatusData
  } = useQuery(GET_APPLICANT_PAYMENT_STATUS, {
    client: onlineApplicationGraphQLClient,
    skip: !applicant?.uid,
    variables: {
      applicantUid: applicant?.uid
    },
    fetchPolicy: "no-cache"
  })

  const is_payed = paymentStatusData?.getApplicantPaymentStatus?.status

  const [open, setOpen] = useState(false)
  const [openCertificateResultModel, setOpenCertificateResultModel] = useState(false)
  const [AVNOpen, setAVNOpen] = useState(false)
  const [add, setAdd] = useState(false)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      qualificationMethod: applicant?.qualificationMethodCode,
      formSixIndexNumber: "",
      formFourIndexNumber: "",
      avnNumber: "",
      indexNumber: "",
      certificateInstitutionName: "",
      certificateProgramName: "",
      certificateCompletionYear: "",
      certificateDocument: "",
      applicationLevel: applicant?.applicationLevelCode,
      certificateGpa: "",
      subject: "",
      grade: "",
      resultType: "",
      generalRemark: "",
      submitValue: ""
    },

    validationSchema: Yup.object().shape({
      qualificationMethod: Yup.string().required("Qualification Method Is Required")
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (values.qualificationMethod === "form-six" || values.qualificationMethod === "form-four" || values?.qualificationMethod === "other-form-four") {
        let examId = ""
        if (values?.qualificationMethod === "form-six") {
          examId = "2"
          values.indexNumber = values.formSixIndexNumber
        }
        if (values?.qualificationMethod === "form-four") {
          examId = "1"
          values.indexNumber = values.formFourIndexNumber
        }
        if (values?.qualificationMethod === "other-form-four") {
          examId = "1"
          values.indexNumber = values.formFourIndexNumber
        }
        getApplicantResult({
          variables: {
            input: {
              indexNumber: values.indexNumber,
              userUid: user?.uid,
              examId: examId
            }
          },
          onCompleted: ({ getNectaApplicantResults }) => {
            showToast(
              getNectaApplicantResults.message,
              getNectaApplicantResults.code === 8000 ? "success" : "error"
            )
            setAdd(false)
            setOpen(true)

            setSubmitting(false)
            // resetForm(true)
          },
          onError: error => {
            console.error("Mutation error:", error)
            setSubmitting(false)
          }
        })
      } else if (values.qualificationMethod === "diploma") {
        // console.log(values.qualificationMethod)
        getApplicantAvnResult({
          variables: {
            input: {
              indexNumber: values.avnNumber,
              userUid: user?.uid
            }
          },
          onCompleted: ({ getNacteResults }) => {
            showToast(
              getNacteResults.message,
              getNacteResults.code === 8000 ? "success" : "error"
            )
            setAdd(false)
            setAVNOpen(true)
            setSubmitting(false)
          },
          onError: error => {
            console.error("Mutation error:", error)
            setSubmitting(false)
          }
        })
      }
    }
  })


  const {
    loading: certificateLoading,
    error: certificatesError,
    data: certificateData
  } = useQuery(GET_APPLICANT_CERTIFICATE_RESULT, {
    client: onlineApplicationGraphQLClient,
    skip: is_payed !== true,
    variables: {
      userUid: user?.uid
    },
    fetchPolicy: "network-only"
  })

  let [
    getApplicantResult,
    { loading: resultLoading, error: resultError, data: resultData }
  ] = useLazyQuery(GET_NECTA_APPLICANT_RESULTS, {
    client: onlineApplicationGraphQLClient,
    fetchPolicy: "no-cache"
  })

  const result = resultData?.getNectaApplicantResults?.data


  let [
    getApplicantAvnResult,
    { loading: avnResultLoading, error: avnResultError, data: avnResultData }
  ] = useLazyQuery(GET_AVN_APPLICANT_RESULTS, {
    client: onlineApplicationGraphQLClient,
    fetchPolicy: "no-cache"
  })

  const avnResult = avnResultData?.getNacteResults?.data
  const certificates = certificateData?.getApplicantCertificate?.data?.items


  const certificateFormik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      certificateTypeCode: "",
      completionYear: avnResult ? avnResult?.completionYear : result?.particulars?.completionYear,
      division: avnResult ? null : result?.results?.division,
      point: avnResult ? null : result?.results?.points,
      applicantUid: applicant?.uid,
      indexNumber: avnResult ? avnResult.indexNumber : validation?.values.indexNumber,
      registrationNumber: avnResult ? avnResult.registrationNumber : null,
      avn: avnResult ? avnResult?.avn : null,
      gpaClassification: avnResult ? avnResult?.gpaClassification : null,
      gpa: avnResult ? avnResult?.gpa : null,
      results: avnResult ? [] : result?.subjects.map(({ __typename, ...subject }) => subject),
      // centerNumber: results?.particulars?.centerNumber,
      institutionName: avnResult ? avnResult?.centerName : result?.particulars?.centerName
    },
    validationSchema: Yup.object().shape({
      indexNumber: Yup.string().required("Index Number is required"),
      // applicantUid: Yup.string().required("Applicant is required"),
      // results: Yup.array().required("Results Are Required"),
      institutionName: Yup.string().required("Center Name is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      values.certificateTypeCode = validation?.values?.qualificationMethod === "form-six" ? "ACSEE" : "CSEE"
      values.certificateTypeCode = validation.values.avnNumber ? "DIPLOMA" : values.certificateTypeCode
      const parts = values.indexNumber.split("/")
      values.completionYear = parseInt(parts[parts.length - 1], 10) // Access the last element
      // alert('adja sf')
      create({
        client: onlineApplicationGraphQLClient,
        variables: {
          inputs: { ...values }
        },
        onCompleted: ({ registerApplicantCertificateResults }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (registerApplicantCertificateResults.code === 8000) {
            // formik.resetForm()
            if (values.certificateTypeCode === "DIPLOMA") setAVNOpen(false)
            else setOpen(false)
          } else {
            setSubmitting(false)
            if (values.certificateTypeCode === "DIPLOMA") setAVNOpen(true)
            else setOpen(true)
            // validation.resetForm(true)
          }
          showToast(
            registerApplicantCertificateResults.message,
            registerApplicantCertificateResults.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
          return false
        },
        refetchQueries: [
          {
            fetchPolicy: "network-only",
            query: GET_APPLICANT_CERTIFICATE_RESULT,
            variables: {
              userUid: user?.uid
            }
          }
        ]
      })
    }
  })
  const certificateConfirmFormik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      certificateTypeCode: "",
      completionYear: "",
      division: "",
      point: null,
      applicantUid: applicant?.uid,
      indexNumber: "",
      registrationNumber: "",
      avn: null,
      gpaClassification: null,
      gpa: "",
      results: [],
      // centerNumber: results?.particulars?.centerNumber,
      institutionName: ""
    },
    validationSchema: Yup.object().shape({
      // indexNumber: Yup.string().required("Index Number is required"),
      completionYear: Yup.string().required("Completion Year is required"),
      gpa: Yup.number().required("GPA is Required"),
      institutionName: Yup.string().required("Center Name is required"),
      institutionProgram: Yup.string().required("Program Name is required")
      // registrationNumber: Yup.string().required("Registration Number is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      values.indexNumber = values.registrationNumber
      values.certificateTypeCode = "CERT"
      // alert('adja sf')
      create({
        client: onlineApplicationGraphQLClient,
        variables: {
          inputs: { ...values }
        },
        onCompleted: ({ registerApplicantCertificateResults }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (registerApplicantCertificateResults.code === 8000) {
            // formik.resetForm()
            setOpenCertificateResultModel(false)
          } else {
            setSubmitting(false)
            setOpenCertificateResultModel(true)
            // validation.resetForm(true)
          }
          showToast(
            registerApplicantCertificateResults.message,
            registerApplicantCertificateResults.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
          return false
        },
        refetchQueries: [
          {
            fetchPolicy: "network-only",
            query: GET_APPLICANT_CERTIFICATE_RESULT,
            variables: {
              userUid: user?.uid
            }
          }
        ]
      })
    }
  })
  if (applicantLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                           description={"Fetching Applicant Details"} />
  if (paymentStatusLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                               description={"Please wait while system is Validating Payment.."} />
  if (!applicant?.applicationLevelUid) navigate("/applicant/application-method")
  else if (is_payed === false) navigate("/applicant/payment")
  return (
    <>
      {applicant?.applicationLevelCode &&
        <EntryQualificationConext.Provider value={{
          setOpen,
          open,
          setAVNOpen,
          AVNOpen,
          result,
          avnResult,
          setAdd,
          add,
          applicant,
          user,
          certificates,
          openCertificateResultModel,
          setOpenCertificateResultModel
        }}>
          <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={"Applicant"}>
            {applicant && certificates && <>
              {add && <FetchResultsModel formik={validation} />}
              {result && <OALevelResultConfirmation results={result} formik={certificateFormik} />}
              {avnResult && <AVNResultConfirmation results={result} formik={certificateFormik} />}
              {certificateLoading ? <div className="text-center"><i
                  className="fas fa-spinner fa-spin text-success fa-3x align-middle me-2"></i></div> :
                <ApplicantCertificateResults certificates={certificateData} user={user} />
              }
            </>}

            <CertificateResultConfirmation formik={certificateConfirmFormik} />
          </LayoutHelper>
        </EntryQualificationConext.Provider>
      }

    </>
  )
}
export default EntryQualification
