import React from "react";
import { Route, Routes } from "react-router";
import StatusList from "../Status/Views/StatusList";
import StatusTypeList from "../StatusType/Views/StatusTypeList";
import DesignationList from "../Designations/Views/DesignationList";
import KinshipList from "../Kinship/Views/KinshipList";
import DissabiltyList from "../Dissability/Views/DissabiltyList";
import DeactivateReasonsList from "../DeactivateReasons/Views/DeactivateReasonsList";
import PostponementReasonsList from "../PostponementReasons/Views/PostponementReasonsList";
import Pages404 from "Modules/Utility/pages-404";
import StaffTitleList from "../StaffTitle/Views/StaffTitleList";
import SeminarTypeList from "../SeminarType/Views/SeminarTypeList";
import ThesisSettingList from "../ThesisSettng/Views/ThesisSettingList";
import ByLawsList from "../ByLaws/Views/ByLawList";

const LookupRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<StatusList />} />
            <Route path="/disignations" index={true} element={<DesignationList />} />
            <Route path="/status-types" index={true} element={<StatusTypeList />} />
            <Route path="/status" index={true} element={<StatusList />} />
            <Route path="/dissabilities" index={true} element={<DissabiltyList />} />
            <Route path="/deactivate-reasons" index={true} element={<DeactivateReasonsList />} />
            <Route path="/postponement-reasons" index={true} element={<PostponementReasonsList />} />
            <Route path="/kinship" index={true} element={<KinshipList />} />
            <Route path="/staff-title" index={true} element={<StaffTitleList />} />
            <Route path="/seminar-type" index={true} element={<SeminarTypeList />} />
            <Route path="/bylaws" index={true} element={<ByLawsList />} />
            <Route path="/thesis-settng" index={true} element={<ThesisSettingList />} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    )

}

export default LookupRoutes;