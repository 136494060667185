import React, {createContext, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, CardTitle,
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import showToast from "../../../helpers/ToastHelper";
import {showDeleteWarning} from "../../../helpers/DeleteWaringHelper";
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader";
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import PaginationHelper from "../../../helpers/PaginationHelper";
import PropTypes from "prop-types";
import {GET_COURSE_LEARNIG_OUTCOMES} from "../Queries/CourseQueries";
import {DELETE_COURSE_LEANING_OUTCOME} from "../Mutations/CourseMutations";
import CourseOutcomeFormModel from "./CourseOutcomeFormModel";
export const CourseLeaningOutcomesContext = createContext()

const CourseLeaningOutcomes = (props) => {
    const [outcomeUid, setOutcomeUid] = useState(null),
        [outcomeData, setOutcomeData] = useState(null),
        {course} = props
    if(course.length === 0) return showToast('Course is not defined','error')
    const { loading,error, data } = useQuery(GET_COURSE_LEARNIG_OUTCOMES, {
        client:registrationGraphQLClient,
        variables: {
            courseUid:course.uid
        }
    })
    const [
        searchQuery,
        { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_COURSE_LEARNIG_OUTCOMES, {client: registrationGraphQLClient})

    // if (error) return showToast("Failed to fetch Programs", "error")
    let outcomes = data?.getCourseLearnOutcomesByCourse?.data
    if (searchData?.getCourseLearnOutcomesByCourse?.data) {
        outcomes = searchData?.getCourseLearnOutcomesByCourse?.data
    }
    const searchValues = {
        search: "",
    }
    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })
    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })
    const search = () => {
        const value = formikSearch.values.search
        if (value.length >= 3) {
            let data = {
                search: formikSearch.values.search,
            }
            searchQuery({ variables: { courseUid:course.uid, input: { ...data } } })
        }
    }
    const [finalizeDelete] = useMutation(DELETE_COURSE_LEANING_OUTCOME, {
        client:registrationGraphQLClient,
        variables: { uid: outcomeUid },
        refetchQueries: [
            {
                query: GET_COURSE_LEARNIG_OUTCOMES,
                variables: {
                    courseUid: course.uid,
                },
            },
        ],
    })
    const confirmDelete = async () => {
        try {
            const response = await finalizeDelete()
            showToast(response.data.removeCourseLearnOutcome.message, "success")
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("Error deleting user:", error)
        }
    }
    const handleDelete = () => {
        if(outcomeUid !== "") {
            showDeleteWarning(() => {
                confirmDelete()
                setOutcomeUid(false)
            })
        }else{
            showToast('Please Select outcome to delete','warning')
        }
    }
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggleDropdown = course => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [course.uid]: !prevState[course.uid],
        }))
    }
    if (error || searchError) {
        return showToast(error || searchError, "error")
    }
    return (
        <CourseLeaningOutcomesContext.Provider value={{outcomeData, setOutcomeData}} >
            <Row>
                <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <h5>{`${course.name} (${course.code}) LEARNING OUTCOMES`}</h5>
                                </CardTitle>
                                <CardSubtitle className="mb-1">
                                    <Row>
                                        <Col className="col-lg-6 col-6">
                                            <form className="app-search d-none d-lg-block">
                                                <div className="position-relative">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onKeyUp={search}
                                                        placeholder="Search..."
                                                        {...formikSearch.getFieldProps("search")}
                                                    />
                                                    {!searchLoading && (
                                                        <span className="bx bx-search-alt" />
                                                    )}
                                                    {searchLoading && (
                                                        <span className="bx bx-search-alt bx-spin" />
                                                    )}
                                                </div>
                                            </form>
                                        </Col>

                                        <Col sm="6">
                                            <CourseOutcomeFormModel courseUid={course.uid} />
                                        </Col>
                                    </Row>
                                </CardSubtitle>

                                {loading || searchLoading  ? (
                                    <PlaceHolderLoader columSize={12} type="table" rows={5} />
                                ) : (
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                        >
                                            <Table
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th>S/No</Th>
                                                        <Th>Learning Outcome</Th>
                                                        <Th>Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {outcomes
                                                        .map((outcome, index) => (
                                                            <Tr key={outcome?.uid}>
                                                                <Td>{index+1}</Td>
                                                                <Td>{outcome?.learningOutcome}</Td>
                                                                <Td>
                                                                    <Dropdown
                                                                        isOpen={dropdownOpen[outcome.uid]}
                                                                        toggle={() => toggleDropdown(outcome)}
                                                                    >
                                                                        <DropdownToggle color="" caret>
                                                                            <i className="fas fa-ellipsis-v" style={{color: 'blue'}} />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    setOutcomeData(outcome)
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-user-edit" style={{color: 'blue', marginRight: '10px'}}  />
                                                                                <span>Edit</span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    setOutcomeUid(outcome.uid)
                                                                                    handleDelete()
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-trash" style={{color: 'red', marginRight: '10px'}}  />
                                                                                <span>Delete</span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
            </Row>
        </CourseLeaningOutcomesContext.Provider>
    )
}
CourseLeaningOutcomes.propTypes = {
    course : PropTypes.array.isRequired
}
export default CourseLeaningOutcomes;
