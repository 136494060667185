import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import Pages404 from "../../Utility/pages-404"
import SupervisorList from "../View/SupervisorList"
import StudentSupervisor from "../View/StudentSupervisor"
const SupervisorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SupervisorList />} />
      <Route path="/students" element={<StudentSupervisor />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}
export default SupervisorRoutes
