//REGISTE
import { gql } from "@apollo/client"

const GET_COLLEGES = gql`
  query GetUnit($pagination: PaginationInput!) {
    getUnits(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          name
          code
          description
          uid
          campus {
            name
          }
        }
        totalCount
      }
    }
  }
`

export { GET_COLLEGES }
