import React from 'react'
import {Route, Routes} from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
import ProgramRequirementList from "../Views/ProgramRequirementList";
const GradePointRoutes = () => {
    return (
        <Routes>
            <Route  path="/" element={<ProgramRequirementList />}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default GradePointRoutes