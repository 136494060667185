import React, { createContext, useContext, useEffect, useState } from "react"
import {
    Form,
    Input,
    FormFeedback,
    Row,
    Col,
    Label, Table
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { useMutation, useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_KINSHIP } from "../../../Lookups/Kinship/Queries/KinshipQueries"
import { paginationHelper } from "../../../../helpers/Functions/GraphqlUpdateFunction"
import {ApplicantProfileWizardContext} from "./ApplicantProfileWizard";
import {
    REMOVE_APPLICANT_NEXT_OF_KIN
} from "../../../OnlineApplication/ProgramSelection/Mutations/ProgramSelectionMutations";
import {GET_APPLICANT_NEXT_OF_KINS} from "../../../OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries";
import {showDeleteWarning} from "../../../../helpers/DeleteWaringHelper";
import {LoaderPage} from "../../../../helpers/LoaderPage";
import {Link, useNavigate} from "react-router-dom";
import ApplicantNextOfKinModel from "./ApplicantNextOfKinModel";
import DropDownActionHelper from "../../../../helpers/DropDownActionHelper";
import showToast from "helpers/ToastHelper"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";


export const ApplicantNextOfKinContext = createContext() //Create data to transfer to child modal


const ApplicantNextOfKin = () => {
    const navigate = useNavigate()
    const [selectedNextOfKin, setSelectedNextOfKin] = useState(null) //Get data for deleting
    const [updateNextOfKinData, setUpdateNextOfKinData] = useState([]) //Get data for editing
    const { toggleTab,activeTab, passedSteps, applicant,setPayload, payLoad} = useContext(ApplicantProfileWizardContext)
    const [deleteNextOfKin] = useMutation(REMOVE_APPLICANT_NEXT_OF_KIN, { client: onlineApplicationGraphQLClient })
    const {
        loading: kinShipLoading,
        error: kinShipError,
        data: kinShipData
    } = useQuery(GET_KINSHIP, {
        client: uaaGraphQLClient,
        variables: {
            pagination: paginationHelper
        }
    })

    const { loading, error, data } = useQuery(GET_APPLICANT_NEXT_OF_KINS, {
        client: onlineApplicationGraphQLClient,
        variables: {
            applicantUid: applicant?.uid
        },
        fetchPolicy: "network-only"
    })

    if (kinShipError) {
        console.log("Response error", kinShipError)
    }

    const kinship = kinShipData?.getApplicantNextOfKins?.data

    const handlePrevious = () => {
        setPayload({ ...payLoad })
        toggleTab(1) // Move to the previous tab
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            applicantUid: payLoad?.uid ? payLoad?.uid : "",
            fullName: payLoad?.fullName ? payLoad?.fullName : "",
            relationship: payLoad?.relationship ? payLoad?.relationship : "",
            phoneNumber: payLoad?.emegencyPhoneNumber
                ? payLoad?.emegencyPhoneNumber
                : ""
        },
        validationSchema: Yup.object().shape({
            phoneNumber: Yup.string()
                .min(13)
                .max(13)
                .required("Please Enter Valid Phone Number"),
            relationship: Yup.string().required("Relationship is required"),
            fullName: Yup.string().required("Full name is required")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const postData = {
                phoneNumber: values.phoneNumber,
                relationship: values.relationship,
                fullName: values.fullName
            }
            setPayload({
                emegencyPhoneNumber: values.phoneNumber,
                relationship: values.relationship,
                fullName: values.fullName,
                phoneNumber: payLoad?.phoneNumber,
                dateOfBirth: payLoad?.dateOfBirth,
                physicalAddress: payLoad?.physicalAddress
            })
            toggleTab(3)
        }
    })

    const result = data?.getApplicantNextOfKins.data

    const handleNext = () => {
        // handleSubmit() // Call the handleSubmit function from the context
        if (result?.length > 0) {
            navigate("/applicant/application-method")
        } else {
            showToast("Emergency contact can not be blank", "warning")
            toggleTab(2) // Move to the next tab
        }
    }
    const handleDeleteNextOfKin = (nextOfKin) => {
        setSelectedNextOfKin(nextOfKin)
        showDeleteWarning(() => {
            performNextOfKinDeletion(nextOfKin)
        })
    }
    const performNextOfKinDeletion = async (nextOfKin) => {
        deleteNextOfKin({
            variables: {
                uid: nextOfKin?.uid
            },
            refetchQueries: [
                {
                    query: GET_APPLICANT_NEXT_OF_KINS,
                    variables: {
                        applicantUid: applicant?.uid
                    }
                }
            ],
            onCompleted: ({ removeNextOfKin }) => {
                showToast(
                    removeNextOfKin.message,
                    removeNextOfKin.code === 8000 ? "success" : "error"
                )
            },
            onError: error => {
                console.error("Mutation error:", error)
            }
        })
    }
    return (
        <ApplicantNextOfKinContext.Provider
            value={{ updateNextOfKinData, setUpdateNextOfKinData, kinship, toggleTab,activeTab, applicant }}
        >
            <Row>
                {loading ? (
                    <PlaceHolderLoader buttonShown={true} columSize={12} />
                ) : (
                    <Col lg={12}>
                        <ApplicantNextOfKinModel />
                        {!loading && !error && (
                            <div className="table-rep-plugin">
                                <div
                                    className="mb-0"
                                >
                                    <table
                                        className="table table-striped table-bordered"
                                    >
                                        <thead>
                                            <tr>
                                                <th>S/N</th>
                                                <th>Full Name</th>
                                                <th>Kinship</th>
                                                <th>Phone</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result?.map((nextOfKin, index) => (
                                                <tr key={nextOfKin?.uid}>
                                                    <td>{index + 1}</td>
                                                    <td>{nextOfKin?.fullName}</td>
                                                    <td>{nextOfKin?.kinshipName}</td>
                                                    <td>{nextOfKin?.phoneNumber}</td>
                                                    <td>
                                                        <DropDownActionHelper
                                                            data={nextOfKin}
                                                            onUpdate={setUpdateNextOfKinData}
                                                            onDelete={handleDeleteNextOfKin}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </Col>
                )}
            </Row>
            <Row>
                <Col md={12}>
                    <div className="wizard mt-3">
                        <div className="actions">
                            <ul>
                                {!applicant?.submissionStatus &&
                                    <li className="previous">
                                        <Link to="#" onClick={handlePrevious}>
                                            {`< Previous`}
                                        </Link>
                                    </li>
                                }
                                {result &&
                                <li className="next" onClick={handleNext}>
                                    <div className="text-center mt-4">
                                        <Link
                                            to="/applicant/application-method"
                                            className="btn btn-primary  btn-sm"
                                        >
                                            Proceed <i className="mdi mdi-arrow-right ms-1"></i>
                                        </Link>
                                    </div>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </ApplicantNextOfKinContext.Provider>
    )
}
export default ApplicantNextOfKin
