import { gql } from "@apollo/client"

const GET_APPLICANT_BY_USERNAME = gql`
    query GetApplicantByUsername($username: String!) {
        getApplicantByUsername(username: $username) {
            status
            code
            message
            data {
                uid
                firstName
                middleName
                lastName
                email
                username
                phoneNumber
            }
        }
    }
`;

export { GET_APPLICANT_BY_USERNAME }