import React from "react";
import { Routes, Route } from "react-router-dom";
import Pages404 from "Modules/Utility/pages-404";
import CourseList from "../Views/CourseList";
import ViewCourse from "../Views/ViewCourse";
import CourseAllocation from "../Views/CourseAllocation";
import HoDStudentCourseRegistration from "../../HoD/Views/HoDStudentCourseRegistration";
import CoreCourseRegistration from "../Views/CoreCourseRegistration";

const CourseRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CourseList/>} />
            <Route path="/allocation" element={<CourseAllocation/>} />
            <Route path="/registration" element={<HoDStudentCourseRegistration/>} />
            <Route index={true} path="/view" element={<ViewCourse/>}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );
}

export default CourseRoutes;