//REGISTE
import { gql } from "@apollo/client"

const GET_PROGRAM_CATEGORIES= gql`
  query GetProgramCategories($pagination: PaginationInput!) {
    getProgramCategories(pagination: $pagination) {
      status
      code
      message
      data {
        items{
          uid
          name
          shortName
        }
        totalCount
      }
    }
  }
`
const GET_PROGRAM_CATEGORY= gql`
  query getProgramCategory($uid: String!) {
    getProgramCategory(uid: $uid) {
      status
      code
      message
      data {
        uid
        name
        shortName
      }
    }
  }
`

export { GET_PROGRAM_CATEGORIES, GET_PROGRAM_CATEGORY }
