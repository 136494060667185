import React, { useContext, useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import ProgramActionHelper from "helpers/ProgramHelper"
import {Link, useLocation, useNavigate} from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  CardSubtitle,
  CardTitle,
} from "reactstrap"
import ApexCharts from 'react-apexcharts';
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_COURSE_ALLOCATION } from "Modules/ProgramSemister/Queries/CourseAllocationQueries"
import { DELETE_COURSE_ALLOCATION } from "Modules/ProgramSemister/Mutations/CourseAllocationMutations"
import { GET_PROGRAM_COURSE_ASSESSMENT, GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE } from "Modules/ProgramCourseAssessments/Queries/ProgramCourseAssessmentQueries"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { uaaGraphQLClient,registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { token } from "helpers/UrlHelper"
import GetStaffAllocatedCourses from "./GetStaffAllocatedCourses";
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { GET_STAFF }      from "Modules/Users/Queries/UserQuery"

const breadcrumbItem = [
  {
    title: "Forward Results",
    path: "/instructor/forward-results",
    isActive: false,
  },
]




const CourseResults = () => {
    const [fetching, setFetching] = useState([])
    const [courseAllocate, setCourseAllocate] = useState(null) //for update
    const [selectedCourseAllocation, setSelectedCourseAllocation ] = useState(null) // for update
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(limit)
    const pagination = { offset: currentPage, limit: limit, search: null }   

    const location = useLocation();
    const navigate = useNavigate();

    if(!location.state?.courseAllocation) navigate('/forward-results')
    let { courseAllocation } = location.state;     
  
    const { loading, error, data, refetch } = useQuery(GET_COURSE_ALLOCATION, {
      client: registrationGraphQLClient,
      variables: {
        pagination,
      },
    })
  
    const [
      searchProgramCourse,
      { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_COURSE_ALLOCATION, {
      client: registrationGraphQLClient,
      variables: {
        pagination,
      },
    })     
    //showToast("searchAssError", "error");
    const {
      loading: assessLoading,
      error: assessError,
      data: assessData,
    } = useQuery(GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE, {
      client: registrationGraphQLClient,
      variables: {
        programCourseUid: courseAllocation?.programCourse?.uid,
      },
    })
  
    const assessments = assessData?.getProgramCourseAssessmentByProgramCourseUid?.data?.items
   
    const handleDeleteResults = (allocating) => {
      setSelectedCourseAllocation(allocating)
      //showDeleteWarning(() => {
        performResultsDeleting()
      //})
    }

    const chartOptionsScatterLineBar = {
      series: [
        {
          name: "Total in %",
          data: [30, 40, 90, 50, 49, 60, 70],
        },
      ],
      options: {
        chart: {
          id: "basic-line",
        },
        xaxis: {
          categories: ['A', 'B+', 'B', 'C', 'D', 'F', 'I']
        }
      }
    };    

  const performResultsDeleting = async () => {
    try {
      //const response = await deleteCourseAllocation()
      // showToast(response.data.removeCourseAllocation.message, "success")
      showToast("Course Results " + selectedCourseAllocation?.programCourse?.course?.code + " Forward Successfully", "success")
    } catch (error) {
      showToast("Failed to forwarding", "error")
    }
  }

  const programCourse = data?.getCourseAllocations?.data?.items || searchData?.getCourseAllocations?.data?.items || []
  const searchedProgramCourse = searchData?.getCourseAllocations?.data?.items
  const result = searchedProgramCourse ? searchedProgramCourse : programCourse
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getCourseAllocations?.data?.totalCount || searchData?.getCourseAllocations?.data?.items || 0

  
  // const programCourseAss = searchAssData?.getProgramCourseAssessments?.data?.items || []
  // const searchedProgramCourseAss = searchAssData?.getProgramCourseAssessments?.data?.items
  // const resultAss = searchedProgramCourseAss ? searchedProgramCourseAss : programCourseAss

  console.log("user data", assessments)
  //showToast(assessments.length, "error")
  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Course Result Details"
    >
       <Row>
        <Col lg={4}>
          <Card>
            <CardSubtitle>
            <h5 className="mb-1 mt-4 text-center">Course Description</h5>
            </CardSubtitle>
            <CardBody>
              <p className="text-muted"><b>Ante :</b> {`${courseAllocation?.programCourse?.course?.code}`}</p>
              <p className="text-muted"><b>Name :</b> {`${courseAllocation?.programCourse?.course?.name}`}</p>
              <p className="text-muted"><b>Academic Year :</b> {`${courseAllocation?.programCourse?.programSemester?.academicYear?.name}`}</p>
              <p className="text-muted"><b>Programme :</b> {`${courseAllocation?.programCourse?.programSemester?.program?.name}`}</p>
              <p className="text-muted"><b>Semester :</b> {`${courseAllocation?.programCourse?.programSemester?.semester}`}</p>
              <p className="text-muted"><b>Study Year :</b> {`${courseAllocation?.programCourse?.programSemester?.studyYear}`}</p>
              
              <br/>

              <div className="d-flex flex-wrap gap-2 mb-1 mt-4 text-center">
              {/* <h6 className="mb-1 mt-4 text-center"> */}
                <button type="button" className="btn btn-success waves-effect btn-label waves-light text-center" onClick={() => handleDeleteResults(courseAllocation)}><i className="bx bxs-fast-forward-circle label-icon"></i> Forward</button>
              {/* </h6>
              <h6 className="mb-1 mt-4 text-center"> */}
                <button type="button" className="btn btn-success waves-effect btn-label waves-light text-center" onClick={() => handleDeleteResults(courseAllocation)}><i className="bx bx-download label-icon"></i> Download</button>
              {/* </h6> */}
             </div>
              {assessments && assessments.length > 0 ? (
              <> 
                <h6 className="mb-1 mt-4 text-center">Assessment and Contributions</h6>
                {assessments.map((resultAssRows, index) => (
                  <p key={index} className="text-muted">
                    <b> </b> {`${resultAssRows?.examCategory?.name}`}
                    <span className="rounded-pill text-bg-default float-end" key="t-new">
                      {`${resultAssRows?.maximumScore}`}%
                    </span>
                  </p>
                ))}
              </>
            ) : (
              <p>No assessments found.</p>
            )}            
            </CardBody>
          </Card>
          <Card>
            <CardSubtitle><h5 className="mb-1 mt-4 text-center">Results Summary by Grade</h5></CardSubtitle>
            <CardBody>
            <ApexCharts
                options={chartOptionsScatterLineBar.options}
                series={chartOptionsScatterLineBar.series}
                type="bar"
                width={400}
                height={250}
              />                      
            </CardBody>  
          </Card>           
        </Col>
        <Col lg={8}>
          <Card>
            <CardSubtitle className="mb-1 mt-4 text-center">Results for Course {`${courseAllocation?.programCourse?.course?.code}`} 
                    <Row>
                        <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                        </Col>
                        <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">

                        </Col>                   
                    </Row>                      
            </CardSubtitle>
            <CardBody>
            <div className="table-responsive mt-4">
              <Table
                id="tech-companies-1"
                className="table table-striped table-wrap"
              >
                <Thead className="table-light">
                  <Tr>
                    <Th data-priority="1">S/N</Th>
                    <Th>Registration No</Th>
                    <Th>Name</Th>
                    <Th>Status</Th>
                    <Th>Coursework</Th>
                    <Th>Final Examination</Th>
                    <Th>Total</Th>
                    {/* <Th data-priority="2">Action</Th> */}
                  </Tr>
                </Thead>
                <Tbody>

                   {result
                      .slice(startIndex, endIndex)
                      .map((resultRows, index) => (
                        <Tr key={resultRows?.uid}>
                          <Td>{startIndex + index + 1}</Td>
                          <Td>{resultRows?.programCourse?.programSemester?.program?.code}</Td>
                          <Td>{resultRows?.programCourse?.course?.code}</Td>
                          <Td>{resultRows?.programCourse?.course?.name}</Td>
                          <Td>{resultRows?.programCourse?.course?.name}</Td>
                          <Td>{resultRows?.programCourse?.course?.name}</Td>
                          <Td>{resultRows?.programCourse?.course?.code}</Td>
                          {/* <Td>
                            <ProgramActionHelper
                              data={resultRows}
                              onDelete={handleDeleteResults}
                            />
                          </Td> */}
                        </Tr>
                      ))
                    }                  
                    
                </Tbody>               
              </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default CourseResults
