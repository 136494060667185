//REGISTE
import { gql } from '@apollo/client';


const GET_COURSE_CATEGORIES = gql`
query GetCourseCategories($pagination: PaginationInput!) {
  getCourseCategories (pagination: $pagination){
    status
    code
    message
    data{
      items{
        uid
        name
        description          
    }
    totalCount
    }
  }
}
`
;

export { GET_COURSE_CATEGORIES }