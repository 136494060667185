import React from 'react'
import {Route, Routes} from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
import SubjectList from "../Views/SubjectList";
const SubjectRoutes = () => {
    return (
        <Routes>
            <Route  path="/" element={<SubjectList />}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default SubjectRoutes