import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"

export const GenerateUEStudentCard = (userData, title, programName) => {
  const pdf = new jsPDF()

  const header =
    "SOKOINE UNIVERSITY OF AGRICULTURE\n                  P.O.Box 3000, \n            Morogoro Tanzania"

  const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"

  // Calculate the number of cards per page (6 cards in this case)
  const cardsPerPage = 6
  const cardHeight = 50
  const separation = 10
  const cardsPerRow = 2
  const rowsPerPage = cardsPerPage / cardsPerRow
  const currentDate = new Date()
  const day = currentDate.getDate().toString().padStart(2, "0")
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0") // Months are zero-based, so add 1
  const year = currentDate.getFullYear()

  // Loop through each set of six User ID cards
  for (
    let pageIndex = 0;
    pageIndex < userData.length / cardsPerPage;
    pageIndex++
  ) {
    // Loop through each row on the current page
    for (let rowIndex = 0; rowIndex < rowsPerPage; rowIndex++) {
      // Calculate the Y position for the current row
      const rowY = rowIndex * (cardHeight + separation) + 10

      // Loop through each card in the row
      for (let cardIndex = 0; cardIndex < cardsPerRow; cardIndex++) {
        const dataIndex =
          pageIndex * cardsPerPage + rowIndex * cardsPerRow + cardIndex
        const user = userData[dataIndex]

        if (user) {
          // Calculate the X position for the current card (left or right)
          const cardX =
            cardIndex === 0 ? 10 : pdf.internal.pageSize.width / 2 + 10
          const startX = pdf.internal.pageSize.width / 2 // Start from the middle of the page

          const imageWidth = 15
          const imageHeight = 15
          pdf.addImage(
            sua_logo,
            "JPEG",
            1,
            cardIndex * (cardHeight + separation) + 70,
            imageWidth,
            imageHeight
          )
          pdf.addImage(
            sua_logo,
            "JPEG",
            startX + 1,
            cardIndex * (cardHeight + separation) + 70,
            imageWidth,
            imageHeight
          )
          // Draw the card
          pdf.autoTable({
            startY: rowY,
            body: [
              [
                { content: "", styles: { cellWidth: 5 } },
                {
                  content: header,
                  colSpan: 2,
                  styles: { fontStyle: "bold" },
                },
              ],
              [
                { content: "", styles: { cellWidth: 5 } },
                { content: `\nName: ${user.name}`, colSpan: 2 },
              ],
              [
                { content: "", styles: { cellWidth: 5 } },
                { content: `Registration No: ${user.regNo}`, colSpan: 2 },
              ],
              [
                { content: "", styles: { cellWidth: 5 } },
                { content: `Program Name: ${title}`, colSpan: 2 },
              ],
              [
                { content: "", styles: { cellWidth: 5 } },
                {
                  content:
                    `Issue Date: ` +
                    `${day}-${month}-${year}` +
                    ` Expiry Date: 29-02-2024`,
                  colSpan: 2,
                },
              ],
              [
                { content: "", styles: { cellWidth: 5 } },
                { content: `For DvC (Academic)`, colSpan: 2 },
              ],
            ],
            theme: "plain", // Use plain theme to remove borders
            margin: { left: cardX, right: 0 },
          })
        }
      }
    }
    // Add a new page for the next set of six User ID cards
    pdf.addPage()
  }

  pdf.save(`${title} ` + ` ${programName}.pdf`)
}
