// export default AdmissionYearList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {onlineApplicationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import {DELETE_ADMISSION_YEARS} from "../Mutations/AdmissionYearMutation";
import AdmissionYearModal from "./AdmissionYearModal";
import { GET_ADMISSION_YEARS } from "../../OnlineApplication/AdmissionProgramCapacity/Queries/ProgramAdmissionQueries"

const breadcrumbItem = [
  {
    title: "Admission Year List",
    path: "",
    isActive: true,
  },
]

export const AdmissionYearContext = createContext(undefined) //Create data to transfer to child modal

const AdmissionYearList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedAdmissionYear, setSelectedAdmissionYear] = useState(null) //Get data for deleting
  const [updateAdmissionYearData, setUpdateAdmissionYearData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_ADMISSION_YEARS, {
    client:onlineApplicationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const [searchAdmissionYear,{ loading: searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_ADMISSION_YEARS,{
    client:onlineApplicationGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {
      console.log("DataReceived", data)
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected;
   // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteAdmissionYear] = useMutation(DELETE_ADMISSION_YEARS, {
    client: onlineApplicationGraphQLClient,
    variables: { uid: selectedAdmissionYear?.uid },
    refetchQueries: [
      {
        query: GET_ADMISSION_YEARS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteAdmissionYear = (deleteData) => {
    setSelectedAdmissionYear(deleteData)
    showDeleteWarning(() => {
      performDataDeletion().then(r => console.log(r))
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteAdmissionYear()
      showToast(response.data.removeAdmissionYear.message, response.data.removeAdmissionYear.code === 8000 ? "success" : 'error');
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchAdmissionYear({variables: {pagination: {...pagination, ...data}}}).then(r => console.log(r))
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }
  const dataArray = data?.getAdmissionYears.data?.items
  const searchedData = searchData?.getAdmissionYears?.data?.items
  const result = searchedData ? searchedData : dataArray
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getAdmissionYears.data?.totalCount || searchData?.getAdmissionYears?.data?.items || 0

  return (
    <AdmissionYearContext.Provider value={{ updateAdmissionYearData, setUpdateAdmissionYearData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Admission Year List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <AdmissionYearModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {
                        loading ? (
                          <div className="text-center">
                            <i className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2" />
                            <br />
                            <strong>Please wait...</strong>
                          </div>
                        ) : (
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="1">S/N</Th>
                                <Th data-priority="1">Name</Th>
                                <Th data-priority="1">Status</Th>
                                <Th>Start Date</Th>
                                <Th>End Date</Th>
                                <Th>No. of Choices</Th>
                                <Th data-priority="3">Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {result && result
                                ?.slice(startIndex, endIndex)
                                ?.map((admissionYear, index) => (
                                  <Tr key={admissionYear?.uid}>
                                    <Td>{startIndex + index + 1}</Td>
                                    <Td>{admissionYear.name}</Td>
                                    <Td>{admissionYear.status==1 ? 'Currect' : 'Not Current'}</Td>
                                    <Td>{admissionYear.startDate}</Td>
                                    <Td>{admissionYear.endDate}</Td>
                                    <Td>{admissionYear.numberOfChoices}</Td>
                                    <Td>
                                      <DropDownActionHelper
                                        data={admissionYear}
                                        onUpdate={setUpdateAdmissionYearData}
                                        onDelete={handleDeleteAdmissionYear}
                                      />
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        )
                      }
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </AdmissionYearContext.Provider>
  )
}

export default AdmissionYearList
