import { gql } from "@apollo/client"

const GET_PUBLICATIONS = gql`
  query getStaffPublications($pagination: PaginationInput!) {
    getStaffPublications(pagination: $pagination) {
      code
      data {
        totalCount
        items {
          authorPercentage
          authorshipPosition
          authorCategory {
            code
            name
            uid
          }
          publication {
            authorCategoryType
            conferenceLocation
            defaultWeight
            doiLink
            edition
            firstAuthorPercentage
            highestReviewerGrade
            highestReviewerWeight
            isbnEIsbn
            issueNumber
            otherAuthorPercentage
            pages
            publicationAttachment
            publicationAudienceType
            publicationTypePointUid
            publicationTypeUid
            publicationYear
            publisherAddress
            staffUid
            title
            totalAuthors
            uid
            volume
          }
          staffUid
        }
      }
      message
      status
    }
  }
`

export { GET_PUBLICATIONS }
