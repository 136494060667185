import { gql } from "@apollo/client"

const CREATE_GROUPS = gql`
  mutation RegisterGroups($inputs: [GroupInput!]!) {
    registerGroups(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const ASSIGN_PERMISSION = gql`
  mutation AssignPermission($inputs: AssignPermissionGroupInput!) {
    assignPermissionsToGroup(inputs: $inputs) {
      status
      code
      message
      data
    }
  }
`

const DELETE_GROUPS = gql`
  mutation RemoveGroup($uid: String!) {
    removeGroup(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_GROUPS, DELETE_GROUPS, ASSIGN_PERMISSION }
