import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import {
    paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { SubjectContext } from "./SubjectList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {onlineApplicationGraphQLClient} from "Modules/Authentication/ApolloClient"
import {CREATE_SUBJECT} from "../Mutations/SubjectMutations";
import Select from "react-select";
import {GET_SUBJECTS} from "../Queries/SubjectQueries";

const SubjectModal = () => {
    const { updateSubjectData, setUpdateSubjectData } = useContext(SubjectContext)
    const [Subject] = useMutation(CREATE_SUBJECT)
    const [modalOpen, setModalOpen] = useState(false)


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: updateSubjectData ? updateSubjectData.name : "",
            code: updateSubjectData ? updateSubjectData.code : "",
            level: updateSubjectData ? updateSubjectData.description : "",
        },

        validationSchema: Yup.object().shape({
            name: Yup.string().required("Subject name is required"),
            code: Yup.string().required("Subject short code is required"),
            level: Yup.string().required("Subject level is required"),
        }),

        onSubmit: (values, { resetForm, setSubmitting }) => {
            Subject({
                client: onlineApplicationGraphQLClient,
                variables: {
                    inputs: {...values, uid: updateSubjectData?.uid},
                },
                refetchQueries: [
                    {
                        query: GET_SUBJECTS,
                        variables: {
                            pagination: paginationHelper,
                        },
                    },
                ],
                onCompleted: ({registerSubject}) => {
                    //updateFunction(client.cache, { data: { registerSubjectes } });
                    if (registerSubject.code === 8000) {
                        validation.resetForm()
                        setModalOpen(false)
                        setUpdateSubjectData(false) // Close the modal
                    } else {
                        setModalOpen(true)
                        setUpdateSubjectData(false)
                        resetForm(false)
                    }
                },
                onError: error => {
                    // Handle errors
                    setSubmitting(false);
                    console.error("Mutation error:", error)
                },
            }).then(r => console.log(r))
         },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Subject
            </Button>
            <Modal
                isOpen={modalOpen || !!updateSubjectData}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!updateSubjectData ? "Edit Subject" : "Add New Subject"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="name" className="col-form-label">
                                Subject Name:
                            </label>
                            <Input
                                type="text"
                                name="name"
                                className="form-control"
                                id="firstname"
                                placeholder="Enter Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                    !!(validation.touched.name && validation.errors.name)
                                }
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.name}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="code" className="col-form-label">
                                Subject Code:
                            </label>
                            <Input
                                type="text"
                                name="code"
                                className="form-control"
                                id="firstname"
                                placeholder="Short Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.code || ""}
                                invalid={
                                    !!(validation.touched.code && validation.errors.code)
                                }
                            />
                            {validation.touched.code && validation.errors.code ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.code}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="col-form-label">
                                Level:
                            </label>

                            <Select
                                // value={selectedMulti2}
                                onChange={(e) => {
                                    validation.setFieldValue('level',e.value)
                                }}
                                options={[
                                    {
                                        label:"CSEE",
                                        value:"CSEE"
                                    },
                                    {
                                        label:"ACSEE",
                                        value:"ACSEE"
                                    },
                                    {
                                        label:"CERTIFICATE",
                                        value:"CERT"
                                    },
                                   {
                                        label:"DIPLOMA",
                                        value:"DIPLOMA"
                                    },
                                   {
                                        label:"JUNIOR",
                                        value:"JUNIOR"
                                    },
                                    {
                                        label:"SENIOR",
                                        value:"SENIOR"
                                    }
                                ]}
                                className="select2-selection"
                                styles={{
                                    menu: base => ({
                                        ...base,
                                        position: "absolute",
                                        zIndex: 9999
                                    })
                                }}
                                defaultValue={updateSubjectData?.level}
                                defaultInputValue={updateSubjectData?.level}
                            />

                            {validation.touched.level && validation.errors.level ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.level}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setUpdateSubjectData(false)
                                validation.resetForm()
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default SubjectModal
