import React from "react";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardSubtitle, Col, Row} from "reactstrap";
import CourseLeaningOutcomes from "./CourseLeaningOutcomes";


const breadcrumbItem = [
    {
        title: "Courses",
        path: "/courses",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]

const ViewCourse = () => {
    const location = useLocation();
    const navigate = useNavigate();
    if(!location.state?.course) navigate('/examination/courses')
    let { course } = location.state;
  return(
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`${course.name} (${course.code})`} >
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody className="border-bottom">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0 card-title flex-grow-1">{course.name}</h5>
                            <div className="flex-shrink-0">
                                {/*<button className="btn btn-soft-danger me-1">Delete Course</button>*/}
                                {/*<Link to="/courses/registration"  className="btn btn-primary me-1" title={'Add new Course'}>Add New <i className="bx bxs-plus-circle"/></Link>*/}
                                {/*<Link to="/courses/registration" className="btn btn-success me-1" state={{course:course}} title={'Update Course'}>Update <i className="bx bxs-edit"></i></Link>*/}
                            </div>
                        </div>
                    </CardBody>
                    <CardBody>
                        <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Course Name: </th>
                                        <td scope="col">{course.name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Course Code:</th>
                                        <td>{course.code}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Description: </th>
                                        <td><div dangerouslySetInnerHTML={{ __html: course.description} } /></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Is Offered ?</th>
                                        <td>{
                                            course.offered ? <span className="badge badge-soft-success">YES</span>
                                                : <span className="badge badge-soft-danger">NO</span>
                                        } </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="hstack gap-2">
                            {/*<button className="btn btn-soft-primary w-100">Apply Now</button>*/}
                            <button className="btn btn-soft-danger w-10">Delete Course</button>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <CourseLeaningOutcomes course={course} />
    </LayoutHelper>
  );
}

export default ViewCourse;