//REGISTE
import { gql } from "@apollo/client"

const GET_ALL_PROGRAM_CHANGE = gql`
  query getAllProgramChange {
    getAllStudentChangePrograms {
      status
      code
      message
      data {
        uid
        academicYear {
          uid
          name
        }
        currentProgram {
          uid
          code
          name
          tcuCode
        }
        newProgram {
          uid
          code
          name
        }
        currentRegistrationNumber
        newRegistrationNumber
        approveStatus
        approveRemark
        approvedBy
        reason
      }
    }
  }
`
const GET_HOD_PROGRAM_SEMESTER = gql`
  query GetHodProgramSemester($academicYearUid: String!, $semester: Int!) {
    getHodProgramSemester(
      academicYearUid: $academicYearUid
      semester: $semester
    ) {
      status
      code
      message
      data {
        studyYear
        uid
        academicYear {
          name
        }
        program {
          name
          code
        }
      }
    }
  }
`
const GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID = gql`
  query GetHodForwardExamCourseResultStatusByProgramSemesterUid(
    $programSemesterUid: String!
  ) {
    getHodForwardExamCourseResultStatusByProgramSemesterUid(
      programSemesterUid: $programSemesterUid
    ) {
      status
      code
      message
      data {
        uid
        id
        course {
          uid
          code
          name
        }
        programSemester {
          semester
          uid
          academicYear {
            name
          }
          program {
            name
          }
          studyYear
        }
        forwardStatus
      }
    }
  }
`

const GET_UPLOAD_RESULTS_DEADLINE = gql `
    query GetUploadResultDeadline{
        getUploadResultDeadline{
            status
            code
            message
            data{
                endDate
                department
                uid
                academicYearSemester{
                    status
                    uid
                }
            }
        }
    }
`

export {
  GET_ALL_PROGRAM_CHANGE,
  GET_HOD_PROGRAM_SEMESTER,
  GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID,
  GET_UPLOAD_RESULTS_DEADLINE
}
