import React from "react";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Link, useLocation} from "react-router-dom";
import {Button, Card, CardBody, CardSubtitle, Col, Row} from "reactstrap";
import ProgramFormModel from "./ProgramFormModel";


const breadcrumbItem = [
    {
        title: "Programs",
        path: "/programs",
        isActive: false,
    },
    {
        title: "View",
        path: "",
        isActive: true,
    }
]

const ViewProgram = () => {
    const location = useLocation();
    let { program } = location.state;
    // console.log(program)
    // return 1
    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`${program.name} (${program.code})`} >
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody className="border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1">{program.name}</h5>
                                <div className="flex-shrink-0">
                                    {/*<ProgramFormModel program={false} />*/}
                                    {/*<Link to="/programs/registration"  className="btn btn-primary me-1" title={'Add new Program'}>Add New <i className="bx bxs-plus-circle"/></Link>*/}
                                    {/*<Link to="/programs/registration" className="btn btn-success me-1" state={{program:program}} title={'Update Program'}>Update <i className="bx bxs-edit"></i></Link>*/}
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Program Name: </th>
                                        <td scope="col">{program.name}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Program Short Name:</th>
                                        <td>{program.shortName}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Program Code:</th>
                                        <td>{program.code}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Program Category:</th>
                                        <td>{program.programCategory.name+' ('+program.programCategory.shortName+')'}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Program Status:</th>
                                        <td>{program.duration} Year's</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Registration Code: </th>
                                        <td>{program.registrationCode}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">NACTE Code: </th>
                                        <td>{program.nacteCode}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">TCU Code: </th>
                                        <td>{program.tcuCode}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="hstack gap-2">
                                {/*<button className="btn btn-soft-primary w-100">Apply Now</button>*/}
                                <button className="btn btn-soft-danger w-10">Delete Program</button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default ViewProgram;