import React, { useContext, useEffect, useState } from "react"
import { Form, Input, FormFeedback, Row, Col, Label, Button } from "reactstrap"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link } from "react-router-dom"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useParams } from "react-router"
import { TeachingAssessmentProfileContext } from "./TeachingCourseAssessmentParent"
import { GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT } from "Modules/TeachingAssessment/Queries/TeachingAssessmentQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT } from "Modules/TeachingAssessment/Mutations/TeachingAssessmentMutations"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"

const QuestionEightAndNine = () => {
  const { uid } = useParams()

  const { updateTeachingAssessmentData } = useContext(
    TeachingAssessmentProfileContext
  )

  const { toggleTab } = useContext(TeachingAssessmentProfileContext)
  const [isSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const [registerCourseAssessment] = useMutation(
    REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT,
    {
      client: registrationGraphQLClient,
    }
  )
  const {
    loading: courseAssessmentLoading,
    error: courseAssessmentError,
    data: courseAssessmentData,
  } = useQuery(GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT, {
    client: registrationGraphQLClient,
    variables: {
      studentCourseRegistrationUid: uid ? uid : "",
      questionNo: 8,
    },
    skip: !uid,
    fetchPolicy: "network-only",
  })

  const {
    loading: questionNo9Loading,
    error: questionNo9Error,
    data: questionNo9Data,
  } = useQuery(GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT, {
    client: registrationGraphQLClient,
    variables: {
      studentCourseRegistrationUid: uid ? uid : "",
      questionNo: 9,
    },
    skip: !uid,
    fetchPolicy: "network-only",
  })
  const questionFourRes =
    questionNo9Data?.getStudentProgramCourseAssessmentResult?.data

  const questionOneRes =
    courseAssessmentData?.getStudentProgramCourseAssessmentResult?.data
  console.log(questionOneRes)

  const [outlineProvided, setOutlineProvided] = useState(
    questionOneRes?.answer !== null && questionOneRes?.answer !== "undefined"
      ? true
      : false
  )

  useEffect(() => {
    const questionOneRes =
      courseAssessmentData?.getStudentProgramCourseAssessmentResult?.data ?? {}
    setOutlineProvided(questionOneRes?.answer ?? "")
  }, [courseAssessmentData])

  const handleCheckboxChange = event => {
    setOutlineProvided(event.target.value)
  }

  const handlePrevious = () => {
    toggleTab(7) // Move to the previous tab
  }

  const handleNext = () => {
    setLoading(true)
    registerCourseAssessment({
      variables: {
        inputs: {
          answer: outlineProvided,
          questionNo: 8,
          studentCourseRegistrationUid: uid,
        },
      },
      onCompleted: ({ registerStudentProgramCourseAssessmentResult }) => {
        if (registerStudentProgramCourseAssessmentResult.code === 8000) {
          setOutlineProvided(false)
          showToast(
            registerStudentProgramCourseAssessmentResult.message,
            "success"
          )
          setLoading(false)
          toggleTab(10) // Move to the next tab
        } else {
          showToast(
            registerStudentProgramCourseAssessmentResult.message,
            registerStudentProgramCourseAssessmentResult.code === 8000
              ? "success"
              : "error"
          )
          setLoading(false)
          toggleTab(8) // Move to the next tab
        }
      },
      onError: error => {
        setLoading(false)
        console.error("Mutation error:", error)
      },
    })
  }
  const numberedItems = `i.   \nii.  \niii. `

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      answer: questionFourRes ? questionFourRes?.answer : numberedItems,
    },

    validationSchema: Yup.object().shape({
      answer: Yup.string().required("Answer is required"),
    }),

    onSubmit: values => {
      validation.resetForm()
      registerCourseAssessment({
        variables: {
          inputs: {
            answer: values.answer,
            questionNo: 9,
            studentCourseRegistrationUid: uid,
          },
        },
        onCompleted: ({ registerStudentProgramCourseAssessmentResult }) => {
          if (registerStudentProgramCourseAssessmentResult.code === 8000) {
            handleNext()
            validation.resetForm()
            toggleTab(9) // Move to the next tab
          } else {
            showToast(
              registerStudentProgramCourseAssessmentResult.message,
              registerStudentProgramCourseAssessmentResult.code === 8000
                ? "success"
                : "error"
            )
            validation.resetForm()
            toggleTab(4) // Move to the next tab
          }
        },
        onError: error => {
          validation.resetForm()
          console.error("Mutation error:", error)
        },
      })
    },
  })

  return (
    <div className="mt-3 wizard">
      {courseAssessmentLoading ? (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label className="font-weight-bolder">
                    <b>8.0</b> Did the instructor encourage team work?
                  </Label>
                  <div>
                    <Label>
                      <Input
                        type="radio"
                        value="Yes"
                        defaultChecked={questionOneRes?.answer === "Yes"}
                        name="qn1"
                        onChange={handleCheckboxChange}
                      />{" "}
                      Yes
                    </Label>
                    <br />
                    <Label>
                      <Input
                        type="radio"
                        value="No"
                        name="qn1"
                        defaultChecked={questionOneRes?.answer === "No"}
                        onChange={handleCheckboxChange}
                      />{" "}
                      No
                    </Label>
                  </div>
                </div>
                {outlineProvided === "Yes" && (
                  <div className="mb-3">
                    <Label className="font-weight-bolder">
                      <b>9.0</b> Explain how:
                    </Label>
                    <div className="form-group">
                      <textarea
                        rows={3}
                        cols={3}
                        name="answer"
                        className="form-control"
                        style={{ whiteSpace: "pre-wrap" }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.answer || ""}
                      >
                        {questionFourRes?.answer
                          ? questionFourRes?.answer
                          : numberedItems}
                      </textarea>
                      {validation.touched.answer && validation.errors.answer ? (
                        <FormFeedback type="invalid">
                          {validation.errors.answer}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="mt-2 mb-3"></div>
              </Col>
            </Row>
          </div>
          <div className="wizard">
            <div className="actions">
              <ul>
                <li className="previous">
                  <Link to="#" onClick={handlePrevious}>
                    {`< Previous`}
                  </Link>
                </li>
                {outlineProvided &&
                  (outlineProvided === "Yes" ? (
                    <SubmitButtonHelper
                      name="Next >"
                      type="primary"
                      formik={validation}
                    />
                  ) : (
                    (outlineProvided === "No") &
                    (
                      <li className="next">
                        <Link to="#" onClick={handleNext}>
                          {loading ? <>Please wait..</> : <>{`Next >`}</>}
                        </Link>
                      </li>
                    )
                  ))}
              </ul>
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default QuestionEightAndNine
