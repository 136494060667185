import { gql } from "@apollo/client"

const CREATE_MARITAL_STATUS = gql`
  mutation RegisterMaritalStatus($inputs: [MaritalStatusDtoInput!]!) {
    registerMaritalStatuses(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
        }
      }
    }
  }
`

const DELETE_MARITAL_STATUS = gql`
  mutation RemoveMaritalStatus($uid: String!) {
    removeMaritalStatus(uid: $uid) {
      code
      message
      status
    }
  }
`

export { CREATE_MARITAL_STATUS, DELETE_MARITAL_STATUS }
