//REGISTE
import { gql } from '@apollo/client';

const GET_STUDENT_EXAM_TO_REGISTER = gql`
  query GetStudentExamToRegister ($studentUid: String!) {
    getStudentExamToRegister(studentUid: $studentUid){
           status
            code
            message
            data{
              firstSitting{
                uid
                programCourse{
                  courseCategory{
                    name
                  }
                  programSemester{
                    studyYear
                    semester
                    academicYear{
                      name
                    }
                  }
                  credit
                  course{
                    code
                    name
                  }
                }
              }
              postponed{
                studentCourseRegistrations{
                  uid
                  programCourse{
                    course{
                      name
                      code
                    }
                    courseCategory{
                      name
                    }
                    credit
                    programSemester{
                      studyYear
                      semester
                      academicYear{
                        name
                      }
                    }
                  }
                }
              }
              failure{
                type
                studentExamRegistration{
                  examCategory{
                    name
                  }
                  studentCourseRegistration{
                    uid
                    programCourse{
                      uid
                      course {
                        code
                        name
                      }
                      courseCategory{
                        name
                      }
                      programSemester{
                        studyYear
                        academicYear{
                          name
                        }
                        semester
                      }
                    }
                  }
                }
              }
            }
    }
  }
`;

const GET_STUDENT_REGISTERED_EXAMS = gql`
    query GetStudentCurrentRegisteredExam ($studentUid: String!) {
        getStudentCurrentRegisteredExam(studentUid: $studentUid) {
            status
            code
            message
            data {
                studentCourseRegistration {
                    uid
                    coreElective
                    programCourse {
                        credit
                        course {
                            code
                            name
                        }
                        courseCategory {
                            name
                        }
                        programSemester {
                            studyYear
                            semester
                            academicYear {
                                name
                            }
                        }
                    }
                }
            }
        }

    }
`;

export { GET_STUDENT_EXAM_TO_REGISTER,GET_STUDENT_REGISTERED_EXAMS }