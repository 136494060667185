import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const StudentModal = ({ isOpen, onClose, StudentContext }) => {
  const [updateStudentData, setUpdateStudentData] = useState(StudentContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateStudentData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = () => {
    // Add logic to save editedData
    // For example, you can call an API to update the student data
    // onClose(); // Close the modal after saving
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <h2>Edit Student Data</h2>s
      <form>
        <label>
          Name:
          <input type="text" name="name" onChange={handleInputChange} />
        </label>
        <label>
          Roll Number:
          <input type="text" name="rollNumber"  onChange={handleInputChange} />
        </label>
        {/* Add more fields as needed */}
        <button type="button" onClick={handleSave}>
          Save
        </button>
        <button type="button" onClick={onClose}>
          Close
        </button>
      </form>
    </Modal>
  );
};

export default StudentModal;
