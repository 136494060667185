import React from "react";
import { Routes, Route } from "react-router-dom";
import Pages404 from "Modules/Utility/pages-404";
import SemesterList from "../Views/SemesterList";

const SemesterRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<SemesterList/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );
}

export default SemesterRoutes;