import React from "react"
import { Route, Routes } from "react-router"
import SponsorList from "../View/SponsorList"
import Pages404 from "Modules/Utility/pages-404"

const SponsorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SponsorList />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default SponsorRoutes
