import React, { createContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  Modal,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient";
import StudentStudyPostponementModal from "./StudentStudyPostponementModal";
import DropDownActionHelper from './../../../../../helpers/DropDownActionHelper';
import { showDeleteWarning } from "helpers/DeleteWaringHelper";
//import { UPDATE_STUDY_POSTPONEMENT } from "../../../Mutations/StudentApplicationMutations";
import { DELETE_STUDY_POSTPONEMENT } from "../../../Mutations/StudentApplicationMutations";
import { GET_STUDENT_STUDY_POSTPONEMENT_REQUEST } from '../../../Queries/Application/StudentApplicationQueries'
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery";
import { GET_APPROVAL_HISTORY } from './../../../Queries/Application/StudentApplicationQueries'
import { token } from "helpers/UrlHelper";
import showToast from "../../../../../helpers/ToastHelper"
const breadcrumbItem = [
  {
    title: 'Study Postponement',
    path: '',
    isActive: true,
  },
];

export const StudyPostponementContext = createContext() //Create data to transfer to child modal

const StudentStudyPostponement = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [studyPostponement, setStudyPostponement] = useState([]); //Get data for editing
  const [selectedRequest, setSelectedRequest] = useState(null) //Get data for deleting
  const [generatedPdf, setGeneratedPdf] = useState(null); // State to store the generated PDF Blob
  const {
    loading: studentLoading,
    error: studentError,
    data: studentDetails,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    variables: { username: token?.username },
  })
  const studentUid = studentDetails?.student?.uid

  
  const { loading, 
    error, 
    data, 
    refetch } = useQuery(GET_STUDENT_STUDY_POSTPONEMENT_REQUEST, {
    client:uaaGraphQLClient,
    variables: {
      // pagination,
    },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (!loading && data) {

    }
  }, [loading, data])


  function closeButton() {
    return (
      <Button
        type={'button'}
        onClick={() => {
          setModalOpen(false);
        }}
      >
        Cancel
      </Button>
    );
  }

  //dummy data for mapping for testing
  // const postponementRequests = [
  //   {
  //     id: 1,
  //     reason: 'Professional Development',
  //     description: 'Nitasafiri kwenda Denmark kwa ajili ya intership ya SUGECO',
  //     pdfLink: '/path-to-attached-pdf',
  //     approvalId: 'Pending Approval', // Example status
  //   },
  //   // Add more requests here
  // ];

  const [deleteStudyPostponement] = useMutation(DELETE_STUDY_POSTPONEMENT, {
    client: uaaGraphQLClient,
    variables: { uid: selectedRequest?.uid },
    refetchQueries: [
      {
        query: GET_STUDENT_STUDY_POSTPONEMENT_REQUEST,
        variables: {
          studentUid: studentUid,
        },
      },
    ],
  })

  const handleDeletePostponement = (deleteRequest) => {
    setSelectedRequest(deleteRequest)
    showDeleteWarning(() => {
      performPostponementDeletion()
    })
    
    // Perform the delete action using the id
    // Update your state or send a request to delete the record
    console.log(`Deleting record with id ${deleteRequest}`);
    // Example:
    // const updatedRequests = postponementRequests.filter((request) => request.id !== id);
    // setPostponementRequests(updatedRequests);
  };

  const performPostponementDeletion = async () => {
    try {
      const response = await deleteStudyPostponement()
      showToast(response?.data?.deleteStudyPostponement?.message, response?.data?.deleteStudyPostponement?.code === 8000 ? "success" : 'error');
    } catch (error) {
      console.error("Error deleting Postponement Request:", error)
    }
  }

  // Callback function to handle the generated PDF
  const handleGeneratedPdf = (pdfBlob) => {
    // Save the generated PDF Blob to state
    setGeneratedPdf(pdfBlob);
  };

  return (

    <StudyPostponementContext.Provider value={{ studyPostponement, setStudyPostponement }}>
   
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Study Postponement Requests">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-1">
                <Row>
                  <Col className="col-6">
                  </Col>
                  <Col className="col-6">
                    <div className="text-sm-end mt-3">
                      <StudentStudyPostponementModal />
                    </div>
                  </Col>
                </Row>
              </CardSubtitle>

              <div className="table-rep-plugin">
                <div className="mb-0" data-pattern="priority-columns">
                  <Table id="study-postponement-table" className="table table-striped table-bordered">
                    <Thead>
                      <Tr>
                        <Th>S/No</Th>
                        <Th data-priority="0">Postponement Reason</Th>
                        <Th data-priority="1">Reason Description</Th>
                        <Th data-priority="2">Attached PDF</Th>
                        <Th>Status</Th>
                        <Th data-priority="3">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {studyPostponement?.map((request) => (
                        <Tr key={request?.id}>
                          <Td>{request?.id}</Td>
                          <Td>{request?.reason}</Td>
                          <Td>{request?.description}</Td>
                          {/* <Td>
                            <a href={request.pdfLink} target="_blank" rel="noopener noreferrer">
                              View PDF
                            </a>
                          </Td> */}
                         <Td>
                            {generatedPdf ? (
                              <div>
                                <object
                                  data={URL.createObjectURL(generatedPdf)}
                                  type="application/pdf"
                                  width="100%"
                                  height="500"
                                />
                                <p>View PDF</p>
                              </div>
                            ) : null}
                          </Td>
                          <Td>{request?.approvalId}</Td>
                          <Td>
                          {request?.approvalId === "Pending Approval" && (
                            <DropDownActionHelper
                              data={request}
                              onUpdate={setStudyPostponement}
                              onDelete={handleDeletePostponement}
                            />
                          )}
                        </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <StudentStudyPostponementModal closeBtn={closeButton()} />
      </Modal>
    </LayoutHelper>
    </StudyPostponementContext.Provider>
  );
};

StudentStudyPostponement.propTypes = {
  uuid: PropTypes.string,
};

export default StudentStudyPostponement;
