import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, } from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { STUDENT_BOARDING } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useLazyQuery, useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_ITEMS, GET_STUDENT_REQUESTS_AND_ALLOCATIONS } from "../Queries/AccommodationQueries"
import { AccommodationListContext } from "./AccommodationList"

import {
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { showWaringConf } from "helpers/DeleteWaringHelper"

const AccBoardingModal = () => {
  const { isBoardingIn, selectedRequest, setSelectedRequest, openBoardingModal, setOpenBoardingModal, callFetchStudentRequests } = useContext(AccommodationListContext)
  const [board_student] = useMutation(STUDENT_BOARDING, {client: accomodationGraphQLClient})
  const formRef = useRef()
  
  const [selectedDate, setSelectedDate] = useState('');

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const [roomItems, setRoomItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const pagination = { offset: 0, limit: 100, search: null }

  const [getRoomItems, { loading: loadingItems, data: roomItemsData }] = useLazyQuery(GET_ITEMS, {
    client:accomodationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setRoomItems(data.getItems?.data?.items?.map((item) => {
        return {
          value: item.uid,
          label: item.name
        }
      }))
    }
  })

  const handleModalOpened = () => {
    // Reset previous options
    if(!roomItems?.length){
      getRoomItems()
    }
  }

  // A callback to reset form
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      items: [],
      remarks: "",
      isIn: isBoardingIn,
      boardingDate: selectedDate
    },

    validationSchema: Yup.object().shape({
      // boardingDate: Yup.string().required("The boarding date is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      
      const message = `You are about to board ${isBoardingIn? "in" : "out"} a student as follows<br/>
        Sudent: ${selectedRequest?.student?.fullName} | ${selectedRequest?.student?.registrationNumber}<br/>
        Room: ${selectedRequest?.roomAllocation?.roomCapacity?.room?.block?.hostel?.name} | ${selectedRequest?.roomAllocation?.roomCapacity?.room?.code}<br/>
        Items: ${selectedItems.join(", ")}.
        <br/><br/>
        Note: <i style="color:red">This action is irrevasible<i>
      `

      showWaringConf(async () => {
        board_student(
            {
              variables: {
                input: { ...values, roomAllocationUid: selectedRequest?.roomAllocation?.uid },
              },
              // refetchQueries: [
              //   {
              //     query: GET_STUDENT_REQUESTS_AND_ALLOCATIONS,
              //     variables: {
              //       pagination: paginationHelper,
              //     },
              //   },
              // ],
              onCompleted: ({ registerBoarding }) => {
                //updateFunction(client.cache, { data: { registerCountries } });
                if (registerBoarding.code === 8000) {
                  validation.resetForm()
                  setOpenBoardingModal(false)
                  setSelectedRequest(null) // Close the modal
                  callFetchStudentRequests()
                } else {
                  setOpenBoardingModal(true)
                  // setSelectedRequest(true) // Close the modal
                  setSubmitting(false)
                }
                showToast(
                  registerBoarding.message,
                  registerBoarding.code === 8000 ? "success" : "error"
                )
              },
              onError: error => {
                setSubmitting(false)
                // Handle errors
                if(process.env.REACT_APP_DEBUG_MODE){
                  console.error("Mutation error:", error)
                }
              },
            }
          )
        }, "Confirm student boarding", message,'warning', isBoardingIn? "Board in" : "Board out",
        () => {
            setSubmitting(false)
        }, true
      )
    },
  })
  return (
    <div className="text-sm-end mt-3">

      <Modal
        isOpen={openBoardingModal}
        onOpened={handleModalOpened}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Student Boarding Form
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setOpenBoardingModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            
            <div className="row">
              <div className="col-12">

                <div className="row">
                  <div className="col-6 mb-1">
                    <label htmlFor="remarks" className="col-form-label w-100">
                      Student Info <span className="text-danger">*</span>:
                    </label>
                    <Input
                      type="text"
                      value={`${selectedRequest?.student?.fullName} | ${selectedRequest?.student?.registrationNumber}`}
                      placeholder="Student info"
                      disabled
                    />
                  </div>
                  
                  <div className="col-6 mb-1">
                    <label htmlFor="remarks" className="col-form-label w-100">
                      Room Info <span className="text-danger">*</span>:
                    </label>
                    <Input
                      type="text"
                      value={`${selectedRequest?.roomAllocation?.roomCapacity?.room?.block?.hostel?.name} | ${selectedRequest?.roomAllocation?.roomCapacity?.room?.code}`}
                      placeholder="Student Room Info"
                      disabled
                    />
                  </div>
                </div>

                <div className="mb-1">
                  <label htmlFor="remarks" className="col-form-label w-100">
                    Boarding Type <span className="text-danger">*</span>:
                  </label>
                  <Input
                    type="text"
                    value={isBoardingIn? "Boarding In" : "Boarding Out"}
                    placeholder="The boarding type"
                    disabled
                  />
                </div>

                <div className="mb-1">
                  <label htmlFor="boardingDate" className="col-form-label w-100">
                    Boarding Date <span className="text-danger">*</span>:
                  </label>
                  <Input
                    type="date"
                    id="boardingDate"
                    name="boardingDate"
                    value={selectedDate}
                    onChange={handleDateChange}
                    placeholder="Boading Date"
                    required
                  />
                </div>

                <div className="mb-1">
                  <label htmlFor="roomItems" className="col-form-label w-100">
                    {isBoardingIn? "Handed Over Items" : "Returned Items"}:
                  </label>
                  <Select
                    id="roomItems"
                    name="roomItems"
                    placeholder="Select Handed over items"
                    isMulti
                    onChange={selectedOptions => {
                      let selectedAll = false
                      const selected = []
                      const selectedValues  = selectedOptions.map(
                        (option) => {
                          return option.value
                        }
                      )
                      setSelectedItems(selectedOptions.map(
                        (option) => {
                          return option.label
                        }
                      ))

                      validation.setFieldValue("items", selectedValues || [])
                    }}
                    options={roomItems}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                </div>
                
                <div className="mb-1">
                  <label htmlFor="remarks" className="col-form-label w-100">
                    Remarks :
                  </label>
                  <Input
                    type="textarea"
                    id="remarks"
                    name="remarks"
                    placeholder="Enter you remarks"
                    isMulti
                    onChange={e => validation.setFieldValue("remarks", e.target.value)}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setOpenBoardingModal(false)
                setSelectedRequest(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default AccBoardingModal
