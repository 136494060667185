//REGISTE
import { gql } from '@apollo/client';


const GET_ALL_STUDENT_SEMINARS = gql`
  query GetSeminars($pagination: PaginationSeminarInput!) {
    getSeminars(pagination: $pagination){
      status
      code
      message
      data{
          items{
            uid
            studentUid
            fullName
            registrationNumber
            title
            seminarDate
            isPass
            status
            seminarMarks
            seminarTypes{
              uid
              description
              name
            }
          }
          totalCount
        }
    }
  }
`;


export { GET_ALL_STUDENT_SEMINARS }