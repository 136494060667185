import React from 'react';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import PropTypes, {any} from "prop-types";
import {capitalize, toUpper} from "lodash";
const image = require('../assets/images/logo/sua.png');
const coat_of_arms = require('../assets/images/logo/Coat_of_arms.png');
const GenerateTablePDF = (columns,data,subtitle,footer = [], orientation = 'p') => {
    const doc = new jsPDF({
        orientation: orientation === 'l' ? 'landscape':'', // Set to 'portrait' for portrait orientation
    });
    let totalPages = 1;
    // const subtitle = "Student registration"
    const headerStyle = { fontSize: 16, margin: { top: 8 } };
    const addFooter = () => {
        const pageCount = doc.internal.getNumberOfPages();
        totalPages = pageCount;

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        // Draw a line for the footer
        doc.setLineWidth(0.5);
        doc.line(10, 285, 200, 285);

        // Set a smaller font size for the page numbers
        doc.setFontSize(7);
        doc.setFont('sans-serif', 'italic', 'bold');
        doc.text(10, 290, 'Sokoine University of Agriculture (SUA)');
        doc.text(100, 290, 'Printed on ' + formattedDate);

        // Add page numbers
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(190, 290, `Page ${i} of ${totalPages}`);
        }
    };
    // Add the custom header to the PDF
    doc.setFontSize(15);
    // doc.setFont('helvetica', '', 'bold')
    doc.setFont('sans-serif', '', 'bold');
    doc.text("THE UNITED REPUBLIC OF TANZANIA", doc.internal.pageSize.getWidth() / 2, 6, 'center');
    // Add the custom header to the PDF
    doc.setFontSize(13);
    doc.setFont('sans-serif', '', 'bold');
    doc.text("MINISTRY OF EDUCATION,\n" +
        "SCIENCE AND TECHNOLOGY (MoEST)\n" +
        "SOKOINE UNIVERSITY OF AGRICULTURE (SUA)", doc.internal.pageSize.getWidth() / 2, 12, 'center');
    const subtitleX = doc.internal.pageSize.getWidth() / 2;
    doc.setFont('sans-serif', '');
    doc.setFontSize(11);
    doc.text("P.O Box 3000, CHUO KIKUU, MOROGORO, TANZANIA.\n" +
        "Phone: Tel. + 255 23 2603511-4, Fax: + 255 23 2640021\n" +
        "E-mail: vc@sua.ac.tz; Website: https://www.sua.ac.tz", subtitleX, 28, 'center');
    const subtitleY = 43;
    doc.setFontSize(11);
    doc.setFont('sans-serif', '', 'bold');
    doc.text(toUpper(subtitle), subtitleX, subtitleY, 'center');
    const lineY = subtitleY +2; // Adjust the Y position for the line
    const lineLength = doc.getStringUnitWidth(subtitle) * doc.internal.getFontSize() * 1.5;
    for (let i = 0; i < lineLength; i += 2) {
        doc.text('.', subtitleX - lineLength / 2 + i, lineY, 'center', 'S');
    }

    const imgWidth = 30; // Adjust the width of the image as needed
    const imgHeight = 30; // Adjust the height of the image as needed
    let imgX = 175; // Adjust the X position

    if(orientation == 'landscape'){
        imgX = 250;
    }

    let imgY = 1; // Position the image at the same Y coordinate as the header
    doc.addImage(image, 'PNG', imgX, imgY, imgWidth, imgHeight);
    imgX = 8; // Adjust the X position
    imgY = 1; // Position the image at the same Y coordinate as the header
    doc.addImage(coat_of_arms, 'PNG', imgX, imgY, imgWidth, imgHeight);
    doc.setFontSize(10);

    // Data for the table

    const columnStyles = {
        cellPadding: 1, // Adjust the cell padding
        headStyles: { fillColor: [192, 192, 192] }, // Background color for the table header
        tableLineWidth: 0.1, // Adjust the table line width
        cellWidth: 'auto', // Set the cell width to auto
        startY: imgY + imgHeight + 10, // Adjust the startY position to make room for the image
    };


    doc.autoTable({
        head: [columns],
        body: data,
        rowStyles: { fontSize: 12 }, // Font size for the table
        columnStyles:columnStyles,
        margin: { top: 0 }, // Adjust the top margin
        theme: 'grid',
        startY: headerStyle.margin.top + 40, // Adjust the startY position
        didDrawPage: addFooter, // Add footer with page numbers
    });

    if(footer){
        const tableFooterY = doc.autoTable.previous.finalY + 10; // Add some padding

        // Data for the table footer (same number of columns as the data)
        const tableFooterData = footer;

        // Define the column styles for the table footer
        const footerColumnStyles = {
            0: { cellWidth: 60,fontStyle:'bold' }, // Adjust the width for the first footer column
            1: { cellWidth: 60, fontStyle: 'bold' }, // Adjust the width for the second footer column
        };

        // Create the table footer
        doc.autoTable({
            body: tableFooterData,
            startY: tableFooterY,
            theme: 'grid',
            columnStyles: footerColumnStyles,
        });
    }


    // window.open(doc.output('bloburl'));
    doc.save(`${subtitle}.pdf`);
};

export {GenerateTablePDF}