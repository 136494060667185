// export default RoomAllocationList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, UncontrolledDropdown, DropdownMenu, DropdownToggle, Button } from "reactstrap"
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_ACCOMMODATION_CONTROL_NUMBER, GET_ACCOMMODATION_FEE_STRUCTURE, GET_ROOM_ALLOCATIONS, GET_STUDENT_ROOM_ALLOCATIONS, GET_STUDENT_ROOM_REQUESTS, VERIFY_ACCOMMODATION_PAYMENT } from "../Queries/AccommodationQueries"
import { DELETE_COUNTRY } from "../Mutations/AccommodationMutations"
import RoomRequestModal from "./StudentRequestModal"
import Moment from 'moment';

import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { accomodationGraphQLClient, cacheGraphQLClient, registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery";
import { GET_PROGRAM_CATEGORY } from "Modules/ProgramCategories/Queries/ProgramCategoryQueries";
import { GET_PROGRAM } from "Modules/Programs/Queries/ProgramQuaries";
import RoomRequestCancelModal from "./RoomRequestCancelModal";


const breadcrumbItem = [
  {
    title: "My Accommodation",
    path: "",
    isActive: true,
  },
]

export const MyAccommodationContext = createContext() //Create data to transfer to child modal

const MyAccommodation = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if(process.env.REACT_APP_DEBUG_MODE){
        console.log("Requesting Page no ")
      }
    },
  })

  const getSuperscript = (number) => {
    switch (number % 10){
      case 1: 
        return "st"
      case 2: 
        return "nd"
      case 3: 
        return "rd"
      default:
        return "th"
    }
  }

  const formatNumber = (number) => (number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))

  const HTMLSuperScriptNumber = ({number}) => (
    <span dangerouslySetInnerHTML={{ __html: number + "<sup>" + getSuperscript(number) + "</sup>" }} />
  );

  const getExpiresAt = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthYear = `${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  
    return (
      <span>
        <HTMLSuperScriptNumber number={day}/> {monthYear}
      </span>
    );
    // <i className="bx bxs-bell bx-xs bx-tada ms-2 text-warning"></i>
    // <i className="bx bxs-bell bx-xs bx-flashing ms-2 text-danger"></i>
  };

  const getBoardingDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthYear = `${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
  
    const now = new Date
    const timeDiffInMilliseconds = date - now;
    const hoursDiff = timeDiffInMilliseconds / (1000 * 60 * 60);

    return (<span><HTMLSuperScriptNumber number={day}/> {monthYear}</span>)
  }

  const [studentInfo, setStudentInfo] = useState({})
  const [studentProgram, setStudentProgram] = useState({})
  const [roomRequests, setRoomRequests] = useState([])

  const requestStatuses = {
    'PENDING': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Pending',
      color: 'warning',
      message: 'Go to accommodation officer for room allocation'
    }, 'SELECTED': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Selected',
      color: 'success',
      message: 'Request Fee & Control number to pay and continue'
    }, 'NOT_SELECTED': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Not Selected',
      color: 'danger',
      message: null
    }, 'CANCELED': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Canceled',
      color: 'danger',
      message: null
    },
  }

  const activeStatuses = ["SELECTED", "PENDING"]
  const cancelStatuses = ["NOT_SELECTED", "CANCELED"]

  const [roomAllocations, setRoomAllocation] = useState(null)
  const [roomAllocationsError, setRoomAllocationsError] = useState(false)
  const [cancelRoomRequest, setCancelRoomRequest] = useState(null)
  const [acceptedRoomRequest, setAcceptedRoomRequest] = useState(null)
  const [failedToGetRequests, setFailedToGetRequests] = useState(true)
  
  const [feeStructure, setFeeStructure] = useState({})
  const [controlNumberInfo, setControlNumberInfo] = useState(null)
  const [paymentMessage, setPaymentMessage] = useState(null)
  const [isPaid, setIsPaid] = useState(false)

  const { loading: studentLoading, refetch: refetchStudent } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username
    },
    fetchPolicy: 'cache-first',
    onCompleted: (results) => {

      const studentData = results?.getUserByUsername?.data
      
      if(!!studentData.student){
        setStudentInfo(studentData)
        loadStudentRoomRequests(
          { 
            variables: { 
              studentUid: studentData.student.uid
            } 
          }
        )
        
        loadStudentRoomAllocations(
          { 
            variables: { 
              studentUid: studentData.student.uid
            } 
          }
        )

        loadStudentProgram(
          { 
            variables: { 
              uid: studentData.student.programmeUid
            } 
          }
        )
      }
    },
  });

  const [loadStudentProgram, { loading: programLoading, error: programError, data: programData },] = useLazyQuery(GET_PROGRAM, {
    client: cacheGraphQLClient,
    onCompleted(data){
      setStudentProgram(data.getProgram?.data)
    }
  })

  const [loadStudentRoomRequests, { loading: requestsLoading, error: requestsError, data: requestsData },] = useLazyQuery(GET_STUDENT_ROOM_REQUESTS, {
    client: accomodationGraphQLClient,
    fetchPolicy: "network-only",
    onCompleted: (results) => {
      const requestsData = results?.getCurrentStudentRoomRequests?.data?? []

      if(results?.getCurrentStudentRoomRequests?.code == 8000){
        setFailedToGetRequests(false)
      } else {
        setFailedToGetRequests(true)
      }

      setRoomRequests(
        requestsData
      )
      
      const cancellables = requestsData.filter((request) => {
        return request.status == "PENDING"
      })

      if(!!cancellables.length){
        setCancelRoomRequest(cancellables[0])
      }

      const acceptedRequests = requestsData.filter((request) => {
        return request.status == "SELECTED" && !request.allocation?.isExpired
      })

      if(!!acceptedRequests.length){
        setAcceptedRoomRequest(acceptedRequests[0])
        if(!acceptedRequests[0].roomAllocation?.isPaid){
          // setLoadingFeeStructure(true)
          // getFeeStructure()
        }
      }

    }, onError(error){
      setFailedToGetRequests(true)
      if(process.env.REACT_APP_DEBUG_MODE){
        console.log("failed to Fetched room requests")
      }
    }
  })

  useEffect(() => {
    if(!!acceptedRoomRequest?.roomAllocation && !acceptedRoomRequest?.roomAllocation?.isPaid && !!studentProgram?.code){
      if(!!acceptedRoomRequest.roomAllocation.expiresAt){
        setLoadingControlNumber(true)
        getControlNumber()
      } else {
        if(!!studentProgram.code){
          setLoadingFeeStructure(true)
          getFeeStructure()
        }
      }
    } //else {
    //   if(!!studentProgram.code){
    //     setLoadingFeeStructure(true)
    //     getFeeStructure()
    //   }
    // }
  }, [acceptedRoomRequest, studentProgram])

  const [loadStudentRoomAllocations, { loading: roomAllocationsLoading, refetch: refetchStudentAllocations },] = useLazyQuery(GET_STUDENT_ROOM_ALLOCATIONS, {
    client: accomodationGraphQLClient,
    fetchPolicy: "network-only",
    onCompleted: (results) => {
      const requestsData = results?.getStudentRoomAllocations?.data?? []

      setRoomAllocation(
        requestsData
      )

    }, onError(error){
      if(process.env.REACT_APP_DEBUG_MODE){
        console.log("failed to Fetched room allocations")
      }
    }
  })

  const [loadingFeeStructure, setLoadingFeeStructure] = useState(false)
  const [loadingControlNumber, setLoadingControlNumber] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(false)

  const [getFeeStructure, { loading: feeLoading, error: feeError, data: feeData, refetch: refetchFeeStructure },] = useLazyQuery(GET_ACCOMMODATION_FEE_STRUCTURE, {
    client: accomodationGraphQLClient,
    variables: {
      input: {
        programCode: studentProgram?.code,
        yearOfStudy: studentInfo?.student?.studyYear,
        programCategory: studentProgram?.programCategory?.shortName,
        studentStatus: studentInfo?.student?.status?.code,
        studentName: studentInfo?.firstName+" "+studentInfo?.middleName+" "+studentInfo?.lastName,
        dialCode: studentInfo?.country?.dialCode,
        registrationNumber: studentInfo?.student?.registrationNumber,
        programName: studentProgram?.name
      }
    },
    fetchPolicy: "network-only",
    onCompleted(data){
      setLoadingFeeStructure(false)
      const feeStructure = data.getAccommodationFeeStructure?.data

      if(!!feeStructure){
        setFeeStructure(feeStructure)
        getControlNumber()
        setLoadingControlNumber(true)
      }
    },
    onError(error){
      setLoadingFeeStructure(false)
    }
  })
  
  const [getControlNumber, { loading: controlNumberLoading, error: controlNumberError, data: controlNumberData, refetch: refetchControlNumber },] = useLazyQuery(GET_ACCOMMODATION_CONTROL_NUMBER, {
    client: accomodationGraphQLClient,
    fetchPolicy: "network-only",
    variables: {
      input: {
        roomRequestUid: acceptedRoomRequest?.uid,
        programCode: studentProgram?.code,
        yearOfStudy: studentInfo?.student?.studyYear,
        programCategory: studentProgram?.programCategory?.shortName,
        studentStatus: studentInfo?.student?.status?.code,
        studentName: studentInfo?.firstName+" "+studentInfo?.middleName+" "+studentInfo?.lastName,
        dialCode: studentInfo?.country?.dialCode,
        registrationNumber: studentInfo?.student?.registrationNumber,
        programName: studentProgram?.name
      }
    },
    onCompleted(data){
      const controlNumberInfo = data.getAccommodationControlNumber?.data

      if(!!controlNumberInfo?.controlNumber){
        setControlNumberInfo(controlNumberInfo)

        if(!feeStructure.amount){
          setFeeStructure({
            amount: controlNumberInfo.amount,
            currency: controlNumberInfo.currency
          })
        }

      }
      setLoadingControlNumber(false)
    },
    onError(error){
      setLoadingControlNumber(false)
    }
  })

  const [verifyPayment, { refetch: refetchPayment },] = useLazyQuery(VERIFY_ACCOMMODATION_PAYMENT, {
    client: accomodationGraphQLClient,
    fetchPolicy: "network-only",
    variables: {
      input: {
        timestamp: Date(),
        roomRequestUid: acceptedRoomRequest?.uid,
        programCode: studentProgram?.code,
        programCategory: studentProgram?.programCategory?.shortName,
        dialCode: studentInfo?.country?.dialCode,
        registrationNumber: studentInfo?.student?.registrationNumber
      }
    },
    onCompleted(data){
      const isPaid = data.verifyAccommodationPayment?.data
      
      if(isPaid){
        // acceptedRoomRequest.roomAllocation.isPaid = isPaid
        refetchStudentAllocations()
        setPaymentMessage(null)
        setIsPaid(true)
      } else {
        setIsPaid(false)
        setPaymentMessage(data.verifyAccommodationPayment?.message)
      }

      setLoadingPayment(false)
    },
    onError(error){
      setLoadingPayment(false)
    }
  })

  return (
    <MyAccommodationContext.Provider
      value={{
        studentInfo,
        studentProgram,
        cancelRoomRequest,
        setCancelRoomRequest
      }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="My Accommodation">
        <Row>
          <Col lg={4}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start border-bottom">
                  <div className="me-2">
                    <h5 className="card-title mb-4 mt-1">
                      <i className="fas fa-hand-holding-water text-primary me-2"></i>
                      My Requests
                    </h5>
                  </div>
                  {
                    (studentLoading || requestsLoading) ||
                    failedToGetRequests ||
                    (!!roomRequests.length && roomRequests.some(request => activeStatuses.includes(request.status))) ? 
                    "" : <RoomRequestModal />
                  }
                </div>

                {
                  studentLoading || requestsLoading?
                    <PlaceHolderLoader rows={2} type="table" columSize={12} />
                  : (!requestsError && roomRequests?.length > 0) ?
                      roomRequests
                        ?.map((roomRequest, index) => (
                          <div 
                            key={"request-" + index} 
                            className={"border card border-" + ((roomRequest.roomAllocation?.isPaid || isPaid)? "info" : requestStatuses[roomRequest.status]?.color)}>
                            
                            {
                              (activeStatuses.includes(roomRequest.status) && !(roomRequest.roomAllocation?.isPaid || isPaid))?
                              (<div className="bg-transparent card-header">
                              <h5 className={"my-0 text-" + requestStatuses[roomRequest.status]?.color}>
                                <i className="mdi mdi-check me-3"></i>{requestStatuses[roomRequest.status]?.title}</h5>
                              </div>)
                              : ""
                            }
                            
                            <div className="card-body">
                              <div className={!activeStatuses.includes(roomRequest.status)? "mt-0 card-title text-danger" : (roomRequest.roomAllocation?.isPaid || isPaid)? "mt-0 card-title text-info" : "mt-0 card-title"}>
                                Accommodation for {roomRequest.academicYear}
                              </div>
                              <p className={!activeStatuses.includes(roomRequest.status)? "card-text text-danger" : (roomRequest.roomAllocation?.isPaid || isPaid)? "card-text text-info" : "card-text"}>
                                {
                                  requestStatuses[roomRequest.status]?.message != null? 
                                    ((roomRequest.roomAllocation?.isPaid || isPaid)?
                                    "Request completed successfully. See `My Rooms`"
                                    :requestStatuses[roomRequest.status]?.message )
                                  : roomRequest.reason
                                }
                                {/*<a className="card-link ms-2 text-secondary" href="#"> see more ...</a>*/}
                              </p>
                            </div>

                            {
                              roomRequest.roomAllocation != null && roomRequest.status == "SELECTED" && !(roomRequest.roomAllocation?.isPaid || isPaid)?
                                (<ul className="list-group list-group-flush">
                                  <li className="list-group-item">
                                    Room {roomRequest.roomAllocation.roomCapacity.room.code}:&nbsp;
                                    {
                                      roomRequest.roomAllocation.roomCapacity.room.floorNumber == 0?
                                      "Ground Floor,"
                                      : <span><HTMLSuperScriptNumber number={roomRequest.roomAllocation.roomCapacity.room.floorNumber}/> Floor,</span>
                                    }&nbsp; 
                                    Block {roomRequest.roomAllocation.roomCapacity.room.block.code},&nbsp; 
                                    {roomRequest.roomAllocation.roomCapacity.room.block.hostel.name}.
                                  </li>
                                  <li className="list-group-item">
                                    Room for: {roomRequest.roomAllocation.roomCapacity.gender}&nbsp;|&nbsp; 
                                    {roomRequest.roomAllocation.roomCapacity.programCategory}&nbsp;|&nbsp;
                                    {roomRequest.roomAllocation.roomCapacity.capacity} students
                                  </li>
                                  <li className="list-group-item">
                                    Accommodation Fee:&nbsp; 
                                    {
                                      !roomRequest.roomAllocation.expiresAt?
                                          loadingFeeStructure?
                                          <span className="text-secondary"><i className="bx bx-loader bx-xs bx-spin ms-2"></i> getting ...</span>
                                          : (
                                            !!feeStructure?.amount?
                                            <strong>{formatNumber(feeStructure.amount) + feeStructure.currency + "/year"}</strong>
                                            : <a className="card-link text-primary ms-2" href="#" 
                                                  onClick={(e) => {
                                                    e.preventDefault(); 
                                                    if(!!studentProgram.uid){
                                                      setLoadingFeeStructure(true) 
                                                      refetchFeeStructure().then(() => {
                                                        setLoadingFeeStructure(false)
                                                      }).catch(()=>{setLoadingFeeStructure(false)}) 
                                                    } else if(!!studentInfo?.student?.programmeUid) {
                                                      setLoadingFeeStructure(true)
                                                      loadStudentProgram(
                                                        { 
                                                          variables: { 
                                                            uid: studentInfo.student.programmeUid
                                                          } 
                                                        }
                                                      ).then(() => {
                                                        setLoadingFeeStructure(false)
                                                      }).catch(()=>{setLoadingFeeStructure(false)}) 
                                                    }
                                                  }}
                                              >Get fee structure</a>
                                          )
                                        : feeStructure?.amount?
                                            <strong>{formatNumber(feeStructure.amount) + feeStructure.currency + "/year"}</strong>
                                          : "---"  
                                    }
                                    
                                  </li>
                                  { 
                                    !!feeStructure?.amount || !!roomRequest.roomAllocation.expiresAt?
                                      <li className="list-group-item">
                                        Control Number:&nbsp;
                                        {
                                          loadingControlNumber?
                                          <span className="text-secondary"><i className="bx bx-loader bx-xs bx-spin ms-2"></i> getting ...</span>
                                          : (
                                            !!controlNumberInfo?.controlNumber?
                                              <strong>{controlNumberInfo.controlNumber}</strong>
                                            : <a className="card-link text-primary ms-2" href="#" 
                                                  onClick={(e) => {
                                                    e.preventDefault(); 
                                                    if(!!studentProgram.uid){
                                                      setLoadingControlNumber(true) 
                                                      refetchControlNumber().then(() => {
                                                        setLoadingControlNumber(false)
                                                      }).catch(()=>{setLoadingControlNumber(false)}) 
                                                    } else if(!!studentInfo?.student?.programmeUid) {
                                                      setLoadingControlNumber(true)
                                                      loadStudentProgram(
                                                        { 
                                                          variables: { 
                                                            uid: studentInfo.student.programmeUid
                                                          } 
                                                        }
                                                      ).then(() => {
                                                        setLoadingControlNumber(false)
                                                      }).catch(()=>{setLoadingControlNumber(false)}) 
                                                    }
                                                  }}
                                              >Get Control No.</a>
                                          )
                                          
                                        }
                                      </li>
                                    : ""
                                  }
                                  {
                                    !!roomRequest.roomAllocation.expiresAt?
                                      (<li className="list-group-item">
                                        Expires at: <strong className="text-secondary">{getExpiresAt(roomRequest.roomAllocation.expiresAt)}</strong> |
                                        {
                                          loadingPayment?
                                            <span className="text-secondary"><i className="bx bx-loader bx-xs bx-spin ms-2"></i> verifying ...</span>
                                          : <a className="card-link text-info ms-2"
                                                    onClick={(e) => {
                                                      e.preventDefault(); 
                                                      setLoadingPayment(true) 
                                                      refetchPayment().then(() => {
                                                        setLoadingPayment(false)
                                                      }).catch(()=>{setLoadingPayment(false)})}}
                                                >Verify Payment</a>}
                                        </li>)
                                    : ""
                                  }
                                  {
                                    paymentMessage != null?
                                      (<li className="list-group-item">
                                        <strong className="text-danger">{paymentMessage}</strong>
                                      </li>)
                                    : ""
                                  }
                                  
                                </ul>)
                              : ""
                            }

                            {
                              roomRequest.status == "PENDING"?
                              (<div className="card-body">
                                <RoomRequestCancelModal/>
                              </div>)
                              : ""
                            }

                          </div>
                        ))
                    : <div className="text-center mt-5 mb-5">
                        {
                          failedToGetRequests?
                            "Could not fetch your requests!"
                          : "No request was found"
                        }
                        <br />
                        <a 
                          className="text-primary" 
                          onClick={(e) => {
                            e.preventDefault(); 
                            if(!studentInfo?.student?.uid){
                              refetchStudent()
                            } else {
                              loadStudentRoomRequests(
                                { 
                                  variables: { 
                                    studentUid: studentInfo.student.uid
                                  } 
                                }
                              )
                            }
                          }}
                        >Retry</a>
                      </div>
                }

              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardBody>
                <div className="d-flex align-items-start border-bottom">
                  <div className="me-2">
                    <h5 className="card-title mb-4 mt-1">
                      <i className="fas fa-person-booth text-primary me-2"></i>
                      My Rooms
                    </h5>
                  </div>
                </div>

                {
                  studentLoading || roomAllocationsLoading?
                    <PlaceHolderLoader rows={2} type="table" columSize={12} />
                  : roomAllocations?.length? (
                      <div className="table-rep-plugin">
                        <div
                          className="mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table-sm table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="1">Academic Year</Th>
                                <Th data-priority="2">Room Details</Th>
                                <Th data-priority="3">Payments Details</Th>
                                <Th data-priority="4">Boarding</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {roomAllocations
                                ?.map((allocation, index) => (
                                  <Tr key={allocation?.uid}>
                                    <Td>{allocation?.roomCapacity?.academicYear}</Td>
                                    <Td>
                                      Room {allocation?.roomCapacity.room.code},&nbsp; 
                                      {
                                        allocation?.roomCapacity.room.floorNumber == 0?
                                        "Ground Floor,"
                                        : <span><HTMLSuperScriptNumber number={allocation?.roomCapacity.room.floorNumber}/> Floor,</span>
                                      }&nbsp; 
                                      Block {allocation?.roomCapacity.room.block.code}<br/>
                                      {allocation?.roomCapacity.room.block.hostel.name}<br/>
                                      For: {allocation?.roomCapacity.gender}&nbsp;|&nbsp; 
                                      {allocation?.roomCapacity.programCategory}&nbsp;|&nbsp;
                                      {allocation?.roomCapacity.capacity} students
                                    </Td>
                                    <Td>
                                      <button type="button" className="btn btn-sm btn-success  btn-label"><i className="bx bx-check label-icon"></i> Paid initial amount </button>
                                    </Td>
                                    <Td>
                                      {
                                        allocation?.boardings && allocation?.boardings?.length?
                                          allocation.boardings.map((boarding, index) => (
                                            <span key={index} className="">
                                              {boarding.isIn? "Boarded In on: " : "Boarded out on: "} 
                                              <strong>{getBoardingDate(boarding.boardingDate)}</strong>
                                              <br/>
                                              {
                                                boarding.items?.length?
                                                  <span>With: <strong>{boarding.items.map((item) => item.name).join(", ")}</strong></span>
                                                : ""
                                              }
                                              {
                                                allocation.boardings.length > (index + 1)?
                                                  <span><br/>--------------<br/></span>
                                                : ""
                                              }
                                            </span>
                                          ))
                                        
                                        : ""
                                      }
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>)
                    : <div className="text-center mt-5 mb-5">
                        Failed to retrive your rooms<br />
                        <a 
                          className="text-primary" 
                          onClick={(e) => {
                            e.preventDefault();
                            if(!studentInfo?.student?.uid){
                              refetchStudent()
                            } else {
                              loadStudentRoomAllocations(
                                { 
                                  variables: { 
                                    studentUid: studentInfo.student.uid
                                  } 
                                }
                              )
                            }
                          }}
                        >Retry</a>
                      </div>
              }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </MyAccommodationContext.Provider>
  )
}

export default MyAccommodation
