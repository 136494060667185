//REGISTE
import { gql } from '@apollo/client';
const GET_SEMESTERS = gql`
  query GetAcademicYearSemesters($pagination: PaginationInput!) {
      getAcademicYearSemesters(pagination: $pagination){
            status
            code
            message
            data{
              items{
                uid
                oddStartDate
                oddEndDate
                evenStartDate
                evenEndDate
                examStartDate
                examTicketDate
                semester
                status
                academicYear{
                  name
                  uid
                }
              }
              totalCount
            }
      }
  }
`;


export { GET_SEMESTERS }