import { gql } from "@apollo/client";
const STUDENT_FINAL_CONFIRMATION= gql`
  mutation StudentSelfRegistration($input:  SelfRegistrationInput!) {
    studentSelfRegistration(input: $input) {
      status
      code
      message
    }
  }
`;

export { STUDENT_FINAL_CONFIRMATION }

