import React, {createContext} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import {useMutation, useQuery} from "@apollo/client";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import PlaceHolderLoader from "../../../../../helpers/PlaceHolderLoader";
import {GET_STUDENT_SEMINAR_TYPE} from "../Queries/StudentDissertationQueries";
import ConceptNote from "./ConceptNote";
import showToast from "../../../../../helpers/ToastHelper";
import {showWaringConf} from "../../../../../helpers/DeleteWaringHelper";
import {
    CREATE_STUDENT_SEMINAR,
    DELETE_STUDENT_SEMINAR_TYPE
} from "../../../../Lookups/SeminarType/Mutations/SeminarTypeMutations";
import {format} from "date-fns";

const breadcrumbItem = [
    {
        title: "Dissertation",
        path: "/students/dissertation",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]
const ViewDissertation = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [submit] = useMutation(CREATE_STUDENT_SEMINAR)
    if(!location.state?.type) navigate('/dissertation')
    const { type,student } = location.state
    const [deleteSeminarType] = useMutation(DELETE_STUDENT_SEMINAR_TYPE)

    const { loading, error, data, refetch } = useQuery(GET_STUDENT_SEMINAR_TYPE, {
        client: registrationGraphQLClient,
        skip:!student?.uid,
        variables: {
            inputs:{
                studentUid:student?.uid,
                seminarTypeUid:type?.uid
            },
        },
    })

    if (loading) {
        return <PlaceHolderLoader columSize={12} type={"rows"} rows={2} />
    }
    const studentSeminarType = data?.getStudentSeminarsByStudentUid?.data
    const seminar = studentSeminarType ? studentSeminarType[0] : false

    const title = `${type?.name}`;
    // console.log(seminar)
    // return false

    const confirmDelete = async () => {
        // console.log('safafw')
        try {
            await deleteSeminarType(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        uid: seminar.uid
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_SEMINAR_TYPE,
                            variables: {
                                inputs:{
                                    studentUid:student?.uid,
                                    seminarTypeUid:type?.uid
                                },
                            },
                        },
                    ],
                    onCompleted: ({removeStudentSeminar}) => {
                        if (removeStudentSeminar.code === 8000) {
                            showToast(removeStudentSeminar.message, "success")
                        } else {
                            showToast(
                                removeStudentSeminar.message,
                                removeStudentSeminar.code === 8000 ? "success" : "error"
                            )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }
    const handleDeleteSeminarType = () => {
        showWaringConf(() => {
                confirmDelete()
            },`Delete ${type.name}`,`Are you sure you need to delete ${type.name}?`,'info','Delete',
            () => {
                // setPayType(null)
            })
    }

    const handleSubmitSeminarType = () => {
        showWaringConf(() => {
                confirmSubmit()
            },`Submit ${type.name}`,`Are you sure you need to submit ${type.name}? You can not cancel or update submitted forms`,'warning','Submit',
            () => {
                // setPayType(null)
            })
    }

    const confirmSubmit = async () => {
        try {
            await submit(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs:{
                            title: seminar.title,
                            description: seminar.description,
                            status:1,
                            uid:seminar.uid,
                            seminarTypesUid:type.uid,
                            studentUid:student?.uid
                        }
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_SEMINAR_TYPE,
                            variables: {
                                inputs:{
                                    studentUid:student?.uid,
                                    seminarTypeUid:type?.uid
                                },
                            },
                        },
                    ],
                    onCompleted: ({registerStudentSeminar}) => {
                        if (registerStudentSeminar.code === 8000) {
                            showToast(registerStudentSeminar.message, "success")
                        } else {
                            showToast(
                                registerStudentSeminar.message,
                                registerStudentSeminar.code === 8000 ? "success" : "error"
                            )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }


  return(
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`${title}`} >
        {studentSeminarType ?
            <>
            {seminar.status === 0 && <ConceptNote  studentSeminarType={seminar}/>}
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center text-uppercase"><h3>Title: {seminar.title}</h3></CardTitle>
                                <CardSubtitle>

                                    <ul style={{listStyleType:"none",marginLeft:'-3%',fontSize:'120%'}}>
                                        <li>
                                            <strong>Status:</strong> {seminar.status === 0 && <span className="badge bg-danger"> Un submitted/Pending ...</span>}
                                            {seminar.status === 1 && <span className="badge bg-danger"> Seminar Date Allocation Pending..</span>}
                                            {seminar.status === 2 && <span className="badge bg-danger"> Seminar Assessment Score Pending..</span>}
                                            {seminar.status === 3 && <span className="badge bg-success"> Seminar Results Published</span>}

                                        </li>
                                        {seminar.status === 2 &&
                                            <li>
                                                <strong>Seminar Date: </strong> <span className="badge bg-success"> {seminar?.seminarDate ? format(new Date(seminar?.seminarDate), 'MMMM dd, yyyy'):''}</span>
                                            </li>
                                        }
                                        {seminar.status > 2 &&
                                            <>
                                                <li>
                                                    <strong>Seminar Score: </strong> <span className="badge bg-success"> {seminar?.seminarMarks}</span>
                                                </li>
                                                <li>
                                                    <strong>Seminar Passed ?: </strong> <span className={`badge bg-${seminar?.isPass ? 'success':'danger'}`}> {seminar?.isPass ? 'YES':'NO'}</span>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </CardSubtitle>
                                <hr/>
                                <div style={{
                                    maxHeight:'700px', overflow: 'auto'
                                }} dangerouslySetInnerHTML={{ __html: seminar.description }}/>
                            </CardBody>
                            <CardFooter>
                                {seminar.status === 0 && <Button onClick={() => handleSubmitSeminarType()} className="btn btn-success float-start">Submit {type.name}</Button>}
                                {seminar.status === 0 && <Button onClick={() => handleDeleteSeminarType()} className="btn btn-danger float-end">Cancel {type.name}</Button>}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </>:<ConceptNote  studentSeminarType={seminar}/>
        }
        {/*<CourseLeaningOutcomes course={course} />*/}
    </LayoutHelper>
  );
}

export default ViewDissertation;