import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_COUNTRY } from "../Mutations/CountryMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_COUNTRY } from "../Queries/CountryQueries"
import { CountryContext } from "./CountryList"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const CountryModal = () => {
  const { updateCountryData, setUpdateCountryData } = useContext(CountryContext)
  const [campus] = useMutation(CREATE_COUNTRY, {client: uaaGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_COUNTRY
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateCountryData ? updateCountryData.name : "",
      code: updateCountryData ? updateCountryData.code : "",
      dialCode: updateCountryData ? updateCountryData.dialCode : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Country code is required e.g Tz.."),
      dialCode: Yup.string().required(" Dial number is required"),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, {setSubmit}) => {
      campus({
        variables: {
          inputs: [{ ...values, uid: updateCountryData?.uid }],
        },
        client:uaaGraphQLClient,
        refetchQueries: [
          {
            query: GET_COUNTRY,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerCountries }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (registerCountries.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateCountryData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateCountryData(true) // Close the modal
            setSubmit(false)
          }
          showToast(
            registerCountries.message,
            registerCountries.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Country
      </Button>
      <Modal
        isOpen={modalOpen || !!updateCountryData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateCountryData ? "Edit Country" : "Add New Country"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="firstname"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ""}
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="dialCode" className="col-form-label">
                Dial number
              </label>
              <Input
                type="text"
                name="dialCode"
                className="form-control"
                id="firstname"
                placeholder="Dial number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.dialCode || ""}
                invalid={
                  validation.touched.dialCode && validation.errors.dialCode
                    ? true
                    : false
                }
              />
              {validation.touched.dialCode && validation.errors.dialCode ? (
                <FormFeedback type="invalid">
                  {validation.errors.dialCode}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateCountryData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default CountryModal
