import React, {useContext, useEffect, useState} from "react"
import PropTypes, {any} from "prop-types";
import {Tbody, td, th, thead, Tr} from "react-super-responsive-table";
import {Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, Row, Table} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import showToast from "../../../../helpers/ToastHelper";
import {useMutation} from "@apollo/client";
import {STUDENT_REGISTER_COURSE} from "../../Mutations/StudentRegistrationMutations";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {GET_STUDENT_COURSE_TO_REGISTER} from "../../Queries/Registration/StudentSemesterRegistrationQuery";
import AlertHelper from "../../../../helpers/AlertHelper";
import {Link} from "react-router-dom";


const CourseWorkResultTable = (props) => {
    const {isLoading,courseWorks} = props
    return (
        <>
            {isLoading ? <div className="text-center"><i className="bx bx-loader fa-3x bx-spin align-middle"></i></div> :
            <>
            {courseWorks &&
                <Row>
                    {courseWorks?.map((course, index) => (
                        <Col xl={4} key={index}>
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <p className="font-16 text-muted mb-2"></p>
                                        
                                        <h5>
                                            <Link to="#" className="text-dark">
                                                {course?.courseName}
                                            </Link>
                                            <hr style={{ width: '100%', height: '2px', backgroundColor: '#63a69f' }} />

                                        </h5>
                                        <h5>
                                            <Link to="#" className="text-dark">
                                                {course?.courseCode}
                                            </Link>
                                            <hr style={{ width: '100%', height: '2px', backgroundColor: '#63a69f' }} />

                                        </h5>
                                    </div>

                                    <div className="table-responsive mt-4">
                                        <table className="table align-middle table-nowrap">
                                            <tbody>
                                            {course?.courseWorkType?.map((type, key) => (
                                                <tr key={"_type_" + key}>
                                                <td style={{width: "50%"}}>
                                                    <p className="mb-0">{type?.type}</p>
                                                </td>
                                                    <td style={{width: "50%"}}>
                                                        <ul className="verti-timeline list-unstyled">
                                                            {type?.scores?.map((marks, key) => (
                                                                <li key={"_marks_" + key} className="event-list">
                                                                    <div className="event-timeline-dot">
                                                                        {marks?.assessmentNumber}
                                                                    </div>
                                                                    <div className="flex-shrink-0 d-flex">
                                                                        <div className="">
                                                                            <div className="font-size-14">
                                                                                {marks?.score}
                                                                                <small> / </small> {marks?.overallMarks}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>


                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            }
            </>

            }
        </>
    );
}
CourseWorkResultTable.propTypes = {
    courseWorks: PropTypes.any,
    isLoading:
    PropTypes.bool.isRequired
};

export default CourseWorkResultTable;