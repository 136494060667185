import { gql } from '@apollo/client';


const CREATE_COURSE = gql`
  mutation RegisterCourses($inputs: [CourseInput!]!) {
    registerCourses(inputs: $inputs) {
        status
        message
        code
         data{
            totalCount
            items{
                 uid
                 code
                 description
                 name
                 offered
                 departmentUid
             }
         }
    }
  }
`

const CREATE_COURSE_LEANING_OUTCOME = gql`
  mutation RegisterCourseLearnOutcome($inputs: CourseLearnOutcomeInput!) {
    registerCourseLearnOutcome(inputs: $inputs) {
        status
        code
        message
         data{
            uid
            learningOutcome
         }
    }
  }
`

const DELETE_COURSE = gql`
  mutation RemoveCourse($uid: String!) {
    removeCourse(uid: $uid) {
      message
      code
      status
    }
  }
`

const CORE_COURSE_REGISTRATION = gql`
  mutation ForceCoreCourseRegistration($input: ForceCoreCourseRegistrationInputNode!) {
    forceCoreCourseRegistration(input: $input) {
      message
      code
      status
      data{
          programName
          programCode
          courseEntryRegistered
          totalCourses
          courseEntryToBeRegistered
          totalStudents
          courseEntryNotRegistered
          courseEntryPushed
        }
    }
  }
`

const DELETE_COURSE_LEANING_OUTCOME = gql`
  mutation RemoveCourseLearnOutcome($uid: String!) {
    removeCourseLearnOutcome(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_COURSE, DELETE_COURSE,DELETE_COURSE_LEANING_OUTCOME,CREATE_COURSE_LEANING_OUTCOME,CORE_COURSE_REGISTRATION }