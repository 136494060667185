import { gql } from "@apollo/client"

const GET_REVIEWERS = gql`
  query getReviewers($pagination: PaginationInput!) {
    getReviewers(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          email
          firstName
          lastName
          middleName
          phoneNumber
          reviewerSpecialist
          title
          uid
          reviewerInstitution {
            uid
            code
            name
          }
        }
      }
    }
  }
`

export { GET_REVIEWERS }
