import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardTitle, UncontrolledAlert } from "reactstrap"
import ResetPassword from "./ResetPassword"
import { uaaRestUrl } from "helpers/UrlHelper"
// ... Other imports ...

const VerifyToken = props => {
  // State to handle API response
  const [verificationResponse, setVerificationResponse] = useState(null)

  const { token } = useParams() // Extract the token from the URL

  useEffect(() => {
    const extractTokenFromURL = () => {
      // Make the GET request to the backend API to verify the token
      fetch(`${uaaRestUrl}/verification/password/reset/${token}`)
        .then(response => response.json())
        .then(data => {
          setVerificationResponse(data) // Update the state with the API response
        })
        .catch(error => {
          console.error("Error while fetching verification:", error)
          setVerificationResponse({
            success: false,
            message: "An error occurred while verifying the token.",
          })
        })
    }

    extractTokenFromURL() // Call the function when the component mounts
  }, [token])

  return (
    <React.Fragment>
      {/* ... Existing code ... */}
      <div className="mt-5 text-center">
        {/* Render the response based on API verification */}
        {verificationResponse ? (
          verificationResponse.status ? (
            <ResetPassword token={token} />
          ) : (
            <p>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Password Reset Feedback{" "}
                  </CardTitle>
                  <div>
                    <UncontrolledAlert
                      color="danger"
                      className="alert-dismissible fade show"
                      role="alert"
                    >
                      <i className="mdi mdi-block-helper me-2"></i>{" "}
                      {verificationResponse.message}
                      <Link to="/forgot-password">
                          Click to Retry
                        </Link>
                    </UncontrolledAlert>
                  </div>
                </CardBody>
              </Card>
            </p>
          )
        ) : (
          <p>Verifying token...</p>
        )}
      </div>
    </React.Fragment>
  )
}

VerifyToken.propTypes = {
  match: PropTypes.object.isRequired,
}

export default withRouter(VerifyToken)
