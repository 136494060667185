import { Route, Routes } from "react-router-dom"
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard"
import Pages404 from "../../Modules/Utility/pages-404"
import React from "react"
import UserRoutes from "../../Modules/Users/Routes/UserRoutes"
import GroupRoute from "../../Modules/Groups/Routes/GroupRoute"
import PermissionRoute from "../../Modules/Permissions/Routes/PermissionRoutes"
import HeadshipRoutes from "../../Modules/Headships/Views/Routes/HeadshipRoutes"
import FailedAccountList from "../../Modules/Users/Views/FailedAccounts/FailedAccountList"
import StaffCategoryRoutes from "../../Modules/StaffCategories/Routes/StaffCategoryRoutes"
import StaffTermsRoutes from "../../Modules/StaffTerms/Routes/StaffTermsRoutes"
import SignaturePadRoutes from "../../Modules/SignaturePad/Routes/SignaturePadRoutes"
import StudentEmailRecovery from "../../Modules/Students/Views/Recovery/StudentEmailRecovery"

const UserManagementRoutes = () => {
  return (
    <Routes>
      <Route path={"dashboard"} element={<AsimsDashboard />} />
      <Route path={"/users/*"} element={<UserRoutes />} />
      <Route path={"/groups/*"} element={<GroupRoute />} />
      <Route path={"/permissions/*"} element={<PermissionRoute />} />
      <Route path={"/headships/*"} element={<HeadshipRoutes />} />
      <Route path={"/failed-accounts"} element={<FailedAccountList />} />
      <Route path={"/staff-categories/*"} element={<StaffCategoryRoutes />} />
      <Route path={"/staff-terms/*"} element={<StaffTermsRoutes />} />
      <Route index={true} path="/signaturepad/*" element={<SignaturePadRoutes />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default UserManagementRoutes