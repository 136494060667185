//REGISTE
import { gql } from '@apollo/client';


const GET_STAFF_TITLE = gql`
  query GetTitles($pagination: PaginationInput!) {
    getTitles(pagination: $pagination){
      status
      code
      message
      data{
        items{
        id
        uid
        name
      }
      totalCount
      }
    }
  }
`;

export { GET_STAFF_TITLE }