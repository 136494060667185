import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { CREATE_STAFF_TERM } from "Modules/StaffTerms/Mutations/StaffTermsMutations"
import { GET_INSTRUCTORS } from "../Queries/ExaminerQueries"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ExaminerContext } from "./ExaminerList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"

const AssignExaminerModal = () => {

  const { updateExaminer, setUpdateExaminer } =
    useContext(ExaminerContext)
  const [StaffTerm] = useMutation(CREATE_STAFF_TERM, {
    client: uaaGraphQLClient,
  })

    const { loading: instructorLoading, error: instructorError, data: instructorData, refetch } = useQuery(GET_INSTRUCTORS, {
    client:uaaGraphQLClient,
    fetchPolicy: 'cache-first'
  })

  const instructors = instructorData?.getInstructors?.data;

  console.log("instructor mbanga: ", instructors)


  const [assigningOption, setAssigningOption] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const options = [
    { label: "Register multiple", value: "internal" },
    { label: "Register External Examiner", value: "external" },
  ]

  const externalExaminer = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      middlename: "",
      surname: "",
      phone: "",
      email: "",
      pfnumber: "",
      supervisorType: "external"
    },

    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("Supervisor first name is required"),
      middlename: Yup.string().required("Supervisor middle name is required"),
      surname: Yup.string().required("Supervisor surname is required"),
      email: Yup.string().required("Supervisor email is required"),
      phone: Yup.string().required("Supervisor phone is required"),
      pfnumber: Yup.string().required("Supervisor PF number is required"),
    }),

      onSubmit: (values, {resetForm}) => {

        console.log(values)
        return
        StaffTerm({
          client: uaaGraphQLClient,
          variables: {
            inputs: [{ ...values, uid: updateExaminer?.uid }],
          },
          refetchQueries: [
            {
              query: GET_STAFF_TERMS,
              variables: {
                pagination: paginationHelper,
              },
            },
          ],
          onCompleted: ({ registerStaffTerms }) => {
            if (registerStaffTerms.code === 8000) {
              resetForm();
              setModalOpen(false);
              setUpdateExaminer(false); // Close the modal
            } else {
              setModalOpen(true);
              setUpdateExaminer(true); // Close the modal
            }
            showToast(
              registerStaffTerms.message,
              registerStaffTerms.code === 8000 ? "success" : "error"
            );
          },
          onError: error => {
            // Handle errors
            console.error("Mutation error:", error);
          },
        });
      },
  })

  const internalExaminer = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userUid: [],
      supervisorType: "internal"
    },

    validationSchema: Yup.object().shape({
        userUid: Yup.array().required("Supervisor first name is required"),
    }),

      onSubmit: (values, {resetForm}) => {

        console.log(values)
        return;
        StaffTerm({
          client: uaaGraphQLClient,
          variables: {
            inputs: [{ ...values, uid: updateExaminer?.uid }],
          },
          refetchQueries: [
            {
              query: GET_STAFF_TERMS,
              variables: {
                pagination: paginationHelper,
              },
            },
          ],
          onCompleted: ({ registerStaffTerms }) => {
            if (registerStaffTerms.code === 8000) {
              resetForm();
              setModalOpen(false);
              setUpdateExaminer(false); // Close the modal
            } else {
              setModalOpen(true);
              setUpdateExaminer(true); // Close the modal
            }
            showToast(
              registerStaffTerms.message,
              registerStaffTerms.code === 8000 ? "success" : "error"
            );
          },
          onError: error => {
            // Handle errors
            console.error("Mutation error:", error);
          },
        });
      },
  })

  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Add Examiner
      </Button>
      <Modal
        isOpen={modalOpen || !!updateExaminer}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateExaminer
              ? "Edit Examiner "
              : "Examiner Registration"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
                setModalOpen(false)
                setUpdateExaminer(false)
                internalExaminer.resetForm()
                externalExaminer.resetForm()
                setAssigningOption(null)
            }}
            aria-label="Close"
          ></button>
        </div>

        <Row>
          <Col md={12}>
            <p className="m-3">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Select Assigning Option
                </label>
                <Select
                  id="outOff"
                  name="outOff"
                  onChange={e => {
                    setAssigningOption(e.value)
                    externalExaminer.resetForm()
                  }}
                  options={options}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
            </p>
          </Col>
        </Row>
        {assigningOption == "internal" ? (
          <Row>
            <Col lg={12}>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  internalExaminer.handleSubmit()
                  return null
                }}
              >
                <div className="modal-body">
                  <div className="alert alert-info">
                    <h5 className="text-muted">Internal Supervisor Registration</h5>
                  </div>
                  <div className="mb-3">
                  <Col lg="12">
                <div className="mb-3">
                  <label>Select Staff</label>
                  <Select

                    name="userUid"
                    onChange={selectedOptions => {
                      const selectedValues = selectedOptions.map(option => ({
                        userUid: option.value,
                      }))
                      internalExaminer.setFieldValue("userUid", selectedValues)
                    }}
                    options={instructors?.map(staff => ({
                      value: staff?.user?.uid,
                      label: staff?.user?.firstName + " " + staff?.user?.lastName                      ,
                    }))}
                    isMulti={true} // Enable multiple selections
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    name="userUid" // Update the field name
                    placeholder="Select Marital Status"
                    type="hidden"
                    rows={2}
                    onChange={internalExaminer.handleChange}
                    onBlur={internalExaminer.handleBlur}
                    value={JSON.stringify(
                      internalExaminer.values.userUid || []
                    )} // Set the value as a JSON string
                  />
                </div>
              </Col>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      setModalOpen(false)
                      setUpdateExaminer(false)
                      internalExaminer.resetForm()
                      externalExaminer.resetForm()
                      setAssigningOption(null)
                    }}
                  >
                    Close
                  </button>
                  <SubmitButtonHelper
                    name="Submit"
                    type="primary"
                    formik={internalExaminer}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        ) : (
          assigningOption == "external" && (
            <Row>
              <Col lg={12}>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    externalExaminer.handleSubmit()
                    return null
                  }}
                >
                  <div className="modal-body">
                    <h5 className="text-muted">
                      External Examiner Registration
                    </h5>
                    <div className="mb-3">
                      <Row>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            First Name
                          </label>
                          <Input
                            type="text"
                            name="firstname"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter First name"
                            onChange={externalExaminer.handleChange}
                            onBlur={externalExaminer.handleBlur}
                            value={
                              externalExaminer.values.firstname ||
                              updateExaminer?.firstname ||
                              ""
                            }
                            invalid={
                              externalExaminer.touched.firstname &&
                              externalExaminer.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {externalExaminer.touched.firstname &&
                          externalExaminer.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {externalExaminer.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Middle Name
                          </label>
                          <Input
                            type="text"
                            name="middlename"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Middle Name"
                            onChange={externalExaminer.handleChange}
                            onBlur={externalExaminer.handleBlur}
                            value={
                              externalExaminer.values.middlename ||
                              updateExaminer?.middlename ||
                              ""
                            }
                            invalid={
                              externalExaminer.touched.middlename &&
                              externalExaminer.errors.middlename
                                ? true
                                : false
                            }
                          />
                          {externalExaminer.touched.middlename &&
                          externalExaminer.errors.middlename ? (
                            <FormFeedback type="invalid">
                              {externalExaminer.errors.middlename}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Surname
                          </label>
                          <Input
                            type="text"
                            name="surname"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Surname"
                            onChange={externalExaminer.handleChange}
                            onBlur={externalExaminer.handleBlur}
                            value={
                              externalExaminer.values.surname ||
                              updateExaminer?.surname ||
                              ""
                            }
                            invalid={
                              externalExaminer.touched.surname &&
                              externalExaminer.errors.surname
                                ? true
                                : false
                            }
                          />
                          {externalExaminer.touched.surname &&
                          externalExaminer.errors.surname ? (
                            <FormFeedback type="invalid">
                              {externalExaminer.errors.surname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            PF Number
                          </label>
                          <Input
                            type="text"
                            name="pfnumber"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter PF Number"
                            onChange={externalExaminer.handleChange}
                            onBlur={externalExaminer.handleBlur}
                            value={
                              externalExaminer.values.pfnumber ||
                              updateExaminer?.pfnumber ||
                              ""
                            }
                            invalid={
                              externalExaminer.touched.pfnumber &&
                              externalExaminer.errors.pfnumber
                                ? true
                                : false
                            }
                          />
                          {externalExaminer.touched.pfnumber &&
                          externalExaminer.errors.pfnumber ? (
                            <FormFeedback type="invalid">
                              {externalExaminer.errors.pfnumber}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Phone Numbr
                          </label>
                          <Input
                            type="text"
                            name="phone"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Phone number"
                            onChange={externalExaminer.handleChange}
                            onBlur={externalExaminer.handleBlur}
                            value={
                              externalExaminer.values.phone ||
                              updateExaminer?.phone ||
                              ""
                            }
                            invalid={
                              externalExaminer.touched.phone &&
                              externalExaminer.errors.phone
                                ? true
                                : false
                            }
                          />
                          {externalExaminer.touched.phone &&
                          externalExaminer.errors.phone ? (
                            <FormFeedback type="invalid">
                              {externalExaminer.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Email
                          </label>
                          <Input
                            type="text"
                            name="email"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Email"
                            onChange={externalExaminer.handleChange}
                            onBlur={externalExaminer.handleBlur}
                            value={
                              externalExaminer.values.email ||
                              updateExaminer?.email ||
                              ""
                            }
                            invalid={
                              externalExaminer.touched.email &&
                              externalExaminer.errors.email
                                ? true
                                : false
                            }
                          />
                          {externalExaminer.touched.email &&
                          externalExaminer.errors.email ? (
                            <FormFeedback type="invalid">
                              {externalExaminer.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        setModalOpen(false)
                        setUpdateExaminer(false)
                        internalExaminer.resetForm()
                        externalExaminer.resetForm()
                        setAssigningOption(null)
                      }}
                    >
                      Close
                    </button>
                    <SubmitButtonHelper
                      name="Submit"
                      type="primary"
                      formik={externalExaminer}
                    />
                  </div>
                </Form>
              </Col>
            </Row>
          )
        )}
      </Modal>
    </div>
  )
}

export default AssignExaminerModal
