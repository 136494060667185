// export default DeadlineList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { GET_UPLOAD_RESULTS_DEADLINE } from "../Queries/HoDQueries"
import { DELETE_UPLOAD_RESULT_DEADLINE } from "../Mutations/HoDMutations"
import UploadResultDeadlineModal from "./UploadResultDeadlineModal"

const breadcrumbItem = [
  {
    title: "Deadline List",
    path: "",
    isActive: true,
  },
]

export const DeadlineContext = createContext() //Create data to transfer to child modal

const UploadResultDeadlineComponent = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const [selectedDeadline, setSelectedDeadline] = useState(null) //Get data for deleting
  const [updateDeadlineData, setUpdateDeadlineData] = useState(null) //Get data for editing

  const {
    loading: deadlineLoading,
    error: deadlineErrors,
    data: deadlineData,
  } = useQuery(GET_UPLOAD_RESULTS_DEADLINE, {
    client: registrationGraphQLClient,
    fetchPolicy: "cache-first",
  })


  const [deleteDeadline] = useMutation( DELETE_UPLOAD_RESULT_DEADLINE,{
    client: registrationGraphQLClient,
    variables: { uid: selectedDeadline?.uid },
    refetchQueries: [
      {
        query: GET_UPLOAD_RESULTS_DEADLINE,
      },
    ],
  })

  const handleDeleteDeadline = delDeadline => {
    setSelectedDeadline(delDeadline)
    showDeleteWarning(() => {
      performDeadlineDeletion()
    })
  }

  const performDeadlineDeletion = async () => {
    try {
      const response = await deleteDeadline()
      showToast(
        response.data.removeUploadResultDeadline.message,
        response.data.removeUploadResultDeadline.code === 8000 ? "success" : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (deadlineLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (deadlineErrors) {
    return showToast(error, "error")
  }

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const deadLineResult = deadlineData?.getUploadResultDeadline?.data

  console.log(deadLineResult)

  return (
    <DeadlineContext.Provider
      value={{ updateDeadlineData, setUpdateDeadlineData }}
    >
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Deadline List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    {/* <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col> */}
                    <Col className="col-lg-12 col-sm-12 col-md-12 float-right">
                      <UploadResultDeadlineModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!deadlineLoading && !deadlineErrors && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">End Date</Th>
                            <Th data-priority="1">Department</Th>
                            <Th>Status</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {deadLineResult
                            ?.slice(startIndex, endIndex)
                            .map((Deadline, index) => (
                              <Tr key={Deadline?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{Deadline?.endDate}</Td>
                                <Td>{Deadline?.department}</Td>
                                <Td>
                                  {Deadline?.academicYearSemester?.status === 1 ? (
                                    <>Active</>
                                  ): (
                                    <>Inactive</>
                                  )}
                                </Td>
                                <Td>
                                  <DropDownActionHelper
                                    data={Deadline}
                                    onUpdate={setUpdateDeadlineData}
                                    onDelete={handleDeleteDeadline}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </DeadlineContext.Provider>
  )
}

export default UploadResultDeadlineComponent
