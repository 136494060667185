import { gql } from "@apollo/client"

const GET_OTHER_EXPERIENCES = gql`
  query getOtherExperiencesPaginated($pagination: PaginationInput!) {
    getOtherExperiencesPaginated(pagination: $pagination) {
      code
      message
      status
      data {
        items {
          description
          id
          otherExperienceExtracurricularHobby
          staffUid
          uid
        }
        totalCount
      }
    }
  }
`

export { GET_OTHER_EXPERIENCES }
