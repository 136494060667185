import React, {createContext, useEffect, useState} from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {useLocation, useNavigate} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {GET_STUDENT_MANU_SCRIPT} from "../Queries/StudentDissertationQueries";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import PlaceHolderLoader from "../../../../../helpers/PlaceHolderLoader";
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import DissertationPaperModel from "./DissertationPaperModel";
import PropTypes from "prop-types";
import {paginationHelper} from "../../../../../helpers/Functions/GraphqlUpdateFunction";
import showToast from "../../../../../helpers/ToastHelper";
import {showDeleteWarning} from "../../../../../helpers/DeleteWaringHelper";
import {DELETE_STUDENT_MANUSCRIPT} from "../Mutations/StudentDissertationMutations";
import {useFormik} from "formik";
const breadcrumbItem = [
    {
        title: "Dissertation",
        path: "/students/dissertation",
        isActive: false,
    },
    {
        title: "My Paper",
        isActive: true,
    },
]
export const DissertationPapersContext = createContext() //Create data to transfer to child modal

const DissertationPapers = props => {
    const location = useLocation(), navigate = useNavigate()
    if(!location.state?.student) navigate('/dissertation')
    const [paperData,setPaperData] = useState(false);
    const { student } = location.state
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const { loading, error, data } = useQuery(GET_STUDENT_MANU_SCRIPT, {
        client:registrationGraphQLClient,
        skip: !student?.uid,
        variables: {
            studentUid: student?.uid,
        },
    })

    let initial = {
        uid: ""
    }

    const [finalizeDelete] = useMutation(DELETE_STUDENT_MANUSCRIPT)

    if (loading) {
        return <PlaceHolderLoader rows={5} type="rows" columSize={12} />
    }
    let papers = data?.getStudentManuscriptByStudentUid?.data
    const toggleDropdown = p => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [p.uid]: !prevState[p.uid],
        }))
    }

    const confirmDelete = (uid) => {
        // console.log(delData)
        try {
            finalizeDelete({
                client: registrationGraphQLClient,
                variables: {uid: uid},
                refetchQueries: [
                    {
                        query: GET_STUDENT_MANU_SCRIPT,
                        variables: {
                            studentUid: student?.uid,
                        },
                    },
                ],
            })
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("Error deleting user:", error)
        }
    }

    const handleDelete = (uid) => {
        if (uid) {
            showDeleteWarning(() => {
                confirmDelete(uid)
            })
        } else {
            showToast("Please Select Manuscript to delete", "warning")
        }
    }


    return (
        <DissertationPapersContext.Provider value={{paperData,setPaperData}}>
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Papers">
                {
                    (student?.uid !== undefined) ?
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardSubtitle className="mb-1">
                                            <Row>
                                                <Col className="col-6">
                                                </Col>
                                                <Col className="col-6">
                                                    <DissertationPaperModel student={student}/>
                                                </Col>
                                            </Row>
                                        </CardSubtitle>

                                        {papers && <div className="table-rep-plugin">
                                            <div
                                                className="table-responsive mb-0"
                                                data-pattern="priority-columns"
                                            >
                                                <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped table-bordered"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th data-priority="0">#</Th>
                                                            <Th data-priority="0">Title</Th>
                                                            <Th data-priority="1">Publication Status</Th>
                                                            <Th data-priority="1">Status</Th>
                                                            <Th data-priority="2">Action</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {papers?.map((p, index) => (
                                                            <Tr key={p?.uid}>
                                                                <Th>
                                                                    {index+1}
                                                                </Th>
                                                                <Th>
                                                                    {p?.title}
                                                                </Th>
                                                                <Th>
                                                                    {p?.publicationStatus}
                                                                </Th>
                                                                <Th>
                                                                    {p?.status === 0 && "Pending..."}
                                                                    {p?.status === 1 && "Submitted..."}
                                                                    {p?.status === 2 && "Approved..."}
                                                                </Th>
                                                                <Th>
                                                                    <Dropdown
                                                                        isOpen={dropdownOpen[p.uid]}
                                                                        toggle={() => toggleDropdown(p)}
                                                                    >
                                                                        <DropdownToggle color="" caret>
                                                                            <i
                                                                                className="fas fa-ellipsis-v"
                                                                                style={{ color: "blue" }}
                                                                            />
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {p?.status === 0 &&
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    setPaperData(p)
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className="fas fa-user-edit"
                                                                                    style={{
                                                                                        color: "blue",
                                                                                        marginRight: "10px",
                                                                                    }}
                                                                                />
                                                                                <span>Edit</span>
                                                                            </DropdownItem>
                                                                            }
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    navigate("/student/dissertation/papers/view", {
                                                                                        state: { paper: p,student:student },
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className="fas fa-th-list"
                                                                                    style={{
                                                                                        color: "primary",
                                                                                        marginRight: "10px",
                                                                                    }}
                                                                                />
                                                                                <span>Detail</span>
                                                                            </DropdownItem>
                                                                            {p?.status === 0 &&
                                                                                <DropdownItem
                                                                                    onClick={() => {
                                                                                        handleDelete(p.uid)
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        className="fas fa-trash"
                                                                                        style={{
                                                                                            color: "red",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                    />
                                                                                    <span>Delete</span>
                                                                                </DropdownItem>
                                                                            }

                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </Th>

                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>


                                            </div>
                                        </div>}


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        : <CardBody></CardBody>
                }
            </LayoutHelper>
        </DissertationPapersContext.Provider>
    );
};

DissertationPapers.propType = {
    uuid:PropTypes.string
}
export default DissertationPapers;