import React, { createContext, useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import Select from "react-select"
import { Link } from "react-router-dom"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { UploadResultFromMoodleContext } from "./UploadResultFromMoodleView"
import { GET_MOODLE_GRADING_METHODS, GET_MOODLE_QUIZES_BY_COURSE } from "Modules/Instructor/Queries/MoodleQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"

const SecondStep = props => {
  const { toggleTab, courseData } = useContext(UploadResultFromMoodleContext)

  const courseName =
    courseData?.getCourseAllocation?.data?.programCourse?.course

  const [isSubmitted] = useState(false)

  const [selectedQuize, setSelectedQuize] = useState(null);
  const [selectedGradingMethod, setSelectedGradingMethod] = useState(null);

  props.onQuizeChange(selectedQuize)

  props.onGradingMethodChange(selectedGradingMethod)

  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(3) // Move to the next tab
    } else {
      toggleTab(2) // Move to the next tab
    }
  }

  const {
    loading: quizeLoading,
    error: quizeError,
    data: quizeData,
  } = useQuery(GET_MOODLE_QUIZES_BY_COURSE, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        courseMoodleId: courseName?.moodleId,
      },
    },
  })

  const {
    loading: gradingMethodLoading,
    error: gradingMethodError,
    data: gradingMethodData,
  } = useQuery(GET_MOODLE_GRADING_METHODS, {
    client: registrationGraphQLClient,
  })

  const handlePrevious = () => {
    toggleTab(1) // Move to the previous tab
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      quize: "",
      gradingMethod: ""
    },

    validationSchema: Yup.object().shape({
      quize: Yup.string().required("Quize type is required"),
      gradingMethod: Yup.string().required("Grading method is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      const postData = {
        quize: values?.quize,
      }
      toggleTab(3)
      console.log("Student data :", postData)
      return
    },
  })

  if (quizeLoading || gradingMethodLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  const quizes = quizeData?.getMoodleQuizzesByCourse?.data?.quizzes
  const gradingMethods = gradingMethodData?.getMoodleGradingMethod?.data

  return (
    <div className="wizard">
      <div className="modal-body">
        { quizes == null || gradingMethods == null ? (
          <Row>
            <Col md={12} lg={12} sm={12}>
              <div className="alert alert-danger">
                <p>
                  It seems you have not defined any quizzes or tests for{" "}
                  <strong>{courseName?.name}</strong>
                </p>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return null
                }}
              >
                <Row>
                  <Col md={12} lg={12} sm={12}>
                    <div className="alert alert-success">
                      <p>
                        Quizes for course <strong>{courseName?.name}</strong>{" "}
                        from Moodle retrieved successsfully! you can proceed...
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6">
                    <div className="mb-3">
                      <Label>
                        Please choose quize or Test type you want to use
                      </Label>
                      <Select
                        name="quize"
                        onChange={e => {
                          validation.setFieldValue("quize", e.value)
                          console.log("selected", e.value)
                          setSelectedQuize(e.value)
                        }}
                        options={quizes?.map(q => ({
                          value: q?.id,
                          label: q?.name,
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                      />
                      <Input
                        name="quize"
                        placeholder="Select Region"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.quize || ""}
                        invalid={
                          !!(
                            validation.touched.quize && validation.errors.quize
                          )
                        }
                      />
                      {validation.touched.quize && validation.errors.quize ? (
                        <FormFeedback type="invalid">
                          {validation.errors.quize}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col lg="6" md="6">
                    <div className="mb-3">
                      <Label>
                        Please choose Grading Method
                      </Label>
                      <Select
                        name="gradingMethod"
                        onChange={e => {
                          validation.setFieldValue("gradingMethod", e.value)
                          console.log("selected", e.value)
                          setSelectedGradingMethod(e.value)
                        }}
                        options={gradingMethods?.map(q => ({
                          value: q?.id,
                          label: q?.name,
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                      />
                      <Input
                        name="gradingMethod"
                        placeholder="Select Region"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gradingMethod || ""}
                        invalid={
                          !!(
                            validation.touched.gradingMethod && validation.errors.gradingMethod
                          )
                        }
                      />
                      {validation.touched.gradingMethod && validation.errors.gradingMethod ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gradingMethod}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="wizard">
                  <div className="actions">
                    <ul>
                      <li className="previous">
                        <Link to="#" onClick={handlePrevious}>
                          {`< Previous`}
                        </Link>
                      </li>
                      <li className="next" onClick={handleNext}>
                        <SubmitButtonHelper
                          type="primary"
                          name="Next >"
                          formik={validation}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}
export default SecondStep
