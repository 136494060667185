import { gql } from "@apollo/client"

const GET_APPLICATION_WINDOW = gql`
    query getAdmissionWindows($pagination: PaginationInput!) {
        getAdmissionWindows(pagination: $pagination) {
            status
            code
            message
            data {
                items {
                    uid
                    description
                    batchNumber
                    startDate
                    endDate
                    levelType{
                        uid
                        name
                        code
                    }
                    admissionYear {
                        uid
                        name
                        numberOfChoices
                    }
                }
                totalCount
            }
        }
    }
`

export { GET_APPLICATION_WINDOW }
