//REGISTE
import { gql } from "@apollo/client"

const GET_COURSE_ALLOCATION = gql`
  query GetProgramCoursesAssessment($pagination: PaginationInput!) {
    getCourseAllocations(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          staffUid
          programCourse {
            uid
            course {
              uid
              code
              name
            }
          }
        }
        totalCount
      }
    }
  }
`

const GET_STAFF_COURSE_ALLOCATION = gql`
query staffallocations ($inputs: StaffAllocationInputNode!) {
  getStaffCourseAllocation(inputs:$inputs){
     message
     code
     data{
        items{
         uid
         staffUid,
         programCourse {
           uid,
           course {
            code,
            name,
            description,
            offered
          }
           programSemester {
             uid
             program {
               code,
               name,
               tcuCode
             }
             academicYear {
               uid,
               name,
               status,
               startDate
             }
             semester,
             studyYear
             coreCredits,
             electiveCredits
           }
           courseCategory {
             uid,
             name
           }
         }
       }
     }
   }
   }
`

export { GET_COURSE_ALLOCATION, GET_STAFF_COURSE_ALLOCATION }
