import { gql } from "apollo-boost"

const GET_ACCOMMODATION_STATUS = gql`
  query GetAccommodationStatus($input: StudentAccommodationStatusInput!) {
    getAccommodationStatus(input: $input) {
      status
      code
      message
      data
    }
  }
`

export { GET_ACCOMMODATION_STATUS }
