import {useLocation, useNavigate} from "react-router-dom";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Button, Card, CardBody, Col, Form, FormFeedback, Input, InputGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useMutation, useQuery} from "@apollo/client";
import {GET_SPONSORS} from "../../Sponsors/Queries/SponsorQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import showToast from "../../../helpers/ToastHelper";
import {GET_STATUS} from "../../Lookups/Status/Queries/StatusQueries";
import {limit} from "../../../helpers/UrlHelper";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {UPDATE_STUDENT_DETAILS} from "../Mutations/UpdateStudentMutation";
import {GET_STUDENT_BY_UID, GET_STUDENTS} from "../Queries/StudentsQueries";
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader";
import {GET_BANKS} from "../../Banks/Queries/BankQueries";


const breadcrumbItem = [
    {
        title: "students",
        path: "/students",
        isActive: false,
    },    {
        title: "update",
        path: "",
        isActive: true,
    },
]

const UpdateStudentDetails = props => {
    const location = useLocation();
    let { student_uid,search } = location.state;
    const navigate = useNavigate();
    const [updateStudent] = useMutation(UPDATE_STUDENT_DETAILS, {
        client: uaaGraphQLClient,
    })

    const { loading,error,data } = useQuery(GET_STUDENT_BY_UID, {
        client: uaaGraphQLClient,
        variables: {
            uid: student_uid,
        },
        fetchPolicy: "no-cache",
        skip:!student_uid
    })

    const std = data?.getStudent?.data;

    // console.log(loading)

    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            userUid: std?.user?.uid,
            physicalAddress: std?.user?.physicalAddress,
            accountNumber: std?.accountNumber,
            nhifNumber: std?.nhifNumber,
            bankUid: std?.bank?.uid,
            dateOfBirth: std?.user?.dateOfBirth,
            registrationNumber: std?.registrationNumber,
            // sponsorUid: [],
            isDisabled: std?.user?.isDisabled,
            gender:std?.user?.gender,
            statusUid: std?.status?.uid,
            phoneNumber: std?.user?.phoneNumber,
            nin:std?.user?.nin,
            placeOfBirth:std?.user?.placeOfBirth
        },
        validationSchema: Yup.object().shape({
            gender: Yup.string().required("Gender is required"),
            registrationNumber: Yup.string().required("Registration Number is required"),
            // sponsorUid: Yup.array().required("Sponsor is required"),
            statusUid: Yup.string().required("Status is required"),
            dateOfBirth: Yup.string().required("Date Of Birth is required"),
            // email: Yup.string()
            //     .email("Must be a valid Email")
            //     .max(255)
            //     .required("Email is required"),
            phoneNumber: Yup.string().max(15).required("Please Enter Your Number"),

        }),
        onSubmit: (values,{setSubmitting,resetForm}) => {
            // values.sponsorUid = values.sponsorUid.map(item => `${item}`).join(',');
            // console.log(values)
            updateStudent({
                variables: {
                    input: values,
                    userUid:student_uid
                },
                fetchPolicy:'no-cache',
                refetchQueries: [
                    {
                        client: registrationGraphQLClient,
                        query: GET_STUDENTS,
                        variables: {
                            pagination: pagination,
                            search:search
                        },
                    },
                ],
                onCompleted: ({ updateStudentDetails }) => {
                    // setSubmitting(false)
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (updateStudentDetails.code === 8000) {
                        showToast(updateStudentDetails.message, "success")
                        resetForm()
                        setSubmitting(false)
                        navigate('/students',{
                            state:{search:search}
                        })
                    } else {
                        showToast(
                            updateStudentDetails.message,
                            updateStudentDetails.code === 8000 ? "success" : "error"
                        )
                        setSubmitting(false)
                    }
                },
                onError: error => {
                    setSubmitting(false)
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
        }
    });

    const GenderOpt = [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" }
    ];

    const {
        loading: sponsorLoading,
        error: sponsorError,
        data: sponsorData,
    } = useQuery(GET_SPONSORS, {
        client: uaaGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })
    const pagination = { offset: 0, limit: limit, search: null }
    const { loading:statusLoading, error:statusError, data:statusData } = useQuery(GET_STATUS, {
        client: uaaGraphQLClient,
        variables: {
            pagination,
        },
    })

    const { loading:bankLoading, error:bankError, data:bankData } = useQuery(GET_BANKS, {
        client: uaaGraphQLClient,
        variables: {
            pagination,
        },
    })

    const checkStatusInArray = (uid) => {
        return std?.sponsors.some((e) => e.uid.includes(uid))
    };


    const addRemoveSponsor = (e) => {
        const sp = []
        // console.log(e)
        if(e.length > 0){
            for(let i = 0; i < e.length; i++){
                const s = e[i]
                sp.push(s.value)
            }
        }
        validationType.setFieldValue('sponsorUid', sp);
    }


    const sponsors = sponsorData?.getSponsors?.data.items
    const statuses = statusData?.getStatuses?.data.items
    const banks = bankData?.getBanks?.data.items
    // console.log(std?.bank?.uid)

    const selected = []




    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={loading ? 'loading...' : `${std?.user?.firstName} ${std?.user?.middleName} ${std?.user?.lastName} - ${std?.registrationNumber}`} >
            {loading ? <PlaceHolderLoader columSize={12} type={'rows'} rows={4} buttonShown={true} />:
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validationType.handleSubmit();
                                    return false;
                                }}>

                                <div className="row">
                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">First Name</Label>
                                        <Input
                                            name="firstName"
                                            placeholder="first name"
                                            type="text"
                                            //disabled="true"
                                            readOnly={true}
                                            value={std?.user?.firstName}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Middle Name</Label>
                                        <Input
                                            name="middleName"
                                            placeholder="Your middle name"
                                            type="text"
                                            readOnly={true}
                                            value={std?.user?.middleName || ""}
                                        />
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Surname</Label>
                                        <Input
                                            name="lastName"
                                            placeholder="surname"
                                            type="text"
                                            readOnly={true}
                                            value={std?.user?.lastName || ""}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Registration No.</Label>
                                        <Input
                                            name="registrationNumber"
                                            // value={std?.registrationNumber}
                                            placeholder="Registration Number"
                                            type="text"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.registrationNumber}
                                            invalid={
                                                !!(validationType.touched.registrationNumber && validationType.errors.registrationNumber)
                                            }
                                        />
                                        {validationType.touched.registrationNumber && validationType.errors.registrationNumber ? (
                                            <FormFeedback
                                                type="invalid">{validationType.errors.registrationNumber}</FormFeedback>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Email</Label>
                                        <Input
                                            readOnly={true}
                                            name="email"
                                            placeholder="Enter Email"
                                            type="text"
                                            value={std?.user?.email}

                                        />

                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Phone</Label>
                                        <Input
                                            name="phoneNumber"
                                            placeholder="Enter phone number"
                                            type="text"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.phoneNumber}
                                            invalid={
                                                !!(validationType.touched.phoneNumber && validationType.errors.phoneNumber)
                                            }
                                        />
                                        {validationType.touched.phoneNumber && validationType.errors.phoneNumber ? (
                                            <FormFeedback
                                                type="invalid">{validationType.errors.phoneNumber}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label>Gender</Label>
                                        <Select
                                            // value={selectedMulti2}
                                            styles={{
                                                menu: base => ({
                                                    ...base,
                                                    position: "absolute",
                                                    zIndex: 9999
                                                })
                                            }}
                                            defaultInputValue={std?.user?.gender}
                                            onChange={e => {
                                                console.log(e.value)
                                                validationType.setFieldValue("gender", e.value)
                                            }}

                                            options={GenderOpt?.map(st => ({
                                                value: st.value,
                                                label: st.label,
                                            }))}
                                            className="select2-selection"
                                        />
                                        <Input
                                            name="gender"
                                            placeholder="Enter yourAddresses"
                                            type="hidden"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.gender}
                                            invalid={
                                                !!(
                                                    validationType.touched.gender &&
                                                    validationType.errors.gender
                                                )
                                            }
                                        />
                                        {validationType.touched.gender &&
                                        validationType.errors.gender ? (
                                            <FormFeedback type="invalid">
                                                {validationType.errors.gender}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Date Of Birth</Label>
                                        <Input
                                            name="dateOfBirth"
                                            placeholder=""
                                            type="date"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.dateOfBirth}
                                            invalid={
                                                !!(validationType.touched.dateOfBirth && validationType.errors.dateOfBirth)
                                            }
                                        />
                                        {validationType.touched.dateOfBirth && validationType.errors.dateOfBirth ? (
                                            <FormFeedback
                                                type="invalid">{validationType.errors.dateOfBirth}</FormFeedback>
                                        ) : null}
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">NIDA No.</Label>
                                        <Input
                                            name="nin"
                                            placeholder="Enter NIDA number"
                                            type="text"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.nin}
                                            invalid={
                                                !!(validationType.touched.nin && validationType.errors.nin)
                                            }
                                        />
                                        {validationType.touched.nin && validationType.errors.nin ? (
                                            <FormFeedback type="invalid">{validationType.errors.nin}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">NHIF Membership No.</Label>
                                        <Input
                                            name="nhifNumber"
                                            placeholder="Enter NHIF membership number"
                                            type="text"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.nhifNumber || ""}
                                            invalid={
                                                !!(validationType.touched.nhifNumber && validationType.errors.nhifNumber)
                                            }
                                        />
                                        {validationType.touched.nhifNumber && validationType.errors.nhifNumber ? (
                                            <FormFeedback
                                                type="invalid">{validationType.errors.nhifNumber}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label>Student Status</Label>
                                        <Select
                                            // value={selectedMulti2}
                                            styles={{
                                                menu: base => ({
                                                    ...base,
                                                    position: "absolute",
                                                    zIndex: 9999
                                                })
                                            }}
                                            defaultInputValue={std?.status?.name}
                                            onChange={e => {
                                                console.log(e.value)
                                                validationType.setFieldValue("statusUid", e.value)
                                            }}

                                            options={statuses?.map(st => ({
                                                value: st.uid,
                                                label: st.name,
                                            }))}
                                            className="select2-selection"
                                            isLoading={statusLoading}
                                        />
                                        <Input
                                            name="statusUid"
                                            placeholder="Enter yourAddresses"
                                            type="hidden"
                                            rows={3}
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.statusUid}
                                            invalid={
                                                !!(
                                                    validationType.touched.statusUid &&
                                                    validationType.errors.statusUid
                                                )
                                            }
                                        />
                                        {validationType.touched.statusUid &&
                                        validationType.errors.statusUid ? (
                                            <FormFeedback type="invalid">
                                                {validationType.errors.statusUid}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                    {/*<div className="col-lg-3 col-12 mb-3">*/}
                                    {/*    <Label>Student Sponsor</Label>*/}
                                    {/*    <Select*/}
                                    {/*        // value={selectedMulti2}*/}
                                    {/*        isMulti={true}*/}
                                    {/*        styles={{*/}
                                    {/*            menu: base => ({*/}
                                    {/*                ...base,*/}
                                    {/*                position: "absolute",*/}
                                    {/*                zIndex: 9999*/}
                                    {/*            })*/}
                                    {/*        }}*/}
                                    {/*        defaultValue={std?.sponsors?.map(st => ({*/}
                                    {/*            value: st.uid,*/}
                                    {/*            label: st.name,*/}
                                    {/*        }))}*/}
                                    {/*        // onChange={e => {*/}
                                    {/*        //     addRemoveSponsor(e)*/}
                                    {/*        // }}*/}

                                    {/*        onChange={selectedOptions => {*/}
                                    {/*            const selectedValues = selectedOptions.map(*/}
                                    {/*                option => ({*/}
                                    {/*                    sponsorUid: option.value,*/}
                                    {/*                })*/}
                                    {/*            )*/}
                                    {/*            validation.setFieldValue("sponsorUid", selectedValues)*/}
                                    {/*        }}*/}


                                    {/*        options={sponsors?.map(st => ({*/}
                                    {/*            value: st.uid,*/}
                                    {/*            label: st.name,*/}
                                    {/*        }))}*/}
                                    {/*        className="select2-selection"*/}
                                    {/*        isLoading={sponsorLoading}*/}
                                    {/*    />*/}

                                    {/*</div>*/}
                                </div>


                                <div className="row">
                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label>Bank Name</Label>
                                        <Select
                                            // value={selectedMulti2}
                                            styles={{
                                                menu: base => ({
                                                    ...base,
                                                    position: "absolute",
                                                    zIndex: 9999
                                                })
                                            }}
                                            defaultInputValue={std?.bank?.name}
                                            onChange={e => {
                                                validationType.setFieldValue("bankUid", e.value)
                                            }}

                                            options={banks?.map(st => ({
                                                value: st.uid,
                                                label: st.name,
                                            }))}
                                            className="select2-selection"
                                            isLoading={bankLoading}
                                        />
                                        <Input
                                            name="bankUid"
                                            placeholder="Enter yourAddresses"
                                            type="hidden"
                                            rows={3}
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.bankUid}
                                            invalid={
                                                !!(
                                                    validationType.touched.bankUid &&
                                                    validationType.errors.bankUid
                                                )
                                            }
                                        />
                                        {validationType.touched.bankUid &&
                                        validationType.errors.bankUid ? (
                                            <FormFeedback type="invalid">
                                                {validationType.errors.bankUid}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Bank Account No.</Label>
                                        <Input
                                            name="accountNumber"
                                            placeholder="Enter Bank Account Number"
                                            type="text"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.accountNumber}
                                            invalid={
                                                !!(validationType.touched.accountNumber && validationType.errors.accountNumber)
                                            }
                                        />
                                        {validationType.touched.accountNumber && validationType.errors.accountNumber ? (
                                            <FormFeedback type="invalid">{validationType.errors.accountNumber}</FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Place Of Birth</Label>
                                        <Input
                                            name="placeOfBirth"
                                            placeholder="Enter place Of Birth"
                                            type="textarea"
                                            rows={3}
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.placeOfBirth}
                                            invalid={
                                                !!(validationType.touched.placeOfBirth && validationType.errors.placeOfBirth)
                                            }
                                        />
                                        {validationType.touched.placeOfBirth && validationType.errors.placeOfBirth ? (
                                            <FormFeedback type="invalid">{validationType.errors.placeOfBirth}</FormFeedback>
                                        ) : null}
                                    </div>


                                    <div className="col-lg-3 col-12 mb-3">
                                        <Label className="form-label">Address</Label>
                                        <Input
                                            name="physicalAddress"
                                            placeholder="Enter Addresses"
                                            type="textarea"
                                            rows={3}
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.physicalAddress}
                                            invalid={
                                                !!(validationType.touched.physicalAddress && validationType.errors.physicalAddress)
                                            }
                                        />
                                        {validationType.touched.physicalAddress && validationType.errors.physicalAddress ? (
                                            <FormFeedback type="invalid">{validationType.errors.physicalAddress}</FormFeedback>
                                        ) : null}
                                    </div>


                                </div>


                                <div className="float-end">
                                    <SubmitButtonHelper type={'primary'} name={'Submit Changes'} formik={validationType} />
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>}
        </LayoutHelper>
    )
}

export default UpdateStudentDetails;