import React, { useContext } from "react"
import {
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "./NhifRegistration" 
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import sweetAlertHelper from 'helpers/SweetAlertHelper';
import { UPDATE_STUDENT_PROFILE } from "Modules/Students/Mutations/UpdateStudentMutation" 
import { REGISTER_NHIF, GET_NHIF_CONTROL_NUMBER } from "../Mutations/NHIFMutation"

import FormAlertHelper from "helpers/FormAlertHelper"

const RequestControlNumberTab = () => {
  const { StudentData } = useContext(StudentProfileContext)

  const { toggleTab } = useContext(StudentProfileContext)

  const [NHIFMutation] = useMutation(GET_NHIF_CONTROL_NUMBER, {
    client: uaaGraphQLClient,
  })

  const handlePrevious = () => {
    toggleTab(1) // Move to the previous tab
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      studentUid: StudentData?.student?.uid,

    },
    onSubmit: ( { resetForm }) => {

      NHIFMutation({
        client: uaaGraphQLClient,
        variables: {
          studentUid: StudentData?.student?.uid,
        },
        onCompleted: ({ registerNhif }) => {
          if (registerNhif.code === 8000) {
            toggleTab(2)
            console.log("Data updated successsfully")
          } else {
            console.log("Failed to update data")
          }
          showToast(
            registerNhif.message,
            registerNhif.code === 8000 ? "success" : "error"
          )

          sweetAlertHelper(registerNhif.message, registerNhif.code === 8000 ? "success" : "error")

          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="wizard">
          <div className="text-center">
            <ul className="list-unstyled">

                  {/* <div>
                  {registerNhif?.message && (
                    <FormAlertHelper
                      color="primary"
                      message={registerNhif.message}
                    />
                  )}
                  </div> */}
            
              <li className="next">
                <SubmitButtonHelper
                  type="primary"
                  name="View NHIF Control Number"
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default RequestControlNumberTab
