import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup,
  Table,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { Link } from "react-router-dom"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import { UploadResultFromMoodleContext } from "./UploadResultFromMoodleView"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { GET_MOODLE_MOODLE_USERS_ATTEMMPTS_ON_QUIZ } from "Modules/Instructor/Queries/MoodleQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { courseMarkedOut, CourseWeights } from "../CourseWeight"
import { UPLOAD_EXAM_SCORE_RESULT_ONLINE } from "Modules/Instructor/Mutations/InstructorMutations"
import { useNavigate } from "react-router-dom"

const ThirdStep = () => {
  const navigate = useNavigate()

  const {
    quizeFromStepTwo,
    gradingMethodFromStepTwo,
    toggleTab,
    courseData,
    assessments,
    uid,
  } = useContext(UploadResultFromMoodleContext)

  // console.log({
  //   "quize: ": quizeFromStepTwo,
  //   gradingMethod: gradingMethodFromStepTwo,
  // })

  const [selectedExamCategory, setSelectedExamCategory] = useState(null)
  const courseWeight = CourseWeights() // course weight
  const markedOut = courseMarkedOut() // Marked out

  const examCategory = assessments.map(assessment => ({
    label: assessment?.examCategory?.name,
    value: assessment?.examCategory?.id,
  }))

  let serialNumber = 1

  const [isSubmitted] = useState(false)

  const handleNext = () => {
    if (isSubmitted) {
      toggleTab(3) // Move to the next tab
    } else {
      toggleTab(2) // Move to the next tab
    }
  }

  const programCourseUID = courseData?.getCourseAllocation?.data?.programCourse
    ? courseData?.getCourseAllocation?.data?.programCourse?.uid
    : ""

  const {
    loading: moodleLoading,
    error: moodleError,
    data: moodleResults,
  } = useQuery(GET_MOODLE_MOODLE_USERS_ATTEMMPTS_ON_QUIZ, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        quizId: quizeFromStepTwo,
        gradingMethod: gradingMethodFromStepTwo,
        programCourseUid: programCourseUID,
      },
    },
  })

  const studentGrades = moodleResults?.getMoodleUsersAttemptsOnQuiz?.data // get each student marks
  console.log("Results: ", studentGrades)

  const handlePrevious = () => {
    toggleTab(2) // Move to the previous tab
  }

  const [uploadOnlineMutation] = useMutation(UPLOAD_EXAM_SCORE_RESULT_ONLINE, {
    client: registrationGraphQLClient,
  }) // result upload mutation

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      marks: studentGrades ? studentGrades : {},
      outOff: "",
      source: "Moodle",
      weight: "", // Change this to a string
      examCategoryId: "",
      assessmentNumber: "",
      programCourseId: courseData?.getCourseAllocation?.data?.programCourse?.id,
    },

    validationSchema: Yup.object().shape({
      examCategoryId: Yup.string().required("Exam category Must be choosen"),
      assessmentNumber: Yup.string().required(
        "Exam assessment number field is required"
      ),
      outOff: Yup.string().required("Exam Out Of field is required"),
      weight: Yup.string().required("Exam Weight field is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      const { marks, ...rest } = values

      const transformedMarks = marks.map(mark => ({
        registrationNumber: mark.registrationNumber,
        score: mark.grade,
      }))

      const transformedData = {
        ...rest, // Include other properties from the input data
        marks: transformedMarks, // Replace marks with the transformed object
      }

      // console.log("posting dataaaa: ", transformedData)
      // validation.resetForm()
      // return

      uploadOnlineMutation({
        variables: {
          inputs: { ...transformedData },
        },
        onCompleted: ({ uploadOnlineScore }) => {
          //updateFunction(client.cache, { data: { registerGroups } });
          if (uploadOnlineScore.code === 8000) {
            validation.resetForm()
            navigate("/examination/instructor/view_submitted_result", {
              state: { uploadScore: uploadOnlineScore },
            })
          } else {
            setSubmitting(false)
            validation.resetForm()
          }
          showToast(
            uploadOnlineScore.message,
            uploadOnlineScore.code === 8000 ? "success" : "error"
          )
          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
          validation.resetForm()
        },
      })
    },
  })

  if (moodleLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="modal-body">
          {studentGrades != "" ? (
            <>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <label htmlFor="name" className="col-form-label">
                      Exam Category
                    </label>
                    <Select
                      id="outOff"
                      name="examCategoryId"
                      onChange={e => {
                        validation.setFieldValue("examCategoryId", e.value)
                        setSelectedExamCategory(e.value)
                      }}
                      options={examCategory.flat()}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                    />
                    <Input
                      type="hidden"
                      name="examCategoryId"
                      className="form-control"
                      id="firstname"
                      placeholder="Enter Out of Mark"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.examCategoryId || ""}
                      invalid={
                        validation.touched.examCategoryId &&
                        validation.errors.examCategoryId
                          ? true
                          : false
                      }
                    />
                    {validation.touched.examCategoryId &&
                    validation.errors.examCategoryId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.examCategoryId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label htmlFor="name" className="col-form-label">
                      Select Assessment Number
                    </label>
                    <Select
                      id="outOff"
                      name="outOff"
                      onChange={e => {
                        validation.setFieldValue("assessmentNumber", e.value)
                      }}
                      options={assessments
                        .filter(
                          assessment =>
                            selectedExamCategory ===
                            assessment?.examCategory?.id
                        )
                        .flatMap(assessment =>
                          Array.from(
                            {
                              // length: assessment?.minimumExams + assessment?.canExceedMinimumBy,  // enabling can exceed by..!
                              length: assessment?.minimumExams,
                            },
                            (_, buttonIndex) => ({
                              label:
                                assessment?.examCategory?.name +
                                " " +
                                (buttonIndex + 1),
                              value: buttonIndex + 1,
                            })
                          )
                        )}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                    />
                    <Input
                      type="hidden"
                      name="assessmentNumber"
                      className="form-control"
                      id="firstname"
                      placeholder="Enter Out of Mark"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.assessmentNumber || ""}
                      invalid={
                        validation.touched.assessmentNumber &&
                        validation.errors.assessmentNumber
                          ? true
                          : false
                      }
                    />
                    {validation.touched.assessmentNumber &&
                    validation.errors.assessmentNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.assessmentNumber}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <label htmlFor="name" className="col-form-label">
                      Select Marked Out Of
                    </label>
                    <Select
                      id="outOff"
                      name="outOff"
                      onChange={e => {
                        validation.setFieldValue("outOff", e.value)
                      }}
                      options={markedOut}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                    />
                    <Input
                      type="hidden"
                      name="outOff"
                      className="form-control"
                      id="firstname"
                      placeholder="Enter Out of Mark"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.outOff || ""}
                      invalid={
                        validation.touched.outOff && validation.errors.outOff
                          ? true
                          : false
                      }
                    />
                    {validation.touched.outOff && validation.errors.outOff ? (
                      <FormFeedback type="invalid">
                        {validation.errors.outOff}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <label htmlFor="weight" className="col-form-label">
                      Result Weight
                    </label>
                    <Select
                      id="weight"
                      name="weight"
                      onChange={e => {
                        validation.setFieldValue("weight", e.value)
                      }}
                      options={courseWeight}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultInputValue="1"
                      defaultValue={parseInt(1)}
                    />
                    <Input
                      type="hidden"
                      name="weight"
                      className="form-control"
                      id="weight"
                      placeholder="Enter Out of Mark"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.weight || ""}
                      invalid={
                        validation.touched.weight && validation.errors.weight
                          ? true
                          : false
                      }
                    />
                    {validation.touched.weight && validation.errors.weight ? (
                      <FormFeedback type="invalid">
                        {validation.errors.weight}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Full Name</th>
                          <th scope="col">Registration Number</th>
                          <th scope="col">Marks Scored</th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {studentGrades?.map(result => (
                          <tr key={result?.fullName}>
                            <td>{serialNumber++}</td>
                            <td>{result?.fullName}</td>
                            <td>{result?.registrationNumber}</td>
                            <td>{result?.grade}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col md="12" lg={12}>
                <div className="alert alert-danger">
                  <strong>
                    There are no students with grades for this course!
                  </strong>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="previous">
                <Link to="#" onClick={handlePrevious}>
                  {`< Previous`}
                </Link>
              </li>
              <li>
                <SubmitButtonHelper
                  type="primary"
                  name="Submit"
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default ThirdStep
