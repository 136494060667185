import React from "react"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import PublicEsbHeader from "components/EsbDashboardLayout/PublicEsbHeader"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"

const ProgramView = () => {
  const location = useLocation()

  let { program } = location.state

  return (
    <>
      <PublicEsbHeader />
      <Container fluid className="mt-4">
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                      style={{ border: "2px solid #63a69f" }}
                    >
                      <thead>
                        <tr>
                          <th data-priority="2">Program Name</th>
                          <th data-priority="3">Max Eq. Students</th>
                          <th data-priority="4">Max Direct Students</th>
                          <th data-priority="5">Capacity</th>
                          <th data-priority="6">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {program && (
                          <tr>
                            <td>{program?.programName}</td>
                            <td className="text-center">
                              {program?.capacityEquivalent}
                            </td>
                            <td className="text-center">
                              {program?.capacityDirect}
                            </td>
                            <td className="text-center">{program?.capacity}</td>
                            <td className="text-justify">
                              {program?.description}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <Link className="btn btn-sm" to="/online/application">
              Go back
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ProgramView
