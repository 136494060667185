import React, {useState, useEffect, createContext} from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Button,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import {useMutation, useQuery} from "@apollo/client"
import { LOGOUT_MUTATION } from "Modules/Authentication/Mutations/LoginMutation"
import showToast from "helpers/ToastHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import idleTimeOut from "../../../Modules/Authentication/IdleTimeOut";
import { jwtDecode } from "jwt-decode"
import {GET_USER_DOCUMENT} from "../../../Modules/Users/Queries/UserQuery";
import ChangePasswordModal from "./ChangePasswordModal";
import MyProfile from "../../../Modules/Students/Views/Profile/MyProfile";
import UpdateUserProfileModel from "./UpdateUserProfileModal";

export const ProfileMenuContext = createContext() //Create data to transfer to child modal

const ProfileMenu = props => {

  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
}
const {idleTimer} = idleTimeOut({ onIdle: handleIdle, idleTime: 1 })
const stay = () => {
    setOpenModal(false)
    idleTimer.reset()
}
const handleLogout = () => {
    setOpenModal(false)
}
  // const data = JSON.parse(localStorage.getItem("authUser"))?.data
  // const userAuthorities = data?.authorities || []

  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken? jwtDecode(accessToken) : null;
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const navigate = useNavigate();
  const userAuth = JSON.parse(localStorage.getItem("authUser"));

  const obj = userAuth?.data

    // console.log(obj.uid)

  const username = obj?.firstName+" "+obj?.lastName
    // console.log(userAuth?.refreshToken)

    const [logout] = useMutation(LOGOUT_MUTATION, {
        client: uaaGraphQLClient,
        variables: { refreshToken: userAuth?.refreshToken },
    })

    // return


    const { loading,error,data } = useQuery(GET_USER_DOCUMENT, {
        client: uaaGraphQLClient,
        variables: {
            userUid: obj?.uid,
            documentType: 'profile'
        },
        fetchPolicy: "cache-first",
        skip:!obj?.uid
    })

    if(data?.getUserDocuments?.data?.base64doc)
        user1 = "data:image/jpeg;base64," +data?.getUserDocuments?.data?.base64doc




    const performLogout = async () => {
    try {
      const response = await logout()
      showToast(response?.data.logout.message, "success")
    } catch (error) {
      console.error("Error logout user:", error)
    }
  }

  const userProfile = () => {
    navigate("/users/user-profile")
  }



    const updateStudentProfilePicture = () => {
        navigate("/students/student-profile")
    }

    const [changePasswordModalOpen, setChangePasswordModal] = useState(false)
    const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false)
    const [updateUserProfileModalOpen, setUpdateUserProfileModalOpen] = useState(false)

  return (
      <ProfileMenuContext.Provider value={{changePasswordModalOpen,setChangePasswordModal,setUpdateProfileModalOpen,updateProfileModalOpen, updateUserProfileModalOpen, setUpdateUserProfileModalOpen}}>
          <React.Fragment>
              <Dropdown
                  isOpen={menu}
                  toggle={() => setMenu(!menu)}
                  className="d-inline-block"
              >
                  <DropdownToggle
                      className="btn header-item "
                      id="page-header-user-dropdown"
                      tag="button"
                  >
                      {loading ? <i className="bx bx-loader fa-2x bx-spin align-middle"></i>:
                          <img
                              className="rounded-circle header-profile-user"
                              src={user1}
                              alt="Header Avatar"
                          />}
                      <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
                      <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                      {hasAuthority("VIEW_STAFF_PROFILE_MENU") && (
                          <DropdownItem tag="a" onClick={() => setUpdateUserProfileModalOpen(true)}>
                              {" "}
                              <i className="bx bx-user font-size-16 align-middle me-1" />
                              {props.t("Profile")}{" "}
                          </DropdownItem>
                      )}
                      {hasAuthority("VIEW_STUDENT_PROFILE_MENU") && (
                          <DropdownItem tag="a" onClick={() => setUpdateProfileModalOpen(true)}>
                              {" "}
                              <i className="bx bxs-user-rectangle font-size-16 align-middle me-1" />
                              {props.t("Update Profile Picture")}{" "}
                          </DropdownItem>
                      )}
                      <DropdownItem tag="a" onClick={() => setChangePasswordModal(true)}>
                          <i className="bx bx-key font-size-16 align-middle me-1" />
                          {props.t("Change Password")}
                      </DropdownItem>
                      <div className="dropdown-divider" />
                      <Link to="/logout" className="dropdown-item" onClick={performLogout}>
                          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                          <span>{props.t("Logout")}</span>
                      </Link>
                  </DropdownMenu>
              </Dropdown>
              <UpdateUserProfileModel />
              <ChangePasswordModal />
              <MyProfile />
              <Modal show={openModal} onHide={stay}>
                  <Modal closeButton>
                      <Modal>Your session is about to expire</Modal>
                  </Modal>
                  <Modal>
                      <p>
                          Your session is about to expire. You'll be automatically signed out.
                      </p>
                      <p>
                          Do you want to stay signed in?
                      </p>
                  </Modal>
                  <Modal>
                      <Button variant="secondary" onClick={handleLogout}>
                          Sign out now
                      </Button>
                      <Button variant="primary" onClick={stay}>
                          Stay signed in
                      </Button>
                  </Modal>
              </Modal>
          </React.Fragment>
      </ProfileMenuContext.Provider>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
