import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { NextOfKinContext } from "./NextOfKinList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { UPDATE_STAFF_NEXT_OF_KIN } from "Modules/Users/Mutations/UserMutations"
import { GET_NEXT_OF_KIN_BY_STAFF } from "Modules/Users/Queries/UserQuery"
import { useMutation, useQuery } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { GET_KINSHIP } from "Modules/Lookups/Kinship/Queries/KinshipQueries"
import { StaffProfileContext } from "../StaffProfile"

const NextOfKinModal = () => {
  const { updateNextOfKinData, setUpdateNextOfKinData } =
    useContext(NextOfKinContext)
  const [nextOfKinMutation] = useMutation(UPDATE_STAFF_NEXT_OF_KIN)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_NEXT_OF_KIN_BY_STAFF
  const variables = { pagination: paginationHelper }
  const registerData = "data"
  const { updateStaffData, setUpdateStaffData } =
    useContext(StaffProfileContext)
  const updateFunction = createUpdateFunction(query, variables, registerData)

  const {
    loading: kinShipLoading,
    error: kinShipError,
    data: kinShipData,
  } = useQuery(GET_KINSHIP, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (catError) return showToast("Failed to fetch Program Categories", "error")
  let kinShipArray = kinShipData?.getKinships?.data?.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: updateNextOfKinData ? updateNextOfKinData.fullName : "",
      phoneNumber: updateNextOfKinData ? updateNextOfKinData.phoneNumber : "",
      placeOfDomicile: updateNextOfKinData
        ? updateNextOfKinData.placeOfDomicile
        : "",
      email: updateNextOfKinData ? updateNextOfKinData.email : "",
      userUid: updateStaffData ? updateStaffData?.uid : "",
      kinshipUid: updateNextOfKinData ? updateNextOfKinData.kinshipUid : "",
    },

    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Full name is required"),
      phoneNumber: Yup.string().required("phone Number is required"),
      kinshipUid: Yup.string().required("kinShip is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      nextOfKinMutation({
        client: uaaGraphQLClient,
        variables: {
          inputs: { userUid: updateStaffData?.uid, ...values },
        },
        refetchQueries: [
          {
            query: GET_NEXT_OF_KIN_BY_STAFF,
            variables: {
              userUid: updateStaffData?.uid,
            },
          },
        ],
        onCompleted: ({ updateUserNextOfKins }) => {
          //updateFunction(client.cache, { data: { updateUserNextOfKins } });
          if (updateUserNextOfKins.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateNextOfKinData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateNextOfKinData(true) //keep the modal
          }
          showToast(
            updateUserNextOfKins.message,
            updateUserNextOfKins.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Next of Kin
      </Button>
      <Modal
        isOpen={modalOpen || !!updateNextOfKinData}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateNextOfKinData ? "Edit Next of Kin" : "Add New Next of Kin"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateNextOfKinData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Row>
                <Col md={6}>
                  <label htmlFor="fullName" className="col-form-label">
                    Full Name:
                  </label>
                  <Input
                    type="text"
                    name="fullName"
                    className="form-control"
                    id="fullName"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.fullName ||
                      updateNextOfKinData?.fullName ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.fullName &&
                        validation.errors.fullName
                      )
                    }
                  />
                  {validation.touched.fullName && validation.errors.fullName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6}>
                  <label>Kin Ship</label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("kinshipUid", e.value)
                    }}
                    name="kinshipUid"
                    options={kinShipArray?.map(pr => ({
                      value: pr.uid,
                      label: pr.name,
                    }))}
                    defaultValue={updateNextOfKinData?.kinshipUid}
                    defaultInputValue={updateNextOfKinData?.kinship?.name}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    type="hidden"
                    name="kinshipUid"
                    className="form-control"
                    id="kinshipUid"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.kinshipUid ||
                      updateNextOfKinData?.kinshipUid ||
                      ""
                    }
                  />
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col md={6}>
                  <label htmlFor="placeOfDomicile" className="col-form-label">
                    Place of Domicile:
                  </label>
                  <Input
                    type="text"
                    name="placeOfDomicile"
                    className="form-control"
                    id="placeOfDomicile"
                    placeholder="Place of Domicile"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.placeOfDomicile ||
                      updateNextOfKinData?.placeOfDomicile ||
                      ""
                    }
                  />
                  <Input
                    type="hidden"
                    name="userUid"
                    className="form-control"
                    id="userUid"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.userUid ||
                      updateNextOfKinData?.userUid ||
                      ""
                    }
                  />
                </Col>

                <Col md={6}>
                  <label htmlFor="phoneNumber" className="col-form-label">
                    Phone:
                  </label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.phoneNumber ||
                      updateNextOfKinData?.phoneNumber ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.phoneNumber &&
                        validation.errors.phoneNumber
                      )
                    }
                  />
                  {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phoneNumber}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="col-form-label">
                Email:
              </label>
              <Input
                type="text"
                name="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.email || updateNextOfKinData?.email || ""
                }
                invalid={
                  !!(validation.touched.email && validation.errors.email)
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateNextOfKinData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default NextOfKinModal
