import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import ApplicationWindowList from "../Views/ApplicationWindowList";

const ApplicationWindowRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ApplicationWindowList />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default ApplicationWindowRoutes;