//REGISTE
import { gql } from "@apollo/client"

const GET_DEPARTMENT = gql`
  query GetDepartments($pagination: PaginationInput!) {
    getDepartments(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`

export { GET_DEPARTMENT }
