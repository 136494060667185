
import React from "react";
import { Route, Routes } from "react-router";
import NhifRegistration from "../Views/NhifRegistration";
import Pages404 from "Modules/Utility/pages-404";

const NhifRoutes = (props) => {

    return (
        <Routes>
            <Route path="/" element={<NhifRegistration />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );

}

export default NhifRoutes;