import React from 'react'
import {Route, Routes} from "react-router-dom";
// import CollegeList from '../Views/CollegeList';
import Pages404 from "../../Utility/pages-404";
import CollegeList from "../Views/CollegeList";
const CollegeRoutes = () => {
    return (
        <Routes>
            <Route  path="/" element={<CollegeList />}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default CollegeRoutes