import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik, Field } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { Link } from "react-router-dom"
import { GET_COUNTRY } from "Modules/Country/Queries/CountryQueries"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "../SelfRegistration"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { UPDATE_STUDENT_CONTANTS } from "Modules/Students/Mutations/UpdateStudentMutation"
import { GET_REGIONS_BY_COUNTRY } from "Modules/Regions/Queries/RegionQueries"
import { GET_DISTRICT_BY_REGION } from "Modules/Districts/Queries/DistrictQueries"
import { GET_BANKS } from "Modules/Banks/Queries/BankQueries"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { limit } from "helpers/UrlHelper"
import { GET_SPONSORS } from "Modules/Sponsors/Queries/SponsorQueries"

const StudentContactDetails = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const { updateStudentData, setUpdateStudentData } = useContext(
    StudentProfileContext
  )

  const { toggleTab, handleSubmit } = useContext(StudentProfileContext)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedSponsors, setSelectedSponsors] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('')
  const [Student] = useMutation(UPDATE_STUDENT_CONTANTS, {
    client: uaaGraphQLClient,
  })

  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(4) // Move to the next tab
    } else {
      toggleTab(3) // Move to the next tab
    }
  }

  // Get Countries
  const {
    loading: countryLoading,
    error: countryError,
    data: countryData,
  } = useQuery(GET_COUNTRY, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const countries = countryData?.getCountries?.data?.items

  const handlePrevious = () => {
    toggleTab(2) // Move to the previous tab
  }


  // console.log("student data", updateStudentData?.student?.district?.region?.country?.uid)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      countryUid: updateStudentData?.student?.district?.region?.country ? updateStudentData?.student?.district?.region?.country?.uid : "" ,
      regiodUid: updateStudentData?.student?.district?.region ? updateStudentData?.student?.district?.region?.uid : "",
      postalAddress: updateStudentData ? updateStudentData?.postalAddress : "",
      physicalAddress: updateStudentData
        ? updateStudentData?.physicalAddress
        : "",
      districtUid: updateStudentData?.student?.district ? updateStudentData?.student?.district?.uid : "",
      bankUid: updateStudentData?.student?.bank ? updateStudentData?.student?.bank?.uid : "",
      accountNumber: updateStudentData?.student ? updateStudentData?.student?.accountNumber : "",
      sponsors: updateStudentData ? updateStudentData?.sponsors : "",
    },

    validationSchema: Yup.object().shape({
      postalAddress: Yup.string().nullable().required("Postal address is required"),
      physicalAddress: Yup.string().nullable().required(
        "Your physical address is required"
      ),
      accountNumber: Yup.string().nullable().required("Bank Account number is required"),
      countryUid: Yup.string().required("Country name is required"),
      regiodUid: Yup.string().required("Region name is required"),
      districtUid: Yup.string().required("District name is required"),
      bankUid: Yup.string().required("Bank name is required"),
      // sponsorUid: Yup.string().required("Sponsor name is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      const postData = {
        districtUid: values.districtUid,
        accountNumber: values.accountNumber,
        bankUid: values.bankUid,
        postalAddress: values.postalAddress,
        physicalAddress: values.physicalAddress,
        sponsors: values.sponsors,
      }
      Student({
        client: uaaGraphQLClient,
        variables: {
          input: { ...postData, userUid: updateStudentData?.uid },
        },
        onCompleted: ({ updateStudentContact }) => {
          if (updateStudentContact.code === 8000) {
            toggleTab(4)
            // setIsSubmitted(true)
          } else {
            // console.log("Failed to update data")
          }
          showToast(
            updateStudentContact.message,
            updateStudentContact.code === 8000 ? "success" : "error"
          )

          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const {
    loading,
    error,
    data: regionData,
    refetch,
  } = useQuery(GET_REGIONS_BY_COUNTRY, {
    client: uaaGraphQLClient,
    skip: !selectedCountry,
    variables: {
      countryUid: selectedCountry,
    },
    fetchPolicy: "cache-and-network"
  })

  const {
    loading: districtLoading,
    error: districtError,
    data: districtData,
    refetch: districtRefetch,
  } = useQuery(GET_DISTRICT_BY_REGION, {
    client: uaaGraphQLClient,
    skip: !selectedRegion,
    variables: {
      regionUid: selectedRegion,
    },
    fetchPolicy: "cache-and-network"
  })

  const {
    loading: banksLoading,
    error: bankError,
    data: bankData,
    refetch: bankrefetch,
  } = useQuery(GET_BANKS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const {
    loading: sponsorLoading,
    error: sponsorkError,
    data: sponsorData,
    refetch: sponsorrefetch,
  } = useQuery(GET_SPONSORS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })
  

  const regions = regionData?.getRegionsByCountry?.data // get regions from country selected

  const districts = districtData?.getDistrictsByRegion?.data // get districts from region selcted

  const banks = bankData?.getBanks?.data?.items // get banks list

  const sponsors = sponsorData?.getSponsors?.data?.items // get sponsors list

  return (
    <div className="mt-3 wizard">
      <Form  onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}>
        <Row>
          <Col lg="4">
            <div className="mb-3">
              <Label>Country</Label>
              <Select
                name="countryUid"
                onChange={e => {
                  validation.setFieldValue("countryUid", e.value);
                  setSelectedCountry(updateStudentData?.country?.uid ? updateStudentData?.country?.uid : e.value);
                }}
                options={countries?.map(dp => ({
                  value: dp?.uid,
                  label: dp?.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                defaultValue={updateStudentData?.student?.district?.region?.country?.uid}
                defaultInputValue={updateStudentData?.student?.district?.region?.country?.name}
              />
              <Input
                name="countryUid"
                placeholder="Select Region"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.countryUid ||
                  updateStudentData?.countryUid ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.countryUid &&
                    validation.errors.countryUid
                  )
                }
              />
              {validation.touched.countryUid && validation.errors.countryUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.countryUid}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="4">
            <div className="mb-3">
              <Label>Region</Label>
              <Select
                onChange={e => {
                  validation.setFieldValue("regiodUid", e.value)
                  setSelectedRegion(e.value)
                }}
                name="regiodUid"
                options={regions?.map(dp => ({
                  value: dp?.uid,
                  label: dp?.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                defaultValue={updateStudentData?.student?.district?.region?.uid}
                defaultInputValue={updateStudentData?.student?.district?.region?.name}
              />
              <Input
                name="regiodUid"
                placeholder="Select Region"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.regiodUid ||
                  updateStudentData?.regiodUid ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.regiodUid && validation.errors.regiodUid
                  )
                }
              />
              {validation.touched.regiodUid && validation.errors.regiodUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.regiodUid}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="4">
            <div className="mb-3">
              <Label>District</Label>
              <Select
                onChange={e => {
                  validation.setFieldValue("districtUid", e.value)
                }}
                name="districtUid"
                options={districts?.map(dp => ({
                  value: dp?.uid,
                  label: dp?.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}

                defaultValue={updateStudentData?.student?.district?.uid}
                defaultInputValue={updateStudentData?.student?.district?.name}
              />
              <Input
                name="districtUid"
                placeholder="Select District"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.districtUid ||
                  updateStudentData?.student?.district?.uid ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.districtUid &&
                    validation.errors.districtUid
                  )
                }
              />
              {validation.touched.districtUid &&
              validation.errors.districtUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.districtUid}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <div className="mb-3">
              <Label>Bank name</Label>
              <Select
                onChange={e => {
                  validation.setFieldValue("bankUid", e.value)
                }}
                name="bankUid"
                options={banks?.map(bank => ({
                  value: bank?.uid,
                  label: bank?.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                defaultValue={updateStudentData?.student?.bank?.uid}
                defaultInputValue={updateStudentData?.student?.bank?.name}
              />
              <Input
                name="bankUid"
                placeholder="Select Region"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.bankUid || updateStudentData?.bankUid || ""
                }
                invalid={
                  !!(validation.touched.bankUid && validation.errors.bankUid)
                }
              />
              {validation.touched.bankUid && validation.errors.bankUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.bankUid}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg="4">
            <div className="mb-3">
              <Label for="accountNumber">Bank Account Number</Label>
              <Input
                name="accountNumber"
                placeholder="Enter Bank Account Number"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.accountNumber || ""}
                invalid={
                  !!(
                    validation.touched.accountNumber &&
                    validation.errors.accountNumber
                  )
                }

                defaultValue={updateStudentData?.student?.accountNumber}
              />
              {validation.touched.accountNumber &&
              validation.errors.accountNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.accountNumber}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col lg="4">
            <div className="mb-3">
              <label>Select Financial Sponsor</label>
              <Select
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions.map(option => ({
                    sponsorUid: option.value,
                  }))
                  validation.setFieldValue("sponsors", selectedValues)
                }}
                name="sponsors"
                options={sponsors?.map(sponsor => ({
                  value: sponsor?.uid,
                  label: sponsor?.name,
                }))}
                isMulti={true} // Enable multiple selections
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
              />
              <Input
                name="sponsors" // Update the field name
                placeholder="Select Financial Sponsor"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={JSON.stringify(
                  validation.values.sponsors ||
                    updateStudentData?.sponsors ||
                    []
                )} // Set the value as a JSON string
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label for="postalAddress">Postal Address</Label>
              <Input
                name="postalAddress"
                placeholder="Enter your postal address"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.postalAddress || ""}
                invalid={
                  !!(
                    validation.touched.postalAddress &&
                    validation.errors.postalAddress
                  )
                }
                defaultValue={updateStudentData?.student?.bank?.uid}
              />
              {validation.touched.postalAddress &&
              validation.errors.postalAddress ? (
                <FormFeedback type="invalid">
                  {validation.errors.postalAddress}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="physicalAddress">Physical Address</Label>
              <Input
                name="physicalAddress"
                placeholder="Enter your physical address"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.physicalAddress || ""}
                invalid={
                  !!(
                    validation.touched.physicalAddress &&
                    validation.errors.physicalAddress
                  )
                }
              />
              {validation.touched.physicalAddress &&
              validation.errors.physicalAddress ? (
                <FormFeedback type="invalid">
                  {validation.errors.physicalAddress}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="previous">
                <Link to="#" onClick={handlePrevious}>
                  {`< Previous`}
                </Link>
              </li>
              <li className="next" onClick={handleNext}>
                <SubmitButtonHelper
                  type="primary"
                  name="Next >"
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default StudentContactDetails
