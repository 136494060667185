import { gql } from '@apollo/client';


const CREATE_SEMESTER = gql`
  mutation RegisterAcademicYearSemester($inputs: [AcademicYearSemesterInput!]!) {
      registerAcademicYearSemester(inputs:$inputs){
        status
        code
        message
      }
  }
`
const DELETE_SEMESTER = gql`
  mutation RemoveAcademicYearSemester($uid: String!) {
    removeAcademicYearSemester(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_SEMESTER, DELETE_SEMESTER }