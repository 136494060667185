import { gql } from "@apollo/client"

const CREATE_STAFF_TITLE = gql`
  mutation RegisterTitle($inputs: [TitleDtoInput!]!) {
    registerTitles(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_STAFF_TITLE = gql`
  mutation RemoveTitle($uid: String!) {
    removeTitle(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_STAFF_TITLE, DELETE_STAFF_TITLE }
