//REGISTE
import { gql } from '@apollo/client';


const GET_GRADE_POINTS = gql`
query GetGradePoint($pagination: PaginationInput!) {
  getGradePoints(pagination: $pagination){
    status
    code
    message
    data{
      items{
        uid
        grade
        gradePoint
        qualificationMethod{
            uid
            name
            code
        }
      }
      totalCount
    }
  }
}
`;

const GET_QUALIFICATION_METHODS = gql`
query GetQualificationMethod{
  getQualificationMethod{
    status
    code
    message
    data{
        uid
        name
        code
      }
    }
  }
`;

export { GET_GRADE_POINTS, GET_QUALIFICATION_METHODS }