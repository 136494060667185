import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { Link } from "react-router-dom"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "./SelfRegistration"
import { GET_DISABILITIES } from "Modules/Lookups/Dissability/Queries/DisabilityQueries"
import { GET_MARITAL_STATUSES } from "Modules/MaritalStatus/Queries/MaritalStatusesQueries"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { UPDATE_STUDENT_PROFILE } from "../Mutations/UpdateStudentMutation"
import { token } from "helpers/UrlHelper"
import verticalLayout from "../../../components/VerticalLayout";

const StudentPersonalInformation = () => {
  const { updateStudentData } = useContext(StudentProfileContext)

  const { toggleTab } = useContext(StudentProfileContext)
  const [isSubmitted] = useState(false)
  const [studentDissability, setStudentDissability] = useState(false)
  const [selectedValue, setSelectedValue] = useState(updateStudentData?.isDisabled || false);
  const [selectedLabel, setSelectedLabel] = useState(updateStudentData?.isDisabled ? "Yes" : "No");

  const [Student] = useMutation(UPDATE_STUDENT_PROFILE, {
    client: uaaGraphQLClient,
  })

  useEffect(() => {
    const user = token
    setSelectedLabel(selectedValue ? "Yes" : "No");
  }, [token,selectedValue])

  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(3) // Move to the next tab
    } else {
      toggleTab(2) // Move to the next tab
    }
  }

  const {
    loading: disabilityLoading,
    error: disabilityError,
    data: disabilityData,
  } = useQuery(GET_DISABILITIES, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const disabilities = disabilityData?.getDisabilities?.data?.items

  const handlePrevious = () => {
    toggleTab(1) // Move to the previous tab
  }

  const {
    loading: maritalLoading,
    error: maritalError,
    data: maritalData,
  } = useQuery(GET_MARITAL_STATUSES, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const maritalStatus = maritalData?.getMaritalStatuses?.data?.items

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const onSubmit = e => {
    e.preventDefault()
    validation.handleSubmit()
    return null
  }


  // console.log(updateStudentData?.student?.nhifNumber)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      isDisabled: updateStudentData ? updateStudentData?.isDisabled : null,
      nhifNumber: updateStudentData?.student?.nhifNumber,
      firstName: updateStudentData ? updateStudentData?.firstName : "",
      middleName: updateStudentData ? updateStudentData?.middleName : "",
      lastName: updateStudentData ? updateStudentData?.lastName : "",
      email: updateStudentData ? updateStudentData?.email : "",
      nin: updateStudentData ? updateStudentData?.nin : "",
      phoneNumber: updateStudentData ? updateStudentData?.phoneNumber : "",
      dateOfBirth: updateStudentData ? updateStudentData?.dateOfBirth : "",
      placeOfBirth: updateStudentData ? updateStudentData?.placeOfBirth : "",
      disability: updateStudentData ? updateStudentData?.disability : "",
      maritalStatusUid: updateStudentData
        ? updateStudentData?.maritalStatus?.uid
        : "",
    },

    validationSchema: Yup.object().shape({
      maritalStatusUid: Yup.string().required("Marital status is required"),
      phoneNumber: Yup.string()
        .min(10)
        .max(13)
        .nullable()
        .required("Please Enter Valid Phone Number"),
      nin: Yup.string().nullable().required("NIDA is required").max(20).min(20),
      dateOfBirth: Yup.date().nullable().required("Date of Birth is required"),
      placeOfBirth: Yup.string()
        .nullable()
        .required("Place of Birth is required"),
      isDisabled: Yup.string().nullable().required("Please choose option"),
    }),

    onSubmit: (values, { resetForm }) => {
      // console.log(values.nhifNumber)
      const postData = {
        disability: studentDissability ? values.disability : null,
        nin: values.nin,
        nhifNumber: values.nhifNumber,
        phoneNumber: values.phoneNumber,
        dateOfBirth: values.dateOfBirth,
        placeOfBirth: values.placeOfBirth,
        maritalStatusUid: values.maritalStatusUid,
        isDisabled: values.isDisabled,
      }
      Student({
        client: uaaGraphQLClient,
        variables: {
          input: { ...postData, userUid: updateStudentData?.uid },
        },
        onCompleted: ({ updateStudentProfile }) => {
          if (updateStudentProfile.code === 8000) {
            toggleTab(3)
            // console.log("Data updated successsfully")
          } else {
            // console.log("Failed to update data")
          }
          showToast(
            updateStudentProfile.message,
            updateStudentProfile.code === 8000 ? "success" : "error"
          )
          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="modal-body">
          <Row>
            <Col md="4" lg="4">
              <div className="mb-3">
                <label htmlFor="firstName">First Name:</label>
                <Input
                  type="text"
                  name="firstName"
                  readOnly
                  className="form-control"
                  id="firstName"
                  placeholder="Enter First Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.firstName ||
                    updateStudentData?.firstName ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.firstName &&
                      validation.errors.firstName
                    )
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="4" lg="4">
              <div className="mb-3">
                <Label for="middleName">Middle Name</Label>
                <Input
                  type="text"
                  name="middleName"
                  readOnly
                  className="form-control"
                  id="middleName"
                  placeholder="Enter middle name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.middleName ||
                    updateStudentData?.middleName ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.middleName &&
                      validation.errors.middleName
                    )
                  }
                />
                {validation.touched.middleName &&
                validation.errors.middleName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.middleName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="4" md="4">
              <div className="mb-3">
                <label htmlFor="lastName">Last Name:</label>
                <Input
                  type="text"
                  readOnly
                  name="lastName"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter Last Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.lastName ||
                    updateStudentData?.lastName ||
                    ""
                  }
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="4" md="4">
              <div className="mb-3">
                <Label for="middlename">Phone Number</Label>
                <Input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.phoneNumber ||
                    updateStudentData?.phoneNumber ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                    )
                  }
                />
                {validation.touched.phoneNumber &&
                validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="4" lg="4">
              <div className="mb-3">
                <label>Select Marital Status</label>
                <Select
                  name="maritalStatusUid"
                  onChange={e => {
                    validation.setFieldValue("maritalStatusUid", e.value)
                  }}
                  options={maritalStatus?.map(m => ({
                    value: m?.uid,
                    label: m?.name,
                  }))}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999,
                    }),
                  }}
                  defaultValue={updateStudentData?.maritalStatus?.uid}
                  defaultInputValue={updateStudentData?.maritalStatus?.name}
                />
                <Input
                  name="maritalStatusUid"
                  placeholder="Select Marital Status"
                  type="hidden"
                  rows={2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values?.uid ||
                    updateStudentData?.maritalStatus?.uid ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.maritalStatusUid &&
                      validation.errors.maritalStatusUid
                    )
                  }
                />
                {validation.touched.maritalStatusUid &&
                validation.errors.maritalStatusUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.maritalStatusUid}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg="4">
              <div className="mb-3">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  name="email"
                  readOnly
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.email || updateStudentData?.email || ""
                  }
                  invalid={
                    !!(validation.touched.email && validation.errors.email)
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="4" lg="4">
              <div className="mb-3">
                <label htmlFor="placeOfBirth">Place of Birth:</label>
                <Input
                    type="text"
                    name="placeOfBirth"
                    className="form-control"
                    id="placeOfBirth"
                    placeholder="Enter place of birth"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                        validation.values.placeOfBirth ||
                        updateStudentData?.placeOfBirth ||
                        ""
                    }
                    invalid={
                      !!(
                          validation.touched.placeOfBirth &&
                          validation.errors.placeOfBirth
                      )
                    }
                />
                {validation.touched.placeOfBirth &&
                validation.errors.placeOfBirth ? (
                    <FormFeedback type="invalid">
                      {validation.errors.placeOfBirth}
                    </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg="4" md="4">
              <div className="mb-3">
                <Label>Date Of Birth</Label>
                <Input
                    type="date"
                    name="dateOfBirth"
                    className="form-control"
                    id="dateOfBirth"
                    placeholder="Enter start Date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                        validation.values.dateOfBirth ||
                        updateStudentData?.dateOfBirth ||
                        ""
                    }
                    invalid={
                      validation.touched.dateOfBirth &&
                      validation.errors.dateOfBirth
                          ? true
                          : false
                    }
                />
                {validation.touched.dateOfBirth &&
                validation.errors.dateOfBirth ? (
                    <FormFeedback type="invalid">
                      {validation.errors.dateOfBirth}
                    </FormFeedback>
                ) : null}

                {/* <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    // name="dateOfBirth"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                    }}
                    onChange={changedateOfBirth}
                    value={validation.values.dateOfBirth || ""}
                  />
                </InputGroup>
                <Input
                  name="dateOfBirth"
                  placeholder="Enter date of birth"
                  type="hidden"
                  rows={3}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dateOfBirth || ""}
                  invalid={
                    !!(
                      validation.touched.dateOfBirth &&
                      validation.errors.dateOfBirth
                    )
                  }
                />
                {validation.touched.dateOfBirth &&
                validation.errors.dateOfBirth ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dateOfBirth}
                  </FormFeedback>
                ) : null} */}
              </div>
            </Col>


            <Col lg="4">
              <div className="mb-3">
                <Label className="form-label">NHIF Membership No.</Label>
                <Input
                    name="nhifNumber"
                    placeholder="Enter NHIF membership number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.nhifNumber || ""}
                    invalid={
                      !!(validation.touched.nhifNumber && validation.errors.nhifNumber)
                    }
                />
                {validation.touched.nhifNumber && validation.errors.nhifNumber ? (
                    <FormFeedback
                        type="invalid">{validation.errors.nhifNumber}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg="4">
              <div className="mb-3">
                <Label>NIDA Number</Label>
                <Input
                    type="text"
                    name="nin"
                    className="form-control"
                    id="text"
                    placeholder="Enter Your NIDA Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.nin || ""}
                    invalid={
                      validation.touched.nin && validation.errors.nin
                          ? true
                          : false
                    }
                />
                {validation.touched.nin && validation.errors.nin ? (
                    <FormFeedback type="invalid">
                      {validation.errors.nin}
                    </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label>Do you have any disability or dissabilities?</Label>
                <Select
                    name="isDisabled"
                    onChange={(e) => {
                      validation.setFieldValue("isDisabled", e.value)
                      setStudentDissability(e.value)
                      setSelectedValue(e.value)
                    }}
                    options={[
                      {value: true, label: "Yes"},
                      {value: false, label: "No"},
                    ]}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    value={{value: selectedValue, label: selectedLabel}}
                />
                <Input
                    name="isDisabled"
                    placeholder="Select Region"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                        validation.values.isDisabled ||
                    updateStudentData?.isDisabled ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.isDisabled &&
                      validation.errors.isDisabled
                    )
                  }
                />
                {validation.touched.isDisabled &&
                validation.errors.isDisabled ? (
                  <FormFeedback type="invalid">
                    {validation.errors.isDisabled}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            {studentDissability === true && (
              <Col lg="6">
                <div className="mb-3">
                  <label>Select Disability</label>
                  <Select
                    onChange={selectedOptions => {
                      const selectedValues = selectedOptions.map(option => ({
                        disabilityUid: option.value,
                      }))
                      validation.setFieldValue("disability", selectedValues)
                    }}
                    options={disabilities?.map(dp => ({
                      value: dp?.uid,
                      label: dp?.name,
                    }))}
                    isMulti={true} // Enable multiple selections
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    name="disability" // Update the field name
                    placeholder="Select Marital Status"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={JSON.stringify(
                      validation.values.disability ||
                        updateStudentData?.uid ||
                        []
                    )} // Set the value as a JSON string
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="previous">
                <Link to="#" onClick={handlePrevious}>
                  {`< Previous`}
                </Link>
              </li>
              <li className="next" onClick={handleNext}>
                <SubmitButtonHelper
                  type="primary"
                  name="Next >"
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default StudentPersonalInformation
