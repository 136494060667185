import React, {useContext} from "react"
import { Modal, Form, Input, FormFeedback } from "reactstrap"

import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {ProfileMenuContext} from "./ProfileMenu";
import StaffProfile from "../../../Modules/Users/Views/StaffProfile";

const UpdateUserProfileModel = () => {
  const { updateUserProfileModalOpen, setUpdateUserProfileModalOpen } = useContext(ProfileMenuContext)
  
  return (
    <div className="text-sm-end mt-3">
      <Modal isOpen={updateUserProfileModalOpen}
             size="xl" backdrop={"static"}
             className="modal-fullscreen" id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Update User Profile
          </h5>
          <button
              type="button"
              className="btn-close"
              onClick={() => {
                setUpdateUserProfileModalOpen(false)
              }}
              aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <StaffProfile/>
        </div>

        <div className="model-foot">
          <button onClick={() => setUpdateUserProfileModalOpen(false)} className="btn btn-danger btn-sm bx-pull-left">
            Close
          </button>
        </div>
      </Modal>
    </div>
)
}

export default UpdateUserProfileModel
