import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { GET_STAFF_COURSE_ALLOCATION } from "Modules/CourseAllocation/Queries/CourseAllocationQueries"
import { useQuery } from "@apollo/client"
import { registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { token } from "helpers/UrlHelper"
import { GET_USER } from "Modules/Users/Queries/UserQuery"

const breadcrumbItem = [
  {
    title: "Upload Results",
    path: "",
    isActive: true,
  },
]

const UplodResults = () => {
  const [semesters, setSemesters] = useState([]),
    [academicYear, setAcademicYear] = useState([]),
    [studyYear, setStudyYear] = useState([]),
    [fetching, setFetching] = useState([])

  useEffect(() => {
    // setFetching(true)
    setFetching(false)
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])

  // Fetch staff info
  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
  } = useQuery(GET_USER, {
    client: uaaGraphQLClient,
    variables: {
      username: token.username
    },
  })


  // Fetch staff allocated courses
  const {
    loading: coursesLoading,
    error: coursesError,
    data: coursesData,
  } = useQuery(GET_STAFF_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        staffUid: staffData?.getUserByUsername?.data?.staff.uid,
        programCourseUid: null,
        isCurrent: 1
      },
    },
  })

  console.log("Aloccations: ", coursesData?.getStaffCourseAllocation?.da)

  console.log("staff info: ",staffData?.getUserByUsername?.data?.staff.uid);

  // console.log("user data", token.username)

  const fetchData = () => {
    setAcademicYear([
      { label: "INF100", value: 1 },
      { label: "INF200", value: 2 },
      { label: "INF300", value: 3 },
    ])
  }

  // Form validation
  const courseAllocationFormikValidator = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      allocationUid: "",
    },
    validationSchema: Yup.object().shape({
      allocationUid: Yup.string().required("Course allocation is required"),
    }),
    onSubmit: values => {
      console.log(values)
    },
  })

  const uploadResultFormik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      csv_file: "",
    },

    // Formik validation
    validationSchema: Yup.object().shape({
      csv_file: Yup.string()
        .required("Please File is required")
        .test(
          "fileType",
          "Invalid file type format. Only CSV file is required",
          value => {
            if (!value) {
              return false
            }
            const allowedExtensions = ["csv", "xlsx", "xls"] // required file format types
            const fileExtension = value.split(".").pop().toLowerCase()
            return allowedExtensions.includes(fileExtension)
          }
        ),
    }),

    onSubmit: values => {
      console.log(values)
    },
  })
  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Examination Result Upload"
    >
      <Form
        onSubmit={e => {
          e.preventDefault()
          courseAllocationFormikValidator.handleSubmit()
          return false
        }}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label>Choose Course Allocation</Label>
                  <Select
                    // value={selectedGroup}
                    // name="study_year"
                    onChange={e => {
                      courseAllocationFormikValidator.setFieldValue(
                        "allocationUid",
                        e.value
                      )
                    }}
                    options={academicYear}
                    className="select2-selection"
                  />
                  <Input
                    name="study_year"
                    type="hidden"
                    onChange={courseAllocationFormikValidator.handleChange}
                    onBlur={courseAllocationFormikValidator.handleBlur}
                    value={
                      courseAllocationFormikValidator.values.allocationUid || ""
                    }
                    invalid={
                      !!(
                        courseAllocationFormikValidator.touched.allocationUid &&
                        courseAllocationFormikValidator.errors.allocationUid
                      )
                    }
                  />
                  {courseAllocationFormikValidator.touched.allocationUid &&
                  courseAllocationFormikValidator.errors.allocationUid ? (
                    <FormFeedback type="invalid">
                      {courseAllocationFormikValidator.errors.allocationUid}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <SubmitButtonHelper
                  type={"primary"}
                  name={"Download Template"}
                  formik={courseAllocationFormikValidator}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>

      <Form
        onSubmit={e => {
          e.preventDefault()
          uploadResultFormik.handleSubmit()
          return false
        }}
        encType="multipart/form-data"
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label>Select File To Upload</Label>
                  <Input
                    name="csv_file"
                    type="file"
                    onChange={uploadResultFormik.handleChange}
                    onBlur={uploadResultFormik.handleBlur}
                    value={uploadResultFormik.values.csv_file || ""}
                    invalid={
                      !!(
                        uploadResultFormik.touched.csv_file &&
                        uploadResultFormik.errors.csv_file
                      )
                    }
                  />
                  {uploadResultFormik.touched.csv_file &&
                  uploadResultFormik.errors.csv_file ? (
                    <FormFeedback type="invalid">
                      {uploadResultFormik.errors.csv_file}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <SubmitButtonHelper
                  type={"primary"}
                  name={"Upload File"}
                  formik={uploadResultFormik}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </LayoutHelper>
  )
}

export default UplodResults
