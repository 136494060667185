import { gql } from "@apollo/client"

const CREATE_DESIGNATION = gql`
  mutation RegisterDesignations($inputs: [DesignationDtoInput!]!) {
    registerDesignations(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_DESIGNATION = gql`
  mutation RemoveDesignation($uid: String!) {
    removeDesignation(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_DESIGNATION, DELETE_DESIGNATION }
