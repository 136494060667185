//REGISTE
import { gql } from '@apollo/client';


const GET_PROGRAM_COURSE_ASSESSMENT = gql`
query GetProgramCoursesAssessment($pagination: PaginationInput!) {
  getProgramCourseAssessments(pagination: $pagination){
    status
    code
    message
    data{
      items {
        uid 
        examCategory {
          uid
          name
          code
        }
        minimumExams
        canExceedMinimumBy
        maximumScore
        programCourse {
          uid
          programSemester {
            uid
            program {
              uid
              name
            }
            academicYear {
              uid
              name
            }
            semester
            studyYear           
          }
          course {
            uid
            code
            name
          }
        }
      }
    }
  }
}
`;
const GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE = gql`
query GetProgramCoursesAssessmentByProgramCourse($programCourseUid : String!) {
  getProgramCourseAssessmentByProgramCourseUid (programCourseUid:$programCourseUid) {
    status
    code
    message
    data{
      items {
        uid 
        examCategory {
          uid
          name
          code
        }
        minimumExams
        canExceedMinimumBy
        maximumScore
        programCourse {
          uid
          programSemester {
            uid
            program {
              uid
              name
            }
            academicYear {
              uid
              name
            }
            semester
            studyYear           
          }
          course {
            uid
            code
            name
          }
        }
      }
    }
  }
}
`;

const GET_PROGRAM_COURSE_ASSESSMENT_BY_COURSE = gql`
query GetProgramCoursesAssessmentByCourse($programCourseUid:String!) {
  getProgramCourseAssessment(courseUid: $programCourseUid){
    status
    code
    message
    data{
        uid 
        examCategory {
          uid
          name
          code
        }
        minimumExams
        canExceedMinimumBy
        maximumScore
        programCourse {
          uid
          programSemester {
            uid
            program {
              uid
              name
            }
            academicYear {
              uid
              name
            }
            semester
            studyYear           
          }
          course {
            uid
            code
            name
          }
        }
    }
  }
}
`;


export { GET_PROGRAM_COURSE_ASSESSMENT, GET_PROGRAM_COURSE_ASSESSMENT_BY_COURSE,GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE }