import React, { useContext } from "react"
import PropTypes from "prop-types"
import QualificationOptions from "./QualificationOptions"
import { Col, Row } from "reactstrap"
import { EntryQualificationConext } from "../EntryQualification"

const TanzanianQualifications = (props) => {
  const { validation } = props
  const {
    applicant, setAdd, setOpenCertificateResultModel, result,
    certificates
  } = useContext(EntryQualificationConext)

  const minLength = 1 // Specify the minimum length you want to check
  const cseeResults = certificates?.filter(data => data?.certificateType?.code === "CSEE" && data?.results?.length > minLength).length > 0
  const acseeResults = certificates?.filter(data => data?.certificateType?.code === "ACSEE" && data?.results?.length > minLength).length > 0
  const certResults = certificates?.filter(data => data?.certificateType?.code === "CERT" && data?.results?.length > minLength).length > 0
  const diplomaResults = certificates?.filter(data => data?.certificateType?.code === "DIPLOMA" && data?.results?.length > minLength).length > 0

  return (
    <>
      <Row>
        <Col lg={12}>
          <label htmlFor="qualificationMethod">Qualification Method: <strong
            className="text-danger">*</strong></label>
          <select
            id="qualificationMethod"
            name="qualificationMethod"
            className="form-control"
            value={validation.values.qualificationMethod}
            onChange={(e) => {
              validation.setFieldValue("formSixIndexNumber", "")
              validation.setFieldValue("formFourIndexNumber", "")
              validation.setFieldValue("avnNumber", "")
              validation.setFieldValue("certificateInstitutionName", "")
              validation.setFieldValue("certificateProgramName", "")
              validation.setFieldValue("certificateCompletionYear", "")
              validation.setFieldValue("certificateDocument", "")
              validation.setFieldValue("qualificationMethod", e.target.value)
              // console.log(e.target.value)
              if (e.target.value === "certificate") {
                setAdd(false)
                setOpenCertificateResultModel(true)
              }
            }}
            onBlur={validation.handleBlur}
          >
            <option value="" label="-- Select Qualification --" />
            {
              cseeResults === false && (
                <option value="form-four" label="Form Four" />
              )
            }
            {
              applicant?.qualificationMethodCode !== "CSEE" && <>
                {(applicant.qualificationMethodCode === "ACSEE") && acseeResults === false ?
                  <option value="form-six" label="Form Six" /> :
                  <option value="form-six" label="Other Form Six results (Private candidate)" />
                }
                {(applicant?.qualificationMethodCode === "CERT") && certResults === false &&
                  <option value="certificate" label="Certificate" />}
                {(applicant.qualificationMethodCode === "DIPLOMA") && diplomaResults === false &&
                  <option value="diploma" label="Diploma" />}
              </>
            }
            <option value="other-form-four" label="Other Form Four Results (Private candidate results)" />
          </select>
          {validation.touched.qualificationMethod && validation.errors.qualificationMethod ? (
            <small className="text-danger"><b>{validation.errors.qualificationMethod}</b></small>
          ) : null}
        </Col>
      </Row>
      {validation.values.applicationLevel && <QualificationOptions applicant={applicant} validation={validation} />}
    </>
  )
}

TanzanianQualifications.propTypes = {
  applicant: PropTypes.any.isRequired,
  validation: PropTypes.any.isRequired
}
export default TanzanianQualifications

