import { gql } from "@apollo/client"

const CREATE_SEMINAR_TYPE = gql`
  mutation RegisterSeminarType($inputs: [SeminarTypeInput!]!) {
    registerSeminarType(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          uid
          name
          rank
          description
       }
      }
    }
  }
`

const CREATE_STUDENT_SEMINAR = gql`
  mutation RegisterStudentSeminar($inputs: [StudentSeminarInput!]!) {
    registerStudentSeminar(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const UPDATE_STUDENT_SEMINAR_SCORE = gql`
   mutation updateStudentSeminar ($input : [StudentSeminarMarksInput!]!) {
    updateSeminarMarks (inputs : $input){
        status
        code
        message
    }
  } 
`

const DELETE_SEMINAR_TYPE = gql`
  mutation RemoveSeminarType($uid: String!) {
    removeSeminarType(uid: $uid) {
      message
      code
      status
    }
  }
`

const DELETE_STUDENT_SEMINAR_TYPE = gql`
  mutation RemoveStudentSeminar($uid: String!) {
    removeStudentSeminar(uid: $uid) {
      message
      code
      status
    }
  }
`

export { UPDATE_STUDENT_SEMINAR_SCORE,CREATE_SEMINAR_TYPE, DELETE_SEMINAR_TYPE,CREATE_STUDENT_SEMINAR,DELETE_STUDENT_SEMINAR_TYPE }
