import { gql } from "@apollo/client"

const DELETE_REVIEWER = gql`
  mutation removeReviewer($uid: String!) {
    removeReviewer(uid: $uid) {
      code
      data
      message
      status
    }
  }
`

const ADD_UPDATE_REVIEWER = gql`
  mutation registerReviewer($inputs: [ReviewerInput!]!) {
    registerReviewer(inputs: $inputs) {
      code
      message
      status
      data {
        totalCount
        items {
          email
          firstName
          lastName
          middleName
          phoneNumber
          reviewerSpecialist
          title
          uid
          reviewerInstitution {
            uid
            name
            code
          }
        }
      }
    }
  }
`

export { DELETE_REVIEWER, ADD_UPDATE_REVIEWER }
