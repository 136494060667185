import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginTop: 2,
        justifyContent: 'flex-start',
        fontWeight:"bold"
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    name: {
        fontSize: 10,
        textTransform:"capitalize",
        fontWeight:"bold"
    },
    label: {
        fontSize: 10,
        fontWeight:"bold"
    }

});


const ExamTicketStudentInfo = ({student}) => (
    <Fragment>
        <View style={styles.row}>
            <Text style={styles.label}>Student Name: </Text>
            <Text style={styles.name}>{` ${student?.firstName} ${student?.middleName} ${student?.lastName}`}</Text>
        </View>
        <View style={styles.row}>
            <Text style={{...styles.label}}>Registration Number: </Text>
            <Text style={styles.name}>{`${student?.student?.registrationNumber}`}</Text>
        </View>
    </Fragment>
);

export default ExamTicketStudentInfo