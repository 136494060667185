import React, {useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {limit} from "../../../../helpers/UrlHelper";
import {GET_ALL_PROGRAMS, GET_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_STATUS, GET_STATUS_NAME} from "../../../Lookups/Status/Queries/StatusQueries";
import ToastHelper from "../../../../helpers/ToastHelper";
import {GET_PROGRAM_REGISTERED_STUDENT_SUMMARY, GET_STUDENT_LIST_REPORT} from "../Queries/StudentReportQueries";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../helpers/PaginationHelper";
import {GenerateTablePDF} from "../../../../helpers/PdfTableHelper";

const breadcrumbItem =  [
    {
        title: 'Program Report',
        path: '',
        isActive: true,
    },
]
const StudentListSummaryReport = () => {

    const pagination = { offset: 0, limit: 20, search: null }

    const { loading:summaryLoading, error:summaryError, data:summaryData } = useQuery(GET_PROGRAM_REGISTERED_STUDENT_SUMMARY, {
        client: uaaGraphQLClient,
    })


    let summary = summaryData?.getProgramRegisteredStudentsReport?.data?.items


    const downloadList = () => {
        let title = 'First Year Undergraduate Students Registration Report'
        let programData = []
        const columns = ['S/No','Program Name', 'Male','Female','Total'];
        let total = 0, totalMale = 0, totalFemale = 0
        if(summary){
            for (let f = 0; f < summary?.length; f++){
                let s = summary[f]
                total += parseInt(s.totalProgramStudent, 10);
                totalMale += parseInt(s.totalProgramMaleStudent, 10);
                totalFemale += parseInt(s.totalProgramFemaleStudent, 10);

                programData.push([
                    f+1,s.name+' ('+s.code+')',s.totalProgramMaleStudent,s.totalProgramFemaleStudent,s.totalProgramStudent
                ])
            }
            programData.push([
                'Total','',totalMale,totalFemale,total
            ])
        }



        // console.log(studentData)
        GenerateTablePDF(columns,programData,title)
    }
    // Form validation
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Summary Report">
            <Row>
                <Col lg={12}>

                    <Card>
                        <CardBody>
                            {
                                summary && <Button className="btn btn-success btn-sm" onClick={downloadList}>
                                    <><i className="fa fa-file-pdf"></i> Generate PDF</>
                                </Button>

                            }

                            {summaryLoading ? (
                                <PlaceHolderLoader type="table" columSize={12} rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >



                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th data-priority="0">Programme Name</Th>
                                                    <Th data-priority="1">Male</Th>
                                                    <Th data-priority="3">Female</Th>
                                                    <Th data-priority="3">Total</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {summary?.map((std, index) => (
                                                    <Tr key={index}>
                                                        <Td>{index + 1}</Td>
                                                        <Td>{std?.name} ({std?.code})</Td>
                                                        <Td>{std?.totalProgramMaleStudent}</Td>
                                                        <Td>{std?.totalProgramFemaleStudent}</Td>
                                                        <Td>{std?.totalProgramStudent}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default StudentListSummaryReport;