import React, { createContext, useContext, useEffect, useState }  from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row, 
    CardSubtitle, 
    CardFooter
} from "reactstrap";
import { saveAs } from 'file-saver';
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {limit} from "../../../../helpers/UrlHelper";
import {GET_ALL_PROGRAMS, GET_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_STATUS, GET_STATUS_NAME} from "../../../Lookups/Status/Queries/StatusQueries";
import ToastHelper from "../../../../helpers/ToastHelper";
import {GET_STUDENT_LIST_REPORT, GET_STUDENT_LIST_REPORT_XLS} from "../Queries/StudentReportQueries";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../helpers/PaginationHelper";
import {GenerateTablePDF} from "../../../../helpers/PdfTableHelper";
import DropDownActionHelper from "helpers/DropDownActionHelper"
import StudentModal  from "./StudentModal"
import { GenerateUEStudentCard } from "helpers/GenerateUEStudentCard";

const breadcrumbItem =  [
    {
        title: 'Student Report',
        path: '',
        isActive: true,
    },
]

const truncateTitle = (title) => {
    const words = title.split(" ");
  
    if (words.length > 4) {
      const truncatedTitle = words.slice(0, 4).join(" ") + "...";
      return truncatedTitle;
    }
  
    return title;
  };


export const StudentContext = createContext() //Create data to transfer to child modal

const StudentListReport = () => {

    let studentsXls = [];

    
  const [updateStudentData, setUpdateStudentData] = useState(null) //Get data for editing


    const pagination = { offset: 0, limit: 20, search: null }

    const [pageSize, setPageSize] = useState(20),
        [allowClick, setAllowClick] = useState(false),
        [currentPage, setCurrentPage] = useState(0)

    const handleClick = data => {
        if(allowClick){
            searchStudent({ variables: {
                    inputData:{
                        statusCode:validation.values.statusCode,
                        studyYear:validation.values.studyYear,
                        gender:validation.values.gender,
                        programUid:validation.values.programUid,
                        offset: 100
                    }
                } })
        }

            setCurrentPage(data.selected)
    }

    const { loading:programLoading, error:programError, data:programData } = useQuery(GET_ALL_PROGRAMS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })


    const [
        searchPrograms,
        { loading: programSearchLoading, error: searchError, data: searchProgram },
    ] = useLazyQuery(GET_ALL_PROGRAMS, {client: registrationGraphQLClient})

    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })
    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items

    let programs = programData?.getAllPrograms?.data?.items
    if (searchProgram?.getAllPrograms?.data)
        programs = searchProgram?.getAllPrograms?.data?.items

    const {loading: statusLoading,error: statusError,
        data: statusData,
        refetch:statusRef,
    } = useQuery(GET_STATUS_NAME, {
        client: uaaGraphQLClient,
    })
    const [
        searchStatus,
        { loading: statusSearchLoading, error: statusSearchError, data: searchStatusData },
    ] = useLazyQuery(GET_STATUS, {client: uaaGraphQLClient})

    const [
        searchStudent,
        { loading: searchStudentLoading, error: searchStudentError, data: searchStudentData },
    ] = useLazyQuery(GET_STUDENT_LIST_REPORT, {client: uaaGraphQLClient,fetchPolicy:"no-cache"})


    const [
        searchStudentXls,
        { loading: downloadStudentXlsLoading, error: downloadStudentXlsError, data: downloadStudentXlsData },
    ] = useLazyQuery(GET_STUDENT_LIST_REPORT_XLS, {client: uaaGraphQLClient,fetchPolicy:"no-cache"})


    let status = statusData?.getStatusName.data?.items
    // if (searchStatusData?.getProgramCategories?.data)
    //     status = searchStatusData?.getStatuses.data?.items

    let students = searchStudentData?.getRegisteredStudents?.data?.items
    let totalCount = students ? searchStudentData?.getRegisteredStudents?.data?.totalCount : 0
    // console.log(students)
    // const [programSave] = useMutation(CREATE_PROGRAM,{client:registrationGraphQLClient})


    const [
        downloadStudent,
        { loading: downloadStudentLoading, error: downloadStudentError, data: downloadStudentData },
    ] = useLazyQuery(GET_STUDENT_LIST_REPORT, {client: uaaGraphQLClient,fetchPolicy:"no-cache"})


    const search = (value,type) => {
        let data = {
            search: value,
        }
        if (value.length >= 2) {
            if(type === 'academicYear'){
                searchAcademicYear({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }else if (type === 'program'){
                searchPrograms({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }else if (type === 'status'){
                searchStatus({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }
        }
    }

    function base64toBlob(base64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    
    const downloadList = () => {
        let title = ''
        if(validation.values.programUid){
            const program =  programs.find(i => i.uid === validation.values.programUid)
            title += program.name+' ('+program.code+') '
        }
        if(validation.values.statusCode) title += validation.values.statusCode
        if(validation.values.studyYear) title += ' Year '+validation.values.studyYear
        if(validation.values.gender) title += ' '+validation.values.gender
        title += ' Students Report'
        downloadStudent({ variables: {
                inputData:{
                    statusCode:validation.values.statusCode,
                    studyYear:validation.values.studyYear,
                    gender:validation.values.gender,
                    programUid:validation.values.programUid,
                    offset: 0,
                    limit: 10000000
                }
            } })
            .then((response) => {
                // Handle the response
                // console.log(response)
                if(response.data.getRegisteredStudents?.status === true){
                    const std = response.data.getRegisteredStudents.data.items
                    const columns = ['S/No','Student Name', 'Registration number','Sex','Phone Number', 'Form Four Index Number','Account Number'];
                    let studentData = []
                    if(std){
                        for (let f = 0; f < std?.length; f++){
                            let s = std[f]

                            studentData.push([
                                f+1,s.firstName+' '+s.lastName,s.registrationNumber,s.gender,s.phoneNumber,s.formFourIndexNumber,s.accountNumber
                            ])
                        }
                    }
                    // console.log(studentData)
                    GenerateTablePDF(columns,studentData,title, [], 'l')
                }

            })
            .catch((error) => {
                // Handle errors
                console.error('Mutation error:', error);
            });
        // GenerateTablePDF(columns,studentData,'Student Reports')
    }

    const printTemporaryCard = () => {
        let title = ''
        let programName = ''
        if(validation.values.programUid){
            const program =  programs.find(i => i.uid === validation.values.programUid)
            title += program.name
        }
        if(validation.values.statusCode) programName += validation.values.statusCode
        if(validation.values.studyYear) programName += ' Year '+validation.values.studyYear
        if(validation.values.gender) programName += ' '+validation.values.gender + ' '
        downloadStudent({ variables: {
                inputData:{
                    statusCode:validation.values.statusCode,
                    studyYear:validation.values.studyYear,
                    gender:validation.values.gender,
                    programUid:validation.values.programUid,
                    offset: 0,
                    limit: 10000000
                }
            } })
            .then((response) => {
                
                if(response.data.getRegisteredStudents?.status === true){
  
                    const studentData = response.data.getRegisteredStudents.data.items

                    const userData = studentData.map(student => ({
                        name: `${student.firstName} ${student.lastName}`,
                        program: title,
                        regNo: student.registrationNumber,
                    }));
                    GenerateUEStudentCard(userData, truncateTitle(title), programName)
                }

            })
            .catch((error) => {
                // Handle errors
                console.error('Error:', error);
            });
        // GenerateTablePDF(columns,studentData,'Student Reports')
    }
  

    const downloadListXls = () => {
       
        searchStudentXls({ variables: {
                inputData:{
                    statusCode:validation.values.statusCode,
                    studyYear:validation.values.studyYear,
                    gender:validation.values.gender,
                    programUid:validation.values.programUid,
                    offset: 0,
                    limit: 10000000
                }
            } })
            .then((response) => {
                if(response.data.getRegisteredStudentsXls?.status === true){
                    const studentsXls = response.data.getRegisteredStudentsXls.data.base64Data
     
                    if (studentsXls) {
                        const blob = base64toBlob(studentsXls)
                        const url = URL.createObjectURL(blob)
                        const link = document.createElement("a")
                        link.href = url
                        // link.setAttribute("download", "template.xlsx")
                        link.setAttribute("download", "Student-List.xlsx")
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        URL.revokeObjectURL(url)
                      } else {
                        console.error("Base64 data not found in the response")
                      }
                }
            }).catch((error) => {
                // Handle errors
                console.error('Mutation error:', error);
            });
        // GenerateTablePDF(columns,studentData,'Student Reports')
    }

    const  studyYear =  [
        { label: "Year 1", value: 1 },
        { label: "Year 2", value: 2 },
        { label: "Year 3", value: 3 },
        { label: "Year 4", value: 4 },
        { label: "Year 5", value: 5 },
    ]

    const  gender =  [
        { label: "MALE", value: 'Male'},
        { label: "FEMALE", value: 'Female'},
    ]

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            programUid: "",
            statusCode:"",
            studyYear:0,
            gender:"",
            citizenship:"",
            disabilityUid:"",
            academicYearUid:""
        },
        validationSchema: Yup.object().shape({
            // programUid: Yup.string().required("Program is required"),
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            // ToastHelper("Waiting for Backend", "info")
            searchStudent({ variables: {
                    inputData:{
                        statusCode:values.statusCode,
                        studyYear:values.studyYear,
                        gender:values.gender,
                        programUid:values.programUid,
                        offset: 0,
                        limit: 20
                    }
                } })
                .then((response) => {
                    // Handle the response
                    setSubmitting(false)
                    setAllowClick(true)
                })
                .catch((error) => {
                    setSubmitting(false)
                    // Handle errors
                    console.error('Mutation error:', error);
                });
            // ToastHelper("Service requested successful", "success")
            //navigate('/Students/Payments')
        },

    });

    return (

        <StudentContext.Provider
        value={{ updateStudentData, setUpdateStudentData }}
      >
        
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student Search">
        <div>

        <Row>
          <Col>
            <Card>

            <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-12 col-sm-12 col-md-12 pull-right">
                      <StudentModal />
                    </Col>
                  </Row>
                </CardSubtitle>
        {/* <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student List Reports"> */}
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                name="academic_year"
                                                onChange={(e) => {
                                                    validation.setFieldValue('academicYearUid',e.value)

                                                }}
                                                onInputChange={e => {
                                                    search(e,'academicYear')
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.uid,
                                                    label: year.name,
                                                }))}
                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYearUid || ""}
                                                invalid={
                                                    !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                }
                                            />
                                            {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYearUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg={5}CardBody>
                                        <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                            <Label>Program</Label>
                                            <Select
                                                // value={selectedMulti2}
                                                styles={{
                                                    menu: base => ({
                                                        ...base,
                                                        position: "absolute",
                                                        zIndex: 9999
                                                    })
                                                }}
                                                onChange={e => {
                                                    validation.setFieldValue("programUid", e.value)
                                                }}
                                                onInputChange={e => {
                                                    search(e,'program')
                                                }}
                                                options={programs?.map(pro => ({
                                                    value: pro.uid,
                                                    label: pro.name + " (" + pro.code + ")",
                                                }))}
                                                className="select2-selection"
                                                isLoading={programLoading || programSearchLoading}
                                            />
                                            <Input
                                                name="programUid"
                                                placeholder=""
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.programUid || ""}
                                                invalid={
                                                    !!(
                                                        validation.touched.programUid &&
                                                        validation.errors.programUid
                                                    )
                                                }
                                            />
                                            {validation.touched.programUid &&
                                            validation.errors.programUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.programUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={2}>

                                        <div className="mb-3">
                                            <Label>Study Year</Label>
                                            <Select
                                                onChange={(e) => {
                                                    validation.setFieldValue('studyYear',e.value)
                                                }}
                                                options={studyYear}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="studyYear"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.studyYear || ""}
                                                invalid={
                                                    !!(validation.touched.studyYear && validation.errors.studyYear)
                                                }
                                            />
                                            {validation.touched.studyYear && validation.errors.studyYear ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.studyYear}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg={2}>
                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <Select
                                                // value={selectedGroup}
                                                name="statusCode"
                                                onChange={(e) => {
                                                    validation.setFieldValue('statusCode',e.value)

                                                }}
                                                // onInputChange={e => {
                                                //     search(e,'status')
                                                // }}
                                                options={status?.map(st => ({
                                                    value: st.name,
                                                    label: st.name,
                                                }))}
                                                className="select2-selection"
                                                isLoading={statusLoading}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.statusCode || ""}
                                                invalid={
                                                    !!(validation.touched.statusCode && validation.errors.statusCode)
                                                }
                                            />
                                            {validation.touched.statusCode && validation.errors.statusCode ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.statusCode}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg={2}>

                                        <div className="mb-3">
                                            <Label>Gender</Label>
                                            <Select
                                                onChange={(e) => {
                                                    validation.setFieldValue('gender',e.value)
                                                }}
                                                options={gender}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="gender"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.gender || ""}
                                                invalid={
                                                    !!(validation.touched.gender && validation.errors.gender)
                                                }
                                            />
                                            {validation.touched.gender && validation.errors.gender ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.gender}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                    <div className="float-end">
                                        <SubmitButtonHelper type={'primary'} name={'Submit'} formik={validation} />
                                    </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>

            </Row>

            <Row>
                <Col lg={12}>

                    <Card>
                        <CardBody>
                            {
                                students && <Button className="btn btn-success btn-sm" onClick={downloadList} disabled={downloadStudentLoading}>
                                    {
                                        downloadStudentLoading ?
                                            <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Please wait..</>
                                            : <><i className="fa fa-file-pdf"></i> Generate PDF</>
                                    }
                                    </Button>

                            }

                            {
                            students && <Button className="btn btn-success btn-sm" onClick={downloadListXls} disabled={downloadStudentXlsLoading}>
                                    {
                                        downloadStudentLoading ?
                                            <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Please wait..</>
                                            : <><i className="fa fa-file-pdf"></i> Generate Excel</>
                                    }
                                    </Button>

                            }

                            {
                                students && <Button className="btn btn-success btn-sm" onClick={printTemporaryCard} disabled={downloadStudentXlsLoading}>
                                    {
                                        downloadStudentLoading ?
                                            <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Please wait..</>
                                            : <><i className="fa fa-file-pdf"></i> Generate Student Temporary ID Card</>
                                    }
                                    </Button>
                            }

                            {searchStudentLoading ? (
                                <PlaceHolderLoader type="table" columSize={12} rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th data-priority="0">Registration No</Th>
                                                    <Th data-priority="1">Full Name</Th>
                                                    <Th data-priority="2">Sex</Th>
                                                    {/* <Th data-priority="3">E-Mail</Th> */}
                                                    <Th data-priority="3">Phone Number</Th>
                                                    <Th data-priority="4">Form Four Index No</Th>
                                                    <Th data-priority="5">Account Number</Th>
                                                    <Th data-priority="6">Action</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {students?.map((std, index) => (
                                                    <Tr key={std?.uid}>
                                                        <Td>{(currentPage * 20) + index + 1}</Td>
                                                        <Td>{std?.registrationNumber}</Td>
                                                        <Td>{`${std?.firstName} ${std?.middleName !== undefined ? std?.middleName:''} ${std?.lastName}`}</Td>
                                                        <Td>{std?.gender}</Td>
                                                        {/* <Td>{std?.email}</Td> */}
                                                        <Td>{std?.phoneNumber}</Td>
                                                        <Td>{std?.formFourIndexNumber}</Td>
                                                        <Td>{std?.accountNumber}</Td>
                                                        <Td>
                                                            <DropDownActionHelper 
                                                            data={std}
                                                            onUpdate={setUpdateStudentData}
                                                            />
                                                            
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}

                            <PaginationHelper totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} pageItemNumber={students?.length} handleClick={handleClick} />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        {/* </LayoutHelper> */}

        </CardBody>

        </Card>
        </Col>
        </Row>
        </div>
        </LayoutHelper>
    </StudentContext.Provider>
    );
}

export default StudentListReport;
