import React from "react"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { Link,useNavigate, useLocation } from "react-router-dom"
import { Button, Card, CardBody, CardSubtitle, Col, Row } from "reactstrap"

const breadcrumbItem = [
  {
    title: "Staff Courses",
    path: "/examination/instructor/my-courses",
    isActive: false,
  }
]

const ViewUploadedResultResponse = () => {

  let counter = 0;

  const location = useLocation()

  const backButton = useNavigate();

  let { uploadScore } = location.state // extract state data

  const handleGoBack = () => {
    backButton(-1);
  }
  // console.log(uploadScore)
  // return 1
  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle={"Submitted Exam Results Preview"}
    >
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="border-bottom">
                <button onClick={handleGoBack} className="float-end btn btn-sm btn-primary">
                  <i className="fa fa-arrow-left"></i> Go Back
                </button>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 alert alert-primary">
                  {uploadScore?.message} <br />
                </div>
              </div>
              <Row>
                <Col md={6}>
                  <div className="alert alert-primary">
                    <strong>
                      Total number of Student successfully uploaded: {uploadScore?.data?.success}
                    </strong>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="alert alert-danger">
                    <strong>Total number of Student Failed to upload: {uploadScore?.data?.failed}</strong>
                  </div>
                </Col>
              </Row>
            </CardBody>
            {uploadScore?.status && (
              <CardBody>
                <Row>
                  <Col md={6}>

                  <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SNo.</th>
                        <th>Registration No</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      uploadScore?.data?.successStudents.map(successStudents => (
                        <tr key={successStudents?.regNumber}>
                          <td scope="col">{++counter}</td>
                          <td scope="col">{successStudents?.regNumber}</td>
                          <td scope="col" style={{ color: 'green' }}> <b>Success</b></td>
                        </tr>
                      )
                      
                      )}
                    </tbody>
                  </table>
                </div>

                  </Col>
                  <Col md={6}>
                  <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>SNo.</th>
                        <th>Registration No</th>
                        <th>Reason For Failure Upload</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      uploadScore?.data?.failedStudents.map(faildStudent => (
                        <tr key={faildStudent?.regNumber}>
                          <td scope="col">{++counter}</td>
                          <td scope="col">{faildStudent?.regNumber}</td>
                          <td scope="col" style={{ color: 'red' }}>{faildStudent?.reason}</td>
                        </tr>
                      )
                      )}
                    </tbody>
                  </table>
                </div>
                  </Col>
                </Row>
          
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default ViewUploadedResultResponse
