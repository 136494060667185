import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_CAMPUS } from "Modules/Campus/Mutations/CampusMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_CAMPUS } from "Modules/Campus/Queries/CampusQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { StateContext } from "./StateList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { CREATE_STATE } from "../Mutation/StateMutation"
import { GET_STATE } from "../Queries/StateQueries"

const StateModal = () => {
  const { updateStateData, setUpdateStateData } = useContext(StateContext)
  const [State] = useMutation(CREATE_STATE)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_CAMPUS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      label: updateStateData ? updateStateData.label : "",
      description: updateStateData ? updateStateData.description : "",
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("State label is required"),
      description: Yup.string().required("State description required"),
    }),

    onSubmit: (values, { resetForm }) => {
      State({
        client: registrationGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateStateData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_STATE,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerStates }) => {
          //updateFunction(client.cache, { data: { registerStates } });
          if (registerStates.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateStateData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateStateData(false)
            resetForm(false)
            //!validation.isSubmitting;
            // setUpdateStateData(true) // Shikilia modal
          }
          showToast(
            registerStates.message,
            registerStates.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New State
      </Button>
      <Modal
        isOpen={modalOpen || !!updateStateData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateStateData ? "Edit State" : "Add New State"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Label
              </label>
              <Input
                type="text"
                name="label"
                className="form-control"
                id="firstname"
                placeholder="Enter label"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.label || ""}
                invalid={!!(validation.touched.label && validation.errors.label)}
              />
              {validation.touched.label && validation.errors.label ? (
                <FormFeedback type="invalid">
                  {validation.errors.label}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                name="description"
                id="message-text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                onInvalid={() => {
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateStateData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default StateModal
