import React from "react";
import LayoutHelper from "./LayoutHelper";
import {Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";

export const LoaderPage = (props) => {
    const {title,description, breadcrumbItem, includeHeader = true} = props

    return(
        includeHeader ?
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={title}>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="text-center"> <i className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2"/>
                                    <br/>
                                    <br/>
                                    <strong><i>{description} ...</i></strong>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </LayoutHelper>
        :
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <div className="text-center"> <i className="fas fa-exclamation-circle fa-5x text-success align-middle me-2"/>
                                <br/>
                                <br/>
                                <strong><i>{description}</i></strong>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
    )
}

LoaderPage.propTypes = {
    includeHeader: PropTypes.bool,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    breadcrumbItem: PropTypes.any.isRequired
}