import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentManuScripts from "../Views/StudentManuScripts";
import Pages404 from "../../Utility/pages-404";
import ViewStudentManuScript from "../Views/ViewStudentManuScript";
const ManuScriptRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<StudentManuScripts />} />
            <Route path="/view" element={<ViewStudentManuScript />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default ManuScriptRoutes;