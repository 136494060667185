import React, {createContext, useEffect, useState} from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, Button,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import * as Yup from 'yup'
//Import Breadcrumb
import { useFormik } from "formik"
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Link, useNavigate} from "react-router-dom";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import PropTypes from "prop-types";
import {LogedInStudent} from "../../LogedInStudent";
import {useQuery} from "@apollo/client";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {GET_STUDENT_CHANGE_PROGRAM_REQUEST} from "../../Queries/Application/StudentApplicationQueries";
import ChangeProgramModel from "./ChangeProgramModel";
const breadcrumbItem =  [
    {
        title: 'Change Program',
        path: '',
        isActive: true,
    },
]

export const StudentChangeProgramContext = createContext() //Create data to transfer to child modal

const StudentChangeProgram = props => {
    const [type,setType] = useState(false);
    const logedIn = LogedInStudent()
    const student = logedIn?.student
    const study_year = student?.studyYear === 0 ?student?.studyYear+1:student?.studyYear

    const { loading, error, data } = useQuery(GET_STUDENT_CHANGE_PROGRAM_REQUEST, {
        client:registrationGraphQLClient,
        skip: !student?.uid,
        variables: {
            studentUid: student?.uid,
        },
    })
    let programChange = data?.getStudentChangePrograms?.data

    return (
        <StudentChangeProgramContext.Provider value={{type,setType}}>
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Change Program Requests">
                { loading ||  !student?.uid && <CardBody><PlaceHolderLoader columSize={12} buttonShown={false} rows={5} /></CardBody> }

                {
                    (!loading && student?.uid !== undefined) ?
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardSubtitle className="mb-1">
                                            <Row>
                                                <Col className="col-6">
                                                    
                                                </Col>
                                                <Col className="col-6">
                                                    { !programChange & study_year === 1 ? <ChangeProgramModel student={student} />
                                                          :' '
                                                    }
                                                </Col>
                                            </Row>
                                        </CardSubtitle>

                                        <div className="table-rep-plugin">
                                            <div
                                                className="table-responsive mb-0"
                                                data-pattern="priority-columns"
                                            >
                                                <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped table-bordered"
                                                >
                                                    <Thead>
                                                        <Tr>
                                                            <Th data-priority="0">From Program</Th>
                                                            <Th data-priority="0">New Program</Th>
                                                            <Th data-priority="1">Reason</Th>
                                                            <Th data-priority="2">Status</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {programChange?.map((c, index) => (
                                                            <Tr key={c?.uid}>
                                                                <Th>
                                                                    {c?.currentProgram?.name}
                                                                </Th>
                                                                <Th>
                                                                    {c?.newProgram?.name}
                                                                </Th>
                                                                <Td><div dangerouslySetInnerHTML={{ __html: c?.reason }}/></Td>
                                                                <Td>{c?.approveStatus}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>


                                            </div>
                                        </div>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        : <CardBody></CardBody>
                }
            </LayoutHelper>
        </StudentChangeProgramContext.Provider>
    );
};

StudentChangeProgram.propType = {
    uuid:PropTypes.string
}
export default StudentChangeProgram;