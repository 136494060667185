import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import PropTypes, { any } from "prop-types"
import { capitalize, toUpper } from "lodash"
const image = require("../assets/images/logo/sua.png")
const coat_of_arms = require("../assets/images/logo/Coat_of_arms.png")
const TemporaryCardSingleStudent = (
  columns,
  data,
  subtitle,
  footer = [],
  orientation = "p",
  regno
) => {
  const doc = new jsPDF({
    orientation: orientation === "p" ? "portrait" : "", // Set to 'portrait' for portrait orientation
  })
  let totalPages = 1
  const pasport_size = process.env.PUBLIC_URL + "/pasport_size.jpg"

  // const subtitle = "Student registration"
  const headerStyle = { fontSize: 16, margin: { top: 8 } }
  // Add the custom header to the PDF
  doc.setFontSize(15)
  // doc.setFont('helvetica', '', 'bold')
  doc.setFont("sans-serif", "", "bold")
  doc.text(
    "THE UNITED REPUBLIC OF TANZANIA",
    doc.internal.pageSize.getWidth() / 2,
    6,
    "center"
  )
  // Add the custom header to the PDF
  doc.setFontSize(13)
  doc.setFont("sans-serif", "", "bold")
  doc.text(
    "MINISTRY OF EDUCATION,\n" +
      "SCIENCE AND TECHNOLOGY (MoEST)\n" +
      "SOKOINE UNIVERSITY OF AGRICULTURE (SUA)",
    doc.internal.pageSize.getWidth() / 2,
    12,
    "center"
  )
  const subtitleX = doc.internal.pageSize.getWidth() / 2
  doc.setFont("sans-serif", "")
  doc.setFontSize(11)
  doc.text("TEMPORARY STUDENT ID", subtitleX, 28, "center")
  const subtitleY = 43
  doc.setFontSize(11)
  doc.setFont("sans-serif", "", "bold")
  doc.text(toUpper(subtitle), subtitleX, subtitleY, "center")
  const lineY = subtitleY + 2 // Adjust the Y position for the line
  const lineLength =
    doc.getStringUnitWidth(subtitle) * doc.internal.getFontSize() * 1.5
  for (let i = 0; i < lineLength; i += 2) {
    doc.text(".", subtitleX - lineLength / 2 + i, lineY, "center", "S")
  }

  const imgWidth = 30 // Adjust the width of the image as needed
  const imgHeight = 30 // Adjust the height of the image as needed
  let imgX = 175 // Adjust the X position
  let imgY = 1 // Position the image at the same Y coordinate as the header
  doc.addImage(image, "PNG", imgX, imgY, imgWidth, imgHeight)
  imgX = 8 // Adjust the X position
  imgY = 1 // Position the image at the same Y coordinate as the header
  doc.addImage(coat_of_arms, "PNG", imgX, imgY, imgWidth, imgHeight)
  doc.setFontSize(10)

  // Data for the table

  const columnStyles = {
    cellPadding: 1, // Adjust the cell padding
    headStyles: { fillColor: [192, 192, 192] }, // Background color for the table header
    tableLineWidth: 0.1, // Adjust the table line width
    cellWidth: "auto", // Set the cell width to auto
    startY: imgY + imgHeight + 10, // Adjust the startY position to make room for the image
  }

  doc.addImage(pasport_size, "PNG", 55, 50, 100, 100) // Adjust position and size as needed


  doc.autoTable({
    head: [columns],
    body: data,
    rowStyles: { fontSize: 12 }, // Font size for the table
    columnStyles: columnStyles,
    margin: { top: 0 }, // Adjust the top margin
    theme: "grid",
    startY: headerStyle.margin.top + 40, // Adjust the startY position
  })

  // window.open(doc.output('bloburl'));
  doc.save(`${regno}.pdf`)
}

export { TemporaryCardSingleStudent }
