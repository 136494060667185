import { gql } from "@apollo/client"

const CREATE_STAFF = gql`
  mutation RegisterStaf($inputs: [StaffInput!]!) {
    registerStaff(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          id
          uid
          pfNumber
          user {
            uid
            firstName
            middleName
            lastName
            email
            phoneNumber
            department {
              uid
              name
              unit {
                uid
                name
              }
            }
          }
        }
        totalCount
      }
    }
  }
`

const DELETE_STAFF = gql`
  mutation RemoveStaff($uid: String!) {
    removeStaff(uid: $uid) {
      message
      code
      status
    }
  }
`

const UPLOAD_USER_AVATAR = gql `
  mutation UploadAvatar($uploadInput: AvatarUploadInput!){
    uploadAvatar(uploadInput: $uploadInput){
      message
      code
      status
    }
  }
`

const RECOVER_USER_EMAIL = gql`
  mutation UpdateUserEmail($inputs: UserEmailInput!) {
    updateUserEmail(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const RECOVER_APPLICANT_EMAIL = gql `
    mutation UpdateApplicantInformation($inputs: ApplicantEmailInput!) {
        updateApplicantInformation(inputs: $inputs) {
            status
            code
            message
        }
    }
`

const RECOVER_USER_PASSWORD = gql`
  mutation ChangePasswordToDefault($uid: String!) {
    changePasswordToDefault(uid: $uid) {
      status
      code
      message
    }
  }
`

const RECOVER_APPLICANT_PASSWORD = gql`
    mutation ChangeApplicantPasswordToDefault($uid: String!) {
        changeApplicantPasswordToDefault(uid: $uid) {
            status
            code
            message
        }
    }
`


const UPDATE_USER_ACTIVATE_ACCOUNT = gql`
  mutation updateUsersActiveStatus($inputs: ActivateInput!) {
    updateUsersActiveStatus(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const UPDATE_PROGRAM_CHANGE_CANCEL = gql`
  mutation updateProgramChangeCancel($inputs: ProgramChangeCancelInput!) {
    updateProgramChangeCancel(inputs: $inputs) {
      status
      code
      message
    }
  }
  `

const DELETE_STAFF_CHILDREN = gql`
  mutation RemoveStaffChild($uid: String!) {
    removeStaffChild(uid: $uid) {
      message
      code
      status
    }
  }
`

const DELETE_STAFF_SPOUSE = gql`
  mutation RemoveStaffSpouse($uid: String!) {
    removeStaffSpouse(uid: $uid) {
      message
      code
      status
    }
  }
`

const DELETE_STAFF_ATTACHMENT = gql`
  mutation RemoveSpouse($uid: String!) {
    removeSpouseChild(uid: $uid) {
      message
      code
      status
    }
  }
`

const CREATE_ACTIVATION = gql`
  mutation ActivateDeactivateUser($inputs: UserActivateDeactivateDtoInput!) {
    activateDeactivateUser(inputs: $inputs) {
      status
      code
      message
    }
  }
`
const ASSIGN_HEADSHIP = gql`
  mutation AssignUserHeadship($inputs: AssignHeadship!) {
    assignUserHeadship(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const UPDATE_PROFILE = gql`
  mutation UpdateUserProfile($input: UserDtoUpdate!) {
    updateUserProfile(input: $input) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
        email
        phoneNumber
        placeOfBirth
        dateOfBirth
        gender
        department {
          uid
          name
          unit {
            uid
            name
          }
        }
      }
    }
  }
`

const CREATE_STAFF_CHILDREN = gql`
  mutation RegisterStaffChildren($inputs: [StaffChildDtoInput!]!) {
    registerStaffChildren(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
      }
    }
  }
`

const CREATE_STAFF_SPOUSE = gql`
  mutation RegisterStaffSpouses($inputs: SpouseDtoInput!) {
    registerStaffSpouses(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
        email
        phoneNumber
        placeOfDomicile
      }
    }
  }
`

const CREATE_STAFF_ATTACHMENT = gql`
  mutation RegisterStaffSpouses($inputs: [SpouseDtoInput!]!) {
    registerStaffSpouses(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
        email
        phoneNumber
        placeOfDomicile
      }
    }
  }
`

const CREATE_STAFF_ACADEMIC = gql`
  mutation UpdateStaffProfile($input: StaffDtoUpdate!) {
    updateStaffProfile(input: $input) {
      status
      code
      message
      data {
        uid
        pfNumber
        checkNumber
        dateEmployed
      }
    }
  }
`

const UPDATE_STAFF_NEXT_OF_KIN = gql`
mutation UpdateUserNextOfKins($inputs: UserNextOfKinsUpdate!) {
  updateUserNextOfKins (input: $inputs) {
    status
    code
    message
    data {
      fullName
      phoneNumber
      placeOfDomicile
      email
      kinship {
        uid
        name
      }
    }
  }
}
`
const DELETE_STAFF_LANGUAGES = gql`
mutation RemoveStaffLanguages($uid: String!) {
  removeStaffLanguage(uid: $uid) {
    message
    code
    status
  }
}
`
const DELETE_STAFF_NEXT_OF_KIN = gql`
mutation RemoveUserNextOfKin($uid: String!) {
  removeUserNextOfKin(uid: $uid) {
    message
    code
    status
  }
}
`
const CREATE_STAFF_LANGUAGES = gql`
mutation RegisterStaffLanguages($inputs: [StaffLanguageProficiencyDtoInput!]!) {
  registerStaffLanguages(inputs: $inputs) {
    status
    code
    message
    data {
      uid
      language
      proficiency
      staff {
        uid
        pfNumber
      }
    }
  }
}
`

const ASSIGN_GROUP = gql`
  mutation AssignGroupsToUser($inputs: AssignGroupUserInput!) {
    assignGroupsToUser(inputs: $inputs) {
      status
      code
      message
      data
    }
  }
`

const RESEND_TASK = gql`
  mutation ResendFailedTask($input: FailedTaskDtoInput!) {
    resendFailedTask(input: $input) {
      status
      code
      message
      data
    }
  }
`

export {
  CREATE_STAFF,
  DELETE_STAFF,
  CREATE_ACTIVATION,
  UPDATE_PROFILE,
  CREATE_STAFF_CHILDREN,
  DELETE_STAFF_CHILDREN,
  CREATE_STAFF_SPOUSE,
  DELETE_STAFF_SPOUSE,
  CREATE_STAFF_ATTACHMENT,
  DELETE_STAFF_ATTACHMENT,
  CREATE_STAFF_ACADEMIC,
  DELETE_STAFF_LANGUAGES,
  DELETE_STAFF_NEXT_OF_KIN,
  CREATE_STAFF_LANGUAGES,
  UPDATE_STAFF_NEXT_OF_KIN,
  UPLOAD_USER_AVATAR,
  ASSIGN_HEADSHIP,
  RECOVER_APPLICANT_PASSWORD,
  ASSIGN_GROUP,UPDATE_USER_ACTIVATE_ACCOUNT,
  RESEND_TASK,RECOVER_USER_PASSWORD,
  RECOVER_USER_EMAIL,
  UPDATE_PROGRAM_CHANGE_CANCEL,
  RECOVER_APPLICANT_EMAIL
}
