import LayoutHelper from 'helpers/LayoutHelper';
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const breadcrumbItem = [
    {
      title: "User Detail",
      path: "",
      isActive: true,
    },
  ]

const UserDetail = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
  };

  return (
   <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle='User Detail'>
     <div className="user-profile">
      <div className="user-info">
        <div className="user-image">
          <img src="path/to/user-image.jpg" alt="User" />
        </div>
        <div className="user-basic-info">
          <h2>User Name</h2>
          <p>Location: City, Country</p>
          <p>Email: user@example.com</p>
        </div>
      </div>
      <div className="user-details">
        <Tabs selectedIndex={selectedTab} onSelect={handleTabSelect}>
          <TabList>
            <Tab>About</Tab>
            <Tab>Photos</Tab>
            <Tab>Posts</Tab>
          </TabList>

          <TabPanel>
            <h3>About</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </TabPanel>
          <TabPanel>
            <h3>Photos</h3>
            <p>Display user's photos here.</p>
          </TabPanel>
          <TabPanel>
            <h3>Posts</h3>
            <p>Show user's posts here.</p>
          </TabPanel>
        </Tabs>
      </div>
    </div>
   </LayoutHelper>
  );
};

export default UserDetail;
