import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap"
import * as Yup from "yup"
import { CREATE_COURSE_ALLOCATION } from "../Mutations/CourseAllocationMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_COURSE_ALLOCATION } from "../Queries/CourseAllocationQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import Select from "react-select"
import { CourseAllocationContext } from "./CourseAllocationList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_STAFF }      from "Modules/Users/Queries/UserQuery"


const CourseAllocationModal = () => {
  const { resultRow, updateProgramCourseAssessmentData, setUpdatedProgramCourseAssessmentData } = useContext(CourseAllocationContext)
  
  const [ProgramCourse] = useMutation(CREATE_COURSE_ALLOCATION, 
    {
      client: registrationGraphQLClient
    })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

 

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  useEffect(() => {
    setTimeout(() => {
        fetchData()
    }, 1000)
}, [])

const fetchData = () => {
    //     fetch academic year and study year from api

}  

  const {
    loading: courseCategoryLoading,
    error: courseCategoryError,
    data: courseCategoryData,
  } = useQuery(GET_STAFF, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (catError) return showToast("Failed to fetch Program Categories", "error")
  let courseAllocationArray = courseCategoryData?.getStaffs?.data.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // assignmentHours: updateProgramCourseAssessmentData ? updateProgramCourseAssessmentData.assignmentHours : "",
      // independentStudyHours: updateProgramCourseAssessmentData ? updateProgramCourseAssessmentData.independentStudyHours : "",

    },


    validationSchema: Yup.object().shape({
      // programSemesterUid: Yup.string().required("Program Course name is required"),
    }),

    onSubmit: (values, {setResubmitting}) => {      
      ProgramCourse({    
        variables: {
          inputs: [{ ...values, programCourseUid:resultRow.uid }],
        },        
        client:registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_COURSE_ALLOCATION,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        
        onCompleted: ({ 
          registerCourseAllocations 
        }) => {
          if (registerCourseAllocations.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdatedProgramCourseAssessmentData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdatedProgramCourseAssessmentData(true) // Keep the modal
            setResubmitting(false);
          }
          showToast(
            registerCourseAllocations.message,
            registerCourseAllocations.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
          // setResubmitting(false);
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Course Allocation
      </Button>
      <Modal
        size="md"
        isOpen={modalOpen || !!updateProgramCourseAssessmentData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramCourseAssessmentData
              ? "Edit Course Allocation"
              : "Add New Course Allocation"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">

          <div>
            <Row>
              <Col md="12" lg="12">
                <div className="mb-3">
                      <label>Instructor</label>
                      <Select
                      id="staffUid"
                      name="staffUid"
                    onChange={e => {
                      validation.setFieldValue("staffUid", e.value)
                    }}
                    options={courseAllocationArray?.map(dp => ({
                      value: dp.uid,
                      label: dp.user.firstName + " " + dp.user.middleName + " " + dp.user.lastName + " [" + dp.pfNumber + "] ",
                    }))}
                    defaultValue={updateProgramCourseAssessmentData?.examCategoryUid}
                    defaultInputValue={updateProgramCourseAssessmentData?.examCategoryUid?.name}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}         
                      /> 
                  </div>
              </Col>
              </Row>
              </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdatedProgramCourseAssessmentData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default CourseAllocationModal
