
import { gql } from "apollo-boost";

const GET_PROGRAM_COURSE_BY_SEMESTER = gql `
query ProgramCourseBySemesterUid ( $programSemisterUid : String!)
{
  getProgramCourseByProgramSemesterUid (programSemesterUid : $programSemisterUid) 
  {
    status,
    code
    message,
    data {
      totalCount
      items {
        uid
        programSemester {
          uid,    
          program {
            uid
            name
            code
          }
          academicYear {
            uid
            name
          }
          studyYear
          semester    
        }
        course {
          uid
          code
          name
        }
        courseCategory {
          uid
          name
        }
        credit
        lectureHours
        seminarHours
        practicalHours
        assignmentHours
        independentStudyHours        
      }
    }
  }
}
`;

const GET_PROGRAM_COURSE_BY_SEMESTER_WITH_HEADSHIP = gql `
query ProgramCourseBySemesterUidWithHeadship ( $programSemisterUid : String! )
{
  getProgramCourseByProgramSemesterUidWithHeadship (programSemesterUid : $programSemisterUid) 
  {
    status,
    code
    message,
    data{
        uid
        code
       name
    }
  }
}
`;



export {GET_PROGRAM_COURSE_BY_SEMESTER,GET_PROGRAM_COURSE_BY_SEMESTER_WITH_HEADSHIP}