import React, { useContext, useEffect, useState } from "react"
import { Row, Col, Form } from "reactstrap"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link } from "react-router-dom"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "../SelfRegistration"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  GET_CONTROL_NUMBER,
  GET_FEE_STRUCTURE,
} from "Modules/Students/Queries/Payment/StudentPaymentQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS } from "Modules/Students/Mutations/StudentPaymentMutations"
import { ThreeDots } from "react-loader-spinner"

const RegistrationPayment = () => {
  const { updateStudentData } = useContext(StudentProfileContext)

  const { toggleTab, handleSubmit } = useContext(StudentProfileContext)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [fetchCount, setFetchCount] = useState(0)

  const [Student] = useMutation(REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS, {
    client: registrationGraphQLClient,
  })

  const capitalize = text => {
    if (!text) return "" // Handle null or undefined input
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(3) // Move to the next tab
    } else {
      toggleTab(2) // Move to the next tab
    }
  }

  const studentStatus = updateStudentData?.student?.status?.name;
  if(studentStatus === 'CONTINUING') {
    handleNext();
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      programUid: updateStudentData?.student?.programmeUid || "",
      yearOfStudy: parseFloat(updateStudentData?.student?.studyYear) || 0,
      studentStatus: capitalize(updateStudentData?.student?.status?.name) || "",
      registrationNumber: updateStudentData?.student?.registrationNumber || "",
      countrycode: updateStudentData?.country?.dialCode || "",
      studentName:
        updateStudentData?.firstName +
        " " +
        updateStudentData?.middleName +
        " " +
        updateStudentData?.lastName,
    },

    onSubmit: values => {
      Student({
        variables: {
          inputs: { ...values },
        },
        refetchQueries: [
          {
            client: registrationGraphQLClient,
            query: GET_CONTROL_NUMBER,
            variables: {
              registrationNumber:
                updateStudentData?.student?.registrationNumber || "",
            },
            fetchPolicy: "cache-first",
          },
          {
            client: registrationGraphQLClient,
            query: GET_FEE_STRUCTURE,
            variables: {
              inputs: {
                programUid: updateStudentData?.student?.programmeUid || "",
                yearOfStudy: updateStudentData?.student?.studyYear || 0,
                studentStatus:
                  capitalize(updateStudentData?.student?.status?.name) || "",
                countrycode: updateStudentData?.country?.dialCode || "",
              },
            },
            fetchPolicy: "cache-first",
          },
        ],

        onCompleted: ({ requestFeeStructureControlNumbers }) => {
          if (requestFeeStructureControlNumbers.code === 8000) {
            // navigate("/students/payments/control_number")
            validation.resetForm()
          } else {
            validation.resetForm()
          }
          showToast(
            requestFeeStructureControlNumbers.message,
            requestFeeStructureControlNumbers.code === 8000
              ? "success"
              : "error"
          )
        },
        onError: error => {
          // Handle errors
          validation.resetForm()
        },
      })
    },
  })

  const {
    loading: fee_loading,
    error: fee_error,
    data: fee_structure,
    refetch: refetchFeeStructures,
  } = useQuery(GET_FEE_STRUCTURE, {
    client: registrationGraphQLClient,
    skip:
      !updateStudentData?.student?.programmeUid ||
      studentStatus !== "UNREGISTERED" ||
      updateStudentData?.student?.studyYear !== 0,
    variables: {
      inputs: {
        programUid: updateStudentData?.student?.programmeUid || "",
        yearOfStudy: updateStudentData?.student?.studyYear || 0,
        studentStatus:
          capitalize(updateStudentData?.student?.status?.name) || "",
        countrycode: updateStudentData?.country?.dialCode || "",
      },
    },
    fetchPolicy: "network-only",
  })

  const {
    loading: loadingFeeStructureControlNumbers,
    error: errorFeeStructure,
    data: feeStructureControlNumber,
    refetch: refetchControlNUmbers,
  } = useQuery(GET_CONTROL_NUMBER, {
    client: registrationGraphQLClient,
    skip:
      !updateStudentData?.student?.registrationNumber ||
      studentStatus !== "UNREGISTERED",
    variables: {
      registrationNumber: updateStudentData?.student?.registrationNumber,
    },
    fetchPolicy: "network-only",
  })

  const fees = fee_structure?.getFeeStructure?.data

  const feeWithStructureControlNumber =
    feeStructureControlNumber?.getControlNumbers?.data

  // console.log("fee structure with control numbers", feeStructureControlNumber)
  // console.log("fee structure ", fee_structure)

  const refetchAll = () => {
    refetchControlNUmbers() // refetch control numbers
  }

  // Set up an interval to refetch data
  useEffect(() => {
    if (!feeWithStructureControlNumber && fetchCount < 3) {
      // Change 2 to the desired number of fetches
      const intervalId = setInterval(() => {
        refetchAll()
        setFetchCount(prevCount => prevCount + 1)
      }, 5000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [fetchCount, feeWithStructureControlNumber])

  if (fee_loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  // console.log("Fees", fees)
  // console.log("controls", feeWithStructureControlNumber)
  return (
    <div className="mt-1 wizard">
      <div className="table-rep-plugin">
        <Row>
          <Col className="col-9"></Col>
          <Col className="col-3">
            <div className="text-sm-end pull-end">
              <div className="mt-3 wizard">
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return null
                  }}
                >
                  {fees?.length > 0 && !feeWithStructureControlNumber ? (
                    <Row>
                      <Col lg="12">
                        <div className="text-center mt-2">
                          <SubmitButtonHelper
                            type="primary"
                            name="Request Control number"
                            formik={validation}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    feeWithStructureControlNumber && (
                      <Row>
                        <Col lg="12">
                          <div className="float-end mt-1">
                            <SubmitButtonHelper
                              type="primary"
                              name="Regenerate control number"
                              formik={validation}
                            />
                          </div>
                        </Col>
                      </Row>
                    )
                  )}
                </Form>
              </div>
            </div>
          </Col>
        </Row>
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <div className="alert alert-info mt-2">
            <b>
              Registration Fees you are required to pay to continue with
              registration!
            </b>
          </div>
          {feeWithStructureControlNumber ? (
            <>
              {/* <button
                className="btn btn-sm btn-secondary mb-2 float-end"
                onClick={refetchAll}
              >
                Reload page
              </button> */}
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered"
              >
                <Thead>
                  <Tr>
                    <Th data-priority="1">Fee Name</Th>
                    <Th data-priority="2">Academi Year</Th>
                    <Th data-priority="3">Amount</Th>
                    <Th data-priority="3">Minimum Amount</Th>
                    <Th data-priority="4">Currency</Th>
                    <Th data-priority="5">Control Number</Th>
                  </Tr>
                </Thead>
                {!loadingFeeStructureControlNumbers && !errorFeeStructure && (
                  <Tbody>
                    {feeWithStructureControlNumber?.map(
                      feeWithControlNumbers => {
                        const matchingFee = fees
                          ? fees.find(
                              payType =>
                                payType?.payType ===
                                feeWithControlNumbers?.payType
                            )
                          : ""

                        return (
                          <Tr key={feeWithControlNumbers?.billId}>
                            <Td>{feeWithControlNumbers?.feeName}</Td>
                            <Td>{feeWithControlNumbers?.academicYear}</Td>
                            <Td>{feeWithControlNumbers?.amount}</Td>
                            <Td>{matchingFee?.minAmount}</Td>
                            <Td className={"text-right"}>
                              {feeWithControlNumbers?.currency}
                            </Td>
                            {feeWithControlNumbers?.controlNumber != null ? (
                              <Td className={"text-right"}>
                                {feeWithControlNumbers?.controlNumber}
                              </Td>
                            ) : (
                              <Td className={"text-right"}>
                                <b className="text-center">Pending...</b>
                              </Td>
                            )}
                          </Tr>
                        )
                      }
                    )}
                  </Tbody>
                )}
              </Table>
            </>
          ) : (
            <>
              {fees ? (
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th data-priority="1">Fee Name</Th>
                      <Th data-priority="2">Minimum amount</Th>
                      <Th data-priority="3">Amount</Th>
                      <Th data-priority="4">Currency</Th>
                    </Tr>
                  </Thead>
                  {!fee_loading && !fee_error && fees !== null && (
                    <Tbody>
                      {fees?.map(fee => (
                        <Tr key={fee.name}>
                          <Td>{fee.name}</Td>
                          <Td>{fee.minAmount}</Td>
                          <Td>{fee.amount}</Td>
                          <Td className={"text-right"}>{fee.currency}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  )}
                </Table>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <div className="text-center">
                    <ThreeDots color={"green"} height={40} width={"100%"} />
                  </div>
                  <b>Fetching payment information...</b>
                </div>
              )}
            </>
          )}
        </div>
          <Row>
            <Col col={12}>
              <div className="wizard">
                <div className="actions">
                  <ul>
                    <li className="next" onClick={handleNext}>
                      <Link to="#" onClick={handleNext}>
                        {`Next >`}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
      </div>
    </div>
  )
}
export default RegistrationPayment
