import { gql } from '@apollo/client';

const GET_ = gql`
  query GetUsers {
    getUsers {
      data {
        id
        name
      }
      status
      code
      message
    }
  }
`;

const GET_FEE_STRUCTURE = gql`
  query GetFeeStructure($inputs: FeeStructureInput!) {
    getFeeStructure(inputs: $inputs){
        status
        code
        message
        data{
          name
          amount
          payType
          minAmount
          currency
        }
  }
}
`;

const GET_CONTROL_NUMBER = gql`
  query GetControlNumbers($registrationNumber: String!) {
    getControlNumbers(registrationNumber: $registrationNumber){
        status
        code
        message
        data{
          registrationNumber
          feeName
          amount
          controlNumber
          currency
          payType
          billId
          academicYear
        }
  }
}
`;

const GET_STUDENT_FINANCIAL_STATEMENT= gql`
  query GetFinancialStatement($registrationNumber: String!) {
    getFinancialStatement(registrationNumber: $registrationNumber){
        status
        code
        message
        data
  }
}
`;
export { GET_FEE_STRUCTURE,GET_CONTROL_NUMBER,GET_STUDENT_FINANCIAL_STATEMENT }