import React, { useState, useContext, useRef } from 'react';
import { Modal, Button, Form, Input, FormFeedback } from 'reactstrap';
import * as Yup from "yup";
import { CREATE_STUDY_POSTPONEMENT_REQUEST } from 'Modules/Students/Mutations/StudentApplicationMutations';
import { SEND_TO_ADVISOR } from 'Modules/Students/Mutations/StudentApplicationMutations';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import showToast from "helpers/ToastHelper";
import { StudyPostponementContext } from './StudentStudyPostponement';
import Select from "react-select";
import { uaaGraphQLClient } from '../../../../Authentication/ApolloClient';
import { GET_STUDENT_STUDY_POSTPONEMENT_REQUEST } from '../../../Queries/Application/StudentApplicationQueries'
import SubmitButtonHelper  from "../../../../../helpers/SubmitButtonHelper";
import { createUpdateFunction, paginationHelper } from "helpers/Functions/GraphqlUpdateFunction";
import { GET_POSTPONEMENT_REASONS }  from './../../../../Lookups/PostponementReasons/Queries/PostponementReasonsQueries'


//const StudentStudyPostponementModal = () =>{
const StudentStudyPostponementModal = props =>{
  const { studyPostponement, setStudyPostponement } = useContext(StudyPostponementContext);
  const [studentStudyPostponement] = useMutation(CREATE_STUDY_POSTPONEMENT_REQUEST, {client: uaaGraphQLClient});
  const [sendToAdvisor] = useMutation(SEND_TO_ADVISOR, {client: uaaGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false);
  const formRef = useRef()
  const {student} = props;
 
  const { loading:postponementLoading,
           error:postponementError,
           data:postponementData } = useQuery(GET_POSTPONEMENT_REASONS, {
    client:uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })



  //const handleShow = () => setModalOpen(true);

  const reasons = postponementData?.getPostponementReasons?.data.items
  console.log("Postponement reasons:", reasons)

  //const query = GET_POSTPONEMENT_REASONS
 // const variables = { pagination: paginationHelper }
 // const registerData = "data"

  //const updateFunction = createUpdateFunction(query, variables, registerData)


  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      sendToAdvisor: false,
      approvalStatus: 'Pending Approval',
      postponementReasonUid: studyPostponement ? studyPostponement.postponementReason.uid : "",
      description: studyPostponement ? studyPostponement.description : "",
      attachedFile: studyPostponement ? studyPostponement.attachedFile : "",
    },

    validationSchema: Yup.object().shape({
      postponementReasonUid: Yup.string().required("Postponement Reason is required"),
      description: Yup.string().required("Reason description  is required"),
      attachedFile: Yup.string().required("Attachment is required"),
      }),

    onSubmit: (values, {setSubmitting}) => {
       // Set sendToAdvisor to true
     values.sendToAdvisor = true;

      studentStudyPostponement({
        client:uaaGraphQLClient,
        variables: {
          // inputs: [{ ...values, uid: studyPostponement?.uid, approvalStatus:'Pending Approval' }],
          inputs: [{ ...values, uid: studyPostponement?.uid  }],
        },
        
        refetchQueries: [
          {
            query: GET_STUDENT_STUDY_POSTPONEMENT_REQUEST,
            variables: {
              studentUid: student?.uid,
            },
          },
        ],
        onCompleted: ({ createStudyPostponementRequest }) => {
          //updateFunction(client.cache, { data: { studentStudyPostponement } });
          if (createStudyPostponementRequest.code === 8000) {
            validation.resetForm()
            setModalOpen(false) // Close the modal
            setStudyPostponement(false) 
            setSubmitting(false) //stop submitting spinner
          } else {
            setModalOpen(true)
            setSubmitting(false)
           
          }
          showToast(
            createStudyPostponementRequest.message,
            createStudyPostponementRequest.code === 8000 ? "success" : "error"
          )
          setSubmitting(false)
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
          setSubmitting(false)
        },
      })
    },
  })
    return (
      <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        // onClick={handleShow}
        onClick={ ()=> {setModalOpen(true)}}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Request Study Postponement
      </Button>
      <Modal
        isOpen={modalOpen || !!studyPostponement}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!studyPostponement ? "Edit Postponement Request" : "Add New Postponement Request"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={ () => {
              setModalOpen(false)
              setStudyPostponement(false)
              validation.resetForm() // Reset the form
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label>Postponement Reason</label>
              <Select
                onChange={e => {
                  validation.setFieldValue("postponementReasonUid", e.value)
                }}
                options={reasons?.map(reason => ({
                  value: reason.uid,
                  label: reason.name,
                }))}

                //dummy data for testing
                // options={[
                //   { value: "reason1", label: "Financial" },
                //   { value: "reason2", label: "Family" },
                //   { value: "reason3", label: "Health" },
                //   { value: "reason4", label: "Social" },
                //   { value: "reason5", label: "Psychological" },
                //   { value: "reason6", label: "Employment" },
                //   { value: "reason7", label: "Internship" },
                //   { value: "reason8", label: "Other" },
                //        ]}
                defaultValue={studyPostponement?.postponementReasonUid}
                defaultInputValue={studyPostponement?.postponementReason?.name}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999
                  })
                }}  
              />
              <Input
                name="postponementReasonUid"
                placeholder="Select Reason"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.postponementReasonUid || studyPostponement?.postponementReasonUid || ""}
                invalid={
                  !!(validation.touched.postponementReasonUid && validation.errors.postponementReasonUid)
                }
              />
              {validation.touched.postponementReasonUid && validation.errors.postponementReasonUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.postponementReasonUid}
                </FormFeedback>
              ) : null}
            </div>
            
            <div className="mb-3">
              <label htmlFor="description" className="col-form-label">
              Description:
              </label>
              <Input
                type="textarea"
                name="description"
                className="form-control"
                id="description"
                placeholder="Type description of your reason for postponement"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.description || ""
                }
                invalid={
                  !!(validation.touched.description && validation.errors.description)
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="attachedFile" className="col-form-label">
              Attach Supportive Document (PDF)
              </label>
              <Input
                type="file"
                name="attachedFile"
                className="form-control"
                id="attachedFile"
                placeholder="Attach File"
                accept=".pdf"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.attachedFile || studyPostponement?.attachedFile || ""
                }
                invalid={
                  validation.touched.attachedFile && validation.errors.attachedFile
                    ? true
                    : false
                }
              />
              {validation.touched.attachedFile && validation.errors.attachedFile ? (
                <FormFeedback type="invalid">
                  {validation.errors.attachedFile}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                console.log("Close button clicked");
                setModalOpen(false)
                setStudyPostponement(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default StudentStudyPostponementModal