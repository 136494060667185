import { gql } from "apollo-boost";

const CREATE_STATE = gql`
  mutation RegisterState($inputs: [StateInput!]!) {
    registerStates(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          uid
          label
          description
        }
      }
    }
  }
`;

const DELETE_STATE = gql`
  mutation RemoveWorkflow($uid: String!) {
    removeState(uid: $uid) {
      status
      code
      message
    }
  }
`;
export {CREATE_STATE, DELETE_STATE}