import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { CREATE_DEACTIVATE_REASONS } from "../Mutations/DeactivateMutations"
import { GET_DEACTIVATE_REASONS } from "../Queries/DeactivateQueries"
import { DeactivateReasonsContext } from "./DeactivateReasonsList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const DeactivateReasonModal = (props) => {
  const { updateDeactivateReasonData, setUpdateDeactivateReasonData } =
    useContext(DeactivateReasonsContext)
    console.log("Deactivate reasons data", updateDeactivateReasonData);
  const [DeactivateReason] = useMutation(CREATE_DEACTIVATE_REASONS,{client:uaaGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_DEACTIVATE_REASONS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateDeactivateReasonData ? updateDeactivateReasonData?.name : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Deactivate Reason name is required"),
    }),

    onSubmit: values => {
      DeactivateReason({
        client:uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateDeactivateReasonData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_DEACTIVATE_REASONS,
            client:uaaGraphQLClient,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerDeactivationReason }) => {
          //updateFunction(client.cache, { data: { registerDeactivationReason } });
          console.log("DS", registerDeactivationReason);
          if (registerDeactivationReason.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateDeactivateReasonData(false) // Close the modal
          } else {
            validation.resetForm()
            setModalOpen(true)
            setUpdateDeactivateReasonData(true) // Close the modal
          }
          showToast(
            registerDeactivationReason.message,
            registerDeactivationReason.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
          validation.resetForm()
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Deactivate Reason
      </Button>
      <Modal
        isOpen={modalOpen || !!updateDeactivateReasonData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateDeactivateReasonData
              ? "Edit DeactivateReason"
              : "Add New DeactivateReason"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || updateDeactivateReasonData?.name || ""
                }
                invalid={
                  !!(validation.touched.name && validation.errors.name)
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            {/* <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                name="desc"
                id="message-text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.desc}
                onInvalid={() => {
                  validation.touched.desc && validation.errors.desc
                    ? true
                    : false
                }}
              />
              {validation.touched.desc && validation.errors.desc ? (
                <FormFeedback type="invalid">
                  {validation.errors.desc}
                </FormFeedback>
              ) : null}
            </div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                // setUpdateDeactivateReasonData(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default DeactivateReasonModal
