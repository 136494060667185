import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  Alert,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label, Modal,
  Row
} from "reactstrap"
import image3 from "assets/login_images/3.jpg"
import image4 from "assets/login_images/4.png"
import image5 from "assets/login_images/5.jpg"
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBCarousel,
  MDBCarouselItem
} from "mdb-react-ui-kit"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

import CarouselPage from "./CarouselPage"
import sualogo from "assets/images/logo/logo.svg"
import SubmitButtonHelper from "../../helpers/SubmitButtonHelper"
import { onlineApplicationGraphQLClient, uaaGraphQLClient } from "./ApolloClient"
import showToast from "../../helpers/ToastHelper"
import { useMutation, useQuery } from "@apollo/client"
import { LOGIN_MUTATION } from "./Mutations/LoginMutation"
import { ServicesCards } from "../../components/EsbDashboardLayout/ESBServices"
import { jwtDecode } from "jwt-decode"
import { GET_ACTIVE_ADMISSION_WINDOW } from "../OnlineApplication/AccountRegistration/Queries/AcountRegistrationQueries"
import EsbFooter from "../../components/EsbDashboardLayout/EsbFooter"

const EsbLogin = () => {
  const [passwordShow, setPasswordShow] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)


  const [auth, { data, error }] = useMutation(LOGIN_MUTATION, {
    client: uaaGraphQLClient
  })
  const navigate = useNavigate()

  const location = useLocation()

  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage || "")
  const [showMessage, setShowMessage] = useState(!!location.state?.successMessage)

  const credentials = location.state?.response

  //meta title
  document.title = "SUA ESB | Login"

  const handleSubmit = async values => {
    const { username, password } = values
    try {
      const response = await auth({
        client: uaaGraphQLClient,
        variables: { input: { username, password } }
      })
      const { data } = response
      // console.log("DataLogin", data);
      if (data?.login.code == 8000) {
        localStorage.setItem("authUser", JSON.stringify(data?.login))
        // Check if the user needs to change their password
        if (data?.login.tokenType == "otp") {
          showToast(
            "Please provide OTP Code sent through your phone number or email.",
            "info"
          )
          navigate("/verification/password/otp/" + data.login.accessToken, {
            state: { loginData: data.login.data }
          }) // Redirect to VerifyOtp page
          localStorage.removeItem("authUser")
        } else if (data?.login.data.isVerified == false) {
          // showToast("Please change your password before continuing.", "info")
          // navigate("/users/change-password") // Redirect to the password change page
          const token = jwtDecode(data?.login?.accessToken)
          const userAuthorities = token?.authorities
          const visibleSystems = ServicesCards.filter(system =>
            userAuthorities.includes(system.authority)
          )
          if (visibleSystems.length === 1) {
            showToast(`Welcome to ${visibleSystems[0].title}`, "success")
            navigate(`${visibleSystems[0].link}`)
          } else {
            showToast("Login success", "success")
            navigate("/")
          }
        } else {
          showToast("Login success", "success")
          navigate("/")
        }
      } else {
        showToast(data?.login.message, "error", "Login Failed")
      }
    } catch (err) {
      // Handle error response
      // console.error("Login Error:", err.message)
    }
  }
  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password")
    }),
    onSubmit: handleSubmit
  })

  const {
    loading: admissionWindowLoading,
    error: admissionWindowError,
    data: admissionWindowData
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: {
      applicationLevelCode: "BSC"
    },
    fetchPolicy: "cache-and-network"
  })

  const currentDateFull = new Date()
  const currentDate = currentDateFull
    .toISOString()
    .slice(0, 19)
    .replace("T", " ")

  const window = admissionWindowData?.getActiveAdmissionWindow?.data

  useEffect(() => {
    if (successMessage) {
      setModalOpen(true)
      const timer = setTimeout(() => {
        setShowMessage(false)
      }, 8000000)
      return () => clearTimeout(timer) // Cleanup the timeout on unmount
    }
  }, [successMessage, modalOpen])

  const handleCloseModal = () => {
    setSuccessMessage("")
    setModalOpen(false)
    navigate(location.pathname, { replace: true }) // Navigate to the current path without state
  }


  const calculateDaysDifference = (end) => {
    // Get the current date
    const currentDate = new Date();

    // Parse the end date
    const endDateObj = new Date(end);

    if (isNaN(endDateObj.getTime())) {
      return NaN; // Return NaN if the date is invalid
    }

    // Calculate the difference in time (milliseconds)
    const diffTime = endDateObj - currentDate;

    // Convert time difference to days
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  return (
    <MDBContainer className="my-5">
      <MDBRow>
        <MDBCol md={2}></MDBCol>
        <MDBCol md={8}>
          <MDBCard
            style={{
              borderRadius: "20px",
            }}
          >
            <MDBRow className="g-0 d-flex align-items-center">
              <MDBCol md={6} sm={6} lg={6} xl={6}>
                <MDBCarousel showControls showIndicators className="d-none d-md-block">
                  <MDBCarouselItem className="active">
                    <MDBCardImage
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover"
                      }}
                      src={image4}
                      alt="phone"
                      className="rounded-t-5 rounded-tr-lg-0"
                      fluid
                    />
                  </MDBCarouselItem>
                  <MDBCarouselItem>
                    <MDBCardImage
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover"
                      }}
                      src={image3}
                      alt="phone"
                      className="rounded-t-5 rounded-tr-lg-0"
                      fluid
                    />
                  </MDBCarouselItem>
                  <MDBCarouselItem>
                    <MDBCardImage
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover"
                      }}
                      src={image5}
                      alt="phone"
                      className="rounded-t-5 rounded-tr-lg-0"
                      fluid
                    />
                  </MDBCarouselItem>
                </MDBCarousel>
              </MDBCol>

              <MDBCol md={6} sm={6} lg={6} xl={6}>
                <MDBCardBody>
                  <div className="text-center">
                    <img
                      src={sualogo}
                      height={100}
                      alt="Sua Logo"
                      className="d-inline-block align-middle"
                    />
                    <h1
                      style={{
                        color: "#000",
                        fontSize: "2em",
                        fontWeight: "bolder"
                      }}
                    >
                      SUA ESB
                    </h1>
                  </div>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="mb-3">
                      <Label className="form-label">Username</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter username"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                        }
                      />
                      {validation.touched.username &&
                        validation.errors.username && (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        )}
                    </div>

                    <div className="mb-3">
                      <div className="float-end">
                        <Link
                          to="/forgot-password"
                          className="text-muted"
                        >
                          Forgot password?
                        </Link>
                      </div>
                      <Label className="form-label">Password</Label>
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={passwordShow ? "text" : "password"}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                          }
                        />
                        <button
                          onClick={() => setPasswordShow(!passwordShow)}
                          className="btn btn-light"
                          type="button"
                          id="password-addon"
                        >
                          {passwordShow ? (
                            <i className="mdi mdi-eye-off-outline"></i> // Icon when password is shown
                          ) : (
                            <i className="mdi mdi-eye-outline"></i> // Icon when password is hidden
                          )}
                        </button>
                      </div>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mt-3 d-grid">
                      <SubmitButtonHelper
                        type="primary"
                        name="Log In"
                        formik={validation}
                      />
                    </div>
                  </Form>
                  {
                    window && (
                      <>
                        {
                          window?.endDate > currentDate && (
                            <div className="mt-5 text-center">
                              <button className="btn btn-light animate-element">
                                <Link
                                  to="/online/application"
                                  className="fw-medium text-primary"
                                >
                                  Click Here to Apply Online
                                </Link>
                              </button>
                              <marquee direction="right" className="mt-2"
                              style={{
                                borderRadius: `15px`
                              }}
                              >
                                <b className="text-info">
                                  {calculateDaysDifference(window?.endDate) < 10 && calculateDaysDifference(window?.endDate) + " days remaining for "+ window?.description}
                                </b>
                              </marquee>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBCard>
        </MDBCol>
        <MDBCol md={2}></MDBCol>
      </MDBRow>
      {/*Footer*/}
      <EsbFooter/>
      <Modal
        isOpen={modalOpen}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Your account credentials
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              handleCloseModal()
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Alert color="danger font-weight-bolder">
            Please save your credentials before you close this modal!
          </Alert>
          <div className="mb-3">
            {
              showMessage && (
                <>
                  <br />
                  <Alert color="primary" className="m-2">
                    {successMessage}
                    <br />
                    <b>Your username: {credentials?.username}</b>
                    <br />
                    <b>Your password: {credentials?.password}</b>
                  </Alert>
                </>
              )
            }
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </Modal>
    </MDBContainer>
  )
}

export default EsbLogin
