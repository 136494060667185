import React, { createContext, useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle
} from "reactstrap"

import { limit } from "helpers/UrlHelper"

import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"

import showToast from "helpers/ToastHelper"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
import ReactPaginate from "react-paginate"
import { useFormik } from "formik"
import LayoutHelper from "helpers/LayoutHelper"
import { GET_COLLEGES } from "../Queries/CollegesQueries"
import { DELETE_COLLEGE } from "../Mutations/CollegesMutations"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"

import CollegeModal from "./CollegeModal"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"

import {
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { set } from "lodash"

const breadcrumbItem = [
  {
    title: "College List",
    path: "",
    isActive: true,
  },
]

export const CollegeContext = createContext() //Create data to transfer to child modal

const CollegeList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedColleges, setSelectedCollege] = useState(null) //Get data for deleting
  const [updateCollegeData, setUpdateCollegeData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_COLLEGES, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
  })

  const [
    searchColleges,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_COLLEGES, { client: uaaGraphQLClient })

  useEffect(() => {
    if (!loading && data) {
      // Handle data received from the backend
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    setCurrentPage(selectedPage.selected)
  }

  const [deleteCollege] = useMutation(DELETE_COLLEGE, {
    client: uaaGraphQLClient,
    variables: { uid: selectedColleges?.uid },
    refetchQueries: [
      {
        query: GET_COLLEGES,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteCollege = (college) => {
    setSelectedCollege(college)
    showDeleteWarning(() => {
      performCollegeDeletion()
    })
  }

  const performCollegeDeletion = async () => {
    try {
      const response = await deleteCollege()
      showToast(response.data.removeUnit.message, response.data.removeUnit.code === 8000 ? "success" : 'error')
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  document.title = "Colleges"
  if (loading) {
    return (
      <>
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
      </>
    )
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 1) => {
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchColleges({ variables: { pagination: { ...pagination, ...data } } })
  }
  const colleges = data

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    }
  }

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getUnits?.data.totalCount || searchData?.getUnits?.data?.items || 0

  return (
    <CollegeContext.Provider
      value={{ updateCollegeData, setUpdateCollegeData }}
    >
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="College List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <CollegeModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !searchLoading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Code</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Campus</Th>
                            <Th>Description</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {data.getUnits.data.items.length > 0}

                          {colleges.getUnits.data.items
                            .slice(startIndex, endIndex)
                            .map((college, index) => (
                              <Tr key={colleges.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{college.code}</Td>
                                <Td>{college.name}</Td>
                                <Td>{college?.campus.name}</Td>
                                <Td>{college.description}</Td>
                                <Td>
                                  <DropDownActionHelper
                                  data={college}
                                  onUpdate={setUpdateCollegeData}
                                  onDelete={handleDeleteCollege}
                                  />
                                
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </CollegeContext.Provider>
  )
}

export default CollegeList
