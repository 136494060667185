import React, {createContext} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import {useMutation, useQuery} from "@apollo/client";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import {showWaringConf} from "../../../../../helpers/DeleteWaringHelper";
import {format} from "date-fns";
import {GET_STUDENT_MANU_SCRIPT} from "../Queries/StudentDissertationQueries";
import {CREATE_STUDENT_DISSERTATION_PAPER} from "../Mutations/StudentDissertationMutations";

const breadcrumbItem = [
    {
        title: "My Manuscripts",
        path: "/student/dissertation/papers",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]
const ViewDissertationPaper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [submit] = useMutation(CREATE_STUDENT_DISSERTATION_PAPER)
    if(!location.state) navigate('/student/dissertation/papers')
    const { paper,student } = location.state
    // console.log(paper)

    const handleSubmitManuscript = () => {
        showWaringConf(() => {
                confirmSubmit()
                navigate('/student/dissertation/papers',{
                    state:{student:student}
                })
            },`Submit Manuscript`,`Are you sure you need to submit this manuscript? You can not cancel or update submitted forms`,'warning','Submit',
            () => {
                // setPayType(null)
            })
    }

    const confirmSubmit = async () => {
        try {
            await submit(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs:{
                            title: paper?.title,
                            description: paper?.description,
                            status:1,
                            uid:paper?.uid,
                            studentUid:student?.uid,
                            publicationDate:paper?.publicationDate,
                            publicationStatus:paper?.publicationStatus
                        }
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_MANU_SCRIPT,
                            variables: {
                                studentUid:student?.uid,
                            },
                        },
                    ],
                    onCompleted: ({registerStudentSeminar}) => {
                        if (registerStudentSeminar.code === 8000) {
                            // showToast(registerStudentSeminar.message, "success")
                        } else {
                            // showToast(
                            //     registerStudentSeminar.message,
                            //     registerStudentSeminar.code === 8000 ? "success" : "error"
                            // )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }


    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`view Manuscript/Paper`} >
            {paper &&
                <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center text-uppercase"><h3>Title: {paper.title}</h3></CardTitle>
                                    <CardSubtitle>

                                        <ul style={{listStyleType:"none",marginLeft:'-3%',fontSize:'120%'}}>
                                            <li>
                                                <strong>Status:</strong> {paper.status === 0 && <span className="badge bg-danger"> Un submitted/Pending ...</span>}
                                                {paper.status === 1 && <span className="badge bg-danger"> Approval Pending..</span>}
                                                {paper.status === 2 && <span className="badge bg-success"> Approved</span>}
                                                {paper.status === 3 && <span className="badge bg-danger"> Rejected</span>}

                                            </li>

                                            <li>
                                                <strong>Publication Status: </strong> <span className="badge bg-success"> {paper?.publicationStatus}</span>
                                            </li>
                                            <li>
                                                <strong>Publication Date: </strong> <span className="badge bg-success"> {paper?.publicationDate ? format(new Date(paper?.publicationDate), 'MMMM dd, yyyy'):''}</span>
                                            </li>
                                        </ul>
                                    </CardSubtitle>
                                    <hr/>
                                    <div style={{
                                        maxHeight:'700px', overflow: 'auto'
                                    }} dangerouslySetInnerHTML={{ __html: paper.description }}/>
                                </CardBody>
                                <CardFooter>
                                    {paper.status === 0 && <Button onClick={() => handleSubmitManuscript()} className="btn btn-success float-start">Submit Manualscript</Button>}
                                </CardFooter>
                            </Card>
                        </Col>
                </Row>
            }
            {/*<CourseLeaningOutcomes course={course} />*/}
        </LayoutHelper>
    );
}

export default ViewDissertationPaper;