//REGISTE
import { gql } from "@apollo/client"

const GET_STAFF = gql`
  query GetStaffs($pagination: PaginationInput!) {
    getStaffs(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          pfNumber
          user {
            uid
            firstName
            middleName
            lastName
            username
            email
            phoneNumber
            isActive
            department {
              uid
              name
              unit {
                uid
                name
              }
            }
          }
        }
        totalCount
      }
    }
  }
`

const GET_USER = gql`
    query GetUserByUsername($username: String!) {
        getUserByUsername(username: $username) {
            status
            code
            message
            data {
                uid
                firstName
                middleName
                lastName
                email
                country{
                    id
                    name
                }
                username
                nin
                title {
                    uid
                    name
                }
                phoneNumber
                placeOfBirth
                dateOfBirth
                gender
                isActive
                isDisabled
                isTwoFactorEnabled
                maritalStatus {
                    uid
                    name
                }
                userDisabilities {
                    uid
                    name
                }
                department {
                    uid
                    name
                    unit {
                        uid
                        name
                    }
                }
                designation {
                    uid
                    name
                }
                staff {
                    uid
                    pfNumber
                    checkNumber
                    staffTerm {
                        uid
                        name
                        code
                    }
                    dateEmployed
                }
            }
        }
    }
`
const GET_STUDENT_BY_USERNAME = gql`
    query SearchUserByUsername($username: String!) {
        searchUserByUsername(username: $username) {
            status
            code
            message
            data {
                uid
                firstName
                middleName
                lastName
                email
                username
                phoneNumber
                student{
                    programmeUid
                    uid
                    registrationNumber
                    studyYear
                    status
                }
            }
        }
    }
`

const GET_STUDENT_BY_USERNAME_LIKE = gql`
  query GetUsersByUserNameLike($username: String!) {
    getUsersByUserNameLike(username: $username) {
      status
      code
      message
      data {
        items{
          userUid
          userName
          firstName
          middleName
          lastName
          registrationNumber
        }
      }
    }
  }
`



const GET_STUDENT = gql`
  query GetUserByUsername($username: String!) {
    getUserByUsername(username: $username) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
        email
        isDisabled
        nin
        phoneNumber
        placeOfBirth
        physicalAddress
        postalAddress
        dateOfBirth
        gender
        isActive
        userDisabilities{
          uid
          name
        }
        student {
          uid
          studyYear
          registrationNumber
          programmeUid
          status {
            uid
            name
            code
          }
          entryCategory {
            uid
            name
            code
          }
          district {
            uid
            name
            code
            region {
              uid
              name
              country {
                uid
                name
              }
            }
          }
          sponsors {
            uid
            name
          }
          accountNumber
          bank {
            uid
            name
          }
          studentCertificates{
            certificateNumber
            certificateType{
              code
              name
            }
          }
          nhifNumber
        }
        country {
          uid
          name
          code
          dialCode
        }
        department {
          uid
          name
          unit {
            uid
            name
          }
        }
        maritalStatus {
          uid
          name
        }
      }
    }
  }
`

const GET_USER_BY_UID = gql`
  query GetUser($uid: String!) {
    getUser(uid: $uid) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
        nin
        email
        phoneNumber
        isActive
        department {
          uid
          name
          unit {
            uid
            name
          }
        }
      }
    }
  }
`
const GET_NEXT_OF_KIN_BY_STAFF = gql`
  query GetUserNextOfKins($userUid: String!) {
    getUserNextOfKins(userUid: $userUid) {
      status
      code
      message
      data {
        uid
        fullName
        phoneNumber
        placeOfDomicile
        email
        kinship {
          uid
          name
        }
      }
    }
  }
`

const GET_STAFF_CHILDREN = gql`
  query GetStaffChildren($userUid: String!) {
    getStaffChildren(userUid: $userUid) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
      }
    }
  }
`

const GET_STAFF_SPOUSE = gql`
  query GetStaffSpouses($userUid: String!) {
    getStaffSpouses(userUid: $userUid) {
      status
      code
      message
      data {
        uid
        firstName
        middleName
        lastName
        email
        phoneNumber
        placeOfDomicile
      }
    }
  }
`

const GET_STAFF_LANGUAGES = gql`
  query GetLanguageProficiency($userUid: String!) {
    getStaffLanguages(userUid: $userUid) {
      status
      code
      message
      data {
        id
        uid
        proficiency
        language
        staff {
          uid
          pfNumber
          user {
            uid
            firstName
          }
        }
      }
    }
  }
`
const GET_ASSIGNED_GROUP = gql`
  query GetUserGroups($userUid: String!) {
    getUserGroups(userUid: $userUid) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
      }
    }
  }
`

const GET_NOT_ASSIGNED_GROUP_OLD = gql`
  query GetUnassignedUserGroups($userUid: String!) {
    getUnassignedUserGroups(userUid: $userUid) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
      }
    }
  }
`


const GET_NOT_ASSIGNED_GROUP = gql`
  query GetGroups($pagination: PaginationInput!) {
    getGroups(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`


const GET_FAILED_TASKS = gql`
  query GetFailedTasks($pagination: PaginationInput!) {
    getFailedTasks(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
          func
          result
        }
        totalCount
      }
    }
  }
`

const GET_USER_DOCUMENT = gql`
  query GetUserDocuments($userUid: String!, $documentType: String!) {
    getUserDocuments(userUid: $userUid, documentType: $documentType) {
      status
      code
      message
      data{base64doc}
    }
  }
`

export {
  GET_STAFF,
  GET_USER,
  GET_USER_BY_UID,
  GET_STAFF_CHILDREN,
  GET_NEXT_OF_KIN_BY_STAFF,
  GET_STAFF_LANGUAGES,
  GET_STAFF_SPOUSE,
  GET_ASSIGNED_GROUP,
  GET_NOT_ASSIGNED_GROUP,
  GET_STUDENT,
  GET_USER_DOCUMENT,
  GET_FAILED_TASKS,GET_STUDENT_BY_USERNAME,
  GET_STUDENT_BY_USERNAME_LIKE
}
