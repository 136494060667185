import { gql } from "@apollo/client"

const CREATE_COURSE_CATEGORY = gql`
mutation RegisterCourseCategories($inputs: [CourseCategoryInput!]!) {
  registerCourseCategories(inputs: $inputs) {
  message
  status
  code
  data{
    items {
    uid
    name
    description
    }
    totalCount
  }
}  
}
`

const DELETE_COURSE_CATEGORY = gql`
  mutation RemoveCourseCategory($uid: String!) {
    removeCourseCategory(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_COURSE_CATEGORY, DELETE_COURSE_CATEGORY }
