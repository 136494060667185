import { gql } from "@apollo/client"

const GET_SPONSORS = gql`
  query GetSponsors($pagination: PaginationInput!) {
    getSponsors(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          code
          name
          uid
        }
        totalCount
      }
    }
  }
`

export { GET_SPONSORS }
