import React, { createContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Alert
} from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { Link, useNavigate } from "react-router-dom"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import ProgramSelectionModal from "./ProgramSelectionModal"
import {
  GET_ACTIVE_ADMISSION_WINDOWS,
  GET_APPLICANT_DETAILS,
  GET_APPLICANT_SELECTED_WINDOW,
  GET_CURRENT_WINDOW_APPLICANT_SELECTIONS
} from "../Queries/ProgramSelectionQueries"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useMutation, useQuery } from "@apollo/client"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { REMOVE_SELECTION } from "../Mutations/ProgramSelectionMutations"
import {
  GET_APPLICANT_CERTIFICATE_RESULT,
  GET_APPLICANT_PAYMENT_STATUS
} from "Modules/Applicant/Queries/ApplicantQueries"
import AppliedPrograms from "../../../ProgramCourses/Views/AppliedPrograms"
import { LoaderPage } from "../../../../helpers/LoaderPage"

export const ProgramSelectionContext = createContext() //Create data to transfer to child modal

const ProgramSelection = () => {
  const [selectedSelection, SetSelectedSelection] = useState(null)
  const [applicant, setApplicant] = useState(null)
  const [admissionWindow, setAdmissionWindow] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [windowData, setWindowData] = useState(null)
  const navigate = useNavigate()

  const breadcrumbItem = [
    {
      title: "Program Selection",
      path: "",
      isActive: true
    }
  ]

  const {
    loading: applicantLoading,
    error: applicantError,
    data: applicantData,
    refetch: refetchApplicant
  } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    variables: {
      userUid: JSON.parse(localStorage.getItem("authUser"))?.data?.uid
    },
    fetchPolicy: "cache-first"
  })

  const {
    loading: selectionsLoading,
    error: selectionError,
    data: selectionData,
    refetch: refetchSelections
  } = useQuery(GET_APPLICANT_SELECTED_WINDOW, {
    client: onlineApplicationGraphQLClient,
    skip: !applicantData?.getApplicantDetails?.data?.uid,
    variables: {
      applicantUid: applicantData?.getApplicantDetails?.data?.uid
    },
    fetchPolicy: "network-only"
  })

  const selections =
    selectionData?.getApplicantAppliedWindow?.data?.items

  const [deleteSelection] = useMutation(REMOVE_SELECTION, {
    client: onlineApplicationGraphQLClient,
    variables: { uid: selectedSelection?.uid },
    refetchQueries: [
      {
        query: GET_CURRENT_WINDOW_APPLICANT_SELECTIONS,
        client: onlineApplicationGraphQLClient,
        variables: {
          applicantUid: applicantData?.getApplicantDetails?.data?.uid
        }
      }
    ]
  })

  const {
    loading: paymentStatusLoading,
    error: controlNumberError,
    data: paymentStatusData,
    refetch: refetchPayment
  } = useQuery(GET_APPLICANT_PAYMENT_STATUS, {
    client: onlineApplicationGraphQLClient,
    skip: !applicantData?.getApplicantDetails?.data?.uid,
    variables: {
      applicantUid: applicantData?.getApplicantDetails?.data?.uid
    },
    fetchPolicy: "network-only"
  })

  const {
    loading: activeWindowLoading,
    error: activeWindowError,
    data: activeWindowData,
    refetch: refetchActiveAdmissionWindow
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOWS, {
    client: onlineApplicationGraphQLClient,
    skip: !applicantData?.getApplicantDetails?.data?.applicationLevelCode,
    variables: {
      applicationLevelCode:
      applicantData?.getApplicantDetails?.data?.applicationLevelCode
    },
    fetchPolicy: "network-only"
  })

  const activeWindow = activeWindowData?.getActiveAdmissionWindow?.data


  if (!paymentStatusLoading && !applicantLoading) {
    if (paymentStatusData?.getApplicantPaymentStatus?.status !== true) {
      navigate("/applicant/payment")
    } else if (
      !applicantData?.getApplicantDetails?.data?.applicationLevelCode
    ) {
      navigate("/applicant/application-method")
    }
  }

  const {
    loading: certificateLoading,
    error: certificatesError,
    data: certificateData
  } = useQuery(GET_APPLICANT_CERTIFICATE_RESULT, {
    client: onlineApplicationGraphQLClient,
    variables: {
      userUid: JSON.parse(localStorage.getItem("authUser"))?.data?.uid
    },
    fetchPolicy: "network-only"
  })
  const certificates = certificateData?.getApplicantCertificate?.data?.items


  const handleDeletion = deleteData => {
    SetSelectedSelection()
    showDeleteWarning(deleteData => {
      performDataDeletion()
    })
  }

  useEffect(() => {
    setApplicant(applicantData?.getApplicantDetails?.data)
    setAdmissionWindow(activeWindow)
  }, [applicantData?.getApplicantDetails?.data, activeWindow, selections])

  const performDataDeletion = async () => {
    try {
      const response = await deleteSelection()
      showToast(
        response.data.removeProgramSelection.message,
        response.data.removeProgramSelection.code === 8000 ? "success" : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const toggleDropdown = staff => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [staff.uid]: !prevState[staff.uid]
    }))
  }

  if (certificateLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"}
                                             description={"Fetching Applicant Certificates"} />


  const requiredQualificationMethod = applicantData?.getApplicantDetails?.data?.qualificationMethodCode
  const certificateExists = certificates?.some(data => data?.certificateType?.code === requiredQualificationMethod)
  if (!certificateExists) {
    navigate("/applicant/entry-qualification")
  }

  return (
    <ProgramSelectionContext.Provider
      value={{
        applicant,
        admissionWindow,
        windowData,
        refetchSelections
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle={"Online Application"}
      >
        <Card>
          <CardBody>
            <Row>
              <Col lg={12}>
                {applicantLoading ||
                selectionsLoading ||
                activeWindowLoading ||
                paymentStatusLoading ? (
                  <div className="text-center">
                    <i className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2" />
                    <br />
                    <strong>Please wait...</strong>
                  </div>
                ) : (
                  <>
                    {
                      activeWindowData?.getActiveAdmissionWindow?.data ? (
                        <>
                          {applicantData?.getApplicantDetails?.data?.submissionStatus === false &&
                            <ProgramSelectionModal />
                          }
                        </>

                      ) : (
                        <Alert color="danger">
                          {`No Active Admission window currently!`}
                        </Alert>
                      )
                    }
                    <div className="mt-5">
                      <CardTitle>Applied admission Window lists</CardTitle>
                      <Table
                        className="table table-bordered table-striped"
                        style={{ border: "2px solid #63a69f" }}
                      >
                        <Thead>
                          <Tr>
                            <Th>S/N</Th>
                            <Th>Window Name</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {selections?.map((prog, index) => (
                            <Tr key={index}>
                              <td>{index + 1}</td>
                              <Td>{prog?.description}</Td>
                              <Td>
                                <a
                                  onClick={() => {
                                    setWindowData(prog)
                                  }}
                                >
                                  {<AppliedPrograms />}
                                </a>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                      <div className="text-center">
                        <Link
                          to="/applicant/entry-qualification"
                          className="btn btn-primary btn-sm mx-2"
                        >
                          <i className="mdi mdi-arrow-left ms-1"></i> Previous
                        </Link>
                        {selections.length > 0 &&
                          <Link
                            to="/applicant/confirmation"
                            className="btn btn-sm btn-primary"
                          >
                            Proceed <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>}
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </LayoutHelper>
    </ProgramSelectionContext.Provider>
  )
}
export default ProgramSelection
