import { gql } from "@apollo/client"

const CREATE_PROGRAM_CHANGE = gql`
  mutation createProgramChange($inputs: [StudentProgramChangeInput!]!) {
    registerStudentProgramChange(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        code
        name
      }
    }
  }
`

const FORWARD_COURSE_RESULT_BY_HOD = gql`
  mutation ForwardCourseResultByHod($programCourseUids: [String!]!) {
    forwardCourseResultByHod(programCourseUids: $programCourseUids) {
      status
      code
      message
      data
    }
  }
`

const RETURN_COURSE_EXAM_RESULT = gql`
  mutation ReturnCourseExamResult($programCourseUids: [String!]!) {
    returnCourseExamResult(programCourseUids: $programCourseUids) {
      status
      code
      message
      data
    }
  }
`

const REGISTER_UPLOAD_RESULTS_DEADLINE = gql`
  mutation RegisterUploadResultDeadline($inputs: ResultDeadlineInput!) {
    registerUploadResultDeadline(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const DELETE_UPLOAD_RESULT_DEADLINE = gql`
  mutation RemoveUploadResultDeadline($uid: String!) {
    removeUploadResultDeadline(uid: $uid) {
      status
      code
      message
      data
    }
  }
`

export {
  CREATE_PROGRAM_CHANGE,
  DELETE_UPLOAD_RESULT_DEADLINE,
  FORWARD_COURSE_RESULT_BY_HOD,
  REGISTER_UPLOAD_RESULTS_DEADLINE,
  RETURN_COURSE_EXAM_RESULT,
}
