//REGISTE
import { gql } from "@apollo/client"

const GET_THESIS_SETTINGS = gql`
  query GetIntentionToSubmitRequirements($pagination: PaginationInput!) {
    getIntentionToSubmitRequirements(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          minimumManuscripts
          minimumSeminars
          seminarPassMarks
          lifeSpan
          programCategory {
             uid
             name
             shortName
          }
        }
        totalCount
      }
    }
  }
`

const GET_THESIS_SETTING_BY_CATEGORY = gql`
  query GetIntentionToSubmitRequirementByCategory($categoryUid: String!) {
    getIntentionToSubmitRequirementByCategory(categoryUid: $categoryUid) {
      status
      code
      message
      data {
          uid
          minimumManuscripts
          minimumSeminars
          seminarPassMarks
          lifeSpan
          programCategory {
             uid
             name
             shortName
          }
      }
    }
  }
`

export { GET_THESIS_SETTINGS,GET_THESIS_SETTING_BY_CATEGORY }
