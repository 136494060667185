import React from "react"
import PropTypes from "prop-types";
import {Col, FormFeedback, Row} from "reactstrap";

const DiplomaQualification = (props) => {
    const {validation, applicant} = props
    // console.log(validation.values)
    return (
        <>
            {validation.values.qualificationMethod === 'certificate' &&
                <>
                    <Col lg={4}>
                        <label htmlFor="certificateInstitutionName">Certificate Institution Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="certificateInstitutionName"
                            id="certificateInstitutionName"
                            placeholder="Enter Certificate Institution Name"
                            value={validation.values.certificateInstitutionName}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                        {validation.touched.certificateInstitutionName && validation.errors.certificateInstitutionName ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.certificateInstitutionName}
                                </FormFeedback>
                        ) : null}
                    </Col>
                    <Col lg={4}>
                        <label htmlFor="certificateProgramName">Certificate Program Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="certificateProgramName"
                            id="certificateProgramName"
                            placeholder="Enter Certificate Program Name"
                            value={validation.values.certificateProgramName}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                        {validation.touched.certificateProgramName && validation.errors.certificateProgramName ? (
                            <FormFeedback type="invalid">
                                {validation.errors.certificateProgramName}
                            </FormFeedback>
                        ) : null}
                    </Col>
                    <Col lg={4}>
                        <label htmlFor="certificateCompletionYear">Certificate Completion Year</label>
                        <input
                            type="number"
                            min={2000}
                            className="form-control"
                            name="certificateCompletionYear"
                            id="certificateCompletionYear"
                            placeholder="Enter Certificate Completion Year"
                            value={validation.values.certificateCompletionYear}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                        />
                        {validation.touched.certificateCompletionYear && validation.errors.certificateCompletionYear ? (
                            <FormFeedback type="invalid">
                                {validation.errors.certificateCompletionYear}
                            </FormFeedback>
                        ) : null}
                    </Col>
                    <Col lg={4}>
                        <label htmlFor="certificateGpa">Certificate GPA</label>
                        <input
                            id="certificateGpa"
                            className="form-control"
                            placeholder="Enter Certificate GPA"
                            name="certificateGpa"
                            type="text"
                            value={validation.values.certificateGpa}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                        />
                        {validation.touched.certificateGpa && validation.errors.certificateGpa ? (
                            <FormFeedback type="invalid">
                                {validation.errors.certificateGpa}
                            </FormFeedback>
                        ) : null}
                    </Col>
                    <Col lg={4}>
                        <label htmlFor="certificateDocument">Upload Certificate</label>
                        <input
                            id="certificateDocument"
                            className="form-control"
                            name="certificateDocument"
                            // type="number"
                            accept="application/pdf"
                            type="file"
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    validation.setFieldValue("certificateDocument", e.target.files[0])
                                }
                            }}
                        />
                        {validation.touched.certificateDocument && validation.errors.certificateDocument ? (
                            <FormFeedback type="invalid">
                                {validation.errors.certificateDocument}
                            </FormFeedback>
                        ) : null}
                    </Col>
                </>
            }
        </>
    )
}

DiplomaQualification.propTypes = {
    applicant: PropTypes.any.isRequired,
    validation: PropTypes.any.isRequired
};

export default DiplomaQualification;