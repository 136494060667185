//REGISTE
import { gql } from '@apollo/client';
const REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS = gql`
  mutation RequestFeeStructureControlNumber($inputs: RequestControlNumberInput!) {
    requestFeeStructureControlNumbers(inputs: $inputs) {
        status
        code
        message
    }
  }
`
const REFRESH_CONTROL_NUMBER = gql`
  mutation RefreshControlNumber($inputs: Strings!) {
    RefreshControlNumber(inputs: $inputs) {
        status
        code
        message
    }
  }
`
const RENEW_CONTROL_NUMBER = gql`
  mutation RenewControlNumber($inputs: String!) {
    renewControlNumber(inputs: $inputs) {
        status
        code
        message
    }
  }
`
export { REFRESH_CONTROL_NUMBER,RENEW_CONTROL_NUMBER,REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS }