import AdmissionIcon from "../../assets/images/svg/agreement.png";
import StudentIdIcon from "../../assets/images/svg/id.png";
import AccommodationIcon from "../../assets/images/svg/accomodation.png";
import StudentIcon from "../../assets/images/svg/male-graduated-student.png";
import MoneyIcon from "../../assets/images/svg/money.png";
import ExaminationIcon from "../../assets/images/svg/exam.png";
import ConferenceIcon from "../../assets/images/svg/conference.png";
import ReportIcon from "../../assets/images/svg/report.png";
import TeamIcon from "../../assets/images/svg/team.png";
import SettingsIcon from "../../assets/images/svg/settings.png";
import * as userAuthorities from "react-table/src/filterTypes";
const toUserManagement = "/user-management/dashboard"
const toRegistration = "/self-registration/dashboard"
const toAdmission = "/admission/dashboard"
const toPayments = "/finance/dashboard"
const toExamination = "/examination/dashboard"
const toSettings = "/system-settings/dashboard"
const toReports = "/reports/dashboard"
const toAccommodation = "/accommodation/dashboard"
const toStudents = "/student/dashboard"
const toApplicant = "/applicant/dashboard"
const toAsims = "/asims/dashboard"
const toTeachingAssessment = "/teaching-assessment/dashboard"

export const ServicesCards = [
    {
        image: AdmissionIcon,
        link: toAdmission,
        title: "Admission",
        shortName: "ADMISSION",
        authority: "VIEW_ADMISSION_MODULE",
        // authority: "VIEW_ADMISSION_MODULE" || "VIEW_ADMISSION_MENU",
    },
    {
        image: StudentIdIcon,
        link: toRegistration,
        title: "Self Registration",
        shortName: "SELF-REG",
        authority: "VIEW_SELF_REGISTRATION_MODULE"
    },
    {
        image: AccommodationIcon,
        link: toAccommodation,
        title: "Accommodation",
        shortName: "ACCOMMODATION",
        authority: "VIEW_ACCOMMODATION_MODULE"
    },
    {
        image: StudentIcon,
        link: toStudents,
        title: "Student Results",
        shortName: "STUDENT",
        authority: "VIEW_STUDENT_MODULE"
    },
    {
        image: MoneyIcon,
        link: toPayments,
        title: "Payments and Fees",
        shortName: "FINANCE",
        authority: "VIEW_PAYMENT_AND_FEES_MODULE"
    },
    {
        image: ReportIcon,
        link: toTeachingAssessment,
        title: "Teaching Assessment",
        shortName: "ASSESSMENT",
        authority: "VIEW_STUDENT_DASHBOARD"
    },
    {
        image: ExaminationIcon,
        link: toExamination,
        title: "Examination",
        shortName: "EXAMINATION",
        authority: "VIEW_EXAMINATION_MODULE"
    },
    {
        image: ConferenceIcon,
        link: toAsims,
        title: "Academic staff Information Management System (ASIMS)",
        shortName: "ASIMS",
        authority: "VIEW_ASIMS_MODULE"
    },
    {
        image: ReportIcon,
        link: toReports,
        title: "Reports",
        shortName: "REPORT",
        authority: "VIEW_REPORTS_MODULE"
    },
    {
        image: TeamIcon,
        link: toUserManagement,
        title: "User Management",
        shortName: "USER MANAGEMENT",
        authority: "VIEW_USER_MANAGEMENT_MODULE"
    },
    {
        image: SettingsIcon,
        link: toSettings,
        title: "System Settings",
        shortName: "SYSTEM SETTINGS",
        authority: "VIEW_SYSTEM_SETTINGS_MODULE"
    },
    {
        image: StudentIcon,
        link: toApplicant,
        title: "Online Application",
        shortName: "ONLINE APPLICATION",
        authority: "VIEW_OAS_MODULE"
    },
];