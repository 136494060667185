import { gql } from "apollo-boost"

const GET_INSTRUCTORS = gql`
  query GetInstructors {
    getInstructors {
      code
      data {
        uid
        pfNumber
        user {
          uid
          firstName
          lastName
          email
          username
        }
      }
    }
  }
`

export { GET_INSTRUCTORS }
