import { gql } from "apollo-boost"

const GET_WORKFLOW = gql`
  query GetWorkflow($pagination: PaginationInput!) {
    getWorkflows(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
          description
        }
        totalCount
      }
    }
  }
`

const GET_TRANSITION_META_BY_WORKFLOW = gql`
  query GetTransitionMetaByWorkflow($workflowUid: String!) {
    getTransitionMetaByWorkflow(workflowUid: $workflowUid) {
      status
      code
      message
      data {
        uid
        workflow {
          uid
          description
          name
        }
        sourceState {
          uid
          description
          label
        }
        destinationState {
          uid
          description
          label
        }
      }
    }
  }
`

export { GET_WORKFLOW, GET_TRANSITION_META_BY_WORKFLOW }
