import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link } from "react-router-dom"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { token, userLoginData } from "helpers/UrlHelper"
import { UploadResultFromMoodleContext } from "./UploadResultFromMoodleView"

const FirstStep = () => {
  const { updateStudentData, courseData } = useContext(UploadResultFromMoodleContext)

  const { toggleTab } = useContext(UploadResultFromMoodleContext)
  const [isSubmitted] = useState(false)
  const [studentDissability, setStudentDissability] = useState(false)

  const dissabilityOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ]
  const handleNext = () => {
      toggleTab(2) // Move to the next tab
  }
  
  const courseName = courseData?.getCourseAllocation?.data?.programCourse?.course?.name;

  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="modal-body">
          <Row>
            <Col md="12" lg="12" sm={12}>
              <div className="mb-3">
                <div className="alert alert-info">
                  <p>
                    You are about to upload the results to SUA-ESB from Moodle for <strong>{courseName}</strong>.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="next">
                <Link to="#" onClick={handleNext}>
                  {`Next >`}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default FirstStep
