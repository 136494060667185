import React, { useContext, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Label
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import showToast from "helpers/ToastHelper"
import { ChildrenContext } from "./ChildrenList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { CREATE_STAFF_CHILDREN } from "Modules/Users/Mutations/UserMutations"
import { GET_STAFF_CHILDREN } from "Modules/Users/Queries/UserQuery"
import { useMutation } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StaffProfileContext } from "../StaffProfile"

const ChildrenModal = () => {
  const { updateStaffData, setUpdateStaffData } =
    useContext(StaffProfileContext)
  const { updateChildrenData, setUpdateChildrenData } =
    useContext(ChildrenContext)
  const [children] = useMutation(CREATE_STAFF_CHILDREN)
  const [modalOpen, setModalOpen] = useState(false)
  const [imgModalOpen, setImgModalOpen] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)

  const handleFileChange = event => {
    const file = event.currentTarget.files[0]
    validation.setFieldValue("birthCertificate", file)

    // Check the file type
    const fileType = file.type

    if (fileType.startsWith("image/")) {
      // If it's an image, display it as an image
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewUrl(reader.result)
        console.log("Image file", previewUrl);

        setImgModalOpen(true)
      }
      reader.readAsDataURL(file)
    } else if (fileType === "application/pdf") {
      console.log("Pdf file here")
      // If it's a PDF file, set the preview URL to the PDF file
      const pdfUrl = URL.createObjectURL(file)
      setPreviewUrl(URL.createObjectURL(file))
      setImgModalOpen(true)
      console.log("Pdf file", URL.createObjectURL(file));
    } else {
      // Handle other file types or show an error message
      showToast("Unsupported file type", "error")
    }
  }

  // const handleFileChange = event => {
  //   const file = event.currentTarget.files[0]
  //   validation.setFieldValue("birthCertificate", file)

  //   const reader = new FileReader()
  //   reader.onload = () => {
  //     setPreviewUrl(reader.result)
  //     setImgModalOpen(true) // Open the modal after setting the preview URL
  //   }
  //   reader.readAsDataURL(file)
  // }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: updateChildrenData ? updateChildrenData.name : "",
      middleName: updateChildrenData ? updateChildrenData.middleName : "",
      lastName: updateChildrenData ? updateChildrenData.lastName : "",
      birthCertificate: "",
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      birthCertificate: Yup.mixed().required("Birth Certificate is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      // Convert birthCertificate file to base64 string
      const reader = new FileReader()
      ;(reader.onload = () => {
        const base64BirthCertificate = reader.result.split(",")[1] // Remove the prefix
        children({
          client: uaaGraphQLClient,
          variables: {
            inputs: [
              {
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName,
                userUid: updateStaffData?.uid,
                birthCertificate: base64BirthCertificate,
                uid: updateChildrenData?.uid,
              },
            ],
          },
          refetchQueries: [
            {
              query: GET_STAFF_CHILDREN,
              variables: {
                userUid: updateStaffData?.uid,
              },
            },
          ],
          onCompleted: ({ registerStaffChildren }) => {
            //updateFunction(client.cache, { data: { registerStaffChildren } });
            if (registerStaffChildren.code === 8000) {
              validation.resetForm()
              setModalOpen(false)
              setUpdateChildrenData(false) // Close the modal
            } else {
              setModalOpen(true)
              setSubmitting(false)
            }
            showToast(
              registerStaffChildren.message,
              registerStaffChildren.code === 8000 ? "success" : "error"
            )
          },
          onError: error => {
            // Handle errors
            console.error("Mutation error:", error)
          },
        })
      }),
        // Read the birthCertificate file as a DataURL
        reader.readAsDataURL(values.birthCertificate)
    },
  })

  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Children
      </Button>
      <Modal
        isOpen={modalOpen || !!updateChildrenData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateChildrenData ? "Edit Children" : "Add New Child"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateChildrenData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="firstName" className="col-form-label">
                First Name:
              </label>
              <Input
                type="text"
                name="firstName"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.firstName ||
                  updateChildrenData?.firstName ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.firstName && validation.errors.firstName
                  )
                }
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="middleName" className="col-form-label">
                Middle Name:
              </label>
              <Input
                type="text"
                name="middleName"
                className="form-control"
                id="firstname"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.middleName ||
                  updateChildrenData?.middleName ||
                  ""
                }
              />
            </div>

            <div className="mb-3">
              <label htmlFor="lastName" className="col-form-label">
                Last Name:
              </label>
              <Input
                type="text"
                name="lastName"
                className="form-control"
                id="lastName"
                placeholder="Enter last name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.lastName ||
                  updateChildrenData?.lastName ||
                  ""
                }
                invalid={
                  !!(validation.touched.lastName && validation.errors.lastName)
                }
              />
              {validation.touched.lastName && validation.errors.lastName ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>

            <div className="md-3">
              <Label for="birthCertificate">Attach Birth Certificate</Label>
              <Input
                type="file"
                accept=".pdf"
                id="birthCertificate"
                onChange={handleFileChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.birthCertificate &&
                  validation.errors.birthCertificate
                }
              />
              {validation.touched.birthCertificate &&
                validation.errors.birthCertificate && (
                  <FormFeedback type="invalid">
                    {validation.errors.birthCertificate}
                  </FormFeedback>
                )}

              {/* <Modal
                isOpen={imgModalOpen}
                toggle={() => setImgModalOpen(false)}
              >
                <ModalHeader toggle={() => setImgModalOpen(false)}>
                  File Preview
                </ModalHeader>
                <ModalBody>
                  {previewUrl &&
                    (previewUrl.endsWith(".pdf") ? (
                      <Document file={previewUrl}>
                        <Page pageNumber={1} />
                      </Document>
                    ) : (
                      <img
                        src={previewUrl}
                        height={300}
                        width={450}
                        alt="Preview"
                      />
                    ))}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => setImgModalOpen(false)}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </Modal> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateChildrenData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ChildrenModal
