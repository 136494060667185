import { Link } from "react-router-dom"
import React from "react"


const DissertationAndThesisLinks = ({hasAuthority}) => {
  return (
    <>
      {hasAuthority("VIEW_DISSERTATION_MENU") && (
        <>

          {hasAuthority("VIEW_DISSERTATION_MENU") && (
            <li>
              <Link to="/dissertation">
                <i className="bx bx-home-circle"></i>
                <span>Seminar List</span>
              </Link>
            </li>
          )}


          {hasAuthority("VIEW_DISSERTATION_MENU") && (
            <li>
              <Link to="/manu-script">
                <i className="bx bx-home-circle"></i>
                <span>Manuscript/Papers</span>
              </Link>
            </li>
          )}


          {hasAuthority("VIEW_DISSERTATION_MENU") && (
            <li>
              <Link to="/intention-to-submit">
                <i className="bx bx-home-circle"></i>
                <span>Intention To Submit</span>
              </Link>
            </li>
          )}

        </>
      )}
    </>
  )
}


export default DissertationAndThesisLinks
