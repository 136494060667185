import React from "react";
import { Route, Routes } from "react-router";
import ProgrammeCategories from "../Views/ProgrammeCategories";
import Pages404 from "Modules/Utility/pages-404";

const CategoryRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<ProgrammeCategories/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
    
}

export default CategoryRoutes