import React, {useContext, useEffect, useState} from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {Card, CardBody, Col, Modal} from "reactstrap"
import { Link } from "react-router-dom"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import { GET_USER } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import { token } from "helpers/UrlHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import StudentProfileImageUpdate from "./StudentProfileImageUpdate";
import {ProfileMenuContext} from "../../../../components/CommonForBoth/TopbarDropdown/ProfileMenu";

const breadcrumbItem = [
  {
    title: "My Profile",
    path: "",
    isActive: true,
  },
]

const MyProfile = props => {
  const [profile, setProfile] = useState([]),
    [fetching, setFetching] = useState(true)

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
  } = useQuery(GET_USER, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
  })

  const studentData = userDetails?.getUserByUsername?.data
  const { updateProfileModalOpen, setUpdateProfileModalOpen } = useContext(ProfileMenuContext)
// console.log(studentData)
    if(!studentData) return false
  return (

      <Modal isOpen={updateProfileModalOpen} backdrop={"static"} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Change Password
          </h5>
          <button
              type="button"
              className="btn-close"
              onClick={() => {
                setUpdateProfileModalOpen(false)
              }}
              aria-label="Close"
          ></button>
        </div>
        <div className="card-body">
          <StudentProfileImageUpdate userUid={studentData?.uid} />
        </div>

      </Modal>
      
            
  )
}

export default MyProfile
