import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { CREATE_ROOM, GENERATE_ROOMS } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import showToast from "helpers/ToastHelper"
import { GET_BLOCK_ROOMS, GET_HOSTEL_BLOCKS } from "../Queries/AccommodationQueries"
import { RoomContext } from "./RoomList"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"

const RoomModal = () => {
  const { parentBlock, updateRoom, setUpdateRoom } = useContext(RoomContext)
  const [create_room] = useMutation(CREATE_ROOM, {client: accomodationGraphQLClient})
  const [generate_rooms] = useMutation(GENERATE_ROOMS, {client: accomodationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const [generateModalOpen, setGenerateModalOpen] = useState(false)
  const formRef = useRef()

  // const [currentPage, setCurrentPage] = useState(0)
  // const pagination = { offset: currentPage, limit: limit, search: null }

  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_BLOCKS, {
    client:accomodationGraphQLClient,
    variables: {
      hostelUid: parentBlock?.hostel?.uid
    },
    fetchPolicy: 'cache-first'
  })
  const blocks_options = data?.getBlocksByHostel?.data?.items

  const [selectedBlock, setSelectedBlock] = useState({});

  const handleModalOpened = () => {
    if (blocks_options?.length) {
      if(updateRoom?.block?.code){
        setSelectedBlock({
          value: updateBlock?.block.code,
          label: updateBlock?.block.name + ' ['+ updateBlock?.block.code +']',
        })
      } else if(parentBlock?.code){
        setSelectedBlock({
          value: parentBlock.code,
          label: parentBlock.name + ' ['+ parentBlock.code +']',
        })
      } else {
        setSelectedCampuses({})
      }
    }
    
  }

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const getFloorName = (number) => {
    const words = [
      'ground', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth',
      'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'
    ];
  
    const tens = [
      '', '', 'twentieth', 'thirtieth', 'fortieth', 'fiftieth', 'sixtieth', 'seventieth', 'eightieth', 'ninetieth'
    ];
  
    let floor_name = ''

    if (number < 20) {
      floor_name = words[number];
    } else {
      const tensPlace = Math.floor(number / 10);
      const onesPlace = number % 10;
      floor_name = tens[tensPlace] + (onesPlace !== 0 ? '-' + words[onesPlace] : '');
    }

    return floor_name.charAt(0).toUpperCase() + floor_name.slice(1) + ' Floor'

  };

  const floor_options = (block) => {
    const floor_count = block? block.floorCount : 1;

    const floor_options = [];

    for (let count = 0; count < floor_count; count++) {
      floor_options.push({
        label: getFloorName(count), value: count
      })
    }

    return floor_options
  }

  const query = GET_BLOCK_ROOMS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      code: updateRoom ? updateRoom.code : "",
      description: updateRoom ? updateRoom.description : "",
      blockUid: updateRoom ? updateRoom.blockUid : "",
      floorNumber: updateRoom ? updateRoom.floorNumber : "",
    },

    validationSchema: Yup.object().shape({
      blockUid: Yup.string().required("Block is required"),
      code: Yup.string().required("Room code is required"),
      floorNumber: Yup.string().required(" Floor number is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      create_room({
        variables: {
          inputs: [{ ...values, uid: updateRoom?.uid }],
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_BLOCK_ROOMS,
            variables: {
              blockUid: parentBlock.uid
            },
          },
        ],
        onCompleted: ({ registerRooms }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (registerRooms.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateRoom(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateRoom(true) // Close the modal
          }

          setSubmitting(false)
          showToast(
            registerRooms.message,
            registerRooms.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const generate_room_validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      blockUid: parentBlock?.uid?? "",
      floorNumber: 0,
      numberOfRooms: parentBlock?.roomCount?? 1,
    },

    validationSchema: Yup.object().shape({
      blockUid: Yup.string().required("Block is required"),
      floorNumber: Yup.string().required("Floor is required"),
      numberOfRooms: Yup.string().required("Number of rooms is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      generate_rooms({
        variables: {
          input: { ...values },
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_BLOCK_ROOMS,
            variables: {
              blockUid: parentBlock.uid
            },
          },
        ],
        onCompleted: ({ generateRooms }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (generateRooms.code === 8000) {
            generate_room_validation.resetForm()
            setGenerateModalOpen(false)
          } else {
            setGenerateModalOpen(true)
          }

          setSubmitting(false)
          showToast(
            generateRooms.message,
            generateRooms.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => {
          setGenerateModalOpen(true)
        }}
      >
        <i className="fas fa-bolt me-1" />
        Generate Rooms
      </Button>
      
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => {
          setModalOpen(true)
        }}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Room
      </Button>

      <Modal
        isOpen={modalOpen || !!updateRoom}
        onOpened={handleModalOpened}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateRoom ? "Edit Room" : "Add New Room"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-1">
              <label htmlFor="code" className="col-form-label">
                Code
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="code"
                placeholder="Enter Room Code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ""}
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-1">
              <label htmlFor="description" className="col-form-label">
                Description
              </label>
              <Input
                type="textarea"
                name="description"
                className="form-control"
                id="description"
                placeholder="Enter Room Description"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description && validation.errors.description
                    ? true
                    : false
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>

            <div className="row">
              <div className="col-6 mb-1">
                <label htmlFor="blockUid" className="col-form-label">
                  Block
                </label>
              
                <Select
                  id="blockUid"
                  name="blockUid"
                  placeholder="Select Parent Block"
                  required
                  value={selectedBlock}
                  isDisabled={parentBlock?.uid? true : false}
                  onChange={selectedOption => {
                    setSelectedBlock(selectedOption)
                    validation.setFieldValue("blockUid", selectedOption.value)
                  }}
                  options={blocks_options?.map(block => ({
                    value: block.uid,
                    label: block.name + ' ['+ block.code +']',
                    isSelected: block.uid == parentBlock?.uid
                  }))}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
                {validation.touched.block && validation.errors.block ? (
                  <FormFeedback type="invalid">
                    {validation.errors.block}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="col-6 mb-1">
                <label htmlFor="floor" className="col-form-label">
                  Floor:
                </label>
                <Select
                  id="block"
                  name="hostel"
                  placeholder="Select Floor"
                  onChange={selectedOption => {
                    validation.setFieldValue("floorNumber", selectedOption.value)
                  }}
                  options={floor_options(parentBlock)}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
                {validation.touched.block && validation.errors.block ? (
                  <FormFeedback type="invalid">
                    {validation.errors.block}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateRoom(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>

      <Modal
        isOpen={generateModalOpen}
        onOpened={handleModalOpened}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateRoom ? "Edit Room" : "Generate Rooms"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setGenerateModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            generate_room_validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">

            <div className="mb-1">
              <label htmlFor="blockUid" className="col-form-label">
                Block
              </label>
            
              <Select
                id="blockUid"
                name="blockUid"
                placeholder="Select Parent Block"
                required
                value={selectedBlock}
                isDisabled={parentBlock?.uid? true : false}
                onChange={selectedOption => {
                  setSelectedBlock(selectedOption)
                  generate_room_validation.setFieldValue("blockUid", selectedOption.value)
                }}
                options={blocks_options?.map(block => ({
                  value: block.uid,
                  label: block.name + ' ['+ block.code +']',
                  isSelected: block.uid == parentBlock?.uid
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
              {generate_room_validation.touched.block && generate_room_validation.errors.block ? (
                <FormFeedback type="invalid">
                  {generate_room_validation.errors.block}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-1">
                <label htmlFor="floorNumber" className="col-form-label">
                  Floor:
                </label>
                <Select
                  id="floorNumber"
                  name="floorNumber"
                  placeholder="Select Floor"
                  onChange={selectedOption => {
                    generate_room_validation.setFieldValue("floorNumber", selectedOption.value)
                  }}
                  options={floor_options(parentBlock)}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
                {generate_room_validation.touched.floorNumber && generate_room_validation.errors.floorNumber ? (
                  <FormFeedback type="invalid">
                    {generate_room_validation.errors.floorNumber}
                  </FormFeedback>
                ) : null}
              </div>

            <div className="mb-1">
              <label htmlFor="numberOfRooms" className="col-form-label">
                Number of Rooms
              </label>
              <Input
                type="number"
                step={1}
                name="numberOfRooms"
                className="form-control"
                id="numberOfRooms"
                placeholder="Enter Number of Rooms"
                onChange={generate_room_validation.handleChange}
                onBlur={generate_room_validation.handleBlur}
                value={generate_room_validation.values.numberOfRooms || ""}
                invalid={
                  generate_room_validation.touched.numberOfRooms && generate_room_validation.errors.numberOfRooms
                    ? true
                    : false
                }
              />
              {generate_room_validation.touched.numberOfRooms && generate_room_validation.errors.numberOfRooms ? (
                <FormFeedback type="invalid">
                  {generate_room_validation.errors.numberOfRooms}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setGenerateModalOpen(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Generate"
              type="primary"
              formik={generate_room_validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default RoomModal
