import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import showToast from "helpers/ToastHelper"
import { SpouseContext } from "./SpouseList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { CREATE_STAFF_SPOUSE } from "Modules/Users/Mutations/UserMutations"
import { GET_STAFF_SPOUSE } from "Modules/Users/Queries/UserQuery"
import { useMutation } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StaffProfileContext } from "../StaffProfile"
import { specifiedRules } from "graphql"

const SpouseModal = () => {
  const { updateStaffData, setUpdateStaffData } = useContext(StaffProfileContext)
  const { updateSpouseData, setUpdateSpouseData } = useContext(SpouseContext)
  const [spouse] = useMutation(CREATE_STAFF_SPOUSE)
  const [modalOpen, setModalOpen] = useState(false)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: updateSpouseData ? updateSpouseData.name : "",
      middleName: updateSpouseData ? updateSpouseData.middleName : "",
      lastName: updateSpouseData ? updateSpouseData.lastName : "",
      email: updateSpouseData ? updateSpouseData.email : "",
      phoneNumber: updateSpouseData ? updateSpouseData.phoneNumber : "",
      placeOfDomicile: updateSpouseData ? updateSpouseData.placeOfDomicile : "",
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      placeOfDomicile: Yup.string().required("Place of domicile is required"),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
    }),

    onSubmit: (values, { setSubmitting }) => {
      spouse({
        client: uaaGraphQLClient,
        variables: {
          inputs: { ...values, userUid: updateStaffData?.uid, uid: updateSpouseData?.uid },
        },
        refetchQueries: [
          {
            query: GET_STAFF_SPOUSE,
            variables: {
              userUid: updateStaffData?.uid,
            },
          },
        ],
        onCompleted: ({ registerStaffSpouses }) => {
          if (registerStaffSpouses.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateSpouseData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerStaffSpouses.message,
            registerStaffSpouses.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end">
      {/* {updateSpouseData?.length < 1 && ( */}
        <Button
        type="button"
        color="warning"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Add Your Spouse
      </Button>
      {/* )} */}
      
      <Modal
        isOpen={modalOpen || !!updateSpouseData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateSpouseData ? "Edit Spouse" : "Add My Spouse"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateSpouseData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="firstName" className="col-form-label">
                    First Name:
                  </label>
                  <Input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.firstName ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.firstName &&
                        validation.errors.firstName
                      )
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="middleName" className="col-form-label">
                    Middle Name:
                  </label>
                  <Input
                    type="text"
                    name="middleName"
                    className="form-control"
                    id="firstname"
                    placeholder="Short Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.middleName ||
                      ""
                    }
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="lastName" className="col-form-label">
                    Last Name:
                  </label>
                  <Input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="lastName"
                    placeholder="Enter last name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.lastName ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.lastName &&
                        validation.errors.lastName
                      )
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="email" className="col-form-label">
                    Email:
                  </label>
                  <Input
                    type="text"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.email ||
                      ""
                    } invalid={
                        !!(
                          validation.touched.email &&
                          validation.errors.email
                        )
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="col-form-label">
                    Phone Number:
                  </label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Enter phone number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.phoneNumber ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.phoneNumber &&
                        validation.errors.phoneNumber
                      )
                    }
                  />
                  {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phoneNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="placeOfDomicile" className="col-form-label">
                    Place of Domicile:
                  </label>
                  <Input
                    type="text"
                    name="placeOfDomicile"
                    className="form-control"
                    id="placeOfDomicile"
                    placeholder="Enter place of domicile"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.placeOfDomicile ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.placeOfDomicile &&
                        validation.errors.placeOfDomicile
                      )
                    }
                  />
                  {validation.touched.placeOfDomicile && validation.errors.placeOfDomicile ? (
                    <FormFeedback type="invalid">
                      {validation.errors.placeOfDomicile}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateSpouseData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default SpouseModal
