import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_PROGRAM_CATEGORY } from "../Mutations/ProgramCategoryMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_PROGRAM_CATEGORIES } from "../Queries/ProgramCategoryQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ProgramCategoryContext } from "./ProgrammeCategories"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"

const ProgrammeCategoryModal = () => {
  const { updateProgramCategoryData, setUpdateProgramCategoryData } =
    useContext(ProgramCategoryContext)
  const [ProgramCategory] = useMutation(CREATE_PROGRAM_CATEGORY, {client: registrationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_PROGRAM_CATEGORIES
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateProgramCategoryData ? updateProgramCategoryData.name : "",
      shortName: updateProgramCategoryData
        ? updateProgramCategoryData.shortName
        : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Program Category name is required"),
      shortName: Yup.string().required(
        "Program Category short name is required"
      ),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: values => {
      ProgramCategory({
        variables: {
          inputs: [{ ...values, uid: updateProgramCategoryData?.uid }],
        },
        client:registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_PROGRAM_CATEGORIES,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerProgramCategory }) => {
          if (registerProgramCategory.code === 8000) {
            resetForm()
            setModalOpen(false)
            setUpdateProgramCategoryData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateProgramCategoryData(true) // Close the modal
          }
          showToast(
            registerProgramCategory.message,
            registerProgramCategory.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Program Category
      </Button>
      <Modal
        isOpen={modalOpen || !!updateProgramCategoryData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramCategoryData
              ? "Edit Program Category"
              : "Add New Program Category"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name ||
                  updateProgramCategoryData?.name ||
                  ""
                }
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Short name
              </label>
              <Input
                type="text"
                name="shortName"
                className="form-control"
                id="firstname"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.shortName ||
                  updateProgramCategoryData?.shortName ||
                  ""
                }
                invalid={
                  validation.touched.shortName && validation.errors.shortName
                    ? true
                    : false
                }
              />
              {validation.touched.shortName && validation.errors.shortName ? (
                <FormFeedback type="invalid">
                  {validation.errors.shortName}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateProgramCategoryData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ProgrammeCategoryModal
