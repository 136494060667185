import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import * as Yup from 'yup'
//Import Breadcrumb
import ReactPaginate from "react-paginate"
import { useFormik } from "formik"
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Link, useNavigate} from "react-router-dom";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
const breadcrumbItem =  [
    {
        title: 'Finance Queries',
        path: '',
        isActive: true,
    },
]


const FinanceQueries = () => {
    const [pageSize, setPageSize] = useState(0),
        [queries, setQueries] = useState([]),
        [page, setPage] = useState(1),
        navigate = useNavigate(),
        [fetching, setFetching] = useState(true),
        [totalData, setTotalData] = useState(0),
        [searchLoading, setSearchLoading] = useState(false)

    useEffect(() => {
        // setFetching(true)
        setFetching(false)
        setTimeout(() => {
            fetchData()
        }, 1000);
    }, [])

    const handleClick = (data) => {
        fetchData(data.selected+1)
        // alert('Requesting Page no '+data.selected)
    }
    const searchValues = {
        search: '',
    }
    const searchSummarySchema = Yup.object().shape({
        search: Yup.string()
            .required(''),
    })


    const formikSearch = useFormik({
        initialValues:searchValues,
        validationSchema: searchSummarySchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            console.log('Requesting Page no ')


            // setQueries([])
            // setFetching(true)
            // setStatus('')
            // setPage(1)
            // setTotalData(0)
            // fetchData()
            // setTimeout(() => {
            //   setSearchLoading(false)
            //   setSubmitting(false)
            // },1000)
        }
    })

    const searchQuery = () => {
        const value = formikSearch.values.search
        if(value.length >= 4){
            // setFetching(true)
            setFetching(false)
            fetchData()
        }
    }
    const fetchData = (page = 1) =>{
        let data = {
            search:formikSearch.values.search,
        }
        setTimeout(() =>{
            // Fetch data Api
            setFetching(false)
            setPageSize(2)
            setTotalData(10)

        },1000)
    }

    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Finance Query">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle className="mb-1">
                                <Row>
                                    <Col className="col-6">
                                        <form className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onKeyUp={searchQuery}
                                                    placeholder="Search..."
                                                    {...formikSearch.getFieldProps('search')}
                                                />
                                                {!searchLoading && <span className="bx bx-search-alt" />}
                                                {searchLoading && (
                                                    <span className='indicator-progress' style={{display: 'block'}}>
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                                </span>
                                                )}
                                            </div>
                                        </form>
                                    </Col>
                                    <Col className="col-6">
                                        <div className="text-sm-end mt-3">
                                            {/*<Link to="/students/payments/queries/create" className="btn btn-primary me-1">Create New <i className="bx bxs-plus-circle"></i></Link>*/}
                                        </div>
                                    </Col>
                                    {/*/Students/Payments/services*/}
                                </Row>
                            </CardSubtitle>

                            {fetching ?
                                (
                                    <PlaceHolderLoader columSize={12} rows={3} type="table" />
                                )
                                :
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>#</Th>
                                                    <Th data-priority="0">Created At</Th>
                                                    <Th data-priority="1">Title</Th>
                                                    <Th data-priority="3">Description</Th>
                                                    <Th data-priority="2">Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>

                                            </Tbody>
                                        </Table>


                                    </div>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    );
};

export default FinanceQueries;