import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { connect } from "react-redux"
// Reactstrap
import { Dropdown } from "reactstrap"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import "assets/scss/sidebar.scss";
//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from "../../store/actions"
import LOGO from "../../assets/images/logo-dark.png"
import { useQuery } from "@apollo/client"
import {
  GET_ACTIVE_ADMISSION_WINDOW
} from "../../Modules/OnlineApplication/AccountRegistration/Queries/AcountRegistrationQueries"
import { onlineApplicationGraphQLClient } from "../../Modules/Authentication/ApolloClient"

const PublicEsbHeader = props => {
  const location = useLocation()
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  const {
    loading: admissionWindowLoading,
    error: admissionWindowError,
    data: admissionWindowData,
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: {
      applicationLevelCode: "BSC",
    },
    fetchPolicy: "network-only",
  })

  const window = admissionWindowData?.getActiveAdmissionWindow?.data

  return (
    <React.Fragment>
      <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
        <header id="page-topbar esb-page-topbar">
          <div className="navbar-header shadow p-3 bg-white rounded">
            <div className="d-flex">
              <Link
                to="/online/application"
                className="d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none header-logo"
              >
                <img src={LOGO} width="auto" height="40" alt="sua-esb" />
              </Link>

              <Link
                to="/online/application"
                className="d-none d-md-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none header-logo"
              >
                <h4>Online Application System</h4>
              </Link>
              <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={megaMenu}
                toggle={() => {
                  setmegaMenu(!megaMenu)
                }}
              ></Dropdown>
            </div>
            <div className="text-center d-none d-md-flex">
              {
                window && (
                  <marquee width="100%" direction="left" height="20px">
                    <strong className="text-primary">
                      Application for Bachelor, Certificate and Diploma Programmes
                      are OPEN.
                    </strong>
                  </marquee>
                )
              }
            </div>
            <div className="d-flex">
              <Link to="/login" className="text-primary font-weight-bolder">
                <strong>Login</strong>
              </Link>
            </div>
          </div>
        </header>
      </div>
    </React.Fragment>
  )
}

PublicEsbHeader.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(PublicEsbHeader))
