import React from "react"
import { Route, Routes } from "react-router"
import Pages404 from "Modules/Utility/pages-404"
import StateList from "../Views/StateList"

const StateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<StateList />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default StateRoutes
