import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
import CourseAllocationList from "../Views/CourseAllocationList";

const CourseAllocationRoutes = () => {

    return (
        <Routes>
            <Route path="/allocations" element={<CourseAllocationList />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )

}

export default CourseAllocationRoutes;