import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_BLOCK } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import showToast from "helpers/ToastHelper"
import { GET_HOSTEL_BLOCKS, GET_HOSTELS } from "../Queries/AccommodationQueries"
import { BlockContext } from "./BlockList"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"

const BlockModal = () => {
  const { parentHostel, updateBlock, setUpdateBlock } = useContext(BlockContext)
  const [create_block] = useMutation(CREATE_BLOCK, {client: accomodationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const { loading, error, data, refetch } = useQuery(GET_HOSTELS, {
    client:accomodationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })
  const hostels_options = data?.getHostels?.data?.items

  const [selectedHostel, setSelectedHostel] = useState({});
  const [hasContinuousNumbering, setHasContinuousNumbering] = useState(false);

  const handleModalOpened = () => {
    if (hostels_options?.length) {
      if(updateBlock?.hostel?.code){
        setSelectedHostel({
          value: updateBlock?.hostel.code,
          label: updateBlock?.hostel.name + ' ['+ updateBlock?.hostel.code +']',
        })
      } else if(parentHostel?.code){
        setSelectedHostel({
          value: parentHostel.code,
          label: parentHostel.name + ' ['+ parentHostel.code +']',
        })
      } else {
        setSelectedHostel({})
      }
    }

    setHasContinuousNumbering(
      updateBlock?.hasContinuousNumbering ?? false
    )
    
  }

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_HOSTEL_BLOCKS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      hostelUid: parentHostel?.uid,// ? updateBlock.name : "",
      name: updateBlock ? updateBlock.name : "",
      code: updateBlock ? updateBlock.code : "",
      description: updateBlock ? updateBlock.description : "",
      floorCount: updateBlock ? updateBlock.floorCount : 1,
      roomCount: updateBlock ? updateBlock.roomCount : 1,
      hasContinuousNumbering: updateBlock ? updateBlock.hasContinuousNumbering : false,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      code: Yup.string().required("Block code is required e.g Tz.."),
      //description: Yup.string().required(" description  is required"),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    

    onSubmit: (values, {setSubmitting}) => {
      create_block({
        variables: {
          inputs: [{ ...values, uid: updateBlock?.uid }],
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_HOSTEL_BLOCKS,
            variables: {
              hostelUid: parentHostel.uid,
            },
          },
        ],
        onCompleted: ({ registerBlocks: response }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (response.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateBlock(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateBlock(true) // Close the modal
            setSubmitting(false)
          }
          showToast(
            response.message,
            response.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Block
      </Button>
      <Modal
        isOpen={modalOpen || !!updateBlock}
        onOpened={handleModalOpened}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateBlock ? "Edit Block" : "Add New Block"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >

          <div className="modal-body">
            <div className='row'>            
              <div className="col-6 mb-1">
                <label htmlFor="code" className="col-form-label">
                  Code <span className="text-danger">*</span>: 
                </label>
                <Input
                  type="text"
                  name="code"
                  className="form-control"
                  id="code"
                  placeholder="Enter Block Code"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.code || ""}
                  invalid={
                    validation.touched.code && validation.errors.code
                      ? true
                      : false
                  }
                />
                {validation.touched.code && validation.errors.code ? (
                  <FormFeedback type="invalid">
                    {validation.errors.code}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="col-6 mb-1">
                <label htmlFor="name" className="col-form-label">
                  Name <span className="text-danger">*</span>: 
                </label>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Enter Block Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="mb-1">
              <label htmlFor="description" className="col-form-label">
                Description
              </label>
              <Input
                type="textarea"
                name="description"
                className="form-control"
                id="description"
                placeholder="Enter Block Description"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description && validation.errors.description
                    ? true
                    : false
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>

            <div className="row">
              <div className="col-4 mb-1">
                <label htmlFor="floorCount" className="col-form-label">
                  Floors <span className="text-danger">*</span>: 
                </label>
                <Input
                  type="number"
                  step={1}
                  name="floorCount"
                  className="form-control"
                  id="floorCount"
                  placeholder="Enter Number of floors"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.floorCount || ""}
                  invalid={
                    validation.touched.floorCount && validation.errors.floorCount
                      ? true
                      : false
                  }
                />
                {validation.touched.floorCount && validation.errors.floorCount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.floorCount}
                  </FormFeedback>
                ) : null}
              </div>
              
              <div className="col-4 mb-1">
                <label htmlFor="roomCount" className="col-form-label">
                  Rooms <span className="text-danger">*</span>: 
                </label>
                <Input
                  type="number"
                  step={1}
                  name="roomCount"
                  className="form-control"
                  id="roomCount"
                  placeholder="Enter Number of rooms"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.roomCount || ""}
                  invalid={
                    validation.touched.roomCount && validation.errors.roomCount
                      ? true
                      : false
                  }
                />
                {validation.touched.roomCount && validation.errors.roomCount ? (
                  <FormFeedback type="invalid">
                    {validation.errors.roomCount}
                  </FormFeedback>
                ) : null}
              </div>
              
              <div className="col-4 mb-1">
                <label htmlFor="hostelUid" className="col-form-label">
                  Hostel <span className="text-danger">*</span>: 
                </label>
              
                <Select
                  id="hostelUid"
                  name="hostelUid"
                  placeholder="Select Parent Hostel"
                  required
                  value={selectedHostel}
                  isDisabled={parentHostel?.uid? true : false}
                  onChange={selectedOption => {
                    setSelectedHostel(selectedOption)
                    validation.setFieldValue("hostelUid", selectedOption.value)
                  }}
                  options={hostels_options?.map(hostel => ({
                    value: hostel.uid,
                    label: hostel.name + ' ['+ hostel.code +']'
                  }))}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                />
                {validation.touched.hostelUid && validation.errors.hostelUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.hostelUid}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-1 mb-1">
                <div className="form-check">
                  <Input
                      onClick={(e) => {
                        validation.setFieldValue("hasContinuousNumbering", !hasContinuousNumbering)
                        setHasContinuousNumbering(!hasContinuousNumbering)
                      }}
                      checked={hasContinuousNumbering}
                      id="hasContinuousNumbering"
                      name="hasContinuousNumbering"
                      type="checkbox"
                      color="primary"
                      className="primary"
                  />
                  <label className="form-check-label" htmlFor="hasContinuousNumbering">
                    This block has {hasContinuousNumbering ? "continuously" : "per-floor"} numbered rooms
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateBlock(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default BlockModal
