import React, { useContext, useEffect, useState } from "react"
import { Form, Row, Col, Table } from "reactstrap"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link, useNavigate } from "react-router-dom"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "../SelfRegistration"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { STUDENT_FINAL_CONFIRMATION } from "Modules/Students/Mutations/StudentFinalConfirmation"
import { GET_USER_ASSIGNED_PERMISSION } from "Modules/Groups/Queries/GroupQueries"
import { REFRESH_MUTATION } from "Modules/Authentication/Mutations/LoginMutation"

const StudentFinalConfirmation = () => {
  const navigate = useNavigate()
  const { updateStudentData, setUpdateStudentData } = useContext(
    StudentProfileContext
  )
  const { toggleTab, handleSubmit } = useContext(StudentProfileContext)

  const refreshToken = JSON.parse(
    localStorage.getItem("authUser")
  )?.refreshToken

  const [Student] = useMutation(STUDENT_FINAL_CONFIRMATION, {
    client: uaaGraphQLClient,
  })

  const [fetchAssignedPermissions, setFetchAssignedPermissions] =
    useState(false)

  // const {
  //   data: assignedPermissionsData,
  //   loading: assignedPermissionsLoading,
  //   error: assignedPermissionsError,
  // } = useQuery(GET_USER_ASSIGNED_PERMISSION, {
  //   client: uaaGraphQLClient,
  //   variables: {
  //     userUid: updateStudentData?.uid,
  //   },
  //   skip: !updateStudentData?.uid || !fetchAssignedPermissions,
  //   onError: error => {
  //     console.error("Error fetching assigned permissions:", error)
  //   },
  // })

  // useEffect(() => {
  //   if (!assignedPermissionsLoading && assignedPermissionsData) {
  //     const authUser = JSON.parse(localStorage.getItem("authUser"))
  //     if (assignedPermissionsData.getUserPermissions.code === 8000) {
  //       authUser.data.authorities =
  //         assignedPermissionsData.getUserPermissions.data.authorities
  //       localStorage.setItem("authUser", JSON.stringify(authUser))
  //       navigate("/")
  //     } else {
  //       console.log(
  //         "Something went wrong",
  //         assignedPermissionsData.getUserPermissions.message
  //       )
  //       showToast("Failed to update your profile", "error")
  //     }
  //   }
  // }, [assignedPermissionsLoading, assignedPermissionsData])


  const {
    data: accessTokenData,
    loading: accessTokenLoading,
  } = useMutation(REFRESH_MUTATION, {
    client: uaaGraphQLClient,
    variables: { refreshToken },
    skip: !updateStudentData?.uid || !fetchAssignedPermissions,
    onError: error => {
      console.error("Error fetching assigned permissions:", error)
    },
  })

  useEffect(() => {
    if (!accessTokenLoading && accessTokenData) {

      if (!!accessTokenData.refreshToken.accessToken) {

        const authUser = JSON.parse(localStorage.getItem("authUser"))
        authUser.accessToken = accessTokenData.refreshToken.accessToken
        localStorage.setItem("authUser", JSON.stringify(authUser))

        navigate("/")
      } else {
        showToast("Failed to update your profile", "error")
      }

    }
  }, [accessTokenLoading, accessTokenData])

  const handlePrevious = () => {
    toggleTab(5) // Move to the previous tab
  }

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,

      initialValues: {
        userUid: updateStudentData ? updateStudentData?.uid : "",
      },

      onSubmit: (values, { resetForm }) => {
        Student({
          variables: {
            input: { ...values },
          },

          onCompleted: ({ studentSelfRegistration }) => {
            if (studentSelfRegistration.code === 8000) {
              //Grab permissions for this student
              setFetchAssignedPermissions(true)
              toggleTab(6)
            } else {
              console.log("Failed to update data")
            }
            showToast(
              studentSelfRegistration.message,
              studentSelfRegistration.code === 8000 ? "success" : "error"
            )
            validation.resetForm()
          },
          onError: error => {
            // Handle errors
            console.error("Mutation error:", error)
          },
        })
      },
    })

    const studentStatus = updateStudentData?.student?.status?.name

  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <Row>
          <Col lg="12">
            <div className="mb-3">
              <p className="text-muted text-center">
                <h5>Confirm Your Details</h5>
              </p>
            </div>
            <div className="table-responsive">
              <table
                id="tech-companies-1"
                className="table table-striped table-bordered"
                style={{ border: "2px solid #63a69f" }}
              >
                <tbody>
                  <tr>
                    <th>Full name</th>
                    <td>
                      {updateStudentData?.firstName +
                        " " +
                        updateStudentData?.middleName +
                        " " +
                        updateStudentData?.lastName}
                    </td>
                    {/* </tr>
                  <tr> */}
                    <th>Gender</th>
                    <td>{updateStudentData?.gender}</td>
                  </tr>
                  <tr>
                    <th>Marital Status</th>
                    <td>{updateStudentData?.maritalStatus?.name}</td>
                    {/* </tr>
                  <tr> */}
                    <th>Email</th>
                    <td>{updateStudentData?.email}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{updateStudentData?.phoneNumber}</td>
                    {/* </tr>
                  <tr> */}
                    <th>Place of Birth</th>
                    <td>{updateStudentData?.placeOfBirth}</td>
                  </tr>
                  <tr>
                    <th>Date of Birth</th>
                    <td>{updateStudentData?.dateOfBirth}</td>
                    {/* </tr>
                  <tr> */}
                    <th>Postal Address</th>
                    <td>{updateStudentData?.postalAddress}</td>
                  </tr>
                  <tr>
                    <th>Physical Address</th>
                    <td>{updateStudentData?.physicalAddress}</td>
                    {/* </tr>
                  <tr> */}
                    <th>NIDA No</th>
                    <td>{updateStudentData?.nin}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{updateStudentData?.country?.name}</td>
                    {/* </tr>
                  <tr> */}
                    <th>Region</th>
                    <td>
                      {updateStudentData?.student?.district?.region?.name}
                    </td>
                  </tr>
                  <tr>
                    <th>District</th>
                    <td>{updateStudentData?.student?.district?.name}</td>
                    {/* </tr>
                  <tr> */}
                    <th>Bank Account</th>
                    <td>{updateStudentData?.student?.accountNumber}</td>
                  </tr>
                  <tr>
                    <th>Bank Type Name</th>
                    <td>{updateStudentData?.student?.bank?.name}</td>
                    {/* </tr>
                  <tr> */}
                    <th>Sponsors</th>
                    <td>
                      {updateStudentData?.student?.sponsors?.map(sponsor => (
                        <ul key={sponsor?.uid}>
                          <li>{sponsor?.name}</li>
                        </ul>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
              {studentStatus && studentStatus == "UNREGISTERED" && (
                <div className="mb-3">
                  <p className="text-muted text-center">
                    If all information provided are correct then Click Confirm
                    button below
                  </p>
                  <div className="text-center mt-3">
                    <SubmitButtonHelper
                      type="primary"
                      name="Confirm ?"
                      formik={validation}
                    />
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="previous">
                <Link to="#" onClick={handlePrevious}>
                  {`< Previous`}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default StudentFinalConfirmation
