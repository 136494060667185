//REGISTE
import { gql } from '@apollo/client';
const GET_STUDENT_LIST_REPORT = gql`
  query GetRegisteredStudents($inputData: RegistrationReportInput!) {
      getRegisteredStudents(inputData: $inputData){
            status
            code
            message
            data{
              items{
                registrationNumber
                oldRegistrationNumber
                firstName
                lastName
                uid
                studyYear
                email
                phoneNumber
                gender
                formFourIndexNumber
                dateOfBirth
                accountNumber
              }
              totalCount
            }
      }
  }
`;


const GET_STUDENT_LIST_REPORT_XLS = gql`
  query getRegisteredStudentsXls($inputData: RegistrationReportInput!) {
    getRegisteredStudentsXls(inputData: $inputData){
            status
            code
            message
            data{
              base64Data
            }
      }
  }
`;

const GET_PROGRAM_REGISTERED_STUDENT_SUMMARY = gql`
  query GetProgramRegisteredStudentsReport{
        getProgramRegisteredStudentsReport{
            message
            status
            data{
              items{
                code
                name
                totalProgramStudent
                totalProgramMaleStudent
                totalProgramFemaleStudent
              }
            }
        }
  }
`;

const GET_CHANGE_PROGRAM_STUDENT_LIST = gql`
  query GetStudentProgramChangeReport{
        getStudentProgramChangeReport{
            message
            status
            data{
              items{
                fullName
                currentRegistrationNumber
                newProgram{
                    code
                    name
                 }
                currentProgram{
                    name
                    code
                }
                reason
                approveRemark
                approveStatus
              }
            }
        }
  }
`;


const GET_NHIF_STUDENT_LIST = gql`
  query GetNhifStudentsReport($pagination: PaginationInput!){
    getNhifStudentsReport(pagination: $pagination){
            message
            status
            data{
              items{
                FirstName
                MiddleName
                Surname
                AdmissionNo
                MobileNo
                NationalID
                Gender
                PayCntrlNum
                CNPaid
                NHIFMessage
                NHIFCNMessage
                MaritalStatus
                DateJoiningEmployer
                FormFourIndexNo
              }
            }
        }
  }
`;


const GET_RESULTS = gql`
  query GetResults {
    getExamCourseWorkResults {
      status
      code
      data {
        studentUid
        score
        programCourse {
          uid
          course {
            uid
            code
            name
          }
        }
      }
    }
  }
`

const GET_EXAM_RESULT_SUMMARIES = gql`
  query GetExamResultSummarie(
    $searchCriteria: ExamResultSummarySearchCriteria!
  ) {
    getExamResultSummaries(searchCriteria: $searchCriteria) {
      status
      code
      data {
        gender
        programCourseId
        studentUid
        registrationNumber
        firstName
        middleName
        lastName
        courseCode
        courseName
        cwPractical
        cwTheory
        cwScore
        ueOral
        ueScore
        ueTheory
        uePractical
        totalScore
        grade
        gradePoint
        programUid
        gradeRemark
      }
    }
  }
`


export { GET_RESULTS, GET_EXAM_RESULT_SUMMARIES, GET_STUDENT_LIST_REPORT,GET_PROGRAM_REGISTERED_STUDENT_SUMMARY,GET_CHANGE_PROGRAM_STUDENT_LIST,GET_NHIF_STUDENT_LIST,GET_STUDENT_LIST_REPORT_XLS }
