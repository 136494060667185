import { gql } from "@apollo/client"

const GET_SPECIAL_APPOINTMENTS = gql`
  query getStaffSpecialAppointments($pagination: PaginationInput!) {
    getStaffSpecialAppointments(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          appointmentLetter
          endDate
          responsibilities
          staffUid
          startDate
          uid
          appointmentType {
            code
            name
            uid
          }
          appointingOffice {
            code
            name
            uid
          }
          appointmentTitle {
            uid
            name
            code
          }
        }
      }
    }
  }
`

export { GET_SPECIAL_APPOINTMENTS }
