import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { CREATE_STATUS } from "../Mutations/StatusMutations"
import { GET_STATUS } from "../Queries/StatusQueries"
import { StatusContext } from "./StatusList"
import { GET_STATUS_TYPE } from "Modules/Lookups/StatusType/Queries/StatusTypeQueries"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const StatusModal = () => {
  
  const { updateStatusData, setUpdateStatusData } =
    useContext(StatusContext)
  const [Status] = useMutation(CREATE_STATUS)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.validation.resetForm()
    }
  }

  const { loading, error, data } = useQuery(GET_STATUS_TYPE, {
    client:uaaGraphQLClient,
    skip: !modalOpen,
    variables: {
      pagination: paginationHelper,
    },
  })

  if (error) return showToast("Failed to fetch status types", "error")
  const statusTypes = data?.getStatusTypes?.data.items

  const query = GET_STATUS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateStatusData ? updateStatusData.name : "",
      statusTypeUid: updateStatusData ? updateStatusData?.statusType?.uid : "",
      code: updateStatusData ? updateStatusData.code : "",
    },

    validationSchema: Yup.object().shape({
      statusTypeUid: Yup.string().required("Status Type is required"),
      name: Yup.string().required("Status name is required"),
      code: Yup.string().required("Status code is required"),

    }),

    onSubmit: (values, {setSubmitting}) => {
      Status({
        client:uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateStatusData?.uid }],
        },
        refetchQueries: [
          {
            client: uaaGraphQLClient,
            query: GET_STATUS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        skip: !modalOpen,
        onCompleted: ({ registerStatuses }) => {
          //updateFunction(client.cache, { data: { registerStatuss } });
          if (registerStatuses.code === 8000) {
            setModalOpen(false)
            setUpdateStatusData(false) // Close the modal
            validation.resetForm()
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerStatuses.message,
            registerStatuses.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Status
      </Button>
      <Modal
        isOpen={modalOpen || !!updateStatusData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateStatusData
              ? "Edit Status"
              : "Add New Status"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateStatusData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
          <div className="mb-3">
              <label>Status Type</label>
              <Select
                onChange={e => {
                  validation.setFieldValue("statusTypeUid", e.value);
                }}
                options={statusTypes?.map(statusType => ({
                  value: statusType.uid,
                  label: statusType.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999
                  })
                }}
                defaultValue={updateStatusData?.statusTypeUid}
                defaultInputValue={updateStatusData?.statusType?.name}
                />
              <Input
                name="statusTypeUid"
                placeholder="Select Status Type"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.statusTypeUid || updateStatusData?.statusType?.uid || ""}
                invalid={
                  !!(validation.touched.statusTypeUid && validation.errors.statusTypeUid)
                }
              />
              {validation.touched.statusTypeUid && validation.errors.statusTypeUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.statusTypeUid}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || updateStatusData?.name || ""
                }
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="firstname"
                placeholder="Enter Code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.code || updateStatusData?.code || ""
                }
                invalid={
                  !!(validation.touched.code && validation.errors.code)
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateStatusData(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default StatusModal
