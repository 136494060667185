import React from "react";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Card, CardBody, CardSubtitle, CardText, Col, Form, Row} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {onlineApplicationGraphQLClient} from "../../Authentication/ApolloClient";
import showToast from "../../../helpers/ToastHelper";
import {GET_APPLICANT_CONTROL_NUMBER, GET_APPLICANT_DETAILS} from "../Queries/ApplicantQueries";
import {useMutation, useQuery} from "@apollo/client";
import {GENERATE_APPLICANT_CONTROL_NUMBER,} from "../Mutations/ApplicantMutation";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {Link, useNavigate} from "react-router-dom";
import {LoaderPage} from "../../../helpers/LoaderPage";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"

const breadcrumbItem = [
    {
        title: "Payments",
        path: "",
        isActive: true,
    }
]
const ApplicantPayment = () => {
    const  ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data
    const navigate = useNavigate()
    const { loading:applicantLoading, error, data:applicantData } = useQuery(GET_APPLICANT_DETAILS, {
        client: onlineApplicationGraphQLClient,
        variables:{
            userUid: ExtractUserAuth?.uid
        },
        nextFetchPolicy: 'network-only'
    })
    const [generate] = useMutation(GENERATE_APPLICANT_CONTROL_NUMBER)
    const applicant = applicantData?.getApplicantDetails.data

    const { loading, error:controlNumberError, data:controlNumberData } = useQuery(GET_APPLICANT_CONTROL_NUMBER, {
        client: onlineApplicationGraphQLClient,
        skip: !applicant?.uid,
        variables:{
            applicantUid: applicant?.uid
        },
        nextFetchPolicy: 'network-only'
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            applicantUid:applicant?.uid
        },

        validationSchema: Yup.object().shape({
            applicantUid: Yup.string().required("Applicant Is Required"),
        }),
        onSubmit: (values, { setSubmitting }) => {
            generate({
                client: onlineApplicationGraphQLClient,
                variables: {
                    inputs: { ...values },
                },
                onCompleted: ({ generateApplicantControlNumber }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (generateApplicantControlNumber.code === 8000) {
                        setSubmitting(false)
                    } else {
                        setSubmitting(false)
                    }
                    showToast(
                        generateApplicantControlNumber.message,
                        generateApplicantControlNumber.code === 8000 ? "success" : "error"
                    )
                },
                refetchQueries: [
                    {
                        variables:{
                            applicantUid: applicant?.uid
                        },
                        query: GET_APPLICANT_CONTROL_NUMBER,
                    },
                ],
                onError: error => {
                    setSubmitting(false)
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
        },
    })
    if(applicantLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"} description={"Fetching Applicant Details"} />
    const controlNumber =  controlNumberData?.getApplicantControlNumber.data
    if(!applicant?.applicationLevelUid){
        navigate("/applicant/application-method")
    }
    // console.log(controlNumber)
    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={'Applicant'}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle>
                                <h4>Payment Details</h4>
                            </CardSubtitle>
                            {loading ? <div className="text-center">Check Control Number <i className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2"/></div>:
                            <>
                            {controlNumber ? <>

                                <Row>
                              <Col md={12}>
                                <Table
                                  id="tech-companies-1"
                                  className="table table-striped table-bordered"
                                  style={{ border: "2px solid #63a69f" }}
                                >
                                  <Thead>
                                    <Tr>
                                      <Th data-priority="1">Fee Name</Th>
                                      <Th data-priority="1">Control Number</Th>
                                      <Th data-priority="1">Amount</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                        <Tr>
                                          <Td>{controlNumber?.feeName}</Td>
                                            <Td><b>{controlNumber?.controlNumber}</b></Td>
                                          <Td>{controlNumber?.amount.toLocaleString()}/= {controlNumber?.currency}</Td>
                                        </Tr>
                                  </Tbody>
                                </Table>
                              </Col>
                            </Row>
                                    {/*<strong>Fee Name: </strong> {controlNumber?.feeName}<br/>*/}
                                    {/*<strong>Amount: </strong> {controlNumber?.amount.toLocaleString()}  <br/>*/}
                                    {/*<strong>Control Number: </strong> {controlNumber?.controlNumber} <br/>*/}
                                    {/*<strong>Currency: </strong> {controlNumber?.currency} <br/>*/}


                                    {!controlNumber?.controlNumber ?
                                        <>
                                            <CardText>
                                                <br/>
                                                <strong>NOTE!</strong> If the Control Number is empty please click refresh button to refresh it <br/>(<b>CONTROL NUMBER MAY TAKE UP TO TWO MINUTES UNTIL RECEIVED</b>)
                                            </CardText>
                                            <Form
                                                onSubmit={e => {
                                                    e.preventDefault()
                                                    validation.handleSubmit()
                                                    return null
                                                }}
                                            >
                                                <SubmitButtonHelper name="Refresh Control Number" type="info" formik={validation} />

                                            </Form>
                                        </>:
                                            <div className="text-center mt-4">
                                                <Link
                                                  to="/applicant/application-method"
                                                  className="btn btn-primary btn-sm mx-2"
                                                >
                                                    <i className="mdi mdi-arrow-left ms-1"></i> Previous
                                                </Link>
                                                <Link
                                                    to="/applicant/entry-qualification"
                                                    className="btn btn-primary  btn-sm"
                                                >
                                                    Proceed <i className="mdi mdi-arrow-right ms-1"></i>
                                                </Link>
                                            </div>

                                    }
                                </> :
                                <>

                                    <CardText>
                                        Your going to request <strong>{applicant?.applicationLevel}</strong> Level By
                                        using <strong>{applicant?.qualificationMethod}</strong> Qualification Method <br/>
                                        If Data above data is clear Please generate Control Number
                                    </CardText>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return null
                                        }}
                                    >
                                        <SubmitButtonHelper name="Generate Control Number" type="primary" formik={validation} />

                                    </Form>
                                </>}
                            </>}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}


export default ApplicantPayment;