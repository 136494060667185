import { gql } from "@apollo/client"

const CREATE_ADMISSION_YEARS = gql`
mutation RegisterAdmissionYear($inputs: [AdmissionYearInput!]!) {
  registerAdmissionYear(inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items {
        uid
        name
        status
        startDate
        endDate
     }
    }
  }
}
`

const DELETE_ADMISSION_YEARS = gql`
  mutation RemoveAdmissionYear($uid: String!) {
    removeAdmissionYear(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_ADMISSION_YEARS, DELETE_ADMISSION_YEARS }
