import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "Modules/Utility/pages-404";
import ProgramList from "../Views/ProgramLIst";
import ViewProgram from "../Views/ViewProgram";
import ProgramSemisterRoutes from "../../ProgramSemister/Routes/ProgramSemisterRoutes";
import ProgramSemisterList from "../../ProgramSemister/Views/ProgramSemisterList"

const ProgramRoutes = () => {
  console.log("PROGRAM LIST\n")
    return (
        <Routes>
            <Route path="/" element={<ProgramList />} />
            <Route index={true} path="/view" element={<ViewProgram />} />
            <Route path="/program-semester-list" element={<ProgramSemisterList />} />
          <Route index={true} path="/program-semester/*" element={<ProgramSemisterRoutes />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>

    )
}

export default ProgramRoutes;