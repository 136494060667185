import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import {CourseContext} from "./CourseList";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {limit} from "../../../helpers/UrlHelper";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CREATE_COURSE} from "../Mutations/CourseMutations";
import {useNavigate} from "react-router-dom";
import {GET_DEPARTMENT} from "../../Departments/Queries/DepartmentQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../helpers/ToastHelper";
import Select from "react-select";
import {GET_COURSES} from "../Queries/CourseQueries";

const CourseFormModel = props => {
    const {courseData,setCourseData} = useContext(CourseContext)
    const [modalOpen, setModalOpen] = useState(false)
    let course = courseData
    const pagination = { offset: 0, limit: limit, search: null }

    const [saveCourse] = useMutation(CREATE_COURSE)

    const navigate = useNavigate();

    const { loading:depLoading,error, data:depart } = useQuery(GET_DEPARTMENT, {
        client:uaaGraphQLClient,
        variables: {
            pagination:paginationHelper,
        }
    })
    // if (error) return showToast("Failed to fetch Departments", "error")
    let departments = depart?.getDepartments?.data?.items

    const [
        searchDepartments,
        { loading: depSearchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_DEPARTMENT, {client: uaaGraphQLClient})


    const searchDepartment = (value) => {
        let data = {
            search: value,
        }

        if(value.length >= 2){
            searchDepartments({
                variables: {pagination: {...pagination, ...data}},
            })
        }
    }


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: course?.name,
            code: course?.code,
            offered: course?.offered,
            departmentUid: course?.departmentUid,
            description: course?.description,
        },

        validationSchema: Yup.object().shape({
            name: Yup.string().required("Course name is required"),
            code: Yup.string().required("Course code is required"),
            offered: Yup.bool().required("Course Status is required"),
            departmentUid: Yup.string().required("Course Department is required"),
            // description: Yup.number().required("Program Duration is required"),
        }),
        onSubmit: (values, {resetForm, setSubmitting}) => {
            saveCourse({
                client: registrationGraphQLClient,
                variables: {
                    inputs: [{...values, uid: course?.uid}],
                },
                refetchQueries: [
                    {
                        query: GET_COURSES,
                        variables: {
                            pagination: pagination,
                        },
                    },
                ],
                onCompleted: ({registerCourses}) => {
                    if (registerCourses.code === 8000) {
                        showToast(registerCourses.message, "success")
                        navigate("/examination/courses/view", {state: {course: registerCourses.data.items[0]}})
                    } else {
                        showToast(
                            registerCourses.message,
                            registerCourses.code === 8000 ? "success" : "error"
                        )
                    }
                    setSubmitting(false);
                },
                onError: error => {
                    // Handle errors
                    setSubmitting(false);
                    console.error("Mutation error:", error);
                },
            }).then(r  => true);
        },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Course
            </Button>
            <Modal
                isOpen={modalOpen || !!course}
                backdrop={"static"}
                id="staticBackdrop"
                // size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!course ? "Edit Course" : "Add New Course"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <Row>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label for="firstname">
                                        Course name
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="firstname"
                                        name="name"
                                        placeholder="Enter Course Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name}
                                        invalid={
                                            !!(validation.touched.name && validation.errors.name)
                                        }
                                    />
                                    {
                                        validation.touched.name && validation.errors.name ? (
                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                        ): null
                                    }
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="middlename">
                                        Course Code
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="middlename"
                                        name="code"
                                        placeholder="Enter Course code"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.code}

                                        invalid={
                                            !!(validation.touched.code && validation.errors.code)
                                        }
                                    />
                                    {
                                        validation.touched.code && validation.errors.code ? (
                                            <FormFeedback type="invalid">{validation.errors.code}</FormFeedback>
                                        ) : null
                                    }
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Is Offered?</Label>
                                    <Select
                                        styles={{
                                            menu: base => ({
                                                ...base,
                                                position: "absolute",
                                                zIndex: 9999
                                            })
                                        }}
                                        // value={selectedMulti2}
                                        onChange={(e) => {
                                            validation.setFieldValue('offered',e.value)
                                        }}
                                        options={[
                                            {
                                                label:"Yes",
                                                value:1
                                            },
                                            {
                                                label:"No",
                                                value:0
                                            }
                                        ]}
                                        defaultValue={course?.offered}
                                        defaultInputValue={
                                            ()=> {
                                                if (course){
                                                    if(course.offered) return 'YES'
                                                    else return 'NO'
                                                }
                                            }
                                        }
                                        className="select2-selection"
                                    />
                                    <Input
                                        name="departmentUid"
                                        placeholder=""
                                        type="hidden"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.offered || ""}
                                        invalid={
                                            !!(validation.touched.offered && validation.errors.offered)
                                        }
                                    />
                                    {validation.touched.offered &&
                                    validation.errors.offered ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.offered}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="12">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Course Department</Label>
                                    <Select
                                        styles={{
                                            menu: base => ({
                                                ...base,
                                                position: "absolute",
                                                zIndex: 9999
                                            })
                                        }}
                                        // value={selectedMulti2}
                                        onChange={(e) => {
                                            validation.setFieldValue('departmentUid',e.value)
                                        }}
                                        onInputChange={
                                            (e) => {
                                                searchDepartment(e)
                                            }
                                        }
                                        defaultValue={course?.departmentUid}
                                        // defaultInputValue={}
                                        options={departments?.map(dep => ({
                                            value: dep.uid,
                                            label: dep.name+' ('+dep.code+')'
                                        }))}
                                        className="select2-selection"
                                        isLoading={depLoading || depSearchLoading}
                                    />
                                    <Input
                                        name="departmentUid"
                                        placeholder=""
                                        type="hidden"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.departmentUid || ""}
                                        invalid={
                                            !!(validation.touched.departmentUid && validation.errors.departmentUid)
                                        }
                                    />
                                    {validation.touched.departmentUid &&
                                    validation.errors.departmentUid ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.departmentUid}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label for="description">
                                        Description
                                    </Label>
                                    <Input
                                        type="textarea"
                                        rows={5}
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        placeholder="Enter Course description"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ''}

                                        invalid={
                                            !!(validation.touched.code && validation.errors.description)
                                        }
                                    />
                                    {
                                        validation.touched.description && validation.errors.description ? (
                                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                        ) : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setCourseData(false)
                                validation.resetForm();
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default CourseFormModel;