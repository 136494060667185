import React from "react"
import { Route, Routes } from "react-router"
import Pages404 from "Modules/Utility/pages-404"
import TeachingAssessmentView from "../Views/TeachingAssessmentView"
import TeachingCourseAssessmentParent from "../Views/Questions/TeachingCourseAssessmentParent"
import AsimsDashboard from '../../ASIMS/views/asimsDashboard';

const TeachingAssessmentRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<AsimsDashboard />} />
      <Route path="/courses" element={<TeachingAssessmentView />} />
      <Route path="/course/:uid" element={<TeachingCourseAssessmentParent />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default TeachingAssessmentRoutes
