import { gql } from "@apollo/client"

const REGISTER_STUDENT_COURSE_LEARN_OUTCOME_ASSESSMENT_RESULT = gql`
  mutation RegisterStudentCourseLeanOutcomeAssessmentResult(
    $inputs: StudentCourseLearningOutcomeAssessmentInput!
  ) {
    registerStudentCourseLearnOutcomeAssessmentResult(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT = gql`
  mutation RegisterStudentProgramCourseAssessmentResult(
    $inputs: StudentCourseAssessmentInput!
  ) {
    registerStudentProgramCourseAssessmentResult(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const REGISTER_STUDENT_LEARN_OUTCOME_ASSESSMENT_RESULTS = gql`
  mutation RegisterStudentCourseLeanOutcomeResult(
    $inputs: StudentCourseLearningOutcomeAssessmentInput!
  ) {
    registerStudentCourseLearnOutcomeAssessmentResult(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const REGISTER_TEACHING_CONTINOUS_COURSE_ASSESSMENT = gql`
  mutation RegisterTeachingContinousCourseAssessment(
    $inputs: TeachingContinuousCourseAssessmentInput!
  ) {
    registerTeachingAndContinuousCourseAssessment(inputs: $inputs) {
      status
      code
      message
    }
  }
`

export {
  REGISTER_STUDENT_COURSE_LEARN_OUTCOME_ASSESSMENT_RESULT,
  REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT,
  REGISTER_STUDENT_LEARN_OUTCOME_ASSESSMENT_RESULTS,
  REGISTER_TEACHING_CONTINOUS_COURSE_ASSESSMENT
}
