import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  Button,
  Table,
} from "reactstrap"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link } from "react-router-dom"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useParams } from "react-router"
import { TeachingAssessmentProfileContext } from "./TeachingCourseAssessmentParent"
import {
  GET_TEACHER_CONTINOUS_COURSE_ASSESSMENT_RESULT,
} from "Modules/TeachingAssessment/Queries/TeachingAssessmentQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { REGISTER_TEACHING_CONTINOUS_COURSE_ASSESSMENT } from "Modules/TeachingAssessment/Mutations/TeachingAssessmentMutations"
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useFormik } from "formik"

// getCourseLearnOutcome
const QuestionTen = () => {
  const { uid } = useParams()

  const { updateTeachingAssessmentData } = useContext(
    TeachingAssessmentProfileContext
  )

  const [currentPage, setCurrentPage] = useState(0)
  const { toggleTab } = useContext(TeachingAssessmentProfileContext)
  const [isSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(100)
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const [registerLearnOutcomeCourseAssessment] = useMutation(
    REGISTER_TEACHING_CONTINOUS_COURSE_ASSESSMENT,
    {
      client: registrationGraphQLClient,
    }
  )

  const [answers, setAnswers] = useState([])

  const handleCheckboxChange = event => {
    const { name, value } = event.target

    // Check if an answer for the current outcome already exists in the answers array
    const existingAnswerIndex = answers.findIndex(
      answer => answer.courseLeanOutcomeUid === name
    )

    if (existingAnswerIndex !== -1) {
      // If an answer exists, update its value
      const updatedAnswers = [...answers]
      updatedAnswers[existingAnswerIndex].answer = parseInt(value, 10) // Convert value to integer
      setAnswers(updatedAnswers)
    } else {
      // If no answer exists, add a new answer to the answers array
      setAnswers([
        ...answers,
        { assessmentId: parseInt(name, 10), answer: value },
      ])
    }
  }

  const handlePrevious = () => {
    toggleTab(8) // Move to the previous tab
  }

  const handleNext = () => {
    setLoading(true)
    registerLearnOutcomeCourseAssessment({
      variables: {
        inputs: {
          studentCourseRegistrationUid: uid,
          answers: answers,
        },
      },
      onCompleted: ({ registerTeachingAndContinuousCourseAssessment }) => {
        if (registerTeachingAndContinuousCourseAssessment.code === 8000) {
          showToast(
            registerTeachingAndContinuousCourseAssessment.message,
            "success"
          )
          setLoading(false)
          toggleTab(10) // Move to the next tab
        } else {
          showToast(
            registerTeachingAndContinuousCourseAssessment.message,
            registerTeachingAndContinuousCourseAssessment.code === 8000
              ? "success"
              : "error"
          )
          setLoading(false)
          toggleTab(10) // Move to the next tab
        }
      },
      onError: error => {
        setLoading(false)
        console.error("Mutation error:", error)
      },
    })
  }

  console.log(answers)

  const {
    loading: Loading,
    error: Error,
    data: learningOutcomeData,
  } = useQuery(GET_TEACHER_CONTINOUS_COURSE_ASSESSMENT_RESULT, {
    client: registrationGraphQLClient,
    variables: {
      studentCourseRegistrationUid: uid ? uid : "",
    },
    skip: !uid,
    fetchPolicy: "network-only",
  })

  const learningOutComes =
    learningOutcomeData?.getTeachingAndContinuousCourseAssessment?.data

  const validation = useFormik({
    initialValues: {
      payload: {},
    },
  })

  const numberedItems = `i.   \nii.  \niii. `

  return (
    <div className="mt-3 wizard">
      {Loading ? (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <div className="mb-3">
                  <Label className="font-weight-bolder">
                    Please assess the following aspects using the following
                    scale:{" "}
                    <strong>
                      5 = Agree completely; 4 = Agree; 3 = Moderately agree 2 =
                      Satisfactory; 1 = Do not agree
                    </strong>
                  </Label>
                  <div>
                    <table className="table bg-white">
                      <thead className="text-center">
                        <tr>
                          <th>S/N</th>
                          <th>Learn outcome</th>
                          <th>Answer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {learningOutComes &&
                          learningOutComes
                            .slice(startIndex, endIndex)
                            .map((outcome, index) => (
                              <tr key={outcome?.courseLeanOutcomeUid}>
                                <td className="text-center">
                                  {startIndex + index + 1}
                                </td>
                                <td className="text-wrap">
                                  {outcome?.assessment}
                                </td>
                                <td className="text-center">
                                  {outcome?.type === "radio" ? (
                                    <>
                                      <Label>
                                        <Input
                                          type="radio"
                                          value="1"
                                          defaultChecked={
                                            outcome?.answer === "1" && true
                                          }
                                          name={outcome?.assessmentId}
                                          onChange={handleCheckboxChange}
                                        />{" "}
                                        1
                                      </Label>
                                      <br />
                                      <Label>
                                        <Input
                                          type="radio"
                                          value="2"
                                          defaultChecked={
                                            outcome?.answer === "2" && true
                                          }
                                          name={outcome?.assessmentId}
                                          onChange={handleCheckboxChange}
                                        />{" "}
                                        2
                                      </Label>
                                      <br />
                                      <Label>
                                        <Input
                                          type="radio"
                                          value="3"
                                          defaultChecked={
                                            outcome?.answer === "3" && true
                                          }
                                          name={outcome?.assessmentId}
                                          onChange={handleCheckboxChange}
                                        />{" "}
                                        3
                                      </Label>
                                      <br />
                                      <Label>
                                        <Input
                                          type="radio"
                                          value="4"
                                          defaultChecked={
                                            outcome?.answer === "4" && true
                                          }
                                          name={outcome?.assessmentId}
                                          onChange={handleCheckboxChange}
                                        />{" "}
                                        4
                                      </Label>
                                      <br />
                                      <Label>
                                        <Input
                                          type="radio"
                                          value="5"
                                          defaultChecked={
                                            outcome?.answer === "5" && true
                                          }
                                          name={outcome?.assessmentId}
                                          onChange={handleCheckboxChange}
                                        />{" "}
                                        5
                                      </Label>
                                    </>
                                  ) : (
                                    outcome?.type === "text" && (
                                      <textarea
                                        rows={4}
                                        cols={4}
                                        name={outcome?.assessmentId}
                                        className="form-control"
                                        onChange={handleCheckboxChange}
                                        style={{
                                          whiteSpace: "pre-wrap",
                                          width: 300,
                                        }}
                                      >
                                        {outcome?.hasAnswer === true
                                          ? outcome?.answer
                                          : numberedItems}
                                      </textarea>
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="wizard">
            <div className="actions">
              <ul>
                <li className="previous">
                  <Link to="#" onClick={handlePrevious}>
                    {`< Previous`}
                  </Link>
                </li>
                {answers.length >= 1 && (
                  <li className="previous">
                    <Link to="#" onClick={handleNext}>
                      {loading ? <>Please wait..</> : <>{`Submit`}</>}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default QuestionTen
