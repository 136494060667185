import React from "react"
const { Link } = require("react-router-dom")

export const forwardStatus = (forward_status) => {
  const statuses = [
    {
      id: 1,
      user: "Instructor",
    },
    {
      id: 2,
      user: "HOD",
    },
    {
      id: 3,
      user: "Principal",
    },
    {
      id: 4,
      user: "Published",
    },
  ]

  return (
    <div className="avatar-group float-start" style={{ float:"left" }}>
      {statuses.map((data, index) => (
        <div className="avatar-group-item" key={data.id} title={data.user}>
          <Link to="#" className="d-inline-block">
            <div className="avatar-exs">
              {forward_status >= data.id ? (
                <span className="avatar-title rounded-circle bg-success text-white font-size-16">
                  <i className="mdi mdi-account-check"></i>
                </span>
              ) : (
                <span className="avatar-title rounded-circle bg-danger text-white font-size-16">
                  <i className="mdi mdi-account-cancel"></i>
                </span>
              )}
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}
