import { gql } from "@apollo/client"

const FORWARD_PROGRAM_RESULT_BY_PRINCIPAL = gql`
    mutation ForwardCourseResultByPrincipal($programSemesterUids: [String!]!) {
        forwardCourseResultByPrincipal(programSemesterUids: $programSemesterUids) {
            status
            code
            data
            message
        }
    }
`

const UNPUBLISH_PROGRAM_SEMESTER_RESULTS = gql `
    mutation UnpublishProgramSemesterResults($programSemesterUids: [String!]!) {
        unPublishSemesterExamResultByProgramSemesterUids(programSemesterUids:$programSemesterUids){
            status
            code
            message
            data
        }
    }
`

const UNPUBLISH_ALL_SEMESTER_RESULTS = gql `
    mutation UnpublishAllSemesterResult($academicYearUid: String!, $semester: Int!) {
        unPublishAllSemesterExamResult(academicYearUid: $academicYearUid, semester: $semester){
            status
            code
            message
            data
        }
    }
`

export {
    FORWARD_PROGRAM_RESULT_BY_PRINCIPAL,
    UNPUBLISH_PROGRAM_SEMESTER_RESULTS,
    UNPUBLISH_ALL_SEMESTER_RESULTS }
