import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentReportRoutes from "./Students/Routes/StudentReportRoutes";
import Pages404 from "../Utility/pages-404";
import StudentListSummaryReport from "./Students/Views/StudentListSummaryReport";
import StudentChangeProgramReport from "./Students/Views/StudentChangeProgramReport";
import StudentNHIFReport from "./Students/Views/StudentNHIFReport";
import StudentChangeProgramProcessedReport from "./Students/Views/StudentChangeProgramProcessedReport";
import StudentExaminationResultsSummary from "./Students/Views/StudentExaminationResultsSummary";
import SemesterExaminationResultsSummary from "./Results/Views/SemesterExaminationResultsSummary";
import SemesterCourseResults from "./Results/Views/SemesterCourseResults";
import AsimsDashboard from "../ASIMS/views/asimsDashboard";
import SingleStudentExamResults from "../Students/Views/Examinations/SingleStudentExamResults";
import {ExaminationReportRoutes} from "../../Routes/HelperRoutes";
import SignaturePadRoutes from "../SignaturePad/Routes/SignaturePadRoutes";
const ReportRoutes = () => {
    return (
        <Routes>
            <Route path="dashboard" element={<AsimsDashboard />} />
            <Route path="/students" element={<StudentReportRoutes />} />
            <Route path="/semester_course_results" element={<SemesterCourseResults />} />
            <Route path="/examination/*" element={<ExaminationReportRoutes />} />
            <Route path="/change_program" element={<StudentChangeProgramReport />} />
            <Route path="/online-application" element={<StudentChangeProgramReport />} />
            <Route path="/student_nhif_reports" element={<StudentNHIFReport />} />
            <Route path="/processed_change_program" element={<StudentChangeProgramProcessedReport />} />
            <Route path="/program/summary" element={<StudentListSummaryReport />} />
            <Route index={true} path="/signaturepad/*" element={<SignaturePadRoutes />} />
            <Route index={true} path="/student-examinations" element={<SingleStudentExamResults/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default ReportRoutes;