// export default DisabilityList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import DisabilityModal from "./DisabilityModal"
import { GET_DISABILITIES } from "../Queries/DisabilityQueries"
import { DELETE_DISABILITY } from "../Mutations/DisabilityMutations"
import { limit } from "helpers/UrlHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"


const breadcrumbItem = [
  {
    title: "Disability List",
    path: "",
    isActive: true,
  },
]

export const DisabilityContext = createContext() //Create data to transfer to child modal

const DisabilityList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedDisability, setSelectedDisability] = useState(null) //Get data for deleting
  const [updateDisabilityData, setUpdateDisabilityData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_DISABILITIES, {
    client:uaaGraphQLClient,
    variables: {
      pagination,
    },
  })

  const [searchDisabilityes,{ loading: searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_DISABILITIES,{
    client:uaaGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected;
    fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteDisability] = useMutation(DELETE_DISABILITY, {
    client:uaaGraphQLClient,
    variables: { uid: selectedDisability?.uid },
    refetchQueries: [
      {
        query: GET_DISABILITIES,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteDisability = (dissability) => {
    setSelectedDisability(dissability);
    showDeleteWarning(() => {
      performUserDeletion()
    })
  }

  const performUserDeletion = async () => {
    try {
      const response = await deleteDisability()
      showToast(response.data.removeDisability.message, "success")
      console.log("User deleted successfully:", response)
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
    )
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchDisabilityes({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    console.log("SearchedInput", value);
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    }
  }

  const Disabilities = data?.getDisabilities.data?.items
  const searchDisabilities = searchData?.getDisabilities?.data?.items
  const results = searchDisabilities ? searchDisabilities : Disabilities
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getDisabilities.data.totalCount || searchData?.getDisabilities?.data?.items || 0

  return (
    <DisabilityContext.Provider value={{ updateDisabilityData, setUpdateDisabilityData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Disability List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <DisabilityModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results
                            .slice(startIndex, endIndex)
                            .map((Disability, index) => (
                              <Tr key={Disability?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{Disability?.name}</Td>
                                <Td>
                                <DropDownActionHelper
                                  data={Disability}
                                  onUpdate={setUpdateDisabilityData}
                                  onDelete={handleDeleteDisability}
                                />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </DisabilityContext.Provider>
  )
}

export default DisabilityList
