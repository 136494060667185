//REGISTE
import { gql } from '@apollo/client';


const GET_ALL_STUDENT_MANU_SCRIPTS = gql`
  query GetManuscripts($pagination: PaginationInput!) {
    getManuscripts(pagination: $pagination){
      status
      code
      message
          data{
              items{
                uid
                studentUid
                title
                publicationDate
                description
                publicationStatus
                registrationNumber
                fullName
                studentUid
                status
              }
              totalCount
            }
    }
  }
`;

const GET_ALL_STUDENT_MANUSCRIPT_BY_UID = gql`
  query GetStudentManuscriptByUid($uid: String!) {
    getStudentManuscriptByUid(uid:$uid){
        status
        code
        message
        data{
          uid
          studentUid
          title
          publicationDate
          description
          status
          publicationStatus
        }
    }
  }
`;



export {GET_ALL_STUDENT_MANU_SCRIPTS,GET_ALL_STUDENT_MANUSCRIPT_BY_UID }