import {Button, Card, CardBody, Col, Form, FormFeedback, Input, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import profileImg from "../../../../assets/images/users/blank.png";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {UPLOAD_USER_AVATAR} from "../../../Users/Mutations/UserMutations";
import {useFormik} from "formik";
import * as Yup from "yup";
import {uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import showToast from "../../../../helpers/ToastHelper";
import PropTypes from "prop-types";
import {GET_USER_DOCUMENT} from "../../../Users/Queries/UserQuery";
import {ProfileMenuContext} from "../../../../components/CommonForBoth/TopbarDropdown/ProfileMenu";
const StudentProfileImageUpdate = props => {
    const { setUpdateProfileModalOpen } = useContext(ProfileMenuContext)
    const [isChanged, setIsChanged] = useState(false)
    let [photo, setPhoto] = useState(profileImg)
    const {userUid} = props
    // console.log(userUid)
    // if(!userUid) return showToast("Technical Error, please pass userUId", "error")
    // console.log(userUid)
    const { loading,error,data } = useQuery(GET_USER_DOCUMENT, {
        client: uaaGraphQLClient,
        variables: {
            userUid: userUid,
            documentType: 'profile'
        },
        fetchPolicy: "no-cache",
        skip:!userUid
    })
    // console.log(data)
    const [showBtn, setShowBtn] = useState(false)
    const [uploadAvatar] = useMutation(UPLOAD_USER_AVATAR)

    let [
        searchImage,
        { loading: imageLoad, error: imageError, data: searchData },
    ] = useLazyQuery(GET_USER_DOCUMENT, {client: uaaGraphQLClient})

    const imageChange = e => {
        setIsChanged(true)
        if (e.target.files && e.target.files.length > 0) {
            setPhoto(URL.createObjectURL(e.target.files[0]))
            validationType.setFieldValue("file", e.target.files[0])
        }
    }
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            file: "",
        },
        validationSchema: Yup.object().shape({
            file: Yup.mixed().test("fileType", "Invalid file format", value => {
                if (!value) {
                    return false // No file provided
                }
                const supportedFormats = ["image/jpeg", "image/png", "image/gif"]
                return value && supportedFormats.includes(value.type)
            }),
        }),
        onSubmit: (values, { setSubmitting }) => {
            const reader = new FileReader();
            (reader.onload = () => {
                const profileImage = reader.result.split(",")[1] // Remove the prefix
                const userData = {
                    userUid: userUid,
                    file: profileImage,
                }
                uploadAvatar({
                    client: uaaGraphQLClient,
                    variables: {
                        uploadInput: userData,
                    },
                    // refetchQueries: [
                    //     {
                    //         query: GET_USER_DOCUMENT,
                    //         variables: {
                    //             userUid: userUid,
                    //         },
                    //     },
                    // ],
                    onCompleted: ({ uploadAvatar }) => {
                        //updateFunction(client.cache, { data: { registerStaffChildren } });
                        if (uploadAvatar.code === 8000) {
                            searchImage({
                                variables: {
                                    userUid: userUid,
                                    documentType: 'profile'
                                },
                            })
                            setUpdateProfileModalOpen(false)
                            setShowBtn(false)
                            setIsChanged(false)
                            validationType.resetForm()
                        } else {
                            setSubmitting(false)
                        }
                        showToast(
                            uploadAvatar.message,
                            uploadAvatar.code === 8000 ? "success" : "error"
                        )
                    },
                    onError: error => {
                        setSubmitting(false)
                    },
                })
            }),
                // Read the birthCertificate file as a DataURL
                reader.readAsDataURL(values.file)
        },
    })

    if(data?.getUserDocuments?.data?.base64doc && !validationType.values.file)
        photo = "data:image/jpeg;base64," +data?.getUserDocuments?.data?.base64doc

    if(searchData?.getUserDocuments?.data?.base64doc && !isChanged)
        photo = "data:image/jpeg;base64," +searchData?.getUserDocuments?.data?.base64doc

    // console.log(photo)
    // data:image/jpeg;base64,
    return(
        <Card>
            <CardBody>
                <div className="text-center">
                    <h4>Click Image to change</h4>
                    <div
                        className="ms-1"
                        style={{
                            zIndex: 900000,
                        }}
                    >
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validationType.handleSubmit()
                                return false
                            }}
                        >
                            {loading || imageLoad ? <i className="bx bx-loader fa-5x bx-spin align-middle"></i>:
                                <img
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    src={photo}
                                    onClick={() => {
                                        document.getElementById("image_p").click()
                                        setShowBtn(true)
                                    }}
                                    alt=""
                                    height="300"
                                    width='300'
                                    className="avatar-md rounded-circle img-thumbnail"
                                />
                            }


                            <Input
                                style={{
                                    display: "none",
                                }}
                                id="image_p"
                                name="file"
                                // type="number"
                                accept="image/*"
                                type="file"
                                invalid={
                                    !!(validationType.touched.file && validationType.errors.file)
                                }
                                onBlur={validationType.handleBlur}
                                onChange={imageChange}
                            />
                            {validationType.touched.file && validationType.errors.file ? (
                                <FormFeedback type="invalid">
                                    {validationType.errors.file}
                                </FormFeedback>
                            ) : null}
                            <div
                                style={{
                                    paddingLeft: "7.5%",
                                    marginTop: "-5%",
                                }}
                            >
                            </div>
                            {showBtn ? (
                                <div className="mt-3">
                                    <SubmitButtonHelper
                                        btnSize={`btn-sm`}
                                        type={`primary`}
                                        name={`Submit`}
                                        formik={validationType}
                                    />{" "}
                                    <Button
                                        type="button"
                                        onClick={() => {
                                            setShowBtn(!showBtn)
                                            validationType.resetForm()
                                        }}
                                        className="btn-sm btn-light"
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (
                                ""
                            )}
                        </Form>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}
StudentProfileImageUpdate.propTypes = {
    userUid: PropTypes.string.isRequired,
};


export default StudentProfileImageUpdate;