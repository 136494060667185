import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Label,
  Col,
  Row
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {
  onlineApplicationGraphQLClient,
  registrationGraphQLClient
} from "Modules/Authentication/ApolloClient"
import { AdmissionCapacityListContext } from "./ProgramCapacityList"
import Select from "react-select"
import { GET_ALL_PROGRAMS } from "../../../Programs/Queries/ProgramQuaries"
import { limit } from "../../../../helpers/UrlHelper"
import showToast from "../../../../helpers/ToastHelper"
import {
  REGISTER_PROGRAM_ADMISSION_CAPACITY,
  UPDATE_PROGRAM_ADMISSION_CAPACITY
} from "../Mutations/ProgramAdmissionMutations"
import {
  GET_ADMISSION_YEARS,
  GET_PROGRAM_CAPACITY
} from "../Queries/ProgramAdmissionQueries"

const ProgramCapacityModal = () => {
  const { updateAdmissionCapacityData, setUpdateAdmissionCapacityData } =
    useContext(AdmissionCapacityListContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [currentCapacity, setCurrentCapacity] = useState(
    updateAdmissionCapacityData?.capacity
      ? updateAdmissionCapacityData?.capacity
      : 0
  )

  const [admissionMutation] = useMutation(
    updateAdmissionCapacityData
      ? UPDATE_PROGRAM_ADMISSION_CAPACITY
      : REGISTER_PROGRAM_ADMISSION_CAPACITY,
    {
      client: onlineApplicationGraphQLClient
    }
  )

  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  console.log(updateAdmissionCapacityData?.capacityEquivalent)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      description: updateAdmissionCapacityData?.description
        ? updateAdmissionCapacityData.description
        : "",
      program: updateAdmissionCapacityData?.programUid || null,
      admissionYearUid: updateAdmissionCapacityData?.admissionYearUid?.uid || "",
      capacityEquivalent: updateAdmissionCapacityData?.capacityEquivalent
        ? updateAdmissionCapacityData?.capacityEquivalent
        : "",
      capacityDirect: updateAdmissionCapacityData?.capacityDirect
        ? updateAdmissionCapacityData?.capacityDirect
        : "",
      totalOLevelPassess: updateAdmissionCapacityData?.totalOLevelPassess
        ? updateAdmissionCapacityData?.totalOLevelPassess
        : "",
      totalALevelPassess: updateAdmissionCapacityData?.totalALevelPassess
        ? updateAdmissionCapacityData?.totalALevelPassess
        : "",
      totalOLevelScienceSubjects:
        updateAdmissionCapacityData?.totalOLevelScienceSubjects
          ? updateAdmissionCapacityData?.totalOLevelScienceSubjects
          : "",
      totalALevelScienceSubjects: updateAdmissionCapacityData?.totalALevelScienceSubjects || "",
      minimumOlevelPoints: updateAdmissionCapacityData?.minimumOlevelPoints
        ? updateAdmissionCapacityData?.minimumOlevelPoints
        : "",
      minimumAlevelPoints: updateAdmissionCapacityData?.minimumAlevelPoints
        ? updateAdmissionCapacityData?.minimumAlevelPoints
        : ""
    },

    validationSchema: Yup.object().shape({
      program: Yup.object()
        .shape({
          value: Yup.object().required(),
          label: Yup.string().required()
        })
        .required("Program selection is required")
        .test(
          "is-valid-program",
          "Program Name is required",
          value => value && value.value && value.label
        ),
      admissionYearUid: Yup.string().required("Academic year required"),
      capacityEquivalent: Yup.number().required(
        "Maximum equivalent student is required"
      ),
      capacityDirect: Yup.number().required(
        "Maximum direct student is required"
      ),
      totalOLevelPassess: Yup.number().required(
        "Total O'Level passes is required"
      ),
      totalALevelPassess: Yup.number().required(
        "Total A'Level passes is required"
      ),
      totalOLevelScienceSubjects: Yup.number().required(
        "Total O'Level science subjects is required"
      ),
      totalALevelScienceSubjects: Yup.number().required(
        "Total A'Level science subjects is required"
      ),
      description: Yup.string().required("Description is required"),
      minimumOlevelPoints: Yup.number().required(
        "Minimum O'Level total points is required"
      ),
      minimumAlevelPoints: Yup.number().required(
        "Minimum A'Level total points is required"
      )
    }),

    onSubmit: (values, { resetForm, setSubmitting }) => {
      const payload = {
        capacity: parseInt(values.capacityEquivalent) + parseInt(values.capacityDirect),
        programUid: values.program?.value?.uid,
        programName: values.program?.value?.name,
        code: values.program?.value?.code,
        description: values.description,
        programCategoryCode: values.program?.value?.programCategory.shortName
      }
      const fullPayload = {
        description: values.description,
        programUid: values.program?.value?.uid,
        admissionYearUid: values.admissionYearUid,
        capacityEquivalent: parseInt(values.capacityEquivalent),
        capacityDirect: parseInt(values.capacityDirect),
        totalOLevelPassess: parseInt(values.totalOLevelPassess),
        totalALevelPassess: parseInt(values.totalALevelPassess),
        totalOLevelScienceSubjects: parseInt(values.totalOLevelScienceSubjects),
        totalALevelScienceSubjects: parseInt(values.totalALevelScienceSubjects),
        minimumOlevelPoints: parseInt(values.minimumOlevelPoints),
        minimumAlevelPoints: parseInt(values.minimumAlevelPoints)
      }
      delete values.program

      admissionMutation({
        variables: {
          inputs: {
            ...payload,
            ...fullPayload,
            uid: updateAdmissionCapacityData?.uid
          }
        },
        refetchQueries: [
          {
            query: GET_PROGRAM_CAPACITY,
            variables: {
              pagination: paginationHelper
            }
          }
        ],
        onCompleted: ({ updateProgramCapacity, registerProgramCapacity }) => {
          if (
            updateProgramCapacity
              ? updateProgramCapacity.code === 8000
              : registerProgramCapacity.code === 8000
          ) {
            resetForm(true)
            setModalOpen(false)
            setUpdateAdmissionCapacityData(false) // Close the modal
          } else {
            setSubmitting(false)
            setModalOpen(true)
          }
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)
          console.error("Mutation error:", error)
        }
      }).then(r => true)
    }
  })

  const { loading, error, data, refetch } = useQuery(GET_ADMISSION_YEARS, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })

  const admissionYears = data?.getAdmissionYears?.data?.items

  const [
    searchPrograms,
    { loading: programSearchLoading, error: searchError, data: searchProgram }
  ] = useLazyQuery(GET_ALL_PROGRAMS, { client: registrationGraphQLClient })

  const {
    loading: programLoading,
    error: programError,
    data: programData
  } = useQuery(GET_ALL_PROGRAMS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: pagination
    }
  })

  let programs = programData?.getAllPrograms?.data?.items
  if (searchProgram?.getAllPrograms?.data)
    programs = searchProgram?.getAllPrograms?.data?.items

  const search = (value, type) => {
    let data = {
      search: value
    }
    if (value.length >= 2) {
      if (type === "program") {
        searchPrograms({
          variables: { pagination: { ...pagination, ...data } }
        })
      } else if (type === "status") {
        searchStatus({
          variables: { pagination: { ...pagination, ...data } }
        })
      }
    }
  }

  const handleProgramChange = selectedOption => {
    validation.setFieldValue("program", selectedOption)
  }

  useEffect(() => {
    if (
      parseInt(validation.values.capacityDirect) &&
      parseInt(validation.values.capacityEquivalent)
    ) {
      setCurrentCapacity(
        parseInt(validation.values.capacityDirect) + parseInt(validation.values.capacityEquivalent)
      )
    }
  }, [parseInt(validation.values.capacityDirect), parseInt(validation.values.capacityEquivalent)])

  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Add Program Capacity
      </Button>
      <Modal
        isOpen={modalOpen || !!updateAdmissionCapacityData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateAdmissionCapacityData
              ? "Edit Program Capacity"
              : "Add New Program Capacity"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              validation.resetForm()
              setCurrentCapacity(0)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="m-3">
            <Row>
              <Col md={5}>
                <div className="form-group mb-3 mt-2 ajax-select select2-container">
                  <Label>Admission Year</Label>
                  <Select
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                    onChange={e => {
                      if (e) {
                        validation.setFieldValue("admissionYearUid", e.value)
                      } else {
                        validation.setFieldValue("admissionYearUid", "")
                      }
                    }}
                    options={admissionYears?.map(year => ({
                      value: year.uid,
                      label: year.name
                    }))}
                    defaultValue={
                      updateAdmissionCapacityData?.admissionYear?.uid
                        ? updateAdmissionCapacityData?.admissionYear?.uid
                        : ""
                    }
                    defaultInputValue={
                      updateAdmissionCapacityData?.admissionYear?.name
                        ? updateAdmissionCapacityData?.admissionYear?.name
                        : ""
                    }
                    className="select2-selection"
                    isClearable={true}
                  />
                  <Input
                    name="admissionYearUid"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.admissionYearUid}
                    invalid={
                      !!(
                        validation.touched.admissionYearUid &&
                        validation.errors.admissionYearUid
                      )
                    }
                  />
                  {validation.touched.admissionYearUid &&
                  validation.errors.admissionYearUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.admissionYearUid}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={7}>
                <div className="form-group mb-3 mt-2 ajax-select select2-container">
                  <Label>Program</Label>
                  <Select
                    // value={selectedMulti2}
                    name='program'
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                    onChange={handleProgramChange}
                    onInputChange={e => {
                      search(e, "program")
                    }}
                    options={programs
                      ?.map(pro => {
                        if (pro?.tcuCode) {
                          return {
                            value: pro,
                            label: pro?.name + " (" + pro?.code + ")"
                          }
                        }
                        return null
                      })
                      .filter(option => option !== null)}
                    className="select2-selection"
                    isLoading={programLoading || programSearchLoading}
                    defaultValue={validation.values?.program?.uid || ""}
                    defaultInputValue={updateAdmissionCapacityData?.programName || ""}
                  />
                  <Input
                    name="program"
                    placeholder=""
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.program?.programUid || ""}
                    invalid={
                      !!(
                        validation.touched.program && validation.errors.program
                      )
                    }
                  />
                  {validation.touched.program && validation.errors.program ? (
                    <FormFeedback type="invalid">
                      {validation.errors.programCode}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div>
                  <label htmlFor="code" className="col-form-label">
                    Total/Maximum direct students
                  </label>
                  <Input
                    type="text"
                    name="capacityDirect"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter max direct students"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.capacityDirect || ""}
                    invalid={
                      !!(
                        validation.touched.capacityDirect &&
                        validation.errors.capacityDirect
                      )
                    }
                  />
                  {validation.touched.capacityDirect &&
                  validation.errors.capacityDirect ? (
                    <FormFeedback type="invalid">
                      {validation.errors.capacityDirect}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <label htmlFor="dialCode" className="col-form-label">
                    Total/Maximum equivalent students
                  </label>
                  <Input
                    type="text"
                    name="capacityEquivalent"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter max equivalent students"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.capacityEquivalent || ""}
                    invalid={
                      !!(
                        validation.touched.capacityEquivalent &&
                        validation.errors.capacityEquivalent
                      )
                    }
                  />
                  {validation.touched.capacityEquivalent &&
                  validation.errors.capacityEquivalent ? (
                    <FormFeedback type="invalid">
                      {validation.errors.capacityEquivalent}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="mt-3">
                  <Label>Capacity: {currentCapacity}</Label>
                </div>
              </Col>
            </Row>

            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="dialCode" className="col-form-label">
                  Total O'Level Passes
                </label>
                <Input
                  type="text"
                  name="totalOLevelPassess"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter total O'Level passes"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.totalOLevelPassess || ""}
                  invalid={
                    !!(
                      validation.touched.totalOLevelPassess &&
                      validation.errors.totalOLevelPassess
                    )
                  }
                />
                {validation.touched.totalOLevelPassess &&
                validation.errors.totalOLevelPassess ? (
                  <FormFeedback type="invalid">
                    {validation.errors.totalOLevelPassess}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="col-lg-6">
                <label htmlFor="dialCode" className="col-form-label">
                  Total A'Level Passes
                </label>
                <Input
                  type="text"
                  name="totalALevelPassess"
                  className="form-control"
                  id="totalALevelPassess"
                  placeholder="Enter total A'Level passes"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.totalALevelPassess || ""}
                  invalid={
                    !!(
                      validation.touched.totalALevelPassess &&
                      validation.errors.totalALevelPassess
                    )
                  }
                />
                {validation.touched.totalALevelPassess &&
                validation.errors.totalALevelPassess ? (
                  <FormFeedback type="invalid">
                    {validation.errors.totalALevelPassess}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="dialCode" className="col-form-label">
                  Total O'Level Science Subjects
                </label>
                <Input
                  type="text"
                  name="totalOLevelScienceSubjects"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter total O'Level science subjects"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.totalOLevelScienceSubjects || ""}
                  invalid={
                    !!(
                      validation.touched.totalOLevelScienceSubjects &&
                      validation.errors.totalOLevelScienceSubjects
                    )
                  }
                />
                {validation.touched.totalOLevelScienceSubjects &&
                validation.errors.totalOLevelScienceSubjects ? (
                  <FormFeedback type="invalid">
                    {validation.errors.totalOLevelScienceSubjects}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="col-lg-6">
                <label htmlFor="dialCode" className="col-form-label">
                  Total A'Level Science Subjects
                </label>
                <Input
                  type="text"
                  name="totalALevelScienceSubjects"
                  className="form-control"
                  id="totalALevelScienceSubjects"
                  placeholder="Enter total A'Level sceince subjects"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.totalALevelScienceSubjects || ""}
                  invalid={
                    !!(
                      validation.touched.totalALevelScienceSubjects &&
                      validation.errors.totalALevelScienceSubjects
                    )
                  }
                />
                {validation.touched.totalALevelScienceSubjects &&
                validation.errors.totalALevelScienceSubjects ? (
                  <FormFeedback type="invalid">
                    {validation.errors.totalALevelScienceSubjects}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <label htmlFor="dialCode" className="col-form-label">
                  Minimum O'Level Points
                </label>
                <Input
                  type="text"
                  name="minimumOlevelPoints"
                  className="form-control"
                  id="minimumOlevelPoints"
                  placeholder="Enter minimum O'Level total points"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.minimumOlevelPoints || ""}
                  invalid={
                    !!(
                      validation.touched.minimumOlevelPoints &&
                      validation.errors.minimumOlevelPoints
                    )
                  }
                />
                {validation.touched.minimumOlevelPoints &&
                validation.errors.minimumOlevelPoints ? (
                  <FormFeedback type="invalid">
                    {validation.errors.minimumOlevelPoints}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="col-lg-6">
                <label htmlFor="dialCode" className="col-form-label">
                  Minimum A'Level Points
                </label>
                <Input
                  type="text"
                  name="minimumAlevelPoints"
                  className="form-control"
                  id="minimumAlevelPoints"
                  placeholder="Enter total A'Level total points"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.minimumAlevelPoints || ""}
                  invalid={
                    !!(
                      validation.touched.minimumAlevelPoints &&
                      validation.errors.minimumAlevelPoints
                    )
                  }
                />
                {validation.touched.minimumAlevelPoints &&
                validation.errors.minimumAlevelPoints ? (
                  <FormFeedback type="invalid">
                    {validation.errors.minimumAlevelPoints}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <label htmlFor="message-text" className="col-form-label">
                    Description:
                  </label>
                  <textarea
                    className="form-control"
                    name="description"
                    id="message-text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    onInvalid={() => {
                      validation.touched.description &&
                      validation.errors.description
                        ? true
                        : false
                    }}
                  />
                  {validation.touched.description &&
                  validation.errors.description ? (
                    <FormFeedback type="invalid">
                      {validation.errors.description}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      setModalOpen(false)
                      setUpdateAdmissionCapacityData(false)
                      setCurrentCapacity(0)
                      validation.resetForm() // Reset the form
                    }}
                  >
                    Close
                  </button>
                  <SubmitButtonHelper
                    name="Submit"
                    type="primary"
                    formik={validation}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ProgramCapacityModal
