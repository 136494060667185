import { Link } from "react-router-dom"
import React from "react"

const SenateLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_HOD_MENU") && (
        <>
          {hasAuthority("VIEW_SENATE_MENU") && (
            <li>
              <Link to="/examination/senate/publish_exam_results">
                <i className="bx bx-happy-heart-eyes"></i>
                <span>Publish official results</span>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default SenateLinks
