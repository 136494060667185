//REGISTE
import { gql } from "@apollo/client"

const GET_BYLAWS = gql`
  query GetByLaws($pagination: PaginationInput!) {
    getByLaws(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
          code
          status
          startDate
          endDate
        }
        totalCount
      }
    }
  }
`

export { GET_BYLAWS }
