import React, { useState, createContext } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  CardText,
  CardTitle,
  Collapse,
  Container,
  Nav,
  TabPane,
  UncontrolledCollapse,
  CardHeader,
  Alert,
} from "reactstrap"
import classnames from "classnames"
import sualogo from "../../../../assets/images/logo/sua.png"
import ApplicantAccountRegistrationFirstStep from "./ApplicantAccountRegistrationFirstStep"
import ApplicantAccountRegistrationSecondStep from "./ApplicantAccountRegistrationSecondStep"
import { useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient } from "../../../Authentication/ApolloClient"
import { ThreeDots } from "react-loader-spinner"
import { GET_ACTIVE_ADMISSION_WINDOW } from "../Queries/AcountRegistrationQueries"
import PublicEsbHeader from "components/EsbDashboardLayout/PublicEsbHeader"
import { Link } from "react-router-dom"

export const ApplicantContextForUndergraduate = createContext() // Create data to transfer to child modal

const Undergraduate = () => {
  const [updateApplicantData, setUpdateApplicantData] = useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [activeTab1, setactiveTab1] = useState("5")
  const [passedSteps, setPassedSteps] = useState([1])
  const [tcuResponce, setTcuResponse] = useState(null)
  const [stepOnePayload, setStepOnePayload] = useState({})
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1")
  const currentDateFull = new Date()
  const currentDate = currentDateFull
    .toISOString()
    .slice(0, 19)
    .replace("T", " ")

  const renderStepContent = tabId => {
    switch (tabId) {
      case 1:
        return <ApplicantAccountRegistrationFirstStep />
      case 2:
        return <ApplicantAccountRegistrationSecondStep />
      default:
        return null
    }
  }

  const toggleTab = tab => {
    handleSubmit()
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const {
    loading: admissionWindowLoading,
    error: admissionWindowError,
    data: admissionWindowData,
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: {
      applicationLevelCode: "BSC",
    },
    fetchPolicy: "network-only",
  })

  const window = admissionWindowData?.getActiveAdmissionWindow?.data

  const handleSubmit = values => {}

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  return (
    <ApplicantContextForUndergraduate.Provider
      value={{
        updateApplicantData,
        setUpdateApplicantData,
        tcuResponce,
        setTcuResponse,
        stepOnePayload,
        setStepOnePayload,
        toggleTab,
        handleSubmit,
        activeTab,
        passedSteps,
      }}
    >
      <PublicEsbHeader />
      <Container fluid className="mt-4">
        <Row>
          <Col lg={12}>
            <p>
              <div className="wizard">
                {admissionWindowLoading ? (
                  <Row className="justify-content-center">
                    <Col md={8}>
                      <Card className="m-5">
                        <CardBody>
                          <div className="text-center">
                            <i className="fas fa-spinner fa-spin fa-5x text-success align-middle me-2" />
                            <br />
                            <strong>
                              Please wait while checking admission window for
                              undergraduate...
                            </strong>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <>
                    {
                      window ? (
                        <>
                          {window?.endDate < currentDate ? (
                            <Alert color="danger">Admission window is closed!</Alert>
                          ) : (
                            <>
                              <div
                                className="steps clearfix"
                                style={{ display: "none" }}
                              >
                                <ul style={{ whiteSpace: "nowrap" }}>
                                  <NavItem
                                    className={classnames({
                                      current: activeTab === 1,
                                    })}
                                  >
                                    <NavLink
                                      className={classnames({
                                        current: activeTab === 1,
                                      })}
                                      onClick={() => toggleTab(1)}
                                      disabled={!(passedSteps || []).includes(1)}
                                    >
                                      <span className="number">1.</span> Payments
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({
                                      current: activeTab === 2,
                                    })}
                                  >
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === 2,
                                      })}
                                      onClick={() => toggleTab(2)}
                                      disabled={!(passedSteps || []).includes(2)}
                                    >
                                      <span className="number">2.</span> Basic Details
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({
                                      current: activeTab === 3,
                                    })}
                                  >
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === 3,
                                      })}
                                      onClick={() => toggleTab(3)}
                                      disabled={!(passedSteps || []).includes(3)}
                                    >
                                      <span className="number">3.</span> Contacts
                                      Detail
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({
                                      current: activeTab === 4,
                                    })}
                                  >
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === 4,
                                      })}
                                      onClick={() => toggleTab(4)}
                                      disabled={!(passedSteps || []).includes(4)}
                                    >
                                      <span className="number">4.</span> Next Of Kin
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({
                                      current: activeTab === 5,
                                    })}
                                  >
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === 5,
                                      })}
                                      onClick={() => toggleTab(5)}
                                      disabled={!(passedSteps || []).includes(5)}
                                    >
                                      <span className="number">5.</span> Hostel
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={classnames({
                                      current: activeTab === 6,
                                    })}
                                  >
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === 6,
                                      })}
                                      onClick={() => toggleTab(6)}
                                      disabled={!(passedSteps || []).includes(6)}
                                    >
                                      <span className="number">6.</span> Confirm
                                    </NavLink>
                                  </NavItem>
                                </ul>
                              </div>
                              <div className="content clearfix">
                                <TabContent activeTab={activeTab} className="body">
                                  {renderStepContent(activeTab)}
                                </TabContent>
                              </div>
                            </>
                          )}
                        </>
                      ): (
                        <Row>
                          <Col md={3}></Col>
                          <Col md={6}>
                            <Alert color="danger">{admissionWindowData?.getActiveAdmissionWindow?.message}</Alert>
                          </Col>
                          <Col md={3}></Col>
                        </Row>
                      )
                    }
                  </>
                )}
              </div>
            </p>
          </Col>
        </Row>
      </Container>
    </ApplicantContextForUndergraduate.Provider>
  )
}

export default Undergraduate
