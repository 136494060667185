import { gql } from "@apollo/client"

const CREATE_PROGRAM_COURSE = gql`
mutation RegisterProgramCourse($inputs: [ProgramCourseInput!]!) {
  registerProgramCourse(inputs: $inputs) {
  message
  status
  code
  data{
    items{
       credit,credit,lectureHours,practicalHours,independentStudyHours,
      seminarHours,
      course{uid, code, name},
      courseCategory{uid,name},
      programSemester{uid,studyYear,coreCredits,electiveCredits}
    }
    totalCount
  }
}
}
`

const DELETE_PROGRAM_COURSE = gql`
mutation removeProgramCourse($uid: String!) {
  removeProgramCourse(uid: $uid) {
    message
    code
    status
  }
}
`

export { CREATE_PROGRAM_COURSE, DELETE_PROGRAM_COURSE }