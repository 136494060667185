import React, {createContext, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, CardTitle, UncontrolledDropdown, Button,
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import showToast from "../../../../helpers/ToastHelper";
import {GET_CONTROL_NUMBER} from "../../Queries/Payment/StudentPaymentQueries";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {Link, useNavigate} from "react-router-dom";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {showWaringConf} from "../../../../helpers/DeleteWaringHelper";
import {
    RENEW_CONTROL_NUMBER,
    REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS
} from "../../Mutations/StudentPaymentMutations";
import {LogedInStudent} from "../../LogedInStudent";

const breadcrumbItem =  [
    {
        title: 'Control Numbers',
        path: '',
        isActive: true,
    },
]
export const ControlNumberContext = createContext();
const ControlNumbers = () => {
    const userData = JSON.parse(localStorage.getItem("authUser"))
    const dataInfo = userData?.data
    const navigate = useNavigate()
    const { loading,error, data } = useQuery(GET_CONTROL_NUMBER, {
        client:registrationGraphQLClient,
        skip: !dataInfo?.student?.registrationNumber,
        variables: {
            registrationNumber:dataInfo?.student?.registrationNumber
        }
    })
    const [renewControlNumber] = useMutation(RENEW_CONTROL_NUMBER)
    const [requestFeeStructureControlNumber] = useMutation(REQUEST_FEE_STRUCTURE_CONTROL_NUMBERS)
    const [
        searchQuery,
        { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_CONTROL_NUMBER, {client: registrationGraphQLClient})

    // if (error) return showToast("Failed to fetch Programs", "error")
    let controlNumbers = data?.getControlNumbers?.data
    if (searchData?.getControlNumbers?.data) {
        controlNumbers = searchData?.getControlnumber?.data
    }
    const searchValues = {
        search: "",
    }

    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })
    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })
    const search = () => {
        const value = formikSearch.values.search
        if (value.length >= 3) {
            let data = {
                search: formikSearch.values.search,
            }
            searchQuery({ variables: { inputs:{registrationNumber:dataInfo?.student?.registrationNumber}, input: { ...data } } })
        }
    }
    const confirmRenew = async (fee) => {
        try {
            await renewControlNumber(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs: {
                            registrationNumber: dataInfo?.student?.registrationNumber,
                            billID: fee.billId,
                            payType:fee.payType
                        }
                    },
                    refetchQueries: [
                        {
                            query: GET_CONTROL_NUMBER,
                            variables: {
                                inputs: {
                                    registrationNumber: dataInfo?.student?.registrationNumber
                                }
                            },
                        },
                    ],
                    onCompleted: ({renewControlNumber}) => {
                        if (renewControlNumber.code === 8000) {
                            showToast(renewControlNumber.message, "success")
                        } else {
                            showToast(
                                renewControlNumber.message,
                                renewControlNumber.code === 8000 ? "success" : "error"
                            )
                        }
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }

    const confirmRequest = async () => {
        try {
            await requestFeeStructureControlNumber(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs: {
                            registrationNumber: dataInfo?.student?.registrationNumber,
                            programUid:dataInfo?.student?.programmeUid,
                            yearOfStudy:dataInfo?.student?.studyYear,
                            studentStatus:dataInfo?.student?.status?.name,
                            countrycode:dataInfo?.country?.dialCode,
                            studentName:dataInfo?.firstName + " "+ dataInfo?.middleName + " " + dataInfo?.lastName
                        }
                    },
                    refetchQueries: [
                        {
                            query: GET_CONTROL_NUMBER,
                            variables: {
                                registrationNumber:dataInfo?.student?.registrationNumber
                            }
                        },
                    ],
                    onCompleted: ({requestFeeStructureControlNumbers}) => {
                        if (requestFeeStructureControlNumbers.code === 8000) {
                            showToast(requestFeeStructureControlNumbers.message, "success")
                        } else {
                            showToast(
                                requestFeeStructureControlNumbers.message,
                                requestFeeStructureControlNumbers.code === 8000 ? "success" : "error"
                            )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }
    const handleRenewControlNumber = (fee) => {
        showWaringConf(() => {
                confirmRenew(fee)
            },'Renew Control Number',`Are you sure you need to renew ${fee.controlNumber}? Control Number`,'warning','Renew',
            () => {
                // console.log("Canceled")
            })
    }

    const handleRequestFeeStructureControlNumber = () => {
        showWaringConf(() => {
                confirmRequest()
            },'Request Control Number',`Are you sure you need to request fee structure control numbers for this academic year?`,'warning','Request',
            () => {
                // setPayType(null)
            })
    }
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggleDropdown = data => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [data.billId]: !prevState[data.billId],
        }))
    }
    if (error || searchError) {
        return showToast(error || searchError, "error")
    }
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={'Control Number'} >
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h5>{`CONTROL NUMBERS`}</h5>
                            </CardTitle>
                            <CardSubtitle className="mb-1">
                                <Row>
                                    <Col className="col-lg-6 col-6">
                                        <form className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onKeyUp={search}
                                                    placeholder="Search..."
                                                    {...formikSearch.getFieldProps("search")}
                                                />
                                                {!searchLoading && (
                                                    <span className="bx bx-search-alt" />
                                                )}
                                                {searchLoading && (
                                                    <span className="bx bx-search-alt bx-spin" />
                                                )}
                                            </div>
                                        </form>
                                    </Col>
                                    <Col className="col-6">
                                        <div className="text-sm-end mt-3">
                                            <Button onClick={event => handleRequestFeeStructureControlNumber()} color="primary" >Fee Structure</Button>
                                            {" "}
                                            <Button onClick={event => navigate('/finance/services')}>Other Payments</Button>
                                        </div>
                                    </Col>

                                </Row>
                            </CardSubtitle>
                            {loading || searchLoading  ? (
                                <PlaceHolderLoader columSize={12} type="table" rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                    >
                                        <Table
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th>Fee Name</Th>
                                                    <Th>Academic Year</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Control Number</Th>
                                                    <Th>Currency</Th>
                                                    <Th>Action</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {controlNumbers?.map((fee, index) => (
                                                    <Tr key={fee?.billid}>
                                                        <Td>{index+1}</Td>
                                                        <Td>{fee?.feeName}</Td>
                                                        <Td>{fee?.academicYear}</Td>
                                                        <Td className="text-end">{fee?.amount.toLocaleString()}</Td>
                                                        <Td>{fee?.controlNumber}</Td>
                                                        <Td>{fee?.currency}</Td>
                                                        <Td>
                                                            <Dropdown
                                                                isOpen={dropdownOpen[fee.billId]}
                                                                toggle={() => toggleDropdown(fee)}
                                                            >
                                                                <DropdownToggle color="" caret>
                                                                    <i className="fas fa-ellipsis-v" style={{color: 'blue'}} />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            handleRenewControlNumber(fee)
                                                                        }}>
                                                                        <i className="fas fa-cogs" style={{color: 'red', marginRight: '10px'}}  />
                                                                        <span>Renew</span>
                                                                    </DropdownItem>
                                                                    {/*<DropdownItem*/}
                                                                    {/*    onClick={() => {*/}
                                                                    {/*        handleRequestFeeStructureControlNumber(fee)*/}
                                                                    {/*    }}>*/}
                                                                    {/*    <i className="fas fa-cog" style={{color: 'green', marginRight: '10px'}}  />*/}
                                                                    {/*    <span>Refresh Bill</span>*/}
                                                                    {/*</DropdownItem>*/}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </Td>

                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}
export default ControlNumbers;
