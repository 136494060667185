import { gql } from "@apollo/client"

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: PasswordChangeDtoInput!) {
    changePassword(input: $input) {
      status
      code
      message
    }
  }
`

export { CHANGE_PASSWORD }