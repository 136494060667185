import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import "flatpickr/dist/themes/material_blue.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input, InputGroup,
    Label,
    Row
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import ToastHelper from "../../../../helpers/ToastHelper"
import * as PropTypes from "prop-types";
const breadcrumbItem =  [
    {
        title: 'Fiance Query',
        path: '/Students/Payments/queries',
        isActive: false,
    },
    {
        title: 'Create',
        path: '',
        isActive: true,
    },
]

const FinanceQueryCreate = () => {
    const navigate = useNavigate();
    const onchangeValues = (e,input) => {
        validationType.setFieldValue(input,e.value)
    }

// Form validation
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            title: '',
            description:'',
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Title is required"),
            description: Yup.string().required("Description is required"),
        }),
        onSubmit: (values) => {
            ToastHelper('Query Send Successful','success')
            navigate('/Students/Payments/queries')
        }
    });


    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Create Finance Query" >
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validationType.handleSubmit();
                                    return false;
                                }}>
                                <div className='row'>
                                    <div className="col-12 mb-3">
                                        <Label className="form-label">Title</Label>
                                        <Input
                                            name="title"
                                            placeholder="Enter Title"
                                            type="text"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.email || ""}
                                            invalid={
                                                !!(validationType.touched.title && validationType.errors.title)
                                            }
                                        />
                                        {validationType.touched.title && validationType.errors.title ? (
                                            <FormFeedback type="invalid">{validationType.errors.title}</FormFeedback>
                                        ) : null}
                                    </div>


                                    <div className="col-12 mb-3">
                                        <Label className="form-label">Description</Label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data=""
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                validationType.setFieldValue('description',editor.getData())
                                            }}
                                        />
                                        <Input
                                            name="description"
                                            placeholder="Enter Description"
                                            type="hidden"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.description || ""}
                                            invalid={
                                                !!(validationType.touched.description && validationType.errors.description)
                                            }
                                        />

                                        {validationType.touched.description && validationType.errors.description ? (
                                            <FormFeedback type="invalid">{validationType.errors.description}</FormFeedback>
                                        ) : null}
                                    </div>

                                </div>

                                <div className=" d-flex flex-wrap gap-2">
                                    <Button type="submit" color="primary" >
                                        Submit
                                    </Button>{" "}
                                    <Button type="reset" color="secondary" >
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default FinanceQueryCreate;