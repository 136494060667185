// export default StatusList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import ThesisSettingModal from "./ThesisSettingModal"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import {GET_THESIS_SETTINGS} from "../Queries/ThesisSettingQueries";
import {DELETE_THESIS_SETTING} from "../Mutations/ThesisSettingMutations";

const breadcrumbItem = [
  {
    title: "Seminar Type",
    path: "",
    isActive: true,
  },
]

export const ThesisSettingContext = createContext() //Create data to transfer to child modal

const ThesisSettingList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })
  const pagination = { offset: 0, limit: limit, search: null }
  const [thesisSetting, setThesisSetting] = useState(null) //Get data for deleting
  const [updateThesisSettingData, setUpdateThesisSettingData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_THESIS_SETTINGS, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
  })

  const [
    searchThesisSetting,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_THESIS_SETTINGS, {
    client: registrationGraphQLClient,
  })
  const [deleteSeminarType] = useMutation(DELETE_THESIS_SETTING, {
    client: registrationGraphQLClient,
    variables: { uid: thesisSetting },
    refetchQueries: [
      {
        query: GET_THESIS_SETTINGS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })
  const handleDeleteThesisSetting = uid => {
    showDeleteWarning(() => {
      performDeletion()
    })
  }

  const performDeletion = async () => {
    try {
      const response = await deleteSeminarType()
      // showToast(response.data.removeThesisSetting.message, response.data.removeThesisSetting.code === 8000 ? "success" : "error")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  const fetchData = () => {
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchThesisSetting({ variables: { pagination: { ...pagination, ...data } } })
  }


  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      fetchData()
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }

  const thesisSettings = data?.getIntentionToSubmitRequirements.data?.items
  const searchedThesisSettings = searchData?.getIntentionToSubmitRequirements?.data?.items
  const results = searchedThesisSettings ? searchedThesisSettings : thesisSettings
  return (
    <ThesisSettingContext.Provider value={{ updateThesisSettingData, setUpdateThesisSettingData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Thesis Settings">
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <ThesisSettingModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                              <Th data-priority="2">Category</Th>
                            <Th data-priority="2">Min Manuscripts</Th>
                            <Th data-priority="2">Min Seminars</Th>
                            <Th data-priority="2">Seminar Pass Marks</Th>
                            <Th data-priority="2">Intention Life Span (Year)</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>

                          {results.map((st, index) => (
                              <Tr key={st?.uid}>
                                <Td>{index + 1}</Td>
                                <Td>{st?.programCategory?.name}</Td>
                                <Td>{st?.minimumManuscripts}</Td>
                                <Td>{st?.minimumSeminars}</Td>
                                <Td>{st?.seminarPassMarks}</Td>
                                <Td>{st?.lifeSpan}</Td>
                                <Td>
                                  <DropDownActionHelper
                                    data={st}
                                    onUpdate={setUpdateThesisSettingData}
                                    onDelete={() => handleDeleteThesisSetting(st.uid)}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </ThesisSettingContext.Provider>
  )
}

export default ThesisSettingList
