import React, {createContext, useEffect, useState} from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button, DropdownToggle, DropdownMenu, DropdownItem, Dropdown,
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import { Link } from "react-router-dom"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { paginationHelper } from "../../../helpers/Functions/GraphqlUpdateFunction"
import showToast from "../../../helpers/ToastHelper"
import { GET_PROGRAMS } from "../Queries/ProgramQuaries"
import { DELETE_PROGRAM } from "../Mutations/ProgramMutations"
import { showDeleteWarning } from "../../../helpers/DeleteWaringHelper"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"
import { limit } from "../../../helpers/UrlHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import {useNavigate } from "react-router-dom"
import PaginationHelper from "../../../helpers/PaginationHelper";
import DropDownActionHelper from "../../../helpers/DropDownActionHelper";
import ProgramFormModel from "./ProgramFormModel";
import {LoaderPage} from "../../../helpers/LoaderPage";
import { jwtDecode } from "jwt-decode"

const breadcrumbItem = [
  {
    title: "Programs List",
    path: "",
    isActive: true,
  },
]
export const ProgramContext = createContext() //Create data to transfer to child modal

const ProgramList = () => {

  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  const [pageSize, setPageSize] = useState(limit),
      [proData,setProData] = useState(false),
    [currentPage, setCurrentPage] = useState(0),
    [programUid, setProgramUid] = useState(null),navigate = useNavigate()
  const pagination = { offset: currentPage, limit: limit, search: null }

  const { loading, error, data } = useQuery(GET_PROGRAMS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: pagination,
    },
  })

  // if (error) return showToast("Failed to fetch Programs", "error")
  let programs = data?.getPrograms?.data?.items
  const [
    searchQuery,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_PROGRAMS, {client: registrationGraphQLClient})
  let totalCount = data?.getPrograms?.data?.totalCount

  if (searchData?.getPrograms?.data?.items) {
    programs = searchData?.getPrograms?.data?.items
    totalCount = searchData?.getPrograms?.data?.totalCount
  }

  // console.log('Current Page '+currentPage)
  const handleClick = data => {
    setCurrentPage(data.selected)
  }
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema
  })

  const search = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchQuery({ variables: { pagination: { ...pagination, ...data } } })
    }
  }

  const [deleteProgram] = useMutation(DELETE_PROGRAM, {
    client: registrationGraphQLClient,
    variables: { uid: programUid },
    refetchQueries: [
      {
        query: GET_PROGRAMS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const confirmProgramDelete = async () => {
    try {
      const response = await deleteProgram()
      showToast(response.data.deleteProgram.message, "success")
      // console.log("User deleted successfully:", response)
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const handleDeleteProgram = () => {
    if (programUid !== "") {
      showDeleteWarning(() => {
        confirmProgramDelete()
        setProgramUid(null)
      })
    } else {
      showToast("Please Select Program to delete", "warning")
    }
  }


  const updateData = (uud) => {
    // Replace '/my-component' with the desired route path
    navigate('/examination/programs',{
      state:{
        uid:uud
      }
    })
  };
  //


  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = pro => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [pro.uid]: !prevState[pro.uid],
    }))
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }


  if(loading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Programs"} description={"Fetching Program List"} />


  return (
    <ProgramContext.Provider value={{proData,setProData}} >
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Program List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                              type="text"
                              className="form-control"
                              onKeyUp={search}
                              placeholder="Search..."
                              {...formikSearch.getFieldProps("search")}
                          />
                          {!searchLoading && (
                              <span className="bx bx-search-alt" />
                          )}
                          {searchLoading && (
                              <span className="bx bx-search-alt bx-spin" />
                          )}
                        </div>
                      </form>
                    </Col>
                    <Col sm="6">
                      <ProgramFormModel program={[]} />
                    </Col>
                  </Row>
                </CardSubtitle>

                {loading || searchLoading ? (
                    <PlaceHolderLoader columSize={12} type="table" rows={5} />
                ) : (
                    <div className="table-rep-plugin">
                      <div
                          className="mb-0"
                          data-pattern="priority-columns"
                      >
                        <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th>S/No</Th>
                              <Th>Name</Th>
                              <Th>Short Name</Th>
                              <Th>Code</Th>
                              <Th>Registration Code</Th>
                              <Th>Duration</Th>
                              <Th>Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {programs?.map((pro, index) => (
                                <Tr key={pro?.uid}>
                                  <Td>{(currentPage * pageSize) + index+1}</Td>
                                  <Td>{pro?.name}</Td>
                                  <Td>{pro?.shortName}</Td>
                                  <Td>{pro?.code}</Td>
                                  <Td>{pro?.registrationCode}</Td>
                                  <Td>{pro?.duration} Year's</Td>
                                  <Td>
                                    <Dropdown
                                        isOpen={dropdownOpen[pro.uid]}
                                        toggle={() => toggleDropdown(pro)}
                                    >
                                      <DropdownToggle color="" caret>
                                        <i className="fas fa-ellipsis-v" style={{color: 'blue'}} />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                            onClick={() => {
                                              setProData(pro)
                                            }}
                                        >
                                          <i className="fas fa-user-edit" style={{color: 'blue', marginRight: '10px'}}  />
                                          <span>Edit</span>
                                        </DropdownItem>
                                        {
                                          hasAuthority("REMOVE_PROGRAM") && (
                                            <DropdownItem
                                              onClick={() => {
                                                setProgramUid(pro.uid)
                                                handleDeleteProgram()
                                              }}
                                            >
                                              <i className="fas fa-trash" style={{color: 'red', marginRight: '10px'}}  />
                                              <span>Delete</span>
                                            </DropdownItem>
                                          )
                                        }
                                        <DropdownItem
                                            onClick={() => {
                                              navigate("/examination/programs/view",{state:{program:pro}})
                                            }}>
                                          <i className="fas fa-th-list" style={{color: 'primary', marginRight: '10px'}}  />
                                          <span>Detail</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                              navigate("/examination/programs/program-semester-list",{state:{program:pro}})
                                            }}>
                                          <i className="mdi mdi-eye-outline" style={{color: 'green', marginRight: '10px'}}  />
                                          <span>Structure</span>
                                        </DropdownItem>                                        
                                      </DropdownMenu>
                                    </Dropdown>
                                  </Td>
                                </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </div>
                      <PaginationHelper totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} pageItemNumber={programs.length} handleClick={handleClick} />
                    </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </ProgramContext.Provider>
  )
}

export default ProgramList
