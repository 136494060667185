import { Link } from "react-router-dom"
import React from "react"

const TeachingAssesmentLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_STUDENT_DASHBOARD") && (
        <>
        <li>
          <Link to="/teaching-assessment/dashboard">
            <i className="bx bx-home-circle"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/teaching-assessment/courses">
            <i className="bx bx-accessibility"></i>
            <span>Teaching Assessment</span>
          </Link>
        </li>
        </> 
      )}
    </>
  )
}
export default TeachingAssesmentLinks
