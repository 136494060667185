import { gql } from "@apollo/client"

const DELETE_EMPLOYMENT_HISTORY = gql`
mutation deleteEmploymentHistory($uid: String!, $deleteBy: Int!) {
  deleteEmploymentHistory(uid: $uid, deletedBy: $deleteBy) {
    status
    code
    message
    data {
      city
      country
      departmentName
      endDate
      id
      institutionName
      position
      responsibilities
      startDate
      uid
      institutionType {
        code
        id
        name
        uid
      }
    }
  }
}
`;


const addUpdateEmploymentHistory = gql`
mutation addUpdateEmploymentHistory($input: EmploymentHistoryInput!) {
  addUpdateEmploymentHistory(input: $input) {
    code
    message
    status
    data {
      city
      country
      departmentName
      endDate
      id
      institutionName
      position
      responsibilities
      startDate
      uid
      institutionType {
        code
        id
        name
        uid
      }
    }
  }
}
`

export { DELETE_EMPLOYMENT_HISTORY,addUpdateEmploymentHistory }
