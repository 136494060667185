import { gql } from "@apollo/client"

const GET_ACADEMIC_QUALIFICATIONS_BY_STAFF_UID = gql`
  query getAcademicQualificationsByStaffUid($staffUid: String!) {
    getAcademicQualificationsByStaffUid(staffUid: $staffUid) {
      code
      data {
        admissionYear
        approvalDate
        approvalStatus
        approvalStatusReason
        approvedBy
        awardCertificate
        cityOfAward
        completionYear
        countryOfAward
        degreeAwardName
        gpa
        institutionName
        institutionType
        specialization
        staffClass
        staffUid
        tcuCertificate
        tcuVerificationNumber
        thesisDissertationTitle
        uid
        qualificationLevel {
          code
          name
          uid
        }
      }
      message
      status
    }
  }
`

const GET_ACADEMIC_QUALIFICATIONS_UID = gql`
  query getEmploymentHistoryByUid($uid: String!) {
    getEmploymentHistoryByUid(uid: $uid) {
      code
      message
      status
      data {
        city
        country
        departmentName
        endDate
        id
        institutionName
        position
        responsibilities
        startDate
        uid
        institutionType {
          code
          id
          name
          uid
        }
      }
    }
  }
`

export {
  GET_ACADEMIC_QUALIFICATIONS_BY_STAFF_UID,
  GET_ACADEMIC_QUALIFICATIONS_UID,
}
