// export default ProgramRequirementList;
import React, { createContext, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import ProgramRequirementModal from "./ProgramRequirementModal"
import {
  GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID
} from "../Queries/ProgramRequirementQueries"
import { DELETE_PROGRAM_REQUIREMENT } from "../Mutations/ProgramRequirementMutation"
import { useLocation } from "react-router"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"

const breadcrumbItem = [
  {
    title: "Program Requirement List",
    path: "",
    isActive: true
  }
]

export const ProgramRequirementContext = createContext(undefined) //Create data to transfer to child modal

const ProgramRequirementList = () => {
  const location = useLocation()
  let { program } = location.state

  const searchValues = {
    search: ""
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required("")
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    }
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedProgramRequirement, setSelectedProgramRequirement] =
    useState(null) //Get data for deleting
  const [updateProgramRequirementData, setUpdateProgramRequirementData] =
    useState(null) //Get data for editing

  const {
    loading: loadingResponse,
    error: errorRessponse,
    data: responseData,
    refetch: refetchResponse
  } = useQuery(GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID, {
    client: onlineApplicationGraphQLClient,
    variables: {
      uid: program?.uid
    },
    skip: !program?.uid,
    fetchPolicy: "network-only"
  })

  const results = responseData?.getProgramSubjectRequirementByProgramCapacityUid?.data?.items

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteProgramRequirement] = useMutation(DELETE_PROGRAM_REQUIREMENT, {
    client: onlineApplicationGraphQLClient,
    variables: { uid: selectedProgramRequirement?.uid },
    refetchQueries: [
      {
        query: GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID,
        variables: {
          uid: program?.uid
        },
      }
    ]
  })

  const handleDeleteProgramRequirement = delProgramRequirement => {
    setSelectedProgramRequirement(delProgramRequirement)
    showDeleteWarning(() => {
      performProgramRequirementDeletion().then(r => console.log(r))
    })
  }

  const performProgramRequirementDeletion = async () => {
    try {
      const response = await deleteProgramRequirement()
      showToast(
        response.data.removeProgramSubjectRequirement.message,
        response.data.removeProgramSubjectRequirement.code === 8000
          ? "success"
          : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search
    }
    // Update the search query variables and refetch the data
    searchProgramRequirements({
      variables: { pagination: { ...pagination, ...data } }
    }).then(r => console.log(r))
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    results?.length || 0

  return (
    <ProgramRequirementContext.Provider
      value={{ updateProgramRequirementData, setUpdateProgramRequirementData }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle={`Program Requirement List for ${program.programName}`}
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <ProgramRequirementModal uid={program?.uid} />
                    </Col>
                  </Row>
                </CardSubtitle>
                {
                  loadingResponse && (
                    <PlaceHolderLoader rows={4} type="table" columSize={12} />
                  )
                }


                {!loadingResponse && !errorRessponse && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Entry Mode</Th>
                            <Th data-priority="1">Qualification Method</Th>
                            <Th data-priority="1">Subject</Th>
                            <Th data-priority="1">Subject Grade</Th>
                            <Th data-priority="1">Must Pass</Th>
                            <Th data-priority="1">Has alternative Subject</Th>
                            <Th data-priority="1">Subject Grade</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results?.slice(startIndex, endIndex)
                            .map((ProgramRequirement, index) => (
                              <Tr key={ProgramRequirement?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{ProgramRequirement?.entryCategoryCode}</Td>
                                <Td>{ProgramRequirement?.qualificationMethodNode?.name}</Td>
                                <Td>{ProgramRequirement?.subjectNode?.name}</Td>
                                <Td>{ProgramRequirement?.gradePointNode?.gradePoint}</Td>
                                <Td>{ProgramRequirement?.mustPass === true ? "Yes" : "No"}</Td>
                                <Td>{ProgramRequirement?.hasAlternativeSubject === true ? "Yes" : "No"}</Td>
                                <Td>{ProgramRequirement?.alternativeGradePointNode?.gradePoint}</Td>
                                <Td>
                                  <DropDownActionHelper
                                    data={ProgramRequirement}
                                    onUpdate={setUpdateProgramRequirementData}
                                    onDelete={handleDeleteProgramRequirement}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </ProgramRequirementContext.Provider>
  )
}

export default ProgramRequirementList
