import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_EMPLOYMENT_HISTORY } from "../Mutations/EmploymentHistoryMutations"
import { GET_EMPLOYMENT_HISTORY } from "../Queries/EmploymentHistoryQueries"
import { EmploymentHistoryContext } from "./EmploymentHistory"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import moment from "moment"

const EmploymentHistoryModal = () => {
  const {
    updateEmploymentHistoryData,
    setUpdateEmploymentHistoryData,
    staffUid,
  } = useContext(EmploymentHistoryContext)

  const InstitutionType = [
    {
      label: "Public Institution",
      value: 1,
    },
    {
      label: "Private Institution",
      value: 2,
    },
  ]

  const [EmploymentHistory] = useMutation(ADD_UPDATE_EMPLOYMENT_HISTORY)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_EMPLOYMENT_HISTORY
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      position: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.position
        : "",
      departmentName: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.departmentName
        : "",
      institutionName: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.institutionName
        : "",
      institutionTypeId: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.institutionTypeId
        : "",
      startDate: updateEmploymentHistoryData
        ? moment(updateEmploymentHistoryData.startDate).format("YYYY-MM-DD")
        : "",
      uid: updateEmploymentHistoryData ? updateEmploymentHistoryData.uid : "",
      staffUid: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.staffUid
        : staffUid,
      endDate: updateEmploymentHistoryData
        ? moment(updateEmploymentHistoryData.endDate).format("YYYY-MM-DD")
        : "",
      responsibilities: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.responsibilities
        : "",
      city: updateEmploymentHistoryData ? updateEmploymentHistoryData.city : "",
      country: updateEmploymentHistoryData
        ? updateEmploymentHistoryData.country
        : "",
    },

    validationSchema: Yup.object().shape({
      position: Yup.string().required("Position is Required"),
      departmentName: Yup.string().required("Department Name is required"),
      institutionName: Yup.string().required("Institution Name is required"),
      institutionTypeId: Yup.string().required("Institution Type is required"),
      startDate: Yup.string().required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
      responsibilities: Yup.string().required("Responsibilities is required"),
      city: Yup.string().required("city is required"),
      country: Yup.string().required("Country is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      console.log(values)

      if (values.uid === "") {
        delete values.uid
      }

      EmploymentHistory({
        client: asimsGraphQLClient,
        variables: {
          input: values,
        },
        refetchQueries: [
          {
            query: GET_EMPLOYMENT_HISTORY,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerEmploymentHistory }) => {
          validation.resetForm()
          setModalOpen(false)
          setUpdateEmploymentHistoryData(false) // Close the modal
          if (registerEmploymentHistory.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateEmploymentHistoryData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerEmploymentHistory.message,
            registerEmploymentHistory.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error.message)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Employment History
      </Button>

      <Modal
        isOpen={modalOpen || !!updateEmploymentHistoryData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-country" id="staticBackdropLabel">
            {!!updateEmploymentHistoryData
              ? "EDIT EMPLOYMENT HISTORY"
              : "ADD EMPLOYMENT HISTORY"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateEmploymentHistoryData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <h5 className="form-details-header">Institution Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="institutionTypeId"
                      className="col-form-label"
                    >
                      Institution type:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("institutionTypeId", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateEmploymentHistoryData?.institutionType?.uid
                      }
                      defaultInputValue={
                        updateEmploymentHistoryData?.institutionType?.name
                      }
                      options={InstitutionType}
                      className="select2-selection"
                    />
                    <Input
                      name="institutionTypeId"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.institutionTypeId || ""}
                      invalid={
                        !!(
                          validation.touched.institutionTypeId &&
                          validation.errors.institutionTypeId
                        )
                      }
                    />
                    {validation.touched.institutionTypeId &&
                    validation.errors.institutionTypeId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.institutionTypeId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="institutionName" className="col-form-label">
                      Institution Name:
                    </label>
                    <Input
                      type="text"
                      name="institutionName"
                      className="form-control"
                      id="institutionName"
                      placeholder="Enter Institution Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.institutionName || ""}
                      invalid={
                        !!(
                          validation.touched.institutionName &&
                          validation.errors.institutionName
                        )
                      }
                    />
                    {validation.touched.institutionName &&
                    validation.errors.institutionName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.institutionName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="departmentName" className="col-form-label">
                      Department Name:
                    </label>
                    <Input
                      type="text"
                      name="departmentName"
                      className="form-control"
                      id="departmentName"
                      placeholder="Enter Middle Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.departmentName || ""}
                      invalid={
                        !!(
                          validation.touched.departmentName &&
                          validation.errors.departmentName
                        )
                      }
                    />
                    {validation.touched.departmentName &&
                    validation.errors.departmentName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.departmentName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="position" className="col-form-label">
                      Position:
                    </label>
                    <Input
                      type="text"
                      name="position"
                      className="form-control"
                      id="position"
                      placeholder="Enter Position"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.position ||
                        updateEmploymentHistoryData?.position ||
                        ""
                      }
                      invalid={
                        !!(
                          validation.touched.position &&
                          validation.errors.position
                        )
                      }
                    />
                    {validation.touched.position &&
                    validation.errors.position ? (
                      <FormFeedback type="invalid">
                        {validation.errors.position}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="12" lg="12">
                  <div className="mb-3">
                    <label
                      htmlFor="responsibilities"
                      className="col-form-label"
                    >
                      Responsibilities:
                    </label>
                    <textarea
                      name="responsibilities"
                      className="form-control"
                      id="responsibilities"
                      placeholder="Enter Responsibilities"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.responsibilities || ""}
                      onInvalid={
                        !!(
                          validation.touched.responsibilities &&
                          validation.errors.responsibilities
                        )
                      }
                    />
                    {validation.touched.responsibilities &&
                    validation.errors.responsibilities ? (
                      <FormFeedback type="invalid">
                        {validation.errors.responsibilities}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Employment Period</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="startDate" className="col-form-label">
                      Start Date:
                    </label>
                    <Input
                      type="date"
                      name="startDate"
                      className="form-control"
                      id="startDate"
                      placeholder="Enter Start Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      invalid={
                        !!(
                          validation.touched.startDate &&
                          validation.errors.startDate
                        )
                      }
                    />
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="endDate" className="col-form-label">
                      End Date:
                    </label>
                    <Input
                      type="date"
                      name="endDate"
                      className="form-control"
                      id="endDate"
                      placeholder="Enter End Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      invalid={
                        !!(
                          validation.touched.endDate &&
                          validation.errors.endDate
                        )
                      }
                    />
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">
                Institution Location Details
              </h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="country" className="col-form-label">
                      Country:
                    </label>
                    <Input
                      type="text"
                      name="country"
                      className="form-control"
                      id="country"
                      placeholder="Enter Title"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.country || ""}
                      invalid={
                        !!(
                          validation.touched.country &&
                          validation.errors.country
                        )
                      }
                    />
                    {validation.touched.country && validation.errors.country ? (
                      <FormFeedback type="invalid">
                        {validation.errors.country}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="city" className="col-form-label">
                      City
                    </label>
                    <Input
                      type="text"
                      name="city"
                      className="form-control"
                      id="city"
                      placeholder="Enter Speciality"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.city || ""}
                      invalid={
                        !!(validation.touched.city && validation.errors.city)
                      }
                    />
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateEmploymentHistoryData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EmploymentHistoryModal
