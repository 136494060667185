import { StyleSheet } from '@react-pdf/renderer';

const pageStyle = StyleSheet.create({
    page: {fontFamily: 'Helvetica',
        padding: 30, },
    section: { color: 'white', textAlign: 'center', margin: 30 },
    container: {
        flexDirection: 'column',
    },
});

const ExamTicketStyles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        padding: 20,
    },
    header: {
        fontSize: 18,
        marginBottom: 10,
    },
    infoContainer: {
        marginBottom: 20,
    },
    infoText: {
        fontSize: 14,
        marginBottom: 5,
    },
    coursesList: {
        fontSize: 14,
    },
});

const TablePdfStyles = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 10
    }
});
export {pageStyle,ExamTicketStyles,TablePdfStyles}