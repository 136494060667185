import React, { createContext, useContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, CardFooter } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation, useQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "../SelfRegistration"
import { DELETE_USER_NEXT_OF_KIN, GET_USER_NEXT_OF_KIN } from "Modules/Students/Queries/NextOfKin/NextOfkinQueries"
import { Link } from "react-router-dom"
import StudentNextOfKinModal from "./StudentNextOfKinModal"
import DropDownActionHelper from "helpers/DropDownActionHelper"

export const StudentNextOfKinContext = createContext() //Create data to transfer to child modal

const StudentNextOfKinDetails = () => {
  const { updateStudentData, setUpdateStudentData } =
    useContext(StudentProfileContext)
  const { toggleTab, handleSubmit } = useContext(StudentProfileContext)
  const [selectedNextOfKin, setSelectedNextOfKin] = useState(null) //Get data for deleting
  const [updateNextOfKinData, setUpdateNextOfKinData] = useState(null) //Get data for editing

  //alert('zzzz'+updateStudentData?.uid)
  const { loading, error, data } = useQuery(GET_USER_NEXT_OF_KIN, {
    client: uaaGraphQLClient,
    variables: {
      userUid: updateStudentData?.uid,
    },
    fetchPolicy: "cache-first",
  })


  const [deleteNextOfKin] = useMutation(DELETE_USER_NEXT_OF_KIN, {

    client: uaaGraphQLClient,

    variables: { uid: selectedNextOfKin?.uid },
    
    refetchQueries: [
      {
        query: GET_USER_NEXT_OF_KIN,
        variables: {
          userUid: updateStudentData?.uid,
        },
      },
    ],
    fetchPolicy: "no-cache"
  })

  const handleDeleteNextOfKin = (nextOfKin) => {
    setSelectedNextOfKin(nextOfKin)
    showDeleteWarning(() => {
      performNextOfKinDeletion()
    })
  }

  const performNextOfKinDeletion = async () => {
    try {
      const response = await deleteNextOfKin()
      showToast(response.data.removeChildren.message,
                response.data.removeChildren.code === 8000 ? "success" : "error")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  const result = data?.getUserNextOfKins.data

  const handleNext = () => {
    if(result?.length > 0){
      toggleTab(5) // Move to the previous tab
    }else{
      showToast("At-least one next of kin is required", "warning")
      toggleTab(4);
    }
  }

  const handlePrevious = () => {
    toggleTab(3) // Move to the previous tab
  }

  return (
    <StudentNextOfKinContext.Provider
      value={{ updateNextOfKinData, updateStudentData, setUpdateNextOfKinData }}
    >
      <div>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-12 col-sm-12 col-md-12 pull-right">
                      <StudentNextOfKinModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && (
                  <div className="table-rep-plugin">
                    <div
                      className="mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Full Name</Th>
                            <Th data-priority="1">Kinship</Th>
                            <Th data-priority="1">Place of Domicile</Th>
                            <Th data-priority="1">Phone</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result?.map((nextOfKin, index) => (
                            <Tr key={nextOfKin?.uid}>
                              <Td>{index + 1}</Td>
                              <Td>{nextOfKin?.fullName}</Td>
                              <Td>{nextOfKin?.kinship?.name}</Td>
                              <Td>{nextOfKin?.placeOfDomicile}</Td>
                              <Td>{nextOfKin?.phoneNumber}</Td>
                              <Td>{nextOfKin?.email}</Td>
                              <Td>
                                <DropDownActionHelper
                                  data={nextOfKin}
                                  onUpdate={setUpdateNextOfKinData}
                                  onDelete={handleDeleteNextOfKin}
                                />
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>
                <div className="wizard">
                  <div className="actions">
                    <ul>
                      <li className="previous" color="info">
                        <Link to="#" onClick={handlePrevious}>
                          Previous
                        </Link>
                      </li>
                      <li className="next">
                          <Link to="#" onClick={handleNext}>
                            Next
                          </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </StudentNextOfKinContext.Provider>
  )
}

export default StudentNextOfKinDetails
