
import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import PublishProvisionalResult from "../Views/PublishProvisionalResult";
import PartialTranscript from "../../Transcripts/Views/PartialTranscript";

const PrincipalRoutes = (props) => {

    return (
        <Routes>
            <Route path="/" element={<PublishProvisionalResult />} />
            <Route index={true} path="/partial-transcript" element={<PartialTranscript/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );

}

export default PrincipalRoutes;