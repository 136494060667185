import { gql } from "@apollo/client"

const CREATE_STAFF_CATEGORY = gql`
  mutation RegisterStaffCategory($inputs: [StaffCategoryDtoInput!]!) {
    registerStaffCategories(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          uid
          name
        }
      }
    }
  }
`

const DELETE_STAFF_CATEGORY = gql`
  mutation RemoveStaffCategory($uid: String!) {
    removeStaffCategory(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_STAFF_CATEGORY, DELETE_STAFF_CATEGORY }
