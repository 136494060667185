import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { Link } from "react-router-dom"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_DISABILITIES } from "Modules/Lookups/Dissability/Queries/DisabilityQueries"
import { GET_MARITAL_STATUSES } from "Modules/MaritalStatus/Queries/MaritalStatusesQueries"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { UPDATE_STUDENT_DETAILS } from "../Mutations/UpdateStudentMutation"
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery"
import { GET_SPONSORS } from "Modules/Sponsors/Queries/SponsorQueries"
import { update } from "lodash"
import { GET_BANKS } from "Modules/Banks/Queries/BankQueries"

const breadcrumbItem = [
  {
    title: "My Profile",
    path: "/Students/Profile",
    isActive: false,
  },
  {
    title: "Update",
    path: "",
    isActive: true,
  },
]

const StudentUpdateInformation = ({ validation }) => {
  const [updateStudentDetails] = useMutation(UPDATE_STUDENT_DETAILS, {
    client: uaaGraphQLClient,
  })

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch: userRefetch
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: { username: JSON.parse(localStorage.getItem("authUser"))?.data?.username },
  })

  // console.log("student data",userDetails)

  const Student = userDetails?.getUserByUsername?.data
  const StudentData = Student?.student

  const [isSubmitted] = useState(false)
  const [studentDissability, setStudentDissability] = useState(false)

  const dissabilityOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ]

  const [fetching, setFetching] = useState(false)

  const {
    loading: disabilityLoading,
    error: disabilityError,
    data: disabilityData,
  } = useQuery(GET_DISABILITIES, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })
  //
  // if (disabilityError)
  //   return showToast("Failed to fetch Marital Statuses", "error")

  const disabilities = disabilityData?.getDisabilities?.data?.items

  const {
    loading: maritalLoading,
    error: maritalError,
    data: maritalData,
  } = useQuery(GET_MARITAL_STATUSES, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (maritalError) return showToast("Failed to fetch marital status", "error")
  const maritalStatus = maritalData?.getMaritalStatuses?.data?.items

  const {
    loading: sponsorLoading,
    error: sponsorError,
    data: sponsorData,
  } = useQuery(GET_SPONSORS, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (sponsorError) return showToast("Failed to fetch sponsor details", "error")
  const sponsors = sponsorData?.getSponsors?.data?.items

  const {
    loading: bankLoading,
    error: bankError,
    data: bankData,
  } = useQuery(GET_BANKS, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (bankError) return showToast("Failed to fetch bank details", "error")
  const banks = bankData?.getBanks?.data?.items

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const onSubmit = e => {
    e.preventDefault()
    validation.handleSubmit()
    return null
  }
  validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      isDisabled: Student ? Student?.isDisabled : null,
      dissabilityOptionKey: null,
      firstName: Student ? Student?.firstName : "",
      middleName: Student ? Student?.middleName : "",
      lastName: Student ? Student?.lastName : "",
      email: Student ? Student?.email : "",
      nin: Student ? Student?.nin : "",
      phoneNumber: Student ? Student?.phoneNumber : "",
      dateOfBirth: Student ? Student?.dateOfBirth : "",
      placeOfBirth: Student ? Student?.placeOfBirth : "",
      disability: Student ? Student?.disability : "",
      nhifNumber: StudentData ? StudentData?.nhifNumber : "",
      maritalStatusUid: Student ? Student?.maritalStatus?.uid : "",
      sponsorsUid: Student ? Student.sponsorsUid : "",
      bankUid: Student ? Student.bankUid : "",
      accountNumber: Student ? StudentData?.accountNumber : "",
      gender: Student ? Student?.gender : "",
      physicalAddress: Student ? Student.physicalAddress : "",
    },
    validationSchema: Yup.object().shape({
      maritalStatusUid: Yup.string().required("Marital status is required"),
      phoneNumber: Yup.string()
        .min(10)
        .max(13)
        .required("Please Enter Valid Phone Number"),
      nin: Yup.string().required("NIDA is required").max(20).min(20),
      nhifNumber: Yup.string().nullable()
        .required("NHIF number is required")
        .max(20)
        .min(5),
      dateOfBirth: Yup.date().required("Date of Birth is required"),
      placeOfBirth: Yup.string().required("Place of Birth is required"),
      isDisabled: Yup.string().required("Please choose option"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const postData = {
        disability: studentDissability ? values.disability : null,
        nin: values.nin,
        phoneNumber: values.phoneNumber,
        dateOfBirth: values.dateOfBirth,
        placeOfBirth: values.placeOfBirth,
        maritalStatusUid: values.maritalStatusUid,
        sponsorsUid: values.sponsorsUid,
        nhifNumber: values.nhifNumber,
        isDisabled: values.isDisabled,
        gender: values.gender,
        bankUid: values.bankUid,
        accountNumber: values.accountNumber,
        physicalAddress: values.physicalAddress,
      }

      updateStudentDetails({
        variables: {
          input: { ...postData, userUid: Student?.uid },
        },

        refetchQueries: [
          {
            query: GET_STUDENT,
            variables: {
              username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
            },
          },
        ],

        onCompleted: ({ updateStudentDetails }) => {
          if (updateStudentDetails.code === 8000) {
            showToast(updateStudentDetails.message, "success")

            console.log("Data updated successfully")

            setSubmitting(false)
          } else {
            console.log("Failed to update data")
            setSubmitting(false)
          }
          showToast(
            updateStudentDetails.message,
            updateStudentDetails.code === 8000 ? "success" : "error"
          )
          setSubmitting(false)
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)
          console.error("Mutation error:", error)
        },
      })
    },
  })

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Update Profile">
      <Row>
        {fetching ? (
          <PlaceHolderLoader buttonShown={true} columSize={12} />
        ) : (
          <Col lg={12}>
            <Card>
              <CardBody>
                {
                  !userLoading ? (
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return null
                    }}
                  >
                    <div className="row"></div>

                    <div className="modal-body">
                      <Row>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <label htmlFor="firstName">First Name:</label>
                            <Input
                              type="text"
                              name="firstName"
                              readOnly
                              className="form-control"
                              id="firstName"
                              placeholder="Enter First Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={Student?.firstName ? Student?.firstName : ""}
                              invalid={
                                !!(
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                )
                              }
                            />
                            {validation.touched.firstName &&
                              validation.errors.firstName && (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstName}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <Label for="middleName">Middle Name</Label>
                            <Input
                              type="text"
                              name="middleName"
                              readOnly
                              className="form-control"
                              id="middleName"
                              placeholder="Enter middle name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                Student?.middleName ? Student?.middleName : ""
                              }
                              invalid={
                                !!(
                                  validation.touched.middleName &&
                                  validation.errors.middleName
                                )
                              }
                            />
                            {validation.touched.middleName &&
                              validation.errors.middleName && (
                                <FormFeedback type="invalid">
                                  {validation.errors.middleName}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col lg="4" md="4">
                          <div className="mb-3">
                            <label htmlFor="lastName">Last Name:</label>
                            <Input
                              type="text"
                              readOnly
                              name="lastName"
                              className="form-control"
                              id="lastName"
                              placeholder="Enter Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={Student?.firstName ? Student?.lastName : ""}
                            />

                            {validation.touched.lastName &&
                              validation.errors.lastName && (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastName}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4" md="4">
                          <div className="mb-3">
                            <label htmlFor="lastName">Registration No.</label>
                            <Input
                              type="text"
                              readOnly
                              name="registrationNumber"
                              className="form-control"
                              id="avn"
                              placeholder="Enter Registration No."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={StudentData?.registrationNumber || ""}
                            />
                            {validation.touched.registrationNumber &&
                              validation.errors.registrationNumber && (
                                <FormFeedback type="invalid">
                                  {validation.errors.registrationNumber}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <label htmlFor="f4indexno">Form IV Index No:</label>
                            <Input
                              type="text"
                              name="f4indexno"
                              readOnly
                              className="form-control"
                              id="f4indexno"
                              placeholder="Enter Form 4 index no"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                StudentData?.studentCertificates?.[0]
                                  ?.certificateNumber
                              }
                              invalid={
                                !!(
                                  validation.touched.f4indexno &&
                                  validation.errors.f4indexno
                                )
                              }
                            />
                            {validation.touched.f4indexno &&
                              validation.errors.f4indexno && (
                                <FormFeedback type="invalid">
                                  {validation.errors.f4indexno}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <Label for="f6indexno">Form VI index No.</Label>
                            <Input
                              type="text"
                              name="f6indexno"
                              readOnly
                              className="form-control"
                              id="f6indexno"
                              placeholder="Form six index no."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                StudentData?.studentCertificates?.[1]
                                  ?.certificateNumber || ""
                              }
                              invalid={
                                !!(
                                  validation.touched.f6indexno &&
                                  validation.errors.f6indexno
                                )
                              }
                            />
                            {validation.touched.f6indexno &&
                              validation.errors.f6indexno && (
                                <FormFeedback type="invalid">
                                  {validation.errors.f6indexno}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="4" md="4">
                          <div className="mb-3">
                            <Label for="phoneNumber">Phone Number</Label>
                            <Input
                              type="text"
                              name="phoneNumber"
                              className="form-control"
                              id="phoneNumber"
                              placeholder="Enter Phone Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phoneNumber}
                              invalid={
                                !!(
                                  validation.touched.phoneNumber &&
                                  validation.errors.phoneNumber
                                )
                              }
                            />
                            {validation.touched.phoneNumber &&
                              validation.errors.phoneNumber && (
                                <FormFeedback type="invalid">
                                  {validation.errors.phoneNumber}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <label>Select Marital Status</label>
                            <Select
                              name="maritalStatusUid"
                              onChange={e => {
                                validation.setFieldValue(
                                  "maritalStatusUid",
                                  e.value
                                )
                              }}
                              options={maritalStatus?.map(m => ({
                                value: m?.uid,
                                label: m?.name,
                              }))}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999,
                                }),
                              }}
                              defaultValue={
                                userDetails?.getUserByUsername.data?.maritalStatus
                                  ?.uid
                              }
                              defaultInputValue={
                                userDetails?.getUserByUsername.data?.maritalStatus
                                  ?.name
                              }
                            />
                            <Input
                              name="maritalStatusUid"
                              placeholder="Select Marital Status"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.maritalStatusUid ||
                                userDetails?.getUserByUsername.data?.maritalStatus
                                  ?.uid ||
                                ""
                              }
                              invalid={
                                !!(
                                  validation.touched.maritalStatusUid &&
                                  validation.errors.maritalStatusUid
                                )
                              }
                            />
                            {validation.touched.maritalStatusUid &&
                            validation.errors.maritalStatusUid ? (
                              <FormFeedback type="invalid">
                                {validation.errors.maritalStatusUid}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3">
                            <label htmlFor="email">Email</label>
                            <Input
                              type="text"
                              name="email"
                              readOnly
                              className="form-control"
                              id="email"
                              placeholder="Enter email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.email || Student?.email || ""
                              }
                              invalid={
                                !!(
                                  validation.touched.email &&
                                  validation.errors.email
                                )
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email && (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <label htmlFor="placeOfBirth">Place of Birth:</label>
                            <Input
                              type="text"
                              name="placeOfBirth"
                              className="form-control"
                              id="placeOfBirth"
                              placeholder="Enter place of birth"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.placeOfBirth}
                              invalid={
                                validation.touched.placeOfBirth &&
                                validation.errors.placeOfBirth
                              }
                            />
                            {validation.touched.placeOfBirth &&
                            validation.errors.placeOfBirth ? (
                              <FormFeedback type="invalid">
                                {validation.errors.placeOfBirth}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg="4" md="4">
                          <div className="mb-3">
                            <Label>Date Of Birth</Label>
                            <InputGroup>
                              <Flatpickr
                                className="form-control d-block"
                                // name="dateOfBirth"
                                placeholder="dd M,yyyy"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                }}
                                onChange={changedateOfBirth}
                                value={
                                  Student?.dateOfBirth ? Student?.dateOfBirth : ""
                                }
                              />
                            </InputGroup>
                            <Input
                              name="dateOfBirth"
                              placeholder="Enter date of birth"
                              type="hidden"
                              rows={3}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.dateOfBirth || ""}
                              invalid={
                                !!(
                                  validation.touched.dateOfBirth &&
                                  validation.errors.dateOfBirth
                                )
                              }
                            />
                            {validation.touched.dateOfBirth &&
                              validation.errors.dateOfBirth && (
                                <FormFeedback type="invalid">
                                  {validation.errors.dateOfBirth}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3">
                            <label htmlFor="nin">NIDA Number</label>
                            <Input
                              type="text"
                              name="nin"
                              className="form-control"
                              id="nin"
                              placeholder="Enter Your NIDA Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.nin}
                              invalid={
                                validation.touched.nin && validation.errors.nin
                              }
                            />

                            {validation.touched.nin && validation.errors.nin && (
                              <FormFeedback type="invalid">
                                {validation.errors.nin}
                              </FormFeedback>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <div className="mb-3">
                            <Label>Postal/Physical Address</Label>
                            <Input
                              type="textarea"
                              name="physicalAddress"
                              className="form-control"
                              id="physicalAddress"
                              placeholder="Enter your Postal or Physical Address"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.physicalAddress}
                              invalid={
                                validation.touched.physicalAddress &&
                                validation.errors.physicalAddress
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.physicalAddress &&
                              validation.errors.physicalAddress && (
                                <FormFeedback type="invalid">
                                  {validation.errors.physicalAddress}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="mb-3">
                            <Label>NHIF Membership Number</Label>
                            <Input
                              type="text"
                              name="nhifNumber"
                              className="form-control"
                              id="nhifNumber"
                              placeholder="Enter your NHIF Membership No"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.nhifNumber}
                              invalid={
                                validation.touched.nhifNumber &&
                                validation.errors.nhifNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.nhifNumber &&
                              validation.errors.nhifNumber && (
                                <FormFeedback type="invalid">
                                  {validation.errors.nhifNumber}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <label>Financial Sponsor</label>
                            <Select
                              name="maritalStatusUid"
                              onChange={e => {
                                validation.setFieldValue("sponsorsUid", e.value)
                              }}
                              options={sponsors?.map(s => ({
                                value: s.uid,
                                label: s.name,
                              }))}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999,
                                }),
                              }}
                              defaultValue={
                                userDetails?.getUserByUsername.data?.student
                                  ?.sponsors?.[0]?.uid
                              }
                              defaultInputValue={
                                userDetails?.getUserByUsername.data?.student
                                  ?.sponsors?.[0]?.name
                              }
                            />
                            <Input
                              name="sponsorsUid"
                              placeholder="Select Sponsor"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.uid ||
                                Student?.user?.sponsors?.uid ||
                                ""
                              }
                              invalid={
                                !!(
                                  validation.touched.sponsorsUid &&
                                  validation.errors.sponsorsUid
                                )
                              }
                            />
                            {validation.touched.sponsorsUid &&
                              validation.errors.sponsorsUid && (
                                <FormFeedback type="invalid">
                                  {validation.errors.sponsorsUid}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <div className="mb-3">
                            <Label>Bank Account Number</Label>
                            <Input
                              type="text"
                              name="accountNumber"
                              className="form-control"
                              id="accountNumber"
                              placeholder="Enter your Bank Account No."
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.accountNumber ||
                                Student?.accountNumber ||
                                ""
                              }
                              invalid={
                                validation.touched.accountNumber &&
                                validation.errors.accountNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.accountNumber &&
                              validation.errors.accountNumber && (
                                <FormFeedback type="invalid">
                                  {validation.errors.accountNumber}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col md="4" lg="4">
                          <div className="mb-3">
                            <label>Bank</label>
                            <Select
                              name="bankUid"
                              onChange={e => {
                                validation.setFieldValue("bankUid", e.value)
                              }}
                              options={banks?.map(b => ({
                                value: b.uid,
                                label: b.name,
                              }))}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999,
                                }),
                              }}
                              defaultValue={
                                userDetails?.getUserByUsername.data?.student?.bank
                                  ?.uid
                              }
                              defaultInputValue={
                                userDetails?.getUserByUsername.data?.student?.bank
                                  ?.name
                              }
                            />
                            <Input
                              name="bankUid"
                              placeholder="Select Bank"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.uid ||
                                StudentData?.bank?.uid ||
                                ""
                              }
                              invalid={
                                !!(
                                  validation.touched.bankUid &&
                                  validation.errors.bankUid
                                )
                              }
                            />
                            {validation.touched.bankUid &&
                              validation.errors.bankUid && (
                                <FormFeedback type="invalid">
                                  {validation.errors.bankUid}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label>
                              Do you have any disability or dissabilities?
                            </Label>
                            <Select
                              name="isDisabled"
                              onChange={e => {
                                validation.setFieldValue("isDisabled", e.value)
                                setStudentDissability(e.value)
                              }}
                              options={dissabilityOptions}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999,
                                }),
                              }}
                              defaultValue={Student?.isDisabled}
                              defaultInputValue={
                                Student?.isDisabled === false ? "No" : "Yes"
                              }
                            />
                            <Input
                              name="isDisabled"
                              placeholder="Select Disability"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.isDisabled ||
                                Student?.isDisabled ||
                                ""
                              }
                              invalid={
                                !!(
                                  validation.touched.isDisabled &&
                                  validation.errors.isDisabled
                                )
                              }
                            />
                            {validation.touched.isDisabled &&
                            validation.errors.isDisabled ? (
                              <FormFeedback type="invalid">
                                {validation.errors.isDisabled}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        {studentDissability && (
                          <Col lg="6">
                            <div className="mb-3">
                              <label>Select Disability</label>
                              <Select
                                onChange={selectedOptions => {
                                  const selectedValues = selectedOptions.map(
                                    option => ({
                                      disabilityUid: option.value,
                                    })
                                  )
                                  validation.setFieldValue(
                                    "disability",
                                    selectedValues
                                  )
                                }}
                                options={disabilities?.map(dp => ({
                                  value: dp.uid,
                                  label: dp.name,
                                }))}
                                isMulti={true} // Enable multiple selections
                                className="select2-selection"
                                styles={{
                                  menu: base => ({
                                    ...base,
                                    position: "absolute",
                                    zIndex: 9999,
                                  }),
                                }}
                              />
                              <Input
                                name="disability" // Update the field name
                                placeholder="Select Marital Status"
                                type="hidden"
                                rows={2}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={JSON.stringify(
                                  validation.values.disability ||
                                    Student?.uid ||
                                    []
                                )} // Set the value as a JSON string
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div className=" d-flex flex-wrap gap-2">
                      <SubmitButtonHelper
                        type="primary"
                        name="Submit"
                        formik={validation}
                      />
                      <Button type="reset" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </Form>
                  ): (
                    <PlaceHolderLoader rows={3} type="table" columSize={12} />
                  )
                }
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </LayoutHelper>
  )
}
export default StudentUpdateInformation
