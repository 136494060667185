import { gql } from "apollo-boost"

const CREATE_WORKFLOW = gql`
  mutation RegisterWorkflow($inputs: [WorkflowInput!]!) {
    registerWorkflows(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          uid
          name
          description
        }
      }
    }
  }
`

const CREATE_TRANSITION_META_WORKFLOW = gql`
  mutation RegisterTransitionMeta($inputs: [TransitionMetaInput!]!) {
    registerTransitionMetas(inputs: $inputs) {
      status
      code
      message
    }
  }
`;

const DELETE_WORKFLOW = gql`
  mutation RemoveWorkflow($uid: String!) {
    removeWorkflow(uid: $uid) {
      status
      code
      message
    }
  }
`;

const DELETE_TRANSITION_META = gql`
  mutation RemoveTransitionMeta($uid: String!) {
    removeTransitionMeta(uid: $uid) {
      status
      code
      message
    }
  }
`;

export { CREATE_WORKFLOW, DELETE_WORKFLOW, CREATE_TRANSITION_META_WORKFLOW, DELETE_TRANSITION_META }
