import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import showToast from "helpers/ToastHelper"
const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"
const qr_code = process.env.PUBLIC_URL + "/qrcode.jpg"

const pasport_size = process.env.PUBLIC_URL + "/pasport_size.jpg"

export const GenerateStudentExamTicket = (
  student,
  tableDataResponse,
  currentDate,
  user1,
  activeAcademicYear,
  qrCode,
  program,
  signaturePad
) => {
  try {
    const doc = new jsPDF({
      orientation: "portrait",
    })
    const pageWidth = doc.internal.pageSize.getWidth();
    // Load both images before creating the PDF
    doc.addImage(sua_logo, "PNG", 10, 5, 30, 30) // Adjust position and size as needed

    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 100, 20, { align: "center" }) // Adjust position as needed

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 100, 25, {
      align: "center",
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 100, 30, {
      align: "center",
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      100,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 100, 40, {
      align: "center",
    })

    doc.setLineWidth(0.5);
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text("Student Examination Ticket", 100, 50, {
      align: "center",
    })
    doc.line(15, 52, pageWidth - 15, 52); // Draw a line across the page width

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Student Name: " + student?.lastName + ", "+student?.firstName, 15, 60, {
      align: "left",
    })

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Academic Year: "+activeAcademicYear?.name, 180, 60, {
      align: "right",
    })

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Registration No: "+student?.student?.registrationNumber, 15, 65, {
      align: "left",
    })

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Semester:   2", 180, 65, {
      align: "right",
    })

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Program Name:   "+program, 15, 70, {
      align: "left",
    })

    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Printed Date  "+currentDate, 180, 70, {
      align: "right",
    })

    doc.setFontSize(11)
    doc.setFont("Year", "bold")
    doc.text("Study Year: "+student?.student?.studyYear, 15, 75, {
      align: "left",
    })

    doc.addImage(user1, "PNG", 170, 5, 30, 30) // Adjust position and size as needed


    doc.setFontSize(11)
    doc.setFont("Roboto", "bold")
    doc.text("Course Registered", 100, 80, {
      align: "center",
    })

    const textAboveTableHeight = 82 // Adjust based on your actual text height

    // Example table data
    const tableData = tableDataResponse

    // const total_courses = tableData.length
    let total_rows = 0

    if(tableData.length % 2 === 0){
      total_rows = tableData.length / 2
    }else {
       total_rows = (tableData.length / 2) + 1
    }

    const chunkedData = [];
    for (let i = 0; i < tableData.length; i += total_rows) {
      chunkedData.push(tableData.slice(i, i + total_rows));
    }

    // Set up the table configuration for each chunk
    chunkedData.forEach((chunk, index) => {
      const startY = textAboveTableHeight + Math.floor(index / 2) * 50; // Adjust vertical spacing between tables
      const marginLeft = index % 2 === 0 ? 15 : 110; // Alternate between left and right

      doc.autoTable({
        startY: startY,
        margin: { top: 5, left: marginLeft, right: 10 },
        headStyles: { fillColor: [41, 128, 185], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
        bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
        columnStyles: {
          0: { cellWidth: 20 }, // Adjust the width of the first column
          1: { cellWidth: 50 }, // Adjust the width of the second column
          2: { cellWidth: 25 }, // Adjust the width of the third column
        },
        head: [["S/No", "Course Ante", "Credit"]], // Headers
        body: chunk, // Table data
      });
    });

    doc.setFontSize(8)
    doc.setFont("Roboto")
    doc.text("This ticket is valid from 1st July 2024 - 27th July 2024.", 100, 140, {
      align: "center",
    })

    doc.setFontSize(15)
    doc.setFont("Roboto")
    doc.text("I declare the information provided above is correct", 100, 150, {
      align: "center",
    })

    doc.setFontSize(8)
    doc.setFont("Roboto")
    doc.text("Signed by "+student?.lastName + ", "+student?.firstName, 40, 155, {
      align: "center",
    })
    doc.setFontSize(8)
    doc.setFont("Roboto")
    doc.text("Approved by Director Of Undergraduate Studies", 170, 155, {
      align: "center",
    })
    const imageWidth = 15; // Adjust according to your image dimensions
    const imageHeight = 15; // Adjust according to your image dimensions
    const imageX = 160; // Adjust the X position to align the image to the right
    const imageY = 160; // Adjust the Y position below the text

    doc.addImage(
      qrCode,
      "PNG", // Image type
      90,
      imageY,
      30,
      30
    );
    signaturePad ? doc.addImage(
      signaturePad,
      "PNG", // Image type
      imageX,
      imageY,
      imageWidth,
      imageHeight
    ) : null

    // Save the PDF
    doc.save(student?.firstName + "_" + student?.lastName + ".pdf")
  } catch (error) {
    showToast("Something went wrong try again later! " + error, "info")
  }
}
