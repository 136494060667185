//REGISTE
import { gql } from '@apollo/client';


const GET_SUBJECTS = gql`
query GetSubject($pagination: PaginationInput!) {
  getAllSubjects(pagination: $pagination){
    status
    code
    message
    data{
      items{
        uid
        name
        code
        level
      }
      totalCount
    }
  }
}
`;

const GET_SUBJECTS_BY_QUALIFICATION = gql`
query GetAllSubjectsByQualificationMethod($inputs: SubjectQualificationMethodInput!) {
  getAllSubjectsByQualificationMethod(inputs: $inputs){
    status
    code
    message
    data{
      items{
        uid
        name
        code
        level
      }
      totalCount
    }
  }
}
`;

export { GET_SUBJECTS, GET_SUBJECTS_BY_QUALIFICATION }