import { gql } from '@apollo/client';


const CREATE_DISTRICT = gql`
mutation RegisterDistrict($inputs: [DistrictDtoInput!]!) {
  registerDistricts (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items{
        id
        uid
        name
        code
        region {
          id
          uid
          code
          name
        }
     }
    }
  }
}
`


const DELETE_DISTRICT = gql`
  mutation removeDistrict($uid: String!) {
    removeDistrict(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_DISTRICT, DELETE_DISTRICT }