import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_AWARDS } from "../Mutations/AwardsMutations"
import { GET_AWARDS } from "../Queries/AwardsQueries"
import { AwardsContext } from "./Awards"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

const AwardsModal = () => {
  const { updateAwardsData, setUpdateAwardsData, staffUid } =
    useContext(AwardsContext)

  const AwardsBoard = [
    {
      label: "Higher Education Students' Loans Board (HESLB)",
      value: 10,
    },
    {
      label: "Public Service Recruitment Secretariat (PSRS)",
      value: 4,
    },
    {
      label: "Researchers Academician And Alied Workers Union (RAAWU)",
      value: 6,
    },
    {
      label: "Sokoine University of Agriculture (SUA)",
      value: 8,
    },
    {
      label: "Tanzania Commission for Universities (TCU)",
      value: 2,
    },
    {
      label: "Tanzania Higher Learning Institutions Trade Union (THTU)",
      value: 8,
    },
    {
      label: "Tanzania Ministry of Education, Science, and Technology",
      value: 5,
    },
    {
      label: "Tanzania Scholarship Fund (TSF)",
      value: 1,
    },
    {
      label: "Tanzania Scholarship Fund (TSF)",
      value: 3,
    },
    {
      label: "WIZARA YA ELIMU, SAYANSI NA TEKNOLOJIA",
      value: 9,
    },
  ]

  const [Awards] = useMutation(ADD_UPDATE_AWARDS)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_AWARDS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: updateAwardsData ? updateAwardsData.name : "",
      description: updateAwardsData ? updateAwardsData.description : "",
      awardYear: updateAwardsData ? updateAwardsData.awardYear : "",
      staffUid: updateAwardsData ? updateAwardsData.staffUid : staffUid,
      awardUid: updateAwardsData ? updateAwardsData.uid : "",
      awardingBoardId: updateAwardsData ? updateAwardsData.awardingBoardId : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Award Name is Required"),
      description: Yup.string().required("Award Description is required"),
      awardYear: Yup.string().required("Award Year is required"),
      awardingBoardId: Yup.string().required("Award Board is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      if (values.awardUid === "") {
        delete values.awardUid
      }

      Awards({
        client: asimsGraphQLClient,
        variables: {
          inputs: {
            ...values,
            staffUid: staffUid,
          },
        },
        refetchQueries: [
          {
            query: GET_AWARDS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerAwards }) => {
          console.log("register Awards", registerAwards)
          validation.resetForm()
          setModalOpen(false)
          setUpdateAwardsData(false) // Close the modal
          if (registerAwards.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateAwardsData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerAwards.message,
            registerAwards.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error.message)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Awards
      </Button>

      <Modal
        isOpen={modalOpen || !!updateAwardsData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateAwardsData ? "Edit Awards" : "Add Awards"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateAwardsData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <h5 className="form-details-header">
                Award Name and Description
              </h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="name" className="col-form-label">
                      Award Name:
                    </label>
                    <Input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Enter Award Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.name || updateAwardsData?.name || ""
                      }
                      invalid={
                        !!(validation.touched.name && validation.errors.name)
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="description" className="col-form-label">
                      Award Description:
                    </label>
                    <textarea
                      name="description"
                      className="form-control"
                      id="description"
                      placeholder="Enter Award Description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                      onInvalid={
                        !!(
                          validation.touched.description &&
                          validation.errors.description
                        )
                      }
                    />
                    {validation.touched.description &&
                    validation.errors.description ? (
                      <FormFeedback type="invalid">
                        {validation.errors.description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Awarding Board and Year</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="awardingBoardId" className="col-form-label">
                      Awarding Board:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("awardingBoardId", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={updateAwardsData?.awardingBoard?.id}
                      defaultInputValue={updateAwardsData?.awardingBoard?.name}
                      options={AwardsBoard}
                      className="select2-selection"
                    />
                    <Input
                      name="awardingBoardId"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.awardingBoardId || ""}
                      invalid={
                        !!(
                          validation.touched.awardingBoardId &&
                          validation.errors.awardingBoardId
                        )
                      }
                    />
                    {validation.touched.awardingBoardId &&
                    validation.errors.awardingBoardId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.awardingBoardId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="awardYear" className="col-form-label">
                      Award Year:
                    </label>
                    <Input
                      type="number"
                      name="awardYear"
                      className="form-control"
                      id="awardYear"
                      placeholder="Enter Award Year"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.awardYear || ""}
                      invalid={
                        !!(
                          validation.touched.awardYear &&
                          validation.errors.awardYear
                        )
                      }
                    />
                    {validation.touched.awardYear &&
                    validation.errors.awardYear ? (
                      <FormFeedback type="invalid">
                        {validation.errors.awardYear}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateAwardsData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default AwardsModal
