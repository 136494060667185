//REGISTE
import { gql } from '@apollo/client';
import { forwardStatus } from 'helpers/ForwardStatus';


const GET_COURSE_ALLOCATION = gql`
query GetCourseAllocations($pagination: PaginationInput!) {
  getCourseAllocations (pagination: $pagination){
    status
    code
    message
    data{
      items {
        uid
        staffUid
        programCourse {
          uid
          course {
            uid
            code
            name
          }
          programSemester {
            uid
            program {
              uid
              code
              name
            }
            academicYear {
              uid
              name
            }
            studyYear
            semester
          }
        }
      }
      totalCount
    }
  }
}
`;

const GET_COURSE_ALLOCATION_BY_COURSE = gql`
query GetCourseAllocationByCourse ( $programCourseUid : String! ) {
  getCourseAllocationByProgramCourseUid (programCourseUid : $programCourseUid) {
    status
    code
    message
    data {
      totalCount
      items {
        uid
        staffUid
				
        programCourse {
          uid
          programSemester {
            uid
            program {
              uid
              code
              name
            }
            academicYear {
              uid
              name
            }
            studyYear
            semester
          }
          course {
            uid
            code
            name
          }
          
        }
      }
    }
  }
}
`;


const GET_STAFF_UID_COURSE_ALLOCATION_BY_PROGRAM_COURSE_UID = gql`
query GetCourseAllocationByCourse ( $programCourseUid : String! ) {
  getCourseAllocationByProgramCourseUid (programCourseUid : $programCourseUid) {
    status
    code
    message
    data {
      totalCount
      items {
        uid
        staffUid
      }
    }
  }
}
`;

export { GET_COURSE_ALLOCATION, GET_COURSE_ALLOCATION_BY_COURSE,GET_STAFF_UID_COURSE_ALLOCATION_BY_PROGRAM_COURSE_UID }