import { gql } from "@apollo/client"

const CREATE_GRADE_POINT= gql`
  mutation RegisterGradePoints($inputs: GradePointInput!) {
    registerGradePoint(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          uid
          grade
          gradePoint
       }
      }
    }
  }
`

const DELETE_GRADE_POINT= gql`
  mutation RemoveGradePoint($uid: String!) {
    removeGradePoint(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_GRADE_POINT, DELETE_GRADE_POINT}
