import { gql } from "@apollo/client"

const DELETE_EMPLOYMENT_HISTORY = gql`
  mutation deleteEmploymentHistory($uid: String!, $deletedBy: Int!) {
    deleteEmploymentHistory(uid: $uid, deletedBy: $deletedBy) {
      status
      code
      message
      data {
        uid
        id
        staffUid
        position
        departmentName
        institutionName
        institutionType {
          uid
          id
          name
          code
        }
        startDate
        endDate
        responsibilities
        city
        country
      }
    }
  }
`

const ADD_UPDATE_EMPLOYMENT_HISTORY = gql`
  mutation AddUpdateEmploymentHistory($input: EmploymentHistoryInput!) {
    addUpdateEmploymentHistory(input: $input) {
      status
      message
      code
      data {
        uid
        id
        staffUid
        position
        departmentName
        institutionName
        institutionType {
          uid
          id
          name
          code
        }
        startDate
        endDate
        responsibilities
        city
        country
      }
    }
  }
`

export { DELETE_EMPLOYMENT_HISTORY, ADD_UPDATE_EMPLOYMENT_HISTORY }
