//REGISTE
import { gql } from '@apollo/client';
const STUDENT_REGISTER_COURSE = gql`
  mutation RegisterStudentCourse($inputs: [CourseRegistrationInputNode!]!,$remove: [String!]!) {
    registerStudentCourse(inputs: $inputs,remove: $remove) {
        status
        code
        message
    }
  }
`
const STUDENT_REGISTER_EXAMINATION = gql`
  mutation RegisterStudentExam($inputs: [ExamRegistrationInput!]!) {
    registerStudentExam(inputs: $inputs) {
        status
        code
        message
    }
  }
`

export { STUDENT_REGISTER_COURSE,STUDENT_REGISTER_EXAMINATION }