import { gql } from "@apollo/client"

const CREATE_BANK = gql`
  mutation RegisterBank($inputs: [BankDtoInput!]!) {
    registerBanks(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
          code
       }
      }
    }
  }
`

const DELETE_BANK = gql`
  mutation RemoveBank($uid: String!) {
    removeBank(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_BANK, DELETE_BANK }
