import React, { createContext, useEffect, useState } from "react";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Link} from "react-router-dom";
import { 
  Row, 
  Col, 
  Card,
  CardBody,
  CardSubtitle,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import {useNavigate, useLocation} from "react-router-dom";
import { GET_PROGRAM_SEMISTERS } from "../Queries/ProgramSemisterQueries"
import { DELETE_PROGRAM_SEMISTER } from "../Mutations/ProgramSemisterMutations"

import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {
  registrationGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import ProgramSemisterModal from "./ProgramSemisterModal"
// import ProgramCoursesModal from "./ProgramCoursesModal"
import ProgramActionHelper from "helpers/ProgramHelper"
import { jwtDecode } from "jwt-decode"

export const ProgramSemisterContext = createContext() //Create data to transfer to child modal

const breadcrumbItem = [
  {
    title: "Programs List",
    path: "/programs",
    isActive: false,
  },
]


const ProgramSemisterList = () => {
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  const navigate = useNavigate();
    const location = useLocation();

    if(!location.state) navigate('/examination/programs')
    if(!location.state?.program) navigate('/examination/programs')
    let { program } = location.state;

    const searchValues = {
      search: "",
    }
    const searchSummarySchema = Yup.object().shape({
      search: Yup.string().required(""),
    })
  
    const formikSearch = useFormik({
      initialValues: searchValues,
      validationSchema: searchSummarySchema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        console.log("Requesting Page no ")
      },
    })
    
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(limit)
    const pagination = { offset: currentPage, limit: limit, search: null }  
    const [selectedProgramSemister, setSelectedProgramSemister] = useState(null) //Get data for deleting
    const [updateProgramSemisterData, setUpdateProgramSemisterData] = useState(null) //Get data for editing
    const [programSemesters, setProgramSemesters] = useState(null) //Get data for editing
    const [updateProgramCoursesData, setUpdateProgramCoursesData] = useState(null) //Get data for editing
    const [programSemesterUid, setProgramSemesterUid] = useState(null) 
    
    const { loading, error, data, refetch } = useQuery(GET_PROGRAM_SEMISTERS, {
      client: registrationGraphQLClient,
      variables: {
        pagination,programUid:program.uid
      },
      fetchPolicy: "no-cache",
    })
  
    const [
      searchProgramSemister,
      { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_PROGRAM_SEMISTERS, {
      client: registrationGraphQLClient,
    })

    // useEffect(() => {
    //   if (!loading && data) {
    //     console.log("DataReceived", data)
    //   }
    // }, [loading, data])
  
    const handleClick = selectedPage => {
      const page = selectedPage.selected
      // fetchData(page);
      setCurrentPage(selectedPage.selected)
    }
  
    const [deleteProgramSemister] = useMutation(DELETE_PROGRAM_SEMISTER, {
      client: registrationGraphQLClient,
      variables: { uid: selectedProgramSemister?.uid },
      refetchQueries: [
        {
          query: GET_PROGRAM_SEMISTERS,
          variables: {
            pagination: paginationHelper,
          },
        },
      ],
    })
  
    const getProgramCourses = (semester) => {
      // navigate("/students/view",{state:{student:std}})
      setProgramSemesters(semester)
      navigate("/examination/programs/program-semester/view",{state:{programSemester:semester}})
    }
  
    const handleDeleteProgramSemister = (semester) => {
      setSelectedProgramSemister(semester)
      showDeleteWarning(() => {
        performProgramSemisterDeletion()
      })
    }
  
    const performProgramSemisterDeletion = async () => {
      try {
        const response = await deleteProgramSemister()
        showToast(response.data.removeProgramSemester.message, response.data.removeProgramSemester.code === 8000 ? "success" : "error")
        console.log("User deleted successfully:", response)
      } catch (error) {
        console.error("Error deleting user:", error)
      }
    }
  
    if (loading) {
      return <PlaceHolderLoader rows={4} type="table" columSize={12} />
    }
    if (error || searchError) {
      return showToast(error || searchError, "error")
    }
  
    const fetchData = (page = 0) => {
      setCurrentPage(page)
      let data = {
        search: formikSearch.values.search,
      }
      // Update the search query variables and refetch the data
      searchProgramSemister({
        variables: { pagination: { ...pagination, ...data } },
      })
    }
  
    const searchQuery = () => {
      const value = formikSearch.values.search
      if (value.length >= 3) {
        // setFetching(true);
        fetchData()
      } else if (value?.length === 0) { //refetch data after clearing search value
        clearSearch();
        fetchData(); //update hook state
      }
    }
  
    const clearSearch = () => { //Function to clear search from formik
      formikSearch.setFieldValue("search", "");
    }
  
    const ProgramSemister = data?.getProgramSemesters.data?.items
    const searchedProgramSemister = searchData?.getProgramSemesters?.data?.items
    const result = searchedProgramSemister ? searchedProgramSemister  : ProgramSemister
    const startIndex = currentPage * pageSize
    const endIndex = (currentPage + 1) * pageSize
    const totalEntries = data?.getProgramSemesters.data?.totalCount || searchData?.getProgramSemesters?.data?.items || 0

    return(
      <ProgramSemisterContext.Provider
          value={{ updateProgramSemisterData, 
            setUpdateProgramSemisterData,
            updateProgramCoursesData,
            setUpdateProgramCoursesData,
            setProgramSemesters,
          }}
      >
        <LayoutHelper
          breadcrumbItem={breadcrumbItem}
          pageTitle="Programme Semester List"
        >
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-1">
                    <Row>
                      <Col className="col-lg-6 col-md-6 col-sm-6">                      
                        <form className="app-search d-none d-lg-block">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control"
                              onKeyUp={searchQuery}
                              placeholder="Search..."
                              {...formikSearch.getFieldProps("search")}
                            />
                          </div>
                        </form>
                      </Col>
                      <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                        <ProgramSemisterModal  program={program}/>
                        {/* <ProgramCoursesModal /> */}
                      </Col>
                
                    </Row>
                  </CardSubtitle>
                  <CardBody>
                  <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1">{program.name}</h5>
                                <div className="flex-shrink-0">
                                </div>
                            </div>
                  </CardBody>
                  {!loading && !error && !searchError && (
                    <div className="table-rep-plugin">
                      <div
                        className="mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table-sm table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th data-priority="1">S/N</Th>
                              <Th>Program Name</Th>
                              <Th>Academic Year</Th>
                              <Th>Semester</Th>
                              <Th data-priority="1">Core Credits</Th>
                              <Th>Elective Credits</Th>
                              <Th data-priority="3">Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {result?.slice(startIndex, endIndex)
                              .map((programSemister, index) => {
                              if (programSemister?.program?.uid===program?.uid) {
                              return (
                                <Tr key={programSemister?.uid}>
                                  <Td>{startIndex + index + 1}</Td>
                                  <Td>{programSemister?.program?.name}</Td>
                                  <Td>{programSemister?.academicYear?.name} [{programSemister?.academicYear?.status===1 ? "Current" : "Not Current" }]</Td>
                                  <Td>{programSemister?.semester}</Td>
                                  <Td>{programSemister?.coreCredits}</Td>
                                  <Td>{programSemister?.electiveCredits}</Td>
                                  <Td>
                                      <ProgramActionHelper
                                        data={programSemister}
                                        onUpdate={hasAuthority("REMOVE_PROGRAM_SEMESTER") && setUpdateProgramSemisterData}
                                        onDelete={hasAuthority("REMOVE_PROGRAM_SEMESTER") && handleDeleteProgramSemister}
                                        onCourses={getProgramCourses}
                                      />
                                  </Td>
                                </Tr>
                              )
                              }
                            }
                              )}
                          </Tbody>
                        </Table>
                      </div>

                      <Row>
                        <Col className="col-lg-6 fw-bold text-gray-700">
                          Showing {startIndex + 1} to{" "}
                          {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                          entries
                        </Col>
                        <Col
                          className="col-lg-6 pull-right"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <ReactPaginate
                            onPageChange={handleClick}
                            breakLabel={"--"}
                            pageCount={Math.ceil(totalEntries / pageSize)}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            nextLinkClassName={"page-link"}
                            previousClassName={"page-link"}
                            previousLabel={"<"}
                            nextLabel={">"}
                            activeClassName={"active"}
                            breakLinkClassName={"page-link"}
                            initialPage={currentPage}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </LayoutHelper>
        </ProgramSemisterContext.Provider>
    );
}

export default ProgramSemisterList;