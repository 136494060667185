// export default RoomAllocationList;
import React, { createContext, useEffect, useState } from "react"
import {Row, Col} from "reactstrap"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { GET_STUDENT_REQUESTS_AND_ALLOCATIONS } from "../Queries/AccommodationQueries"
import { accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"
import AccBoardingModal from "./AccBoardingModal";
import DataTable, { SEARCH_CONTEXTS } from "helpers/DataTable";
import AccChangeRoomModal from "./AccChangeRoomModal"
import AccAllocateRoomModal from "./AccAllocateRoomModal"
import AccommodationModal from "./AccommodationModal";

const breadcrumbItem = [
  {
    title: "Allocation & Boarding",
    path: "",
    isActive: true,
  },
]

export const AccommodationListContext = createContext() //Create data to transfer to child modal

const AccommodationList = () => {

  const getSuperscript = (number) => {
    switch (number % 10){
      case 1: 
        return "st"
      case 2: 
        return "nd"
      case 3: 
        return "rd"
      default:
        return "th"
    }
  }

  const HTMLSuperScriptNumber = ({ number }) => (
    <span dangerouslySetInnerHTML={{ __html: number + "<sup>" + getSuperscript(number) + "</sup>" }} />
  );

  const getExpiryColor = (hoursDiff) => {
    if(hoursDiff > 24)
      return "secondary"
    if(hoursDiff > 12)
      return "warning"
    
    return "danger"
  }

  const getExpiresAt = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthYear = `${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  
    const now = new Date();
    const timeDiffInMilliseconds = date - now;
    const hoursDiff = timeDiffInMilliseconds / (1000 * 60 * 60);
    const color = getExpiryColor(hoursDiff);
  
    return (
      <small className={`text-${color}`}>
        Expires at: <HTMLSuperScriptNumber number={day}/> {monthYear}
        {
          hoursDiff < 12 ?
            hoursDiff > 6 ?
              <i className={`bx bxs-bell bx-xs bx-tada ms-2 text-${color}`}></i>
            : <i className={`bx bxs-bell bx-xs bx-flashing ms-2 text-${color}`}></i>
          : ""
        }
      </small>
    )
  }
  

  const getBoardingDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const monthYear = `${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`;
  
    const now = new Date
    const timeDiffInMilliseconds = date - now;
    const hoursDiff = timeDiffInMilliseconds / (1000 * 60 * 60);
    const color = getExpiryColor(hoursDiff)

    return (<span><HTMLSuperScriptNumber number={day}/> {monthYear}</span>)
  }

  const [selectedRequest, setSelectedRequest] = useState(null)
  const [isBoardingIn, setIsBoardingIn] = useState(false)

  const [openChangeRoomModal, setOpenChangeRoomModal] = useState(false)
  const [openCancelRoomModal, setOpenCancelRoomModal] = useState(false)
  const [openAllocateModal, setOpenAllocateModal] = useState(false)
  const [openBoardingModal, setOpenBoardingModal] = useState(false)

  const requestStatuses = {
    'PENDING': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Pending',
      color: 'warning',
      message: 'Waiting for room allocation'
    }, 'SELECTED': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Selected',
      color: 'success',
      message: 'Should Request Fee & Control number to pay'
    }, 'NOT_SELECTED': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Not Selected',
      color: 'danger',
      message: null
    }, 'CANCELED': {
      icon: 'mdi mdi-autorenew',
      title: 'Request Canceled',
      color: 'danger',
      message: null
    },
  }

  // const pagination = { offset: 0, limit: 10, search: null }
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [searchContext, setSearchContext] = useState(SEARCH_CONTEXTS.UAA)

  const callFetchStudentRequests = () => {
    fetchStudentRequests({
      variables: {
        pagination: {
          offset: offset,
          limit: limit,
          search: search,
          context: searchContext
        }
      }
    })
  }

  const [fetchStudentRequests, { loading: requestLoading, data }] = useLazyQuery(GET_STUDENT_REQUESTS_AND_ALLOCATIONS, {
    client: accomodationGraphQLClient,
    fetchPolicy: 'network-only',//'cache-first',
    onCompleted: (results) => {
      const requestsData = results.getCurrentRoomRequests?.data?.items
      const totalCount = results.getCurrentRoomRequests?.data?.totalCount

      if(!!requestsData?.length){
        setRequestsAllocations(requestsData)
        setTotalRequests(totalCount)
      }
    },
    onError: (error) => {

    }
  });

  const changeRoom = (request) => {
    setSelectedRequest(request)
    setOpenChangeRoomModal(true)
  }

  const cancelRoom = (request) => {
    setSelectedRequest(request)
    setOpenCancelRoomModal(true)
  }


  const allocateRoom = (request) => {
    setSelectedRequest(request)
    setOpenAllocateModal(true)
  }

  const studentBoading = (request, isIn) => {
    setSelectedRequest(request)
    setIsBoardingIn(isIn)
    setOpenBoardingModal(true)
  }

  const columns = [
    { 
      key: 'student', 
      label: 'Student Details' , 
      renderer: (request, index) => (
        <span>
          {request.student?.fullName}<br/>
          <span className="text-secondary">
              {request.student?.registrationNumber}&nbsp;
            | <a href={"tel:"+request.student?.phoneNumber}>{request.student?.phoneNumber} </a>
          </span>
        </span>
      )
    },
    { 
      key: 'request', 
      label: 'Request' , 
      renderer: (request, index) => (
        <span>
          <span className={"text-"+requestStatuses[request.status]?.color}>{requestStatuses[request.status]?.title}</span>
          <br/>
          {!!request.reason? 
            (<small className="text-secondary">Reason: {request.reason}</small>) 
            : 
            (request.roomAllocation && request.roomAllocation?.isPaid?
              <small className="text-secondary">Request completed successfully</small>
              : !!request.roomAllocation && !!request.roomAllocation.expiresAt?
                  <small className="text-success">Control number received. waiting payment.</small>
                : <small className="text-secondary">{requestStatuses[request.status]?.message}</small>
            )
          }
          <br/>
          {
            !!request.roomAllocation?.expiresAt && !request.roomAllocation?.isPaid?
            getExpiresAt(request.roomAllocation?.expiresAt)
            : ""
          }
        </span>
      )
    },
    { 
      key: 'allocation', 
      label: 'Allocation',
      renderer: (request, index) => (
        !!request.roomAllocation?
          <span>
            <span className="text-primary">Room: {request.roomAllocation?.roomCapacity.room.code}&nbsp;
            {
              request?.roomAllocation?.roomCapacity.room.floorNumber == 0?
              "Ground Floor,"
              : <span><HTMLSuperScriptNumber number={request?.roomAllocation?.roomCapacity.room.floorNumber}/> Floor,</span>
            }&nbsp; 
            Block {request?.roomAllocation?.roomCapacity.room.block.code}</span><br/>
            Hostel: {request?.roomAllocation?.roomCapacity.room.block.hostel.name}<br/>
            <span className="text-secondary">
              {
                request?.roomAllocation?.roomCapacity.room.block.hostel.campuses?.length?
                  request?.roomAllocation?.roomCapacity.room.block.hostel.campuses.join(", ")+" | "
                : request?.roomAllocation?.roomCapacity.room.block.hostel.programs?.length?
                    request?.roomAllocation?.roomCapacity.room.block.hostel.programs.join(", ") +" | "
                  : ""
              }
              {request?.roomAllocation?.roomCapacity.programCategory}&nbsp;|&nbsp;
              {request?.roomAllocation?.roomCapacity.gender}&nbsp;|&nbsp;
              {request?.roomAllocation?.roomCapacity.capacity} students
            </span>
          </span>
        : <small className="btn btn-sm btn-rounded btn-outline-secondary">Not Allocated</small>
      )
    },
    { 
      key: 'payment-details', 
      label: 'Payments Details',
      renderer: (request, index) => (
        request.roomAllocation && request.roomAllocation?.isPaid?
          <small className="btn btn-sm btn-rounded btn-primary"><i className="fas fa-check"> </i> Paid initial amount</small>
        : "---"
      )
    },
    { 
      key: 'boarding', 
      label: 'Boarding Details',
      renderer: (request, index) => (
        request.roomAllocation?.boardings && request.roomAllocation?.boardings?.length?
          request.roomAllocation.boardings.map((boarding, index) => (
            <span key={index} className="">
              {boarding.isIn? "Boarded In on: " : "Boarded out on: "} 
              <strong>{getBoardingDate(boarding.boardingDate)}</strong>
              <br/>
              {
                boarding.items?.length?
                  <span>With: <strong>{boarding.items.map((item) => item.name).join(", ")}</strong></span>
                : ""
              }
              {
                request.roomAllocation.boardings.length > (index + 1)?
                  <span><br/>--------------<br/></span>
                : ""
              }
            </span>
          ))
        : "---"
      )
    }
  ];

  const actions = [
    {
      icon:"fas fa-person-booth", name:"Allocate", color: "primary", 
      isShowing: (request)=>(!request.roomAllocation && request.status === "PENDING"), 
      callback: (request)=>allocateRoom(request)
    },
    {
      icon:"bx bx-block", name:"Reject Request", color: "danger", 
      isShowing: (request)=>(false), 
      callback: (request)=>alert("Reject clicked")
    },
    {
      icon:"bx bx-reset", name:"Change Room", color: "warning", 
      isShowing: (request)=>(!!request.roomAllocation && !request.roomAllocation?.boardings?.length), 
      callback: (request)=>changeRoom(request)
    },
    {
      icon:"bx bx-block", name:"Cancel Allocation", color: "danger",
      // isShowing: (request)=>(request.status === 'SELECTED'),
      isShowing: (request)=>(true),
      callback: (request)=>cancelRoom(request)
    },
    {
      icon:"bx bx-log-in", name:"Board In", color: "primary", 
      isShowing: (request)=>(
        // !!request?.roomAllocation && request.roomAllocation.isPaid && !request?.roomAllocation?.boardings?.length
        !!request?.roomAllocation && !request?.roomAllocation?.boardings?.length
      ), 
      callback: (request)=>studentBoading(request, true)
    },
    {
      icon:"bx bx-log-out", name:"Board Out", color: "warning", 
      isShowing: (request)=>(!!request?.roomAllocation?.boardings?.length), 
      callback: (request)=>studentBoading(request, false)
    }
  ]

  // console.log(openCancelRoomModal)
  return (
    <AccommodationListContext.Provider
      value={{
        isBoardingIn,
        selectedRequest,
        setSelectedRequest,
        setOpenCancelRoomModal,
        openCancelRoomModal,
        openChangeRoomModal,
        setOpenChangeRoomModal,
        openAllocateModal,
        setOpenAllocateModal,
        openBoardingModal,
        setOpenBoardingModal,
        callFetchStudentRequests
      }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Allocation & Boarding">
        <Row>
          <Col>
            <DataTable
              columns={columns}
              data={data ? data?.getCurrentRoomRequests?.data?.items : []}
              totalEntries={data ? data?.getCurrentRoomRequests?.data?.totalCount : 0}
              totalResults={data ? data?.getCurrentRoomRequests?.data?.searchCount : 0}
              fetchData={fetchStudentRequests}
              useQueryFunction={true}
              pageLimit={limit}
              currentPage={offset}
              searchText={search}
              searchContext={searchContext}
              setCurrentPage={setOffset}
              setSearchText={setSearch}
              setSearchContext={setSearchContext}
              searchContexts={[
                {label: "Student",context: SEARCH_CONTEXTS.UAA},
                {label: "Room", context: SEARCH_CONTEXTS.SELF}
              ]}
              actions={actions}
              isLoading={requestLoading}
              isError={!requestLoading && !data?.getCurrentRoomRequests?.data?.searchCount}
              loadingComponent={<PlaceHolderLoader rows={3} type="table" columSize={12} />}
              errorComponent={
                <div className="text-center mt-5 mb-5">
                  No request or allocation found! 
                  <a className="card-link text-primary ms-2"
                      onClick={(e) => {
                        e.preventDefault(); 
                        fetchStudentRequests()
                      }}
                  >Try again</a>
                </div>
              }
            />
            <AccChangeRoomModal/>
            <AccAllocateRoomModal/>
            <AccBoardingModal/>
            <AccommodationModal/>
          </Col>
        </Row>
      </LayoutHelper>
    </AccommodationListContext.Provider>
  )
}

export default AccommodationList
