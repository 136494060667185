import { gql } from "@apollo/client"

const GET_MEDIA_COVERAGES = gql`
  query getStaffMediaCoverages($pagination: PaginationInput!) {
    getStaffMediaCoverages(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          uid
          startDate
          mediaName
          link
          endDate
          coverageLevel
          audienceType
        }
      }
    }
  }
`

export { GET_MEDIA_COVERAGES }
