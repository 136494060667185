import { gql } from "apollo-boost"

const FORWARD_PROGRAM_RESULT_BY_SENATE = gql`
  mutation PublishExamResultByProgramSemesterUid($programSemesterUids: [String!]!) {
    publishExamResultByProgramSemesterUid(programSemesterUids: $programSemesterUids) {
      status
      code
      data
      message
    }
  }
`

export { FORWARD_PROGRAM_RESULT_BY_SENATE }
