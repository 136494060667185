import { gql } from "apollo-boost"

const GET_PROGRAM_SEMESTER_FORWARD_STATUS = gql`
  query GetProgramSemesterForwardStatus(
    $academicYearUid: String!
    $semester: Int!
    $forwardStatus: Int!
  ) {
    getProgramSemesterForwardStatus(
      academicYearUid: $academicYearUid
      semester: $semester
      forwardStatus: $forwardStatus
    ) {
      status
      code
      message
      data {
        studyYear
        programSemesterUid
        programName
        programCode
        forwardStatus
        remark
      }
    }
  }
`

export { GET_PROGRAM_SEMESTER_FORWARD_STATUS }
