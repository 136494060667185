import { gql } from '@apollo/client';

const GET_STUDENTS = gql`
  query GetStudents($pagination: PaginationInput!) {
    getStudents(pagination: $pagination){
        status
        code
        message
        data{
            items{
                uid
                registrationNumber
                fullName
                gender
                phoneNumber
                status
                isActive
            }
            totalCount
            searchCount
        }
    }
  }
`

const GET_STUDENT_BY_UID = gql`
  query GetStudent($uid: String!) {
    getStudent(uid: $uid){
        status
        code
        message
        data{
         uid
         accountNumber
          registrationNumber
          studyYear
          status{
            uid
            name
            code
          }
          bank{
            uid
            name
          }
         user{
            uid
            firstName
            lastName
            middleName
            email
            username
            gender
            phoneNumber
            dateOfBirth
            placeOfBirth
            physicalAddress
            isDisabled
            nin
            country{
              uid
              code
            }
            
             
          }
        }
    }
  }
`
export  {GET_STUDENTS,GET_STUDENT_BY_UID };