import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { StudentNextOfKinContext } from "./StudentNextOfKinDetails"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { GET_USER_NEXT_OF_KIN } from "Modules/Students/Queries/NextOfKin/NextOfkinQueries"
import { UPDATE_USER_NEXT_OF_KINS } from "Modules/Students/Mutations/UpdateStudentMutation"
import { useMutation, useQuery } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { GET_KINSHIP } from "Modules/Lookups/Kinship/Queries/KinshipQueries"
import { StudentProfileContext } from "../SelfRegistration"

const StudentNextOfKinModal = () => {
  const { updateNextOfKinData, updateStudentData, setUpdateNextOfKinData } =
    useContext(StudentNextOfKinContext)
  const [modalOpen, setModalOpen] = useState(false)

  const [nextOfKinMutation] = useMutation(UPDATE_USER_NEXT_OF_KINS, {
    client: uaaGraphQLClient,
  })
  
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const {
    loading: kinShipLoading,
    error: kinShipError,
    data: kinShipData,
  } = useQuery(GET_KINSHIP, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (catError) return showToast("Failed to fetch Program Categories", "error")
  let kinShipArray = kinShipData?.getKinships?.data?.items

  const formikValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: updateNextOfKinData ? updateNextOfKinData.fullName : "",
      phoneNumber: updateNextOfKinData ? updateNextOfKinData.phoneNumber : "",
      placeOfDomicile: updateNextOfKinData
        ? updateNextOfKinData.placeOfDomicile
        : "",
      email: updateNextOfKinData ? updateNextOfKinData.email : "",
      kinshipUid: updateNextOfKinData ? updateNextOfKinData.kinshipUid : "",
    },

    validationSchema: Yup.object().shape({
      fullName: Yup.string().required("Full name is required"),
      phoneNumber: Yup.string().required("phone Number is required"),
      placeOfDomicile: Yup.string().required("place Of Domicile is required"),
      email: Yup.string().required("email is required"),
      kinshipUid: Yup.string().nullable().required("kinShip is required"),
    }),

    onSubmit: values => {
      nextOfKinMutation({
        variables: {
          input: {
            ...values,
            userUid: updateStudentData?.uid,
            uid: updateNextOfKinData?.uid,
          },
        },
        refetchQueries: [
          {
            query: GET_USER_NEXT_OF_KIN,
            variables: {
              userUid: updateStudentData?.uid,
            },
          },
        ],

        onCompleted: ({ updateUserNextOfKins }) => {
          //updateFunction(client.cache, { data: { updateUserNextOfKins } });
          if (updateUserNextOfKins.code === 8000) {
            formikValidation.resetForm()
            setModalOpen(false)
            setUpdateNextOfKinData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateNextOfKinData(true) //keep the modal
          }
          showToast(
            updateUserNextOfKins.message,
            updateUserNextOfKins.code === 8000 ? "success" : "error"
          )
          formikValidation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Next of Kin
      </Button>
      <Modal
        isOpen={modalOpen || !!updateNextOfKinData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateNextOfKinData ? "Edit Next of Kin" : "Next of Kin"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateNextOfKinData(false)
              resetForm() // Reset the form
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formikValidation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Row>
                <Col md={6}>
                  <label htmlFor="fullName" className="col-form-label">
                    Full Name:
                  </label>
                  <Input
                    type="text"
                    name="fullName"
                    className="form-control"
                    id="fullName"
                    placeholder="Enter Name"
                    onChange={formikValidation.handleChange}
                    onBlur={formikValidation.handleBlur}
                    value={
                      formikValidation.values.fullName ||
                      updateNextOfKinData?.fullName ||
                      ""
                    }
                    invalid={
                      !!(
                        formikValidation.touched.fullName &&
                        formikValidation.errors.fullName
                      )
                    }
                  />
                  {formikValidation.touched.fullName &&
                  formikValidation.errors.fullName ? (
                    <FormFeedback type="invalid">
                      {formikValidation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6}>
                  <label>Kin Ship</label>
                  <Select
                    onChange={e => {
                      formikValidation.setFieldValue("kinshipUid", e.value)
                    }}
                    name="kinshipUid"
                    options={kinShipArray?.map(pr => ({
                      value: pr.uid,
                      label: pr.name,
                    }))}
                    defaultValue={updateNextOfKinData?.uid}
                    defaultInputValue={updateNextOfKinData?.name}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    type="hidden"
                    name="kinshipUid"
                    className="form-control"
                    id="kinshipUid"
                    onChange={formikValidation.handleChange}
                    onBlur={formikValidation.handleBlur}
                    invalid={
                      !!(
                        formikValidation.touched.kinshipUid && formikValidation.errors.kinshipUid
                      )
                    }
                    value={
                      formikValidation.values.kinshipUid ||
                      updateNextOfKinData?.kinshipUid ||
                      ""
                    }
                    />
                    {formikValidation.touched.kinshipUid && formikValidation.errors.kinshipUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.regiodUid}
                      </FormFeedback>
                    ) : null}
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col md={6}>
                  <label htmlFor="placeOfDomicile" className="col-form-label">
                    Place of Domicile:
                  </label>
                  <Input
                    type="text"
                    name="placeOfDomicile"
                    className="form-control"
                    id="placeOfDomicile"
                    placeholder="Place of Domicile"
                    onChange={formikValidation.handleChange}
                    onBlur={formikValidation.handleBlur}
                    value={
                      formikValidation.values.placeOfDomicile ||
                      updateNextOfKinData?.placeOfDomicile ||
                      ""
                    }
                  />
                </Col>
                <Col md={6}>
                  <label htmlFor="phoneNumber" className="col-form-label">
                    Phone:
                  </label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={formikValidation.handleChange}
                    onBlur={formikValidation.handleBlur}
                    value={
                      formikValidation.values.phoneNumber ||
                      updateNextOfKinData?.phoneNumber ||
                      ""
                    }
                    invalid={
                      !!(
                        formikValidation.touched.phoneNumber &&
                        formikValidation.errors.phoneNumber
                      )
                    }
                  />
                  {formikValidation.touched.phoneNumber &&
                  formikValidation.errors.phoneNumber ? (
                    <FormFeedback type="invalid">
                      {formikValidation.errors.phoneNumber}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>

            <div className="mb-3">
              <Row>
                <Col md={6}>
                  <label htmlFor="email" className="col-form-label">
                    Email:
                  </label>
                  <Input
                    type="text"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    onChange={formikValidation.handleChange}
                    onBlur={formikValidation.handleBlur}
                    value={
                      formikValidation.values.email ||
                      updateNextOfKinData?.email ||
                      ""
                    }
                    invalid={
                      !!(
                        formikValidation.touched.email &&
                        formikValidation.errors.email
                      )
                    }
                  />
                  {formikValidation.touched.email &&
                  formikValidation.errors.email ? (
                    <FormFeedback type="invalid">
                      {formikValidation.errors.email}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6}>
                  <Input
                    type="hidden"
                    name="userUid"
                    className="form-control"
                    id="userUid"
                    onChange={formikValidation.handleChange}
                    onBlur={formikValidation.handleBlur}
                    value={
                      formikValidation.values.userUid ||
                      updateNextOfKinData?.userUid ||
                      ""
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateNextOfKinData(false)
                formikValidation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={formikValidation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default StudentNextOfKinModal
