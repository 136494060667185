import { gql } from "@apollo/client"

const CREATE_EXAM_CATEGORY = gql`
mutation RegisterExamCategory ($inputs : [ExamCategoryInput!]!) {
  registerExamCategories (inputs:$inputs) {
     status
     code
     message
     data {
       uid
      code
       name
     }
   }  
}
`

const DELETE_EXAM_CATEGORY = gql`
mutation RemoveExamCategory($uid: String!) {
  removeExamCategories (uid: $uid) {
    message
    code
    status
  }
}
`
export { CREATE_EXAM_CATEGORY, DELETE_EXAM_CATEGORY }
