import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
} from "reactstrap"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"

import logo from "assets/images/logo.svg"
import sualogo from "../../assets/images/logo/sua.png"
import showToast from "helpers/ToastHelper"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "./ApolloClient"
import { VERIFY_OTP_MUTATION } from "./Mutations/LoginMutation"

const VerifyOtp = () => {
  const { userUid } = useParams() // Extract the token from the URL
  const location = useLocation();
  const { loginData } = location.state || {};

  const [maskedEmail, setMaskedEmail] = useState(""); // State to store the masked email
  const [maskedPhone, setMaskedPhone] = useState(""); // State to store the masked email


  useEffect(() => {
    // Extract the masked part of the email and set it to the state
    
  }, [userUid]);

  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    // Update the OTP digits in the state
    setOtpDigits((prevDigits) => {
      const updatedDigits = [...prevDigits];
      updatedDigits[index] = value;
      return updatedDigits;
    });
  };

  useEffect(() => {
    const maskedPart = loginData?.email.split("@")[0].substr(0, 4) + "****";
    setMaskedEmail(maskedPart + loginData?.email.substr(loginData?.email.indexOf("@")));

    const maskedPhonePart = loginData?.phoneNumber.split("@")[0].substr(0, 4) + "****";
    setMaskedPhone(maskedPhonePart + loginData?.phoneNumber.substr(loginData?.phoneNumber.indexOf("@")));

  }, [userUid])

  const [auth, { data, error }] = useMutation(VERIFY_OTP_MUTATION, {
    client: uaaGraphQLClient,
  })
  const navigate = useNavigate()
  // const [errorMesg, setErrorMsg] = useState(null);

  const handleSubmit = async () => {
    const otp = otpDigits.join("");

    if (otp.length !== 6) {
      showToast("Please Enter a 6-digit OTP", "error", "Invalid OTP");
      return;
    }
    try {

      const response = await auth({
        client: uaaGraphQLClient,
        variables: { input: { otp, userUid } },
      })
      const { data } = response
      if (data?.verifyUserOtp.code == 8000) {
        // Store the token or perform other actions with it
        localStorage.setItem("authUser", JSON.stringify(data?.verifyUserOtp))
        // Check if the user needs to change their password
        if (data?.verifyUserOtp.data.isVerified == false) {
          showToast("Please change your password before continuing.", "info")
          navigate("/users/change-password") // Redirect to the password change page
        } else {
          showToast("Verify OTP success", "success")
          navigate("/")
        }
      } else {
        showToast(data?.verifyUserOtp.message, "error", "VerifyOtp Failed")
      }
    } catch (err) {
      // Handle error response
      console.error("VerifyOtp Error:", err.message)
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    // validationSchema: Yup.object({
    //   otp: Yup.string().required("Please Enter Your OTP"),
    // }),

    onSubmit: handleSubmit,
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  border: "2px solid #d5e3d5",
                }}
              >
                <div
                  className="text-center"
                  style={{
                    backgroundColor: "#d5e3d5",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-2">
                        <img src={sualogo} height={100} alt="" className=" " />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <h2 style={{ fontFamily: "Roboto" }}>SUA-ESB</h2>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <div style={{ textAlign: "center" }}>
                      <h4>OTP Verification</h4>
                      <p>
                        Please enter the 6 digit code sent to{" "}
                        <span className="fw-semibold">{maskedPhone}</span> OR
                        via your email {maskedEmail}
                      </p>
                    </div>
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit();
                      }}
                    >
                      <Row>
                        {Array.from({ length: 6 }, (_, index) => (
                          <Col className="col-2" key={index}>
                            <div className="mb-3">
                              <Label
                                htmlFor={`digit${index + 1}-input`}
                                className="visually-hidden"
                              >
                                Digit {index + 1}
                              </Label>
                              <Input
                                type="text"
                                className="form-control form-control-lg text-center two-step"
                                maxLength="1"
                                data-value={index + 1}
                                id={`digit${index + 1}-input`}
                                value={otpDigits[index]}
                                onChange={(e) => handleInputChange(e, index)}
                                // onChange={e =>
                                //   handleOtpChange(index, e.target.value)
                                // }
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>

                      <div className="mt-3 d-grid">
                        <SubmitButtonHelper
                          type="primary"
                          name="Verify & Log In"
                          formik={validation}
                        />
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Back to login?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  © {new Date().getFullYear()} Sokoine University of
                  Agriculture. <br /> All rights reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default VerifyOtp
