import { gql } from "@apollo/client"

const DELETE_MEDIA_COVERAGES = gql`
  mutation removeStaffMediaCoverage($uid: String!) {
    removeStaffMediaCoverage(uid: $uid) {
      code
      data
      message
      status
    }
  }
`

const ADD_UPDATE_MEDIA_COVERAGE = gql`
  mutation registerStaffMediaCoverages($input: [StaffMediaCoverageInput!]!) {
    registerStaffMediaCoverages(inputs: $input) {
      code
      message
      status
      data {
        totalCount
        items {
          audienceType
          coverageLevel
          endDate
          link
          mediaName
          uid
          startDate
        }
      }
    }
  }
`

export { DELETE_MEDIA_COVERAGES, ADD_UPDATE_MEDIA_COVERAGE }
