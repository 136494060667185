import PropTypes from "prop-types"
import React, { useState } from "react"
import {
    Row,
    Col,
    Alert,
    Card,
    CardBody,
    Container,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

import sualogo from "../../assets/images/logo/sua.png"
import showToast from "helpers/ToastHelper"
import { uaaRestUrl } from "helpers/UrlHelper"
import SubmitButtonHelper from "../../helpers/SubmitButtonHelper";
import toastHelper from "helpers/ToastHelper";

const ForgetEmailPage = props => {
    const navigate = useNavigate()

    const [apiResponse, setApiResponse] = useState({
        success: false,
        error: null,
    })

    const handleSubmit = async e => {
        e.preventDefault()

    }

    const dispatch = useDispatch()

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            applicationUsername:"",
            applicationPassword:"",
            email: "",
            phoneNumber:""
        },
        validationSchema: Yup.object({
            applicationUsername: Yup.string().required("Please Enter Applicant Username"),
            applicationPassword: Yup.string().required("Please Enter Applicant Password"),
            email: Yup.string().email('Invalid email format').required("Please Enter Your Email"),
            phoneNumber: Yup.string().required("Please Enter Phone Number"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            fetch(uaaRestUrl + "/update/user-email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: values.email,username:values.applicationUsername,password:values.applicationPassword,phone_number:values.phoneNumber }),
            }).then(response => response.json()).then(data => {
                if(data.status){
                    navigate("/forgot-password")
                    showToast(
                        data.message,
                        data.code === 8000 ? "success" : "error"
                    )
                }else{
                    toastHelper(data.message,'error')
                }
                setSubmitting(false)
            })
                .catch((error) => {
                toastHelper('An error occurred while making the request.','error')
                console.error('Error submitting data:', error);
                setSubmitting(false)
            });


            // dispatch(userForgetPassword(values, props.history))
        },
    })

    const { forgetError, forgetSuccessMsg } = useSelector(state => ({
        forgetError: state.ForgetPassword.forgetError,
        forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    }))

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card
                                className="overflow-hidden"
                                style={{
                                    border: "2px solid #d5e3d5",
                                }}
                            >
                                <div
                                    className="text-center"
                                    style={{
                                        backgroundColor: "#d5e3d5",
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="text-primary p-2">
                                                <img src={sualogo} height={100} alt="" className=" " />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div>
                                                <h2 style={{ fontFamily: "Roboto" }}>SUA-ESB</h2>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        {forgetError && forgetError ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {forgetError}
                                            </Alert>
                                        ) : null}
                                        {forgetSuccessMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <Form
                                            className="form-horizontal mt-4"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Application Username</Label>
                                                <Input
                                                    name="applicationUsername"
                                                    className="form-control"
                                                    placeholder="Enter Application Username"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.applicationUsername || ""}
                                                    invalid={
                                                        validation.touched.applicationUsername &&
                                                        validation.errors.applicationUsername
                                                    }
                                                />
                                                {validation.touched.applicationUsername &&
                                                    validation.errors.applicationUsername && (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.applicationUsername}
                                                        </FormFeedback>
                                                    )}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label">Application Password</Label>
                                                <Input
                                                    name="applicationPassword"
                                                    value={validation.values.applicationPassword}
                                                    type="password"
                                                    placeholder="Enter Application Password"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={
                                                        validation.touched.applicationPassword &&
                                                        validation.errors.applicationPassword
                                                    }
                                                />
                                                {validation.touched.applicationPassword &&
                                                    validation.errors.applicationPassword && (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.applicationPassword}
                                                        </FormFeedback>
                                                    )}
                                            </div>


                                            <div className="mb-3">
                                                <Label className="form-label">New Email Address</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter New Email address"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email}
                                                    invalid={
                                                        !!(validation.touched.email && validation.errors.email)
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.email}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>


                                            <div className="mb-3">
                                                <Label className="form-label">New Phone Number</Label>
                                                <Input
                                                    name="phoneNumber"
                                                    className="form-control"
                                                    placeholder="Enter New Phone Number"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.phoneNumber}
                                                    invalid={
                                                        !!(validation.touched.phoneNumber && validation.errors.phoneNumber)
                                                    }
                                                />
                                                {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.phoneNumber}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-center">
                                                    <SubmitButtonHelper
                                                        type="primary"
                                                        name="Update Email"
                                                        formik={validation}
                                                    />
                                                </Col>

                                            </Row>
                                        </Form>
                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    <Link to="/login" className="font-weight-medium text-primary">
                                        Go back to Login
                                    </Link>
                                </p>
                                <p>
                                    © {new Date().getFullYear()} © Sokoine University of
                                    Agriculture. <br /> All rights reserved
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

ForgetEmailPage.propTypes = {
    history: PropTypes.object,
}

export default withRouter(ForgetEmailPage)
