//REGISTE
import { gql } from '@apollo/client';

const GET_PERMISSIONS = gql`
  query GetPermissions($pagination: PaginationInput!) {
    getPermissions(pagination: $pagination){
      status
      code
      message
      data{
        items{
        id
        uid
        name
        code
        description
        service
      }
      totalCount
      }
    }
  }
`;

export { GET_PERMISSIONS }