import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import classnames from "classnames"
import { Link } from "react-router-dom"

//Import Breadcrumb
import LayoutHelper from "helpers/LayoutHelper"

const breadcrumbItem = [
  {
    title: "Users",
    path: "/users",
    isActive: false,
  },
  {
    title: "Create",
    path: "",
    isActive: true,
  },
]

const UserForm = () => {
  //meta title
  document.title = "User Form | Create New User"

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  const [passedSteps, setPassedSteps] = useState([1])
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Create New User">
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setactiveTab(1)
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span> Basic Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setactiveTab(2)
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span> Some Detail 2
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          setactiveTab(3)
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number">3.</span> Some Detail Three
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => {
                          setactiveTab(4)
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">4.</span> Confirm Detail
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <Form>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">
                                First name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-firstname-input1"
                                placeholder="Enter Your First Name"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-lastname-input2">
                                Last name
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-lastname-input2"
                                placeholder="Enter Your Last Name"
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-phoneno-input3">
                                Phone
                              </Label>
                              <Input
                                type="text"
                                className="form-control"
                                id="basicpill-phoneno-input3"
                                placeholder="Enter Your Phone No."
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-email-input4">Email</Label>
                              <Input
                                type="email"
                                className="form-control"
                                id="basicpill-email-input4"
                                placeholder="Enter Your Email ID"
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <div className="mb-3">
                              <Label for="basicpill-address-input1">
                                Address
                              </Label>
                              <textarea
                                id="basicpill-address-input1"
                                className="form-control"
                                rows="2"
                                placeholder="Enter Your Address"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-pancard-input5">
                                  PAN Card
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-pancard-input5"
                                  placeholder="Enter Your PAN No."
                                />
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-vatno-input6">
                                  VAT/TIN No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-vatno-input6"
                                  placeholder="Enter Your VAT/TIN No."
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-cstno-input7">
                                  CST No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cstno-input7"
                                  placeholder="Enter Your CST No."
                                />
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  Service Tax No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-servicetax-input8"
                                  placeholder="Enter Your Service Tax No."
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-companyuin-input9">
                                  Company UIN
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-companyuin-input9"
                                  placeholder="Enter Your Company UIN"
                                />
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-declaration-input10">
                                  Declaration
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-Declaration-input10"
                                  placeholder="Declaration Details"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={3}>
                      <div>
                        <Form>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-namecard-input11">
                                  Name on Card
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-namecard-input11"
                                  placeholder="Enter Your Name on Card"
                                />
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label>Credit Card Type</Label>
                                <select className="form-select">
                                  <option defaultValue>Select Card Type</option>
                                  <option value="AE">American Express</option>
                                  <option value="VI">Visa</option>
                                  <option value="MC">MasterCard</option>
                                  <option value="DI">Discover</option>
                                </select>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-cardno-input12">
                                  Credit Card Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-cardno-input12"
                                  placeholder="Credit Card Number"
                                />
                              </div>
                            </Col>

                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-card-verification-input0">
                                  Card Verification Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-card-verification-input0"
                                  placeholder="Credit Verification Number"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-expiration-input13">
                                  Expiration Date
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="basicpill-expiration-input13"
                                  placeholder="Card Expiration Date"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tabId={4}>
                      <div className="row justify-content-center">
                        <Col lg="6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Confirm Detail</h5>
                              <p className="text-muted">
                                If several languages coalesce, the grammar of
                                the resulting
                              </p>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1)
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li className={activeTab === 4 ? "next disabled" : "next"}>
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab + 1)
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default UserForm
