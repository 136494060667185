import React, {createContext} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {useMutation, useQuery} from "@apollo/client";
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader";
import showToast from "../../../helpers/ToastHelper";
import {showWaringConf} from "../../../helpers/DeleteWaringHelper";
import { format } from 'date-fns';
import {NONE} from "draft-js/lib/SampleDraftInlineStyle";
import {limit} from "../../../helpers/UrlHelper";
import StudentManuScriptForm from "./Forms/StudentManuScriptForm";
import {GET_ALL_STUDENT_MANU_SCRIPTS, GET_ALL_STUDENT_MANUSCRIPT_BY_UID} from "../Queries/ManuScriptQueries";
import {
    CREATE_STUDENT_DISSERTATION_PAPER
} from "../../Students/Views/Dissertation/Mutations/StudentDissertationMutations";

const breadcrumbItem = [
    {
        title: "Manuscripts",
        path: "/manu-script",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]
const ViewStudentManuScript = () => {
    const location = useLocation();
    const navigate = useNavigate();
    if(!location.state?.paper) navigate('/dissertation')
    const { paper,student } = location.state
    const pagination = { offset: 0, limit: limit, search: null }
    const [submit] = useMutation(CREATE_STUDENT_DISSERTATION_PAPER)
    const { loading, error, data, refetch } = useQuery(GET_ALL_STUDENT_MANUSCRIPT_BY_UID, {
        client: registrationGraphQLClient,
        skip:!paper?.uid,
        variables: {
            uid:paper.uid
            // inputs:{
            //     studentUid:sem?.studentUid,
            //     seminarTypeUid:sem?.seminarTypes?.uid
            // },
        },
    })

    const manuscript = data?.getStudentManuscriptByUid?.data
    if (loading || !paper?.uid) {
        return <PlaceHolderLoader columSize={12} type={"rows"} rows={2} />
    }

    const handleApprove = (status) => {
        let type = manuscript?.status === 1 ? 'Seminar Date':'Seminar Marks Score'
        showWaringConf(() => {
                confirmSubmit(status)
            },status === 2 ? `Approve Manuscript Status` : 'Reject Manuscript',status === 2 ? `Are you sure you need to approve manuscript? Student will view submitted results`: `Are you sure you need to reject manuscript? Student will be able to update this manuscript`,status === 2 ? 'warning':'info',status === 2 ? 'Approve':'Reject',
            () => {
                // setPayType(null)
            })
    }

    const confirmSubmit = async (status) => {
        try {
            await submit(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs:{
                            status: status,
                            uid:manuscript?.uid,
                            title: manuscript?.title,
                            description: manuscript?.description,
                            studentUid:manuscript?.studentUid,
                            publicationDate:manuscript?.publicationDate,
                            publicationStatus:manuscript?.publicationStatus

                        }
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_ALL_STUDENT_MANUSCRIPT_BY_UID,
                            variables: {
                                uid:manuscript.uid
                            },
                        },
                        {
                            client: registrationGraphQLClient,
                            query: GET_ALL_STUDENT_MANU_SCRIPTS,
                            variables: {
                                pagination: pagination,
                            },
                        },
                    ],
                    onCompleted: ({registerStudentManuscript}) => {
                        if (registerStudentManuscript.code === 8000) {
                            // showToast(registerStudentSeminar.message, "success")
                        } else {
                            // showToast(
                            //     registerStudentSeminar.message,
                            //     registerStudentSeminar.code === 8000 ? "success" : "error"
                            // )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }

    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`View manusctript`} >
            {manuscript &&

                <>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center text-uppercase"><h3>Title: {manuscript.title}</h3></CardTitle>
                                    <CardSubtitle>
                                        <ul style={{listStyleType:"none",marginLeft:'-3%',fontSize:'120%'}}>
                                            <li>
                                                <strong>Student Name: </strong> <span className="badge bg-success"> {paper?.fullName}</span>
                                            </li>
                                            <li>
                                                <strong>Registration No: </strong> <span className="badge bg-success"> {paper?.registrationNumber}</span>
                                            </li>
                                            <li>
                                                <strong>Status:</strong> {manuscript.status === 0 && <span className="badge bg-danger"> Un submitted/Pending ...</span>}
                                                {manuscript.status === 1 && <span className="badge bg-danger"> Approval Pending..</span>}
                                                {manuscript.status === 2 && <span className="badge bg-success"> Approved</span>}
                                                {manuscript.status === 3 && <span className="badge bg-danger"> Rejected</span>}

                                            </li>

                                            <li>
                                                <strong>Publication Status: </strong> <span className="badge bg-success"> {manuscript?.publicationStatus}</span>
                                            </li>
                                            <li>
                                                <strong>Publication Date: </strong> <span className="badge bg-success"> {manuscript?.publicationDate ? format(new Date(manuscript?.publicationDate), 'MMMM dd, yyyy'):''}</span>
                                            </li>
                                        </ul>
                                    </CardSubtitle>
                                    <hr/>
                                    <div style={{
                                        maxHeight:'700px', overflow: 'auto'
                                    }} dangerouslySetInnerHTML={{ __html: manuscript.description }}/>
                                </CardBody>
                                <CardFooter>
                                    {manuscript?.status === 1 && <Button onClick={() => handleApprove(0)} className="btn btn-danger float-start">Reject Manuscript</Button>}
                                    {manuscript?.status === 1 && <Button onClick={() => handleApprove(2)} className="btn btn-success float-end">Approve Manuscript</Button>}
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
            {/*<CourseLeaningOutcomes course={course} />*/}
        </LayoutHelper>
    );
}

export default ViewStudentManuScript;