import { gql } from "@apollo/client"

const CREATE_PROGRAM_REQUIREMENT = gql`
  mutation RegisterProgramSubjectRequirement(
    $inputs: ProgramSubjectRequirementInput!
  ) {
    registerProgramSubjectRequirement(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items {
          uid
          mustPass
          entryCategoryCode
        }
      }
    }
  }
`

const DELETE_PROGRAM_REQUIREMENT = gql`
  mutation RemoveProgramSubjectRequirement($uid: String!) {
      removeProgramSubjectRequirement(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_PROGRAM_REQUIREMENT, DELETE_PROGRAM_REQUIREMENT }
