import { gql } from "@apollo/client"

const ADD_UPDATE_OTHER_EXPERIENCES = gql`
  mutation addUpdateOtherExperienceExtracurricular(
    $input: StaffOtherExperienceExtracurricularInput!
  ) {
    addUpdateOtherExperienceExtracurricular(input: $input) {
      status
      message
      code
      data {
        description
        id
        otherExperienceExtracurricularHobby
        staffUid
        uid
      }
    }
  }
`

const DELETE_OTHER_EXPERIENCES = gql`
  mutation deleteOtherExperience($deletedBy: Int!, $uid: String!) {
    deleteOtherExperience(deletedBy: $deletedBy, uid: $uid) {
      code
      data {
        uid
        staffUid
        otherExperienceExtracurricularHobby
        id
        description
      }
      message
      status
    }
  }
`

export { DELETE_OTHER_EXPERIENCES, ADD_UPDATE_OTHER_EXPERIENCES }
