import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

import sualogo from "../../assets/images/logo/sua.png"
import showToast from "helpers/ToastHelper"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaRestUrl } from "helpers/UrlHelper"

const ResetPassword = props => {
  
  const navigate = useNavigate();
  const { token } = props; // Get the passed token from props
  useEffect(() => {
    console.log(token);
  }, [token]);

  const [apiResponse, setApiResponse] = useState({
    success: false,
    error: null,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      // Make the API call using Fetch API
      const response = await fetch(
        uaaRestUrl + "/user/change-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ 
            token: token,
            email: validation.values.email,
            password: validation.values.confirmPassword
         }),
        }
      )

      const responseData = await response.json()

      if (response.ok) {
        // API call was successful
        if(responseData.code === 8000) {
            navigate('/');
        }
        showToast(
          responseData.message,
          responseData.code === 8000 ? "success" : "error"
        )
        setApiResponse({ success: true, error: null })
      } else {
        // API call returned an error
        console.log("ErrorOccured", responseData.message)
        setApiResponse({ success: false, error: responseData.message })
      }
    } catch (error) {
      console.log("ErrorOccured", error)

      // Error occurred while making the API call
      setApiResponse({
        success: false,
        error: "An error occurred while making the request.",
      })
    }
  }

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      newPassword: Yup.string()
        .required("New password is required")
        .matches(
          /^(?=.*[A-Z])(?=.*\d).{6,}$/,
          "Password must contain at least one uppercase letter, one number, and be at least 6 characters long"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history))
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  border: "2px solid #d5e3d5",
                }}
              >
                <div
                  className="text-center"
                  style={{
                    backgroundColor: "#d5e3d5",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-2">
                        <img src={sualogo} height={100} alt="" className=" " />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <h2 style={{ fontFamily: "Roboto" }}>SUA-ESB</h2>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={handleSubmit}
                      style={{ textAlign: "left" }}
                    >
                      <div className="mb-2" style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
                        Password Reset!
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" >Email Address</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="newPassword" className="col-form-label">
                          New Password:
                        </label>
                        <Input
                          type="password"
                          name="newPassword"
                          className="form-control"
                          id="firstoldPassword"
                          placeholder="Enter new password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.newPassword || ""}
                          invalid={
                            !!(
                              validation.touched.newPassword &&
                              validation.errors.newPassword
                            )
                          }
                        />
                        {validation.touched.newPassword &&
                        validation.errors.newPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.newPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="confirmPassword"
                          className="col-form-label"
                        >
                          Confirm Password:
                        </label>
                        <Input
                          type="password"
                          name="confirmPassword"
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            !!(
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                            )
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          {/* <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Reset My Password
                          </button> */}
                          <SubmitButtonHelper type="primary" name="Reset My Password" formik={validation} />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-2 text-center">
                <p>
                  <Link to="/login" className="font-weight-medium text-primary">
                    Go back to Login
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} © Sokoine University of
                  Agriculture. <br /> All rights reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
