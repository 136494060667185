import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import HoDProgramChange from "../Views/HoDProgramChange";
import HoDPostpone from "../Views/HoDPostpone";
import ForwardResultsToPrincipal from "../Views/ForwardResultsToPrincipal";
import UploadResultDeadlineList from "../Views/UploadResultDeadlineList"

const HodRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<HoDProgramChange />} />
            <Route path="/hod-program-change" element={<HoDProgramChange />} />
            <Route path="/hod-postpone" element={<HoDPostpone />} />
          <Route path="/course_result_forwarding" element={<ForwardResultsToPrincipal />} />
          <Route path="/upload_results_deadline" element={<UploadResultDeadlineList />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default HodRoute