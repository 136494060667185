import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { createUpdateFunction, paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {onlineApplicationGraphQLClient} from "Modules/Authentication/ApolloClient"

import Select from "react-select"
import {CREATE_ADMISSION_YEARS} from "../Mutations/AdmissionYearMutation";
import {AdmissionYearContext} from "./AdmissionYearList";
import moment from "moment";
import { GET_ADMISSION_YEARS } from "../../OnlineApplication/AdmissionProgramCapacity/Queries/ProgramAdmissionQueries"

  const AdmissionYearModal = () => {
  const { updateAdmissionYearData, setUpdateAdmissionYearData } = useContext(AdmissionYearContext)
  const [AdmissionYear] = useMutation(CREATE_ADMISSION_YEARS)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_ADMISSION_YEARS;
  const variables = { pagination: paginationHelper };
  const registerData = 'data';
  
  const updateFunction =  createUpdateFunction(query, variables, registerData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateAdmissionYearData ? updateAdmissionYearData.name : "",
      status: updateAdmissionYearData ? updateAdmissionYearData.status : "",
      startDate: updateAdmissionYearData ? updateAdmissionYearData.startDate : "",
      endDate: updateAdmissionYearData ? updateAdmissionYearData.endDate : "",
      numberOfChoices: updateAdmissionYearData ? updateAdmissionYearData.numberOfChoices : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      startDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("Start date is required"),
      numberOfChoices: Yup.number()
                        .min(1, 'Number of choice must be at least 1')
                        .max(9, 'Batch number must be at most 9')
                        .required('Number of choice is required'),
      status:  Yup.string().required("Status is required"),
    }),

   
  onSubmit: (values, {resetForm, setSubmitting}) => {
    const startDate = moment(values.startDate).format("yyyy-MM-DD");
    const endDate = moment(values.endDate).format("yyyy-MM-DD");
    AdmissionYear({
      client: onlineApplicationGraphQLClient,
      variables: {
        inputs: [{...values, startDate, endDate, uid: updateAdmissionYearData?.uid}],
      },
      refetchQueries: [
        {
          query: GET_ADMISSION_YEARS,
          client: onlineApplicationGraphQLClient,
          variables: {
            pagination: paginationHelper,
          },
        },
      ],
      onCompleted: ({registerAdmissionYear}) => {
        if (registerAdmissionYear.code === 8000) {
          //resetForm();
          setModalOpen(false);
          setUpdateAdmissionYearData(false); // Close the modal
        } else {
          setModalOpen(true);
          setUpdateAdmissionYearData(true); // Keep the modal
        }
        setSubmitting(false);
      },
      onError: error => {
        setSubmitting(false)
        // Handle errors
        console.error("Mutation error:", error);
      },
    }).then(r => console.log(r));
  },
  })
  return (
      <div className="text-sm-end mt-3">
        <Button
          type="button"
          color="primary"
          className="btn mb-2 me-2 pull-right"
          onClick={() => setModalOpen(true)}
        >
          <i className="mdi mdi-plus-circle-outline me-1" />
          New Admission Year
        </Button>
        <Modal
          isOpen={modalOpen || !!updateAdmissionYearData}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {!!updateAdmissionYearData ? "Edit Admission Year" : "Add New Admission Year"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalOpen(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Name:
                </label>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Enter Admission Year Name (2023/2024)"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    !!(validation.touched.name && validation.errors.name)
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="startDate" className="col-form-label">
                Start Date:
                </label>
                <Input
                  type="date"
                  name="startDate"
                  className="form-control"
                  id="startDate"
                  placeholder="Enter start Date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={moment(validation.values.startDate).format("yyyy-MM-DD") || ""}
                  invalid={
                    !!(validation.touched.startDate && validation.errors.startDate)
                  }
                />
                {validation.touched.startDate && validation.errors.startDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.startDate}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="endDate" className="col-form-label">
                End Date:
                </label>
                <Input
                  type="date"
                  name="endDate"
                  className="form-control"
                  id="endDate"
                  placeholder="Enter End Date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={moment(validation.values.endDate).format("yyyy-MM-DD") || ""}
                  invalid={
                    !!(validation.touched.endDate && validation.errors.endDate)
                   }
                />
                {validation.touched.endDate && validation.errors.endDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.endDate}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="row">
                <div className="col-lg-6">
                <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Number of Choices:
                </label>
                <Input
                  type="number"
                  name="numberOfChoices"
                  className="form-control"
                  id="name"
                  placeholder="Enter number of choices)"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.numberOfChoices || ""}
                  invalid={
                    !!(validation.touched.numberOfChoices && validation.errors.numberOfChoices)
                  }
                />
                {validation.touched.numberOfChoices && validation.errors.numberOfChoices ? (
                  <FormFeedback type="invalid">
                    {validation.errors.numberOfChoices}
                  </FormFeedback>
                ) : null}
              </div>
                </div>
                <div className="col-lg-6">
                <div className="mb-3">
                <label htmlFor="status" className="col-form-label">
                  Status:
                </label>

                     <Select
                          // value={selectedMulti2}
                          onChange={(e) => {
                            validation.setFieldValue('status',e.value)
                          }}
                          options={[
                            {
                              label:"Current",
                              value:1
                            },
                            {
                              label:"Not Current",
                              value:0
                            }
                          ]}
                          className="select2-selection"
                      />

                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>
                </div>
              </div>

              
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModalOpen(false)
                  setUpdateAdmissionYearData(false)
                  resetForm() // Reset the form
                }}
              >
                Close
              </button>
              <Button type="submit" className="btn btn-btn" color="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
  )
}

export default AdmissionYearModal
