
import React, { useContext, useState, useEffect } from "react";
import DualListBox from "react-dual-listbox";
import { GroupContext } from "./GroupList";
import { Modal } from "reactstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ASSIGNED_PERMISSION,
  GET_NOT_ASSIGNED_PERMISSION,
} from "../Queries/GroupQueries";
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient";
import { useFormik } from "formik";
import { ASSIGN_PERMISSION } from "../Mutations/GroupMutations";
import showToast from "helpers/ToastHelper";

const AssignPermission = () => {
  const { assignPermission, setAssignPermission } = useContext(GroupContext);
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [assignedPermissions, setAssignedPermissions] = useState([]);
  const [notAssignedPermissions, setNotAssignedPermissions] = useState([]);
  const [assignPermissionsToGroup] = useMutation(ASSIGN_PERMISSION);

  const {
    data: assignedPermissionsData,
    loading: assignedPermissionsLoading,
    error: assignedPermissionsError,
  } = useQuery(GET_ASSIGNED_PERMISSION, {
    client: uaaGraphQLClient,
    variables: {
      uid: assignPermission?.uid,
    },
    skip: !assignPermission,
    onError: (error) => {
      console.error("Error fetching assigned permissions:", error);
    },
  });

  const {
    data: notAssignedPermissionsData,
    loading: notAssignedPermissionsLoading,
    error: notAssignedPermissionsError,
  } = useQuery(GET_NOT_ASSIGNED_PERMISSION, {
    client: uaaGraphQLClient,
    variables: {
      uid: assignPermission?.uid,
    },
    skip: !assignPermission,
    onError: (error) => {
      console.error("Error fetching not assigned permissions:", error);
    },
  });

  useEffect(() => {
    if (assignedPermissionsData) {
      const assignedPermissions =
        assignedPermissionsData.getGroupPermissions.data.items;
      setAssignedPermissions(assignedPermissions);
    }
  }, [assignedPermissionsData]);

  useEffect(() => {
    if (notAssignedPermissionsData) {
      const notAssignedPermissions =
        notAssignedPermissionsData.getUnassignedGroupPermissions.data.items;
      setNotAssignedPermissions(notAssignedPermissions);
    }
  }, [notAssignedPermissionsData]);

  useEffect(() => {
    // Update the selected value based on assignedPermissions
    setSelected(assignedPermissions.map((permission) => permission.uid));
  }, [assignedPermissions]);

  const formik = useFormik({
    initialValues: {
      permissions: selected,
    },
    onSubmit: async () => {
      try {
        const { data } = await assignPermissionsToGroup({
          client: uaaGraphQLClient,
          variables: {
            inputs: {
              permissions: selected,
              groupUid: assignPermission.uid,
            },
          },
        });

        if (data.assignPermissionsToGroup.code === 8000) {
          setModalOpen(false);
          setAssignPermission(false); // Close the modal
        } else {
          setModalOpen(true);
          setAssignPermission(true);
          // setSubmitting(false);
        }
      } catch (error) {
        console.error("Error assigning permissions:", error);
      }
    },
  });

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modalOpen || !!assignPermission}
        backdrop={"static"}
        id="staticBackdrop"
      >
        {/* Modal content */}
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!assignPermission
              ? "Assign Permission(s) To " + assignPermission.code + " Group"
              : "Group Not Selected"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <DualListBox
            canFilter
            filterCallback={(option, filterInput) => {
              if (filterInput === "") {
                return true;
              }
              return new RegExp(filterInput, "i").test(option.label);
            }}
            options={[
              ...assignedPermissions.map((permission) => ({
                value: permission.uid,
                label: permission.name,
              })),
              ...notAssignedPermissions.map((permission) => ({
                value: permission.uid,
                label: permission.name,
              })),
            ]}
            selected={selected}
            onChange={(value) => setSelected(value)}
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModalOpen(false);
              setAssignPermission(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AssignPermission;

