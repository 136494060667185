import { gql } from '@apollo/client';


const CREATE_STUDENT_DISSERTATION_PAPER = gql`
  mutation RegisterStudentManuscript($inputs: [StudentManuscriptInput!]!) {
    registerStudentManuscript(inputs: $inputs) {
        status
        code
        message
    }
  }
`


const CREATE_STUDENT_INTENTION_TO_SUBMIT = gql`
  mutation RegisterIntentionToSubmit($inputs: [IntentionToSubmitInput!]!) {
    registerIntentionToSubmit(inputs: $inputs) {
      status
      code
      message
    }
  }
`


const DELETE_STUDENT_MANUSCRIPT = gql`
  mutation RemoveStudentManuscript($uid: String!) {
    removeStudentManuscript(uid: $uid) {
      message
      code
      status
    }
  }
`


export { DELETE_STUDENT_MANUSCRIPT,CREATE_STUDENT_DISSERTATION_PAPER,CREATE_STUDENT_INTENTION_TO_SUBMIT }