import React from "react"
import { UncontrolledAlert } from "reactstrap"
import PropTypes from "prop-types";

const AlertHelper = props => {
  const {type,message} = props
  return (
    <UncontrolledAlert
      color={type}
      className="alert-dismissible fade show"
      role="alert"
    >
      {type === 'warning' ? <i className="mdi mdi-alert-outline me-2"></i> : ''}
      {type === 'danger' ? <i className="mdi mdi-block-helper me-2"></i> : ''}
      {type === 'primary' ? <i className="mdi mdi-bullseye-arrow me-2"></i> : ''}
      {type === 'success' ? <i className="mdi mdi-check-all me-2"></i> : ''}
      {message}
    </UncontrolledAlert>
  );
}

AlertHelper.propTypes = {
  type: PropTypes.oneOf(['warning','danger','primary','success']),
  message: PropTypes.string
};

export default AlertHelper;