import { gql } from "@apollo/client"

const CREATE_PROGRAM = gql`
  mutation registerProgram($inputs: [ProgramInput!]!) {
    registerProgram(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          tcuCode
          code
          nacteCode
          shortName
          name
          duration
          registrationCode
          departmentUid
          programCategory{
            uid
            name
            shortName
          }
        }
        totalCount
      }
    }
  }
`

const DELETE_PROGRAM = gql`
  mutation RemoveProgram($uid: String!) {
    removeProgram(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_PROGRAM, DELETE_PROGRAM }
