import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, InputGroup, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import {CourseContext} from "./SemesterList";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {limit} from "../../../helpers/UrlHelper";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CREATE_SEMESTER} from "../Mutations/SemesterMutations";
import {useNavigate} from "react-router-dom";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../helpers/ToastHelper";
import Select from "react-select";
import {GET_SEMESTERS} from "../Queries/SemesterQueries";
import Flatpickr from "react-flatpickr";
import {GET_ACADEMICYEARS} from "../../AcademicYears/Queries/AcademicYearsQueries";

const SemesterFormModel = props => {
    const {semesterData,setSemesterData} = useContext(CourseContext)
    const [modalOpen, setModalOpen] = useState(false)
    let semester = semesterData

    const pagination = { offset: 0, limit: limit, search: null }
    const [saveSemester] = useMutation(CREATE_SEMESTER)
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(GET_ACADEMICYEARS, {
        client:registrationGraphQLClient,
        variables: {
            pagination:pagination,
        }
    })
    const academicYears = data?.getAcademicYears?.data.items
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            semester:semester?.semester,
            academicYearUid:semester?.academicYearUid,
            oddStartDate:semester?.oddStartDate,
            oddEndDate:semester?.oddEndDate,
            evenStartDate:semester?.evenStartDate,
            evenEndDate:semester?.evenEndDate,
            examStartDate:semester?.examStartDate,
            examTicketDate:semester?.examTicketDate,
            status:semester?.status,
            // changeProgramStartDate:semester?.changeProgramStartDate,
            // changeProgramEndDate:semester?.changeProgramEndDate,
        },

        validationSchema: Yup.object().shape({

            oddStartDate: Yup.date()
                .typeError('Invalid Odd Start Date')
                .required('Odd Start Date Is Required'),
            oddEndDate: Yup.date()
                .typeError('Invalid Odd End Date')
                .required('Odd End Date Is Required')
                .min(Yup.ref('oddStartDate'), 'Odd End Date Must Be After The Odd Start Date'),
            evenStartDate: Yup.date()
                .typeError('Invalid Even Start Date')
                .required('Even Start Date Is Required')
                .min(Yup.ref('oddEndDate'), 'Even Start Date Must Be After The Odd End Date'),
            evenEndDate: Yup.date()
                .typeError('Invalid Even End Date')
                .required('Odd End Date Is Required')
                .min(Yup.ref('evenStartDate'), 'Even End Date Must Be After The Even Start Date'),

            // .when('evenStartDate', (evenStartDate, schema) => {
                //     return schema.min(evenStartDate, 'Even End Date Must Be After The Even Start Date');
                // }),
            examStartDate: Yup.date()
                .typeError('Invalid Exam Date')
                .required('Exam Date Is Required')
                .min(Yup.ref('examTicketDate'), 'Exam Date Must Be After Exam Ticket Date'),
            examTicketDate: Yup.date()
                .typeError('Invalid Exam Ticket Date')
                .required('Exam Ticket Date Is Required'),
            // changeProgramEndDate: Yup.date()
            //     .typeError('Invalid Change Program Date')
            //     .required('Change Program Date Is Required')
            //     .min(Yup.ref('changeProgramStartDate'), 'Change Program End Date Must Be After End Date'),
            // changeProgramStartDate: Yup.date()
            //     .typeError('Invalid Change Program Date')
            //     .required('Change Program Start Date Is Required'),
            semester: Yup.number()
                .typeError('Invalid Semester Number')
                .min(1).max(2)
                .required('Semester Is Required'),
            status: Yup.number()
                .typeError('Invalid Status')
                .min(0).max(1)
                .required('Status Is Required'),
        }),
        onSubmit: (values,{setSubmitting,resetForm}) => {
            saveSemester({
                client:registrationGraphQLClient,
                variables: {
                    inputs: [{ ...values,uid:semester?.uid }],
                },
                refetchQueries: [
                    {
                        query: GET_SEMESTERS,
                        variables: {
                            pagination: pagination,
                        },
                    },
                ],
                onCompleted: ({ registerAcademicYearSemester }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    // console.log(registerCourses)
                    if (registerAcademicYearSemester.code === 8000) {
                        showToast(registerAcademicYearSemester.message, "success")
                        setSemesterData(null)
                        setModalOpen(false)
                        resetForm()
                    } else {
                        showToast(
                            registerAcademicYearSemester.message,
                            registerAcademicYearSemester.code === 8000 ? "success" : "error"
                        )
                    }
                    setSubmitting(false)
                },
                onError: error => {
                    // Handle errors
                    setSubmitting(false)
                    console.error("Mutation error:", error);
                },
            });
        },
    })

    // return 1
    const changeDate = e => {
        // const d =
        validation.setFieldValue(
            "dateOfBirth",
            e
        )
    }
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Semester
            </Button>
            <Modal
                isOpen={modalOpen || !!semesterData}
                backdrop={"static"}
                id="staticBackdrop"
                // size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!semesterData ? "Edit Semester" : "Add New Semester"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <Row>
                            <Col lg="3" md="6">
                                <div className="mb-3">
                                    <Label>Semester</Label>
                                    <Input
                                        name="semester"
                                        placeholder="Enter Semester Number"
                                        type="number"
                                        min={1}
                                        max={2}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.semester || ""}
                                        invalid={
                                            !!(
                                                validation.touched.semester &&
                                                validation.errors.semester
                                            )
                                        }
                                    />
                                    {validation.touched.semester &&
                                    validation.errors.semester ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.semester}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="3" md="6">
                                <div className="mb-3">
                                    <Label>Is Active? {validation.values.status} </Label>
                                    <div className="square-switch small">
                                        <Input
                                            disabled={false}
                                            type="checkbox"
                                            id={"status_checkbox"}
                                            // value={validation.values.status}
                                            className="switch switch-bool"
                                            name="status"
                                            onChange={
                                                (e) => {
                                                    // validation.values.status       = 0
                                                    // validation.handleChange(e)
                                                    if(e.target.checked) validation.setFieldValue('status', 1)
                                                    else validation.setFieldValue('status', 0)
                                                }
                                            }
                                            defaultChecked={semester?.status === 1}
                                            onBlur={validation.handleBlur}
                                            // value={}
                                            invalid={
                                                !!(validation.touched.status && validation.errors.status)
                                            }
                                        />
                                        <label
                                            htmlFor={"status_checkbox"}
                                            data-on-label="Yes"
                                            data-off-label="No"
                                        />
                                    </div>

                                    {validation.touched.status &&
                                    validation.errors.status ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.status}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={6} md={6}>
                                <Label>Academic Year</Label>
                                <Select
                                    // value={selectedGroup}
                                    isClearable={true}
                                    name="academicYearUid"
                                    onChange={(e) => {
                                        validation.setFieldValue('academicYearUid', e?.value)
                                        // console.log(e?.value)
                                    }}
                                    options={academicYears?.map(year => ({
                                        value: year.uid,
                                        label: year.name,
                                    }))}
                                    className="select2-selection"
                                />
                                <Input
                                    name="academicYearUid"
                                    type="hidden"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.academicYearUid || ""}
                                    invalid={
                                        !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                    }
                                />
                                {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.academicYearUid}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6">
                                <div className="mb-3">
                                    <Label>Start Date (Odd Semester)</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            // name="dateOfBirth"
                                            placeholder="dd M,yyyy"
                                            value={validation.values.oddStartDate}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={date => validation.setFieldValue('oddStartDate', date[0])}
                                            // onChange={changeDate}
                                        />
                                    </InputGroup>
                                    <Input
                                        name="oddStartDate"
                                        placeholder="Enter date of birth"
                                        type="hidden"
                                        rows={3}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.oddStartDate || ""}
                                        invalid={
                                            !!(
                                                validation.touched.oddStartDate &&
                                                validation.errors.oddStartDate
                                            )
                                        }
                                    />
                                    {validation.touched.oddStartDate &&
                                    validation.errors.oddStartDate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.oddStartDate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6" md="6">
                                <div className="mb-3">
                                    <Label>End Date (Odd Semester)</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            // name="dateOfBirth"
                                            placeholder="dd M,yyyy"
                                            value={validation.values.oddEndDate}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={date => validation.setFieldValue('oddEndDate', date[0])}
                                            // onChange={changeDate}
                                        />
                                    </InputGroup>
                                    <Input
                                        name="oddEndDate"
                                        placeholder="Enter date of birth"
                                        type="hidden"
                                        rows={3}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.oddEndDate || ""}
                                        invalid={
                                            !!(
                                                validation.touched.oddEndDate &&
                                                validation.errors.oddEndDate
                                            )
                                        }
                                    />
                                    {validation.touched.oddEndDate &&
                                    validation.errors.oddEndDate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.oddEndDate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6">
                                <div className="mb-3">
                                    <Label>Start Date (Even Semester)</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            value={validation.values.evenStartDate}
                                            // name="dateOfBirth"
                                            placeholder="dd M,yyyy"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={date => validation.setFieldValue('evenStartDate', date[0])}
                                            // onChange={changeDate}
                                        />
                                    </InputGroup>
                                    <Input
                                        name="evenStartDate"
                                        placeholder="Enter Start Date"
                                        type="hidden"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.evenStartDate || ""}
                                        invalid={
                                            !!(
                                                validation.touched.evenStartDate &&
                                                validation.errors.evenStartDate
                                            )
                                        }
                                    />
                                    {validation.touched.evenStartDate &&
                                    validation.errors.evenStartDate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.evenStartDate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6" md="6">
                                <div className="mb-3">
                                    <Label>End Date (Even Semester)</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            // name="dateOfBirth"
                                            value={validation.values.evenEndDate}
                                            placeholder="dd M,yyyy"
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={date => validation.setFieldValue('evenEndDate', date[0])}
                                            // onChange={changeDate}
                                        />
                                    </InputGroup>
                                    <Input
                                        name="evenEndDate"
                                        placeholder="Enter Semester End Date"
                                        type="hidden"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.evenEndDate || ""}
                                        invalid={
                                            !!(
                                                validation.touched.evenEndDate &&
                                                validation.errors.evenEndDate
                                            )
                                        }
                                    />
                                    {validation.touched.evenEndDate &&
                                    validation.errors.evenEndDate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.evenEndDate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" md="6">
                                <div className="mb-3">
                                    <Label>Exam Ticket Date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            // name="dateOfBirth"
                                            placeholder="dd M,yyyy"
                                            value={validation.values.examTicketDate}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={date => validation.setFieldValue('examTicketDate', date[0])}
                                            // onChange={changeDate}
                                        />
                                    </InputGroup>
                                    <Input
                                        name="examTicketDate"
                                        placeholder="Enter date of birth"
                                        type="hidden"
                                        rows={3}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.examTicketDate || ""}
                                        invalid={
                                            !!(
                                                validation.touched.examTicketDate &&
                                                validation.errors.examTicketDate
                                            )
                                        }
                                    />
                                    {validation.touched.examTicketDate &&
                                    validation.errors.examTicketDate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.examTicketDate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6" md="6">
                                <div className="mb-3">
                                    <Label>Final Exam Date</Label>
                                    <InputGroup>
                                        <Flatpickr
                                            className="form-control d-block"
                                            // name="dateOfBirth"
                                            placeholder="dd M,yyyy"
                                            value={validation.values.examStartDate}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d",
                                            }}
                                            onChange={date => validation.setFieldValue('examStartDate', date[0])}
                                            // onChange={changeDate}
                                        />
                                    </InputGroup>
                                    <Input
                                        name="examStartDate"
                                        placeholder="Enter date of birth"
                                        type="hidden"
                                        rows={3}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.examStartDate || ""}
                                        invalid={
                                            !!(
                                                validation.touched.examStartDate &&
                                                validation.errors.examStartDate
                                            )
                                        }
                                    />
                                    {validation.touched.examStartDate &&
                                    validation.errors.examStartDate ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.examStartDate}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col lg="6" md="6">*/}
                        {/*        <div className="mb-3">*/}
                        {/*            <Label>Change Program Start Date</Label>*/}
                        {/*            <InputGroup>*/}
                        {/*                <Flatpickr*/}
                        {/*                    className="form-control d-block"*/}
                        {/*                    // name="dateOfBirth"*/}
                        {/*                    placeholder="dd M,yyyy"*/}
                        {/*                    value={validation.values.changeProgramStartDate}*/}
                        {/*                    options={{*/}
                        {/*                        altInput: true,*/}
                        {/*                        altFormat: "F j, Y",*/}
                        {/*                        dateFormat: "Y-m-d",*/}
                        {/*                    }}*/}
                        {/*                    onChange={date => validation.setFieldValue('changeProgramStartDate', date[0])}*/}
                        {/*                    // onChange={changeDate}*/}
                        {/*                />*/}
                        {/*            </InputGroup>*/}
                        {/*            <Input*/}
                        {/*                name="changeProgramStartDate"*/}
                        {/*                placeholder="Enter date of birth"*/}
                        {/*                type="hidden"*/}
                        {/*                rows={3}*/}
                        {/*                onChange={validation.handleChange}*/}
                        {/*                onBlur={validation.handleBlur}*/}
                        {/*                value={validation.values.changeProgramStartDate || ""}*/}
                        {/*                invalid={*/}
                        {/*                    !!(*/}
                        {/*                        validation.touched.changeProgramStartDate &&*/}
                        {/*                        validation.errors.changeProgramStartDate*/}
                        {/*                    )*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*            {validation.touched.changeProgramStartDate &&*/}
                        {/*            validation.errors.changeProgramStartDate ? (*/}
                        {/*                <FormFeedback type="invalid">*/}
                        {/*                    {validation.errors.changeProgramStartDate}*/}
                        {/*                </FormFeedback>*/}
                        {/*            ) : null}*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*    <Col lg="6" md="6">*/}
                        {/*        <div className="mb-3">*/}
                        {/*            <Label>Change Program End Date</Label>*/}
                        {/*            <InputGroup>*/}
                        {/*                <Flatpickr*/}
                        {/*                    className="form-control d-block"*/}
                        {/*                    // name="dateOfBirth"*/}
                        {/*                    placeholder="dd M,yyyy"*/}
                        {/*                    value={validation.values.changeProgramEndDate}*/}
                        {/*                    options={{*/}
                        {/*                        altInput: true,*/}
                        {/*                        altFormat: "F j, Y",*/}
                        {/*                        dateFormat: "Y-m-d",*/}
                        {/*                    }}*/}
                        {/*                    onChange={date => validation.setFieldValue('changeProgramEndDate', date[0])}*/}
                        {/*                    // onChange={changeDate}*/}
                        {/*                />*/}
                        {/*            </InputGroup>*/}
                        {/*            <Input*/}
                        {/*                name="changeProgramEndDate"*/}
                        {/*                placeholder="Enter date of birth"*/}
                        {/*                type="hidden"*/}
                        {/*                rows={3}*/}
                        {/*                onChange={validation.handleChange}*/}
                        {/*                onBlur={validation.handleBlur}*/}
                        {/*                value={validation.values.changeProgramEndDate || ""}*/}
                        {/*                invalid={*/}
                        {/*                    !!(*/}
                        {/*                        validation.touched.changeProgramEndDate &&*/}
                        {/*                        validation.errors.changeProgramEndDate*/}
                        {/*                    )*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*            {validation.touched.changeProgramEndDate &&*/}
                        {/*            validation.errors.changeProgramEndDate ? (*/}
                        {/*                <FormFeedback type="invalid">*/}
                        {/*                    {validation.errors.changeProgramEndDate}*/}
                        {/*                </FormFeedback>*/}
                        {/*            ) : null}*/}
                        {/*        </div>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setSemesterData(false)
                                validation.resetForm();
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default SemesterFormModel;