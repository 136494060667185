import React, {useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody, Label, Input, FormFeedback, Form,
} from "reactstrap"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
import {useFormik} from "formik";
import {registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../AcademicYears/Queries/AcademicYearsQueries";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import Select from "react-select";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import showToast from "../../../helpers/ToastHelper";
import {CORE_COURSE_REGISTRATION} from "../Mutations/CourseMutations";
import { GET_PROGRAMS } from "../../Programs/Queries/ProgramQuaries"


const breadcrumbItem = [
    {
        title: "Student Course Registration",
        path: "",
        isActive: true,
    },
]

const CoreCourseRegistration = () => {
    const [summary,setSummary] = useState([])
    const studyYear = [
        { label: "Year 1", value: 1 },
        { label: "Year 2", value: 2 },
        { label: "Year 3", value: 3 },
        { label: "Year 4", value: 4 },
        { label: "Year 5", value: 5 },
    ]
    const [courseRegistration] = useMutation(CORE_COURSE_REGISTRATION, {client: registrationGraphQLClient})

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            academicYearUid: "",
            semester: "",
            programUid: "",
        },
        validationSchema: Yup.object().shape({
            academicYearUid: Yup.string().required("Academic year is required"),
            semester: Yup.string().required("Semester is required"),
            programUid: Yup.string().required("Program name is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            courseRegistration({
                variables: {
                    input:values
                },
                fetchPolicy: 'no-cache',
                client: registrationGraphQLClient,
                onCompleted: ({ forceCoreCourseRegistration }) => {
                    showToast(
                        forceCoreCourseRegistration.message,
                        forceCoreCourseRegistration.code === 8000 ? "success" : "error"
                    )
                    if(forceCoreCourseRegistration.code === 8000){
                        setSummary(forceCoreCourseRegistration?.data)
                    }

                    setSubmitting(false)
                },
                onError: error => {
                    console.error("Mutation error:", error)
                    setSubmitting(false)
                },
            })
        },
    });

    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })


    const {loading: programLoading,error: programError,
        data: programData,
    } = useQuery(GET_PROGRAMS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })

    const programsOptions = programData?.getPrograms?.data?.items

    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items

    const Semesters = [
        { label: "Odd Semester", value: 'Odd' },
        { label: "Even Semester", value: 'Even' }
    ]

    // if (courseData?.getProgramCourseByProgramSemesterUid?.data)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Force Student Course Registration">
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                onChange={(e) => {
                                                    if (e) {
                                                        validation.setFieldValue("academicYearUid", e.value)
                                                    } else {
                                                        validation.setFieldValue("academicYearUid", "")
                                                    }
                                                }}
                                                onInputChange={e => {
                                                    // search(e, 'academicYear')
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.uid,
                                                    label: year.name,
                                                }))}

                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYearUid}
                                                invalid={
                                                    !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                }
                                            />
                                            {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYearUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label>Semester Type</Label>
                                            <Select
                                                // value={selectedGroup}
                                                onChange={e =>
                                                    validation.setFieldValue(
                                                        "semester",
                                                        e.value
                                                    )
                                                }
                                                options={Semesters}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="emester"
                                                type="hidden"
                                                rows={3}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.semester || ""}
                                                invalid={
                                                    !!(
                                                        validation.touched.semester &&
                                                        validation.errors.semester
                                                    )
                                                }
                                            />
                                            {validation.touched.semester &&
                                            validation.errors.semester ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.semester}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Label>Program name</Label>
                                            <Select
                                              // value={selectedGroup}
                                              onChange={e =>
                                                validation.setFieldValue(
                                                  "programUid",
                                                  e.value
                                                )
                                              }
                                              options={programsOptions?.map(program => ({
                                                  label: program.name,
                                                  value: program.uid,
                                              }))}
                                              isLoading={programLoading}
                                              className="select2-selection"
                                            />
                                            <Input
                                              name="emester"
                                              type="hidden"
                                              rows={3}
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.programUid || ""}
                                              invalid={
                                                  !!(
                                                    validation.touched.programUid &&
                                                    validation.errors.programUid
                                                  )
                                              }
                                            />
                                            {validation.touched.programUid &&
                                            validation.errors.programUid ? (
                                              <FormFeedback type="invalid">
                                                  {validation.errors.programUid}
                                              </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="float-end">
                                            <SubmitButtonHelper type={"primary"} name={"Submit"}
                                                                formik={validation} />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {summary && !validation.isSubmitting &&
              <Row>
                  <Col lg={12}>
                      <Card>
                          <CardBody>
                              <div className="table-responive">
                                  <table className="table table-bordered table-striped">
                                      <thead>
                                      <tr>
                                          <th>S/No</th>
                                          <th>Program Name</th>
                                          <th>Program Code</th>
                                          <th>CORE Course</th>
                                          <th>Student No.</th>
                                          <th>Expectation</th>
                                          <th>Registered</th>
                                          <th>Not Registered</th>
                                          <th>Pushed</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      {summary?.map((c, index) => (
                                        <tr key={index + 1}>
                                            <td>{index + 1}</td>
                                            <td>{c?.programName}</td>
                                            <td>{c?.programCode}</td>
                                            <td align="center">{c?.totalCourses}</td>
                                            <td align="center">{c?.totalStudents}</td>
                                                <td align="center">{c?.courseEntryToBeRegistered}</td>
                                                <td align="center">{c?.courseEntryRegistered}</td>
                                                <td align="center">{c?.courseEntryNotRegistered}</td>
                                                <td align="center">{c?.courseEntryPushed}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </LayoutHelper>
    )
}

export default CoreCourseRegistration
