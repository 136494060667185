import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  Button,
  Table,
} from "reactstrap"
import { useMutation, useQuery } from "@apollo/client"
import * as Yup from "yup"
import showToast from "helpers/ToastHelper"
import { Link } from "react-router-dom"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useParams } from "react-router"
import { TeachingAssessmentProfileContext } from "./TeachingCourseAssessmentParent"
import {
  GET_STUDENT_COURSELEARNING_OUTCOME,
  GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT,
} from "Modules/TeachingAssessment/Queries/TeachingAssessmentQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT } from "Modules/TeachingAssessment/Mutations/TeachingAssessmentMutations"
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useFormik } from "formik"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"

// getCourseLearnOutcome
const QuestionSix = () => {
  const { uid } = useParams()

  const { updateTeachingAssessmentData } = useContext(
    TeachingAssessmentProfileContext
  )

  const [currentPage, setCurrentPage] = useState(0)
  const { toggleTab } = useContext(TeachingAssessmentProfileContext)
  const [isSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(100)
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const [registerCourseAssessment] = useMutation(
    REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT,
    {
      client: registrationGraphQLClient,
    }
  )

  const [outlineProvided, setOutlineProvided] = useState(false)

  const handlePrevious = () => {
    toggleTab(5) // Move to the previous tab
  }

  const {
    loading: courseAssessmentLoading,
    error: courseAssessmentError,
    data: courseAssessmentData,
  } = useQuery(GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT, {
    client: registrationGraphQLClient,
    variables: {
      studentCourseRegistrationUid: uid ? uid : "",
      questionNo: 6,
    },
    skip: !uid,
    fetchPolicy: "network-only",
  })
  const questionFourRes =
    courseAssessmentData?.getStudentProgramCourseAssessmentResult?.data

  const numberedItems = `i.   \nii.  \niii. `

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      answer: questionFourRes ? questionFourRes?.answer : numberedItems,
    },

    validationSchema: Yup.object().shape({
      answer: Yup.string().required("Answer is required"),
    }),

    onSubmit: values => {
      validation.resetForm();
      registerCourseAssessment({
        variables: {
          inputs: {
            answer: values.answer,
            questionNo: 6,
            studentCourseRegistrationUid: uid,
          },
        },
        onCompleted: ({ registerStudentProgramCourseAssessmentResult }) => {
          if (registerStudentProgramCourseAssessmentResult.code === 8000) {
            showToast(
              registerStudentProgramCourseAssessmentResult.message,
              "success"
            )
            toggleTab(7) // Move to the next tab
            validation.resetForm()
          } else {
            showToast(
              registerStudentProgramCourseAssessmentResult.message,
              registerStudentProgramCourseAssessmentResult.code === 8000
                ? "success"
                : "error"
            )
            validation.resetForm()
            toggleTab(6) // Move to the next tab
          }
        },
        onError: error => {
          validation.resetForm()
          console.error("Mutation error:", error)
        },
      })

    }
  })


  return (
    <div className="mt-3 wizard">
      {courseAssessmentLoading ? (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col lg="12">
                <div className="mb-3">
                  <Label className="font-weight-bolder">
                  <b>6.0</b> Out of the list in section 5.0 above, list the 3 best options that helped you learn better in this course? 
                  </Label>
                  <div className="form-group">
                    <textarea
                      rows={3}
                      cols={3}
                      name="answer"
                      className="form-control"
                      style={{ whiteSpace: "pre-wrap"}}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.answer || ""}
                    >
                      {
                        questionFourRes?.answer ? questionFourRes?.answer
                        : numberedItems}
                    </textarea>
                    {validation.touched.answer && validation.errors.answer ? (
                      <FormFeedback type="invalid">
                        {validation.errors.answer}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="wizard">
            <div className="actions">
              <ul>
                <li className="previous">
                  <Link to="#" onClick={handlePrevious}>
                    {`< Previous`}
                  </Link>
                </li>
                  <li className="previous">
                  <SubmitButtonHelper name="Next >" type="primary" formik={validation} />
                  </li>
              </ul>
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default QuestionSix
