import React, { createContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown, Input,
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import ReactPaginate from "react-paginate"
import { useFormik } from "formik"
import { Link } from "react-router-dom"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { paginationHelper } from "../../../helpers/Functions/GraphqlUpdateFunction"
import showToast from "../../../helpers/ToastHelper"
import { showDeleteWarning } from "../../../helpers/DeleteWaringHelper"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"
import { GET_SEMESTERS } from "../Queries/SemesterQueries"
import { DELETE_SEMESTER } from "../Mutations/SemesterMutations"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import { limit } from "../../../helpers/UrlHelper"
import PaginationHelper from "../../../helpers/PaginationHelper"
import SemesterFormModel from "./SemesterFormModel"
import moment from "moment"

const breadcrumbItem = [
  {
    title: "Semester List",
    path: "",
    isActive: true,
  },
]
export const CourseContext = createContext()
const SemesterList = () => {
  // return 1
  const [semesterUid, setSemesterUid] = useState(null),
    [semesterData, setSemesterData] = useState(null),
    [pageSize, setPageSize] = useState(limit),
    [currentPage, setCurrentPage] = useState(0),
    navigate = useNavigate()
  const pagination = { offset: currentPage, limit: limit, search: null }
  const { loading, error, data } = useQuery(GET_SEMESTERS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: pagination,
    },
  })
  const [
    searchQuery,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_SEMESTERS, { client: registrationGraphQLClient })

  let semesters = data?.getAcademicYearSemesters?.data?.items
  let totalCount = data?.getAcademicYearSemesters?.data?.totalCount
  if (searchData?.getAcademicYearSemesters?.data?.items) {
    semesters = searchData?.getAcademicYearSemesters?.data?.items
    totalCount = searchData?.getAcademicYearSemesters?.data?.totalCount
  }
  // return 1
  const handleClick = data => {
    setCurrentPage(data.selected)
  }
  // return 1
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })
  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
  })
  const search = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchQuery({ variables: { pagination: { ...pagination, ...data } } })
    }
  }
  const [finalizeDelete] = useMutation(DELETE_SEMESTER, {
    client: registrationGraphQLClient,
    variables: { uid: semesterUid },
    refetchQueries: [
      {
        query: GET_SEMESTERS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const confirmDelete = async () => {
    try {
      const response = await finalizeDelete()
      showToast(response.data.removeAcademicYearSemester.message, "success")
      // console.log("User deleted successfully:", response)
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const handleDelete = () => {
    if (semesterUid !== "") {
      showDeleteWarning(() => {
        confirmDelete()
        setSemesterUid(null)
      })
    } else {
      showToast("Please Select Program to delete", "warning")
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = course => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [course.uid]: !prevState[course.uid],
    }))
  }
  // if (error || searchError) {
  //     return showToast(error || searchError, "error")
  // }
  const formatDate = date => {
    return moment(date).format("DD MMM YYYY") // Display short month name
  }
  return (
    <CourseContext.Provider value={{ semesterData, setSemesterData }}>
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="List Of Semesters"
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={search}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                          {!searchLoading && (
                            <span className="bx bx-search-alt" />
                          )}
                          {searchLoading && (
                            <span className="bx bx-search-alt bx-spin" />
                          )}
                        </div>
                      </form>
                    </Col>

                    <Col sm="6">
                      <SemesterFormModel />
                    </Col>
                  </Row>
                </CardSubtitle>

                {loading || searchLoading ? (
                  <PlaceHolderLoader columSize={12} type="table" rows={5} />
                ) : (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>S/No</Th>
                            <Th>ACADEMIC YEAR</Th>
                            <Th>SEMESTER</Th>
                            <Th>ACTIVE</Th>
                            <Th>ODD START DATE</Th>
                            <Th>ODD END DATE</Th>
                            <Th>EVEN START DATE</Th>
                            <Th>EVEN END DATE</Th>
                            <Th>EXAM TICKET DATE</Th>
                            <Th>EXAM DATE</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {semesters?.map((sem, index) => (
                            <Tr key={sem?.uid}>
                              <Td>{currentPage * pageSize + index + 1}</Td>
                              <Td>{sem?.academicYear?.name}</Td>
                              <Td>{sem?.semester}</Td>
                              <Td>
                                {
                                  sem?.status === 1 ? "YES":"NO"
                                }
                              </Td>
                              <Td>{formatDate(sem?.oddStartDate)}</Td>
                              <Td>{formatDate(sem?.oddEndDate)}</Td>
                              <Td>{formatDate(sem?.evenStartDate)}</Td>
                              <Td>{formatDate(sem?.evenEndDate)}</Td>
                              <Td>{formatDate(sem?.examTicketDate)}</Td>
                              <Td>{formatDate(sem?.examStartDate)}</Td>
                              <Td>
                                <Dropdown
                                    isOpen={dropdownOpen[sem.uid]}
                                    toggle={() => toggleDropdown(sem)}
                                >
                                  <DropdownToggle color="" caret>
                                    <i
                                        className="fas fa-ellipsis-v"
                                        style={{color: "blue"}}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                        onClick={() => {
                                          setSemesterData(sem)
                                        }}
                                    >
                                      <i
                                          className="fas fa-user-edit"
                                          style={{
                                            color: "blue",
                                            marginRight: "10px",
                                          }}
                                      />
                                      <span>Edit</span>
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                          setSemesterUid(sem.uid)
                                          handleDelete()
                                        }}
                                    >
                                      <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                      />
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>

                    <PaginationHelper
                        currentPage={currentPage}
                        pageItemNumber={semesters?.length}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        handleClick={handleClick}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </CourseContext.Provider>
  )
}

export default SemesterList
