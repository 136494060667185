import React, { useEffect, useState } from "react"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Card, CardBody, CardSubtitle, Col, Row} from "reactstrap";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import AlertHelper from "../../../../helpers/AlertHelper";
import {LogedInStudent} from "../../LogedInStudent";
import {useQuery} from "@apollo/client";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {GET_STUDENT_FINANCIAL_STATEMENT} from "../../Queries/Payment/StudentPaymentQueries";
import showToast from "../../../../helpers/ToastHelper";
const breadcrumbItem =  [
    {
        title: 'Financial Statement',
        path: '',
        isActive: true,
    },
]
const StudentFinancialStatement = () => {
    const userData = JSON.parse(localStorage.getItem("authUser"))
    const dataInfo = userData?.data
    const { loading,error, data } = useQuery(GET_STUDENT_FINANCIAL_STATEMENT, {
        client:registrationGraphQLClient,
        variables: {
            registrationNumber:dataInfo?.student?.registrationNumber
            // registrationNumber:'ECB/D/2020/0030'
        }
    })

    let statement = data?.getFinancialStatement?.data
    let pdfUrl;
    if(statement){
        const byteCharacters = atob(statement);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        pdfUrl = URL.createObjectURL(blob);
    }

    if (error) {
        return showToast(error, "error")
    }
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Financial Statement">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {loading &&<PlaceHolderLoader columSize={12} />}
                            {
                                data?.getFinancialStatement?.data  &&
                                    <iframe
                                        title="PDF Viewer"
                                        src={pdfUrl}
                                        width="100%"
                                        autoSave={true}
                                        height="500px"
                                        style={{ border: 'none' }}
                                    ></iframe>
                                    // : <AlertHelper message={data?.getFinancialStatement?.message} type="primary" />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    );
};

export default StudentFinancialStatement;