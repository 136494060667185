import React, { createContext, useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {
  Card,
  CardBody,
  Col,
  Dropdown, DropdownItem, DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Row
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { useMutation, useQuery } from "@apollo/client"
import { registrationGraphQLClient } from "../../../Authentication/ApolloClient"
import { Td, td, th, thead } from "react-super-responsive-table"
import { showWaringConf } from "../../../../helpers/DeleteWaringHelper"
import showToast from "../../../../helpers/ToastHelper"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import {
  GET_STUDENT_EXAM_TO_REGISTER,
  GET_STUDENT_REGISTERED_EXAMS
} from "../../Queries/Registration/StudentExamRegistrationQuery"
import { STUDENT_REGISTER_EXAMINATION } from "../../Mutations/StudentRegistrationMutations"
import PdfHelper from "../../../../helpers/PdfHelper"
import { PDFDownloadLink, Text, View } from "@react-pdf/renderer"
import { pageStyle, TablePdfStyles } from "../../../../helpers/PdfStyle"
import ExamTicketTile from "./ExamTicket/ExamTicketTitle"
import ExamTicketStudentInfo from "./ExamTicket/ExamTicketStudentInfo"
import ExamPostponementForm from "../Application/ExamPostponement/ExamPostponementForm"
import AlertHelper from "../../../../helpers/AlertHelper"

const breadcrumbItem = [
  {
    title: "Examination Registration",
    path: "",
    isActive: true
  }
]

export const StudentExamRegistrationContext = createContext()
const StudentExamRegistration = () => {

  const [exam, setExam] = useState(null)
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const dataInfo = userData?.data
  const confirmPrintExam = async (fee) => {
    showToast("Waiting for backend to complete the process", "info")
    // try {
    //     await renewControlNumber(
    //         {
    //             client: registrationGraphQLClient,
    //             variables: {
    //                 inputs: {
    //                     registrationNumber: student?.registrationNumber,
    //                     billID: fee.billId,
    //                     payType:fee.payType
    //                 }
    //             },
    //             refetchQueries: [
    //                 {
    //                     query: GET_CONTROL_NUMBER,
    //                     variables: {
    //                         inputs: {
    //                             registrationNumber: student?.registrationNumber
    //                         }
    //                     },
    //                 },
    //             ],
    //             onCompleted: ({renewControlNumber}) => {
    //                 if (renewControlNumber.code === 8000) {
    //                     showToast(renewControlNumber.message, "success")
    //                 } else {
    //                     showToast(
    //                         renewControlNumber.message,
    //                         renewControlNumber.code === 8000 ? "success" : "error"
    //                     )
    //                 }
    //             },
    //         }
    //     )
    //     // console.log("User deleted successfully:", response)
    // } catch (error) {
    //     console.error("error:", error)
    // }
  }
  const handleConfirmButton = (pay) => {
    showWaringConf(() => {
        confirmPrintExam(pay)
      }, "Print Exam Ticket", `Are you sure that you need to print exam ticket?`, "warning", "Print",
      () => {
        // console.log("Canceled")
      })
  }

  const [registerExam] = useMutation(STUDENT_REGISTER_EXAMINATION)
  const [dropdownOpen, setDropdownOpen] = useState(false)


  const { loading: registeredLoading, error: registeredError, data } = useQuery(GET_STUDENT_REGISTERED_EXAMS, {
    client: registrationGraphQLClient,
    skip: !dataInfo?.student?.uid,
    variables: {
      studentUid: dataInfo?.student?.uid
    }
  })

  const registeredExams = data?.getStudentCurrentRegisteredExam?.data


  const { loading: loading, error: error, data: fetchCourses } = useQuery(GET_STUDENT_EXAM_TO_REGISTER, {
    client: registrationGraphQLClient,
    skip: !dataInfo?.student?.uid,
    variables: {
      studentUid: dataInfo?.student?.uid
    }
  })

  let courses = fetchCourses?.getStudentExamToRegister?.data
  let firstSiting = courses?.firstSitting
  let postponed = courses?.postponed?.studentCourseRegistrations
  let failure = courses?.failure?.studentCourseRegistration

  // if(postponed === undefined) postponed = []
  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      courses: courses
    },
    validationSchema: Yup.object().shape({
      // courses: Yup.array().min(1, 'Select at least one Courses')
    }),
    onSubmit: (values, { setSubmitting }) => {
      let registered = []
      // start with first sitting
      for (let f = 0; f < firstSiting?.length; f++) {
        let c = firstSiting[f]
        registered.push({
          type: 1,
          courseRegistrationUid: c.uid
        })
      }
      // postpone
      for (let p = 0; p < postponed?.length; p++) {
        let c = postponed[p]
        registered.push({
          type: 2,
          courseRegistrationUid: c.uid
        })
      }
      // next offered
      for (let p = 0; p < failure?.length; p++) {
        let c = failure[p]
        registered.push({
          type: 3,
          courseRegistrationUid: c.uid
        })
      }
      if (registered.length === 0) {
        setSubmitting(false)
        return showToast("No any Exam to register", "info")
      }
      registerExam({
        client: registrationGraphQLClient,
        variables: {
          inputs: registered
        },
        refetchQueries: [
          {
            query: GET_STUDENT_EXAM_TO_REGISTER,
            variables: {
              studentUid: dataInfo?.student?.uid
            }
          },
          {
            query: GET_STUDENT_REGISTERED_EXAMS,
            variables: {
              studentUid: dataInfo?.student?.uid
            }
          }
        ],
        onCompleted: ({ registerStudentExam }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (registerStudentExam.code === 8000) {
            showToast(registerStudentExam.message, "success")
            setSubmitting(false)
          } else {

            showToast(
              registerStudentExam.message,
              registerStudentExam.code === 8000 ? "success" : "error"
            )
            setSubmitting(false)
          }
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        }
      })
    }
  })

  const toggleDropdown = sem => {
    // console.log(sem)
    setDropdownOpen(prevState => ({
      ...prevState,
      [sem.uid]: !prevState[sem.uid]
    }))
  }


  // const PDFDownload = () => (
  //   <div>
  //     <PDFDownloadLink document={
  //
  //       <PdfHelper styles={pageStyle}>
  //         <ExamTicketTile title="SOKOINE UNIVERSITY OF AGRICULTURE (SUA)" />
  //         <ExamTicketStudentInfo student={dataInfo} />
  //         <View>
  //           <View style={TablePdfStyles.table}>
  //             <View style={TablePdfStyles.tableRow}>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "5%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>S/No</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "35%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Course Name</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "20%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Course Ante</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "10%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Academic Year</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "5%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Study Year</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "5%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Semester</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "10%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Nature</Text>
  //               </View>
  //               <View style={{ ...TablePdfStyles.tableCol, width: "10%" }}>
  //                 <Text style={TablePdfStyles.tableCell}>Type</Text>
  //               </View>
  //             </View>
  //             {registeredExams?.map((c, index) => (
  //               <View key={c.uid} style={TablePdfStyles.tableRow}>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "5%" }}>
  //                   <Text style={TablePdfStyles.tableCell}>{index + 1}</Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "35%" }}>
  //                   <Text
  //                     style={TablePdfStyles.tableCell}>{c?.studentCourseRegistration?.programCourse?.course.name} </Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "20%" }}>
  //                   <Text
  //                     style={TablePdfStyles.tableCell}>{c?.studentCourseRegistration?.programCourse?.course?.code}</Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "10%" }}>
  //                   <Text
  //                     style={TablePdfStyles.tableCell}>{c?.studentCourseRegistration?.programCourse?.programSemester?.academicYear?.name}</Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "5%" }}>
  //                   <Text
  //                     style={TablePdfStyles.tableCell}>{c?.studentCourseRegistration?.programCourse?.programSemester?.studyYear}</Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "5%" }}>
  //                   <Text
  //                     style={TablePdfStyles.tableCell}>{c?.studentCourseRegistration?.programCourse?.programSemester?.semester}</Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "10%" }}>
  //                   <Text
  //                     style={TablePdfStyles.tableCell}>{c?.studentCourseRegistration?.programCourse?.courseCategory?.name}</Text>
  //                 </View>
  //                 <View style={{ ...TablePdfStyles.tableCol, width: "10%" }}>
  //                   <Text style={TablePdfStyles.tableCell}>
  //                     {c?.type === "1" && "First Sitting"}
  //                     {c?.type === 2 && "Postponed"}
  //                     {c?.type === 3 && "Probation"}
  //                     {c?.type === 4 && "Retake"}
  //                   </Text>
  //                 </View>
  //               </View>
  //
  //             ))}
  //
  //           </View>
  //         </View>
  //       </PdfHelper>
  //
  //     } fileName={`${dataInfo?.student?.registrationNumber} examination ticket.pdf`}>
  //       {({ blob, url, loading: los, error }) =>
  //         los ? "Loading document..." : "CLick to download Exam Ticket"
  //       }
  //     </PDFDownloadLink>
  //   </div>
  // )
  console.log(registeredExams)

  return (
    <StudentExamRegistrationContext.Provider value={{ exam, setExam }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Examination Registration">
        {
          <Row>
            <Col lg={12}>
              <Card>
                {
                  loading || registeredLoading ? (
                    <PlaceHolderLoader columSize={12} buttonShown={true} rows={5} />
                  ) : (
                    <>
                      {registeredExams?.length > 0 ?
                        <CardBody>
                          <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                              <thead>
                              <tr>
                                <th>S/No</th>
                                <th>Course Name</th>
                                <th>Course Ante</th>
                                <th>Academic Year</th>
                                <th>Study Year</th>
                                <th>Semester</th>
                                <th>Nature</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {registeredExams?.map((c, index) => (
                                <tr key={c?.studentCourseRegistration?.programCourse?.course?.name}>
                                  <td align="center">{index + 1}</td>
                                  <td align="left">{c?.studentCourseRegistration?.programCourse?.course?.name}</td>
                                  <td align="left">{c?.studentCourseRegistration?.programCourse?.course?.code}</td>
                                  <td
                                    align="left">{c?.studentCourseRegistration?.programCourse?.programSemester?.academicYear?.name}</td>
                                  <td
                                    align="left">{c?.studentCourseRegistration?.programCourse?.programSemester?.studyYear}</td>
                                  <td
                                    align="left">{c?.studentCourseRegistration?.programCourse?.programSemester?.semester}</td>
                                  <td
                                    align="left">{c?.studentCourseRegistration?.programCourse?.courseCategory?.name}</td>
                                  <td align="left">
                                    {c?.type === 1 && "First Sitting"}
                                    {c?.type === 2 && "Postponed"}
                                    {c?.type === 3 && "Probation"}
                                    {c?.type === 4 && "Retake"}
                                  </td>
                                  <td>
                                    <Dropdown
                                      isOpen={dropdownOpen[c?.studentCourseRegistration?.uid]}
                                      toggle={() => toggleDropdown(c?.studentCourseRegistration)}
                                    >
                                      <DropdownToggle color="" caret>
                                        <i
                                          className="fas fa-ellipsis-v"
                                          style={{ color: "blue" }}
                                        />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => {
                                            setExam(c?.studentCourseRegistration)
                                          }}
                                        >

                                          <ExamPostponementForm />
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </td>
                                </tr>

                              ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="float-end">
                            {/*<strong>{PDFDownload()}</strong>*/}
                          </div>
                        </CardBody> : ""
                      }
                      {registeredExams?.length === 0 && (!registeredLoading || !loading) ?
                        <CardBody>
                          {(postponed || firstSiting || failure) && <>
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped">

                                <tbody>
                                {firstSiting?.map((c, index) => (
                                  <>
                                    {
                                      index === 0 && <>
                                        <tr key={`index-${c?.uid}`}>
                                          <td colSpan={7} align="center"><strong>FIRST SITTING EXAMINATIONS</strong></td>
                                        </tr>
                                        <tr>
                                          <th>S/No</th>
                                          <th>Course Name</th>
                                          <th>Course Ante</th>
                                          <th>Academic Year</th>
                                          <th>Study Year</th>
                                          <th>Semester</th>
                                          <th>Nature</th>
                                        </tr>
                                      </>
                                    }
                                    <tr key={c?.programCourse?.course?.name}>
                                      <td align="center">{index + 1}</td>
                                      <td align="left">{c?.programCourse?.course?.name}</td>
                                      <td align="left">{c?.programCourse?.course?.code}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.academicYear?.name}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.studyYear}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.semester}</td>
                                      <td align="left">{c?.programCourse?.courseCategory?.name}</td>
                                    </tr>
                                  </>
                                ))}

                                {postponed?.map((c, index) => (
                                  <>
                                    {
                                      index === 0 && <>
                                        <tr key={`post-${c.uid}`}>
                                          <td colSpan={7} align="center"><strong>PROBATION EXAMINATIONS</strong></td>
                                        </tr>
                                        <tr>
                                          <th>S/No</th>
                                          <th>Course Name</th>
                                          <th>Course Ante</th>
                                          <th>Academic Year</th>
                                          <th>Study Year</th>
                                          <th>Semester</th>
                                          <th>Nature</th>
                                        </tr>
                                      </>
                                    }
                                    <tr key={c?.programCourse?.course?.name}>
                                      <td align="center">{index + 1}</td>
                                      <td align="left">{c?.programCourse?.course?.name}</td>
                                      <td align="left">{c?.programCourse?.course?.code}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.academicYear?.name}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.studyYear}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.semester}</td>
                                      <td align="left">{c?.programCourse?.courseCategory?.name}</td>
                                    </tr>
                                  </>
                                ))}

                                {failure?.map((c, index) => (
                                  <>
                                    {
                                      index === 0 && <>
                                        <tr key={`fail-${c.uid}`}>
                                          <td colSpan={7} align="center"><strong>PROBATION EXAMINATIONS</strong></td>
                                        </tr>
                                        <tr>
                                          <th>S/No</th>
                                          <th>Course Name</th>
                                          <th>Course Ante</th>
                                          <th>Academic Year</th>
                                          <th>Study Year</th>
                                          <th>Semester</th>
                                          <th>Nature</th>
                                        </tr>
                                      </>
                                    }
                                    <tr key={c?.programCourse?.course?.name}>
                                      <td align="center">{index + 1}</td>
                                      <td align="left">{c?.programCourse?.course?.name}</td>
                                      <td align="left">{c?.programCourse?.course?.code}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.academicYear?.name}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.studyYear}</td>
                                      <td align="left">{c?.programCourse?.programSemester?.semester}</td>
                                      <td align="left">{c?.programCourse?.courseCategory?.name}</td>
                                    </tr>
                                  </>
                                ))}

                                </tbody>
                              </table>
                            </div>

                            {(postponed || firstSiting || failure) ?
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  validationType.handleSubmit()
                                  return false
                                }}>
                                {validationType.errors.courses ? (
                                  <FormFeedback type="invalid">
                                    {validationType.errors.courses}
                                  </FormFeedback>
                                ) : null}
                                {
                                  registeredExams?.length < 1
                                }
                                <div className="float-end">
                                  <SubmitButtonHelper type={"primary"} name={"Register Examination Courses"}
                                                      formik={validationType} />
                                </div>
                              </Form>
                              :
                              <AlertHelper type={"danger"} message={"No any course available for exam"} />

                            }
                          </>

                          }

                        </CardBody> : (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validationType.handleSubmit()
                              return false
                            }}>
                            {validationType.errors.courses ? (
                              <FormFeedback type="invalid">
                                {validationType.errors.courses}
                              </FormFeedback>
                            ) : null}
                            <div className="text-center p-3">
                              <SubmitButtonHelper btnSize={`small`} type={"primary"} name={"Re-register Courses"}
                                                  formik={validationType} />
                            </div>
                          </Form>
                        )
                      }
                    </>
                  )
                }
              </Card>
            </Col>
          </Row>
        }
      </LayoutHelper>
    </StudentExamRegistrationContext.Provider>
  )
}

export default StudentExamRegistration