import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import {limit} from "../../../helpers/UrlHelper";
import {useNavigate} from "react-router-dom";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_DEPARTMENT} from "../../Departments/Queries/DepartmentQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import {GET_PROGRAM_CATEGORIES} from "../../ProgramCategories/Queries/ProgramCategoryQueries";
import {CREATE_PROGRAM} from "../Mutations/ProgramMutations";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../helpers/ToastHelper";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import Select from "react-select";
import {ProgramContext} from "./ProgramLIst";
import {GET_PROGRAMS} from "../Queries/ProgramQuaries";

const ProgramFormModel = props => {
    let {program} = props
    const [modalOpen, setModalOpen] = useState(false)
    const pagination = { offset: 0, limit: limit, search: null },
        navigate = useNavigate(),{proData,setProData} = useContext(ProgramContext)
    program = proData
    // console.log(program)
    const {
        loading: depLoading,
        error,
        data: depart,
    } = useQuery(GET_DEPARTMENT, {
        client: uaaGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })

    const {
        loading: catLoading,
        error: catError,
        data: catData,
    } = useQuery(GET_PROGRAM_CATEGORIES, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })

    // if (error) return showToast("Failed to fetch Departments", "error")
    let departments = depart?.getDepartments?.data?.items
    // if (catError) return showToast("Failed to fetch Program Categories", "error")
    let categories = catData?.getProgramCategories?.data?.items

    const [
        searchDepartments,
        { loading: depSearchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_DEPARTMENT, {client: uaaGraphQLClient})

    const [
        searchCategory,
        { loading: catSearchLoading, error: catSearchError, data: searchCat },
    ] = useLazyQuery(GET_PROGRAM_CATEGORIES, {client: registrationGraphQLClient})
    if (searchData?.getDepartments?.data)
        departments = searchData?.getDepartments?.data?.items
    if (searchCat?.getProgramCategories?.data)
        categories = searchCat?.getProgramCategories?.data?.items
    const searchDepartment = value => {
        let data = {
            search: value,
        }
        if (value.length >= 2) {
            searchDepartments({
                variables: { pagination: { ...pagination, ...data } },
            })
        }
    }
    const [programSave] = useMutation(CREATE_PROGRAM,{client:registrationGraphQLClient})
    const searchCategories = value => {
        let data = {
            search: value,
        }
        if (value.length >= 2) {
            searchCategory({
                variables: { pagination: { ...pagination, ...data } },
            })
        }
    }


    // return 1
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            code: program?.code,
            tcuCode: program?.tcuCode,
            nacteCode: program?.nacteCode,
            name: program?.name,
            shortName: program?.shortName,
            duration: program?.duration,
            registrationCode: program?.registrationCode,
            programCategoryUid: program?.programCategory?.uid,
            departmentUid: program?.departmentUid,
        },

        validationSchema: Yup.object().shape({
            name: Yup.string().required("Program name is required"),
            code: Yup.string().required("Program code is required"),
            registrationCode: Yup.string().required(
                "Program Registration code is required"
            ),
            shortName: Yup.string().required("Program Short name is required"),
            programCategoryUid: Yup.string().required("Program Type is required"),
            // // departmentUid: Yup.number().required("Program Department is required"),
            duration: Yup.number()
                .min(1)
                .max(5)
                .required("Program Duration is required"),
        }),

        onSubmit: (values,{setSubmitting}) => {
            // console.log({...values})
            programSave({
                client: registrationGraphQLClient,
                variables: {
                    inputs: [{ ...values,uid: program?.uid }],
                },
                refetchQueries: [
                    {
                        query: GET_PROGRAMS,
                        variables: {
                            pagination: pagination,
                        },
                    },
                ],
                onCompleted: ({ registerProgram }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (registerProgram.code === 8000) {
                        showToast(registerProgram.message, "success")
                        validation.resetForm()
                        navigate("/programs/view",{state:{program:registerProgram.data.items[0]}})
                    } else {
                        showToast(
                            registerProgram.message,
                            registerProgram.code === 8000 ? "success" : "error"
                        )
                        setSubmitting(false)

                    }
                },
                onError: error => {
                    setSubmitting(false)
                    validation.resetForm()
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
        },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Program
            </Button>
            <Modal
                isOpen={modalOpen || !!proData}
                backdrop={"static"}
                id="staticBackdrop"
                size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!proData ? "Edit Program" : "Add New Program"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <Row>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label for="name">Program name</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        autoFocus={true}
                                        className="form-control"
                                        id="name"
                                        placeholder="Enter Program Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            !!(validation.touched.name && validation.errors.name)
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.name}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="shortName">Program Short Name</Label>
                                    <Input
                                        type="text"
                                        name="shortName"
                                        autoFocus={true}
                                        className="form-control"
                                        id="shortName"
                                        placeholder="Enter Program Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.shortName || ""}
                                        invalid={
                                            !!(
                                                validation.touched.shortName &&
                                                validation.errors.shortName
                                            )
                                        }
                                    />
                                    {validation.touched.shortName &&
                                    validation.errors.shortName ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.shortName}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="code">Program Code</Label>
                                    <Input
                                        type="text"
                                        name="code"
                                        className="form-control"
                                        id="code"
                                        placeholder="Enter Program Code"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.code || ""}
                                        invalid={
                                            !!(validation.touched.code && validation.errors.code)
                                        }
                                    />
                                    {validation.touched.code && validation.errors.code ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.code}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="nacteCode">NACTE Code</Label>
                                    <Input
                                        name="nacteCode"
                                        placeholder="Enter Description"
                                        type="text"
                                        id="nacteCode"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.nacteCode || ""}
                                        invalid={
                                            !!(
                                                validation.touched.nacteCode &&
                                                validation.errors.nacteCode
                                            )
                                        }
                                    />
                                    {validation.touched.nacteCode &&
                                    validation.errors.nacteCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.nacteCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="tcuCode">TCU Code</Label>
                                    <Input
                                        name="tcuCode"
                                        placeholder="Enter Description"
                                        type="text"
                                        id="tcuCode"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.tcuCode || ""}
                                        invalid={
                                            !!(
                                                validation.touched.tcuCode &&
                                                validation.errors.tcuCode
                                            )
                                        }
                                    />
                                    {validation.touched.tcuCode &&
                                    validation.errors.tcuCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.tcuCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="registrationCode">Registration Code</Label>
                                    <Input
                                        name="registrationCode"
                                        placeholder="Enter Program Code"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.registrationCode || ""}
                                        invalid={
                                            !!(
                                                validation.touched.registrationCode &&
                                                validation.errors.registrationCode
                                            )
                                        }
                                    />
                                    {validation.touched.registrationCode &&
                                    validation.errors.registrationCode ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.registrationCode}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3">
                                    <Label for="duration">Program Duration</Label>
                                    <Input
                                        name="duration"
                                        placeholder="Enter Programme Duration"
                                        type="number"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.duration || ""}
                                        invalid={
                                            !!(
                                                validation.touched.duration &&
                                                validation.errors.duration
                                            )
                                        }
                                    />
                                    {validation.touched.duration &&
                                    validation.errors.duration ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.duration}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Program Category</Label>
                                    <Select
                                        styles={{
                                            menu: base => ({
                                                ...base,
                                                position: "absolute",
                                                zIndex: 9999
                                            })
                                        }}
                                        // value={selectedMulti2}
                                        onChange={e => {
                                            // validation.values.programCategoryUid =e.value
                                            validation.setFieldValue(
                                                "programCategoryUid",
                                                e.value
                                            )
                                        }}
                                        onInputChange={e => {
                                            searchCategories(e)
                                        }}
                                        defaultValue={program?.programCategoryUid}
                                        defaultInputValue={program?.programCategory?.name}
                                        options={categories?.map(cat => ({
                                            value: cat.uid,
                                            label: cat.name + " (" + cat.shortName + ")",
                                        }))}
                                        className="select2-selection"
                                        isLoading={catLoading || catSearchLoading}
                                    />
                                    <Input
                                        name="programCategoryUid"
                                        placeholder=""
                                        type="hidden"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.programCategoryUid || ""}
                                        invalid={
                                            !!(
                                                validation.touched.programCategoryUid &&
                                                validation.errors.programCategoryUid
                                            )
                                        }
                                    />
                                    {validation.touched.programCategoryUid &&
                                    validation.errors.programCategoryUid ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.programCategoryUid}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                    <Label>Program Department</Label>
                                    <Select
                                        // value={selectedMulti2}
                                        styles={{
                                            menu: base => ({
                                                ...base,
                                                position: "absolute",
                                                zIndex: 9999
                                            })
                                        }}
                                        onChange={e => {
                                            validation.setFieldValue("departmentUid", e.value)
                                        }}
                                        onInputChange={e => {
                                            searchDepartment(e)
                                        }}
                                        options={departments?.map(dep => ({
                                            value: dep.uid,
                                            label: dep.name + " (" + dep.code + ")",
                                        }))}
                                        className="select2-selection"
                                        isLoading={depLoading || depSearchLoading}
                                    />
                                    <Input
                                        name="departmentUid"
                                        placeholder=""
                                        type="hidden"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.departmentUid || ""}
                                        invalid={
                                            !!(
                                                validation.touched.departmentUid &&
                                                validation.errors.departmentUid
                                            )
                                        }
                                    />
                                    {validation.touched.departmentUid &&
                                    validation.errors.departmentUid ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.departmentUid}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setProData(false)
                                validation.resetForm();
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>

            </Modal>
        </div>
    )
}
ProgramFormModel.propTypes = {
    program:PropTypes.array.isRequired
}

export default ProgramFormModel;
