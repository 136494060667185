import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_SPONSORS } from "../Queries/SponsorQueries"
import { DELETE_SPONSOR } from "../Mutations/SponsorMutations"
import SponsorModal from "./SponsorModal"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const breadcrumbItem = [
  {
    title: "Sponsor List",
    path: "",
    isActive: true,
  },
]

export const SponsorContext = createContext() //Create data to transfer to child modal

const SponsorList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedSponsor, setSelectedSponsor] = useState(null) //Get data for deleting
  const [updateSponsorData, setUpdateSponsorData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_SPONSORS, {
    client:uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const [searchSponsor,{ loading: searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_SPONSORS,{
    client:uaaGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected;
   // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteSponsor] = useMutation(DELETE_SPONSOR, {
    variables: { uid: selectedSponsor?.uid },
    client:uaaGraphQLClient,
    refetchQueries: [
      {
        query: GET_SPONSORS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteSponsor = () => {
    showDeleteWarning(() => {
      performSponsorDeletion()
    })
  }

  const performSponsorDeletion = async () => {
    try {
      const response = await deleteSponsor()
      showToast(response.data.removeSponsor.message, "success")
      console.log("User deleted successfully:", response)
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
    )
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchSponsor({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }
  const Sponsor = data?.getSponsors.data?.items
  const searchedSponsor = searchData?.getSponsors?.data?.items
  const result = searchedSponsor ? searchedSponsor : Sponsor
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getSponsors.data.totalCount || searchData?.getSponsors?.data?.items || 0

  console.log(Sponsor)
  return (
    <SponsorContext.Provider value={{ updateSponsorData, setUpdateSponsorData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Sponsor List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <SponsorModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Code</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            .slice(startIndex, endIndex)
                            .map((Sponsor, index) => (
                              <Tr key={Sponsor?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{Sponsor?.code}</Td>
                                <Td>{Sponsor?.name}</Td>
                                <Td>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      setUpdateSponsorData(Sponsor)
                                    }}
                                  >
                                    <i className="bx bxs-edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      setSelectedSponsor(Sponsor)
                                      handleDeleteSponsor()
                                    }}
                                  >
                                    <i className="bx bxs-trash"></i>
                                  </button>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </SponsorContext.Provider>
  )
}

export default SponsorList
