import { gql } from "@apollo/client"

const CREATE_COLLEGE = gql`
  mutation RegisterUnits($inputs: [UnitDtoInput!]!) {
    registerUnits(inputs: $inputs) {
      code
      message
      data{
        items
        {
          name
          code
          campus{
          name
          }
        }
      }
    }
  }
`

const DELETE_COLLEGE = gql`
  mutation RemoveUnit($uid: String!) {
    removeUnit(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_COLLEGE, DELETE_COLLEGE }
