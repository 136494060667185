import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import {
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { StaffContext } from "./UsersList"
import { GET_DEPARTMENT } from "Modules/Departments/Queries/DepartmentQueries"
import { GET_STAFF } from "../Queries/UserQuery"
import { CREATE_STAFF } from "../Mutations/UserMutations"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const StaffModal = () => {
  const { updateStaffData, setUpdateStaffData } = useContext(StaffContext)
  const [Staff] = useMutation(CREATE_STAFF)
  const [modalOpen, setModalOpen] = useState(false)
  const [genders, setGender] = useState([])

  const { loading, error, data } = useQuery(GET_DEPARTMENT, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  // if (error) return showToast("Failed to fetch Departments", "error")
  const departments = data?.getDepartments?.data?.items

  const avaiableGender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ]

  useEffect(() => {
    // fetch Gender
    setGender(avaiableGender)
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: updateStaffData ? updateStaffData?.user?.firstName : "",
      lastName: updateStaffData ? updateStaffData?.user?.lastName : "",
      email: updateStaffData ? updateStaffData?.user?.email : "",
      phoneNumber: updateStaffData ? updateStaffData?.user?.phoneNumber : "",
      departmentUid: updateStaffData ? updateStaffData?.user?.department?.uid : "",
      gender: updateStaffData ? updateStaffData?.gender : "",
      middleName: updateStaffData ? updateStaffData?.user?.middleName : "",
      pfNumber: updateStaffData ? updateStaffData?.pfNumber : "",
    },

    validationSchema: Yup.object().shape({
      departmentUid: Yup.string().required("Department is required"),
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      pfNumber: Yup.string().required("PF Number is required"),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required"),
      phoneNumber: Yup.string()
        .min(10)
        .max(13)
        .required("Please Enter Valid Phone Number"),
      gender: Yup.string().required("Gender is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      Staff({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateStaffData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_STAFF,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerStaff }) => {
          //updateFunction(client.cache, { data: { registerStaffs } });
          if (registerStaff.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateStaffData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerStaff.message,
            registerStaff.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Staff
      </Button>
      <Modal
        size="lg"
        isOpen={modalOpen || !!updateStaffData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateStaffData ? "Edit Staff" : "Add New Staff"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateStaffData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
            <Row>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="firstName">
                    First Name:
                  </label>
                  <Input
                    type="text"
                    name="firstName"
                    className="form-control"
                    id="firstName"
                    placeholder="Enter First Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.firstName ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.firstName &&
                        validation.errors.firstName
                      )
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <Label for="middleName">Middle Name</Label>
                  <Input
                    type="text"
                    name="middleName"
                    className="form-control"
                    id="middleName"
                    placeholder="Enter middle name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.middleName ||
                      ""}
                  />
                </div>
              </Col>
            </Row>
            </div>
           

            <Row>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <label htmlFor="lastName">
                    Last Name:
                  </label>
                  <Input
                    type="text"
                    name="lastName"
                    className="form-control"
                    id="lastName"
                    placeholder="Enter Last Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.lastName ||
                      ""
                    }
                  />
                   {validation.touched.lastName &&
                  validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label for="middlename">Phone Number</Label>
                  <Input
                    type="text"
                    name="phoneNumber"
                    className="form-control"
                    id="phoneNumber"
                    placeholder="Enter Phone Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phoneNumber ||
                      ""}
                    invalid={
                      !!(
                        validation.touched.phoneNumber &&
                        validation.errors.phoneNumber
                      )
                    }
                  />
                  {validation.touched.phoneNumber &&
                  validation.errors.phoneNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phoneNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label htmlFor="pfNumber">
                    PF Number:
                  </label>
                  <Input
                    type="text"
                    name="pfNumber"
                    className="form-control"
                    id="pfNumber"
                    placeholder="Enter PF Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.pfNumber ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.pfNumber &&
                        validation.errors.pfNumber
                      )
                    }
                  />
                  {validation.touched.pfNumber && validation.errors.pfNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.pfNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg="6" md="6">
                <div className="mb-3">
                  <Label for="firstname">Email:</Label>
                  <Input
                    type="text"
                    name="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      !!(validation.touched.email && validation.errors.email)
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6" lg="6">
                <div className="mb-3">
                  <label>Select Department</label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("departmentUid", e.value)
                    }}
                    options={departments?.map(dp => ({
                      value: dp.uid,
                      label: dp.name,
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    defaultValue={updateStaffData?.user?.departmentUid}
                    defaultInputValue={updateStaffData?.user?.department?.name}
                  />
                  <Input
                    name="departmentUid"
                    placeholder="Select Department"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.uid || updateStaffData?.user?.department?.uid || ""}
                    invalid={
                      !!(
                        validation.touched.departmentUid &&
                        validation.errors.departmentUid
                      )
                    }
                  />
                  {validation.touched.departmentUid &&
                  validation.errors.departmentUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.departmentUid}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg="6" md="6">
                <div className="mb-3">
                  <label>Select Gender</label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("gender", e.value)
                    }}
                    options={genders}
                    className="select2-selection"
                    defaultValue={updateStaffData?.gender}
                    defaultInputValue={updateStaffData?.gender}
                  />
                  <Input
                    name="gender"
                    placeholder="Select Gender"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.gender || updateStaffData?.gender || ""
                    }
                    invalid={
                      !!(validation.touched.gender && validation.errors.gender)
                    }
                  />
                  {validation.touched.gender && validation.errors.gender ? (
                    <FormFeedback type="invalid">
                      {validation.errors.gender}
                    </FormFeedback> 
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateStaffData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper type="primary" name="Submit" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}
export default StaffModal
