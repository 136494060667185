import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_CAMPUS } from "../Mutations/CampusMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_CAMPUS } from "../Queries/CampusQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { CampusContext } from "./CampusList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const CampusModal = () => {
  const { updateCampusData, setUpdateCampusData } = useContext(CampusContext)
  const [campus] = useMutation(CREATE_CAMPUS)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_CAMPUS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateCampusData ? updateCampusData.name : "",
      code: updateCampusData ? updateCampusData.code : "",
      description: updateCampusData ? updateCampusData.description : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Campus name is required"),
      code: Yup.string().required("Campus short name is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      campus({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateCampusData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_CAMPUS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerCampuses }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (registerCampuses.code === 8000) {
             validation.resetForm()
            setModalOpen(false)
            setUpdateCampusData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateCampusData(false) 
            resetForm(false)
            //!validation.isSubmitting;
            // setUpdateCampusData(true) // Shikilia modal
          }
          showToast(
            registerCampuses.message,
            registerCampuses.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Campus
      </Button>
      <Modal
        isOpen={modalOpen || !!updateCampusData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateCampusData ? "Edit Campus" : "Add New Campus"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                invalid={
                  !!(validation.touched.name && validation.errors.name)
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="firstname"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ""}
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                name="description"
                id="message-text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                onInvalid={() => {
                  validation.touched.description &&
                  validation.errors.description
                    ? true
                    : false
                }}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateCampusData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default CampusModal
