import React from "react"
import { Document, Page } from "@react-pdf/renderer"
import PropTypes from "prop-types"

export const convertFileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result.split(",")[1])
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}

const PdfHelper = props => {
  const { styles } = props
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {props.children}
      </Page>
    </Document>
  )
}

PdfHelper.propTypes = {
  styles: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
}
export default { PdfHelper, convertFileToBase64 }
