import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_CONSULTANCIES } from "../Mutations/ConsultanciesMutations"
import { GET_CONSULTANCIES } from "../Queries/ConsultanciesQueries"
import { ConsultanciesContext } from "./Consultancies"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import moment from "moment"

const ConsultanciesModal = () => {
  const { updateConsultanciesData, setUpdateConsultanciesData, staffUid } =
    useContext(ConsultanciesContext)

  const RoleType = [
    {
      label: "Teaching",
      value: 1,
    },
    {
      label: "Research",
      value: 2,
    },
    {
      label: "Administration",
      value: 3,
    },
  ]

  const RoleCategory = [
    {
      label: "Lecturer",
      value: 1,
    },
    {
      label: "Instructor",
      value: 1,
    },
    {
      label: "Teaching Assistant",
      value: 1,
    },
    {
      label: "Research Associate",
      value: 2,
    },
    {
      label: "Research Assistant",
      value: 2,
    },
    {
      label: "Postdoctoral Researcher",
      value: 2,
    },
    {
      label: "Visiting Appointment",
      value: 3,
    },
    {
      label: "Administrative Appointment",
      value: 3,
    },
    {
      label: "Temporary or Term Appointment",
      value: 3,
    },
  ]

  const [Consultancies] = useMutation(ADD_UPDATE_CONSULTANCIES)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_CONSULTANCIES
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      uid: updateConsultanciesData ? updateConsultanciesData.uid : "",
      startDate: updateConsultanciesData
        ? moment(updateConsultanciesData.startDate).format("YYYY-MM-DD")
        : "",
      endDate: updateConsultanciesData
        ? moment(updateConsultanciesData.endDate).format("YYYY-MM-DD")
        : "",
      title: updateConsultanciesData ? updateConsultanciesData.title : "",
      location: updateConsultanciesData ? updateConsultanciesData.location : "",
      client: updateConsultanciesData ? updateConsultanciesData.client : "",
      description: updateConsultanciesData
        ? updateConsultanciesData.description
        : "",
      consultancyRegistrationLetter: updateConsultanciesData
        ? updateConsultanciesData.consultancyRegistrationLetter
        : "",
      consultancyClientLetter: updateConsultanciesData
        ? updateConsultanciesData.consultancyClientLetter
        : "",
    },

    validationSchema: Yup.object().shape({
      consultancyRegistrationLetter: Yup.mixed().required(
        "Consultancy Registration Letter is required",
      ),
      consultancyClientLetter: Yup.mixed().required(
        "Consultancy Client Letter is required",
      ),
      startDate: Yup.string().required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
      description: Yup.string().required("Description is required"),
      location: Yup.string().required("Location Type is required"),
      client: Yup.string().required("Client is required"),
      title: Yup.string().required("Title is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      console.log(values)

      if (values.uid === "") {
        delete values.uid
      }

      Consultancies({
        client: asimsGraphQLClient,
        variables: {
          inputs: {
            ...values,
            appointmentTitleUid: values.appointmentTitleUid.toString(),
            appointmentTypeUid: values.appointmentTypeUid.toString(),
            appointingOfficeUid: values.appointingOfficeUid.toString(),
            consultancyClientLetter:
              values.consultancyClientLetter instanceof File
                ? values.consultancyClientLetter
                : null,
            consultancyRegistrationLetter:
              values.consultancyRegistrationLetter instanceof File
                ? values.consultancyRegistrationLetter
                : null,
          },
        },
        refetchQueries: [
          {
            query: GET_CONSULTANCIES,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerConsultancies }) => {
          validation.resetForm()
          setModalOpen(false)
          setUpdateConsultanciesData(false) // Close the modal
          if (registerConsultancies.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateConsultanciesData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerConsultancies.message,
            registerConsultancies.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error.message)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Consultancy
      </Button>

      <Modal
        isOpen={modalOpen || !!updateConsultanciesData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-country" id="staticBackdropLabel">
            {!!updateConsultanciesData ? "EDIT CONSULTANCY" : "ADD CONSULTANCY"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateConsultanciesData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <h5 className="form-details-header">Appointment Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointmentTypeUid"
                      className="col-form-label"
                    >
                      Appointment Type:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("appointmentTypeUid", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateConsultanciesData?.appointmentType?.uid
                      }
                      defaultInputValue={
                        updateConsultanciesData?.appointmentType?.name
                      }
                      options={RoleCategory}
                      className="select2-selection"
                    />
                    <Input
                      name="appointmentTypeUid"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appointmentTypeUid || ""}
                      invalid={
                        !!(
                          validation.touched.appointmentTypeUid &&
                          validation.errors.appointmentTypeUid
                        )
                      }
                    />
                    {validation.touched.appointmentTypeUid &&
                    validation.errors.appointmentTypeUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointmentTypeUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointingOfficeUid"
                      className="col-form-label"
                    >
                      Appointment Office:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("appointingOfficeUid", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateConsultanciesData?.appointingOffice?.uid
                      }
                      defaultInputValue={
                        updateConsultanciesData?.appointingOffice?.name
                      }
                      options={RoleType}
                      className="select2-selection"
                    />
                    <Input
                      name="appointingOfficeUid"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appointingOfficeUid || ""}
                      invalid={
                        !!(
                          validation.touched.appointingOfficeUid &&
                          validation.errors.appointingOfficeUid
                        )
                      }
                    />
                    {validation.touched.appointingOfficeUid &&
                    validation.errors.appointingOfficeUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointingOfficeUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="client" className="col-form-label">
                      Client:
                    </label>
                    <Input
                      type="text"
                      name="client"
                      className="form-control"
                      id="client"
                      placeholder="Enter Client Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.client || ""}
                      invalid={
                        !!(
                          validation.touched.client && validation.errors.client
                        )
                      }
                    />
                    {validation.touched.client && validation.errors.client ? (
                      <FormFeedback type="invalid">
                        {validation.errors.client}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointmentLetter"
                      className="col-form-label"
                    >
                      Appointment Letter:
                    </label>
                    <Input
                      type="file" // Set the type to "file" for file input
                      name="appointmentLetter"
                      className="form-control"
                      id="appointmentLetter"
                      onChange={event => {
                        validation.setFieldValue(
                          "appointmentLetter",
                          event.currentTarget.files[0],
                        )
                      }}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.appointmentLetter &&
                          validation.errors.appointmentLetter
                        )
                      }
                    />
                    {validation.touched.appointmentLetter &&
                    validation.errors.appointmentLetter ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointmentLetter}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="12" lg="12">
                  <div className="mb-3">
                    <label
                      htmlFor="responsibilities"
                      className="col-form-label"
                    >
                      Responsibilities:
                    </label>
                    <textarea
                      name="responsibilities"
                      className="form-control"
                      id="responsibilities"
                      placeholder="Enter Responsibilities"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.responsibilities || ""}
                      onInvalid={
                        !!(
                          validation.touched.responsibilities &&
                          validation.errors.responsibilities
                        )
                      }
                    />
                    {validation.touched.responsibilities &&
                    validation.errors.responsibilities ? (
                      <FormFeedback type="invalid">
                        {validation.errors.responsibilities}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Client Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="client" className="col-form-label">
                      Client:
                    </label>
                    <Input
                      type="text"
                      name="client"
                      className="form-control"
                      id="client"
                      placeholder="Enter Client Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.client || ""}
                      invalid={
                        !!(
                          validation.touched.client && validation.errors.client
                        )
                      }
                    />
                    {validation.touched.client && validation.errors.client ? (
                      <FormFeedback type="invalid">
                        {validation.errors.client}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="consultancyClientLetter"
                      className="col-form-label"
                    >
                      Consultancy Client Letter:
                    </label>
                    <Input
                      type="file"
                      name="consultancyClientLetter"
                      className="form-control"
                      id="consultancyClientLetter"
                      onChange={event => {
                        validation.setFieldValue(
                          "consultancyClientLetter",
                          event.currentTarget.files[0],
                        )
                      }}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.consultancyClientLetter &&
                          validation.errors.consultancyClientLetter
                        )
                      }
                    />
                    {validation.touched.consultancyClientLetter &&
                    validation.errors.consultancyClientLetter ? (
                      <FormFeedback type="invalid">
                        {validation.errors.consultancyClientLetter}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Consultancy Period</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="startDate" className="col-form-label">
                      Start Date:
                    </label>
                    <Input
                      type="date"
                      name="startDate"
                      className="form-control"
                      id="startDate"
                      placeholder="Enter Start Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      invalid={
                        !!(
                          validation.touched.startDate &&
                          validation.errors.startDate
                        )
                      }
                    />
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="endDate" className="col-form-label">
                      End Date:
                    </label>
                    <Input
                      type="date"
                      name="endDate"
                      className="form-control"
                      id="endDate"
                      placeholder="Enter End Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      invalid={
                        !!(
                          validation.touched.endDate &&
                          validation.errors.endDate
                        )
                      }
                    />
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateConsultanciesData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ConsultanciesModal
