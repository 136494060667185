//REGISTE
import { gql } from '@apollo/client';

const GET_HEADSHIP_TYPE = gql`
query getHeadshipType{
  getHeadshipType{
    message
    data
  }
}
`;

const GET_HEADSHIPS = gql`
query getHeadships($pagination: PaginationInput!){
  getHeadships(pagination: $pagination){
    status
    code
    message
    data{
      totalCount
      items{
        uid
        type
        isActive
        startDate
        endDate
        group {
          uid
          name
        }
        user{
          id
          uid
          firstName
          lastName
          email
          username
          department{
            id
            uid
            name
            code
            unit{
              id
              uid
              name
              code
              description
              campus{
                name
                id
                uid
                name
                code
                description
              }
            }
          }
        }
      }
    }
  }
}
`;

const GET_HEADSHIP_ORGANS = gql`
query GetHeadshipOrigins($headshipType : String!) {
    getHeadshipOrigins(headshipType: $headshipType){
        status
        code
        message
        data{
          name
          uid
        }
  }
}
`;

export { GET_HEADSHIP_TYPE, GET_HEADSHIP_ORGANS, GET_HEADSHIPS }
