import React, {useEffect, useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import CourseWorkResultTable from "./CourseWorkResultTable";
import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {VIEW_COURSE_WORKS} from "../../Queries/Examination/StudentCourseWorkQueries";
import showToast from "../../../../helpers/ToastHelper";
import {sr2URL} from "../../../../helpers/UrlHelper";
import toastHelper from "../../../../helpers/ToastHelper";
import {Sr2CheckPaymentHelper} from "../../../../helpers/Sr2CheckPaymentHelper";
const breadcrumbItem =  [
    {
        title: 'Coursework',
        path: '',
        isActive: true,
    },
]
const StudentCoursework = () => {
    const userData = JSON.parse(localStorage.getItem("authUser"))
    const dataInfo = userData?.data
    const studentData = dataInfo?.student

    // console.log(studentData)

    const studyYear = [];

    for (let i = 1; i <= studentData?.studyYear; i++) {
        studyYear.push({ value: i, label: `Year ${i}` });
    }

    const [semesters,setSemesters] = useState([])
    const [buttonName,setButtonName] = useState('View')

    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })
    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    const [
        searchCourseWorks,
        { loading: courseWorkLoading, error: courseWorkError, data: courseWorkData },
    ] = useLazyQuery(VIEW_COURSE_WORKS, {client: registrationGraphQLClient})

    // const payments = Sr2CheckPaymentHelper(studentData?.registrationNumber)
    // console.log(payments)
    // console.log(studentData?.registrationNumber)
    // Form validation
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            studentUid: studentData?.uid,
            studyYear: "",
            semester:"",
            academicYearUid:""
        },
        validationSchema: Yup.object().shape({
            studyYear: Yup.number().required("Study year is required"),
            semester: Yup.number().required("Semester is required"),
            academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setButtonName('Validate Payments')
            fetch(sr2URL + "students/payment_status?registration_number="+studentData?.registrationNumber+"&balance="+true, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                // body: JSON.stringify({ registration_number: registration_number }),
            }).then(response => response.json()).then(data => {
                if(data.status !== 200){
                    setButtonName('View')
                    toastHelper(data.message,'error')
                    setSubmitting(false)
                }else if (data.status === 200){
                    setButtonName('Fetching Course Works')
                    searchCourseWorks({
                        variables: {
                            input:values
                        },
                        fetchPolicy: "no-cache",
                        onCompleted: ({ getStudentActiveSemesterCourseWorkResults }) => {
                            showToast(
                                getStudentActiveSemesterCourseWorkResults.message,
                                getStudentActiveSemesterCourseWorkResults.code === 8000 ? "success" : "error"
                            )
                            setSubmitting(false)
                            setButtonName('View')
                        },
                        onError: error => {
                            console.error("Mutation error:", error)
                            setSubmitting(false)
                            setButtonName('View')
                        },
                    })
                }
            })
            .catch((error) => {
                // alert("wewwrw")
                // toastHelper('An error occurred while making the request.','error')
                console.error('Error submitting data:', error);
                return false
            });
        },

    });

    const search = (value,type) => {
        // console.log(type)
        // console.log(validation.values.programUid)
        let data = {
            search: value,
        }

        if (type === 'academicYear'){
            if(value !== undefined) {
                if (value.length < 4) return false
                searchAcademicYear({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }
        }
    }
    const pagination = { offset: 0, limit: 20, search: null }
    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items

    let courseWorks = courseWorkData?.getStudentActiveSemesterCourseWorkResults?.data
    // console.log(courseWorks)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Coursework Results">
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validationType.handleSubmit()
                                    return false
                                }}
                            >
                            <Row>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label>Academic Year</Label>
                                        <Select
                                            // value={selectedGroup}
                                            onChange={(e) => {
                                                if (e) {
                                                    validationType.setFieldValue("academicYearUid", e.value)
                                                } else {
                                                    validationType.setFieldValue("academicYearUid", "")
                                                }
                                            }}
                                            onInputChange={e => {
                                                search(e, 'academicYear')
                                            }}
                                            options={academicYears?.map(year => ({
                                                value: year.uid,
                                                label: year.name,
                                            }))}

                                            className="select2-selection"
                                            isLoading={academicYearLoading || yearSearchLoading}
                                            isClearable={true}
                                        />
                                        <Input
                                            name="academicYearUid"
                                            type="hidden"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.academicYearUid || ""}
                                            invalid={
                                                !!(validationType.touched.academicYearUid && validationType.errors.academicYearUid)
                                            }
                                        />
                                        {validationType.touched.academicYearUid && validationType.errors.academicYearUid ? (
                                            <FormFeedback type="invalid">
                                                {validationType.errors.academicYearUid}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label>Study Year</Label>
                                        <Select
                                            // value={selectedGroup}
                                            // name="studyYear"
                                            onChange={(e) => {
                                                validationType.setFieldValue('studyYear', e.value)
                                                let sem = []
                                                setSemesters([])
                                                // console.log(e.value)
                                                let value = e.value * 2
                                                for (let i = (e.value * 2) - 1; i < value + 1; i++) {
                                                    sem.push({label: 'Semester ' + i, value: i})
                                                }
                                                setSemesters(sem)
                                            }}
                                            options={studyYear}
                                            className="select2-selection"
                                        />
                                        <Input
                                            name="studyYear"
                                            type="hidden"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.studyYear || ""}
                                            invalid={
                                                !!(validationType.touched.studyYear && validationType.errors.studyYear)
                                            }
                                        />
                                        {validationType.touched.studyYear && validationType.errors.studyYear ? (
                                            <FormFeedback type="invalid">
                                                {validationType.errors.studyYear}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="mb-3">
                                        <Label>Semester</Label>
                                        <Select
                                            // value={selectedGroup}
                                            name="semester"
                                            onChange={(e) => {
                                                validationType.setFieldValue('semester', e.value)

                                            }}
                                            options={semesters}
                                            className="select2-selection"
                                        />

                                        <Input
                                            name="studyYear"
                                            type="hidden"
                                            onChange={validationType.handleChange}
                                            onBlur={validationType.handleBlur}
                                            value={validationType.values.semester || ""}
                                            invalid={
                                                !!(validationType.touched.semester && validationType.errors.semester)
                                            }
                                        />
                                        {validationType.touched.semester && validationType.errors.semester ? (
                                            <FormFeedback type="invalid">
                                                {validationType.errors.semester}
                                            </FormFeedback>
                                        ) : null}
                                    </div>

                                </Col>


                            </Row>

                                <SubmitButtonHelper type={'primary'} name={buttonName} formik={validationType}/>
                            </Form>
                        </CardBody>
                    </Card>


                    <CourseWorkResultTable courseWorks={courseWorks} isLoading={courseWorkLoading}/>
                </Col>

            </Row>
        </LayoutHelper>
    );
}

export default StudentCoursework;