import React, {useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {limit} from "../../../../helpers/UrlHelper";
import {GET_ALL_PROGRAMS, GET_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_STATUS, GET_STATUS_NAME} from "../../../Lookups/Status/Queries/StatusQueries";
import ToastHelper from "../../../../helpers/ToastHelper";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../helpers/PaginationHelper";
import {GenerateTablePDF} from "../../../../helpers/PdfTableHelper";
import {GET_CHANGE_PROGRAM_STUDENT_LIST} from "../Queries/StudentReportQueries";

const breadcrumbItem =  [
    {
        title: 'Change Program Report',
        path: '',
        isActive: true,
    },
]
const StudentChangeProgramReport = () => {

    const { loading:reportLoading, error:reportError, data:reportData } = useQuery(GET_CHANGE_PROGRAM_STUDENT_LIST, {
        client: registrationGraphQLClient,
    })


    let data = reportData?.getStudentProgramChangeReport?.data?.items

    const downloadList = () => {
        const rep = approved ? 'Approved':''
        let title = `First Year Undergraduate Students Change Program ${rep} Request Report`
        let programData = []
        const columns = ['S/No','Student Name','Registration Number', 'Current Program','New Program',approved ? 'Approval Remark' : 'Reason', 'Status'];
        if(data){
            for (let f = 0; f < data?.length; f++){
                let s = data[f]
                programData.push([
                    f+1,s?.fullName,s?.currentRegistrationNumber,s?.currentProgram?.name+' ('+s?.currentProgram?.code+')',s?.newProgram?.name+' ('+s?.newProgram?.code+')', approved ? s?.approveRemark : s?.reason, s?.approveStatus
                ])
            }

        }
        // console.log(studentData)
        GenerateTablePDF(columns,programData,title,[],'l')
    }
    // Form validation
    const [approved, setApproved] = useState(false)

    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student List">
            <Row>
                <Col lg={12}>

                    <Card>
                        <CardBody>
                            {
                                data && <Button className="btn btn-success btn-sm font-weight-bold" onClick={downloadList}>
                                    <><i className="fa fa-file-pdf"></i> Generate PDF</>
                                </Button>

                            }
                            {
                                data && (
                                    <div className="float-end">
                                        <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="isReserved"
                                            onClick={e => {
                                                setApproved(!approved)
                                            }}
                                        /> {' '}
                                        <label
                                            className="form-check-label"
                                            htmlFor="isReserved"
                                            style={{ color: 'green' }}
                                        >
                                            View Approved Program Change Report
                                        
                                        </label>

                                    </div>
                                )
                            }

                            {reportLoading ? (
                                <PlaceHolderLoader type="table" columSize={12} rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >



                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th data-priority="0">Student Name</Th>
                                                    <Th data-priority="1">Registration Number</Th>
                                                    <Th data-priority="3">Current Program</Th>
                                                    <Th data-priority="3">New Program</Th>
                                                    <Th data-priority="3">{ approved ? 'Approval Remark':'Reason'}</Th>
                                                    <Th data-priority="3">Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {data?.map((std, index) => (
                                                    <Tr key={index}>
                                                        <Td>{index + 1}</Td>
                                                        <Td>{std?.fullName}</Td>
                                                        <Td>{std?.currentRegistrationNumber}</Td>
                                                        <Td>{std?.currentProgram?.name} ({std?.currentProgram?.code})</Td>
                                                        <Td>{std?.newProgram?.name} ({std?.newProgram?.code})</Td>
                                                        <Td>{ approved ? std?.approveRemark :  std?.reason}</Td>
                                                        <Td>{std?.approveStatus}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default StudentChangeProgramReport;