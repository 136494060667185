import { gql } from "apollo-boost"

const GET_STAFF_CATEGORIES = gql`
  query GetStaffCategory($pagination: PaginationInput!) {
    getStaffCategories(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
        }
        totalCount
      }
    }
  }
`

export { GET_STAFF_CATEGORIES }
