import { gql } from "@apollo/client"

const CREATE_THESIS_SETTING = gql`
  mutation RegisterIntentionToSubmitRequirement($inputs: [IntentionToSubmitRequirementInput!]!) {
    registerIntentionToSubmitRequirement(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const DELETE_THESIS_SETTING = gql`
  mutation RemoveIntentionToSubmitRequirement($uid: String!) {
    removeIntentionToSubmitRequirement(uid: $uid) {
      message
      code
      status
    }
  }
`


export { CREATE_THESIS_SETTING, DELETE_THESIS_SETTING }
