import { Link } from "react-router-dom"
import React from "react"

const SettingsLinks = ({ hasAuthority }) => {
  return (
    <>
      <li>
        <Link to="/system-settings/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      {hasAuthority("VIEW_SYSTEM_SETTINGS_MENU") && (
        <>
          {hasAuthority("VIEW_CAMPUSES") && (
            <li>
              <Link to="/system-settings/campuses">
                <i className="bx bx-buildings"></i>
                <span>Campus</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_COLLEGE_MENU") && (
            <li>
              <Link to="/system-settings/colleges">
                <i className="bx bx-calendar-event"></i>
                <span>College/Schools</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_DEPARTMENT_MENU") && (
            <li>
              <Link to="/system-settings/departments">
                <i className="bx bx-building-house"></i>
                <span>Departments</span>
              </Link>
            </li>
          )}



          {hasAuthority("VIEW_COUNTRIES") && (
            <li>
              <Link to="/system-settings/country">
                <i className="bx bx-globe"></i>
                <span>Countries</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_WARD_MENU") && (
            <li>
              <Link to="/system-settings/wards">
                <i className="bx bx-map"></i>
                <span>Wards</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_KINSHIP_MENU") && (
            <li>
              <Link to="/system-settings/lookups/kinship">
                <i className="bx bx-list-check"></i>
                <span>Kinship</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_MARITAL_STATUS_MENU") && (
            <li>
              <Link to="/system-settings/marital-status">
                <i className="bx bx-heart"></i>
                <span>Marital Statuses</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_DESIGNATION_MENU") && (
            <li>
              <Link to="/system-settings/lookups/disignations">
                <i className="bx bx bx-briefcase"></i>
                <span>Designation</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_STAFF_TITLE_MENU") && (
            <li>
              <Link to="/system-settings/lookups/staff-title">
                <i className="bx bx-id-card"></i>
                <span>Staff Title</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_DISABILITY_MENU") && (
            <li>
              <Link to="/system-settings/lookups/dissabilities">
                <i className="bx bx-accessibility"></i>
                <span>Disability</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_DEACTIVATION_MENU") && (
            <li>
              <Link to="/system-settings/lookups/deactivate-reasons">
                <i className="bx bx-x-circle"></i>
                <span>Deactivate Reasons</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_DEACTIVATION_MENU") && (
            <li>
              <Link to="/system-settings/lookups/postponement-reasons">
                <i className="bx bx-time-five"></i>
                <span>Postponement Reasons</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_BANKS_MENU") && (
            <li>
              <Link to="/system-settings/banks">
                <i className="bx bx-building"></i>
                <span>Banks</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_SPONSOR_MENU") && (
            <li>
              <Link to="/system-settings/sponsors">
                <i className="bx bx-money"></i>
                <span>Sponsors</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_SYSTEM_SETTINGS_MENU") && (
            <li>
              <Link to="/system-settings/lookups/seminar-type">
                <i className="bx bx-microphone"></i>
                <span>Seminar type</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_SYSTEM_SETTINGS_MENU") && (
            <li>
              <Link to="/system-settings/lookups/thesis-settng">
                <i className="bx bx-cog"></i>
                <span>Thesis Setting</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_SYSTEM_SETTINGS_MENU") && (
            <li>
              <Link to="/system-settings/workflow">
                <i className="bx bx-network-chart"></i>
                <span>Workflows</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_SYSTEM_SETTINGS_MENU") && (
            <li>
              <Link to="/system-settings/state">
                <i className="bx bx-flag"></i>
                <span>States</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_SYSTEM_SETTINGS_MENU") && (
            <li>
              <Link to="/system-settings/lookups/bylaws">
                <i className="bx bx-male"></i>
                <span>Bylaws</span>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default SettingsLinks
