import React, {Fragment} from "react";
import {StyleSheet, Text, View} from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        textAlign:"center",
        alignItems: 'center', // Center-align content vertically
        justifyContent: 'center', // Center-align content horizontally
    },
    reportTitle:{
        fontWeight:"bold",
        fontSize: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});

const ExamTicketTile = ({title}) => (
    <Fragment>
        <View style={styles.titleContainer}>
            <Text style={styles.reportTitle}>{title}</Text>
        </View>
    </Fragment>
);

export default ExamTicketTile