import { color } from "d3-color"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import MoodleUrlComponent from "./MoodleUrlComponent"




const StudentCourseList = ({ courseData }) => {


  return (
    <React.Fragment>
      <Row>
      <Col xl={12} xs={12} sm={12} md={12} lg={12}>
        <Card className="text-center primary">
          <CardBody>
{/* 
          <Link
                to={`/instructor/course_allocations/${courseData?.uid}`}
                className="text-dark"
              > */}
            <h5 className="font-size-15 mb-0">
                <b>{courseData?.programCourse?.course?.code}</b>
            </h5>

            <p className="text-muted">
              {courseData?.programCourse?.course?.name}
            </p>
            <p className="text-dark">
                {courseData?.programCourse?.programSemester?.program?.name}
            </p>
            {/* </Link> */}

            {courseData?.programCourse?.course?.moodleId !== null ? (

              <p className="text-muted">

              <div>

              
                      <MoodleUrlComponent courseData={courseData} key={courseData?.programCourse?.course?.moodleId} moodleId={courseData?.programCourse?.course?.moodleId} />
                      {/* <MoodleUrlComponent courseData_={courseData} /> */}
              </div>

                </p>
            ) : (
              <p style={{ color: 'red' }}>Not yet linked with Moodle</p>
            )}
            
           
          </CardBody>
        </Card>
      </Col>
      </Row>
    </React.Fragment>
  )
}

export default StudentCourseList
