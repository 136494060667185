//REGISTE
import { gql } from "@apollo/client"

const GET_USER_NEXT_OF_KIN = gql`
  query GetUserNextOfKins($userUid: String!) {
    getUserNextOfKins(userUid: $userUid) {
      status
      code
      message
      data {
        uid
        fullName
        phoneNumber
        email
        placeOfDomicile
        kinship {
          uid
          name
        }
      }
    }
  }
`

const DELETE_USER_NEXT_OF_KIN = gql`
mutation RemoveUserNextOfKin($uid: String!) {
  removeUserNextOfKin(uid: $uid) {
    message
    code
    status
  }
}`
export { GET_USER_NEXT_OF_KIN, DELETE_USER_NEXT_OF_KIN }
