import { Link } from "react-router-dom"
import React from "react"

const ExaminationAndResultsLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_EXAM_RESULTS_MENU") && (
        <>


          {hasAuthority("VIEW_SENATE_MENU") && (
              <>
              {hasAuthority("VIEW_PUBLISH_RESULT_MENU") && (
                <li>
                  <Link to="/examination/senate/publish_exam_results">
                    <i className="bx bx-home-circle"></i>
                    <span> Publish Exam results</span>
                  </Link>
                </li>
                  )}
              </>
          )}



        </>
      )}
    </>
  )
}

export default ExaminationAndResultsLinks
