// export default StatusList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import SeminarTypeModal from "./SeminarTypeModal"
import {DELETE_SEMINAR_TYPE} from "../Mutations/SeminarTypeMutations"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import {GET_SEMINAR_TYPE} from "../../../Students/Views/Dissertation/Queries/StudentDissertationQueries";

const breadcrumbItem = [
  {
    title: "Seminar Type",
    path: "",
    isActive: true,
  },
]

export const SeminarTypeContext = createContext() //Create data to transfer to child modal

const SeminarTypeList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const pagination = { offset: 0, limit: limit, search: null }

  const [selectedStatus, setSelectedStatus] = useState(null) //Get data for deleting
  const [updateSeminarTypeData, setupdateSeminarTypeData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_SEMINAR_TYPE, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
  })

  const [
    searchSeminarType,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_SEMINAR_TYPE, {
    client: registrationGraphQLClient,
  })



  const [deleteSeminarType] = useMutation(DELETE_SEMINAR_TYPE, {
    client: registrationGraphQLClient,
    variables: { uid: selectedStatus?.uid },
    refetchQueries: [
      {
        query: GET_SEMINAR_TYPE,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteSeminarType = status => {
    setSelectedStatus(status)
    showDeleteWarning(() => {
      performDeletion()
    })
  }

  const performDeletion = async () => {
    try {
      const response = await deleteSeminarType()
      showToast(response.data.removeSeminarType.message, response.data.removeSeminarType.code === 8000 ? "success" : "error")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }


  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = () => {
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchSeminarType({ variables: { pagination: { ...pagination, ...data } } })
  }


  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      fetchData()
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }

  const seminarTypes = data?.getSeminarTypes.data?.items
  const searchedSeminarTypes = searchData?.getSeminarTypes?.data?.items
  const results = searchedSeminarTypes ? searchedSeminarTypes : seminarTypes
  return (
    <SeminarTypeContext.Provider value={{ updateSeminarTypeData, setupdateSeminarTypeData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Seminar Type List">
        <Row>
          <Col lg={8}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <SeminarTypeModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                              <Th data-priority="2">Rank</Th>
                            <Th data-priority="2">Name</Th>
                            <Th data-priority="2">Description</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>

                          {results.map((st, index) => (
                              <Tr key={st?.uid}>
                                <Td>{index + 1}</Td>
                                <Td>{st?.rank}</Td>
                                <Td>{st?.name}</Td>
                                <Td>{st?.description}</Td>
                                <Td>
                                  <DropDownActionHelper
                                    data={st}
                                    onUpdate={setupdateSeminarTypeData}
                                    onDelete={() => handleDeleteSeminarType()}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </SeminarTypeContext.Provider>
  )
}

export default SeminarTypeList
