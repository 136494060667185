import PropTypes from "prop-types"
import React, { useState } from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"

import { connect } from "react-redux"
// Reactstrap
import {Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row} from "reactstrap"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import "assets/scss/sidebar.scss";
//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from "../../store/actions"
import Services from "../CommonForBoth/TopbarDropdown/Services"
import UserManuals from "../CommonForBoth/TopbarDropdown/Usermanuals"
import LOGO from "../../assets/images/logo-dark.png"
import {ServicesCards} from "./ESBServices";
import Card from "../Common/Card";
import * as userAuthorities from "react-table/src/filterTypes";
import StudentIdIcon from "../../assets/images/svg/id.png";
import {jwtDecode} from "jwt-decode";

const Header = props => {
  const location = useLocation()
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []
  const hasAuthority = authority => userAuthorities.includes(authority)
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  const activePath = location.pathname.split('/')[1];

  const link = ServicesCards.filter(card => card.link.startsWith(`/${activePath}/`));
  // console.log(link)
  const filteredLinks = link.length > 0 ? link[0]: {
    image: StudentIdIcon,
    link: location.pathname,
    title: activePath,
    shortName: activePath,
    authority: null
  }

  const visibleSystems = ServicesCards.filter(system =>
      userAuthorities.includes(system.authority)
  );
  let toPath = '/'
  if(visibleSystems.length === 1) {
    toPath = visibleSystems[0].link
  }

  return (
    <React.Fragment>
      <div style={{ position: "sticky", top: 0, zIndex: 1000 }}>
        <header id="page-topbar esb-page-topbar">
          <div className="navbar-header shadow p-3 bg-white rounded">
            <div className="d-flex">
              <Link
                  to={toPath}
                  className="d-flex align-items-center mb-2 mb-lg-0 link-body-emphasis text-decoration-none header-logo"
              >
                <img src={LOGO} width="auto" height="40" alt="sua-esb"/>
              </Link>

              <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"/>
              </button>

              <UserManuals/>


              <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={megaMenu}
                  toggle={() => {
                    setmegaMenu(!megaMenu)
                  }}
              ></Dropdown>
            </div>

            <div className="d-flex">
              {activePath !== 'esb-dashboard' &&
                  <Services activePath={activePath} pathName={location.pathname}/>
              }
            </div>

            <div className="d-flex">
              <Dropdown
                  className="d-none d-lg-inline-block ms-1"
                  isOpen={socialDrp}
                  toggle={() => {
                    setsocialDrp(!socialDrp);
                  }}
              >
                <DropdownToggle
                    title={"SWITCH SERVICE"}
                    className="btn header-item noti-icon "
                    tag="button"
                >
                  <i className="bx bx-customize"/>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      {ServicesCards.map((card, index) => {
                        if (card.authority && !hasAuthority(card.authority)) {
                          return null;
                        }
                        return (
                            <Col title={card.title} key={index} lg={4}>
                              <Link className="dropdown-icon-item" style={{
                                backgroundColor: filteredLinks.link === card.link ? "#D5E3D5":""
                              }} onClick={() => {
                                if(filteredLinks.link !== card.link)
                                  window.location.href = card.link;
                              }} to="#">
                                <img src={card.image} alt={card.title}/>
                                <span>{card.shortName}</span>
                              </Link>
                            </Col>
                        );
                      })}

                    </Row>

                  </div>
                </DropdownMenu>
              </Dropdown>

              <ProfileMenu/>
            </div>
          </div>
        </header>
      </div>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
