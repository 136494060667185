import React, { useContext, useEffect, useState } from "react"
import {
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "./NhifRegistration" 
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { REGISTER_NHIF } from "../Mutations/NHIFMutation"

const NhifPersonalInformationTab = () => {
  const { StudentData } = useContext(StudentProfileContext)

  const { toggleTab } = useContext(StudentProfileContext)
  const [isSubmitted] = useState(false)
  const [studentDissability, setStudentDissability] = useState(false)

  const [NHIFMutation] = useMutation(REGISTER_NHIF, {
    client: uaaGraphQLClient,
  })

  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(2) // Move to the next tab
    } else {
      toggleTab(1) // Move to the next tab
    }
  }

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const onSubmit = e => {
    e.preventDefault()
    validation.handleSubmit()
    return null
  } 


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      studentUid: StudentData?.student?.uid,

    },

    onSubmit: ( { resetForm }) => {

      NHIFMutation({
        client: uaaGraphQLClient,
        variables: {
          studentUid: StudentData?.student?.uid,
        },
        onCompleted: ({ registerNhif }) => {
          if (registerNhif.code === 8000) {
            toggleTab(2)
            console.log("Data updated successsfully")
          } else {
            console.log("Failed to update data")
          }
          showToast(
            registerNhif.message,
            registerNhif.code === 8000 ? "success" : "error"
          )
          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="wizard">
          <div className="text-center">
            <ul className="list-unstyled">
            
              <li className="next">
                <SubmitButtonHelper
                  type="primary"
                  name="Request NHIF Control Number"
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default NhifPersonalInformationTab
