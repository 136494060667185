import React from "react"
import PropTypes from 'prop-types'
import {Link, useLocation, useNavigate} from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const {breadcrumbItem } = props
  const location = useLocation(), navigate = useNavigate()
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{document.title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              {breadcrumbItem.map((item, key) => (
                <BreadcrumbItem key={key} active={item.isActive}>
                  {
                    !item.isActive ? <Link state={location?.state} to={item.path}>{item.title}</Link>:
                        <span>{item.title}</span>


                  }
                </BreadcrumbItem>
              ))}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.array,
  title: PropTypes.string
}

export default Breadcrumb
