import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, Button,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import PropTypes from "prop-types"
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import StudentExamPostponementForm from "./StudentExamPostponementForm";
const breadcrumbItem =  [
    {
        title: 'Exam Postponement',
        path: '',
        isActive: true,
    },
]


const StudentExamPostponement = props => {
    const [requests, setRequests] = useState([]),
        [type,SetType] = useState(''),
        {uuid} = props,
        [fetching, setFetching] = useState(true)

    useEffect(() => {
        // setFetching(true)
        setFetching(false)
        setTimeout(() => {
            fetchData()
        }, 1000);
    }, [])
    const fetchData = (page = 1) =>{
        setTimeout(() =>{
            // Fetch data Api
            setFetching(false)
            setRequests([])

        },1000)
    }

    function closeButton(){
        return  (
            <Button type={'button'} onClick={
                event => {
                    SetType('')
                }
            } >Cancel</Button>
        )
    }

    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Exam Postponement Requests">
            { type === 'create' ? <>
                <StudentExamPostponementForm closeBtn={closeButton()} />
            </>: type === 'update'  ? <StudentExamPostponementForm closeBtn={closeButton()} />:
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardSubtitle className="mb-1">
                                    <Row>
                                        <Col className="col-6">
                                        </Col>
                                        <Col className="col-6">
                                            <div className="text-sm-end mt-3">
                                                <Button color="primary" onClick={
                                                    event => {
                                                        SetType('create')

                                                        // console.log(type)
                                                    }
                                                }>Request New <i className="bx bxs-plus-circle"></i></Button>
                                            </div>
                                        </Col>
                                        {/*/Students/Payments/services*/}
                                    </Row>
                                </CardSubtitle>

                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>#</Th>
                                                    <Th data-priority="0">New Program</Th>
                                                    <Th data-priority="1">Reason</Th>
                                                    <Th data-priority="2">Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td>1</Td>
                                                    <Th>
                                                        Bsc. Informatics
                                                    </Th>
                                                    <Td>Hii course mliyo nipangia saivi sio ya ndoto yangu nahitaji course mpya nitimize mlengo yangu</Td>
                                                    <Td>Received</Td>
                                                </Tr>

                                            </Tbody>
                                        </Table>


                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </LayoutHelper>
    );
};

StudentExamPostponement.propType = {
    uuid:PropTypes.string
}
export default StudentExamPostponement;