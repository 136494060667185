import React, { useState } from "react"
import { ThreeDots } from "react-loader-spinner"
import { useLazyQuery } from "@apollo/client" // Use useLazyQuery

import { GET_COURSE_MOODLE_URL } from "../Queries/InstructorCoursesQueries"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { Col, Row } from "reactstrap"
import showToast from "helpers/ToastHelper"

const MoodleUrlComponent = ({ moodleId }) => {
  const [isLoading, setIsLoading] = useState(false)

  // Define the useLazyQuery for fetching Moodle course URL
  const [
    fetchMoodleURL,
    { loading: MoodleCourseURLLoading, data: MoodleCourseURLData },
  ] = useLazyQuery(GET_COURSE_MOODLE_URL, {
    client: registrationGraphQLClient,
    fetchPolicy: "network-only", // Ensure that the query is not cached
    onCompleted: data => {
      if (data?.getMoodleUrl?.code === 8000) {
        const url = data?.getMoodleUrl?.data
        if (url) {
          window.open(url, "_blank") // Open URL in a new tab
          setIsLoading(false) // Reset isLoading state
        }
       }
       showToast(
        data?.getMoodleUrl?.message,
        data?.getMoodleUrl?.code === 8000 ? "success" : "error"
        )
        setIsLoading(false) // Reset isLoading state
    },
  })

  const handleLinkClick = () => {
    setIsLoading(true) // Start loading
    fetchMoodleURL({
      variables: {
        inputs: {
          courseMoodleId: moodleId,
        },
      },
    })
  }

  return (
    <div style={{ textAlign: "center" }}>
      {moodleId !== null ? (
        <p className="text-muted">
          <b>
            <a  onClick={handleLinkClick} style={{ cursor: "pointer", color: "green" }}>
              View Course in Moodle
            </a>
          </b>
        </p>
      ) : (
        <p style={{ color: "red" }}>Not yet linked with Moodle</p>
      )}
      {isLoading && (
        <Row>
          <Col md={5}></Col>
          <Col md={2}>
            <ThreeDots color={"green"} height={50} width={50} />
          </Col>
          <Col md={5}></Col>
        </Row>
      )}
    </div>
  )
}

export default MoodleUrlComponent
