//REGISTE
import { gql } from '@apollo/client';


const GET_PROGRAM_COURSES = gql`
query GetProgramCourses($pagination: PaginationInput!) {
  getProgramCourses(pagination: $pagination){
    status
    code
    message
    data{
      items{
        uid,
        programSemester 
        {uid
          studyYear
          coreCredits
          electiveCredits
          program{
            name
            code
          }
          academicYear{
            name
            startDate
            endDate
          }
          semester        
        },
        course{uid,name,code},
        courseCategory{uid,name},
        credit
        lectureHours,seminarHours,practicalHours,assignmentHours,independentStudyHours
        passHours
      }totalCount
    }
  }
}
`;

const GET_PROGRAM_COURSES_UID = gql`
query GetProgramCourseByUid($uid: String!) {
  getProgramCourse (uid : $uid){
    status
    code
    message
    data{   
        uid,
        programSemester {
          uid,
          program {
            uid,
            code,
            name,
            tcuCode,
            nacteCode,
            duration,
            registrationCode
          }
          academicYear {
            uid
            name
          }
          studyYear,
          semester,
          coreCredits,
          electiveCredits
          
        }
      course {
        uid,
        code,
        name
      }
      credit,
      lectureHours,
      seminarHours,
      practicalHours,
      assignmentHours,
      independentStudyHours,
      passHours,
      programCourseAssessments {
        uid,
        minimumExams,
        canExceedMinimumBy,
        maximumScore,
        
      }
    }
  }
}
`;

const  GET_PROGRAM_COURSE_BY_PROGRAM_SEMESTER_UID= gql`
query ProgramCourseBySemesterUid ( $programSemisterUid : String!)
{
  getProgramCourseByProgramSemesterUid (programSemesterUid : $programSemisterUid) 
  {
    status,
    code
    message,
    data {
      totalCount
      items {
        uid
        programSemester {
          uid, 
          program {
            uid,
            name
            code
          }       
        }
        course {
          uid
          code
          name
        }
        courseCategory {
          uid
          name
        }
      }
    }
  }
}
`

export { GET_PROGRAM_COURSES, GET_PROGRAM_COURSES_UID, GET_PROGRAM_COURSE_BY_PROGRAM_SEMESTER_UID }