const statistical_data = {
  companay: [30, 48, 28, 74, 39, 87, 54, 36, 50, 87, 84],
  newjobs: [20, 50, 42, 10, 24, 28, 60, 35, 47, 64, 78],
  totaljobs: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
  jobview: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
  label: [
    "01/01/2023",
    "02/01/2022",
    "03/01/2021",
    "04/01/2020",
    "05/01/2019",
    "06/01/2018",
    "07/01/2017",
    "08/01/2016",
    "09/01/2015",
    "10/01/2014",
    "11/01/2013",
  ],
}

export default statistical_data
