import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Button, Card, CardBody, CardHeader, CardText, CardTitle, Form, Modal} from "reactstrap";
import {useMutation} from "@apollo/client";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../../../helpers/ToastHelper";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../../helpers/PaginationHelper";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import {EntryQualificationConext} from "../EntryQualification";
import {CREATE_APPLICANT_CERTIFICATE} from "../../../Mutations/ApplicantMutation";
import {onlineApplicationGraphQLClient} from "../../../../Authentication/ApolloClient";
import {GET_APPLICANT_CERTIFICATE_RESULT} from "../../../Queries/ApplicantQueries";
const AVNResultConfirmation = (props) => {
    const {formik} = props
    const [create] = useMutation(CREATE_APPLICANT_CERTIFICATE)
    const {setAVNOpen,AVNOpen, applicant, user, avnResult} = useContext(EntryQualificationConext)
    // console.log(formData.values)
    return(
        <Modal
            isOpen={AVNOpen}
            backdrop={"static"}
            id="staticBackdrop"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    CONFIRM AVN RESULTS
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setAVNOpen(false)
                        formik.resetForm()
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <Form
                onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return null
                }}
            >
                <div className="modal-body">

                    <Card outline color="success" className="border">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0 text-success">
                                <i className="mdi mdi-account-check me-3" />

                                {avnResult?.firstName} {" "} {avnResult?.middleName} {" "} {avnResult?.lastName}
                                {" "} ({avnResult.sex})
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <CardTitle className="mt-0">Results</CardTitle>
                            <CardText>
                                <strong>AVN Number: </strong> {avnResult?.avn} <br/>
                                <strong>Institution Name: </strong> {avnResult?.centerName} <br/>
                                <strong>Programme: </strong> {avnResult?.programmeName} <br/>
                                <strong>Registration Number: </strong> {avnResult?.registrationNumber} <br/>
                                <strong>Completion Year: </strong> {avnResult?.completionYear} <br/>
                                <strong>Division: </strong> {avnResult?.division} <br/>
                                <strong>GPA: </strong> {avnResult?.gpa} <br/>
                                <strong>GPA Classification: </strong> {avnResult?.gpaClassification}

                                <div className="text-center mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setAVNOpen(false)
                                            formik.resetForm()
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    {" "}
                                    <SubmitButtonHelper name="Confirm Result" type="primary" formik={formik}/>
                                </div>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>


            </Form>
        </Modal>
    )
}

AVNResultConfirmation.propTypes = {
    // open: PropTypes.bool.isRequired,
    formik: PropTypes.any.isRequired
};
export default AVNResultConfirmation

