import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  Card,
  CardBody
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { ApplicantContext } from "./ApplicantAccountRegistration"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { token } from "helpers/UrlHelper"
import {
  onlineApplicationGraphQLClient,
  uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import { GET_COUNTRY } from "Modules/Country/Queries/CountryQueries"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { GET_NECTA_APPLICANT_PARTICULAR } from "../Queries/AcountRegistrationQueries"
import { REGISTER_APPLICANT } from "../Mutations/AccountRegistrationMutations"
import { ApplicantContextForPostgraduate } from "./Postgraduate"
import { ApplicantContextForUndergraduate } from "./Undergraduate"

const ApplicantAccountRegistrationFirstStep = () => {
  const location = useLocation()
  const [registerApplicant] = useMutation(REGISTER_APPLICANT, {
    client: onlineApplicationGraphQLClient
  })

  const navigate = useNavigate()

  const context =
    useContext(ApplicantContextForPostgraduate) ||
    useContext(ApplicantContextForUndergraduate)
  const {
    tcuResponce,
    setTcuResponse,
    stepOnePayload,
    setStepOnePayload,
    toggleTab
  } = context

  const [isSubmitted] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectedLabel, setSelectedLabel] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [selectedCitizenship, setSelectedCitizenship] = useState(
    stepOnePayload?.citizenship ? stepOnePayload?.citizenship : null
  )
  const [selectedVerification, setSelectedVerification] = useState(
    stepOnePayload?.isOlevelCertificateVerified
      ? stepOnePayload?.isOlevelCertificateVerified
      : null
  )
  const [IsStudiedInTanzania, setIsStudiedInTanzania] = useState(
    stepOnePayload?.isApplicantStudiedInTanzania
      ? stepOnePayload?.isApplicantStudiedInTanzania
      : null
  )
  // Get Countries
  const {
    loading: countryLoading,
    error: countryError,
    data: countryData
  } = useQuery(GET_COUNTRY, {
    client: uaaGraphQLClient,
    skip: selectedCitizenship !== "No",
    variables: {
      pagination: paginationHelper
    }
  })

  const countries = countryData?.getCountries?.data?.items

  const citizenshipOptions = [
    {
      label: "Yes",
      value: "Yes"
    },
    {
      label: "No",
      value: "No"
    }
  ]

  const isOlevelCertificateVerifiedOptions = [
    {
      label: "Yes",
      value: "Yes"
    },
    {
      label: "No",
      value: "No"
    }
  ]

  const studiedTanzaniaOptions = [
    {
      label: "Yes",
      value: "Yes"
    },
    {
      label: "No",
      value: "No"
    }
  ]

  const genders = [
    {
      label: "Male",
      value: "Male"
    },
    {
      label: "Female",
      value: "Female"
    }
  ]

  useEffect(() => {
    const user = token
    setSelectedLabel(selectedValue ? "Yes" : "No")
  }, [token, selectedValue])

  const handleNext = () => {
    if (isSubmitted) {
      toggleTab(2) // Move to the next tab
    } else {
      toggleTab(1) // Move to the next tab
    }
  }

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const onSubmit = e => {
    e.preventDefault()
    validation.handleSubmit()
    return null
  }

  const [
    refetchApplcantData,
    { loading: applicanDataLoading, error: dataError, data: applicantData }
  ] = useLazyQuery(GET_NECTA_APPLICANT_PARTICULAR, {
    client: onlineApplicationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only"
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      citizenship: stepOnePayload?.citizenship
        ? stepOnePayload?.citizenship
        : "",
      gender: stepOnePayload?.gender ? stepOnePayload?.gender : "",
      countryCode: stepOnePayload?.countryCode
        ? stepOnePayload?.countryCode
        : "",
      formFourIndexNo: stepOnePayload?.formFourIndexNo
        ? stepOnePayload?.formFourIndexNo
        : "",
      formFourEquivalentNumber: stepOnePayload?.formFourEquivalentNumber
        ? stepOnePayload?.formFourEquivalentNumber
        : "",
      isApplicantStudiedInTanzania: stepOnePayload?.isApplicantStudiedInTanzania
        ? stepOnePayload?.isApplicantStudiedInTanzania
        : "",
      isOlevelCertificateVerified: stepOnePayload?.isOlevelCertificateVerified
        ? stepOnePayload?.isOlevelCertificateVerified
        : "",
      firstName: stepOnePayload?.firstName ? stepOnePayload?.firstName : "",
      identificationNumber: stepOnePayload?.identificationNumber
        ? stepOnePayload?.identificationNumber
        : "",
      middleName: stepOnePayload?.middleName ? stepOnePayload?.middleName : "",
      lastName: stepOnePayload?.lastName ? stepOnePayload?.lastName : "",
      email: stepOnePayload?.email ? stepOnePayload?.email : "",
      phoneNumber: stepOnePayload?.phoneNumber
        ? stepOnePayload?.phoneNumber
        : ""
    },

    validationSchema: Yup.object().shape({
      citizenship: Yup.string().required("Your citizenship is required"),
      formFourIndexNo:
        IsStudiedInTanzania === "Yes" &&
        Yup.string().required("Your Form Number is required"),
      isOlevelCertificateVerified:
        IsStudiedInTanzania === "No" &&
        Yup.string().required("This field is required"),
      formFourEquivalentNumber:
        selectedVerification === "Yes" &&
        Yup.string().required("This field is required"),
      firstName:
        selectedCitizenship === "No" &&
        Yup.string().required("This field is required"),
      middleName:
        selectedCitizenship === "No" &&
        Yup.string().nullable("This field is required"),
      lastName:
        selectedCitizenship === "No" &&
        Yup.string().required("This field is required"),
      identificationNumber:
        selectedCitizenship === "No" &&
        Yup.string().required("This field is required"),
      email:
        selectedCitizenship === "No" &&
        Yup.string().required("This field is required"),
      countryCode:
        selectedCitizenship === "No" &&
        Yup.string().required("This field is required"),
      gender:
        selectedCitizenship === "No" &&
        Yup.string().required("This field is required"),
      phoneNumber:
        selectedCitizenship === "No" &&
        Yup.string()
          .min(13, "Phone number must be valid")
          .max(13, "Phone number must be valid")
          .nullable()
          .required("Please enter a valid phone number")
          .matches(/^\+?(\d{10,13})$/, "Phone number must be valid")
          .test(
            "no-invalid-start",
            "Phone number must be valid!",
            value => !/^(\+25562|\+25561)/.test(value)
          )
    }),

    onSubmit: (values, { resetForm, setSubmitting }) => {
      setStepOnePayload({
        citizenship: values.citizenship,
        formFourIndexNo: values.formFourIndexNo,
        formFourEquivalentNumber: values.formFourEquivalentNumber,
        isOlevelCertificateVerified: values.isOlevelCertificateVerified,
        phoneNumber: values.phoneNumber,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        email: values.email,
        identificationNumber: values.identificationNumber,
        isApplicantStudiedInTanzania: IsStudiedInTanzania
      }) // set data in state for the next step tab

      switch (values.citizenship) {
        case "Yes":
          const payload = {
            indexNumber:
              IsStudiedInTanzania === "Yes"
                ? values.formFourIndexNo
                : IsStudiedInTanzania === "No" &&
                values.formFourEquivalentNumber,
            examId: "1"
          }
          if (IsStudiedInTanzania === "Yes") {
            refetchApplcantData({
              variables: {
                input: { ...payload }
              },
              onCompleted: ({ getNectaApplicantParticular }) => {
                if (getNectaApplicantParticular?.code === 8000) {
                  setTcuResponse(getNectaApplicantParticular?.data)
                  showToast(
                    getNectaApplicantParticular?.message,
                    "success",
                    "Feedback"
                  )
                  toggleTab(2)
                } else if (getNectaApplicantParticular?.code >= 8001) {
                  toggleTab(1)
                  showToast(
                    getNectaApplicantParticular?.message,
                    "error",
                    "Feedback"
                  )
                  setSubmitting(false)
                }
              },
              onError: error => {
                setSubmitting(false)
              }
            })
          } else if (IsStudiedInTanzania === "No") {
            refetchApplcantData({
              variables: {
                input: { ...payload }
              },
              onCompleted: ({ getNectaApplicantParticular }) => {
                if (getNectaApplicantParticular?.code === 8000) {
                  setTcuResponse(getNectaApplicantParticular?.data)
                  showToast(
                    getNectaApplicantParticular?.message,
                    "success",
                    "Feedback"
                  )
                  toggleTab(2)
                } else if (getNectaApplicantParticular?.code >= 8001) {
                  toggleTab(1)
                  showToast(
                    getNectaApplicantParticular?.message,
                    "error",
                    "Feedback"
                  )
                  setSubmitting(false)
                }
              },
              onError: error => {
                setSubmitting(false)
              }
            })
          }
          break

        case "No":
          const payloadData = {
            formFourIndexNumber:
              values.citizenship === "No" && values.identificationNumber,
            firstName: values.firstName,
            middleName: values.middleName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            gender: values.gender,
            tanzanian: false,
            countryCode: values.countryCode
          }

          registerApplicant({
            variables: {
              inputs: { ...payloadData }
            },
            onCompleted: ({ registerApplicant }) => {
              if (registerApplicant.code === 8000) {
                const response = registerApplicant?.data
                showToast(registerApplicant.message, "success")
                setSubmitting(false)
                setSuccessMessage("Successfully account created, please check your email for accessing username and password")
                navigate("/login", {
                  state: { successMessage: successMessage, response: response }
                })
              } else {
                showToast(
                  registerApplicant.message,
                  registerApplicant.code === 8000 ? "success" : "error"
                )
                setSubmitting(false)
              }
            },
            onError: error => {
              console.error("Mutation error:", error)
              setSubmitting(false)
            }
          })
          break

        default:
          setSubmitting(false)
          return null
      }
    }
  })

  return (
    <div className="wizard">
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Card className="border border-2 border-primary">
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return null
                }}
              >
                {
                  location?.pathname === "/online/application/undergraduate" ? (
                    <h4 className="mb-2 p-2  text-center border-primary bg-gradient">Undergraduate application</h4>
                  ) : location?.pathname === "online/application/postgraduate" && (
                    <h4>Postgraduate application</h4>
                  )
                }
                <div className="mb-3">
                  <Label>Are you a Tanzanian?</Label>
                  <Select
                    name="citizenship"
                    onChange={e => {
                      validation.setFieldValue("citizenship", e.value)
                      setSelectedCitizenship(e.value)
                      setSelectedVerification(null)
                    }}
                    options={citizenshipOptions}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                    defaultInputValue={
                      stepOnePayload?.citizenship
                        ? stepOnePayload?.citizenship
                        : ""
                    }
                    defaultValue={
                      stepOnePayload?.citizenship
                        ? stepOnePayload?.citizenship
                        : ""
                    }
                  />
                  <Input
                    name="citizenship"
                    placeholder="Select Disability"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.citizenship ||
                      stepOnePayload?.citizenship
                        ? stepOnePayload?.citizenship
                        : ""
                    }
                    invalid={
                      !!(
                        validation.touched.citizenship &&
                        validation.errors.citizenship
                      )
                    }
                  />
                  {validation.touched.citizenship &&
                  validation.errors.citizenship ? (
                    <FormFeedback type="invalid">
                      {validation.errors.citizenship}
                    </FormFeedback>
                  ) : null}
                </div>
                {selectedCitizenship === "Yes" ? (
                  <>
                    <div className="mb-3">
                      <Label>Have you studied in Tanzania?</Label>
                      <Select
                        name="isApplicantStudiedInTanzania"
                        onChange={e => {
                          validation.setFieldValue(
                            "isApplicantStudiedInTanzania",
                            e.value
                          )
                          setIsStudiedInTanzania(e.value)
                          setSelectedVerification(null)
                        }}
                        options={studiedTanzaniaOptions}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999
                          })
                        }}
                        defaultInputValue={
                          stepOnePayload?.isApplicantStudiedInTanzania
                            ? stepOnePayload?.isApplicantStudiedInTanzania
                            : ""
                        }
                        defaultValue={
                          stepOnePayload?.isApplicantStudiedInTanzania
                            ? stepOnePayload?.isApplicantStudiedInTanzania
                            : ""
                        }
                      />
                      <Input
                        name="isApplicantStudiedInTanzania"
                        placeholder="Select Disability"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={
                          validation.values.isApplicantStudiedInTanzania ||
                          stepOnePayload?.isApplicantStudiedInTanzania
                            ? stepOnePayload?.isApplicantStudiedInTanzania
                            : ""
                        }
                        invalid={
                          !!(
                            validation.touched.isApplicantStudiedInTanzania &&
                            validation.errors.isApplicantStudiedInTanzania
                          )
                        }
                      />
                      {validation.touched.isApplicantStudiedInTanzania &&
                      validation.errors.isApplicantStudiedInTanzania ? (
                        <FormFeedback type="invalid">
                          {validation.errors.isApplicantStudiedInTanzania}
                        </FormFeedback>
                      ) : null}
                    </div>
                    {IsStudiedInTanzania === "Yes" ? (
                      <div className="mb-3">
                        <Label>Form four index number</Label>
                        <Input
                          type="text"
                          name="formFourIndexNo"
                          className="form-control"
                          id="text"
                          placeholder="e.g S0000/0001/2015"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.formFourIndexNo || ""}
                          invalid={
                            validation.touched.formFourIndexNo &&
                            validation.errors.formFourIndexNo
                              ? true
                              : false
                          }
                        />
                        {validation.touched.formFourIndexNo &&
                        validation.errors.formFourIndexNo ? (
                          <FormFeedback type="invalid">
                            {validation.errors.formFourIndexNo}
                          </FormFeedback>
                        ) : null}
                      </div>
                    ) : (
                      IsStudiedInTanzania === "No" && (
                        <>
                          <div className="mb-3">
                            <Label>
                              Have you verified your o-level certificates?
                            </Label>
                            <Select
                              name="isOlevelCertificateVerified"
                              onChange={e => {
                                validation.setFieldValue(
                                  "isOlevelCertificateVerified",
                                  e.value
                                )
                                setSelectedVerification(e.value)
                              }}
                              options={isOlevelCertificateVerifiedOptions}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999
                                })
                              }}
                              defaultInputValue={
                                stepOnePayload?.isOlevelCertificateVerified
                                  ? stepOnePayload?.isOlevelCertificateVerified
                                  : ""
                              }
                              defaultValue={
                                stepOnePayload?.isOlevelCertificateVerified
                                  ? stepOnePayload?.isOlevelCertificateVerified
                                  : ""
                              }
                            />
                            <Input
                              name="isOlevelCertificateVerified"
                              placeholder="Select Disability"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.isOlevelCertificateVerified ||
                                stepOnePayload?.isOlevelCertificateVerified
                                  ? stepOnePayload?.isOlevelCertificateVerified
                                  : ""
                              }
                              invalid={
                                !!(
                                  validation.touched
                                    .isOlevelCertificateVerified &&
                                  validation.errors.isOlevelCertificateVerified
                                )
                              }
                            />
                            {validation.touched.isOlevelCertificateVerified &&
                            validation.errors.isOlevelCertificateVerified ? (
                              <FormFeedback type="invalid">
                                {validation.errors.isOlevelCertificateVerified}
                              </FormFeedback>
                            ) : null}
                          </div>
                          {selectedVerification === "Yes" ? (
                            <div className="mb-3">
                              <Label>Form Four Equivalent Number</Label>
                              <Input
                                type="text"
                                name="formFourEquivalentNumber"
                                className="form-control"
                                id="text"
                                placeholder="e.g E000/0012/2015"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.formFourEquivalentNumber ||
                                  ""
                                }
                                invalid={
                                  validation.touched.formFourEquivalentNumber &&
                                  validation.errors.formFourEquivalentNumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.formFourEquivalentNumber &&
                              validation.errors.formFourEquivalentNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.formFourEquivalentNumber}
                                </FormFeedback>
                              ) : null}
                            </div>
                          ) : (
                            selectedVerification === "No" && (
                              <div className="alert alert-danger">
                                You are required to verify your o-level
                                certificates from NECTA
                              </div>
                            )
                          )}
                        </>
                      )
                    )}
                  </>
                ) : (
                  selectedCitizenship === "No" && (
                    <>
                      <Row>
                        <Col md={6}>
                          <div className="mb-2">
                            <Label>Identification Number (Ordinary level / High level)</Label>
                            <Input
                              type="text"
                              name="identificationNumber"
                              className="form-control"
                              id="text"
                              placeholder="Identification Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={
                                validation.values.identificationNumber || ""
                              }
                              invalid={
                                validation.touched.identificationNumber &&
                                validation.errors.identificationNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.identificationNumber &&
                            validation.errors.identificationNumber ? (
                              <FormFeedback type="invalid">
                                {validation.errors.identificationNumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-2">
                            <Label>First Name</Label>
                            <Input
                              type="text"
                              name="firstName"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your First Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstName || ""}
                              invalid={
                                validation.touched.firstName &&
                                validation.errors.firstName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.firstName &&
                            validation.errors.firstName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-2">
                            <Label>Middle Name</Label>
                            <Input
                              type="text"
                              name="middleName"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your Middle Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.middleName || ""}
                              invalid={
                                validation.touched.middleName &&
                                validation.errors.middleName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.middleName &&
                            validation.errors.middleName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.middleName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-2">
                            <Label>Last Name</Label>
                            <Input
                              type="text"
                              name="lastName"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastName || ""}
                              invalid={
                                validation.touched.lastName &&
                                validation.errors.lastName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.lastName &&
                            validation.errors.lastName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-2">
                            <Label>
                              Phone Number
                              <small style={{ fontSize: 8 }}>
                                (Start with international code e.g +255)
                              </small>
                            </Label>
                            <Input
                              type="text"
                              name="phoneNumber"
                              className="form-control"
                              id="text"
                              placeholder="Enter your phone number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phoneNumber || ""}
                              invalid={
                                validation.touched.phoneNumber &&
                                validation.errors.phoneNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phoneNumber &&
                            validation.errors.phoneNumber ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phoneNumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-2">
                            <Label>Email Address</Label>
                            <Input
                              type="text"
                              name="email"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Country</Label>
                            <Select
                              name="countryCode"
                              onChange={e => {
                                validation.setFieldValue("countryCode", e.value)
                              }}
                              options={countries
                                ?.filter(country => country?.name !== "TANZANIA")
                                ?.map(country => ({
                                  value: country?.code,
                                  label: country?.name
                                }))
                              }

                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999
                                })
                              }}
                            />
                            <Input
                              name="countryCode"
                              placeholder="Select Region"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.countryCode || ""}
                              invalid={
                                !!(
                                  validation.touched.countryCode &&
                                  validation.errors.countryCode
                                )
                              }
                            />
                            {validation.touched.countryCode &&
                            validation.errors.countryCode ? (
                              <FormFeedback type="invalid">
                                {validation.errors.countryCode}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Gender</Label>
                            <Select
                              name="gender"
                              onChange={e => {
                                validation.setFieldValue("gender", e.value)
                              }}
                              options={genders}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999
                                })
                              }}
                            />
                            <Input
                              name="gender"
                              placeholder="Select Region"
                              type="hidden"
                              rows={2}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.gender || ""}
                              invalid={
                                !!(
                                  validation.touched.gender &&
                                  validation.errors.gender
                                )
                              }
                            />
                            {validation.touched.gender &&
                            validation.errors.gender ? (
                              <FormFeedback type="invalid">
                                {validation.errors.gender}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </>
                  )
                )}
                {selectedVerification !== "No" && (
                  <div className="wizard">
                    <div className="actions">
                      <ul>
                        <li className="next" onClick={handleNext}>
                          <SubmitButtonHelper
                            type="primary"
                            name={
                              applicanDataLoading ? "Please wait.." : "Next >"
                            }
                            formik={validation}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </Form>
              <Row>
                <Col md={12} className="text-center">
                  <Link className="btn btn-sm" to="/online/application">Go back</Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  )
}

export default ApplicantAccountRegistrationFirstStep
