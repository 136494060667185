import React from 'react'
import {Route, Routes} from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
// import CountryList from "../Views/CountryList";
import BlockList from '../Views/BlockList';
import HostelList from '../Views/HostelList';
import RoomList from '../Views/RoomList';
import AccommodationYearList from '../Views/AccommodationYearList';
import RoomCapacityList from '../Views/RoomCapacityList';
import RoomAllocationList from '../Views/RoomAllocationList';
import RoomRequestList from '../Views/RoomRequestList';
import MyAccommodation from '../Views/MyAccommodation';
import AccommodationList from '../Views/AccommodationList';
import RoomAllocationPaymentStatus from "../Views/RoomAllocationPaymentStatus";
import AsimsDashboard from "../../ASIMS/views/asimsDashboard";


const AccommodationRoutes = () => {
    return (
        <Routes>
            <Route path="dashboard" element={<AsimsDashboard />} />
            <Route  path="/" element={<HostelList />}/>
            <Route  path="/hostels" element={<HostelList />}/>
            <Route  path="/hostels/blocks" element={<BlockList />}/>
            <Route  path="hostels/blocks/rooms" element={<RoomList />}/>
            <Route  path="/room-capacity" element={<RoomCapacityList />}/>
            <Route  path="/room-allocation" element={<RoomAllocationList />}/>
            {/*<Route  path="/room-request" element={<RoomRequestList />}/>*/}
            <Route  path="/my-accommodation" element={<MyAccommodation />}/>
            <Route  path="/allocation-and-boarding" element={<AccommodationList />}/>
            <Route  path="/accommodation-year" element={<AccommodationYearList />}/>
            <Route  path="/allocation-payment-status" element={<RoomAllocationPaymentStatus />}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default AccommodationRoutes