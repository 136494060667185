import { gql } from "apollo-boost"

const GET_STATE = gql`
  query GetState($pagination: PaginationInput!) {
    getStates(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          label
          description
        }
        totalCount
      }
    }
  }
`

export { GET_STATE }
