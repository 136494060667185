import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  Card,
  CardBody
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link, useLocation } from "react-router-dom"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { token } from "helpers/UrlHelper"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useNavigate } from "react-router-dom"
import { REGISTER_APPLICANT } from "../Mutations/AccountRegistrationMutations"
import { ApplicantContextForPostgraduate } from "./Postgraduate"
import { ApplicantContextForUndergraduate } from "./Undergraduate"

const ApplicantAccountRegistrationSecondStep = () => {
  const location = useLocation()
  const [registerApplicant] = useMutation(REGISTER_APPLICANT, {
    client: onlineApplicationGraphQLClient
  })

  const navigate = useNavigate()

  const context =
    useContext(ApplicantContextForPostgraduate) ||
    useContext(ApplicantContextForUndergraduate)
  const {
    tcuResponce,
    setTcuResponse,
    stepOnePayload,
    setStepOnePayload,
    toggleTab
  } = context

  const [isSubmitted] = useState(false)
  const [successMessage, setSuccessMessage] = useState(null)
  const [selectedValue, setSelectedValue] = useState(null)
  const [selectedLabel, setSelectedLabel] = useState(null)

  useEffect(() => {
    const user = token
    setSelectedLabel(selectedValue ? "Yes" : "No")
  }, [token, selectedValue])

  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(3) // Move to the next tab
    } else {
      toggleTab(2) // Move to the next tab
    }
  }

  const handlePrevious = () => {
    setStepOnePayload({ ...stepOnePayload })
    setTcuResponse({ ...setTcuResponse })
    toggleTab(1) // Move to the previous tab
  }

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      citizenship: stepOnePayload?.citizenship
        ? stepOnePayload?.citizenship
        : "",
      formFourIndexNo: stepOnePayload?.formFourIndexNo
        ? stepOnePayload?.formFourIndexNo
        : "",
      formFourEquivalentNumber: stepOnePayload?.formFourEquivalentNumber
        ? stepOnePayload?.formFourEquivalentNumber
        : "",
      isApplicantStudiedInTanzania: stepOnePayload?.isApplicantStudiedInTanzania
        ? stepOnePayload?.isApplicantStudiedInTanzania
        : "",
      isOlevelCertificateVerified: stepOnePayload?.isOlevelCertificateVerified
        ? stepOnePayload?.isOlevelCertificateVerified
        : "",
      firstName: stepOnePayload?.firstName ? stepOnePayload?.firstName : "",
      identificationNumber: stepOnePayload?.identificationNumber
        ? stepOnePayload?.identificationNumber
        : "",
      middleName: stepOnePayload?.middleName ? stepOnePayload?.middleName : "",
      lastName: stepOnePayload?.lastName ? stepOnePayload?.lastName : "",
      email: stepOnePayload?.email ? stepOnePayload?.email : "",
      phoneNumber: stepOnePayload?.phoneNumber
        ? stepOnePayload?.phoneNumber
        : ""
    },

    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(13, "Phone number must be valid with international code")
        .max(13, "Phone number must be valid with international code")
        .nullable()
        .required("Please enter a valid phone number")
        .matches(/^\+?(\d{13,13})$/, "Phone number must be valid")
        .test(
          "no-invalid-start",
          "Phone number must be valid1",
          value => !/^(\+25562|\+25561)/.test(value)
        )
    }),

    onSubmit: (values, { resetForm }) => {
    }
  })

  const validationForNectaParticulars = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      citizenship: stepOnePayload?.citizenship
        ? stepOnePayload?.citizenship
        : "",
      formFourIndexNo: stepOnePayload?.formFourIndexNo
        ? stepOnePayload?.formFourIndexNo
        : "",
      sex: tcuResponce?.sex ? tcuResponce?.sex : "",
      isApplicantStudiedInTanzania: stepOnePayload?.isApplicantStudiedInTanzania
        ? stepOnePayload?.isApplicantStudiedInTanzania
        : "",
      isOlevelCertificateVerified: stepOnePayload?.isOlevelCertificateVerified
        ? stepOnePayload?.isOlevelCertificateVerified
        : "",
      firstName: stepOnePayload?.firstName ? stepOnePayload?.firstName : "",
      centerName: tcuResponce?.centerName ? tcuResponce?.centerName : "",
      middleName: stepOnePayload?.middleName ? stepOnePayload?.middleName : "",
      lastName: stepOnePayload?.lastName ? stepOnePayload?.lastName : "",
      email: "",
      phoneNumber: ""
    },

    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(13, "Phone number must be valid with international code")
        .max(13, "Phone number must be valid with international code")
        .nullable()
        .required("Please enter a valid phone number")
        .matches(/^\+?(\d{10,13})$/, "Phone number must be valid")
        .test(
          "no-invalid-start",
          "Phone number must be valid",
          value => !/^(\+25562|\+25561)/.test(value)
        ),
      email: Yup.string().required("Please Enter Email")
    }),

    onSubmit: (values, { resetForm, setSubmitting }) => {
      const postData = {
        firstName: tcuResponce?.firstName,
        middleName: tcuResponce?.middleName,
        lastName: tcuResponce?.lastName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        tanzanian: stepOnePayload?.citizenship === "Yes" ? true : false,
        formFourIndexNumber: stepOnePayload?.formFourIndexNo
          ? stepOnePayload?.formFourIndexNo
          : stepOnePayload?.formFourEquivalentNumber,
        countryCode: stepOnePayload?.citizenship === "Yes" ? "TZ" : "Foreign",
        gender:
          tcuResponce?.sex === "M"
            ? "Male"
            : tcuResponce?.sex === "F" && "Female"
      }

      registerApplicant({
        variables: {
          inputs: { ...postData }
        },
        onCompleted: ({ registerApplicant }) => {
          if (registerApplicant.code === 8000) {
            const response = registerApplicant?.data
            showToast(registerApplicant.message, "success")
            resetForm()
            setSuccessMessage("")
            navigate("/login", {
              state: {
                successMessage: "Successfully account created, " +
                  "please check your email for accessing username and password",
                response: response
              }
            })
          } else {
            showToast(
              registerApplicant.message,
              registerApplicant.code === 8000 ? "success" : "error"
            )
            setSubmitting(false)
          }
        },
        onError: error => {
          console.error("Mutation error:", error)
          setSubmitting(false)
        }
      })
    }
  })

  return (
    <div className="mt-3 wizard">
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Card className="border border-2 border-primary">
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  if (stepOnePayload?.citizenship === "No") {
                    return validation.handleSubmit()
                  } else if (stepOnePayload?.citizenship === "Yes") {
                    return validationForNectaParticulars.handleSubmit()
                  }
                  return null
                }}
              >
                <div className="modal-body">
                  <Row>
                    <Col md={12}>
                      {
                        location?.pathname === "/online/application/undergraduate" ? (
                          <h4 className="mb-2 p-2 text-center border-primary bg-gradient">Undergraduate application</h4>
                        ) : location?.pathname === "online/application/postgraduate" && (
                          <h4>Postgraduate application</h4>
                        )
                      }
                    </Col>
                  </Row>
                  {stepOnePayload?.citizenship === "No" ? (
                    <>

                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Senior/Junior Identification Number (Ordinary level)</Label>
                            <Input
                              disabled={true}
                              type="text"
                              name="identificationNumber"
                              className="form-control"
                              id="text"
                              placeholder="Identification Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={stepOnePayload?.identificationNumber || ""}
                              invalid={
                                validation.touched.identificationNumber &&
                                validation.errors.identificationNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.identificationNumber &&
                            validation.errors.identificationNumber ? (
                              <FormFeedback type="invalid">
                                {validation.errors.identificationNumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>First Name</Label>
                            <Input
                              disabled={true}
                              type="text"
                              name="firstName"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your First Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={stepOnePayload?.firstName || ""}
                              invalid={
                                validation.touched.firstName &&
                                validation.errors.firstName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.firstName &&
                            validation.errors.firstName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Middle Name</Label>
                            <Input
                              disabled={true}
                              type="text"
                              name="middleName"
                              className="form-control"
                              id="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={stepOnePayload?.middleName || ""}
                              invalid={
                                validation.touched.middleName &&
                                validation.errors.middleName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.middleName &&
                            validation.errors.middleName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.middleName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Last Name</Label>
                            <Input
                              disabled={true}
                              type="text"
                              name="lastName"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={stepOnePayload?.lastName || ""}
                              invalid={
                                !!(
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                )
                              }
                            />
                            {validation.touched.lastName &&
                            validation.errors.lastName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>
                              Phone Number(Start with international code e.g
                              +255)
                            </Label>
                            <Input
                              disabled={true}
                              type="text"
                              name="phoneNumber"
                              className="form-control"
                              id="text"
                              placeholder="e.g +255"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={stepOnePayload?.phoneNumber || ""}
                              invalid={
                                !!(
                                  validation.touched.phoneNumber &&
                                  validation.errors.phoneNumber
                                )
                              }
                            />
                            {validation.touched.phoneNumber &&
                            validation.errors.phoneNumber ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phoneNumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Email Address</Label>
                            <Input
                              disabled={true}
                              type="text"
                              name="email"
                              className="form-control"
                              id="text"
                              placeholder="Enter Your email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={stepOnePayload?.email || ""}
                              invalid={
                                !!(
                                  validation.touched.email &&
                                  validation.errors.email
                                )
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    stepOnePayload?.citizenship === "Yes" && (
                      <>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Center Name</Label>
                              <Input
                                readOnly={true}
                                type="text"
                                name="centerName"
                                className="form-control"
                                id="text"
                                placeholder="Center name"
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={tcuResponce?.centerName || ""}
                                invalid={
                                  validationForNectaParticulars.touched
                                    .identificationNumber &&
                                  validationForNectaParticulars.errors
                                    .identificationNumber
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched
                                .identificationNumber &&
                              validationForNectaParticulars.errors
                                .identificationNumber ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationForNectaParticulars.errors
                                      .identificationNumber
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>First Name</Label>
                              <Input
                                readOnly={true}
                                type="text"
                                name="firstName"
                                className="form-control"
                                id="text"
                                placeholder="Enter Your First Name"
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={tcuResponce?.firstName || ""}
                                invalid={
                                  validationForNectaParticulars.touched
                                    .firstName &&
                                  validationForNectaParticulars.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched
                                .firstName &&
                              validationForNectaParticulars.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationForNectaParticulars.errors
                                      .firstName
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Middle Name</Label>
                              <Input
                                readOnly={true}
                                type="text"
                                name="middleName"
                                className="form-control"
                                id="text"
                                placeholder=""
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={tcuResponce?.middleName || ""}
                                invalid={
                                  validationForNectaParticulars.touched
                                    .middleName &&
                                  validationForNectaParticulars.errors
                                    .middleName
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched
                                .middleName &&
                              validationForNectaParticulars.errors
                                .middleName ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationForNectaParticulars.errors
                                      .middleName
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Last Name</Label>
                              <Input
                                readOnly={true}
                                type="text"
                                name="lastName"
                                className="form-control"
                                id="text"
                                placeholder="Enter Your Last Name"
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={tcuResponce?.lastName || ""}
                                invalid={
                                  validationForNectaParticulars.touched
                                    .lastName &&
                                  validationForNectaParticulars.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched.lastName &&
                              validationForNectaParticulars.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationForNectaParticulars.errors
                                      .lastName
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label>Sex</Label>
                              <Input
                                readOnly={true}
                                type="text"
                                name="firstName"
                                className="form-control"
                                id="text"
                                placeholder="Enter Your sex"
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={tcuResponce?.sex || ""}
                                invalid={
                                  validationForNectaParticulars.touched.sex &&
                                  validationForNectaParticulars.errors.sex
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched.sex &&
                              validationForNectaParticulars.errors.sex ? (
                                <FormFeedback type="invalid">
                                  {validationForNectaParticulars.errors.sex}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>
                                Phone Number
                                <small>
                                  (Start with international code e.g +255)
                                </small>
                              </Label>
                              <Input
                                type="text"
                                name="phoneNumber"
                                className="form-control"
                                id="text"
                                placeholder="e.g +255700000000"
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={
                                  validationForNectaParticulars.values
                                    .phoneNumber || ""
                                }
                                invalid={
                                  validationForNectaParticulars.touched
                                    .phoneNumber &&
                                  validationForNectaParticulars.errors
                                    .phoneNumber
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched
                                .phoneNumber &&
                              validationForNectaParticulars.errors
                                .phoneNumber ? (
                                <FormFeedback type="invalid">
                                  {
                                    validationForNectaParticulars.errors
                                      .phoneNumber
                                  }
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Email Address</Label>
                              <Input
                                type="text"
                                name="email"
                                className="form-control"
                                id="text"
                                placeholder="Enter Your email"
                                onChange={
                                  validationForNectaParticulars.handleChange
                                }
                                onBlur={
                                  validationForNectaParticulars.handleBlur
                                }
                                value={
                                  validationForNectaParticulars.values.email ||
                                  ""
                                }
                                invalid={
                                  validationForNectaParticulars.touched.email &&
                                  validationForNectaParticulars.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validationForNectaParticulars.touched.email &&
                              validationForNectaParticulars.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validationForNectaParticulars.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </>
                    )
                  )}
                </div>
                <div className="wizard">
                  <div className="actions">
                    <ul>
                      <li className="previous">
                        <Link to="#" onClick={handlePrevious}>
                          {`< Previous`}
                        </Link>
                      </li>
                      <li className="next" onClick={handleNext}>
                        <SubmitButtonHelper
                          type="primary"
                          name="Submit"
                          formik={
                            stepOnePayload?.citizenship === "No"
                              ? validation
                              : stepOnePayload?.citizenship === "Yes" &&
                              validationForNectaParticulars
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Form>
              <Row>
                <Col md={12} className="text-center">
                  <Link className="btn btn-sm" to="/online/application">
                    Go back
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  )
}
export default ApplicantAccountRegistrationSecondStep
