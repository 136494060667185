import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_STAFF_CATEGORY } from "../Mutations/StaffCategoryMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_STAFF_CATEGORIES } from "../Queries/StaffCategoryQueries"
import { createUpdateFunction, paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { StaffCategoryContext } from "./StaffCategoryList" 
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const StaffCategoryModal = () => {

  const { updateStaffCategoryData, setUpdateStaffCategoryData } = useContext(StaffCategoryContext)
  const [StaffCategory] = useMutation(CREATE_STAFF_CATEGORY,{
    client:uaaGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_STAFF_CATEGORIES;
  const variables = { pagination: paginationHelper };
  const registerData = 'data';
  
  const updateFunction =  createUpdateFunction(query, variables, registerData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateStaffCategoryData ? updateStaffCategoryData.name : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Staff Category name is required"),
    }),

   
  onSubmit: (values, {resetForm}) => {
    StaffCategory({
    client:uaaGraphQLClient,
      variables: {
        inputs: [{ ...values, uid: updateStaffCategoryData?.uid }],
      },
      refetchQueries: [
        {
          query: GET_STAFF_CATEGORIES,
          variables: {
            pagination: paginationHelper,
          },
        },
      ],
      onCompleted: ({ registerStaffCategories }) => {
        if (registerStaffCategories.code === 8000) {
          resetForm();
          setModalOpen(false);
          setUpdateStaffCategoryData(false); // Close the modal
        } else {
          setModalOpen(true);
          setUpdateStaffCategoryData(true); // Close the modal
        }
        showToast(
          registerStaffCategories.message,
          registerStaffCategories.code === 8000 ? "success" : "error"
        );
      },
      onError: error => {
        // Handle errors
        console.error("Mutation error:", error);
      },
    });
  },
  })
  return (
      <div className="text-sm-end mt-3">
        <Button
          type="button"
          color="primary"
          className="btn mb-2 me-2 pull-right"
          onClick={() => setModalOpen(true)}
        >
          <i className="mdi mdi-plus-circle-outline me-1" />
          New Staff Category
        </Button>
        <Modal
          isOpen={modalOpen || !!updateStaffCategoryData}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {!!updateStaffCategoryData ? "Edit Staff Category" : "Add New Staff Category"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalOpen(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Name:
                </label>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || updateStaffCategoryData?.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModalOpen(false)
                  setUpdateStaffCategoryData(false)
                }}
              >
                Close
              </button>
              <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
            </div>
          </Form>
        </Modal>
      </div>
  )
}

export default StaffCategoryModal