import React, { useEffect, useState } from "react"
import LayoutHelper from "helpers/LayoutHelper"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  CardSubtitle,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { createContext } from "react"
import { useNavigate } from "react-router-dom"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_INSTRUCTOR_COURSE_ALLOCATION } from "Modules/Instructor/Queries/InstructorCoursesQueries"
import {
  showReturnCourseResultAlert,
  showForwardingAlert,
  showForceCourseResultForwardingAlert,
} from "helpers/DeleteWaringHelper"
import {
  GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID,
  GET_HOD_PROGRAM_SEMESTER,
} from "../Queries/HoDQueries"
import {
  FORWARD_COURSE_RESULT_BY_HOD,
  RETURN_COURSE_EXAM_RESULT,
} from "../Mutations/HoDMutations"
import { forwardStatus } from "helpers/ForwardStatus"

const breadcrumbItem = [
  {
    title: "Forward Results",
    path: "",
    isActive: true,
  },
]

export const ForwardResultsContext = createContext()

const ForwardResultsToPrincipal = () => {
  const [courseAllocate, setCourseAllocate] = useState(null),
    navigate = useNavigate() //for update
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const [loading, setLoading] = useState(false)
  const [selectedProgramSemesterUid, setSelectedProgramSemesterUid] =
    useState(null)
  const [forceCourseForwardLOading, setForceCourseForwardLoading] =
    useState(false)
  const [selectedUids, setSelectedUids] = useState([])
  const [selectedForceUids, setSelectedForceUids] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null)
  const [selectedSemester, setSelectedSemester] = useState(null)
  const [programSemesterResponse, setprogramSemesterResponse] = useState([])

  const [forwardIntrsuctorCoureResultByHodMutation] = useMutation(
    FORWARD_COURSE_RESULT_BY_HOD,
    { client: registrationGraphQLClient }
  )

  const [returnCourse] = useMutation(RETURN_COURSE_EXAM_RESULT, {
    client: registrationGraphQLClient,
  })

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const {
    loading: academicYearLoading,
    error: academicYearError,
    data: academicYear,
    refetch: acRef,
  } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
    fetchPolicy: "network-only",
  })

  const [
    fetchHodProgramSemester,
    { loading: programLoading, error: errorProgramsFetch, data: programResult },
  ] = useLazyQuery(GET_HOD_PROGRAM_SEMESTER, {
    client: registrationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only",
  })

  const programs = programResult?.getHodProgramSemester?.data

  useEffect(() => {
    if (selectedAcademicYear && selectedSemester) {
      fetchHodProgramSemester({
        variables: {
          semester: parseInt(selectedSemester),
          academicYearUid: selectedAcademicYear,
        },
        fetchPolicy: "network-only",
      })
    }
  }, [selectedAcademicYear, selectedSemester])

  const [
    { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
  ] = useLazyQuery(GET_ACADEMICYEARS, { client: registrationGraphQLClient })

  let academicYears = academicYear?.getAcademicYears?.data?.items
  if (searchYear?.getAcademicYears?.data)
    academicYears = searchYear?.getAcademicYears?.data?.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      academicYearUid: "",
      semester: "",
      programSemesterUid: "",
    },

    validationSchema: Yup.object().shape({
      semester: Yup.string().required("Semester is required"),
      academicYearUid: Yup.string().required("Academic year is required"),
      programSemesterUid: Yup.string().required("Semester Program is required"),
    }),
  })

  const semester_options = Array.from({ length: 10 }, (_, index) => ({
    value: `${index + 1}`,
    label: `Semester ${index + 1}`,
  }))

  const handleCheckboxChange = event => {
    const { id, checked } = event.target
    if (checked) {
      setSelectedUids(prevUids => [...prevUids, id])
    } else {
      setSelectedUids(prevUids => prevUids.filter(uid => uid !== id))
    }
  }

  const handleForceCheckboxChange = event => {
    const { id, checked } = event.target
    if (checked) {
      setSelectedForceUids(prevUids => [...prevUids, id])
    } else {
      setSelectedForceUids(prevUids => prevUids.filter(uid => uid !== id))
    }
  }

  const forwardResult = () => {
    if (selectedUids.length > 0) {
      showForwardingAlert(() => {
        setLoading(true)
        forwardIntrsuctorCoureResultByHodMutation({
          variables: {
            programCourseUids: selectedUids,
          },
          refetchQueries: [
            {
              query:
                GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID,
              variables: {
                programSemesterUid: selectedProgramSemesterUid,
              },
              fetchPolicy: "network-only",
            },
          ],
          onCompleted: ({ forwardCourseResultByHod }) => {
            if (forwardCourseResultByHod.code === 8000) {
              setSelectedUids([])
              showToast(forwardCourseResultByHod.message, "success")
              setLoading(false)
            } else {
              showToast(
                forwardCourseResultByHod.message,
                forwardCourseResultByHod.code === 8000 ? "success" : "error"
              )
              setLoading(false)
            }
          },
          onError: error => {
            setLoading(false)
            console.error("Mutation error:", error)
          },
        })
      })
    } else {
      showToast("No any course selected for forwarding result", "error")
      return
    }
  }

  const forceCourseResultForward = () => {
    if (selectedForceUids.length > 0) {
      showForceCourseResultForwardingAlert(() => {
        setForceCourseForwardLoading(true)
        forwardIntrsuctorCoureResultByHodMutation({
          variables: {
            programCourseUids: selectedForceUids,
          },
          refetchQueries: [
            {
              query:
                GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID,
              variables: {
                programSemesterUid: selectedProgramSemesterUid,
              },
              fetchPolicy: "network-only",
            },
          ],
          onCompleted: ({ forwardCourseResultByHod }) => {
            if (forwardCourseResultByHod.code === 8000) {
              setSelectedUids([])
              showToast(forwardCourseResultByHod.message, "success")
              setForceCourseForwardLoading(false)
            } else {
              showToast(
                forwardCourseResultByHod.message,
                forwardCourseResultByHod.code === 8000 ? "success" : "error"
              )
              setForceCourseForwardLoading(false)
            }
          },
          onError: error => {
            setLoading(false)
            console.error("Mutation error:", error)
          },
        })
      })
    } else {
      showToast("No any course selected for forwarding result", "error")
      return
    }
  }

  const [
    fetcthProgramCourseSemester,
    {
      loading: programCourseSemesterLoading,
      error: errorProgramCourseSemester,
      data: programCourseSemesterResult,
    },
  ] = useLazyQuery(
    GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID,
    {
      client: registrationGraphQLClient,
      variables: {},
      fetchPolicy: "network-only",
    }
  )

  const handleReturnCourse = () => {
    showReturnCourseResultAlert(() => {
      returnCourse({
        variables: {
          programCourseUids: selectedUids,
        },
        refetchQueries: [
          {
            query:
              GET_HOD_FORWARD_EXAM_COURSE_RESULTS_STATUS_BY_PROGRAM_SEMESTER_UID,
            variables: {
              programSemesterUid: selectedProgramSemesterUid,
            },
            fetchPolicy: "network-only",
          },
        ],
        onCompleted: ({ returnCourseExamResult }) => {
          if (returnCourseExamResult.code === 8000) {
            setSelectedUids([])
            showToast(returnCourseExamResult.message, "success")
          } else {
            showToast(
              returnCourseExamResult.message,
              returnCourseExamResult.code === 8000 ? "success" : "error"
            )
          }
        },
        onError: error => {
          setLoading(false)
          console.error("Mutation error:", error)
        },
      })
    })
  }

  useEffect(() => {
    if (!programCourseSemesterLoading && programCourseSemesterResult) {
      setprogramSemesterResponse(
        programCourseSemesterResult
          ?.getHodForwardExamCourseResultStatusByProgramSemesterUid?.data || []
      )
    }
  }, [programCourseSemesterLoading, programCourseSemesterResult])

  return (
    <ForwardResultsContext.Provider
      value={{
        setCourseAllocate,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Examination Result and Forwarding"
      >
        {academicYearLoading && (
          <PlaceHolderLoader columSize={12} type={"table"} rows={5} />
        )}
        <>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label>Academic Year</Label>
                          <Select
                            // value={selectedGroup}
                            onChange={e => {
                              if (e) {
                                validation.setFieldValue(
                                  "academicYearUid",
                                  e.value
                                )
                                setSelectedAcademicYear(e.value)
                              } else {
                                validation.setFieldValue("academicYearUid", "")
                              }
                            }}
                            options={academicYears?.map(year => ({
                              value: year.uid,
                              label: year.name,
                            }))}
                            className="select2-selection"
                            isLoading={academicYearLoading || yearSearchLoading}
                            isClearable={true}
                          />
                          <Input
                            name="academicYearUid"
                            type="hidden"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.academicYearUid}
                            invalid={
                              !!(
                                validation.touched.academicYearUid &&
                                validation.errors.academicYearUid
                              )
                            }
                          />
                          {validation.touched.academicYearUid &&
                          validation.errors.academicYearUid ? (
                            <FormFeedback type="invalid">
                              {validation.errors.academicYearUid}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Semester</Label>
                          <Select
                            // value={selectedGroup}
                            name="semester"
                            onChange={e => {
                              validation.setFieldValue("semester", e.value)
                              setSelectedSemester(e.value)
                            }}
                            styles={{
                              menu: base => ({
                                ...base,
                                position: "absolute",
                                zIndex: 9999,
                              }),
                            }}
                            options={semester_options}
                            className="select2-selection"
                            // defaultValue={updateProgramSemisterData?.studyYear}
                            // defaultInputValue={updateProgramSemisterData?.studyYear}
                          />
                          <Input
                            name="semester"
                            type="hidden"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.semester || 1}
                            invalid={
                              !!(
                                validation.touched.semester &&
                                validation.errors.semester
                              )
                            }
                          />
                          {validation.touched.semester &&
                          validation.errors.semester ? (
                            <FormFeedback type="invalid">
                              {validation.errors.semester}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                          <Label>Program</Label>
                          <Select
                            styles={{
                              menu: base => ({
                                ...base,
                                position: "absolute",
                                zIndex: 9999,
                              }),
                            }}
                            onChange={e => {
                              if (e) {
                                validation.setFieldValue(
                                  "programSemesterUid",
                                  e.value
                                )
                                setSelectedProgramSemesterUid(e.value)
                                fetcthProgramCourseSemester({
                                  variables: {
                                    programSemesterUid: e.value,
                                  },
                                })
                              } else {
                                validation.setFieldValue(
                                  "programSemesterUid",
                                  ""
                                )
                              }
                            }}
                            options={programs?.map(pro => ({
                              value: pro.uid,
                              label:
                                pro?.program.name +
                                "  (Year " +
                                pro.studyYear +
                                ")",
                            }))}
                            className="select2-selection"
                            isClearable={true}
                            isLoading={programLoading}
                          />
                          <Input
                            name="programSemesterUid"
                            placeholder=""
                            type="hidden"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.programSemesterUid}
                            invalid={
                              !!(
                                validation.touched.programSemesterUid &&
                                validation.errors.programSemesterUid
                              )
                            }
                            style={{
                              width: 25,
                              height: 25,
                              padding: 0,
                              borderColor: "black",
                              marginRight: 10,
                              verticalAlign: "middle",
                            }}
                          />
                          {validation.touched.programSemesterUid &&
                          validation.errors.programSemesterUid ? (
                            <FormFeedback type="invalid">
                              {validation.errors.programSemesterUid}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="ml-2">
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-1 mt-4 card-title">
                    <h5>Course Lists</h5>
                    <Button
                      disabled={selectedUids.length <= 0 ? true : false}
                      color="primary"
                      className="btn btn-sm  mb-2"
                      onClick={() => {
                        forwardResult()
                      }}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mr-3"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-4">Please wait...</span>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                          Forward result
                        </>
                      )}
                    </Button>{" "}
                    <Button
                      disabled={selectedUids.length <= 0 ? true : false}
                      color="danger"
                      className="btn btn-sm  mb-2 ml-2"
                      onClick={() => {
                        handleReturnCourse()
                      }}
                      style={{ marginLeft: "10" }}
                    >
                      <i className="fa fa-times"></i> Return result
                    </Button>{" "}
                    <Button
                      disabled={selectedForceUids.length <= 0 ? true : false}
                      color="dark"
                      className="btn btn-sm  mb-2 ml-2"
                      onClick={() => {
                        forceCourseResultForward()
                      }}
                      style={{ marginLeft: "10" }}
                    >
                      {forceCourseForwardLOading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mr-3"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-4">Please wait...</span>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-arrow-alt-circle-right"></i> Force
                          forward
                        </>
                      )}
                    </Button>
                  </CardSubtitle>

                  <div className=" table-responsive">
                    {programCourseSemesterLoading ? (
                      <PlaceHolderLoader rows={4} type="table" columSize={12} />
                    ) : (
                      <Table
                        id="tech-companies-1"
                        className="table-sm table-striped table-bordered"
                      >
                        <Thead className="text-center">
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th>Forward</Th>
                            <Th>Course Ante</Th>
                            <Th>Forward Level</Th>
                            <Th>Force Forward</Th>
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {programSemesterResponse &&
                            programSemesterResponse
                              .slice(startIndex, endIndex)
                              .map((resultRows, index) => (
                                <Tr key={resultRows?.uid}>
                                  <Td>{startIndex + index + 1}</Td>
                                  <Td
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="form-check">
                                      {resultRows?.forwardStatus === 1 ? (
                                        <>
                                          <Input
                                            onChange={handleCheckboxChange}
                                            id={resultRows?.uid}
                                            name={resultRows?.uid}
                                            type="checkbox"
                                            color="primary"
                                            className="primary"
                                            style={{
                                              width: 25,
                                              height: 25,
                                              padding: 0,
                                              borderColor: "black",
                                              marginRight: 10,
                                              verticalAlign: "middle",
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          {forwardStatus(
                                            resultRows?.forwardStatus
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </Td>
                                  <Td>{resultRows?.course?.code}</Td>
                                  <Td>
                                    {resultRows?.forwardStatus === 0 ? (
                                      <>Instructor</>
                                    ) : resultRows?.forwardStatus === 1 ? (
                                      <>HOD</>
                                    ) : resultRows?.forwardStatus === 2 ? (
                                      <>Principal</>
                                    ) : resultRows?.forwardStatus === 3 ? (
                                      <>Published(Provisional)</>
                                    ) : (
                                      resultRows?.forwardStatus === 4 && (
                                        <>Published(Senate)</>
                                      )
                                    )}
                                  </Td>
                                  <Td>
                                    <div
                                      className="form-check"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <>
                                        {resultRows?.forwardStatus == 0 ? (
                                          <>
                                            <Input
                                              onChange={
                                                handleForceCheckboxChange
                                              }
                                              id={resultRows?.uid}
                                              name={resultRows?.uid}
                                              type="checkbox"
                                              color="primary"
                                              className="primary"
                                              style={{
                                                width: 25,
                                                height: 25,
                                                padding: 0,
                                                borderColor: "black",
                                                marginRight: 10,
                                                verticalAlign: "middle",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <strong>No action</strong>
                                        )}
                                      </>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                        </Tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      </LayoutHelper>
    </ForwardResultsContext.Provider>
  )
}
export default ForwardResultsToPrincipal
