import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import "flatpickr/dist/themes/material_blue.css"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import ToastHelper from "../../../../helpers/ToastHelper"
import Select from "react-select"
import { useMutation, useQuery } from "@apollo/client"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import { CREATE_STAFF } from "Modules/Users/Mutations/UserMutations"
const breadcrumbItem = [
  {
    title: "Payments Service",
    path: "/Students/Payments",
    isActive: false,
  },
  {
    title: "Request Service",
    path: "",
    isActive: true,
  },
];

const StudentPaymentServices = props => {

const [payment] = useMutation(CREATE_STAFF);

  const navigate = useNavigate()
  const onchangeValues = (e, input) => {
    validationType.setFieldValue(input, e.value)
  }

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      service: "",
    },
    validationSchema: Yup.object().shape({
      service: Yup.number().required("Service is required"),
    }),
    onSubmit: values => {
      console.log(values)
     
      payment({ variables: values })
      .then((response) => {
        // Handle the response
        console.log('Mutation response:', response);
      })
      .catch((error) => {
        // Handle errors
        console.error('Mutation error:', error);
      });
      ToastHelper("Service requested successful", "success")
      validationType.resetForm;
      //navigate('/Students/Payments')
    },
    
  });
  

  const [services, setServices] = useState([]),
    [fetching, setFetching] = useState(true)

  useEffect(() => {
    // setFetching(true)
    setFetching(false)
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])
  //
  const fetchData = () => {
    // API Call
    setServices([])
    setFetching(false)
    // setServicesImage('image_path')
  }

  const ServiceOpt = [
    { label: "Certificate Certification (10,000 TZS / 10 USD)", value: 5 },
    { label: "English Certificate (10,000 TZS / 10 USD)", value: 4 },
    { label: "Full Transcript (20,000 TZS / 20 USD)", value: 3 },
    { label: "Graduation Gown (20,000 TZS / 20 USD)", value: 1 },
    { label: "Partial Transcript (10,000 TZS / 10 USD)", value: 2 },
  ]

  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Request Control Numbers"
    >
      <Row>
        {fetching ? (
          <PlaceHolderLoader buttonShown={true} columSize={6} />
        ) : (
          <Col lg={6}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validationType.handleSubmit()
                    return false
                  }}
                >
                  <div className="mb-3">
                    <Label>Payment Services</Label>
                    <Select
                      // value={selectedGroup}
                      onChange={e => onchangeValues(e, "service")}
                      options={ServiceOpt}
                      className="select2-selection"
                    />

                    <Input
                      name="service"
                      placeholder="Enter yourAddresses"
                      type="hidden"
                      rows={3}
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.service || ""}
                      invalid={
                        !!(
                          validationType.touched.service &&
                          validationType.errors.service
                        )
                      }
                    />
                    {validationType.touched.service &&
                    validationType.errors.service ? (
                      <FormFeedback type="invalid">
                        {validationType.errors.service}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <SubmitButtonHelper type={'primary'} name={'Submit Request'} formik={validationType} />
                </Form>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </LayoutHelper>
  )
}

export default StudentPaymentServices;