import React, { useContext, useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Label,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"
import { CREATE_PROGRAM_SEMISTER } from "../Mutations/ProgramSemisterMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import Select from "react-select"
import showToast from "helpers/ToastHelper"
import { GET_PROGRAM_SEMISTERS } from "../Queries/ProgramSemisterQueries"
import { GET_PROGRAMS } from "Modules/Programs/Queries/ProgramQuaries"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ProgramSemisterContext } from "./ProgramSemisterList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import PropTypes from "prop-types";

const ProgramSemisterModal = (props) => {
  const { updateProgramSemisterData, setUpdateProgramSemisterData } =
    useContext(ProgramSemisterContext)
  const [ProgramSemister] = useMutation(CREATE_PROGRAM_SEMISTER, {
    client: registrationGraphQLClient,
  })

  const {program} = props
  // console.log(program)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_PROGRAM_SEMISTERS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const [semesters, setSemesters] = useState([])
  const {
    loading: yearLoading,
    error: yearsError,
    data: yearsData,
    refetch,
  } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const academicyears = yearsData?.getAcademicYears?.data?.items

  const studyYear = [
      { label: "Year 1", value: 1 },
      { label: "Year 2", value: 2 },
      { label: "Year 3", value: 3 },
      { label: "Year 4", value: 4 },
      { label: "Year 5", value: 5 },
    ]

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      studyYear: updateProgramSemisterData ? updateProgramSemisterData.studyYear : 1,
      semester: updateProgramSemisterData ? updateProgramSemisterData.semester : 1,
      coreCredits: updateProgramSemisterData ? updateProgramSemisterData.coreCredits : "",
      electiveCredits: updateProgramSemisterData ? updateProgramSemisterData.electiveCredits : "",
      programUid: program?.uid,
      academicYearUid: updateProgramSemisterData ? updateProgramSemisterData.academicYearUid : "",
      
    },

    validationSchema: Yup.object().shape({
      studyYear: Yup.number().required("Study year is required"),
      semester: Yup.string().required("Semester is required"),
      coreCredits: Yup.number().required("Semester Core Credits is required"),
      electiveCredits: Yup.number().required("Semester Elective Credits is required"),
      programUid: Yup.string().required("Program is required"),
      academicYearUid: Yup.string().required("Academic year is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      ProgramSemister({
        client: registrationGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateProgramSemisterData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_PROGRAM_SEMISTERS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerProgramSemester }) => {
          if (registerProgramSemester.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateProgramSemisterData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateProgramSemisterData(true) // Shikilia modal
          }
          showToast(
            registerProgramSemester.message,
            registerProgramSemester.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Program Structure
      </Button>
      <Modal
        isOpen={modalOpen || !!updateProgramSemisterData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramSemisterData
              ? "Edit Program Structure"
              : "Add New Program Structure"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Label>Program Name: {program?.name}</Label>

              <Input
                name="programUid"
                type="hidden"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.programUid || ""}
                invalid={!!(validation.touched.programUid && validation.errors.programUid)}
              />
              {validation.touched.programUid && validation.errors.programUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.programUid}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label>Academic Year</Label>
              <Select
                // value={selectedGroup}
                name="academicYearUid"
                onChange={e => {
                  validation.setFieldValue("academicYearUid", e.value)
                }}
                options={academicyears?.map(year => ({
                  value: year.uid,
                  label: year.name + "[" + (year.status===1 ? "Current" : "Not Current") +"]",
                }))}
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                defaultValue={updateProgramSemisterData?.academicYearUid}
                defaultInputValue={
                  () => {
                    if(updateProgramSemisterData) {
                      return updateProgramSemisterData?.academicYear?.status === 1
                          ? updateProgramSemisterData?.academicYear?.name + "[ Current ]"
                          : updateProgramSemisterData?.academicYear?.name + "[ Not Current ]"
                    }
                  }
                }
                className="select2-selection"
              />
              <Input
                name="academicYearUid"
                type="hidden"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.academicYearUid || ""}
                invalid={
                  !!(
                    validation.touched.academicYearUid &&
                    validation.errors.academicYearUid
                  )
                }
              />
              {validation.touched.academicYearUid &&
              validation.errors.academicYearUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.academicYearUid}
                </FormFeedback>
              ) : null}
            </div>
            <Row>
              <Col col={6}>
                <div className="mb-3">
                  <Label>Study Year</Label>
                  <Select
                    // value={selectedGroup}
                    name="studyYear"
                    onChange={e => {
                      validation.setFieldValue("studyYear", e.value)
                      let sem = []
                      setSemesters([])
                      // console.log(e.value)
                      let value = e.value * 2
                      for (let i = e.value * 2 - 1; i < value + 1; i++) {
                        sem.push({ label: "Semester " + i, value: i })
                      }
                      setSemesters(sem)
                    }}
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    options={studyYear}
                    className="select2-selection"
                    // defaultValue={updateProgramSemisterData?.studyYear}
                    // defaultInputValue={updateProgramSemisterData?.studyYear}
                  />
                  <Input
                    name="study_year"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.studyYear || 1}
                    invalid={
                      !!(
                        validation.touched.studyYear &&
                        validation.errors.studyYear
                      )
                    }
                  />
                  {validation.touched.studyYear &&
                  validation.errors.studyYear ? (
                    <FormFeedback type="invalid">
                      {validation.errors.studyYear}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col col={6}>
                <div className="mb-3">
                  <Label>Semester</Label>
                  <Select
                    // value={selectedGroup}
                    name="semester"
                    onChange={e => {
                      validation.setFieldValue("semester", e.value)
                    }}
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    options={semesters}
                    className="select2-selection"
                    // defaultValue={updateProgramSemisterData?.semester}
                    // defaultInputValue={updateProgramSemisterData?.semester}
                  />

                  <Input
                    name="study_year"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.semester || 1}
                    invalid={
                      !!(
                        validation.touched.semester &&
                        validation.errors.semester
                      )
                    }
                  />
                  {validation.touched.semester && validation.errors.semester ? (
                    <FormFeedback type="invalid">
                      {validation.errors.semester}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col col={6}>
                <div className="mb-3">
                  <Label>Core Credits</Label>
                  <Input
                    name="coreCredits"
                    type="number"
                    placeholder="Core Credits"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.coreCredits || ""}
                    invalid={
                      !!(
                        validation.touched.coreCredits &&
                        validation.errors.coreCredits
                      )
                    }
                  />
                  {validation.touched.coreCredits &&
                  validation.errors.coreCredits ? (
                    <FormFeedback type="invalid">
                      {validation.errors.coreCredits}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col col={6}>
                <div className="mb-3">
                  <Label>Elective Credits</Label>
                  <Input
                    name="electiveCredits"
                    type="number"
                    placeholder="Elective Credits"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.electiveCredits || ""}
                    invalid={
                      !!(
                        validation.touched.electiveCredits &&
                        validation.errors.electiveCredits
                      )
                    }
                  />
                  {validation.touched.electiveCredits &&
                  validation.errors.electiveCredits ? (
                    <FormFeedback type="invalid">
                      {validation.errors.electiveCredits}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateProgramSemisterData(false)
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

ProgramSemisterModal.propTypes = {
  program : PropTypes.array.isRequired
}

export default ProgramSemisterModal
