
import React, { useContext, useState, useEffect } from "react";
import DualListBox from "react-dual-listbox";
import { Form, Modal } from "reactstrap"
import { useMutation, useQuery } from "@apollo/client";
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient";
import { useFormik } from "formik";
import showToast from "helpers/ToastHelper";
import { StaffContext } from "./UsersList";
import { GET_ASSIGNED_GROUP, GET_NOT_ASSIGNED_GROUP } from "../Queries/UserQuery";
import { ASSIGN_GROUP } from "../Mutations/UserMutations";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"

const AssignGroupToUser = () => {
  const { assignGroup, setAssignGroup } = useContext(StaffContext);
  const [selected, setSelected] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const pagination = { offset: 0, limit: 1000, search: null }

  const [assignedGroups, setAssignedGroups] = useState([]);
  const [notAssignedGroups, setNotAssignedGroups] = useState([]);
  const [assignGroupsToUser] = useMutation(ASSIGN_GROUP);

  const {
    data: assignedGroupsData,
    loading: assignedGroupsLoading,
    error: assignedGroupsError,
  } = useQuery(GET_ASSIGNED_GROUP, {
    client: uaaGraphQLClient,
    variables: {
      userUid: assignGroup ? assignGroup?.user?.uid : false,
    },
    skip: !assignGroup,
    onError: (error) => {
      console.error("Error fetching assigned Groups:", error);
    },
  });

  // const {
  //   data: notAssignedGroupsData,
  //   loading: notAssignedGroupsLoading,
  //   error: notAssignedGroupsError,
  // } = useQuery(GET_NOT_ASSIGNED_GROUP, {
  //   client: uaaGraphQLClient,
  //   variables: {
  //       userUid: assignGroup ? assignGroup?.user.uid : false,
  //   },
  //   skip: !assignGroup,
  //   onError: (error) => {
  //     console.error("Error fetching not assigned Groups:", error);
  //   },
  // });


  const {
    data: notAssignedGroupsData,
    loading: notAssignedGroupsLoading,
    error: notAssignedGroupsError,
  } = useQuery(GET_NOT_ASSIGNED_GROUP, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    if (assignedGroupsData) {
      const assignedGroups =
        assignedGroupsData.getUserGroups.data.items;
      setAssignedGroups(assignedGroups);
    }
  }, [assignedGroupsData]);

  useEffect(() => {
    if (notAssignedGroupsData) {
      const notAssignedGroups =
        notAssignedGroupsData.getGroups.data.items;
      setNotAssignedGroups(notAssignedGroups);
    }
  }, [notAssignedGroupsData]);

  useEffect(() => {
    // Update the selected value based on assignedGroups
    setSelected(assignedGroups.map((group) => group.uid));
  }, [assignedGroups]);

  const formik = useFormik({
    initialValues: {
      Groups: selected,
    },
    onSubmit: async () => {
      try {
        const { data } = await assignGroupsToUser({
          client: uaaGraphQLClient,
          variables: {
            inputs: {
              groups: selected,
              userUid: assignGroup?.user?.uid,
            },
          },
        });

        if (data.assignGroupsToUser.code === 8000) {
          setModalOpen(false);
          setAssignGroup(false); // Close the modal
        } else {
          setModalOpen(false);
          setAssignGroup(false);
          // setSubmitting(false);
        }
      } catch (error) {
        setModalOpen(false);
        setAssignGroup(false);
        console.error("Error assigning Groups:", error);
      }
    },
  });

  return (
    <div>
      <Modal
        size="lg"
        isOpen={modalOpen || !!assignGroup}
        backdrop={"static"}
        id="staticBackdrop"
      >
        {/* Modal content */}
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!assignGroup
              ? "Assign Group(s) To " + assignGroup?.user?.firstName +" "+assignGroup?.user?.lastName
              : "Group Not Selected"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false);
              setAssignGroup(false);
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return null
          }}
        >
        <div className="modal-body">
          <DualListBox
            canFilter
            filterCallback={(option, filterInput) => {
              if (filterInput === "") {
                return true;
              }
              return new RegExp(filterInput, "i").test(option.label);
            }}
            options={[
              ...assignedGroups.map((group) => ({
                value: group?.uid,
                label: group?.name,
              })),
              ...notAssignedGroups.map((group) => ({
                value: group?.uid,
                label: group?.name,
              })),
            ]}
            selected={selected}
            onChange={(value) => setSelected(value)}
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              setModalOpen(false);
              setAssignGroup(false);
            }}
          >
            Close
          </button>
          <SubmitButtonHelper name="Submit" type="primary"
                              formik={formik} />
        </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AssignGroupToUser;