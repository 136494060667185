import React, { useContext, useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import showToast from "helpers/ToastHelper"
import { LanguageProficiencyContext } from "./LanguageProficiencyList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { CREATE_STAFF_LANGUAGES } from "Modules/Users/Mutations/UserMutations"
import { GET_STAFF_LANGUAGES } from "Modules/Users/Queries/UserQuery"
import { useMutation, useQuery } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { StaffProfileContext } from "../StaffProfile"

const LanguageProficiencyModal = () => {
  const { updateStaffLanguageData, setUpdateStaffLanguageData } = useContext(
    LanguageProficiencyContext
  )
  const [nextOfKinMutation] = useMutation(CREATE_STAFF_LANGUAGES)
  const [modalOpen, setModalOpen] = useState(false)
  
  const { updateStaffData } =
    useContext(StaffProfileContext)
  const [proficiencies, setProficiency] = useState([])


  useEffect(() => {
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])

  const fetchData = () => {
    setProficiency([
      { label: "BEGINNER", value: "BEGINNER" },
      { label: "INTERMEDIATE", value: "INTERMEDIATE" },
      { label: "ADVANCED", value: "ADVANCED" },
    ])
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      language: updateStaffLanguageData ? updateStaffLanguageData.language : "",
      proficiency: updateStaffLanguageData
        ? updateStaffLanguageData.proficiency
        : "",
      userUid: updateStaffData ? updateStaffData?.uid : "",
    },

    validationSchema: Yup.object().shape({
      language: Yup.string().required("Language is required"),
      //proficiency: Yup.string().required("Proficiency is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      nextOfKinMutation({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateStaffLanguageData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_STAFF_LANGUAGES,
            variables: {
              userUid: updateStaffData?.uid,
            },
          },
        ],
        onCompleted: ({ registerStaffLanguages }) => {
          if (registerStaffLanguages.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateStaffLanguageData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
          setSubmitting(false)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Language Proficiency
      </Button>
      <Modal
        isOpen={modalOpen || !!updateStaffLanguageData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateStaffLanguageData
              ? "Edit Language Proficiency"
              : "Add New Language Proficiency"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateStaffLanguageData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="language" className="col-form-label">
                Language:
              </label>
              <Input
                type="text"
                name="language"
                className="form-control"
                id="language"
                placeholder="Enter Language"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.language ||
                  ""
                }
                invalid={
                  !!(validation.touched.language && validation.errors.language)
                }
              />
              {validation.touched.language && validation.errors.language ? (
                <FormFeedback type="invalid">
                  {validation.errors.language}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="proficiency" className="col-form-label">
                Proficiency:
              </label>
              <Select
                // value={selectedGroup}
                name="proficiency"
                onChange={e => {
                  validation.setFieldValue("proficiency", e.value)
                }}
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                options={proficiencies}
                className="select2-selection"
              />
              <Input
                name="proficiency"
                type="hidden"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.proficiency || ""}
                invalid={
                  !!(
                    validation.touched.proficiency &&
                    validation.errors.proficiency
                  )
                }
              />
            </div>
            <Input
              type="hidden"
              name="proficiency"
              className="form-control"
              id="proficiency"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={
                validation.values.proficiency ||
                updateStaffLanguageData?.proficiency ||
                ""
              }
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateStaffLanguageData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default LanguageProficiencyModal
