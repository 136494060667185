import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody, CardSubtitle,
    Col,
    Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle, Input,
    Row
} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {useLazyQuery, useQuery} from "@apollo/client";
import {limit} from "../../../helpers/UrlHelper";
import {registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {GET_ALL_STUDENT_SEMINARS} from "../Queries/DesignationQueries";
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../helpers/PaginationHelper";
import {useNavigate} from "react-router-dom";
import LayoutHelper from "../../../helpers/LayoutHelper";
import CourseFormModel from "../../Courses/Views/CourseFormModel";

const breadcrumbItem =  [
    {
        title: 'Dissertation',
        path: '',
        isActive: true,
    },
]
const StudentSeminers = () => {
    const [pageSize, setPageSize] = useState(limit),
        [currentPage, setCurrentPage] = useState(0),
        navigate = useNavigate()
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const pagination = { offset: 0, limit: limit, search: null }
    const { loading, error:programError, data } = useQuery(GET_ALL_STUDENT_SEMINARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })

    const [searchSeminars,{ loading:searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_ALL_STUDENT_SEMINARS, {client: registrationGraphQLClient})
    let seminars = searchData?.getSeminars?.data ? searchData?.getSeminars?.data.items : data?.getSeminars?.data.items
    let totalCount = searchData?.getSeminars?.data ? searchData?.getSeminars?.data.totalCount : data?.getSeminars?.data.totalCount
    // let seminars = data?.getAllStudentSeminars?.data

    const searchValues = {
        search: "",
        status:null
    }
    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
        status: Yup.number,
    })
    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })
    const search = () => {
        const value = formikSearch.values.search
        if (value.length >= 3) {
            let data = {
                search: formikSearch.values.search,
                status: Number(searchValues.status)
            }
            searchSeminars({ variables: { pagination: { ...pagination, ...data } } })

        }
    }


    const searchStatus = (e) =>{
        searchValues.status = e.target.value
        let data = {
            search: formikSearch.values.search,
            status: Number(e.target.value)
        }
        if(e.target.value) searchSeminars({ variables: { pagination: { ...pagination, ...data } } })
        // if(e.target.value) console.log(searchValues.status)
    }

    const handleClick = data => {
        setCurrentPage(data.selected)
    }
    const toggleDropdown = sem => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [sem.uid]: !prevState[sem.uid],
        }))
    }
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student Dissertation List">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle className="mb-1">
                                <Row>
                                    <Col className="col-lg-6 col-6">
                                        <form className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    onKeyUp={search}
                                                    placeholder="Search..."
                                                    {...formikSearch.getFieldProps("search")}

                                                />
                                                {!searchLoading && (
                                                    <span className="bx bx-search-alt" />
                                                )}
                                                {searchLoading && (
                                                    <span className="bx bx-search-alt bx-spin" />
                                                )}
                                            </div>
                                        </form>
                                    </Col>

                                    <Col sm="6" className="m">
                                        <Row>
                                            <Col lg={8}></Col>
                                            <Col lg={4}>
                                                <label htmlFor="">Filter By Status</label>
                                                <Input
                                                    name="status"
                                                    type="select"
                                                    className="form-select"
                                                    onChange={(e)=>{
                                                        searchStatus(e)
                                                        searchValues.status = e.target.value
                                                    } }
                                                >
                                                    <option value="">-- --</option>
                                                    <option value={0}>Un submitted/Pending</option>
                                                    <option value={1}>Seminar Date Allocation Pending</option>
                                                    <option value={2}>Seminar Assessment Score Pending</option>
                                                    <option value={3}>Seminar Results Published</option>

                                                </Input>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardSubtitle>

                            {loading || searchLoading ? (
                                <PlaceHolderLoader columSize={12} type="table" rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >
                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th>Name</Th>
                                                    <Th>Reg No</Th>
                                                    <Th>Seminar Type</Th>
                                                    <Th>Title</Th>
                                                    <Th>Status</Th>
                                                    <Th>Action</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {seminars?.map((sem, index) => (
                                                    <Tr key={sem?.uid}>
                                                        <Td>{currentPage * pageSize + index + 1}</Td>
                                                        <Td>{sem?.fullName}</Td>
                                                        <Td>{sem?.registrationNumber}</Td>
                                                        <Td>{sem?.seminarTypes?.name}</Td>
                                                        <Td>{sem?.title}</Td>
                                                        <Td>

                                                            {sem.status === 0 && <span className="badge bg-danger"> Un submitted/Pending ...</span>}
                                                            {sem.status === 1 && <span className="badge bg-danger"> Seminar Date Allocation Pending..</span>}
                                                            {sem.status === 2 && <span className="badge bg-danger"> Seminar Assessment Score Pending..</span>}
                                                            {sem.status === 3 && <span className="badge bg-success"> Seminar Results Published</span>}
                                                        </Td>
                                                        <Td>
                                                            <Dropdown
                                                                isOpen={dropdownOpen[sem.uid]}
                                                                toggle={() => toggleDropdown(sem)}
                                                            >
                                                                <DropdownToggle color="" caret>
                                                                    <i
                                                                        className="fas fa-ellipsis-v"
                                                                        style={{ color: "blue" }}
                                                                    />
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem
                                                                        onClick={() => {
                                                                            navigate("/dissertation/view", {
                                                                                state: { sem: sem },
                                                                            })
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className="fas fa-th-list"
                                                                            style={{
                                                                                color: "primary",
                                                                                marginRight: "10px",
                                                                            }}
                                                                        />
                                                                        <span>Detail</span>
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>

                                    <PaginationHelper
                                        currentPage={currentPage}
                                        pageItemNumber={seminars?.length}
                                        totalCount={totalCount}
                                        pageSize={pageSize}
                                        handleClick={handleClick}
                                    />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default StudentSeminers;