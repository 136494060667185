import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import Pages404 from "../../Utility/pages-404"
import ExaminerList from "../View/ExaminerList"
import StudentExaminer from "../View/StudentExaminer"
const ExaminerRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ExaminerList />} />
      <Route path="/students" element={<StudentExaminer />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}
export default ExaminerRoutes
