import React, { useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Input,
  FormFeedback,
  Form,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Modal, Label, CardTitle
} from "reactstrap"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient, uaaGraphQLClient } from "../../../Authentication/ApolloClient"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import {
  RECOVER_APPLICANT_EMAIL, RECOVER_APPLICANT_PASSWORD
} from "../../../Users/Mutations/UserMutations"
import showToast from "../../../../helpers/ToastHelper"
import {
  showWaringConf
} from "../../../../helpers/DeleteWaringHelper"
import { GET_APPLICANT_BY_USERNAME } from "../Queries/ApplicantQueries"
import Select from "react-select"
import { GET_APPLICATION_LEVELS, GET_APPLICATION_METHOD_BY_LEVEL } from "../../../Applicant/Queries/ApplicantQueries"
import { UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD_BY_ADMISSION_OFFICER } from "../../../Applicant/Mutations/ApplicantMutation"

const breadcrumbItem = [
  {
    title: "Applicant Recovery",
    path: "",
    isActive: true
  }
]
const ApplicantEmailRecovery = () => {
  const [postRecover] = useMutation(RECOVER_APPLICANT_EMAIL, {
    client: uaaGraphQLClient
  })

  const [updateApplicationMethodAndQualification] = useMutation(UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD_BY_ADMISSION_OFFICER, {
    client: onlineApplicationGraphQLClient
  })


  const [modalOpen, setModalOpen] = useState(false)
  const [applicant, setApplicant] = useState(null)
  const [reset, setReset] = useState(false)
  // const useS
  const [
    searchStudent,
    { loading: searchLoading, error: searchError, data: searchData }
  ] = useLazyQuery(GET_APPLICANT_BY_USERNAME, {
    client: uaaGraphQLClient,
    fetchPolicy: "no-cache"
  })

  let user = searchData?.getApplicantByUsername?.data

  const { loading, error, data } = useQuery(GET_APPLICATION_LEVELS, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen,
    fetchPolicy: "no-cache"
  })

  const [
    searchApplicationMethod,
    { loading: methodLoading, error: searchErrorr, data: methodData }
  ] = useLazyQuery(GET_APPLICATION_METHOD_BY_LEVEL, { client: onlineApplicationGraphQLClient })

  const getLevelMethod = (levelUid) => {
    searchApplicationMethod({
      variables: { levelUid: levelUid },
      fetchPolicy: "network-only"
    })
  }
  let methods = methodData?.getApplicationMethodByLevel?.data

  const levels = data?.getApplicationLevel?.data

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: ""
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Username is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      // ToastHelper("Waiting for Backend", "info")
      searchStudent({
        variables: {
          username: values.username
        }
      })
        .then(response => {
          // Handle the response
          setSubmitting(false)
        })
        .catch(error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        })
    }
  })

  const updateApplicantMethodFormik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      qualificationMethodUid: "",
      applicationLevelUid: "",
      applicantUid: applicant?.uid
    },
    validationSchema: Yup.object().shape({
      qualificationMethodUid: Yup.string().required("This field is required"),
      applicationLevelUid: Yup.string().required("This field is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      updateApplicationMethodAndQualification({
        variables: {
          inputs: { ...values }
        },
        onCompleted: ({ updateApplicantLevelAndQualificationMethodByAdmissionOfficer }) => {
          if (updateApplicantLevelAndQualificationMethodByAdmissionOfficer.code === 8000) {
            setModalOpen(false)
            setSubmitting(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false) // Close the modal
          }
          showToast(
            updateApplicantLevelAndQualificationMethodByAdmissionOfficer.message,
            updateApplicantLevelAndQualificationMethodByAdmissionOfficer.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          console.error("Mutation error:", error)
        }
      })
    }
  })


  const updateValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      userUid: user?.uid,
      phoneNumber: user?.phoneNumber,
      email: user?.email ? user?.email : ""
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string().required("Phone Number year is required"),
      email: Yup.string().required("E-mail year is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      // ToastHelper("Waiting for Backend", "info")
      postRecover({
        variables: {
          inputs: values
        }
      })
        .then(response => {
          // Handle the response
          setSubmitting(false)
        })
        .catch(error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        })
    }
  })

  const [finalizeReset] = useMutation(RECOVER_APPLICANT_PASSWORD, {
    client: uaaGraphQLClient,
    variables: { uid: updateValidation.values.userUid }
  })


  const confirmReset = () => {
    finalizeReset({
      variables: {
        uid: updateValidation.values.userUid
      }
    })
      .then(response => {
        // console.log(response)
        // Handle the response
        showToast(
          response.data.changeApplicantPasswordToDefault.message,
          response.data.status ? "success" : "error"
        )
        setReset(false)
      })
      .catch(error => {
        setReset(false)
        // Handle errors
        // console.error('Mutation error:', error);
      })
  }

  const handleReset = () => {
    if (updateValidation.values.userUid !== "") {
      showWaringConf(
        () => {
          setReset(true)
          confirmReset()
        },
        "Reset Default Password",
        "Are you sure that you need to reset this user password o default password?\n New password will be Sua12345",
        "warning",
        "Reset",
        () => {
          // console.log("Canceled")
          showToast("Process Terminated", "info")
        }
      )
    } else {
      showToast("Please Select Student to push to LDAP", "warning")
    }
  }
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = std => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [std.uid]: !prevState[std.uid]
    }))
  }

  const levelsOptions = levels?.map(level => ({
    label: level?.name,
    value: level?.uid
  }))

  const methodOptions = methods?.map(level => ({
    label: level?.name,
    value: level?.uid
  }))

  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Recover Applicant Details"
    >
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-1">
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return null
                  }}
                >
                  <Row>
                    <Col className="col-lg-6 col-12">
                      <label htmlFor="name" className="col-form-label">
                        Username:
                      </label>
                      <Input
                        type="text"
                        name="username"
                        className="form-control"
                        id="username"
                        placeholder="Enter Username"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username}
                        invalid={
                          validation.touched.username &&
                          validation.errors.username
                            ? true
                            : false
                        }
                      />
                      {validation.touched.username &&
                      validation.errors.username ? (
                        <FormFeedback type="invalid">
                          {validation.errors.username}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col className="col-lg-2 col-12">
                      <br />
                      <br />
                      <SubmitButtonHelper
                        name="Submit"
                        type="primary"
                        formik={validation}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {searchLoading ? (
                <PlaceHolderLoader type="table" columSize={12} rows={3} />
              ) : (
                <>
                  {user && (
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            updateValidation.handleSubmit()
                            return null
                          }}
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="0">Full Name</Th>
                                <Th data-priority="0">Sex</Th>
                                <Th data-priority="0">E-mail</Th>
                                <Th data-priority="0">Phone Number</Th>
                                <Th>Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Td>{`${user?.firstName} ${
                                user?.middleName !== undefined
                                  ? user?.middleName
                                  : ""
                              } ${user?.lastName}`}</Td>
                              <Td>{user?.gender}</Td>
                              <Td>
                                <Input
                                  type="text"
                                  name="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Enter E-mail"
                                  onChange={updateValidation.handleChange}
                                  onBlur={updateValidation.handleBlur}
                                  value={updateValidation.values.email}
                                  invalid={
                                    !!(
                                      updateValidation.touched.email &&
                                      updateValidation.errors.email
                                    )
                                  }
                                />
                                {updateValidation.touched.email &&
                                updateValidation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {updateValidation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </Td>
                              <Td>
                                <Input
                                  type="text"
                                  name="phoneNumber"
                                  className="form-control"
                                  id="phoneNumber"
                                  placeholder="Enter phone Number"
                                  onChange={updateValidation.handleChange}
                                  onBlur={updateValidation.handleBlur}
                                  value={updateValidation.values.phoneNumber}
                                  invalid={
                                    !!(
                                      updateValidation.touched.phoneNumber &&
                                      updateValidation.errors.phoneNumber
                                    )
                                  }
                                />
                                {updateValidation.touched.phoneNumber &&
                                updateValidation.errors.phoneNumber ? (
                                  <FormFeedback type="invalid">
                                    {updateValidation.errors.phoneNumber}
                                  </FormFeedback>
                                ) : null}
                              </Td>
                              <Td>
                                {reset ? (
                                  <>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                                    ...
                                  </>
                                ) : (
                                  <Dropdown
                                    isOpen={dropdownOpen[user.uid]}
                                    toggle={() => toggleDropdown(user)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "blue" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          handleReset()
                                        }}
                                      >
                                        <i
                                          className="fas fa-key"
                                          style={{
                                            color: "red",
                                            marginRight: "10px"
                                          }}
                                        />
                                        <span>Recover Default Password</span>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setModalOpen(true)
                                          setApplicant(user)
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{
                                            color: "green",
                                            marginRight: "10px"
                                          }}
                                        />
                                        <span>Update applicant entry level</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                )}
                              </Td>
                            </Tbody>
                          </Table>
                          <div className="float-end">
                            {!reset && (
                              <SubmitButtonHelper
                                name="Update"
                                type="primary"
                                formik={updateValidation}
                              />
                            )}
                          </div>
                        </Form>
                      </div>
                    </div>
                  )}
                </>
              )}
              <Modal
                isOpen={modalOpen}
                backdrop={"static"}
                id="staticBackdrop"
                size="lg"
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    {`Applicant application level Swapping`}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                      setModalOpen(false)
                    }}
                    aria-label="Close"
                  ></button>
                </div>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    updateApplicantMethodFormik.handleSubmit()
                    return null
                  }}
                >
                  <div className="modal-body mt-4">
                    <Row>
                      <>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Application level of Study</Label>
                            <Select
                              name="applicationLevelUid"
                              onChange={option => {
                                getLevelMethod(option.value)
                                updateApplicantMethodFormik.setFieldValue("applicationLevelUid", option.value)
                              }}
                              options={levelsOptions}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999
                                })
                              }}
                              isLoading={loading}
                            />
                            <Input
                              name="programName"
                              placeholder="Select Region"
                              type="hidden"
                              rows={2}
                              onChange={updateApplicantMethodFormik.handleChange}
                              onBlur={updateApplicantMethodFormik.handleBlur}
                              invalid={
                                !!(
                                  updateApplicantMethodFormik.touched.applicationLevelUid &&
                                  updateApplicantMethodFormik.errors.applicationLevelUid
                                )
                              }
                            />
                            {updateApplicantMethodFormik.touched.applicationLevelUid &&
                            updateApplicantMethodFormik.errors.applicationLevelUid ? (
                              <FormFeedback type="invalid">
                                {updateApplicantMethodFormik.errors.applicationLevelUid}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label>Application method</Label>
                            <Select
                              name="qualificationMethodUid"
                              onChange={e => {
                                updateApplicantMethodFormik.setFieldValue("qualificationMethodUid", e.value)
                              }}
                              isLoading={methodLoading}
                              options={methodOptions}
                              className="select2-selection"
                              styles={{
                                menu: base => ({
                                  ...base,
                                  position: "absolute",
                                  zIndex: 9999
                                })
                              }}
                            />
                            <Input
                              name="qualificationMethodUid"
                              placeholder="Select Region"
                              type="hidden"
                              rows={2}
                              onChange={updateApplicantMethodFormik.handleChange}
                              onBlur={updateApplicantMethodFormik.handleBlur}
                              invalid={
                                !!(
                                  updateApplicantMethodFormik.touched.qualificationMethodUid &&
                                  updateApplicantMethodFormik.errors.qualificationMethodUid
                                )
                              }
                            />
                            {updateApplicantMethodFormik.touched.qualificationMethodUid &&
                            validation.errors.qualificationMethodUid ? (
                              <FormFeedback type="invalid">
                                {updateApplicantMethodFormik.errors.qualificationMethodUid}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <SubmitButtonHelper
                              name="Submit"
                              type="primary"
                              formik={updateApplicantMethodFormik}
                            />
                          </div>
                        </Col>
                      </>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        setModalOpen(false)
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Form>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default ApplicantEmailRecovery
