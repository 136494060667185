import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Nav, NavItem, NavLink } from "reactstrap"
import { StatisticsApplicationsChart } from "./JobCharts"

import { useDispatch, useSelector } from "react-redux"
import { getStatisticData } from "../../../store/dashboard-asims/actions"
import statistical_data from "./statistical_data"
import { useQuery } from "@apollo/client"
import { GET_APPLICANT_COUNT_BY_DATE_GRAPH } from "../../OnlineApplication/Reports/Queries/ApplicationReportQueries"
import { onlineApplicationGraphQLClient } from "../../Authentication/ApolloClient"
import { ApexChartApplication } from "../../OnlineApplication/Reports/Views/ApexChartApplication"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"
import { GET_CURRENT_ADMISSION_YEAR } from "../../AdmissionYear/Queries/AdmissionYearQueries"
import { limit } from "../../../helpers/UrlHelper"

const StatisticsApplications = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const [duration, setDuration] = useState("year")

  const {
    loading,
    error,
    data,
    refetch
  } = useQuery(GET_APPLICANT_COUNT_BY_DATE_GRAPH, { client: onlineApplicationGraphQLClient })

  const {
    loading: loadingYear,
    error: yearError,
    data: yearResult
  } = useQuery(GET_CURRENT_ADMISSION_YEAR, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })
  const activeAdmissionYear = yearResult?.getActiveAdmissionYear?.data

  const results = data?.getApplicantCountByDate?.data?.items

  const dispatch = useDispatch()
  const changeDuration = duration => {
    setDuration(duration)
    dispatch(getStatisticData(duration))
  }

  useEffect(() => {
    dispatch(getStatisticData(duration))
  }, [dispatch])

  const { statistic_data } = useSelector(state => ({
    statistic_data: statistical_data
  }))

  useEffect(() => {
    console.log("statistic_data", statistic_data)
  }, [statistic_data])

  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div className="d-sm-flex flex-wrap">
              <h4 className="card-title mb-4">Daily number of applicants for first admission window for {activeAdmissionYear?.name} (Total number of applicants { data?.getApplicantCountByDate?.data?.totalCount})</h4>
              {/*<div className="ms-auto">*/}
              {/*  <Nav pills>*/}
              {/*    <NavItem>*/}
              {/*      <NavLink*/}
              {/*        className={duration === "week" ? "active" : ""}*/}
              {/*        href="#"*/}
              {/*        onClick={() => changeDuration("week")}*/}
              {/*      >*/}
              {/*        Week*/}
              {/*      </NavLink>*/}
              {/*    </NavItem>*/}
              {/*    <NavItem>*/}
              {/*      <NavLink*/}
              {/*        className={duration === "month" ? "active" : ""}*/}
              {/*        href="#"*/}
              {/*        onClick={() => changeDuration("month")}*/}
              {/*      >*/}
              {/*        Month*/}
              {/*      </NavLink>*/}
              {/*    </NavItem>*/}
              {/*    <NavItem>*/}
              {/*      <NavLink*/}
              {/*        className={duration === "year" ? "active" : ""}*/}
              {/*        href="#"*/}
              {/*        onClick={() => changeDuration("year")}*/}
              {/*      >*/}
              {/*        Year*/}
              {/*      </NavLink>*/}
              {/*    </NavItem>*/}
              {/*  </Nav>*/}
              {/*</div>*/}
            </div>
            {
              loading || loadingYear ? (
                <PlaceHolderLoader type="table" columSize={12} rows={5} />
              ) : (
                <ApexChartApplication results={results} year={activeAdmissionYear} />
              )
            }
            {/*<StatisticsApplicationsChart*/}
            {/*  seriesData={statistic_data}*/}
            {/*  dataColors='["--bs-primary", "--bs-success", "--bs-warning", "--bs-info"]'*/}
            {/*  dir="ltr"*/}
            {/*/>*/}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default StatisticsApplications
