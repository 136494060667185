import React, {useState} from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown, Label, Input, FormFeedback, Form,
} from "reactstrap"

import {Table, Thead, Tbody, Tr, Th, Td, td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
import {useFormik} from "formik";
import {registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {useLazyQuery, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../AcademicYears/Queries/AcademicYearsQueries";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import Select from "react-select";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import {GET_HOD_STUDENT_REGISTERED_COURSES} from "../../Courses/Queries/CourseQueries";
import showToast from "../../../helpers/ToastHelper";


const breadcrumbItem = [
    {
        title: "Student Course Registration",
        path: "",
        isActive: true,
    },
]

const HoDStudentCourseRegistration = () => {
    const [semesters,setSemesters] = useState([])
    const studyYear = [
        { label: "Year 1", value: 1 },
        { label: "Year 2", value: 2 },
        { label: "Year 3", value: 3 },
        { label: "Year 4", value: 4 },
        { label: "Year 5", value: 5 },
    ]
    let [
        getCourseResults,
        { loading: reportLoading, error: reportError, data: reportData },
    ] = useLazyQuery(GET_HOD_STUDENT_REGISTERED_COURSES, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            registrationNumber: "",
            academicYearUid: "",
            semester:"",
            studyYear:"",
        },
        validationSchema: Yup.object().shape({
            studyYear: Yup.number().required("Study Year is required"),
            // courseUid: Yup.string().required("Course is required"),
            semester: Yup.string().required("Semester is required"),
            registrationNumber: Yup.string().required("Registration Number is required"),
            academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            getCourseResults({
                variables: {
                    input:values
                },
                fetchPolicy: 'no-cache',
                client: registrationGraphQLClient,
                onCompleted: ({ getHodStudentCourseRegistration }) => {
                    showToast(
                        getHodStudentCourseRegistration.message,
                        getHodStudentCourseRegistration.code === 8000 ? "success" : "error"
                    )
                    if (getHodStudentCourseRegistration.code === 8000) {
                        // DownloadExcelFile(getHoDStudentCourseRegistration?.data?.base64Data,getHoDStudentCourseRegistration?.data?.fileName)
                    }
                    setSubmitting(false)
                },
                onError: error => {
                    console.error("Mutation error:", error)
                    setSubmitting(false)
                },
            })

        },

    });

    const  courses = reportData?.getHodStudentCourseRegistration?.data

    const searchValues = {
        search: "",
    }

    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })

    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })

    const pagination = { offset: 0, limit: 20, search: null }

    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })

    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items


    // if (courseData?.getProgramCourseByProgramSemesterUid?.data)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student Course Registration">
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label for="registrationNumber">
                                                Student Registration Number
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="registrationNumber"
                                                name="registrationNumber"
                                                placeholder="Enter Sudent Registration Number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.registrationNumber}
                                                invalid={
                                                    !!(validation.touched.registrationNumber && validation.errors.registrationNumber)
                                                }
                                            />
                                            {
                                                validation.touched.registrationNumber && validation.errors.registrationNumber ? (
                                                    <FormFeedback type="invalid">{validation.errors.registrationNumber}</FormFeedback>
                                                ): null
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                onChange={(e) => {
                                                    if (e) {
                                                        validation.setFieldValue("academicYearUid", e.value)
                                                    } else {
                                                        validation.setFieldValue("academicYearUid", "")
                                                    }
                                                }}
                                                onInputChange={e => {
                                                    // search(e, 'academicYear')
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.uid,
                                                    label: year.name,
                                                }))}

                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYearUid}
                                                invalid={
                                                    !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                }
                                            />
                                            {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYearUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label>Study Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                // name="study_year"
                                                onChange={(e) => {
                                                    validation.setFieldValue('studyYear', e.value)
                                                    let sem = []
                                                    setSemesters([])
                                                    // console.log(e.value)
                                                    let value = e.value * 2
                                                    for (let i = (e.value * 2) - 1; i < value + 1; i++) {
                                                        sem.push({label: 'Semester ' + i, value: i})
                                                    }
                                                    setSemesters(sem)
                                                }}
                                                options={studyYear}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="study_year"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.studyYear || ""}
                                                invalid={
                                                    !!(validation.touched.studyYear && validation.errors.studyYear)
                                                }
                                            />
                                            {validation.touched.studyYear && validation.errors.studyYear ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.studyYear}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label>Semesters</Label>
                                            <Select
                                                onChange={e => {
                                                    if (e) {
                                                        validation.setFieldValue("semester", e.value)
                                                    } else {
                                                        validation.setFieldValue("semester", "")
                                                    }
                                                }}

                                                options={semesters}
                                                className="select2-selection"
                                                // isLoading={semesterLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="semesterUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.semester || ""}
                                                invalid={
                                                    !!(validation.touched.semester && validation.errors.semester)
                                                }
                                            />
                                            {validation.touched.semester && validation.errors.semester ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.semester}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="float-end">
                                            <SubmitButtonHelper type={'primary'} name={'Submit'}
                                                                formik={validation}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                {courses && !reportLoading &&
                    <Col lg={7}>
                        <Card>
                            <CardBody>
                                <div className="table-responive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>S/No</th>
                                            <th>Course Ante</th>
                                            <th>Course Name</th>
                                            <th>Nature</th>
                                            <th>Credit Hours</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {courses?.map((c, index) => (
                                            <tr key={c?.uid}>
                                                <td align="center">{index + 1}</td>
                                                <td align="center">{c?.programCourse?.course.code}</td>
                                                <td align="left">{c?.programCourse?.course.name}</td>
                                                <td align="center">{c?.programCourse?.courseCategory?.name}</td>
                                                <td align="center">{c?.programCourse?.credit}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                }

            </Row>
        </LayoutHelper>
    )
}

export default HoDStudentCourseRegistration
