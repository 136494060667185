import {Route, Routes} from "react-router-dom";
import Pages404 from "../../Modules/Utility/pages-404";
import React from "react";
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard";
import CourseRoutes from "../../Modules/Courses/Routes/CourseRoutes";
import CourseCategoriesRoutes from "../../Modules/CourseCategories/Routes/CourseCategoriesRoutes";
import CourseAllocationRoutes from "../../Modules/CourseAllocation/Routes/CourseAllocationRoutes";
import ProgramRoutes from "../../Modules/Programs/Routes/ProgramRoutes";
import HodRoute from "../../Modules/HoD/Routes/HoDRoute";
import SupervisorRoutes from "../../Modules/ResearchSupervisor/Routes/SupervisorRoutes";
import ExaminerRoutes from "../../Modules/ResearchExaminer/Routes/ExaminerRoutes";
import InstructorRoutes from "../../Modules/Instructor/Routes/InstructorRoutes";
import PrincipalRoutes from "../../Modules/Principal/Routes/PrincipalRoutes";
import SenateRoutes from "../../Modules/Senate/Routes/SenateRoutes";
import CategoryRoutes from "../../Modules/ProgramCategories/Routes/CategoryRoutes";
import SignaturePadRoutes from "../../Modules/SignaturePad/Routes/SignaturePadRoutes";
import StudentsList from "Modules/Students/Views/StudentsList";
import ProgramCoursesRoutes from "../../Modules/ProgramCourseAssessments/Routes/ProgramCourseAssessmentRoutes"
import CoreCourseRegistration from "../../Modules/Courses/Views/CoreCourseRegistration"

const ExaminationRoutes = () => {
    return (
        <Routes>
            <Route path="dashboard" element={<AsimsDashboard />} />
            <Route path="/courses/*" element={<CourseRoutes />} />
            <Route path="/course-categories/*" element={<CourseCategoriesRoutes />} />
            <Route path="/course-allocation/*" element={<CourseAllocationRoutes />} />
            <Route path="/course-assessment/*" element={<ProgramCoursesRoutes />} />
            <Route path="/programs/*" element={<ProgramRoutes />} />
            <Route path="/hod/*" element={<HodRoute />} />
            <Route path="/supervisors/*" element={<SupervisorRoutes />} />
            <Route path="/examiners/*" element={<ExaminerRoutes />} />
            <Route path="/instructor/*" element={<InstructorRoutes />} />
            <Route path="/principal/*" element={<PrincipalRoutes />} />
            <Route path="/senate/*" element={<SenateRoutes />} />
            <Route path="/students" element={<StudentsList/>}/>
            <Route path="/core-course-registration" element={<CoreCourseRegistration/>}/>
            <Route index={true} path="/signaturepad/*" element={<SignaturePadRoutes />} />


            <Route path="*" element={<Pages404 />} />
            {/* Add more student-related routes here */}
        </Routes>
    );
}

export default ExaminationRoutes;