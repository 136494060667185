// export default FailedAccountList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_FAILED_TASKS } from "Modules/Users/Queries/UserQuery"
import { RESEND_TASK } from "Modules/Users/Mutations/UserMutations"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { showTaskWarning } from "helpers/DeleteWaringHelper"

const breadcrumbItem = [
  {
    title: "Failed Task List",
    path: "",
    isActive: true,
  },
]

export const FailedAccountContext = createContext() //Create data to transfer to child modal

const FailedAccountList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedTask, setSelectedTask] = useState(null) //Get data for deleting

  const { loading, error, data } = useQuery(GET_FAILED_TASKS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const [resendTask] = useMutation(RESEND_TASK, {
    client: uaaGraphQLClient,
    variables: { input: { failedTaskUid: selectedTask?.uid} },
    refetchQueries: [
      {
        query: GET_FAILED_TASKS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const [
    searchFailedAccountes,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_FAILED_TASKS, { client: uaaGraphQLClient })

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    //fetchData(page)
    setCurrentPage(selectedPage.selected)
  }

  if (loading && !searchLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchFailedAccountes({
      variables: { pagination: { ...pagination, ...data } },
    })
  }

  const searchQuery = () => {
    const value = formikSearch.values?.search || ""

    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchFailedAccountes({
        variables: { pagination: { ...pagination, ...data } },
      })
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }

  const handleResendTask = () => {
    showTaskWarning(
      () => {
        performUserResendTask()
      },
      () => {
        console.log("Task cancelled")
      }
    )
  }

  const performUserResendTask = async () => {
    try {
      const response = await resendTask()
      showToast(response.data.resendFailedTask.message, "success")
    } catch (error) {
      console.error("Error resending task:", error)
    }
  }

  const FailedAccountes = data?.getFailedTasks?.data?.items
  const SearchedFailedAccounts = searchData?.getFailedTasks?.data?.items

  const results = SearchedFailedAccounts
    ? SearchedFailedAccounts
    : FailedAccountes
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getFailedTasks?.data?.totalCount ||
    searchData?.getFailedTasks?.data?.items ||
    0

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Failed Tasks List">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-1">
                <Row>
                  <Col className="col-lg-6 col-md-6 col-sm-6">
                    <form className="app-search d-none d-lg-block">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control"
                          onKeyUp={searchQuery}
                          placeholder="Search..."
                          {...formikSearch.getFieldProps("search")}
                        />
                      </div>
                    </form>
                  </Col>
                  <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                    {/* <FailedAccountModal /> */}
                  </Col>
                </Row>
              </CardSubtitle>

              {!loading && !error && !searchError && (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th data-priority="1">S/N</Th>
                          <Th data-priority="1">Name</Th>
                          <Th data-priority="1">Function</Th>
                          <Th data-priority="1">Result</Th>
                          <Th data-priority="3">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {results
                          ?.slice(startIndex, endIndex)
                          .map((FailedAccount, index) => (
                            <Tr key={FailedAccount?.uid}>
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{FailedAccount?.name}</Td>
                              <Td>{FailedAccount?.func}</Td>
                              <Td>{FailedAccount?.result}</Td>
                              <Td>
                                <button
                                  className="btn btn-sm btn-success"
                                  onClick={() => {
                                    setSelectedTask(FailedAccount)
                                    handleResendTask()
                                  }}
                                >
                                  Re-send
                                </button>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </div>

                  <Row>
                    <Col className="col-lg-6 fw-bold text-gray-700">
                      Showing {startIndex + 1} to{" "}
                      {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                      entries
                    </Col>
                    <Col
                      className="col-lg-6 pull-right"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ReactPaginate
                        onPageChange={handleClick}
                        breakLabel={"--"}
                        pageCount={Math.ceil(totalEntries / pageSize)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={3}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        previousClassName={"page-link"}
                        previousLabel={"<"}
                        nextLabel={">"}
                        activeClassName={"active"}
                        breakLinkClassName={"page-link"}
                        initialPage={currentPage}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default FailedAccountList
