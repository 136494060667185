// export default GradePointList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {onlineApplicationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import GradePointModal from "./GradePointModal";
import {GET_GRADE_POINTS} from "../Queries/GradePointQueries";
import {DELETE_GRADE_POINT} from "../Mutations/GradePointMutations";

const breadcrumbItem = [
    {
        title: "Grade Point List",
        path: "",
        isActive: true,
    },
]

export const GradePointContext = createContext(undefined) //Create data to transfer to child modal

const GradePointList = () => {
    const searchValues = {
        search: "",
    }
    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })

    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            console.log("Requesting Page no ")
        },
    })

    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(limit)
    const pagination = { offset: currentPage, limit: limit, search: null }

    const [selectedGradePoint, setSelectedGradePoint] = useState(null) //Get data for deleting
    const [updateGradePointData, setUpdateGradePointData] = useState(null) //Get data for editing

    const { loading, error, data, refetch } = useQuery(GET_GRADE_POINTS, {
        client: onlineApplicationGraphQLClient,
        variables: {
            pagination,
        },
        fetchPolicy: "cache-first",
    })

    const [
        searchGradePoints,
        { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_GRADE_POINTS, {
        client: onlineApplicationGraphQLClient,
    })

    useEffect(() => {
        if (!loading && data) {
        }
    }, [loading, data])

    const handleClick = selectedPage => {
        const page = selectedPage.selected
        // fetchData(page);
        setCurrentPage(selectedPage.selected)
    }

    const [deleteGradePoint] = useMutation(DELETE_GRADE_POINT, {
        client: onlineApplicationGraphQLClient,
        variables: { uid: selectedGradePoint?.uid },
        refetchQueries: [
            {
                query: GET_GRADE_POINTS,
                variables: {
                    pagination: paginationHelper,
                },
            },
        ],
    })

    const handleDeleteGradePoint = delGradePoint => {
        setSelectedGradePoint(delGradePoint)
        showDeleteWarning(() => {
            performGradePointDeletion().then(r => console.log(r))
        })
    }

    const performGradePointDeletion = async () => {
        try {
            const response = await deleteGradePoint()
            showToast(
                response.data.removeGradePoint.message,
                response.data.removeGradePoint.code === 8000 ? "success" : "error"
            )
        } catch (error) {
            console.error("Error deleting user:", error)
        }
    }

    if (loading) {
        return <PlaceHolderLoader rows={4} type="table" columSize={12} />
    }

    const fetchData = (page = 0) => {
        setCurrentPage(page)
        let data = {
            search: formikSearch.values.search,
        }
        // Update the search query variables and refetch the data
        searchGradePoints({variables: {pagination: {...pagination, ...data}}}).then(r => console.log(r))
    }

    const searchQuery = () => {
        const value = formikSearch.values.search
        if (value.length >= 3) {
            // setFetching(true);
            fetchData()
        } else if (value?.length === 0) {
            //refetch data after clearing search value
            clearSearch()
            fetchData() //update hook state
        }
    }

    const clearSearch = () => {
        //Function to clear search from formik
        formikSearch.setFieldValue("search", "")
    }
    const GradePoints = data?.getGradePoints.data?.items
    const searchedGradePoints = searchData?.getGradePoints?.data?.items
    const result = searchedGradePoints ? searchedGradePoints : GradePoints
    const startIndex = currentPage * pageSize
    const endIndex = (currentPage + 1) * pageSize
    const totalEntries =
        data?.getGradePoints.data?.totalCount ||
        searchData?.getGradePoints?.data?.items ||
        0

    return (
        <GradePointContext.Provider value={{ updateGradePointData, setUpdateGradePointData }}>
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Grade Point List">
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardSubtitle className="mb-1">
                                    <Row>
                                        <Col className="col-lg-6 col-md-6 col-sm-6">
                                            <form className="app-search d-none d-lg-block">
                                                <div className="position-relative">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onKeyUp={searchQuery}
                                                        placeholder="Search..."
                                                        {...formikSearch.getFieldProps("search")}
                                                    />
                                                </div>
                                            </form>
                                        </Col>
                                        <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                                            <GradePointModal />
                                        </Col>
                                    </Row>
                                </CardSubtitle>

                                {!loading && !searchError && (
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="tech-companies-1"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="1">S/N</Th>
                                                        <Th data-priority="1">Grade Name</Th>
                                                        <Th data-priority="1">Grade Point</Th>
                                                        <Th>Qualification Method</Th>
                                                        <Th data-priority="3">Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {result
                                                        ?.slice(startIndex, endIndex)
                                                        .map((GradePoint, index) => (
                                                            <Tr key={GradePoint?.uid}>
                                                                <Td>{startIndex + index + 1}</Td>
                                                                <Td>{GradePoint?.grade}</Td>
                                                                <Td>{GradePoint?.gradePoint}</Td>
                                                                <Td>{GradePoint?.qualificationMethod?.name}</Td>
                                                                <Td>
                                                                    <DropDownActionHelper
                                                                        data={GradePoint}
                                                                        onUpdate={setUpdateGradePointData}
                                                                        onDelete={handleDeleteGradePoint}
                                                                    />

                                                                </Td>
                                                            </Tr>
                                                        ))}
                                                </Tbody>
                                            </Table>
                                        </div>

                                        <Row>
                                            <Col className="col-lg-6 fw-bold text-gray-700">
                                                Showing {startIndex + 1} to{" "}
                                                {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                                                entries
                                            </Col>
                                            <Col
                                                className="col-lg-6 pull-right"
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <ReactPaginate
                                                    onPageChange={handleClick}
                                                    breakLabel={"--"}
                                                    pageCount={Math.ceil(totalEntries / pageSize)}
                                                    marginPagesDisplayed={3}
                                                    pageRangeDisplayed={3}
                                                    containerClassName={"pagination"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}
                                                    nextLinkClassName={"page-link"}
                                                    previousClassName={"page-link"}
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    activeClassName={"active"}
                                                    breakLinkClassName={"page-link"}
                                                    initialPage={currentPage}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </LayoutHelper>
        </GradePointContext.Provider>
    )
}

export default GradePointList
