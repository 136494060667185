import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { CREATE_GROUPS } from "../Mutations/GroupMutations"
import { GET_GROUPS } from "../Queries/GroupQueries"
import { GroupContext } from "./GroupList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const GroupModal = () => {
  const { updateGroupData, setUpdateGroupData } =
    useContext(GroupContext)
  const [Group] = useMutation(CREATE_GROUPS)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_GROUPS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateGroupData ? updateGroupData.name : "",
      code: updateGroupData ? updateGroupData.code : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Group name is required"),
      code: Yup.string().required("Group code is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      Group({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateGroupData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_GROUPS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerGroups }) => {
          //updateFunction(client.cache, { data: { registerGroups } });
          console.log("DS", registerGroups);
          if (registerGroups.code === 8000) {
           validation.resetForm();
            setModalOpen(false)
            setUpdateGroupData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false);
          }
          showToast(
            registerGroups.message,
            registerGroups.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Group
      </Button>
      <Modal
        isOpen={modalOpen || !!updateGroupData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateGroupData
              ? "Edit Group"
              : "Add New Group"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateGroupData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || ""
                }
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Group Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="code"
                placeholder="Enter Code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.code || updateGroupData?.code || ""
                }
                invalid={
                  !!(validation.touched.code && validation.errors.code)
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateGroupData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default GroupModal
