import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { SponsorContext } from "./SponsorList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { CREATE_SPONSOR } from "../Mutations/SponsorMutations"
import { GET_SPONSORS } from "../Queries/SponsorQueries"

const SponsorModal = () => {
  const { updateSponsorData, setUpdateSponsorData } = useContext(SponsorContext)
  const [Sponsor] = useMutation(CREATE_SPONSOR)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_SPONSORS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateSponsorData ? updateSponsorData.name : "",
      code: updateSponsorData ? updateSponsorData.code : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Sponsor name is required"),
      code: Yup.string().required("Sponsor short name is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      Sponsor({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateSponsorData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_SPONSORS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerSponsors }) => {
          //updateFunction(client.cache, { data: { registerSponsors } });
          if (registerSponsors.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateSponsorData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateSponsorData(true) // Shikilia modal
          }
          showToast(
            registerSponsors.message,
            registerSponsors.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Sponsor
      </Button>
      <Modal
        isOpen={modalOpen || !!updateSponsorData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateSponsorData ? "Edit Sponsor" : "Add New Sponsor"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || updateSponsorData?.name || ""}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="firstname"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || updateSponsorData?.code || ""}
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateSponsorData(false)
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default SponsorModal
