import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "../../../Utility/pages-404";
import StudentListReport from "../Views/StudentListReport";
const StudentReportRoutes = () => {
    return (
        <Routes>
            <Route path="/summary" element={<StudentListReport />} />
            <Route path="/" element={<StudentListReport />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default StudentReportRoutes;