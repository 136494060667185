import {Route, Routes} from "react-router-dom";
import Pages404 from "../Modules/Utility/pages-404";
import React from "react";
import StudentsList from "../Modules/Students/Views/StudentsList";
import ViewStudent from "../Modules/Students/Views/ViewStudent";
import UpdateStudentDetails from "../Modules/Students/Views/UpdateStudentDetails";
import StatusTypeList from "../Modules/Lookups/StatusType/Views/StatusTypeList";
import ApplicantStatusByProgramme
    from "../Modules/OnlineApplication/ApplicationConfirmationAndSelections/Views/ApplicantStatusByProgramme";
import ApplicantConfirmationByProgramme
    from "../Modules/OnlineApplication/ApplicationConfirmationAndSelections/Views/ApplicantConfirmationByProgramme";
import ProgramCapacityList from "../Modules/OnlineApplication/AdmissionProgramCapacity/Views/ProgramCapacityList";
import ApplicantSelectedReport
    from "../Modules/OnlineApplication/ApplicationConfirmationAndSelections/Views/ApplicantSelectedReports";
import ApplicationRanking
    from "../Modules/OnlineApplication/ApplicationConfirmationAndSelections/Views/ApplicationRanking";
import SubjectRoutes from "../Modules/Subjects/Routes/SubjectRoutes";
import AdmissionYearRoutes from "../Modules/AdmissionYear/Routes/AdmissionYearRoutes";
import ProgramRequirementRoutes from "../Modules/ProgramRequirements/Routes/ProgramRequirementRoutes";
import StudentExaminationResultsSummary from "../Modules/Reports/Students/Views/StudentExaminationResultsSummary";
import SemesterExaminationResultsSummary from "../Modules/Reports/Results/Views/SemesterExaminationResultsSummary";
import AcademicUnitResult from "../Modules/Reports/Results/Views/AcademicUnitResult";


export const AdmissionStudentRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<StudentsList/>} />
            <Route path="/view" element={<ViewStudent/>} />
            <Route path="/update" element={<UpdateStudentDetails/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );
}

export const AdmissionOnlineApplicationRoutes = () => {

    return (
        <Routes>
            <Route index={true} path="/applicants" element={<ApplicantStatusByProgramme />} />
            <Route index={true} path="/admission-year/*" element={<AdmissionYearRoutes />} />
            <Route index={true} path="/confirmation" element={<ApplicantConfirmationByProgramme />} />
            <Route index={true} path="/program-capacity" element={<ProgramCapacityList />} />
            <Route index={true} path="/applicant" element={<ApplicantSelectedReport />} />
            <Route index={true} path="/ranking" element={<ApplicationRanking />} />
            <Route index={true} path="/subjects/*" element={<SubjectRoutes />} />
            <Route index={true} path="/program-requirements/*" element={<ProgramRequirementRoutes/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );

}

export const ExaminationReportRoutes = () => {
    return (
        <Routes>
            <Route path="/semester_results" element={<SemesterExaminationResultsSummary/>} />
            <Route path="/exam_summary_report" element={<StudentExaminationResultsSummary/>} />
            <Route path="/academic_unit_result" element={<AcademicUnitResult/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );
}