import { gql } from "@apollo/client"

const UPDATE_APPLICANT_BIRTHDATE_AND_PHYSICAL_ADDRESS = gql`
  mutation UpdateApplicantBirthDateAndAddress(
    $birthDate: String!
    $physicalAddress: String!
    $applicantUid: String!
  ) {
    updateApplicantBirthDateAndAddress(
      birthDate: $birthDate
      physicalAddress: $physicalAddress
      applicantUid: $applicantUid
    ) {
      status
      code
      message
      data {
        birthDate
        physicalAddress
        applicationLevel
        formFourIndexNumber
        applicationLevelId
        applicationLevelCode
        email
        firstName
        middleName
        lastName
      }
    }
  }
`

const REGISTER_APPLICANT_NEXT_OF_KINS = gql`
  mutation AddNextOfKin($inputs: [NextOfKinInput!]!) {
    addApplicantNextOfKins(inputs: $inputs) {
      code
      message
      data {
        items {
          uid
          applicantUid
          fullName
        }
      }
    }
  }
`

const REMOVE_APPLICANT_NEXT_OF_KIN = gql`
  mutation RemoveNextOfKin($uid: String!) {
    removeNextOfKin(uid: $uid) {
      status
      code
      message
      data
    }
  }
`

const REGISTER_PROGRAM_SELECTION = gql`
  mutation RemoveNextOfKin($inputs: [ProgramSelectionInput!]!) {
    registerProgramSelection(inputs: $inputs) {
      status
      code
      message
    }
  }
`

const SAVE_CONFIRM_APPLICATION = gql`
  mutation SaveConfirmApplication($applicantUid: String!) {
    saveConfirmApplication(applicantUid: $applicantUid) {
      status
      code
      message
    }
  }
`

const REMOVE_SELECTION = gql`
  mutation RemoveSelection($uid: String!) {
    removeProgramSelection(uid: $uid) {
      status
      code
      message
    }
  }
`
export {
  UPDATE_APPLICANT_BIRTHDATE_AND_PHYSICAL_ADDRESS,
  REGISTER_APPLICANT_NEXT_OF_KINS,
  REMOVE_APPLICANT_NEXT_OF_KIN,
  REGISTER_PROGRAM_SELECTION,
  REMOVE_SELECTION,
  SAVE_CONFIRM_APPLICATION,
}
