import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  Label,
  Modal,
  Row
} from "reactstrap"
import { useMutation } from "@apollo/client"
import { useFormik } from "formik"
import * as Yup from "yup"
import showToast from "../../../../../helpers/ToastHelper"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import PaginationHelper from "../../../../../helpers/PaginationHelper"
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper"
import { EntryQualificationConext } from "../EntryQualification"
import { CREATE_APPLICANT_CERTIFICATE } from "../../../Mutations/ApplicantMutation"
import { onlineApplicationGraphQLClient } from "../../../../Authentication/ApolloClient"
import { GET_APPLICANT_CERTIFICATE_RESULT } from "../../../Queries/ApplicantQueries"
import TanzanianQualifications from "./TanzanianQualifications"
import ForeignersQualifications from "../Foreigners/ForeignersQualifications"
import ApplicationLevel from "../../ApplicationLevel"

const FetchResultsModel = (props) => {
  const { formik } = props
  const { applicant, setAdd, add } = useContext(EntryQualificationConext)
  return (
    <Modal
      isOpen={add}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          ADD ENTRY QUALIFICATION
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setAdd(false)
            formik.resetForm()
          }}
          aria-label="Close"
        ></button>
      </div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          formik.handleSubmit()
          return null
        }}
      >

        <Card>
          <CardBody>
            <TanzanianQualifications validation={formik} applicant={applicant} />

            <Row>
              <Col lg={12} className={"mt-3"} style={{ alignContent: "end" }}>
                <div className="float-start">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      setAdd(false)
                      formik.resetForm()
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <SubmitButtonHelper btnSize={"float-end"} name="Search Result" type="primary" formik={formik} />

              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Modal>
  )
}

FetchResultsModel.propTypes = {
  // open: PropTypes.bool.isRequired,
  formik: PropTypes.any.isRequired
}
export default FetchResultsModel

