import {Route, Routes} from "react-router-dom";
import Pages404 from "../../Modules/Utility/pages-404";
import React from "react";
import SelfRegistrationPayment from "../../Modules/SelfRegistration/View/SelfRegistrationPayment";
import SelfRegistrationMyInformation from "../../Modules/SelfRegistration/View/SelfRegistrationMyInformation";
import SelfRegistrationContactInformation from "../../Modules/SelfRegistration/View/SelfRegistrationContactInformation";
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard";
import SelfRegistrationNextOfKin from "../../Modules/SelfRegistration/View/SelfRegistrationNextOfKin";
import SelfRegistrationHostel from "../../Modules/SelfRegistration/View/SelfRegistrationHostel";
import SelfRegistrationConfirmation from "../../Modules/SelfRegistration/View/SelfRegistrationConfirmation";

const SelfRegistrationRoutes = () => {
    return (
        <Routes>
            <Route path={"dashboard"} element={<AsimsDashboard /> } />
            <Route path="payments" element={<SelfRegistrationPayment />} />
            <Route path="my-information" element={<SelfRegistrationMyInformation />} />
            <Route path="contact-information" element={<SelfRegistrationContactInformation />} />
            <Route path="next-of-kin-information" element={<SelfRegistrationNextOfKin />} />
            <Route path="hostel" element={<SelfRegistrationHostel />} />
            <Route path="confirmation" element={<SelfRegistrationConfirmation />} />
            <Route path="*" element={<Pages404 />} />
            {/* Add more student-related routes here */}
        </Routes>
    );
}

export default SelfRegistrationRoutes;