import { gql } from "apollo-boost"

const GET_ENTRY_CATEGORIES = gql`
  query GetEntryCategory($pagination: PaginationInput!) {
    getEntryCategories(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`

export { GET_ENTRY_CATEGORIES }
