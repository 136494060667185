//REGISTE
import { gql } from "@apollo/client"

const GET_STATUS = gql`
  query GetStatus($pagination: PaginationInput!) {
    getStatuses(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
          statusType {
            uid
            name
          }
        }
        totalCount
      }
    }
  }
`

const GET_STATUS_NAME = gql`
  query GetStatusName{
    getStatusName {
      status
      code
      message
      data {
        items {
          name
        }
      }
    }
  }
`



export { GET_STATUS,GET_STATUS_NAME }
