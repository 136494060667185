import { Link } from "react-router-dom"
import React from "react"

const ASIMSLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_ACCOMODATION_MENU") && (
        <>
          {hasAuthority("VIEW_ACCOMODATION_MENU") && (
            <>
              <li>
                <Link to="/asims/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>Dashboard</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/personal-informations">
                  <i className="bx bx-user"></i>
                  <span>Personal Information</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/academic-qualifications">
                  <i className="bx bx-book"></i>
                  <span>Academic Qualifications</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/professional-skills">
                  <i className="bx bx-briefcase"></i>
                  <span>Professional and Technical Skills</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/research-grants">
                  <i className="bx bx-dollar-circle"></i>
                  <span>Research Grants</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/awards">
                  <i className="bx bx-award"></i>
                  <span>Awards</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/academic-events">
                  <i className="bx bx-calendar-event"></i>
                  <span>Academic Events</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/appointments">
                  <i className="bx bx-calendar-check"></i>
                  <span>Appointments</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/employement-history">
                  <i className="bx bx-briefcase-alt"></i>
                  <span>Employment History</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/consultancies">
                  <i className="bx bx-conversation"></i>
                  <span>Consultancies</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/outreach-activities">
                  <i className="bx bx-street-view"></i>
                  <span>Outreach Activities</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/reviewer-roles">
                  <i className="bx bx-edit"></i>
                  <span>Reviewer</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/publications">
                  <i className="bx bx-book-content"></i>
                  <span>Publications</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/media-coverage">
                  <i className="bx bx-camera"></i>
                  <span>Media Coverage</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/other-experiences">
                  <i className="bx bx-rocket"></i>
                  <span>Other Experiences</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/professional-memberships">
                  <i className="bx bx-id-card"></i>
                  <span>Professional Body Membership</span>
                </Link>
              </li>

              <li>
                <Link to="/asims/curriculum-vitae">
                  <i className="bx bx-file"></i>
                  <span>Curriculum Vitae</span>
                </Link>
              </li>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ASIMSLinks
