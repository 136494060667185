import { Link } from "react-router-dom"
import React from "react"

const UserManagementLinks = ({ hasAuthority }) => {
  return (
    <>
      <li>
        <Link to="/user-management/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      {hasAuthority("VIEW_USER_MANAGEMENT_MENU") && (
          <>
              {hasAuthority("VIEW_USERS_MENU") && (
                  <li>
                      <Link to="/user-management/users">
                          <i className="bx bx-user"></i>
                          <span>Users</span>
                      </Link>
                  </li>
              )}

            {hasAuthority("VIEW_USERS_MENU") && (
              <li>
                <Link to="/user-management/users/recover-account-password">
                  <i className="bx bx-key"></i>
                  <span>Recover Student Account</span>
                </Link>
              </li>
            )}

              {hasAuthority("VIEW_USER_GROUPS_MENU") && (
                  <li>
                      <Link to="/user-management/groups">
                          <i className="bx bx-group"></i>
                          <span>Groups</span>
                      </Link>
                  </li>
              )}

              {hasAuthority("VIEW_USER_PERMISSIONS_MENU") && (
                  <li>
                      <Link to="/user-management/permissions">
                          <i className="bx bx-key"></i>
                          <span>Permissions</span>
                      </Link>
                  </li>
              )}


              <li>
                  <Link to="/user-management/headships">
                      <i className="bx bx-user-pin"></i>
                      <span>Headships</span>
                  </Link>
              </li>

              <li>
                  <Link to="/user-management/failed-accounts">
                      <i className="bx bx-error"></i>
                      <span>Failed Tasks</span>
                  </Link>
              </li>

              {hasAuthority("VIEW_STAFF_CATEGORY_MENU") && (
                  <li>
                      <Link to="/user-management/staff-categories">
                          <i className="bx bx-group"></i>
                          <span>Staff Categories</span>
                      </Link>
                  </li>
              )}

              {hasAuthority("VIEW_STAFF_TERMS_MENU") && (
                  <li>
                      <Link to="/user-management/staff-terms">
                          <i className="bx bx-calendar-event"></i>
                          <span>Staff Terms</span>
                      </Link>
                  </li>
              )}
          </>
      )}
    </>
  )
}

export default UserManagementLinks
