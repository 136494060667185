import { gql } from "@apollo/client"

const GET_AWARDS = gql`
  query getAcademicAwardsPaginated($pagination: PaginationInput!) {
    getAcademicAwardsPaginated(pagination: $pagination) {
      status
      code
      message
      data {
        totalCount
        items {
          uid
          id
          staffUid
          name
          description
          awardingBoard {
            code
            uid
            name
          }
          awardYear
        }
      }
    }
  }
`

export { GET_AWARDS }
