//REGISTE
import { gql } from '@apollo/client';

const GET_ = gql`
  query GetUsers {
    getUsers {
      data {
        id
        name
      }
      status
      code
      message
    }
  }
`;

const GET_COURSE_MOODLE_URL = gql`
query getMoodleUrl(
  $inputs: MoodleGetUrlInput!
) {
  getMoodleUrl(inputs: $inputs) {
    message
    status
    code
    data

  }
}
  `

const GET_PROGRAM_SEMESTER_REGISTRATION = gql`
  query GetStudentProgramCourse($input: RequestProgramSemester!) {
      getStudentProgramCourse(input: $input) {
            status
            code
            message
            data{
              uid
              course{
                uid
                code
                name
              }
              credit
              courseCategory{
                name
              }
              programSemester{
                studyYear
                semester
                electiveCredits
                academicYear{
                  name
                }
              }
            }
      }
  }
`;

const GET_STUDENT_COURSE_TO_REGISTER = gql`
  query GetStudentCourseToRegister($input: CourseRegisterInputNode!) {
      getStudentCourseToRegister(inputs: $input) {
            status
            code
            message
            data{
              courseRegistered{
                uid
                programCourse{
                  uid
                  programSemester{
                    semester
                    studyYear
                  }
                  courseCategory{
                    name
                  }
                  course{
                    code
                    name
                    description
                    moodleId
                    uid
                  }
                }
              }
              courseToRegister{
                  uid
                  course{
                    uid
                    code
                    name
                  }
                  credit
                  courseCategory{
                    name
                  }
                  programSemester{
                    studyYear
                    semester
                    electiveCredits
                    academicYear{
                      name
                    }
                  }
                }
            }
      }
  }
`;


const GET_STUDENT_REGISTERED_COURSES = gql`
  query GetStudentCourseToRegister($input: CourseRegisterInputNode!) {
      getStudentCourseToRegister(inputs: $input) {
            status
            code
            message
            data{
                courseRegistered{
                    programCourse{
                      uid
                      programSemester{
                        semester
                        studyYear
                      }
                      courseCategory{
                        name
                      }
                      course{
                        code
                        name
                        description
                        moodleId
                        uid
                      }
                    }
                  }
            }
      }
  }
`;

export { GET_PROGRAM_SEMESTER_REGISTRATION,GET_STUDENT_COURSE_TO_REGISTER,GET_STUDENT_REGISTERED_COURSES,GET_COURSE_MOODLE_URL }