import { gql } from "apollo-boost"

const GET_WARDS = gql`
  query GetWard($pagination: PaginationInput!) {
    getWards(pagination: $pagination) {
      data {
        items {
          name
          uid
          code
          district {
            name
            region {
              name
            }
          }
        }
        totalCount
      }
    }
  }
`

export { GET_WARDS }
