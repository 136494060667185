import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Label, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_EXAM_CATEGORIES } from "../Queries/ExamCategoryQueries"
import { CREATE_EXAM_CATEGORY } from "../Mutations/ExamCategoryMutations"
import { ExamCategoryContext } from "./ExamCategoryList"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import Select from "react-select"

const ExamCategoryModal = () => {
  const { updateExamCategoryData, setUpdateExamCategoryData } = useContext(ExamCategoryContext)
  const [examCategory] = useMutation(CREATE_EXAM_CATEGORY, {client: registrationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }


  const options = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ]

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateExamCategoryData ? updateExamCategoryData.name : "",
      code: updateExamCategoryData ? updateExamCategoryData.code : "",
      isUe: null,
      isOral: null,
      isTheory: null,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("name is required"),
      code: Yup.string().required("short name is required"),
      isUe: Yup.boolean().nullable().required("This option is required"),
      isOral: Yup.boolean().nullable().required("This option is required"),
      isTheory: Yup.boolean().nullable().required("This option is required"),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, {reSubmit}) => {
      //alert('Exam Category Form'+values.examCategoryGroupUid)
      examCategory({          
        variables: {            
        inputs: [{ ...values, uid: updateExamCategoryData?.uid }],
        },
        client:registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_EXAM_CATEGORIES,
            variables: {
              
            },
          },
        ],
        onCompleted: ({ registerExamCategories }) => {
          //updateFunction(client.cache, { data: { registerExamCategories } });
          if (registerExamCategories.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateExamCategoryData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateExamCategoryData(true) // Close the modal
            reSubmit(false)
          }
          showToast(
            registerExamCategories.message,
            registerExamCategories.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Exam Category
      </Button>
      <Modal
        isOpen={modalOpen || !!updateExamCategoryData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateExamCategoryData ? "Edit Exam Category" : "Add New Exam Category"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <Row>
                <Col lg={6} md={6}>
                  <label htmlFor="name" className="col-form-label">
                    Name:
                  </label>
                  <Input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col lg={6} md={6}>
                  <label htmlFor="code" className="col-form-label">
                    Code:
                  </label>
                  <Input
                    type="text"
                    name="code"
                    className="form-control"
                    id="code"
                    placeholder="Short Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code || ""}
                    invalid={
                      validation.touched.code && validation.errors.code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>
            <div className="mb-3">
              <Row>
                <Col lg={6} md={6}>
                    <Label>Is Theory?</Label>
                    <Select
                      name="isTheory"
                      onChange={e => {
                        validation.setFieldValue("isTheory", e.value)
                      }}
                      options={options}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      // defaultValue={Student?.isDisabled}
                      // defaultInputValue={Student?.isDisabled === false ? "No" : "Yes"}                
                    />
                    <Input
                      name="isTheory"
                      placeholder="Select Disability"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.isTheory || ""
                      }
                      invalid={
                        !!(
                          validation.touched.isTheory &&
                          validation.errors.isTheory
                        )
                      }
                    />
                    {validation.touched.isTheory &&
                    validation.errors.isTheory ? (
                      <FormFeedback type="invalid">
                        {validation.errors.isTheory}
                      </FormFeedback>
                    ) : null}
                </Col>
                <Col lg={6} md={6}>
                  <Label>Is Ue?</Label>
                  <Select
                    name="isUe"
                    onChange={e => {
                      validation.setFieldValue("isUe", e.value)
                    }}
                    options={options}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    // defaultValue={updateExamCategoryData?.isUe}
                    // defaultInputValue={updateExamCategoryData?.isUe === false ? "No" : "Yes"}                
                  />
                  <Input
                    name="isUe"
                    placeholder="Select Disability"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.isUe ||
                      updateExamCategoryData?.isUe ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.isUe &&
                        validation.errors.isUe
                      )
                    }
                  />
                  {validation.touched.isUe &&
                  validation.errors.isUe ? (
                    <FormFeedback type="invalid">
                      {validation.errors.isUe}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>          
            <div className="mb-3">
              <Label>Is Oral?</Label>
              <Select
                name="isOral"
                onChange={e => {
                  validation.setFieldValue("isOral", e.value)
                }}
                options={options}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                // defaultValue={updateExamCategoryData?.isUe}
                // defaultInputValue={updateExamCategoryData?.isUe === false ? "No" : "Yes"}                
              />
              <Input
                name="isOral"
                placeholder="Select Disability"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.isOral ||
                  updateExamCategoryData?.isOral ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.isOral &&
                    validation.errors.isOral
                  )
                }
              />
              {validation.touched.isOral &&
              validation.errors.isOral ? (
                <FormFeedback type="invalid">
                  {validation.errors.isOral}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateExamCategoryData(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ExamCategoryModal
