import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

const   ProgramActionHelper = ({ data, onUpdate, onDelete, onCourses,onAssessement,onAllocation,onForward,onDetails, onApprove }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle color="" caret>
        <i className="fas fa-ellipsis-v" style={{ color: "green" }} />
      </DropdownToggle>
      <DropdownMenu>
        {onUpdate && (
          <DropdownItem onClick={() => onUpdate(data)}>
            <i className="fas fa-pen" style={{ color: "green", marginRight: "10px" }} />
            <span>Update</span>
          </DropdownItem>
        )}   
        {onForward && (
          <DropdownItem onClick={() => onForward(data)}>
            <i className="fas fa-book" style={{ color: "green", marginRight: "10px" }} />
            <span>Forward</span>
          </DropdownItem>
        )}               
        {onCourses && (
          <DropdownItem onClick={() => onCourses(data)}>
            <i className="fas fa-book" style={{ color: "green", marginRight: "10px" }} />
            <span>Courses</span>
          </DropdownItem>
        )}
        {onAssessement && (
          <DropdownItem onClick={() => onAssessement(data)}>
            <i className="fas fa-book" style={{ color: "green", marginRight: "10px" }} />
            <span>Course assessment</span>
          </DropdownItem>
        )}  
        {onAllocation && (
          <DropdownItem onClick={() => onAllocation(data)}>
            <i className="fas fa-user" style={{ color: "green", marginRight: "10px" }} />
            <span>Courses Allocation</span>
          </DropdownItem>
        )} 
        {onDelete && (                           
        <DropdownItem onClick={() => onDelete(data)}>
          <i
            className="fas fa-trash"
            style={{ color: "red", marginRight: "10px" }}
          />
          <span>Delete</span>
        </DropdownItem>
        )} 
        {onDetails && (                           
        <DropdownItem onClick={() => onDetails(data)}>
          <i
            className="mdi mdi-eye-outline"
            style={{ color: "green", marginRight: "10px" }}
          />
          <span>Details</span>
        </DropdownItem>
        )} 
        {onApprove && (                           
        <DropdownItem onClick={() => onApprove(data)}>
          <i
            className="mdi mdi-eye-outline"
            style={{ color: "green", marginRight: "10px" }}
          />
          <span>Approve</span>
        </DropdownItem>
        )}         
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProgramActionHelper;