import { gql } from "@apollo/client"

const GET_EMPLOYMENT_HISTORY = gql`
  query GetEmploymentHistoriesPaginated($pagination: PaginationInput!) {
    getEmploymentHistoriesPaginated(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          id
          staffUid
          position
          departmentName
          institutionName
          institutionType {
            uid
            id
            name
            code
          }
          startDate
          endDate
          responsibilities
          city
          country
        }
        totalCount
      }
    }
  }
`

export { GET_EMPLOYMENT_HISTORY }
