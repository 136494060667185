import { gql } from "@apollo/client"

const REGISTER_PROGRAM_ADMISSION_CAPACITY = gql`
    mutation RegisterProgramCapacity($inputs:ProgramCapacityInput!){
        registerProgramCapacity(inputs:$inputs){
            status
            message
            code
            data{
                items{
                    uid
                    capacity
                    programUid
                    programName
                    code
                    admissionYear{
                        name
                    }
                }
            }
        }
    }
`;

const UPDATE_PROGRAM_ADMISSION_CAPACITY = gql`
    mutation UpdateProgramCapacity($inputs:ProgramCapacityInput!){
        updateProgramCapacity(inputs:$inputs){
            status
            code
            message
            data{
                items{
                    uid
                    capacity
                    capacityDirect
                    capacityEquivalent
                    code
                    programName
                    programUid
                    admissionYear{
                        uid
                        name
                    }
                }
                totalCount
            }
        }
    }
`;

const DELETE_PROGRAM_ADMISSION_CAPACITY = gql`
    mutation RemoveProgramCapacity($uid:String!){
        removeProgramCapacity(uid:$uid){
            status
            code
            message
        }
    }
`;
export { REGISTER_PROGRAM_ADMISSION_CAPACITY, UPDATE_PROGRAM_ADMISSION_CAPACITY, DELETE_PROGRAM_ADMISSION_CAPACITY }