import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import ProgramActionHelper from "helpers/ProgramHelper"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardSubtitle,
} from "reactstrap"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_COURSE_ALLOCATION, GET_STAFF_COURSE_ALLOCATION } from "Modules/CourseAllocation/Queries/CourseAllocationQueries"
import { DELETE_COURSE_ALLOCATION } from "Modules/ProgramSemister/Mutations/CourseAllocationMutations"
import { GET_USER } from "Modules/Users/Queries/UserQuery"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { uaaGraphQLClient,registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import {useNavigate} from "react-router-dom";

const breadcrumbItem = [
  {
    title: "Delete Results",
    path: "",
    isActive: true,
  },
]
const DeleteResults = () => {
  const [semesters, setSemesters] = useState([]),
    [academicYear, setAcademicYear] = useState([]),
    [studyYear, setStudyYear] = useState([]),
    [fetching, setFetching] = useState([]), navigate=useNavigate()
    const [courseAllocate, setCourseAllocate] = useState(null) //for update
    const [selectedCourseAllocation, setSelectedCourseAllocation ] = useState(null) // for update
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(limit)
    const pagination = { offset: currentPage, limit: limit, search: null }  
    
  // Fetch staff info
  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
  } = useQuery(GET_USER, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username
      //username: "hussein.mkwazu"
    },
  })    
  const staffUid =staffData?.getUserByUsername?.data?.staff?.uid;

  const { loading, error, data, refetch } = useQuery(GET_STAFF_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        programCourseUid: null,
        isCurrent: 1,
        staffUid: staffData?.getUserByUsername?.data?.staff?.uid ? staffData?.getUserByUsername?.data?.staff?.uid : "",
      },
    },
  })

    const [deleteCourseAllocation] = useMutation(DELETE_COURSE_ALLOCATION, {
      client: registrationGraphQLClient,
      variables: { uid: selectedCourseAllocation?.uid },
      refetchQueries: [
        {
          query: GET_COURSE_ALLOCATION,
          variables: {
            pagination: paginationHelper,
          },
        },
      ],
    })

    const getCourseAllocation = (semester) => {
      // navigate("/students/view",{state:{student:std}})
      setCourseAllocate(semester);
      navigate("/instructor/course-result",{state:{courseAllocation:semester}})
    }  

    const handleDeleteResults = (allocating) => {
        setSelectedCourseAllocation(allocating)
        showDeleteWarning(() => {
        performResultsDeleting()
      })
  }

  const performResultsDeleting = async () => {
    try {
      //const response = await deleteCourseAllocation()
      // showToast(response.data.removeCourseAllocation.message, "success")
      showToast("Course Results " + selectedCourseAllocation?.programCourse?.course?.code + " Deleted Successfully", "success")
    } catch (error) {
      showToast("Failed to delete", "error")
    }
  }

  const programCourse = data?.getStaffCourseAllocation?.data?.items || []
  //const programCourse = data?.getStaffCourseAllocation?.data?.items || searchData?.getStaffCourseAllocation?.data?.items || []
  // const searchedProgramCourse = searchData?.getStaffCourseAllocation?.data?.items
  // const result = searchedProgramCourse ? searchedProgramCourse : programCourse
  const result = programCourse
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  //const totalEntries = 100
  // const totalEntries = data?.getStaffCourseAllocation?.data?.totalCount || searchData?.getStaffCourseAllocation?.data?.items || 0
  const totalEntries = data?.getStaffCourseAllocation?.data?.totalCount || 100
  
  console.log("user data", programCourse)

  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Delete Examination Result"
    >
       <Row>
        <Col sm={12}>
          <Card>
            <CardSubtitle className="mb-1 mt-1 text-center"><h4 className="mb-1 mt-4 text-center">Allocated Course</h4>  
                     
            </CardSubtitle>
            <CardBody>
            <div className="table-responsive mt-1">
              <Table
                id="tech-companies-1"
                className="table-sm table-responsive table-striped table-hover mt-1"
              >
                <Thead className="table-light">
                  <Tr>
                    <Th data-priority="1">S/N</Th>
                    <Th>Program</Th>
                    <Th>Course Ante</Th>
                    <Th>Course</Th>
                    <Th data-priority="3">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>

                   {result
                      .slice(startIndex, endIndex)
                      .map((resultRows, index) => (
                        <Tr key={resultRows?.uid}>
                          <Td>{startIndex + index + 1}</Td>
                          <Td>{resultRows?.programCourse?.programSemester?.program?.code}</Td>
                          <Td>{resultRows?.programCourse?.course?.code}</Td>
                          <Td>{resultRows?.programCourse?.course?.name}</Td>
                          <Td>
                            <ProgramActionHelper
                              data={resultRows}
                              // onDelete={handleDeleteResults}
                              onDetails={getCourseAllocation}
                            />
                          </Td>
                        </Tr>
                      ))
                    }                  
                    
                </Tbody>               
              </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default DeleteResults
