import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {
  Card,
  CardBody,
  Col, Form, FormFeedback, Input, Label,
  Row
} from "reactstrap"
import { useLazyQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient } from "../../../Authentication/ApolloClient"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import {
  GET_APPLICANT_SUBMISSION_STATUS_REPORT
} from "../Queries/ApplicationReportQueries"
import { ApplicantListByDatePdfGenerator } from "../../../../helpers/ApplicantListByDate"
import Select from "react-select"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { ApplicantSubmissionReportPDF } from "../../../../helpers/ApplicantSubmissionReportPDF"

const breadcrumbItem = [
  {
    title: "Application Submission Report",
    path: "",
    isActive: true
  }
]
const ApplicantSubmissionStatusReport = () => {

  const [loading, setLoading] = useState(false)
  const [applicantResults, setApplicantResults] = useState([])

  const options = [
    {
      value: 1,
      label: "Submitted application"
    },
    {
      value: 0,
      label: "Unsubmitted application"
    }
  ]


  const downloadList = (applicants) => {
    setLoading(true)
    const title = "APPLICATION SUBMISSION REPORT"
    const tableDataResponse = applicants?.map((item, index) => {
      const firstName = item?.firstName
      const middleName = item?.middleName
      const lastName = item?.lastName
      const phoneNumber = item?.phoneNumber
      const isPaid = item?.isPaid === true ? "Yes": "No"
      const submissionStatus = item?.submissionStatus ? "Yes" : "No"
      const formFourIndexNumber = item?.formFourIndexNumber

      return [
        (index + 1).toString(),
        firstName,
        middleName,
        lastName,
        formFourIndexNumber,
        phoneNumber,
        isPaid,
        submissionStatus
      ]
    })
    ApplicantSubmissionReportPDF(tableDataResponse, title)
    setLoading(false)
    setApplicantResults([])
  }

  const [
    refetchQuery,
    { loading: applicantSubmissionStatusLoading, error: applicantError, data: applicantData }
  ] = useLazyQuery(GET_APPLICANT_SUBMISSION_STATUS_REPORT, {
    client: onlineApplicationGraphQLClient,
    variables: {
      submissionType: null
    },
    fetchPolicy: "network-only"
  })


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      submissionType: null
    },

    validationSchema: Yup.object().shape({
      submissionType: Yup.string().required("This field is required")
    }),

    onSubmit: async (values, { setSubmitting }) => {
      refetchQuery({
        variables: {
          submissionType: parseInt(values.submissionType)
        },
        fetchPolicy: "network-only"
      })

      if (!applicantSubmissionStatusLoading) {
        setSubmitting(false)
      }
    }
  })

  useEffect(() => {
    if (!applicantSubmissionStatusLoading && applicantData) {
      setApplicantResults(applicantData?.getApplicantSubmissionReport?.data || [])
    }
  }, [applicantSubmissionStatusLoading, applicantData])

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Summary Report">
      <Row>
        <Col lg={12}>

          <Card>
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label>Application status type</Label>
                  <Select
                    // value={selectedGroup}
                    name="semester"
                    onChange={e => {
                      validation.setFieldValue("submissionType", e.value)
                    }}
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                    options={options}
                    className="select2-selection"
                    // defaultValue={updateProgramSemisterData?.studyYear}
                    // defaultInputValue={updateProgramSemisterData?.studyYear}
                  />
                  <Input
                    name="submissionType"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.submissionType || 1}
                    invalid={
                      !!(
                        validation.touched.submissionType &&
                        validation.errors.submissionType
                      )
                    }
                  />
                  {validation.touched.submissionType &&
                  validation.errors.submissionType ? (
                    <FormFeedback type="invalid">
                      {validation.errors.submissionType}
                    </FormFeedback>
                  ) : null}
                </div>
                <SubmitButtonHelper
                  type={"primary"}
                  name={"Submit"}
                  formik={validation}
                />
              </Form>

              {applicantSubmissionStatusLoading ? (
                <PlaceHolderLoader type="table" columSize={12} rows={5} />
              ) : (
                <div className="table-rep-plugin mt-2">
                  {
                    applicantResults.length > 0 && (
                      <button
                        disabled={loading}
                        onClick={() => {
                          downloadList(applicantResults)
                        }}
                        className="btn btn-sm btn-dark">
                        {
                          loading ? "Please wait..." : "Download applicant(s)"
                        }
                        </button>
                    )
                  }
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >


                    {/*<Table*/}
                    {/*  id="tech-companies-1"*/}
                    {/*  className="table table-striped table-bordered"*/}
                    {/*>*/}
                    {/*  <Thead>*/}
                    {/*    <Tr>*/}
                    {/*      <Th>S/No</Th>*/}
                    {/*      <Th data-priority="0">First name</Th>*/}
                    {/*      <Th data-priority="0">Middle name</Th>*/}
                    {/*      <Th data-priority="0">Last name</Th>*/}
                    {/*      <Th data-priority="0">Phone number</Th>*/}
                    {/*      <Th data-priority="0">Email</Th>*/}
                    {/*      <Th data-priority="1">Submission Status</Th>*/}
                    {/*      <Th data-priority="3">Action</Th>*/}
                    {/*    </Tr>*/}
                    {/*  </Thead>*/}
                    {/*  <Tbody>*/}
                    {/*    {applicantResults?.map((applicant, index) => (*/}
                    {/*      <Tr key={index}>*/}
                    {/*        <Td>{index + 1}</Td>*/}
                    {/*        <Td>{applicant?.firstName}</Td>*/}
                    {/*        <Td>{applicant?.middleName}</Td>*/}
                    {/*        <Td>{applicant?.lastName}</Td>*/}
                    {/*        <Td>{applicant?.phoneNumber}</Td>*/}
                    {/*        <Td>{applicant?.eamil}</Td>*/}
                    {/*        <Td>{applicant?.submissionStatus === true ? "Submitted" : "Not submitted"}</Td>*/}
                    {/*        <Td>*/}
                    {/*          <button onClick={() => {*/}
                    {/*            downloadList(applicant?.applicants, applicant?.date)*/}
                    {/*          }} type="button" disabled={loading}*/}
                    {/*                  className="btn btn-sm btn-primary">{loading === true ? "Please wait..." : "Export applicant(s)"}</button>*/}
                    {/*        </Td>*/}
                    {/*      </Tr>*/}
                    {/*    ))}*/}
                    {/*  </Tbody>*/}
                    {/*</Table>*/}
                  </div>
                </div>
              )}
            </CardBody>
          </Card>

        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default ApplicantSubmissionStatusReport