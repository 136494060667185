//REGISTE
import { gql } from '@apollo/client';


const GET_PROGRAMS = gql`
    query GetPrograms($pagination: PaginationInput!) {
        getPrograms(pagination: $pagination){
            status
            code
            message
            data{
                items{
                    uid
                    code
                    name
                    shortName
                    tcuCode
                    nacteCode
                    duration
                    registrationCode
                    departmentUid
                    programCategory{
                        uid
                        name
                        shortName
                    }
                }
                totalCount
            }
        }
    }
`;

const GET_PROGRAM = gql`
  query GetProgram($uid: String!) {
    getProgram(uid: $uid){
    status
    code
    message
    data{
        uid
        code
        name
        shortName
        tcuCode
        nacteCode
        duration
        registrationCode
        departmentUid
        programCategory{
          uid
          name
          shortName
        }
    }
  }
}
`;

const GET_PROGRAM_NAME = gql`
  query GetProgramName($programUid: String!) {
    getProgramName(programUid: $programUid){
    status
    message
    data
  }
}
`;
const GET_STUDENT_COURSES_REGISTRATION = gql`
  query GetProgramCourseAssessment($inputs: String!) {
    getProgramCourseAssessment(inputs: $inputs){
    status
    code
    message
    data{
        uid
    }
  }
}
`;


const GET_ALL_PROGRAMS = gql`
    query GetAllPrograms($pagination: PaginationInput!) {
        getAllPrograms(pagination: $pagination){
            status
            code
            message
            data{
                items{
                    uid
                    code
                    registrationCode
                    programCategory{
                        uid
                        name
                        shortName
                    }
                    name
                    tcuCode
                    duration
                }
            }
        }
    }
`;


export { GET_PROGRAMS,GET_STUDENT_COURSES_REGISTRATION,GET_PROGRAM, GET_PROGRAM_NAME,GET_ALL_PROGRAMS }
