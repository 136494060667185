//REGISTE
import { gql } from "@apollo/client"

const GET_REGION = gql`
query GetRegion($pagination: PaginationInput!) {
  getRegions(pagination: $pagination){
    status
    code
    message
    data{
      items{
      id
      uid
      name
      code
      country {
        id
        name
        code
        uid
      }
    }
    totalCount
    }
  }
}  
`;

const GET_REGIONS_BY_COUNTRY = gql`
  query GetRegionsByCountry($countryUid: String!) {
    getRegionsByCountry(countryUid: $countryUid) {
      status
      code
      message
      data {
        uid
        name
        code
      }
    }
  }
`

export { GET_REGION, GET_REGIONS_BY_COUNTRY }