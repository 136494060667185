import React, { useState, useEffect, createContext, useContext } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { GET_STUDENT, GET_USER } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import LayoutHelper from "helpers/LayoutHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { token } from "helpers/UrlHelper"
import { InstructorCourseDetailsContext } from "../InstructorCourseDetail"
import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"
import ThirdStep from "./ThirdStep"

export const UploadResultFromMoodleContext = createContext() // Create data to transfer to child modal

const UploadResultFromMoodleView = () => {
  const [updateStudentData, setUpdateStudentData] = useState(null)

  const {
    examCategoryID,
    examCategoryName,
    assessmentNumber,
    allocationUID,
    uploadResultOnline,
    setUploadResultOnline,
    assessments,
    uid,
    courseData,
  } = useContext(InstructorCourseDetailsContext)

  console.log(
    "Course Data: ",
    courseData?.getCourseAllocation?.data
  )

  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const [quizeFromStepTwo, setdataFromStepTwo] = useState(null)
  const [gradingMethodFromStepTwo, setGradingMethodFromStepTwo] = useState(null)

  const handleQuizeData = data => {
    setdataFromStepTwo(data)
  }

  const handleGradingMethodData = data => {
    setGradingMethodFromStepTwo(data)
  }

  const renderStepContent = tabId => {
    switch (tabId) {
      case 1:
        return <FirstStep />
      case 2:
        return (
          <SecondStep
            onQuizeChange={handleQuizeData}
            onGradingMethodChange={handleGradingMethodData}
          />
        )
      case 3:
        return <ThirdStep />
      default:
        return null
    }
  }

  const toggleTab = tab => {
    handleSubmit()
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const handleSubmit = values => {}

  return (
    <UploadResultFromMoodleContext.Provider
      value={{
        toggleTab,
        handleSubmit,
        activeTab,
        passedSteps,
        courseData,
        assessments,
        quizeFromStepTwo,
        uid,
        gradingMethodFromStepTwo,
      }}
    >
      <div className="container mt-3">
        <Row>
          <Col md={12} sm={12} lg={12}>
            <div className="text-center">
              <h5>Moodle Results Uploading</h5>
            </div>
          </Col>
        </Row>
        <Row style={{ marginRight: "4px", marginLeft: "4px" }}>
          <Col lg={12} md={12} sm={12}>
            <div className="wizard">
              <div className="steps clearfix">
                <ul style={{ whiteSpace: "nowrap" }}>
                  <NavItem
                    className={classnames({
                      current: activeTab === 1,
                    })}
                  >
                    <NavLink
                      className={classnames({
                        current: activeTab === 1,
                      })}
                      onClick={() => toggleTab(1)}
                      disabled={!(passedSteps || []).includes(1)}
                    >
                      <span className="number">1.</span> First Step
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      current: activeTab === 2,
                    })}
                  >
                    <NavLink
                      className={classnames({
                        active: activeTab === 2,
                      })}
                      onClick={() => toggleTab(2)}
                      disabled={!(passedSteps || []).includes(2)}
                    >
                      <span className="number">2.</span> Moodle Course Test
                      Types
                    </NavLink>
                  </NavItem>
                  <NavItem
                    className={classnames({
                      current: activeTab === 3,
                    })}
                  >
                    <NavLink
                      className={classnames({
                        active: activeTab === 3,
                      })}
                      onClick={() => toggleTab(3)}
                      disabled={!(passedSteps || []).includes(3)}
                    >
                      <span className="number">3.</span> Student Results and
                      Upload
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent activeTab={activeTab} className="body">
                  {renderStepContent(activeTab)}
                </TabContent>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </UploadResultFromMoodleContext.Provider>
  )
}

export default UploadResultFromMoodleView
