
import React from "react";
import { Route, Routes } from "react-router";
import Permission from "../Views/PermissionList";

const PermissionRoute = (props) => {

    return (
        <Routes>
            <Route path="/" element={<Permission />} />
        </Routes>
    );

}

export default PermissionRoute;