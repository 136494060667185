import React, { useState } from "react"

import { Card, CardBody, Col, Row } from "reactstrap"
import { Link, useLocation } from "react-router-dom"

import { GET_STUDENT, GET_STUDENT_BY_USERNAME } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import {
  registrationGraphQLClient,
  uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import { RegistrationStudentForm } from "./RegistrationPDF"
import { GET_USER_NEXT_OF_KIN } from "Modules/Students/Queries/NextOfKin/NextOfkinQueries"
import { GET_PROGRAM } from "Modules/Programs/Queries/ProgramQuaries"
import { jwtDecode } from "jwt-decode"
import FormAlertHelper from "helpers/FormAlertHelper"
import PlaceHolderLoader from "../../helpers/PlaceHolderLoader"
const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
const userData = accessToken ? jwtDecode(accessToken) : null
const userAuthorities = userData?.authorities || []
const hasAuthority = authority => userAuthorities.includes(authority)

const StudentDashboard = () => {
  const location = useLocation()
  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch,
  } = useQuery(GET_STUDENT_BY_USERNAME, {
    client: uaaGraphQLClient,
    skip:
      !JSON.parse(localStorage.getItem("authUser"))?.data?.username ||
      location?.pathname === "/teaching-assessment/dashboard",
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })

  const {
    loading: userByUsernameLoading,
    error: userByusernameError,
    data: userByusernameData,
    userByusernameRefetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip:
      !JSON.parse(localStorage.getItem("authUser"))?.data?.username ||
      location?.pathname === "/teaching-assessment/dashboard",
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })


  const student = userDetails?.searchUserByUsername?.data
  const studentByUsername = userByusernameData?.getUserByUsername?.data

  const {
    loading: nextOfKinLoading,
    error: nextOfKinError,
    data: nextOfKin,
    refetch: reftechNextOfKin,
  } = useQuery(GET_USER_NEXT_OF_KIN, {
    client: uaaGraphQLClient,
    skip:
      !student?.uid || location?.pathname === "/teaching-assessment/dashboard",
    variables: {
      userUid: student?.uid,
    },
    fetchPolicy: "cache-and-network",
  })

  const {
    loading: ProgramLoading,
    error: errorProgram,
    data: Program,
    // refetch: reftechProgram,
  } = useQuery(GET_PROGRAM, {
    client: registrationGraphQLClient,
    variables: {
      uid: student?.student?.programmeUid,
    },
    skip:
      !student?.student?.programmeUid ||
      location?.pathname === "/teaching-assessment/dashboard",
    fetchPolicy: "no-cache",
  })

  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  const downloadMedicalForm = () => {
    const pdfUrl = process.env.PUBLIC_URL + "/forms/sua_medical.pdf" // Path to your PDF file in the "public" directory
    const a = document.createElement("a")
    a.href = pdfUrl
    a.download = "sua_medical_form.pdf" // You can change the filename here
    a.style.display = "none"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  const nextOfKinData = nextOfKin?.getUserNextOfKins?.data

  const studentNextOfKin = nextOfKinData?.[0]?.fullName
    ? `${nextOfKinData[0].fullName}, Kinship: ${
        nextOfKinData[0].kinship?.name || ""
      }`
    : ""

  const programName = Program?.getProgram?.data?.shortName

  return (
    <div className="container">
      <h1>Dashboard</h1>
      {hasAuthority("VIEW_STUDENT_DASHBOARD") ? (
        <>
          {location?.pathname === "/teaching-assessment/dashboard" ? (
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    Teaching Assessment
                    <br/>
                    <Link
                      to="/teaching-assessment/courses"
                      className="btn btn-sm btn-primary mt-4"
                    >
                      <span>Click here to start course assessment</span>
                    </Link>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>{/*s*/}</Col>
                </Row>
                {userLoading && (
                  <PlaceHolderLoader rows={4} type="table" columSize={12} />
                )}

                {!userLoading && !userError && student && (
                  <Row>
                    <Col md={6}>
                      <div className="table-responsive">
                        <h3>
                          Welcome:{" "}
                          {student?.firstName +
                            " " +
                            student?.middleName +
                            " " +
                            student?.lastName}
                        </h3>

                        <table className="table">
                          <tbody className="font-weight-bolder">
                            <tr>
                              <th>Reg No</th>
                              <td>
                                <b>{student?.student?.registrationNumber}</b>
                              </td>
                            </tr>
                            <tr>
                              <th>Study Year</th>
                              <td>
                                <b>{`Year ` + student?.student?.studyYear}</b>
                              </td>
                            </tr>
                            <tr>
                              <th>Academic Satus</th>
                              <td>
                                <b>{student?.student?.status}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* <div className="float-bottom">
                   <h4>Registration Form</h4>
                   <button className="btn btn-primary" onClick={downloadRegistratuionForm}>Print registration form</button>
               </div> */}
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col md={6}>
                          <h4>Student Registration Form</h4>
                          <button
                            onClick={() =>
                              RegistrationStudentForm(
                                student,
                                studentNextOfKin,
                                programName,
                                studentByUsername
                              )
                            }
                            className="btn btn-outline-primary mt-2"
                          >
                            Print Registration Form
                          </button>{" "}
                        </Col>
                        <Col md={6}>
                          <div className="float-end">
                            <h4>Medical Form</h4>
                            <button
                              onClick={downloadMedicalForm}
                              className="btn btn-outline-primary mt-2"
                            >
                              Print Medical Form
                            </button>{" "}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          )}
        </>
      ) : (
        <div>
          {userLoading && (
            <PlaceHolderLoader rows={4} type="table" columSize={12} />
          )}
          {!userLoading && !userError && (
            <>
              <Row>
                <Col md={12}>
                  <div className="table-responsive">
                    {/* <h5 style={{ color: "red", textAlign: "center" }}>
                      Please upload passport photo <u>(My profile Menu)</u> and
                      your signature <u>(Signature Pad Menu)</u> before 27th
                      December 2023 in order to get University ID Card
                    </h5> */}
                  </div>
                </Col>
              </Row>
              <h3>
                Welcome:{" "}
                {student?.firstName +
                  " " +
                  student?.middleName +
                  " " +
                  student?.lastName}
              </h3>
            </>
          )}
        </div>
      )}
    </div>
  )
}
export default StudentDashboard
