// export default PermissionList;
import React, { createContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_PERMISSIONS } from "../Queries/PermissionQueries"
import PermissionModal from "./PermissionModal"
import { DELETE_PERMISSIONS } from "../Mutations/PermissionMutation"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"

const breadcrumbItem = [
  {
    title: "Permissions List",
    path: "",
    isActive: true,
  },
]

export const PermissionContext = createContext() //Create data to transfer to child modal

const PermissionList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedPermission, setSelectedPermission] = useState(null) //Get data for deleting
  const [updatePermissionData, setUpdatePermissionData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_PERMISSIONS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const [
    searchPermissiones,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_PERMISSIONS, { client: uaaGraphQLClient })

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    //fetchData(page)
    setCurrentPage(selectedPage.selected)
  }

  const [deletePermission] = useMutation(DELETE_PERMISSIONS, {
    client: uaaGraphQLClient,
    variables: { uid: selectedPermission?.uid },
    refetchQueries: [
      {
        query: GET_PERMISSIONS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = permission => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [permission.uid]: !prevState[permission.uid],
    }))
  }

  const handleDeletePermission = (Permission) => {
    setSelectedPermission(Permission)
    showDeleteWarning(() => {
      performUserDeletion()
    })
  }

  const performUserDeletion = async () => {
    try {
      const response = await deletePermission()
      showToast(response.data.removePermission.message, "success")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading && !searchLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchPermissiones({
      variables: { pagination: { ...pagination, ...data } },
    })
  }

  const searchQuery = () => {
    const value = formikSearch.values?.search || ""

    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchPermissiones({
        variables: { pagination: { ...pagination, ...data } },
      })
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }

  const Permissiones = data?.getPermissions?.data?.items
  const SearchedPermissions = searchData?.getPermissions?.data?.items

  const results = SearchedPermissions ? SearchedPermissions : Permissiones
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getPermissions?.data?.totalCount ||
    searchData?.getPermissions?.data?.items ||
    0

  return (
    <PermissionContext.Provider
      value={{ updatePermissionData, setUpdatePermissionData }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Permissions List"
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <PermissionModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Code</Th>
                            <Th data-priority="1">Description</Th>
                            <Th data-priority="1">Service</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results?.slice(startIndex, endIndex)
                            .map((Permission, index) => (
                              <Tr key={Permission?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{Permission?.name}</Td>
                                <Td>{Permission?.code}</Td>
                                <Td>{Permission?.description}</Td>
                                <Td>{Permission?.service}</Td>
                                <Td>
                                  <DropDownActionHelper
                                    data={Permission}
                                    onUpdate={setUpdatePermissionData}
                                    onDelete={handleDeletePermission}
                                  />
                                  
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </PermissionContext.Provider>
  )
}

export default PermissionList
