import React, { useEffect, useState } from "react"
import "flatpickr/dist/themes/material_blue.css";
import { useNavigate } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    Button,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input, InputGroup,
    Label,
    Row
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import PropTypes from "prop-types";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import ToastHelper from "../../../../../helpers/ToastHelper";


const StudentExamPostponementForm = props => {
    const navigate = useNavigate();
    const  {closeBtn} = props
    const onchangeValues = (e,input) => {
        validationType.setFieldValue(input,e.value)
    }
    const ProgramsOpt = [
        { label: "Bsc Wild Life Management", value: 5 },
        { label: "Bsc. WIth informatics", value: 4 },
        { label: "Bsc. Engineering", value: 3 },
    ]

// Form validation
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            reason:'',
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required("Reason for postponement is required"),
        }),
        onSubmit: (values) => {
            ToastHelper('Query Send Successful','success')
            navigate('/Students/Payments/queries')
        }
    });

    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validationType.handleSubmit();
                                return false;
                            }}>
                            <div className='row'>
                                <div className="col-12 mb-3">
                                    <Label className="form-label">Reason For Program Change</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data=""
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            validationType.setFieldValue('reason',editor.getData())
                                        }}
                                    />
                                    <Input
                                        name="description"
                                        placeholder="Enter Description"
                                        type="hidden"
                                        onChange={validationType.handleChange}
                                        onBlur={validationType.handleBlur}
                                        value={validationType.values.reason || ""}
                                        invalid={
                                            !!(validationType.touched.reason && validationType.errors.reason)
                                        }
                                    />

                                    {validationType.touched.reason && validationType.errors.reason ? (
                                        <FormFeedback type="invalid">{validationType.errors.reason}</FormFeedback>
                                    ) : null}
                                </div>

                            </div>
                            <SubmitButtonHelper type={'primary'} name={'Submit Request'} formik={validationType} />
                            {" "} {closeBtn}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
StudentExamPostponementForm.propTypes = {
    closeBtn : PropTypes.any.isRequired
}
export default StudentExamPostponementForm;