// export default RegionList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_REGION, GET_REGIONS_BY_COUNTRY } from "../Queries/RegionQueries"
import { DELETE_REGION } from "../Mutations/RegionMutations"
import RegionModal from "./RegionModal"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { Link, useNavigate, useLocation } from "react-router-dom"

const breadcrumbItem = [
  {
    title: "Region List",
    path: "",
    isActive: true,
  },
]


export const RegionContext = createContext() //Create data to transfer to child modal

const RegionList = () => {

  const searchValues = {
    search: "",
  }

  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })


  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = campus => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [campus.uid]: !prevState[campus.uid],
    }))
  }

  let counter = 0

  const location = useLocation()

  const backButton = useNavigate()

  const navigate = useNavigate()

  let { country } = location.state // extract state data

  // console.log("Country, ", country)

  const handleGoBack = () => {
    backButton(-1)
  }

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedRegion, setSelectedRegion] = useState(null) //Get data for deleting
  const [updateRegionData, setUpdateRegionData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_REGIONS_BY_COUNTRY, {
    client: uaaGraphQLClient,
    skip: !country,
    variables: {
      countryUid: country,
    },
  })

  const [
    searchRegions,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_REGIONS_BY_COUNTRY, {
    client: uaaGraphQLClient,
    variables: {
      countryUid: country,
    },
  })

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteRegions] = useMutation(DELETE_REGION, {
    client: uaaGraphQLClient,
    variables: { uid: selectedRegion?.uid },
    refetchQueries: [
      {
        query: GET_REGIONS_BY_COUNTRY,
        variables: {
          countryUid: country,
        },
      },
    ],
  })

  const handleDeleteRegion = deleteData => {
    let region = deleteData[0]

    setSelectedRegion(region)
    showDeleteWarning(() => {
      performDataDeletion()
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteRegions()
      showToast(
        response.data.removeRegion.message,
        response.data.removeRegion.code === 8000 ? "success" : "error"
      )
    } catch (error) {
      // console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchRegions({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }
  const dataArrray = data?.getRegionsByCountry.data
  const searchedData = searchData?.getRegionsByCountry?.data
  const result = searchedData ? searchedData : dataArrray
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getRegionsByCountry.data.totalCount ||
    searchData?.getRegionsByCountry?.data ||
    0
  return (
    <RegionContext.Provider value={{ updateRegionData, setUpdateRegionData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Region List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <RegionModal countryInfo={country} />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Code</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            .slice(startIndex, endIndex)
                            .map((region, index) => (
                              <Tr key={region?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{region?.code}</Td>
                                <Td>{region?.name}</Td>
                                <Td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DropDownActionHelper
                                      data={[region, { country: country }]}
                                      onUpdate={setUpdateRegionData}
                                      onDelete={handleDeleteRegion}
                                    />

                                    <a
                                      className="btn btn-link text-info font-weight-bolder"
                                      style={{ marginLeft: "10px" }} // You can adjust the margin as needed
                                      onClick={() => {
                                        navigate("/districts", {
                                          state: { regionUid: region?.uid },
                                        })
                                      }}
                                    >
                                      <small>Districts</small>
                                    </a>
                                  </div>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </RegionContext.Provider>
  )
}

export default RegionList
