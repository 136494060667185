import React, { useContext, useRef, useState, useEffect } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { CREATE_HOSTELS } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import showToast from "helpers/ToastHelper"
import { GET_COUNTRY, GET_HOSTELS } from "../Queries/AccommodationQueries"
import { HostelContext } from "./HostelList"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient, registrationGraphQLClient, accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_CAMPUS } from "Modules/Campus/Queries/CampusQueries"
import { GET_PROGRAMS } from "Modules/Programs/Queries/ProgramQuaries"

const HostelModal = () => {
  const { updateHostel, setUpdateHostel } = useContext(HostelContext)
  const [hostel] = useMutation(CREATE_HOSTELS, {client: accomodationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const { loading, error, data, refetch } = useQuery(GET_CAMPUS, {
    client:uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })
  const campus_options = data?.getCampuses.data?.items

  const { loading: program_loading, error: program_error, data: program_data, refetch: refe } = useQuery(GET_PROGRAMS, {
    client:registrationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })
  const programs_options = program_data?.getPrograms?.data?.items
  
  const [selectedCampuses, setSelectedCampuses] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const handleModalOpened = () => {
    if (campus_options?.length) {
      if(updateHostel?.campuses?.length){
        setSelectedCampuses(campus_options?.map((campus) => {
          if(updateHostel.campuses.includes(campus.code)) {
            return {
              value: campus.code,
              label: campus.name + ' ['+ campus.code +']',
            }
          }
        }))
      } else {
        setSelectedCampuses([])
      }
    }
    
    if (programs_options?.length) {
      if(updateHostel?.programs?.length){
        setSelectedPrograms(programs_options?.map((program) => {
          if(updateHostel.programs.includes(program.code)) {
            return {
              value: program.code,
              label: program.name + ' ['+ program.code +']',
            }
          }
        }))
      } else {
        setSelectedPrograms([])
      }
    }
  }

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const updateProgramsOptions = (e, campus) => {

  }

  const query = GET_COUNTRY
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: updateHostel ? updateHostel.name : "",
      code: updateHostel ? updateHostel.code : "",
      location: updateHostel ? updateHostel.location : "",
      description: updateHostel ? updateHostel.description : "",
      // campuses: updateHostel ? updateHostel.campuses : [],
      // programs: updateHostel ? updateHostel.programs : [],
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Hostel name is required"),
      code: Yup.string().required("Hostel code is required"),
      description: Yup.string().required("The description is required"),
      location: Yup.string().required("Hostel location is required")
    }),

    onSubmit: (values, {setSubmitting}) => {
      hostel({
        variables: {
          inputs: [{ ...values, uid: updateHostel?.uid }],
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_HOSTELS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],

        onCompleted: ({ registerHostels }) => {
          //updateFunction(client.cache, { data: { response } });
          if (registerHostels.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateHostel(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateHostel(true) // Close the modal
            setSubmitting(false)
          }

          showToast(
            registerHostels.message,
            registerHostels.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Hostel
      </Button>
      <Modal
        isOpen={modalOpen || !!updateHostel}
        onOpened={handleModalOpened}
        size="lg"
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateHostel ? "Edit Hostel" : "Add New Hostel"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className='row'>            
              <div className="col-6 mb-1">
                <label htmlFor="code" className="col-form-label">
                  Code <span className="text-danger">*</span>: 
                </label>
                <Input
                  type="text"
                  name="code"
                  className="form-control"
                  id="code"
                  placeholder="Enter Hostel Code"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.code || ""}
                  invalid={
                    validation.touched.code && validation.errors.code
                      ? true
                      : false
                  }
                />
                {validation.touched.code && validation.errors.code ? (
                  <FormFeedback type="invalid">
                    {validation.errors.code}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="col-6 mb-1">
                <label htmlFor="name" className="col-form-label">
                  Name <span className="text-danger">*</span>: 
                </label>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Enter Hostel Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </div>

            <div className="mb-1">
              <label htmlFor="location" className="col-form-label">
                Location <span className="text-danger">*</span>: 
              </label>
              <Input
                type="text"
                name="location"
                className="form-control"
                id="location"
                placeholder="Enter Hostel Location"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.location || ""}
                invalid={
                  validation.touched.location && validation.errors.location
                    ? true
                    : false
                }
              />
              {validation.touched.location && validation.errors.location ? (
                <FormFeedback type="invalid">
                  {validation.errors.location}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-1">
              <label htmlFor="description" className="col-form-label">
                Description
              </label>
              <Input
                type="textarea"
                name="description"
                className="form-control"
                id="description"
                placeholder="Enter Hostel Description"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                invalid={
                  validation.touched.description && validation.errors.description
                    ? true
                    : false
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
            
            <div className="mb-1">
              <label className="col-form-label">
                Dedicated For <span className="text-danger">*</span>: 
              </label>
              
              <Row>
                <Col className="col-6">
                  <Select
                    id="campuses"
                    name="campuses"
                    placeholder="Select Allowed Campuses"
                    isMulti
                    value={selectedCampuses}
                    isDisabled={selectedPrograms.length && !selectedCampuses.length}
                    onChange={selectedOptions => {
                      const selected = []
                      const selectedValues  = selectedOptions.map(
                        (option) => {
                          selected.push({
                            value: option.value,
                            label: option.label
                          })
                          return option.value
                        }
                      )

                      setSelectedCampuses(selected)
                      validation.setFieldValue("campuses", selectedValues)
                    }}
                    options={campus_options?.map(campus => ({
                      value: campus.code,
                      label: campus.name + ' ['+ campus.code +']',
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.campuses && validation.errors.campuses ? (
                    <FormFeedback type="invalid">
                      {validation.errors.campuses}
                    </FormFeedback>
                  ) : null}
                </Col>
                
                <Col className="col-6">
                  <Select
                    id="programs"
                    name="programs"
                    placeholder="Select Allowed Programs"
                    isMulti
                    value={selectedPrograms}
                    isDisabled={selectedCampuses.length && !selectedPrograms.length}
                    onChange={selectedOptions => {
                      const selected = []
                      const selectedValues  = selectedOptions.map(
                        (option) => {
                          selected.push({
                            value: option.value,
                            label: option.label
                          })
                          return option.value
                        }
                      )

                      setSelectedPrograms(selected)
                      validation.setFieldValue("programs", selectedValues)
                    }}
                    options={programs_options?.map(program => ({
                      value: program.code,
                      label: program.name + ' ['+ program.code +']',
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.programs && validation.errors.programs ? (
                    <FormFeedback type="invalid">
                      {validation.errors.programs}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateHostel(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default HostelModal
