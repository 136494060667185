import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_STAFF_SPECIAL_APPOINTMENT } from "../Mutations/SpecialAppointmentsMutations"
import { GET_SPECIAL_APPOINTMENTS } from "../Queries/SpecialAppointmentsQueries"
import { SpecialAppointmentsContext } from "./SpecialAppointments"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import moment from "moment"
import { convertFileToBase64 } from "helpers/PdfHelper"

const SpecialAppointmentsModal = () => {
  const {
    updateSpecialAppointmentsData,
    setUpdateSpecialAppointmentsData,
    staffUid,
  } = useContext(SpecialAppointmentsContext)

  const AppointmentTitles = [
    {
      label: "Lecturer",
      value: "1fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Program Coordinator",
      value: "5fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Department Chair",
      value: "6fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Instructor",
      value: "3fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Associate Professor",
      value: "2fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Teaching Assistant",
      value: "4fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
  ]

  const AppointmentOffices = [
    {
      label: "Human Resources Department",
      value: "1fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Academic Affairs Office",
      value: "2fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Board of Trustees or Governing Council",
      value: "4fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Faculty or Departmental Committees",
      value: "3fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Deans and Heads of Departments",
      value: "6fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Registrar's Office",
      value: "5fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
  ]

  const AppointmentTypes = [
    {
      label: "Adjunct Appointment",
      value: "3fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Research Appointment",
      value: "6fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Regular Appointment",
      value: "1fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Visiting Appointment",
      value: "4fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Administrative Appointment",
      value: "5fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
    {
      label: "Temporary or Term Appointment",
      value: "2fd61888-afff-4f42-bc81-9cf6546a77ca",
    },
  ]

  const ForceAppointment = [
    {
      label: "YES",
      value: true,
    },
    {
      label: "NO",
      value: false,
    },
  ]

  const [SpecialAppointments] = useMutation(
    ADD_UPDATE_STAFF_SPECIAL_APPOINTMENT,
  )
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_SPECIAL_APPOINTMENTS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      uid: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.uid
        : "",
      staffUid: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.staffUid
        : staffUid,
      startDate: updateSpecialAppointmentsData
        ? moment(updateSpecialAppointmentsData.startDate).format("YYYY-MM-DD")
        : "",
      endDate: updateSpecialAppointmentsData
        ? moment(updateSpecialAppointmentsData.endDate).format("YYYY-MM-DD")
        : "",
      appointmentLetter: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.appointmentLetter
        : "",
      responsibilities: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.responsibilities
        : "",
      appointmentTitleUid: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.appointmentTitleUid
        : "",
      appointmentTypeUid: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.appointmentTypeUid
        : "",
      appointingOfficeUid: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.appointingOfficeUid
        : "",
      forceAppointing: updateSpecialAppointmentsData
        ? updateSpecialAppointmentsData.forceAppointing
        : "",
    },

    validationSchema: Yup.object().shape({
      appointmentLetter: Yup.mixed().required("Appointment Letter is required"),
      // appointmentLetter: mixed().test(
      //   "fileSize",
      //   "The file is too large",
      //   value => {
      //     if (!value.length) return true
      //     return value[0].size <= 2000000
      //   },
      // ),
      responsibilities: Yup.string().required(
        "Responsibilities Type is required",
      ),
      startDate: Yup.string().required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
      appointmentTitleUid: Yup.string().required(
        "Appointment Title is required",
      ),
      appointmentTypeUid: Yup.string().required("Appointment Type is required"),
      appointingOfficeUid: Yup.string().required(
        "Appointing Office is required",
      ),
      forceAppointing: Yup.string().required("Force Appointing is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      console.log(values)

      if (values.uid === "") {
        delete values.uid
      }

      SpecialAppointments({
        client: asimsGraphQLClient,
        variables: {
          inputs: {
            ...values,
            appointmentTitleUid: values.appointmentTitleUid.toString(),
            appointmentTypeUid: values.appointmentTypeUid.toString(),
            appointingOfficeUid: values.appointingOfficeUid.toString(),
            appointmentLetter:
              values.appointmentLetter instanceof File
                ? await convertFileToBase64(values.appointmentLetter)
                : null,
          },
        },
        refetchQueries: [
          {
            query: GET_SPECIAL_APPOINTMENTS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerSpecialAppointments }) => {
          validation.resetForm()
          setModalOpen(false)
          setUpdateSpecialAppointmentsData(false) // Close the modal
          if (registerSpecialAppointments.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateSpecialAppointmentsData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerSpecialAppointments.message,
            registerSpecialAppointments.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error.message)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Special Appointment
      </Button>

      <Modal
        isOpen={modalOpen || !!updateSpecialAppointmentsData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-country" id="staticBackdropLabel">
            {!!updateSpecialAppointmentsData
              ? "EDIT SPECIAL APPOINTMENT"
              : "ADD SPECIAL APPOINTMENT"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateSpecialAppointmentsData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <h5 className="form-details-header">Appointment Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointmentTypeUid"
                      className="col-form-label"
                    >
                      Appointment Type:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("appointmentTypeUid", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateSpecialAppointmentsData?.appointmentType?.uid
                      }
                      defaultInputValue={
                        updateSpecialAppointmentsData?.appointmentType?.name
                      }
                      options={AppointmentTypes}
                      className="select2-selection"
                    />
                    <Input
                      name="appointmentTypeUid"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appointmentTypeUid || ""}
                      invalid={
                        !!(
                          validation.touched.appointmentTypeUid &&
                          validation.errors.appointmentTypeUid
                        )
                      }
                    />
                    {validation.touched.appointmentTypeUid &&
                    validation.errors.appointmentTypeUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointmentTypeUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointingOfficeUid"
                      className="col-form-label"
                    >
                      Appointment Office:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("appointingOfficeUid", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateSpecialAppointmentsData?.appointingOffice?.uid
                      }
                      defaultInputValue={
                        updateSpecialAppointmentsData?.appointingOffice?.name
                      }
                      options={AppointmentOffices}
                      className="select2-selection"
                    />
                    <Input
                      name="appointingOfficeUid"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appointingOfficeUid || ""}
                      invalid={
                        !!(
                          validation.touched.appointingOfficeUid &&
                          validation.errors.appointingOfficeUid
                        )
                      }
                    />
                    {validation.touched.appointingOfficeUid &&
                    validation.errors.appointingOfficeUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointingOfficeUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointmentTitleUid"
                      className="col-form-label"
                    >
                      Appointment Title:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("appointmentTitleUid", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateSpecialAppointmentsData?.appointmentTitle?.uid
                      }
                      defaultInputValue={
                        updateSpecialAppointmentsData?.appointmentTitle?.name
                      }
                      options={AppointmentTitles}
                      className="select2-selection"
                    />
                    <Input
                      name="appointmentTitleUid"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.appointmentTitleUid || ""}
                      invalid={
                        !!(
                          validation.touched.appointmentTitleUid &&
                          validation.errors.appointmentTitleUid
                        )
                      }
                    />
                    {validation.touched.appointmentTitleUid &&
                    validation.errors.appointmentTitleUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointmentTitleUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="appointmentLetter"
                      className="col-form-label"
                    >
                      Appointment Letter:
                    </label>
                    <Input
                      type="file" // Set the type to "file" for file input
                      name="appointmentLetter"
                      className="form-control"
                      id="appointmentLetter"
                      onChange={event => {
                        validation.setFieldValue(
                          "appointmentLetter",
                          event.currentTarget.files[0],
                        )
                      }}
                      onBlur={validation.handleBlur}
                      invalid={
                        !!(
                          validation.touched.appointmentLetter &&
                          validation.errors.appointmentLetter
                        )
                      }
                    />
                    {validation.touched.appointmentLetter &&
                    validation.errors.appointmentLetter ? (
                      <FormFeedback type="invalid">
                        {validation.errors.appointmentLetter}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="12" lg="12">
                  <div className="mb-3">
                    <label
                      htmlFor="responsibilities"
                      className="col-form-label"
                    >
                      Responsibilities:
                    </label>
                    <textarea
                      name="responsibilities"
                      className="form-control"
                      id="responsibilities"
                      placeholder="Enter Responsibilities"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.responsibilities || ""}
                      onInvalid={
                        !!(
                          validation.touched.responsibilities &&
                          validation.errors.responsibilities
                        )
                      }
                    />
                    {validation.touched.responsibilities &&
                    validation.errors.responsibilities ? (
                      <FormFeedback type="invalid">
                        {validation.errors.responsibilities}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Appointment Period</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="startDate" className="col-form-label">
                      Start Date:
                    </label>
                    <Input
                      type="date"
                      name="startDate"
                      className="form-control"
                      id="startDate"
                      placeholder="Enter Start Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      invalid={
                        !!(
                          validation.touched.startDate &&
                          validation.errors.startDate
                        )
                      }
                    />
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="endDate" className="col-form-label">
                      End Date:
                    </label>
                    <Input
                      type="date"
                      name="endDate"
                      className="form-control"
                      id="endDate"
                      placeholder="Enter End Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      invalid={
                        !!(
                          validation.touched.endDate &&
                          validation.errors.endDate
                        )
                      }
                    />
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Appointment Status</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="forceAppointing" className="col-form-label">
                      Force Appointment:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("forceAppointing", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateSpecialAppointmentsData?.forceAppointing
                      }
                      defaultInputValue={
                        updateSpecialAppointmentsData?.forceAppointing
                      }
                      options={ForceAppointment}
                      className="select2-selection"
                    />
                    <Input
                      name="forceAppointing"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.forceAppointing || ""}
                      invalid={
                        !!(
                          validation.touched.forceAppointing &&
                          validation.errors.forceAppointing
                        )
                      }
                    />
                    {validation.touched.forceAppointing &&
                    validation.errors.forceAppointing ? (
                      <FormFeedback type="invalid">
                        {validation.errors.forceAppointing}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6"></Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateSpecialAppointmentsData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default SpecialAppointmentsModal
