import React, { useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { Card, CardBody, Col, Row } from "reactstrap"
import { GenerateStudentExamTicket } from "helpers/GenerateStudentExamTicket"
import { useQuery } from "@apollo/client"
import { GET_STUDENT_BY_USERNAME, GET_USER_DOCUMENT } from "../../../Users/Queries/UserQuery"
import { registrationGraphQLClient, uaaGraphQLClient } from "../../../Authentication/ApolloClient"
import { GET_STUDENT_REGISTERED_EXAMS } from "../../Queries/Registration/StudentExamRegistrationQuery"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import user1 from "../../../../assets/images/users/avatar-1.jpg"
import { GET_CURRENT_ACADEMICYEAR } from "../../../AcademicYears/Queries/AcademicYearsQueries"
import { sr2URL, uaaRestUrl } from "../../../../helpers/UrlHelper"
import { GET_PROGRAM } from "../../../Programs/Queries/ProgramQuaries"
import toastHelper from "../../../../helpers/ToastHelper"

const breadcrumbItem = [
  {
    title: "Print Exam Ticket",
    path: "",
    isActive: true
  }
]
const ExamTicket = () => {
  const [semesters, setSemesters] = useState([]),
    [academicYear, setAcademicYear] = useState([]),
    [studyYear, setStudyYear] = useState([]),
    [fetching, setFetching] = useState([])
  const [loadingSR2, setLoadingSR2] = useState(false)

  const {
    loading: loadingData,
    error: studentError,
    data: studentData,
    refetch: studentRefetch
  } = useQuery(GET_STUDENT_BY_USERNAME, {
    client: uaaGraphQLClient,
    skip:
      !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username
    },
    fetchPolicy: "network-only"
  })

  const student = studentData?.searchUserByUsername?.data

  const { loading: loadingSignature,error: errorSignature,data: signatureData } = useQuery(GET_USER_DOCUMENT, {
    client: uaaGraphQLClient,
    variables: {
      userUid: "2e5b53e7-3097-4ffe-b2e9-9e7759108d57",
      documentType: 'signature'
    },
    fetchPolicy: "no-cache",
  })

  const dus_signature = signatureData?.getUserDocuments?.data
  let signaturePad = ''
  if(signatureData?.getUserDocuments?.data?.base64doc)
    signaturePad = "data:image/jpeg;base64," +signatureData?.getUserDocuments?.data?.base64doc

  const {
    loading: courseLoading,
    error: courseError,
    data: courseData,
    refetch: refetchData
  } = useQuery(GET_STUDENT_REGISTERED_EXAMS, {
    client: registrationGraphQLClient,
    skip: !student?.student?.uid,
    variables: {
      studentUid: student?.student?.uid
    },
    fetchPolicy: "network-only"
  })

  const {
    loading: ProgramLoading,
    error: errorProgram,
    data: Program
    // refetch: reftechProgram,
  } = useQuery(GET_PROGRAM, {
    client: registrationGraphQLClient,
    variables: {
      uid: student?.student?.programmeUid
    },
    skip: !student?.student?.programmeUid,
    fetchPolicy: "no-cache"
  })

  const program = Program?.getProgram.data?.shortName

  const {
    loading: activeYearLoading,
    error: activeYearErrro,
    data: activeYear,
    refetch: refetchActiveYear
  } = useQuery(GET_CURRENT_ACADEMICYEAR, {
    client: registrationGraphQLClient,
    skip: !student?.student?.uid,
    variables: {
      studentUid: student?.student?.uid
    },
    fetchPolicy: "network-only"
  })

  const qr_code_type = "EXAM_TICKET" // Replace with the actual QR code type
  const qr_code_data = student?.student?.uid // Replace with the actual QR code data

  const courses = courseData?.getStudentCurrentRegisteredExam?.data
  const activeAcademicYear = activeYear?.getActiveAcademicYear?.data

  const tableDataResponse = courses?.map((item, index) => {
    const course = item.studentCourseRegistration.programCourse.course
    const credit = item.studentCourseRegistration.programCourse.credit

    return [
      (index + 1).toString(),
      course.code,
      credit // This can be modified based on what you need, e.g., credit or core/elective.
    ]
  })

  const currentDateFull = new Date()
  const currentDate = currentDateFull.toISOString().split("T")[0]

  const { loading, error, data } = useQuery(GET_USER_DOCUMENT, {
    client: uaaGraphQLClient,
    variables: {
      userUid: JSON.parse(localStorage.getItem("authUser"))?.data?.uid,
      documentType: "profile"
    },
    fetchPolicy: "cache-first",
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.uid
  })

  if (data?.getUserDocuments?.data?.base64doc)
    user1 = "data:image/jpeg;base64," + data?.getUserDocuments?.data?.base64doc


  const generateExaminationTicket = () => {
    setLoadingSR2(true)
    fetch(sr2URL + "students/payment_status?registration_number=" + student?.student?.registrationNumber + "&balance=" + true, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
      // body: JSON.stringify({ registration_number: registration_number }),
    }).then(response => response.json()).then(data => {
      if (data.status !== 200) {
        toastHelper(data.message, "error")
        setLoadingSR2(false)
      } else if (data.status === 200) {
        fetch(`${uaaRestUrl}/generate_qr_code/${qr_code_type}/${qr_code_data}`)
          .then(response => {
            if (!response.ok) {
              setLoadingSR2(false)
              throw new Error("Network response was not ok")
            }
            return response.blob()
          })
          .then(async qrCodeBlob => {
            const qrCodeBase64 = await new Promise((resolve) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.readAsDataURL(qrCodeBlob)
            })
            GenerateStudentExamTicket(
              student,
              tableDataResponse,
              currentDate,
              user1,
              activeAcademicYear,
              qrCodeBase64,
              program,
              signaturePad
            )
            setLoadingSR2(false)
          })
          .catch(error => {
            setLoadingSR2(false)
            console.error("Error fetching QR code:", error)
          })
      }
    })
      .catch((error) => {
        console.error("Error submitting data:", error)
        return false
      })
  }

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Examination Ticket">
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {
                loadingData || courseLoading || loading || activeYearLoading || ProgramLoading ? (
                  <PlaceHolderLoader rows={4} type="table" columSize={12} />
                ) : (
                  <>
                    {
                      courses?.length > 0 ? (
                        <div className="float-end">
                          <button disabled={loadingSR2} className="btn btn-sm btn-primary"
                                  onClick={() => generateExaminationTicket()}>
                            {
                              loadingSR2 ? (
                                <>Please wait...</>
                              ) : (
                                <>Print
                                  Exam
                                  Ticket</>
                              )
                            }
                          </button>
                        </div>
                      ) : (
                        <div className="mb-3 alert alert-dismissible alert-danger">
                          <strong>
                            You have not registered for examination
                          </strong>
                        </div>
                      )
                    }
                  </>
                )
              }
            </CardBody>
          </Card>
        </Col>

      </Row>
    </LayoutHelper>
  )
}

export default ExamTicket
