import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Button, Card, CardBody, CardHeader, CardText, CardTitle, Form, Modal} from "reactstrap";
import {useMutation} from "@apollo/client";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../../../helpers/ToastHelper";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../../helpers/PaginationHelper";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import {EntryQualificationConext} from "../EntryQualification";
import {CREATE_APPLICANT_CERTIFICATE} from "../../../Mutations/ApplicantMutation";
import {onlineApplicationGraphQLClient} from "../../../../Authentication/ApolloClient";
import {GET_APPLICANT_CERTIFICATE_RESULT} from "../../../Queries/ApplicantQueries";
const OALevelResultConfirmation = (props) => {
    const {results,formik} = props
    const [create] = useMutation(CREATE_APPLICANT_CERTIFICATE)
    const {setOpen,open, applicant, user} = useContext(EntryQualificationConext)
    // console.log(formData.values)
    return(
        <Modal
            isOpen={open}
            backdrop={"static"}
            id="staticBackdrop"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    CONFIRM RESULTS
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setOpen(false)
                        formik.resetForm()
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <Form
                onSubmit={e => {
                    e.preventDefault()
                    formik.handleSubmit()
                    return null
                }}
            >
                <div className="modal-body">

                    <Card outline color="success" className="border">
                    <CardHeader className="bg-transparent">
                        <h5 className="my-0 text-success">
                            <i className="mdi mdi-account-check me-3" />

                            {results?.particulars?.firstName} {" "} {results?.particulars?.middleName} {" "} {results?.particulars?.lastName}
                            {" "} ({results?.particulars?.sex})
                        </h5>
                    </CardHeader>
                    <CardBody>
                        <CardTitle className="mt-0">Results</CardTitle>
                        <CardText>
                            <div className="table-rep-plugin">
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>S/No</Th>
                                                <Th>SUBJECT</Th>
                                                <Th>GRADE</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {results?.subjects.map((sub, index) => (
                                                <Tr key={`id----${index+1}`}>
                                                    <Td>{index + 1}</Td>
                                                    <Td>{sub?.subjectName}</Td>
                                                    <Td>{sub?.grade}</Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                            <strong>Division: </strong> {results?.results?.division} - <strong>Point: </strong> {results?.results?.points}
                            <div className="text-center">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setOpen(false)
                                        formik.resetForm()
                                    }}
                                >
                                    Cancel
                                </button>
                                {" "}
                                <SubmitButtonHelper name="Confirm Result" type="primary" formik={formik} />
                                {/*<SubmitButtonHelper name="Confirm Result" type="primary" formik={formik}/>*/}
                            </div>
                        </CardText>
                    </CardBody>
                    </Card>
                </div>


            </Form>
        </Modal>
    )
}

OALevelResultConfirmation.propTypes = {
    // applicant: PropTypes.any.isRequired,
    results: PropTypes.any.isRequired,
    // open: PropTypes.bool.isRequired,
    formik: PropTypes.any.isRequired
};
export default OALevelResultConfirmation

