import { gql } from "@apollo/client"

const DELETE_CONSULTANCIES = gql`
  mutation RemoveConsultancy($uid: String!) {
    removeConsultancy(uid: $uid) {
      status
      code
      message
      data
    }
  }
`

const ADD_UPDATE_CONSULTANCIES = gql`
  mutation RegisterConsultancies($inputs: [ConsultancyInput!]!) {
    registerConsultancies(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items {
          uid
          title
          location
          client
          description
          startDate
          endDate
          consultancyRegistrationLetter
          consultancyClientLetter
        }
      }
    }
  }
`
export { DELETE_CONSULTANCIES, ADD_UPDATE_CONSULTANCIES }
