import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { CREATE_STAFF_TERM } from "Modules/StaffTerms/Mutations/StaffTermsMutations"
import { GET_INSTRUCTORS } from "../Queries/ResearchSupervisorQueries"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { SupervisorContext } from "./SupervisorList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"

const AssignSupervisorModal = () => {
  const { updateSupervisor, setUpdateSupervisor } =
    useContext(SupervisorContext)
  const [StaffTerm] = useMutation(CREATE_STAFF_TERM, {
    client: uaaGraphQLClient,
  })

  const {
    loading: instructorLoading,
    error: instructorError,
    data: instructorData,
    refetch,
  } = useQuery(GET_INSTRUCTORS, {
    client: uaaGraphQLClient,
    fetchPolicy: "cache-first",
  })

  const instructors = instructorData?.getInstructors?.data

  const [assigningOption, setAssigningOption] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const options = [
    { label: "Register multiple", value: "internal" },
    { label: "Register External Supervisor", value: "external" },
  ]

  const externalSupervisor = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      middlename: "",
      surname: "",
      phone: "",
      email: "",
      pfnumber: "",
      supervisorType: "external",
    },

    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("Supervisor first name is required"),
      middlename: Yup.string().required("Supervisor middle name is required"),
      surname: Yup.string().required("Supervisor surname is required"),
      email: Yup.string().required("Supervisor email is required"),
      phone: Yup.string().required("Supervisor phone is required"),
      pfnumber: Yup.string().required("Supervisor PF number is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values)
      return
      StaffTerm({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateSupervisor?.uid }],
        },
        refetchQueries: [
          {
            query: GET_STAFF_TERMS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerStaffTerms }) => {
          if (registerStaffTerms.code === 8000) {
            resetForm()
            setModalOpen(false)
            setupdateSupervisor(false) // Close the modal
          } else {
            setModalOpen(true)
            setupdateSupervisor(true) // Close the modal
          }
          showToast(
            registerStaffTerms.message,
            registerStaffTerms.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const internalSupervisor = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userUid: [],
      supervisorType: "internal",
    },

    validationSchema: Yup.object().shape({
      userUid: Yup.array().required("Supervisor first name is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(values)
      return
      StaffTerm({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateSupervisor?.uid }],
        },
        refetchQueries: [
          {
            query: GET_STAFF_TERMS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerStaffTerms }) => {
          if (registerStaffTerms.code === 8000) {
            resetForm()
            setModalOpen(false)
            setupdateSupervisor(false) // Close the modal
          } else {
            setModalOpen(true)
            setupdateSupervisor(true) // Close the modal
          }
          showToast(
            registerStaffTerms.message,
            registerStaffTerms.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Add Supervisor
      </Button>
      <Modal
        isOpen={modalOpen || !!updateSupervisor}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateSupervisor
              ? "Edit Research Supervisor "
              : "Research Supervisor Registration"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateSupervisor(false)
              internalSupervisor.resetForm()
              externalSupervisor.resetForm()
              setAssigningOption(null)
            }}
            aria-label="Close"
          ></button>
        </div>

        <Row>
          <Col md={12}>
            <p className="m-3">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Choose Option
                </label>
                <Select
                  id="outOff"
                  name="outOff"
                  onChange={e => {
                    setAssigningOption(e.value)
                    externalSupervisor.resetForm()
                  }}
                  options={options}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
            </p>
          </Col>
        </Row>
        {assigningOption == "internal" ? (
          <Row>
            <Col lg={12}>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  internalSupervisor.handleSubmit()
                  return null
                }}
              >
                <div className="modal-body">
                  <h5 className="text-muted">
                    Internal Supervisor Registration
                  </h5>
                  <div className="mb-3">
                    <Col lg="12">
                      <div className="mb-3">
                        <label>Select atleast 1 instructor</label>
                        <Select
                          name="userUid"
                          onChange={selectedOptions => {
                            const selectedValues = selectedOptions.map(
                              option => ({
                                userUid: option.value,
                              })
                            )
                            internalSupervisor.setFieldValue(
                              "userUid",
                              selectedValues
                            )
                          }}
                          options={instructors?.map(staff => ({
                            value: staff?.user?.uid,
                            label:
                              staff?.user?.firstName +
                              " " +
                              staff?.user?.lastName,
                          }))}
                          isMulti={true} // Enable multiple selections
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                        <Input
                          name="userUid" // Update the field name
                          placeholder="Select Marital Status"
                          type="hidden"
                          rows={2}
                          onChange={internalSupervisor.handleChange}
                          onBlur={internalSupervisor.handleBlur}
                          value={JSON.stringify(
                            internalSupervisor.values.userUid || []
                          )} // Set the value as a JSON string
                        />
                      </div>
                    </Col>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      setModalOpen(false)
                      setUpdateSupervisor(false)
                      internalSupervisor.resetForm()
                      externalSupervisor.resetForm()
                      setAssigningOption(null)
                    }}
                  >
                    Close
                  </button>
                  <SubmitButtonHelper
                    name="Submit"
                    type="primary"
                    formik={internalSupervisor}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        ) : (
          assigningOption == "external" && (
            <Row>
              <Col lg={12}>
                <Form
                  onSubmit={e => {
                    e.preventDefault()
                    externalSupervisor.handleSubmit()
                    return null
                  }}
                >
                  <div className="modal-body">
                    <h5 className="text-muted">
                      External Research Supervisor Registration
                    </h5>
                    <div className="mb-3">
                      <Row>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            First Name
                          </label>
                          <Input
                            type="text"
                            name="firstname"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter First name"
                            onChange={externalSupervisor.handleChange}
                            onBlur={externalSupervisor.handleBlur}
                            value={
                              externalSupervisor.values.firstname ||
                              updateSupervisor?.firstname ||
                              ""
                            }
                            invalid={
                              externalSupervisor.touched.firstname &&
                              externalSupervisor.errors.firstname
                                ? true
                                : false
                            }
                          />
                          {externalSupervisor.touched.firstname &&
                          externalSupervisor.errors.firstname ? (
                            <FormFeedback type="invalid">
                              {externalSupervisor.errors.firstname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Middle Name
                          </label>
                          <Input
                            type="text"
                            name="middlename"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Middle Name"
                            onChange={externalSupervisor.handleChange}
                            onBlur={externalSupervisor.handleBlur}
                            value={
                              externalSupervisor.values.middlename ||
                              updateSupervisor?.middlename ||
                              ""
                            }
                            invalid={
                              externalSupervisor.touched.middlename &&
                              externalSupervisor.errors.middlename
                                ? true
                                : false
                            }
                          />
                          {externalSupervisor.touched.middlename &&
                          externalSupervisor.errors.middlename ? (
                            <FormFeedback type="invalid">
                              {externalSupervisor.errors.middlename}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Surname
                          </label>
                          <Input
                            type="text"
                            name="surname"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Surname"
                            onChange={externalSupervisor.handleChange}
                            onBlur={externalSupervisor.handleBlur}
                            value={
                              externalSupervisor.values.surname ||
                              updateSupervisor?.surname ||
                              ""
                            }
                            invalid={
                              externalSupervisor.touched.surname &&
                              externalSupervisor.errors.surname
                                ? true
                                : false
                            }
                          />
                          {externalSupervisor.touched.surname &&
                          externalSupervisor.errors.surname ? (
                            <FormFeedback type="invalid">
                              {externalSupervisor.errors.surname}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                    <div className="mb-3">
                      <Row>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            PF Number
                          </label>
                          <Input
                            type="text"
                            name="pfnumber"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter PF Number"
                            onChange={externalSupervisor.handleChange}
                            onBlur={externalSupervisor.handleBlur}
                            value={
                              externalSupervisor.values.pfnumber ||
                              updateSupervisor?.pfnumber ||
                              ""
                            }
                            invalid={
                              externalSupervisor.touched.pfnumber &&
                              externalSupervisor.errors.pfnumber
                                ? true
                                : false
                            }
                          />
                          {externalSupervisor.touched.pfnumber &&
                          externalSupervisor.errors.pfnumber ? (
                            <FormFeedback type="invalid">
                              {externalSupervisor.errors.pfnumber}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Phone Numbr
                          </label>
                          <Input
                            type="text"
                            name="phone"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Phone number"
                            onChange={externalSupervisor.handleChange}
                            onBlur={externalSupervisor.handleBlur}
                            value={
                              externalSupervisor.values.phone ||
                              updateSupervisor?.phone ||
                              ""
                            }
                            invalid={
                              externalSupervisor.touched.phone &&
                              externalSupervisor.errors.phone
                                ? true
                                : false
                            }
                          />
                          {externalSupervisor.touched.phone &&
                          externalSupervisor.errors.phone ? (
                            <FormFeedback type="invalid">
                              {externalSupervisor.errors.phone}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col lg={4}>
                          <label htmlFor="name" className="col-form-label">
                            Email
                          </label>
                          <Input
                            type="text"
                            name="email"
                            className="form-control"
                            id="firstname"
                            placeholder="Enter Email"
                            onChange={externalSupervisor.handleChange}
                            onBlur={externalSupervisor.handleBlur}
                            value={
                              externalSupervisor.values.email ||
                              updateSupervisor?.email ||
                              ""
                            }
                            invalid={
                              externalSupervisor.touched.email &&
                              externalSupervisor.errors.email
                                ? true
                                : false
                            }
                          />
                          {externalSupervisor.touched.email &&
                          externalSupervisor.errors.email ? (
                            <FormFeedback type="invalid">
                              {externalSupervisor.errors.email}
                            </FormFeedback>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        setModalOpen(false)
                        setUpdateSupervisor(false)
                        setUpdateSupervisor(false)
                        internalSupervisor.resetForm()
                        externalSupervisor.resetForm()
                        setAssigningOption(null)
                      }}
                    >
                      Close
                    </button>
                    <SubmitButtonHelper
                      name="Submit"
                      type="primary"
                      formik={externalSupervisor}
                    />
                  </div>
                </Form>
              </Col>
            </Row>
          )
        )}
      </Modal>
    </div>
  )
}
export default AssignSupervisorModal
