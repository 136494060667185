import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_DEPARTMENT } from "../Queries/DepartmentQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { DepartmentContext } from "./DepartmentList"
import Select from "react-select"
import { GET_COLLEGES } from "Modules/Colleges/Queries/CollegesQueries"
import { CREATE_DEPARTMENT } from "../Mutations/DepartmentMutations"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const DepartmentModal = () => {
  const { updateDepartmentData, setUpdateDepartmentData } =
    useContext(DepartmentContext)
    console.log("EditD", updateDepartmentData)
  const [department] = useMutation(CREATE_DEPARTMENT,{
    client:uaaGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const { loading, error, data } = useQuery(GET_COLLEGES, {
    client:uaaGraphQLClient,
    skip: !modalOpen,
    variables: {
      pagination: paginationHelper,
    },
  });

  const units = data?.getUnits?.data.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateDepartmentData ? updateDepartmentData.name : "",
      code: updateDepartmentData ? updateDepartmentData.code : "",
      description: updateDepartmentData ? updateDepartmentData.description : "",
      unitUid: updateDepartmentData ? updateDepartmentData.unitUid : "",
    },

    validationSchema: Yup.object().shape({
      unitUid: Yup.string().required("College is required"),
      name: Yup.string().required("Department name is required"),
      code: Yup.string().required("Department short name is required"),
      //   description: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      department({
        client:uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateDepartmentData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_DEPARTMENT,
            client:uaaGraphQLClient,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerDepartments }) => {
          //updateFunction(client.cache, { data: { registerDepartments } });
          if (registerDepartments.code === 8000) {
            validation.resetForm();
            setModalOpen(false)
            setUpdateDepartmentData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerDepartments.message,
            registerDepartments.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Department
      </Button>
      <Modal
        isOpen={modalOpen || !!updateDepartmentData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateDepartmentData ? "Edit Department" : "Add New Department"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateDepartmentData(false)
              resetForm() // Reset the form
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label>College/Unit</label>
              <Select
                onChange={e => {
                  validation.setFieldValue("unitUid", e.value)
                }}
                options={units?.map(unit => ({
                  value: unit.uid,
                  label: unit.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999
                  })
                }}
                defaultValue={updateDepartmentData?.unitUid}
                defaultInputValue={updateDepartmentData?.unit?.name}
              />
              <Input
                name="unitUid"
                placeholder="Select College"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.uid || ""}
                invalid={
                  !!(validation.touched.unitUid && validation.errors.unitUid)
                }
              />
              {validation.touched.unitUid && validation.errors.unitUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.unitUid}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || ""
                }
                invalid={
                  !!(validation.touched.name && validation.errors.name)
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="code"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.code || ""
                }
                invalid={
                  !!(validation.touched.code && validation.errors.code)
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                name="description"
                id="message-text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.description ||
                  ""
                }
                onInvalid={() => {
                  !!(validation.touched.description &&
                  validation.errors.description)
                }}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateDepartmentData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default DepartmentModal
