import { gql } from '@apollo/client';


const CREATE_DEPARTMENT = gql`
  mutation RegisterDepartments($inputs: [DepartmentDtoInput!]!) {
    registerDepartments(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          uid
          name
          code
       }
      }
    }
  }
`


const DELETE_DEPARTMENT = gql`
  mutation removeDepartment($uid: String!) {
    removeDepartment(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_DEPARTMENT, DELETE_DEPARTMENT }