import React, { createContext, useContext, useEffect, useState } from "react"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  Card,
  CardBody,
  Col,
  Row,
  CardSubtitle,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_ALL_PROGRAM_CHANGE } from "..//Queries/HoDQueries"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import ProgramActionHelper from "helpers/ProgramHelper"
import HoDProgramChangeModal from "./HoDProgramChangeModal"

export const HoDProgramChangeContext = createContext();

const breadcrumbItem = [
  {
    title: "Program Change",
    path: "",
    isActive: true,
  },
]



const HoDProgramChange = () => {

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [selectedProgramChange, setSelectedProgramChange ] = useState(null) // for update
  const [updateProgramChangeData, setUpdateProgramChangeData] = useState(null)  // for update
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }   

  const { loading, error, data, refetch } = useQuery(GET_ALL_PROGRAM_CHANGE, {
    client: registrationGraphQLClient,
  })

  const [
    searchProgramChange,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_ALL_PROGRAM_CHANGE, {
    client: registrationGraphQLClient,
  })  

  const getProgramChange = (pChange) => {
    // navigate("/students/view",{state:{student:std}})
    showToast("Mbanga Successfully", "success")
  }  

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchProgramChange({ variables: {  } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }

  const programChange = data?.getAllStudentChangePrograms?.data || searchData?.getAllStudentChangePrograms?.data || []
  const searchedProgramCourse = searchData?.getAllStudentChangePrograms?.data
  const result = searchedProgramCourse ? searchedProgramCourse : programChange
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getAllStudentChangePrograms?.data || searchData?.getAllStudentChangePrograms?.data || 100

  // console.log("user data", programChange)

  return (
    <HoDProgramChangeContext.Provider 
        value={{ 
            updateProgramChangeData, 
            setUpdateProgramChangeData 
          }}>
          <LayoutHelper
            breadcrumbItem={breadcrumbItem}
            pageTitle="Program Change"
          >
          <Row>
            <Col lg={12}>
              <Card>
                <CardSubtitle className="mb-1 mt-4 card-title text-center"><h5>Program Change Applications</h5>                     
                      <Row>
                        <Col className="col-lg-6 col-md-6 col-sm-6">
                          <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                onKeyUp={searchQuery}
                                placeholder="Search..."
                                {...formikSearch.getFieldProps("search")}
                              />
                            </div>
                          </form>
                        </Col>
                        <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                          <HoDProgramChangeModal />
                        </Col>
                      </Row>
                    </CardSubtitle>
                <CardBody>
                  <Table
                    id="tech-companies-1"
                    className="table-sm table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th data-priority="1">S/N</Th>
                        <Th>Registration No</Th>
                        <Th>Name</Th>
                        <Th>Current Programme</Th>
                        <Th>New Programme</Th>
                        <Th>Status</Th>
                        <Th data-priority="3">Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>

                      {result
                          .map((resultRows, index) => (
                            <Tr key={resultRows?.uid}>
                              <Td>{startIndex + index + 1}</Td>
                              <Td>{resultRows?.currentRegistrationNumber}</Td>
                              <Td>{resultRows?.currentRegistrationNumber}</Td>
                              <Td>{resultRows?.currentProgram?.name}</Td>
                              <Td>{resultRows?.newProgram?.name}</Td>
                              <Td>{resultRows?.approveStatus}</Td>
                              <Td>
                                <ProgramActionHelper
                                  data={resultRows}
                                  onApprove={setUpdateProgramChangeData}
                                />
                              </Td>
                            </Tr>
                          ))
                        }                  
                        
                    </Tbody>              
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </LayoutHelper>
    </HoDProgramChangeContext.Provider>
  )
}

export default HoDProgramChange
