import { gql } from "@apollo/client"

const GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT = gql`
  query GetStudentProgramCourseAssessmentResult(
    $studentCourseRegistrationUid: String!
    $questionNo: Int!
  ) {
    getStudentProgramCourseAssessmentResult(
      studentCourseRegistrationUid: $studentCourseRegistrationUid
      questionNo: $questionNo
    ) {
      status
      code
      message
      data {
        answer
        questionNo
      }
    }
  }
`

const GET_STUDENT_COURSELEARNING_OUTCOME = gql`
  query GetStudentCourseLeanOutcomeAssessmentResult(
    $studentCourseRegistrationUid: String!
  ) {
    getStudentCourseLearnOutcomeAssessmentResult(
      studentCourseRegistrationUid: $studentCourseRegistrationUid
    ) {
      status
      code
      message
      data {
        uid
        courseLeanOutcome
        courseLeanOutcomeUid
        hasAnswer
        answer
      }
    }
  }
`

const GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_QUESTION_FIVE_RESULT = gql`
  query GetStudentProgramCourseAssessmentQn5Result(
    $studentCourseRegistrationUid: String!
    $questionNo: Int!
  ) {
    getStudentProgramCourseAssessmentQn5Result(
      studentCourseRegistrationUid: $studentCourseRegistrationUid
      questionNo: $questionNo
    ) {
      status
      code
      message
      data {
        hasAnswer
        uid
        item
        itemId
        type
        answer
      }
    }
  }
`

const GET_TEACHER_CONTINOUS_COURSE_ASSESSMENT_RESULT = gql`
  query GetTeacherContinuousCourseAssessmentResult(
    $studentCourseRegistrationUid: String!
  ) {
    getTeachingAndContinuousCourseAssessment(
      studentCourseRegistrationUid: $studentCourseRegistrationUid
    ) {
      status
      code
      message
      data {
        hasAnswer
        uid
        answer
        assessmentId
        assessment
        type
      }
    }
  }
`

export {
  GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT,
  GET_STUDENT_COURSELEARNING_OUTCOME,
  GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_QUESTION_FIVE_RESULT,
  GET_TEACHER_CONTINOUS_COURSE_ASSESSMENT_RESULT
}
