import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { CREATE_PROGRAM_COURSE } from "Modules/ProgramCourses/Mutations/ProgramCoursesMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_PROGRAM_COURSES } from "Modules/ProgramCourses/Queries/ProgramCoursesQueries"
import { GET_PROGRAM_COURSE_BY_SEMESTER } from "Modules/ProgramSemister/Queries/ProgramCourseQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import Select from "react-select"
import { ProgramCoursesContext } from "Modules/ProgramCourses/Views/ProgramCoursesList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_PROGRAMS } from "Modules/Programs/Queries/ProgramQuaries"
import { GET_COURSES } from "Modules/Courses/Queries/CourseQueries"
import { GET_COURSE_CATEGORIES } from "Modules/CourseCategories/Queries/CourseCategoriesQueries"
import {
  GET_PROGRAM_SEMISTERS,
  GET_STAFFS,
} from "Modules/ProgramSemister/Queries/ProgramSemisterQueries"
import { ProgramCourseDetailContext } from "./ProgramCourseDetails"
import { ProgramCourseDetails } from "./ProgramCourseDetails"
import { COURSE_ALLOCATION } from "../Mutations/ProgramSemisterMutations"
import { GET_COURSE_ALLOCATION } from "../Queries/CourseAllocationQueries"

const ProgramCoursesModal = () => {
  const {
    programSemester,
    updateProgramCourseData,
    courseAllocate,
    setCourseAllocate,
    setUpdateProgramCourseData,
  } = useContext(ProgramCourseDetailContext)

  const [ProgramCourse] = useMutation(CREATE_PROGRAM_COURSE, {
    client: registrationGraphQLClient,
  })

  const [courseAllocation] = useMutation(COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
  })

  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const [semesters, setSemesters] = useState([]),
    [studyYear, setStudyYear] = useState([])

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])

  const fetchData = () => {
    setStudyYear([
      { label: "Year 1", value: 1 },
      { label: "Year 2", value: 2 },
      { label: "Year 3", value: 3 },
      { label: "Year 4", value: 4 },
      { label: "Year 5", value: 5 },
    ])
  }
  const { loadingacyear, erroracyear, data } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })


  const {
    loading: programSemisterLoading,
    error: programSemisterError,
    data: programSemisterData,
  } = useQuery(GET_PROGRAM_SEMISTERS, {
    client: registrationGraphQLClient,
    variables: {
      programUid: programSemester?.program?.uid,
      pagination: paginationHelper,
    },
  })

  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
  } = useQuery(GET_STAFFS, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const staffs = staffData?.getStaffs?.data?.items

  let programSemisterArray =
    programSemisterData?.getProgramSemesters?.data?.items

  const {
    loading: courseLoading,
    error: courseError,
    data: courseData,
  } = useQuery(GET_COURSES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  let coursesArray = courseData?.getCourses?.data?.items

  const {
    loading: courseCategoryLoading,
    error: courseCategoryError,
    data: courseCategoryData,
  } = useQuery(GET_COURSE_CATEGORIES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  let courseCategoryArray = courseCategoryData?.getCourseCategories?.data?.items

  if (erroracyear) return showToast("Failed to fetch Academic Years", "error")
  const academicyears = data?.getAcademicYears?.data.items

  const query = GET_PROGRAM_COURSE_BY_SEMESTER
  const variables = { programSemisterUid: programSemester.uid }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      assignmentHours: updateProgramCourseData
        ? updateProgramCourseData.assignmentHours
        : "",
      independentStudyHours: updateProgramCourseData
        ? updateProgramCourseData.independentStudyHours
        : "",
      practicalHours: updateProgramCourseData
        ? updateProgramCourseData.practicalHours
        : "",
      lectureHours: updateProgramCourseData
        ? updateProgramCourseData.lectureHours
        : "",
      seminarHours: updateProgramCourseData
        ? updateProgramCourseData.seminarHours
        : "",
      credit: updateProgramCourseData ? updateProgramCourseData.credit : "",
      courseCategoryUid: updateProgramCourseData
        ? updateProgramCourseData.courseCategoryUid
        : "",
      courseUid: updateProgramCourseData
        ? updateProgramCourseData.courseUid
        : "",
    },

    validationSchema: Yup.object().shape({
      courseUid: Yup.string().required("Course offeres is required"),
      courseCategoryUid: Yup.string().required("Course Category is required"),
      credit: Yup.string().required("Course Credits/Units is required"),
      lectureHours: Yup.string().required("Lecture Hours is required"),
      seminarHours: Yup.string().required("seminarHours Hours is required"),
      practicalHours: Yup.string().required("practicalHours Hours is required"),
      independentStudyHours: Yup.string().required(
        "independentStudyHours Hours is required"
      ),
      assignmentHours: Yup.string().required(
        "assignmentHours Hours is required"
      ),
    }),

    onSubmit: values => {
      ProgramCourse({
        variables: {
          inputs: [
            {
              ...values,
              uid: updateProgramCourseData?.uid,
              programSemesterUid: programSemester.uid,
            },
          ],
        },
        client: registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_PROGRAM_COURSE_BY_SEMESTER,
            variables: {
              programSemisterUid: programSemester.uid,
            },
          },
        ],
        onCompleted: ({ registerProgramCourse }) => {
          if (registerProgramCourse.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateProgramCourseData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateProgramCourseData(true) // Close the modal
          }
          showToast(
            registerProgramCourse.message,
            registerProgramCourse.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const validationStaffAllocation = useFormik({
    enableReinitialize: true,

    initialValues: {
      programCourseUid: courseAllocate ? courseAllocate.uid : "",
      // staffUid: "",
      staffUid: [],
    },

    validationSchema: Yup.object().shape({
      // staffUid: Yup.string().nullable().required("PLease select Instructor")
      staffUid: Yup.array()
        .min(1, "Please select at least one Instructor")
        .required("Please select at least one Instructor"),
    }),

    onSubmit: values => {
      const transformedData = values.staffUid.map(staffUid => ({
        programCourseUid: values.programCourseUid,
        staffUid,
      }))

      courseAllocation({
        variables: {
          inputs: transformedData,
        },
        client: registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_COURSE_ALLOCATION,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerCourseAllocations }) => {
          if (registerCourseAllocations.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setCourseAllocate(false) // Close the modal
          } else {
            setModalOpen(true)
            setCourseAllocate(true) // keep the modal
          }
          showToast(
            registerCourseAllocations.message,
            registerCourseAllocations.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          validationStaffAllocation.resetForm()
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Add New Course
      </Button>
      <Modal
        size="lg"
        isOpen={modalOpen || !!updateProgramCourseData || !!courseAllocate}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramCourseData
              ? "Edit Course"
              : courseAllocate
              ? "Staff Course Allocation"
              : "Add New Course"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        {updateProgramCourseData ? (
          <>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return null
              }}
            >
              <div className="modal-body">
                <div>
                  <Row>
                    <Col md="6" lg="6">
                      <div className="mb-3">
                        <label>Course</label>
                        <Select
                          name="courseUid"
                          id="courseUid"
                          onChange={e => {
                            validation.setFieldValue("courseUid", e.value)
                          }}
                          options={coursesArray?.map(pr => ({
                            value: pr.uid,
                            label: pr.name + " (" + pr.code + ")",
                          }))}
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Practical Hours</label>
                        <Input
                          type="number"
                          name="practicalHours"
                          className="form-control"
                          id="practicalHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.practicalHours || ""}
                          invalid={
                            !!(
                              validation.touched.practicalHours &&
                              validation.errors.practicalHours
                            )
                          }
                        />
                        {validation.touched.practicalHours &&
                        validation.errors.practicalHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.practicalHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Assignment Hours</label>
                        <Input
                          type="number"
                          name="assignmentHours"
                          className="form-control"
                          id="assignmentHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.assignmentHours || ""}
                          invalid={
                            !!(
                              validation.touched.assignmentHours &&
                              validation.errors.assignmentHours
                            )
                          }
                        />
                        {validation.touched.assignmentHours &&
                        validation.errors.assignmentHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.assignmentHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" lg="6">
                      <div className="mb-3">
                        <label>Course Category</label>
                        <Select
                          id="courseCategoryUid"
                          name="courseCategoryUid"
                          onChange={e => {
                            validation.setFieldValue(
                              "courseCategoryUid",
                              e.value
                            )
                          }}
                          options={courseCategoryArray?.map(dp => ({
                            value: dp.uid,
                            label: dp.name,
                          }))}
                          defaultValue={
                            updateProgramCourseData?.courseCategoryUid
                          }
                          defaultInputValue={
                            updateProgramCourseData?.courseCategory?.name
                          }
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Seminar Hours</label>
                        <Input
                          type="number"
                          name="seminarHours"
                          className="form-control"
                          id="seminarHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seminarHours || ""}
                          invalid={
                            !!(
                              validation.touched.seminarHours &&
                              validation.errors.seminarHours
                            )
                          }
                        />
                        {validation.touched.seminarHours &&
                        validation.errors.seminarHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.seminarHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label htmlFor="code" className="col-form-label">
                          Independent Hourss
                        </label>
                        <Input
                          type="number"
                          name="independentStudyHours"
                          className="form-control"
                          id="independentStudyHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.independentStudyHours || ""}
                          invalid={
                            !!(
                              validation.touched.independentStudyHours &&
                              validation.errors.independentStudyHours
                            )
                          }
                        />
                        {validation.touched.independentStudyHours &&
                        validation.errors.independentStudyHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.independentStudyHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" lg="6">
                      <div className="mb-3">
                        <label>Credits</label>
                        <Input
                          type="number"
                          name="credit"
                          className="form-control"
                          id="credit"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.credit || ""}
                          invalid={
                            !!(
                              validation.touched.credit &&
                              validation.errors.credit
                            )
                          }
                        />
                        {validation.touched.credit &&
                        validation.errors.credit ? (
                          <FormFeedback type="invalid">
                            {validation.errors.credit}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Lecture Hours</label>
                        <Input
                          type="number"
                          name="lectureHours"
                          className="form-control"
                          id="lectureHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lectureHours || ""}
                          invalid={
                            !!(
                              validation.touched.lectureHours &&
                              validation.errors.lectureHours
                            )
                          }
                        />
                        {validation.touched.lectureHours &&
                        validation.errors.lectureHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lectureHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="3" lg="3"></Col>
                  </Row>

                  <Row>
                    <Col md="6" lg="6"></Col>
                  </Row>
                  <Row>
                    <Col md="6" lg="6"></Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    setModalOpen(false)
                    setUpdateProgramCourseData(false)
                    resetForm() // Reset the form
                  }}
                >
                  Close
                </button>
                <SubmitButtonHelper
                  name="Submit"
                  type="primary"
                  formik={validation}
                />
              </div>
            </Form>
          </>
        ) : courseAllocate ? (
          <>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validationStaffAllocation.handleSubmit()
                return null
              }}
            >
              <div className="modal-body">
                <div>
                  <Row>
                    <Col lg={12}>
                      <Alert color="info">
                        <p>Allocate staff</p>
                        <p>Course: {courseAllocate?.course?.code}</p>
                      </Alert>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" lg="12">
                      {/* <div className="mb-3">
                        <label>Instructor name</label>
                        <Select
                          name="staffUid"
                          id="staffUid"
                          onChange={e => {
                            validationStaffAllocation.setFieldValue("staffUid", e.value)
                          }}
                          options={staffs?.map(staff => ({
                            value: staff.uid,
                            label: staff?.user?.firstName + " " + staff?.user?.lastName
                          }))}
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div> */}
                      <div className="mb-3">
                        <label>Instructor name</label>
                        <Select
                          name="staffUid"
                          onChange={selectedOptions => {
                            const selectedValues = selectedOptions.map(
                              option => option.value
                            )
                            validationStaffAllocation.setFieldValue(
                              "staffUid",
                              selectedValues
                            )
                          }}
                          options={staffs?.map(staff => ({
                            value: staff.uid,
                            label:
                              staff?.user?.firstName +
                              " " +
                              staff?.user?.lastName,
                          }))}
                          isMulti={true} // Enable multiple selections
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                        <Input
                          name="staffUid" // Update the field name
                          placeholder="Select Marital Status"
                          type="hidden"
                          rows={2}
                          onChange={validationStaffAllocation.handleChange}
                          onBlur={validationStaffAllocation.handleBlur}
                          value={JSON.stringify(
                            validationStaffAllocation.values.staffUid || []
                          )} // Set the value as a JSON string
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    setModalOpen(false)
                    setCourseAllocate(false)
                    resetForm() // Reset the form
                  }}
                >
                  Close
                </button>
                <SubmitButtonHelper
                  name="Submit"
                  type="primary"
                  formik={validationStaffAllocation}
                />
              </div>
            </Form>
          </>
        ) : (
          <>
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return null
              }}
            >
              <div className="modal-body">
                <div>
                  <Row>
                    <Col md="6" lg="6">
                      <div className="mb-3">
                        <label>Course</label>
                        <Select
                          name="courseUid"
                          id="courseUid"
                          onChange={e => {
                            validation.setFieldValue("courseUid", e.value)
                          }}
                          options={coursesArray?.map(pr => ({
                            value: pr.uid,
                            label: pr.name + " (" + pr.code + ")",
                          }))}
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Practical Hours</label>
                        <Input
                          type="number"
                          name="practicalHours"
                          className="form-control"
                          id="practicalHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.practicalHours || ""}
                          invalid={
                            !!(
                              validation.touched.practicalHours &&
                              validation.errors.practicalHours
                            )
                          }
                        />
                        {validation.touched.practicalHours &&
                        validation.errors.practicalHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.practicalHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Assignment Hours</label>
                        <Input
                          type="number"
                          name="assignmentHours"
                          className="form-control"
                          id="assignmentHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.assignmentHours || ""}
                          invalid={
                            !!(
                              validation.touched.assignmentHours &&
                              validation.errors.assignmentHours
                            )
                          }
                        />
                        {validation.touched.assignmentHours &&
                        validation.errors.assignmentHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.assignmentHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" lg="6">
                      <div className="mb-3">
                        <label>Course Category</label>
                        <Select
                          id="courseCategoryUid"
                          name="courseCategoryUid"
                          onChange={e => {
                            validation.setFieldValue(
                              "courseCategoryUid",
                              e.value
                            )
                          }}
                          options={courseCategoryArray?.map(dp => ({
                            value: dp.uid,
                            label: dp.name,
                          }))}
                          defaultValue={
                            updateProgramCourseData?.courseCategoryUid
                          }
                          defaultInputValue={
                            updateProgramCourseData?.courseCategory?.name
                          }
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Seminar Hours</label>
                        <Input
                          type="number"
                          name="seminarHours"
                          className="form-control"
                          id="seminarHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.seminarHours || ""}
                          invalid={
                            !!(
                              validation.touched.seminarHours &&
                              validation.errors.seminarHours
                            )
                          }
                        />
                        {validation.touched.seminarHours &&
                        validation.errors.seminarHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.seminarHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label htmlFor="code" className="col-form-label">
                          Independent Hours
                        </label>
                        <Input
                          type="number"
                          name="independentStudyHours"
                          className="form-control"
                          id="independentStudyHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.independentStudyHours || ""}
                          invalid={
                            !!(
                              validation.touched.independentStudyHours &&
                              validation.errors.independentStudyHours
                            )
                          }
                        />
                        {validation.touched.independentStudyHours &&
                        validation.errors.independentStudyHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.independentStudyHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" lg="6">
                      <div className="mb-3">
                        <label>Credits</label>
                        <Input
                          type="number"
                          name="credit"
                          className="form-control"
                          id="credit"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.credit || ""}
                          invalid={
                            !!(
                              validation.touched.credit &&
                              validation.errors.credit
                            )
                          }
                        />
                        {validation.touched.credit &&
                        validation.errors.credit ? (
                          <FormFeedback type="invalid">
                            {validation.errors.credit}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="3" lg="3">
                      <div className="mb-3">
                        <label>Lecture Hours</label>
                        <Input
                          type="number"
                          name="lectureHours"
                          className="form-control"
                          id="lectureHours"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lectureHours || ""}
                          invalid={
                            !!(
                              validation.touched.lectureHours &&
                              validation.errors.lectureHours
                            )
                          }
                        />
                        {validation.touched.lectureHours &&
                        validation.errors.lectureHours ? (
                          <FormFeedback type="invalid">
                            {validation.errors.lectureHours}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="3" lg="3"></Col>
                  </Row>

                  <Row>
                    <Col md="6" lg="6"></Col>
                  </Row>
                  <Row>
                    <Col md="6" lg="6"></Col>
                  </Row>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    setModalOpen(false)
                    setUpdateProgramCourseData(false)
                    validation.resetForm() // Reset the form
                  }}
                >
                  Close
                </button>
                <SubmitButtonHelper
                  name="Submit"
                  type="primary"
                  formik={validation}
                />
              </div>
            </Form>
          </>
        )}
      </Modal>
    </div>
  )
}
export default ProgramCoursesModal
