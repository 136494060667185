import React from "react"
import { Navigate } from "react-router-dom"
import UserProfile from "../Modules/Authentication/user-profile"
import Logout from "../Modules/Authentication/Logout"
import Register from "../Modules/Authentication/Register"
import ForgetPwd from "../Modules/Authentication/ForgetPassword"
import PagesMaintenance from "../Modules/Utility/pages-maintenance"
import PagesComingsoon from "../Modules/Utility/pages-comingsoon"
import Pages404 from "../Modules/Utility/pages-404"
import Pages500 from "../Modules/Utility/pages-500"
import StudentRoutes from "./Systems/StudentRoutes"
import ProgramCoursesRoutes from "../Modules/ProgramCourses/Routes/ProgramCoursesRoutes"
import ProgramCourseAssessmentRoutes from "../Modules/ProgramCourseAssessments/Routes/ProgramCourseAssessmentRoutes"
import VerifyToken from "Modules/Authentication/VerifyToken"
import ReportRoutes from "../Modules/Reports/ReportRoutes"
import VerifyOtp from "Modules/Authentication/VerifyOtp"
import AccommodationRoutes from "Modules/Accommodation/Routes/AccommodationRoutes"
import DissertationRoutes from "../Modules/Dissertation/Routes/DissertationRoutes"
import ManuScriptRoutes from "../Modules/ManuScript/Routes/ManuScriptRoutes"
import IntentionToSubmitRoutes from "../Modules/IntentionToSubmit/Routes/IntentionToSubmitRoutes"
import ForgetEmailPage from "../Modules/Authentication/ForgetEmail"
import ControlNumberPortal from "../Modules/Authentication/ControlNumberPortal"
import TeachingAssessmentRoutes from "../Modules/TeachingAssessment/Routes/TeachingAssessmentRoutes"
import OnlineApplicationRoutes from "Modules/OnlineApplication/AccountRegistration/Routes/OnlineApplicationRoutes"

// asism
import AsimsRoutes from "Modules/ASIMS/routes/asimsRoutes"
import EsbDashboard from "../Modules/esb-dashboard/Views/EsbDashboard"
import EsbLogin from "../Modules/Authentication/EsbLogin"
import AdmissionRoutes from "./Systems/AdmissionRoutes";
import FinanceRoutes from "./Systems/FinanceRoutes";
import SelfRegistrationRoutes from "./Systems/SelfRegistrationRoutes";
import ApplicantRoutes from "./Systems/ApplicantRoutes";
import ExaminationRoutes from "./Systems/ExaminationRoutes";
import UserManagementRoutes from "./Systems/UserManagementRoutes";
import SystemSettingRoutes from "./Systems/SettingRoutes";

const authProtectedRoutes = [
  //   Resolved Routes
  { path: "/student/*", component: <StudentRoutes /> },
  { path: "/admission/*", component: <AdmissionRoutes /> },
  { path: "/finance/*", component: <FinanceRoutes /> },
  { path: "/teaching-assessment/*", component: <TeachingAssessmentRoutes /> },
  { path: "/self-registration/*", component: <SelfRegistrationRoutes /> },
  { path: "/applicant/*", component: <ApplicantRoutes /> },
  { path: "/examination/*", component: <ExaminationRoutes /> },
  { path: "/accommodation/*", component: <AccommodationRoutes /> },
  { path: "/user-management/*", component: <UserManagementRoutes /> },
  { path: "/system-settings/*", component: <SystemSettingRoutes /> },
  { path: "/reports/*", component: <ReportRoutes /> },
  // { path: "/Students/*", component: <StudentRoutes /> },



  // { path: "/dashboard", component: <Dashboard /> },
  { path: "/esb-dashboard", component: <EsbDashboard /> },
  { path: "/Profile", component: <UserProfile /> },
  { path: "*", component: <Pages404 /> },
  //Program Semister
  { path: "/program-courses/*", component: <ProgramCoursesRoutes /> },
  {
    path: "/program-course-assessments/*",
    component: <ProgramCourseAssessmentRoutes />,
  },



  { path: "/dissertation/*", component: <DissertationRoutes /> },
  { path: "/manu-script/*", component: <ManuScriptRoutes /> },
  { path: "/intention-to-submit/*", component: <IntentionToSubmitRoutes /> },

  { path: "/asims/*", component: <AsimsRoutes /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/esb-dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  // { path: "/login", component: <Login /> },
  { path: "/login", component: <EsbLogin /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/control-number-portal", component: <ControlNumberPortal /> },
  { path: "/forgot-email", component: <ForgetEmailPage /> },
  { path: "/register", component: <Register /> },
  { path: "/verification/password/reset/:token", component: <VerifyToken /> },
  { path: "/verification/password/otp/:userUid", component: <VerifyOtp /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  {
    path: "online/*",
    component: <OnlineApplicationRoutes />,
  },
]

export { authProtectedRoutes, publicRoutes }
