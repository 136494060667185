import { gql } from "@apollo/client"

const CREATE_PROGRAM_SEMISTER = gql`
  mutation RegisterProgramSemester($inputs: [ProgramSemesterInput!]!) {
    registerProgramSemester(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          uid
          studyYear
          coreCredits
          electiveCredits
          program {
            name
          }
          academicYear {
            name
            startDate
            endDate
          }
          semester
        }
        totalCount
      }
    }
  }
`


const ASSIGN_COURSE = gql`
  mutation RegisterProgramSemester($inputs: [ProgramSemesterInput!]!) {
    registerProgramSemester(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          uid
          studyYear
          coreCredits
          electiveCredits
          program {
            name
          }
          academicYear {
            name
            startDate
            endDate
          }
          semester
        }
        totalCount
      }
    }
  }
`

const DELETE_PROGRAM_SEMISTER = gql`
  mutation RemoveProgramSemester($uid: String!) {
    removeProgramSemester(uid: $uid) {
      message
      code
      status
    }
  }
`;

const COURSE_ALLOCATION = gql `
mutation RegisterCourseAllocation($inputs: [CourseAllocationInput!]!) {
  registerCourseAllocations(inputs: $inputs) {
    message
    code
    status
  }
}
`

export { CREATE_PROGRAM_SEMISTER, DELETE_PROGRAM_SEMISTER,ASSIGN_COURSE, COURSE_ALLOCATION }
