import React, { useContext, useEffect, useRef, useState } from "react"
import {Modal, Form, Input, FormFeedback, Button, Label, InputGroup} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"

import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PropTypes from "prop-types";
import {GET_STUDENT_SEMINAR_TYPE} from "../../../Students/Views/Dissertation/Queries/StudentDissertationQueries";
import Flatpickr from "react-flatpickr";
import {
    CREATE_STUDENT_SEMINAR,
    UPDATE_STUDENT_SEMINAR_SCORE
} from "../../../Lookups/SeminarType/Mutations/SeminarTypeMutations";
import Select from "react-select";


const StudentSeminarForm = (props) => {
    const {seminar} = props
    // console.log(seminar.studentUid)
    const [create] = useMutation(CREATE_STUDENT_SEMINAR)
    const [updateScore] = useMutation(UPDATE_STUDENT_SEMINAR_SCORE)
    const [modalOpen, setModalOpen] = useState(false)
    let type = ''
    if(seminar?.status === 2) type = 'Seminar Score'
    if(seminar?.status === 1) type = 'Seminar Date'
    // console.log(seminar)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            title: seminar.title,
            description: seminar.description,
            status:seminar?.status,
            uid:seminar.uid,
            // isPass:seminar?.isPass,
            seminarDate:seminar?.seminarDate,
            seminarMarks:seminar?.seminarMarks,
            seminarTypesUid:seminar?.seminarTypes?.uid,
            studentUid:seminar?.studentUid
        },
        validationSchema: Yup.object().shape({
            seminarMarks: Yup.number().required(`${seminar?.seminarTypes?.name} Score is required`).min(0, `${seminar?.seminarTypes?.name} Score should not be les than 0`)
                .max(100, `${seminar?.seminarTypes?.name} Score should not exceed 100`),
            // isPass: Yup.bool().required(`${seminar?.seminarTypes?.name} Is passed is required`),
            seminarDate: Yup.string().required(`${seminar?.seminarTypes?.name} ${type} is required`)
        }),
        onSubmit: (values, {setSubmitting,resetForm}) => {
            if(values.status !== 2) {
                values = {
                    title: seminar.title,
                    description: seminar.description,
                    status: seminar?.status,
                    uid: seminar.uid,
                    // isPass:seminar?.isPass,
                    seminarDate: values.seminarDate,
                    // seminarMarks:seminar?.seminarMarks,
                    seminarTypesUid: seminar?.seminarTypes?.uid,
                    studentUid: seminar?.studentUid
                }
                create({
                    client:registrationGraphQLClient,
                    variables: {
                        inputs: { ...values, uid: seminar?.uid },
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_SEMINAR_TYPE,
                            variables: {
                                inputs:{
                                    studentUid:seminar?.studentUid,
                                    seminarTypeUid:seminar?.seminarTypes?.uid
                                },
                            },
                        },
                    ],
                    skip: !modalOpen,
                    onCompleted: ({ registerStudentSeminar }) => {
                        if (registerStudentSeminar.code === 8000) {
                            setModalOpen(false)
                            validation.resetForm()
                        } else {
                            setModalOpen(true)
                            setSubmitting(false)
                        }
                        // console.log('sfasfasfsa')
                        // showToast(
                        //     registerStudentSeminar.message,
                        //     registerStudentSeminar.code === 8000 ? "success" : "error"
                        // )
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)

                        setSubmitting(false)
                    },
                })
            }
            else {
                values = {
                    seminarMarks: values.seminarMarks,
                }
                updateScore({
                    client:registrationGraphQLClient,
                    variables: {
                        input: { ...values, uid: seminar?.uid },
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_SEMINAR_TYPE,
                            variables: {
                                inputs:{
                                    studentUid:seminar?.studentUid,
                                    seminarTypeUid:seminar?.seminarTypes?.uid
                                },
                            },
                        },
                    ],
                    skip: !modalOpen,
                    onCompleted: ({ updateSeminarMarks }) => {
                        // console.log(updateSeminarMarks.code)
                        if (updateSeminarMarks.code === 8000) {
                            setModalOpen(false)
                            validation.resetForm()
                            setModalOpen(false)
                        } else {
                            setModalOpen(true)
                            setSubmitting(false)
                        }
                        // console.log('sfasfasfsa')
                        // showToast(
                        //     registerStudentSeminar.message,
                        //     registerStudentSeminar.code === 8000 ? "success" : "error"
                        // )
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)

                        setSubmitting(false)
                    },
                })
            }
        },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-update me-1" />
                Update  {seminar?.seminarTypes?.name}  {type}
            </Button>
            <Modal
                isOpen={modalOpen}
                backdrop={"static"}
                size={'md'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Update {seminar?.seminarTypes?.name}  {type}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">

                        {
                            seminar?.status === 1 &&
                            <div className="mb-3">
                                <Label>Seminar Date</Label>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        // name="seminarDate"
                                        value={validation.values.seminarDate}
                                        placeholder="dd M,yyyy"
                                        options={{
                                            altInput: true,
                                            minDate: 'today',
                                            altFormat: "F j, Y",
                                        }}
                                        onChange={date => validation.setFieldValue('seminarDate', date[0])}

                                    />
                                </InputGroup>
                                <Input
                                    name="seminarDate"
                                    placeholder="Select"
                                    type="hidden"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.seminarDate}
                                    invalid={
                                        !!(validation.touched.seminarDate && validation.errors.seminarDate)
                                    }
                                />
                                {validation.touched.seminarDate && validation.errors.seminarDate ? (
                                    <FormFeedback type="invalid">{validation.errors.seminarDate}</FormFeedback>
                                ) : null}
                            </div>
                        }

                        {
                            seminar?.status === 2 &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="seminarMarks" className="col-form-label">
                                        Seminar Score
                                    </label>
                                    <Input
                                        type="number"
                                        min={0}
                                        step={0.01}
                                        name="seminarMarks"
                                        className="form-control"
                                        id="seminarMarks"
                                        placeholder={`Enter Score Marks`}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                            validation.values.seminarMarks
                                        }
                                        invalid={
                                            validation.touched.seminarMarks && validation.errors.seminarMarks
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.seminarMarks && validation.errors.seminarMarks ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.seminarMarks}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </>
                        }


                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                validation.resetForm() // Reset the form
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
StudentSeminarForm.propTypes = {
    seminar : PropTypes.any.isRequired
}
export default StudentSeminarForm
