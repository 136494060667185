import React, { useContext, useEffect, useState } from "react"
import { Form, Input, FormFeedback, Row, Col } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { InstructorCourseDetailsContext } from "./InstructorCourseDetail"
import { CourseWeights, courseMarkedOut } from "./CourseWeight"
import { UPLOAD_EXAM_SCORE_RESULT_ONLINE } from "../Mutations/InstructorMutations"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useNavigate } from "react-router-dom"
import { GET_STUDENT_COURSE_ALLOCATIONS } from "../Queries/InstructorCoursesQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"

const UploadResultOnline = ({ closeOnlineUpload }) => {
  const { uid, assessments, courseData } = useContext(
    InstructorCourseDetailsContext
  )

  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(limit)
  const [pageSize, setPageSize] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const startIndex = currentPage * pageSize

  const [selectedExamCategory, setSelectedExamCategory] = useState(null)

  const [uploadOnlineMutation] = useMutation(UPLOAD_EXAM_SCORE_RESULT_ONLINE, {
    client: registrationGraphQLClient,
  })
  const [defaultMark, setDefaultMark] = useState(10)
  const courseWeight = CourseWeights() // course weight

  const examCategory = assessments?.map(assessment => ({
    label: assessment?.examCategory?.name,
    value: assessment?.examCategory?.id,
  }))

  const course = courseData?.getCourseAllocation?.data?.programCourse

  const markedOut = courseMarkedOut() // Marked out

  const [examCategorySelected, setExamCategorySelected] = useState(null)

  const [selectedOutOf, setSelectedOutOff] = useState(null)

  const [selectedAssessmentNumber, setSelectedAssessmentNumber] = useState(null)

  const {
    loading,
    error,
    data: studentData,
    refetch: studentRefeching,
  } = useQuery(GET_STUDENT_COURSE_ALLOCATIONS, {
    client: registrationGraphQLClient,

    skip: !selectedOutOf,

    variables: {
      allocationUid: uid ? uid : "",
      assessmentNumber: selectedAssessmentNumber?.value,
      examCategoryId: examCategorySelected?.value,
      outOff: selectedOutOf?.value,
    },

    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (
      selectedAssessmentNumber?.value &&
      examCategorySelected?.value &&
      selectedOutOf?.value
    ) {
      const fetchData = async () => {
        try {
          // Use the refetch function
          await studentRefeching()
        } catch (error) {
          console.error("Error fetching data:", error)
        }
      }
      fetchData()
    }
  }, [
    selectedAssessmentNumber?.value,
    examCategorySelected?.value,
    selectedOutOf?.value,
    studentRefeching,
  ])

  const students = studentData?.getAllocationStudents?.data

  console.log("Student records", students)

  console.log(
    "assess number : ",
    selectedAssessmentNumber?.value + " " + examCategorySelected?.value
  )

  const uploadResultByOnline = useFormik({
    initialValues: {
      marks: {},
      outOff: "",
      source: "Online",
      assessmentWeight: "1", // Change this to a string
      allocationUid: "",
      examCategory: "",
      assessmentNumber: "",
    },

    validationSchema: Yup.object().shape({
      marks: Yup.object().test(
        "marks",
        "Marks are required for all students",
        value => {
          console.log("ValuesEntered", value)
          return Object.keys(value)?.length === students?.length
        }
      ),
      examCategory: Yup.string().required("Exam category Must be choosen"),
      assessmentNumber: Yup.string().required(
        "Exam assessment number Must be choosen"
      ),
    }),

    onSubmit: (values, { setSubmitting }) => {
      const formattedMarks = Object.entries(values.marks)?.map(
        ([registrationNumber, score]) => ({
          registrationNumber,
          score,
        })
      )

      // console.log("formated: ", formattedMarks)

      const postingData = {
        weight: parseInt(values.assessmentWeight),
        outOff: values.outOff,
        examCategoryId: values.examCategory,
        assessmentNumber: values.assessmentNumber,
        programCourseId: course?.id,
        marks: formattedMarks,
        source: values.source,
      }

      uploadOnlineMutation({
        variables: {
          inputs: { ...postingData },
        },
        onCompleted: ({ uploadOnlineScore }) => {
          //updateFunction(client.cache, { data: { registerGroups } });
          if (uploadOnlineScore.code === 8000) {
            uploadResultByOnline.resetForm()
            closeOnlineUpload() // close Modal
            navigate("/examination/instructor/view_submitted_result", {
              state: { uploadScore: uploadOnlineScore },
            })
          } else {
            setSubmitting(false)
            uploadResultByOnline.resetForm()
          }
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
          setSubmitting(false)
        },
      })
    },
  })

  useEffect(() => {
    const tempMarks = {}

    students?.forEach(student => {
      if (student?.score !== undefined) {
        const registrationNumber = student?.registrationNumber
        const score = parseFloat(student?.score)
        tempMarks[registrationNumber] = score
      }
    })

    // Set the marks in one go
    uploadResultByOnline.setValues({
      ...uploadResultByOnline.values,
      marks: tempMarks,
    })
  }, [students])

  console.log(
    "Assesments",
    courseData?.getCourseAllocation?.data?.programCourse?.uid
  )

  const handleMarksChange = (regNo, value) => {
    uploadResultByOnline.setFieldValue(`marks.${regNo}`, value)
  }
  

  return (
    <Row>
      <Col md={12}>
        <div className="m-3">
          <h4 className="text-primary">Online Result Uploading!</h4>
          <Form
            onSubmit={e => {
              e.preventDefault()
              uploadResultByOnline.handleSubmit()
              return null
            }}
          >
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Exam Category
                  </label>
                  <Select
                    id="outOff"
                    name="examCategory"
                    onChange={e => {
                      uploadResultByOnline.setFieldValue(
                        "examCategory",
                        e.value
                      )
                      setSelectedAssessmentNumber(null)
                      setSelectedOutOff(null)
                      setSelectedExamCategory(e.value)
                      setExamCategorySelected(e)
                    }}
                    options={examCategory.flat()}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    type="hidden"
                    name="examCategory"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter Out of Mark"
                    onChange={uploadResultByOnline.handleChange}
                    onBlur={uploadResultByOnline.handleBlur}
                    value={uploadResultByOnline.values.examCategory || ""}
                    invalid={
                      uploadResultByOnline.touched.examCategory &&
                      uploadResultByOnline.errors.examCategory
                        ? true
                        : false
                    }
                  />
                  {uploadResultByOnline.touched.examCategory &&
                  uploadResultByOnline.errors.examCategory ? (
                    <FormFeedback type="invalid">
                      {uploadResultByOnline.errors.examCategory}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Select Assessment Number
                  </label>
                  <Select
                    id="outOff"
                    name="outOff"
                    isDisabled={!selectedExamCategory}
                    value={selectedAssessmentNumber}
                    onChange={e => {
                      uploadResultByOnline.setFieldValue(
                        "assessmentNumber",
                        e.value
                      )
                      setSelectedAssessmentNumber(e)
                      setSelectedOutOff(null)
                    }}
                    options={assessments
                      .filter(
                        assessment =>
                          selectedExamCategory === assessment?.examCategory?.id
                      )
                      .flatMap(assessment =>
                        Array.from(
                          {
                            // length: assessment?.minimumExams + assessment?.canExceedMinimumBy,  // enabling can exceed by..!
                            length: assessment?.minimumExams,
                          },
                          (_, buttonIndex) => ({
                            label:
                              assessment?.examCategory?.name +
                              " " +
                              (buttonIndex + 1),
                            value: buttonIndex + 1,
                          })
                        )
                      )}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    type="hidden"
                    name="assessmentNumber"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter Out of Mark"
                    onChange={uploadResultByOnline.handleChange}
                    onBlur={uploadResultByOnline.handleBlur}
                    value={uploadResultByOnline.values.assessmentNumber || ""}
                    invalid={
                      uploadResultByOnline.touched.assessmentNumber &&
                      uploadResultByOnline.errors.assessmentNumber
                        ? true
                        : false
                    }
                  />
                  {uploadResultByOnline.touched.assessmentNumber &&
                  uploadResultByOnline.errors.assessmentNumber ? (
                    <FormFeedback type="invalid">
                      {uploadResultByOnline.errors.assessmentNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Select Marked Out Of
                  </label>
                  <Select
                    id="outOff"
                    name="outOff"
                    isDisabled={!selectedAssessmentNumber}
                    value={selectedOutOf}
                    onChange={e => {
                      uploadResultByOnline.setFieldValue("outOff", e.value)
                      setSelectedOutOff(e)
                    }}
                    options={markedOut}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                  />
                  <Input
                    type="hidden"
                    name="outOff"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter Out of Mark"
                    onChange={uploadResultByOnline.handleChange}
                    onBlur={uploadResultByOnline.handleBlur}
                    value={uploadResultByOnline.values.outOff || ""}
                    invalid={
                      uploadResultByOnline.touched.outOff &&
                      uploadResultByOnline.errors.outOff
                        ? true
                        : false
                    }
                  />
                  {uploadResultByOnline.touched.outOff &&
                  uploadResultByOnline.errors.outOff ? (
                    <FormFeedback type="invalid">
                      {uploadResultByOnline.errors.outOff}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <label htmlFor="assessmentWeight" className="col-form-label">
                    Result Weight
                  </label>
                  <Select
                    id="assessmentWeight"
                    name="assessmentWeight"
                    onChange={e => {
                      uploadResultByOnline.setFieldValue(
                        "assessmentWeight",
                        e.value
                      )
                    }}
                    options={courseWeight}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999,
                      }),
                    }}
                    defaultInputValue="1"
                    defaultValue={parseInt(1)}
                  />
                  <Input
                    type="hidden"
                    name="assessmentWeight"
                    className="form-control"
                    id="assessmentWeight"
                    placeholder="Enter Out of Mark"
                    onChange={uploadResultByOnline.handleChange}
                    onBlur={uploadResultByOnline.handleBlur}
                    value={uploadResultByOnline.values.assessmentWeight || ""}
                    invalid={
                      uploadResultByOnline.touched.assessmentWeight &&
                      uploadResultByOnline.errors.assessmentWeight
                        ? true
                        : false
                    }
                  />
                  {uploadResultByOnline.touched.assessmentWeight &&
                  uploadResultByOnline.errors.assessmentWeight ? (
                    <FormFeedback type="invalid">
                      {uploadResultByOnline.errors.assessmentWeight}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                {loading ? (
                  <PlaceHolderLoader rows={4} type="table" columSize={12} />
                ) : (
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                  >
                    <Thead>
                      <Tr>
                        <Th data-priority="1">S/N</Th>
                        <Th data-priority="1">Full Name</Th>
                        <Th data-priority="1">Registration No</Th>
                        <Th>Marks</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {students?.map((student, index) => (
                        <Tr key={student?.registrationNumber}>
                          <Td>{startIndex + index + 1}</Td>
                          <Td>{student?.fullName}</Td>
                          <Td>{student?.registrationNumber}</Td>
                          <Td>
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              step="0.001"
                              name={`marks.${student?.registrationNumber}`}
                              className="form-control-sm"
                              placeholder="Enter marks"
                              defaultValue={student?.score}
                              onChange={e => {
                                const newMarks = {
                                  ...uploadResultByOnline.values.marks,
                                  [student?.registrationNumber]: parseFloat(
                                    e.target.value
                                  ),
                                }
                                parseFloat(
                                  uploadResultByOnline.setFieldValue(
                                    "marks",
                                    newMarks
                                  ),
                                  10
                                )
                              }}
                              invalid={
                                uploadResultByOnline.touched.marks?.[
                                  student?.registrationNumber
                                ] &&
                                uploadResultByOnline.errors.marks?.[
                                  student?.registrationNumber
                                ]
                              }
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <SubmitButtonHelper
                name="Submit"
                type="primary"
                formik={uploadResultByOnline}
              />
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  )
}
export default UploadResultOnline
