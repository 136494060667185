import { Link } from "react-router-dom"
import React from "react"


const AcademicAdvisorLinks = ({hasAuthority}) => {
  return (
    <>
      {hasAuthority("VIEW_ACADEMIC_ADVISOR_MENU") && (
        <>
          {hasAuthority("VIEW_ACADEMIC_ADVISOR_MENU") && (
            <li>
              <Link to="/instructor/study_postponement_requests">
                <i className="bx bx-time-five"></i>
                <span>Study Postponement Requests</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_ACADEMIC_ADVISOR_MENU") && (
            <li>
              <Link to="/instructor/exam_postponement_requests">
                <i className="bx bx-calendar-minus"></i>
                <span>Exam Postponement Requests</span>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  )
}


export default AcademicAdvisorLinks
