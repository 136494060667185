import { gql } from "@apollo/client"

const CREATE_STATUS_TYPE = gql`
  mutation RegisterStatusTypes($inputs: [StatusTypeDtoInput!]!) {
    registerStatusTypes(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_STATUS_TYPE = gql`
  mutation RemoveStatusType($uid: String!) {
    removeStatusType(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_STATUS_TYPE, DELETE_STATUS_TYPE }
