import { gql } from "@apollo/client"

const CREATE_SUBJECT= gql`
  mutation RegisterSubjects($inputs: SubjectInput!) {
    registerSubject(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          uid
          name
          code
       }
      }
    }
  }
`

const DELETE_SUBJECT= gql`
  mutation RemoveSubject($uid: String!) {
    removeSubject(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_SUBJECT, DELETE_SUBJECT}
