import React from "react"
import Card from "../../../components/Common/Card"
import { jwtDecode } from "jwt-decode"
import {ServicesCards} from "../../../components/EsbDashboardLayout/ESBServices";
import showToast from "../../../helpers/ToastHelper";
import {useNavigate} from "react-router-dom";
const EsbDashboard = () => {
    const navigate = useNavigate()
    const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
    const userData = accessToken ? jwtDecode(accessToken) : null
    const userAuthorities = userData?.authorities || []
    const hasAuthority = authority => userAuthorities.includes(authority)
    const visibleSystems = ServicesCards.filter(system =>
        userAuthorities.includes(system.authority)
    );
    if(visibleSystems.length === 1) {
        navigate(`${visibleSystems[0].link}`)
    }
  return (
    <div className="card-wrapper">
      <h2>Services</h2>
      <div className="row card-container">
        {ServicesCards.map((card, index) => {
          if (card.authority && !hasAuthority(card.authority)) {
            return null;
          }
          return (
            <Card
              key={index}
              card_image={card.image}
              link={card.link}
              title={card.title}
            />
          );
        })}
      </div>
    </div>
  );
}

export default EsbDashboard
