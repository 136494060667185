import { gql } from "@apollo/client";
const UPDATE_ACCOMMODATION_STATUS= gql`
  mutation UpdateAccommodationStatus($input:  StudentAccommodationStatusInput!) {
    updateAccommodationStatus(input: $input) {
      status
      code
      message
    }
  }
`;

export { UPDATE_ACCOMMODATION_STATUS }

