import React, { useContext, useEffect, useState } from "react"
import PropTypes, { any } from "prop-types"
import { Tbody, td, th, thead, Tr } from "react-super-responsive-table"
import { Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, Row, Table } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import showToast from "../../../../helpers/ToastHelper"
import { useMutation } from "@apollo/client"
import { STUDENT_REGISTER_COURSE } from "../../Mutations/StudentRegistrationMutations"
import { registrationGraphQLClient } from "../../../Authentication/ApolloClient"
import { GET_STUDENT_COURSE_TO_REGISTER } from "../../Queries/Registration/StudentSemesterRegistrationQuery"
import AlertHelper from "../../../../helpers/AlertHelper"


const RegisterCourses = props => {
  const { courses, loginData, courseRegistered } = props
  let student = loginData.student
  const [allowReg, setAllowReg] = useState(0)
  const [selectedCourses, setSelectedCourses] = useState([])
  let elective = 0
  let course_status
  const [registerCourse] = useMutation(STUDENT_REGISTER_COURSE)
  // console.log(student.status.name)


  if (courses)
    elective = courses[0]?.programSemester?.electiveCredits

  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      courses: [],
      remove: []
    },
    validationSchema: Yup.object().shape({
      courses: Yup.array().min(1, "Select at least one Courses")
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (allowReg < elective) {
        setSubmitting(false)
        return showToast(`Select At least ${elective} Credit Of Elective Courses ${allowReg} Credits Selected`, "error")
      }
      registerCourse({
        client: registrationGraphQLClient,
        variables: {
          inputs: values.courses,
          remove: values.remove
        },
        refetchQueries: [
          {
            query: GET_STUDENT_COURSE_TO_REGISTER,
            variables: {
              input: {
                studyYear: student?.studyYear === 0 ? student?.studyYear + 1 : student?.studyYear,
                semester: 1,
                // registrationNumber:student?.registrationNumber,
                studentUid: student.uid,
                programUid: student?.programmeUid
              }
            }
          }
        ],
        onCompleted: ({ registerStudentCourse }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (registerStudentCourse.code === 8000) {
            showToast(registerStudentCourse.message, "success")
            setSubmitting(false)
          } else {

            showToast(
              registerStudentCourse.message,
              registerStudentCourse.code === 8000 ? "success" : "error"
            )
            setSubmitting(false)
          }
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        }
      })
    }
  })

  const checkValueInArrays = (uid) => {
    return courseRegistered.some((e) => e.programCourse.uid.includes(uid))
  }
  const selected = []
  useEffect(() => {
    let selectedElective = 0
    for (let i = 0; i < courses?.length; i++) {
      const c = courses[i]
      const coreEle = c.courseCategory.name.toLowerCase() === "core" ? 1 : 2
      if (courseRegistered && checkValueInArrays(c.uid)) {
        selected.push({
          programCourseUid: c.uid,
          studentUid: student?.uid,
          coreElective: student.status.name.toLowerCase() === "retake" ? 3 : coreEle
        })
        if (c.courseCategory.name.toLowerCase() === "elective") setAllowReg(selectedElective += c.credit)
      } else {
        if (student.status.name.toLowerCase() === "continuing" && courses[i]?.courseCategory.name.toLowerCase() === "core") {
          selected.push({
            programCourseUid: c.uid,
            studentUid: student?.uid,
            coreElective: student.status.name.toLowerCase() === "retake" ? 3 : coreEle
          })
        }
      }
      setSelectedCourses(selected)
      validationType.setFieldValue("courses", selected)
    }
  }, [])


  const checkUncheckData = (item, e) => {
    let course = [...validationType.values.courses]
    let remove = [...validationType.values.remove]
    if (e) {
      course.push(item)
      const regCourse = courseRegistered.find(i => i.programCourse.uid === item.programCourseUid)
      if (regCourse) remove = remove.filter((uid) => uid !== regCourse.uid)
    } else {
      course = course.filter((ar) => ar.programCourseUid !== item.programCourseUid)
      const regCourse = courseRegistered.find(i => i.programCourse.uid === item.programCourseUid)
      if (regCourse) remove.push(regCourse.uid)
    }
    validationType.setFieldValue("courses", course)
    validationType.setFieldValue("remove", remove)
  }


  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>

            <Form
              onSubmit={e => {
                e.preventDefault()
                validationType.handleSubmit()
                return false
              }}>
              {validationType.errors.courses ? (
                <FormFeedback type="invalid">
                  {validationType.errors.courses}
                </FormFeedback>
              ) : null}
              {courses?.length > 0 ?
                <div className="table-responsive">
                  <div><i className="fa fa-times-circle text-danger" /> NOT REGISTERED | <i
                    className="fa fa-check-circle text-success" /> REGISTERED
                  </div>

                  <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                      <th>S/No</th>
                      <th>Academic Year</th>
                      <th>Study Year</th>
                      <th>Sem</th>
                      <th>Course Ante</th>
                      <th>Course Name</th>
                      <th>Nature</th>
                      <th>Credit Hours</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {courses?.map((c, index) => (
                      <tr key={c.uid}>
                        <td align="center">{index + 1}</td>
                        <td align="left">{c?.programSemester?.academicYear?.name}</td>
                        <td align="center">{c?.programSemester?.studyYear}</td>
                        <td align="center">{c?.programSemester?.semester}</td>
                        <td align="center">{c?.course?.code}</td>
                        <td align="left">{c?.course.name}</td>
                        <td align="center">{c?.courseCategory?.name}</td>
                        <td align="center">{c?.credit}</td>
                        <th>
                          {
                            courseRegistered && checkValueInArrays(c.uid) ?
                              <i className="fa fa-check-circle text-success" /> :
                              <i className="fa fa-times-circle text-danger" />
                          }
                        </th>
                        <td align="center">
                          <div className="square-switch small">
                            <Input
                              disabled={student?.status?.name.toLowerCase() === "continuing" && c.courseCategory.name.toLowerCase() === "core"}
                              type="checkbox"
                              id={c.uid}
                              className="switch switch-bool"
                              name="courses"
                              defaultChecked={checkValueInArrays(c.uid) || (student?.status?.name.toLowerCase() === "continuing" && c.courseCategory.name.toLowerCase() === "core")}
                              onChange={
                                (e) => {
                                  validationType.handleChange(e)
                                  if (c.courseCategory.name.toLowerCase() === "elective") {
                                    if (e.target.checked) setAllowReg(allowReg + c.credit)
                                    else setAllowReg(allowReg - c.credit)
                                  }
                                  if (student?.status?.name.toLowerCase() === "retake") course_status = 3
                                  else course_status = c.courseCategory.name.toLowerCase() === "elective" ? 2 : 1
                                  checkUncheckData({
                                    programCourseUid: c.uid,
                                    studentUid: student?.uid,
                                    coreElective: course_status
                                  }, e.target.checked)
                                }
                              }
                              onBlur={validationType.handleBlur}
                              // value={}
                              invalid={
                                !!(validationType.touched.courses && validationType.errors.courses)
                              }
                            />
                            <label
                              htmlFor={c.uid}
                              data-on-label="Yes"
                              data-off-label="No"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}

                    </tbody>
                  </table>

                  <div className="text-end">
                    {
                      allowReg < elective ?
                        <strong className="text-danger">
                          {`Select At least ${elective} Credit Of Elective Courses ${allowReg} Credits Selected`}
                        </strong>
                        :
                        <SubmitButtonHelper type={"primary"} name={"Register"} formik={validationType} />
                    }
                  </div>

                </div>
                : <AlertHelper type={"danger"}
                               message={"There is no any course associated to you right now, if you think its a problem please contact your head of department for more help"} />

              }
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
RegisterCourses.propTypes = {
  loginData: PropTypes.any.isRequired,
  courses: PropTypes.any.isRequired,
  courseRegistered: any.isRequired
}

export default RegisterCourses