import React, { useEffect, useState } from "react"
import LayoutHelper from "helpers/LayoutHelper"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  CardSubtitle,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { createContext } from "react"
import { useNavigate } from "react-router-dom"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_INSTRUCTOR_COURSE_ALLOCATION } from "Modules/Instructor/Queries/InstructorCoursesQueries"
import {
  showForwardingAlert,
  showReturnCourseResultAlert,
  showUnpublishProgramSemesterResultAlert,
} from "helpers/DeleteWaringHelper"
import { FORWARD_PROGRAM_RESULT_BY_SENATE } from "../Mutations/SenateMutations"
import { GET_PROGRAM_SEMESTER_FORWARD_STATUS } from "Modules/Principal/Queries/PrinciplaQueries"
import {
  UNPUBLISH_ALL_SEMESTER_RESULTS,
  UNPUBLISH_PROGRAM_SEMESTER_RESULTS,
} from "../../Principal/Mutations/PrinciaplaMutations"

const breadcrumbItem = [
  {
    title: "Forward Results",
    path: "",
    isActive: true,
  },
]

export const ForwardResultsContext = createContext()

const PublishExamBySenateResult = () => {
  const [courseAllocate, setCourseAllocate] = useState(null),
    navigate = useNavigate() //for update
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState(null)
  const [selectedUids, setSelectedUids] = useState([])
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null)
  const [selectedSemester, setSelectedSemester] = useState(null)
  const [programResponse, setProgramResponse] = useState([])
  const [isUnpublishLoading, setIsUnpublishLoading] = useState(false)
  const [forwardBySenate] = useMutation(FORWARD_PROGRAM_RESULT_BY_SENATE, {
    client: registrationGraphQLClient,
  })

  const [unpublishAllSemesterResult] = useMutation(
    UNPUBLISH_ALL_SEMESTER_RESULTS,
    {
      client: registrationGraphQLClient,
    }
  )

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const {
    loading: academicYearLoading,
    error: academicYearError,
    data: academicYear,
    refetch: acRef,
  } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
    fetchPolicy: "network-only",
  })

  const [
    fetchCourseData,
    { loading: courseLoading, error: errorCourseFetch, data: courseResult },
  ] = useLazyQuery(GET_INSTRUCTOR_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only",
  })

  const [
    fetchHodProgramSemester,
    { loading: programLoading, error: errorProgramsFetch, data: programResult },
  ] = useLazyQuery(GET_PROGRAM_SEMESTER_FORWARD_STATUS, {
    client: registrationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only",
  })

  useEffect(() => {
    if (selectedAcademicYear && selectedSemester) {
      fetchHodProgramSemester({
        variables: {
          semester: parseInt(selectedSemester),
          academicYearUid: selectedAcademicYear,
          forwardStatus: 3,
        },
        fetchPolicy: "network-only",
      })
    }
  }, [selectedAcademicYear, selectedSemester])

  const [
    { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
  ] = useLazyQuery(GET_ACADEMICYEARS, { client: registrationGraphQLClient })

  let academicYears = academicYear?.getAcademicYears?.data?.items
  if (searchYear?.getAcademicYears?.data)
    academicYears = searchYear?.getAcademicYears?.data?.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      academicYearUid: "",
      semester: "",
      programSemesterUid: "",
    },

    validationSchema: Yup.object().shape({
      semester: Yup.string().required("Semester is required"),
      academicYearUid: Yup.string().required("Academic year is required"),
      programSemesterUid: Yup.string().required("Semester Program is required"),
    }),
  })

  const semester_options = Array.from({ length: 10 }, (_, index) => ({
    value: `${index + 1}`,
    label: `Semester ${index + 1}`,
  }))

  Array.from({ length: 10 }, (_, index) => ({
    value: `${index + 1}`,
    label: `Semester ${index + 1}`,
  }))

  const handleCheckboxChange = event => {
    const { id, checked } = event.target
    if (checked) {
      setSelectedUids(prevUids => [...prevUids, id])
    } else {
      setSelectedUids(prevUids => prevUids.filter(uid => uid !== id))
    }
  }

  const forwardResult = () => {
    if (selectedUids.length > 0) {
      showForwardingAlert(() => {
        setLoading(true)
        forwardBySenate({
          variables: {
            programSemesterUids: selectedUids,
          },
          refetchQueries: [
            {
              query: GET_PROGRAM_SEMESTER_FORWARD_STATUS,
              variables: {
                semester: parseInt(selectedSemester),
                academicYearUid: selectedAcademicYear,
                forwardStatus: 3,
              },
              fetchPolicy: "network-only",
            },
          ],
          onCompleted: ({ publishExamResultByProgramSemesterUid }) => {
            if (publishExamResultByProgramSemesterUid.code === 8000) {
              setSelectedUids([])
              showToast(
                publishExamResultByProgramSemesterUid.message,
                "success"
              )
              setLoading(false)
            } else {
              showToast(
                publishExamResultByProgramSemesterUid.message,
                publishExamResultByProgramSemesterUid.code === 8000
                  ? "success"
                  : "error"
              )
              setLoading(false)
            }
          },
          onError: error => {
            setLoading(false)
            console.error("Mutation error:", error)
          },
        })
      })
    } else {
      showToast("No any course selected for forwarding result", "error")
    }
  }
  console.log(selectedAcademicYear)
  // Perform Unpublish All Semester Results
  const handleUnpublishAllSemesterResults = () => {
    showUnpublishProgramSemesterResultAlert(() => {
      setIsUnpublishLoading(true)
      unpublishAllSemesterResult({
        variables: {
          semester: parseInt(selectedSemester),
          academicYearUid: selectedAcademicYear,
        },
        refetchQueries: [
          {
            query: GET_PROGRAM_SEMESTER_FORWARD_STATUS,
            variables: {
              semester: parseInt(selectedSemester),
              academicYearUid: selectedAcademicYear,
              forwardStatus: 3,
            },
            fetchPolicy: "network-only",
          },
        ],
        onCompleted: ({ unPublishAllSemesterExamResult }) => {
          setIsUnpublishLoading(false)
          if (unPublishAllSemesterExamResult.code === 8000) {
            setSelectedUids([])
            showToast(unPublishAllSemesterExamResult.message, "success")
          } else {
            showToast(
              unPublishAllSemesterExamResult.message,
              unPublishAllSemesterExamResult.code === 8000 ? "success" : "error"
            )
          }
        },
        onError: error => {
          setLoading(false)
          console.error("Mutation error:", error)
        },
      })
    })
  }

  useEffect(() => {
    if (!programLoading && programResult) {
      setProgramResponse(
        programResult?.getProgramSemesterForwardStatus?.data || []
      )
    }
  }, [programLoading, programResult])

  return (
    <ForwardResultsContext.Provider
      value={{
        setCourseAllocate,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Examination Result publish"
      >
        {academicYearLoading && (
          <PlaceHolderLoader columSize={12} type={"table"} rows={5} />
        )}
        <>
          <Row>
            <Col md={6}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label>Academic Year</Label>
                          <Select
                            // value={selectedGroup}
                            onChange={e => {
                              if (e) {
                                validation.setFieldValue(
                                  "academicYearUid",
                                  e.value
                                )
                                setSelectedAcademicYear(e.value)
                              } else {
                                validation.setFieldValue("academicYearUid", "")
                              }
                            }}
                            options={academicYears?.map(year => ({
                              value: year.uid,
                              label: year.name,
                            }))}
                            className="select2-selection"
                            isLoading={academicYearLoading || yearSearchLoading}
                            isClearable={true}
                          />
                          <Input
                            name="academicYearUid"
                            type="hidden"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.academicYearUid}
                            invalid={
                              !!(
                                validation.touched.academicYearUid &&
                                validation.errors.academicYearUid
                              )
                            }
                          />
                          {validation.touched.academicYearUid &&
                          validation.errors.academicYearUid ? (
                            <FormFeedback type="invalid">
                              {validation.errors.academicYearUid}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Semester</Label>
                          <Select
                            // value={selectedGroup}
                            name="semester"
                            onChange={e => {
                              setSelectedSemester(e.value)
                              validation.setFieldValue("semester", e.value)
                            }}
                            styles={{
                              menu: base => ({
                                ...base,
                                position: "absolute",
                                zIndex: 9999,
                              }),
                            }}
                            options={semester_options}
                            className="select2-selection"
                            // defaultValue={updateProgramSemisterData?.studyYear}
                            // defaultInputValue={updateProgramSemisterData?.studyYear}
                          />
                          <Input
                            name="semester"
                            type="hidden"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.semester || 1}
                            invalid={
                              !!(
                                validation.touched.semester &&
                                validation.errors.semester
                              )
                            }
                          />
                          {validation.touched.semester &&
                          validation.errors.semester ? (
                            <FormFeedback type="invalid">
                              {validation.errors.semester}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="ml-2">
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-1 mt-4 card-title">
                    <h5>Program Lists</h5>
                    <Button
                      disabled={selectedUids.length <= 0 ? true : false}
                      color="primary"
                      className="btn btn-sm  mb-2"
                      onClick={() => {
                        forwardResult()
                      }}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mr-3"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-4">Please wait...</span>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                          Publish result
                        </>
                      )}
                    </Button>{" "}
                    <Button
                      disabled={
                        selectedSemester === null || selectedAcademicYear === ""
                          ? true
                          : false
                      }
                      color="warning"
                      className="btn btn-sm  mb-2 ml-2"
                      onClick={() => {
                        handleUnpublishAllSemesterResults()
                      }}
                      style={{ marginLeft: "10" }}
                    >
                      {isUnpublishLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mr-3"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-4">Please wait...</span>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-times"></i> Unpublish All Semester
                          Results
                        </>
                      )}
                    </Button>{" "}
                  </CardSubtitle>

                  <div className=" table-responsive">
                    {programLoading ? (
                      <PlaceHolderLoader rows={4} type="table" columSize={12} />
                    ) : (
                      <Table
                        id="tech-companies-1"
                        className="table-sm table-striped table-bordered"
                      >
                        <Thead className="text-center">
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th>Forward</Th>
                            <Th>Programme name</Th>
                            <Th>Course(s) published</Th>
                            <Th>Study Yeary</Th>
                          </Tr>
                        </Thead>
                        <Tbody className="text-center">
                          {programResponse &&
                            programResponse
                              .slice(startIndex, endIndex)
                              .map((resultRows, index) => (
                                <Tr key={resultRows?.uid}>
                                  <Td>{startIndex + index + 1}</Td>
                                  <Td
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="form-check">
                                      <>
                                        {resultRows?.forwardStatus === false ? (
                                          <Input
                                            onChange={handleCheckboxChange}
                                            id={resultRows?.programSemesterUid}
                                            name={
                                              resultRows?.programSemesterUid
                                            }
                                            type="checkbox"
                                            color="primary"
                                            className="primary"
                                            style={{
                                              width: 25,
                                              height: 25,
                                              padding: 0,
                                              borderColor: "black",
                                              marginRight: 10,
                                              verticalAlign: "middle",
                                            }}
                                          />
                                        ) : (
                                          <strong>Forwarded</strong>
                                        )}
                                      </>
                                    </div>
                                  </Td>
                                  <Td style={{ textAlign: "left" }}>
                                    {resultRows?.programName +
                                      "(" +
                                      resultRows?.programCode +
                                      ")"}
                                  </Td>
                                  <Td>
                                    {resultRows?.remark}
                                  </Td>
                                  <Td>{"Year " + resultRows?.studyYear}</Td>
                                </Tr>
                              ))}
                        </Tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      </LayoutHelper>
    </ForwardResultsContext.Provider>
  )
}
export default PublishExamBySenateResult
