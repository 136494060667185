import { Link } from "react-router-dom"
import React from "react"

const InstructorLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_INSTRUCTOR_MENU") && (
        <>
          {hasAuthority("VIEW_MY_COURSES_MENU") && (
            <>
              <li>
                <Link to="/examination/instructor/my-courses">
                  <i className="bx bx-book-open"></i>
                  <span>My Courses</span>
                </Link>
              </li>

              <li>
                <Link to="/examination/instructor/my_courses_previous">
                  <i className="bx bx-history"></i>
                  <span>My Previous Courses</span>
                </Link>
              </li>
            </>
          )}

          {hasAuthority("FORWARD_EXAM_RESULTS") && (
            <li>
              <Link to="/examination/instructor/forward-results">
                <i className="bx bx-send"></i>
                <span>Forward Results</span>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default InstructorLinks
