//REGISTE
import { gql } from '@apollo/client';

const GET_COURSES = gql`
  query GetCourses($pagination: PaginationInput!) {
    getCourses(pagination: $pagination){
        status
        code
        message
        data{
          items{
            uid
            code
            description
            name
            offered
            departmentUid
          }
          totalCount
        }
  }
}
`;

const GET_COURSE_LEARNIG_OUTCOMES = gql`
  query GetCourseLearnOutcomes($courseUid: String!) {
    getCourseLearnOutcomesByCourse(courseUid: $courseUid){
        status
        code
        message
        data{
            uid
            learningOutcome
        }
  }
}
`;

const GET_HOD_STUDENT_REGISTERED_COURSES = gql`
  query GetHodStudentCourseRegistration($input: HODStudentCourseRegistrationInput!) {
    getHodStudentCourseRegistration(input: $input){
        status
        code
        message
        data{
      uid
      coreElective
      programCourse{
        credit
        courseCategory{
          name
        }
        programSemester{
          academicYear{
            name
          }
        }
        course{
          code
          name
        }
      }
      
    }
  }
}
`;

export { GET_COURSES,GET_COURSE_LEARNIG_OUTCOMES,GET_HOD_STUDENT_REGISTERED_COURSES }