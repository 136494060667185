import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_REVIEWER } from "../Mutations/ReviewerMutations"
import { GET_REVIEWERS } from "../Queries/ReviewerQueries"
import { ReviewerContext } from "./Reviewer"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

const ReviewerModal = () => {
  const { updateReviewerData, setUpdateReviewerData, staffUid } =
    useContext(ReviewerContext)

  const reviewerInstitutions = [
    {
      label: "Ardhi University",
      value: "2ac0f17a-08f9-42fd-af72-7a7a8bcdcbfd",
    },
    {
      label: "University of Dar es Salaam",
      value: "2ac0f17a-08f9-42fd-af72-7a7a8bcdcbfd",
    },
    {
      label: "Mzumbe University",
      value: "2ac0f17a-08f9-42fd-af72-7a7a8bcdcbfd",
    },
  ]

  const [Reviewer] = useMutation(ADD_UPDATE_REVIEWER)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_REVIEWERS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: updateReviewerData ? updateReviewerData.firstName : "",
      middleName: updateReviewerData ? updateReviewerData.middleName : "",
      lastName: updateReviewerData ? updateReviewerData.lastName : "",
      email: updateReviewerData ? updateReviewerData.email : "",
      phoneNumber: updateReviewerData ? updateReviewerData.phoneNumber : "",
      uid: updateReviewerData ? updateReviewerData.uid : "",
      title: updateReviewerData ? updateReviewerData.title : "",
      reviewerInstitutionUid: updateReviewerData
        ? updateReviewerData.reviewerInstitutionUid
        : "",
      reviewerSpecialist: updateReviewerData
        ? updateReviewerData.reviewerSpecialist
        : "",
    },

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First Name is Required"),
      middleName: Yup.string().required("Middle Name is required"),
      lastName: Yup.string().required("Last Name Level is required"),
      email: Yup.string().required("Email Level is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      title: Yup.string().required("Title is required"),
      reviewerInstitutionUid: Yup.string().required("Institution is required"),
      reviewerSpecialist: Yup.string().required("Speciality is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      if (values.uid === "") {
        delete values.uid
      }

      Reviewer({
        client: asimsGraphQLClient,
        variables: {
          inputs: values,
        },
        refetchQueries: [
          {
            query: GET_REVIEWERS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerReviewer }) => {
          validation.resetForm()
          setModalOpen(false)
          setUpdateReviewerData(false) // Close the modal
          if (registerReviewer.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateReviewerData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerReviewer.message,
            registerReviewer.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Reviewer
      </Button>

      <Modal
        isOpen={modalOpen || !!updateReviewerData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateReviewerData ? "Edit Reviewer" : "Add Reviewer"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateReviewerData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <h5 className="form-details-header">Personal Information</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="firstName" className="col-form-label">
                      FirstName:
                    </label>
                    <Input
                      type="text"
                      name="firstName"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter FirstName"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.firstName ||
                        updateReviewerData?.firstName ||
                        ""
                      }
                      invalid={
                        !!(
                          validation.touched.firstName &&
                          validation.errors.firstName
                        )
                      }
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="middleName" className="col-form-label">
                      Middle Name:
                    </label>
                    <Input
                      type="text"
                      name="middleName"
                      className="form-control"
                      id="middleName"
                      placeholder="Enter MiddleName"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.middleName || ""}
                      invalid={
                        !!(
                          validation.touched.middleName &&
                          validation.errors.middleName
                        )
                      }
                    />
                    {validation.touched.middleName &&
                    validation.errors.middleName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.middleName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="lastName" className="col-form-label">
                      Last Name:
                    </label>
                    <Input
                      type="text"
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter Last Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        !!(
                          validation.touched.lastName &&
                          validation.errors.lastName
                        )
                      }
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6"></Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Contact Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="col-form-label">
                      Phone Number:
                    </label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="Enter Phone Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phoneNumber || ""}
                      invalid={
                        !!(
                          validation.touched.phoneNumber &&
                          validation.errors.phoneNumber
                        )
                      }
                    />
                    {validation.touched.phoneNumber &&
                    validation.errors.phoneNumber ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phoneNumber}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="email" className="col-form-label">
                      Email:
                    </label>
                    <Input
                      type="email"
                      name="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Email Address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        !!(validation.touched.email && validation.errors.email)
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Affiliation Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="reviewerSpecialist"
                      className="col-form-label"
                    >
                      Speciality
                    </label>
                    <Input
                      type="text"
                      name="reviewerSpecialist"
                      className="form-control"
                      id="reviewerSpecialist"
                      placeholder="Enter Speciality"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.reviewerSpecialist || ""}
                      invalid={
                        !!(
                          validation.touched.reviewerSpecialist &&
                          validation.errors.reviewerSpecialist
                        )
                      }
                    />
                    {validation.touched.reviewerSpecialist &&
                    validation.errors.reviewerSpecialist ? (
                      <FormFeedback type="invalid">
                        {validation.errors.reviewerSpecialist}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="title" className="col-form-label">
                      Title:
                    </label>
                    <Input
                      type="text"
                      name="title"
                      className="form-control"
                      id="title"
                      placeholder="Enter Title"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title || ""}
                      invalid={
                        !!(validation.touched.title && validation.errors.title)
                      }
                    />
                    {validation.touched.title && validation.errors.title ? (
                      <FormFeedback type="invalid">
                        {validation.errors.title}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label
                      htmlFor="reviewerInstitutionUid"
                      className="col-form-label"
                    >
                      Institution:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue(
                          "reviewerInstitutionUid",
                          e.value,
                        )
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateReviewerData?.reviewerInstitution?.uid
                      }
                      defaultInputValue={
                        updateReviewerData?.reviewerInstitution?.name
                      }
                      options={reviewerInstitutions}
                      className="select2-selection"
                    />
                    <Input
                      name="reviewerInstitutionUid"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.reviewerInstitutionUid || ""}
                      invalid={
                        !!(
                          validation.touched.reviewerInstitutionUid &&
                          validation.errors.reviewerInstitutionUid
                        )
                      }
                    />
                    {validation.touched.reviewerInstitutionUid &&
                    validation.errors.reviewerInstitutionUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.reviewerInstitutionUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6"></Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateReviewerData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ReviewerModal
