import { Link } from "react-router-dom"
import React from "react"

const ReportsLinks = ({ hasAuthority }) => {
  return (
      <>
          <li>
              <Link to="/reports/dashboard">
                  <i className="bx bx-home-circle"></i>
                  <span>Dashboard</span>
              </Link>
          </li>

          {hasAuthority("VIEW_STUDENT_REPORT_MENU") && (
              <>
                  {hasAuthority("VIEW_STUDENT_LIST_REPORT") && (
                      <li>
                          <Link to="/reports/students">
                              <i className="bx bx-list-ul"></i>
                              <span>Student List </span>
                          </Link>
                      </li>
                  )}

                  {hasAuthority("VIEW_NHIF_REPORT") && (
                      <li>
                          <Link to="/reports/student_nhif_reports">
                              <i className="bx bx-file"></i>
                              <span> NHIF Reports </span>
                          </Link>
                      </li>
                  )}

                  {hasAuthority("VIEW_PROGRAM_CHANGE_REQUESTS_REPORT") && (
                      <li>
                          <Link to="/reports/change_program">
                              <i className="bx bx-transfer"></i>
                              <span>Change Program Requests</span>
                          </Link>
                      </li>
                  )}

                  {hasAuthority("VIEW_PROCESSED_PROGRAM_CHANGE_REQUESTS_REPORT") && (
                      <li>
                          <Link to="/reports/processed_change_program">
                              <i className="bx bx-check-double"></i>
                              <span>Change Program Results</span>
                          </Link>
                      </li>
                  )}

                  {hasAuthority("VIEW_PROGRAM_SUMMARY_REPORT") && (
                      <li>
                          <Link to="/reports/program/summary">
                              <i className="bx bx-bar-chart"></i>
                              <span>Program Summary Report</span>
                          </Link>
                      </li>
                  )}

                  {hasAuthority("VIEW_ALL_EXAMS_SUMMARY_REPORT") && (
                      <li>
                          <Link to="/reports/student-examinations">
                              <i className="bx bx-check-square"></i>
                              <span>Student Exam Results</span>
                          </Link>
                      </li>
                  )}

                  {hasAuthority("VIEW_ALL_EXAMS_SUMMARY_REPORT") && (
                      <li>
                          <Link to="/reports/semester_course_results">
                              <i className="bx bx-book-open"></i>
                              <span>Course Result Report</span>
                          </Link>
                      </li>
                  )}


              </>
          )}


          {hasAuthority("VIEW_ALL_EXAMS_SUMMARY_REPORT") && (
              <li>
                  <Link to="/#" className="has-arrow">
                      <i className="bx bxs-user-badge"></i>
                      <span>EXAMINATION REPORTS</span>
                  </Link>
                  <ul className="sub-menu">
                      <li>
                          <Link to="/reports/examination/semester_results">Semester Result</Link>
                      </li>
                      <li>
                          <Link to="/reports/examination/exam_summary_report">Exam Summary</Link>
                      </li>
                      <li>
                          <Link to="/reports/examination/academic_unit_result">Academic Unit Result</Link>
                      </li>
                  </ul>
              </li>

          )}

          {hasAuthority("VIEW_ADMISSION_MENU") && (
              <li>
                  <Link to="/#" className="has-arrow">
                      <i className="bx bxs-user-badge"></i>
                      <span>ONLINE APPLICATION</span>
                  </Link>
                  <ul className="sub-menu">
                      <li>
                          <Link to="/reports/online-application/selected-applicant">Selection report</Link>
                      </li>
                  </ul>
              </li>

          )}

          <li>
              <Link to="/reports/signaturepad">
                  <i className="bx bx-pen"></i>
                  <span>Submit Signature</span>
              </Link>
          </li>
      </>
  )
}

export default ReportsLinks
