import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Select from "react-select"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { CREATE_BYLAW } from "../Mutations/ByLawMutations"
import { GET_BYLAWS } from "../Queries/ByLawsQueries"
import { ByLawsContext } from "./ByLawList"
import { ChangeDateFormat } from "./ChangeDateFormat"

const ByLawsModal = props => {
  const { updateByLawsData, setUpdateByLawsData } = useContext(ByLawsContext)
  console.log("By laws data", updateByLawsData)
  const [ByLaws] = useMutation(CREATE_BYLAW, {
    client: registrationGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_BYLAWS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateByLawsData ? updateByLawsData?.name : "",
      code: updateByLawsData ? updateByLawsData?.code : "",
      status: updateByLawsData ? updateByLawsData?.status : "",
      startDate: updateByLawsData ? ChangeDateFormat(updateByLawsData?.startDate) : "",
      endDate: updateByLawsData ? ChangeDateFormat(updateByLawsData?.endDate) : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Bylaw name is required"),
      code: Yup.string().required("Bylaw code is required"),
      status: Yup.string().required("Bylaw Staus is required"),
      startDate: Yup.string().required("Bylaw start date is required"),
      endDate: Yup.string().required("Bylaw end date is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      // console.log("Data to post", values)
      ByLaws({
        client: registrationGraphQLClient,
        variables: {
          inputs: { ...values, uid: updateByLawsData?.uid },
        },
        refetchQueries: [
          {
            query: GET_BYLAWS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerByLaw }) => {
          //updateFunction(client.cache, { data: { registerByLaw } });
          if (registerByLaw.code === 8000) {
             validation.resetForm()
            setModalOpen(false)
            setUpdateByLawsData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateByLawsData(false) 
            validation.resetForm()
            //!validation.isSubmitting;
            // setUpdateCampusData(true) // Shikilia modal
          }
          showToast(
            registerByLaw.message,
            registerByLaw.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          validation.resetForm()
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Bylaw
      </Button>
      <Modal
        isOpen={modalOpen || !!updateByLawsData}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateByLawsData ? "Edit ByLaws" : "Add New ByLaws"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateByLawsData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md={6} lg={6}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Name:
                  </label>
                  <Input
                    type="text"
                    name="name"
                    className="form-control"
                    id="firstname"
                    placeholder="Enter Name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.name || updateByLawsData?.name || ""
                    }
                    invalid={
                      !!(validation.touched.name && validation.errors.name)
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6} lg={6}>
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Code:
                  </label>
                  <Input
                    type="text"
                    name="code"
                    className="form-control"
                    id="code"
                    placeholder="Enter code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.code || updateByLawsData?.code || ""
                    }
                    invalid={
                      !!(validation.touched.code && validation.errors.code)
                    }
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} lg={6}>
                <div className="mb-3">
                  <label htmlFor="startDate" className="col-form-label">
                    Start Date:
                  </label>
                  <Input
                    type="date"
                    name="startDate"
                    className="form-control"
                    id="startDate"
                    placeholder="Enter start Date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.startDate ||
                      updateByLawsData?.startDate ||
                      ""
                    }
                    invalid={
                      validation.touched.startDate &&
                      validation.errors.startDate
                        ? true
                        : false
                    }
                  />
                  {validation.touched.startDate &&
                  validation.errors.startDate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.startDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col md={6} lg={6}>
                <div className="mb-3">
                  <label htmlFor="endDate" className="col-form-label">
                    End Date:
                  </label>
                  <Input
                    type="date"
                    name="endDate"
                    className="form-control"
                    id="endDate"
                    placeholder="Enter End Date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.endDate ||
                      updateByLawsData?.endDate ||
                      ""
                    }
                    invalid={
                      validation.touched.endDate && validation.errors.endDate
                        ? true
                        : false
                    }
                  />
                  {validation.touched.endDate && validation.errors.endDate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.endDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>

            <div className="mb-3">
              <label htmlFor="status" className="col-form-label">
                Status:
              </label>

              <Select
                // value={selectedMulti2}
                onChange={e => {
                  validation.setFieldValue("status", e.value)
                }}
                options={[
                  {
                    label: "Current",
                    value: true,
                  },
                  {
                    label: "Not Current",
                    value: false,
                  },
                ]}
                className="select2-selection"
              />

              {validation.touched.status && validation.errors.status ? (
                <FormFeedback type="invalid">
                  {validation.errors.status}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateByLawsData(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ByLawsModal
