import React, { useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap"
import { useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient } from "../../../Authentication/ApolloClient"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import { GET_APPLICANT_COUNT_BY_DATE } from "../Queries/ApplicationReportQueries"
import { ApplicantListByDatePdfGenerator } from "../../../../helpers/ApplicantListByDate"

const breadcrumbItem = [
  {
    title: "Application Report By Date",
    path: "",
    isActive: true
  }
]
const ApplicantReportByDate = () => {

  const [loading, setLoading] = useState(false)
  const {
    loading: applicantReportLoading,
    error: applicantReportError,
    data: applicantReportData
  } = useQuery(GET_APPLICANT_COUNT_BY_DATE, {
    client: onlineApplicationGraphQLClient
  })


  let applicantReport = applicantReportData?.getApplicantCountByDate?.data


  const downloadList = (applicants, date) => {
    setLoading(true)
    const title = "APPLICATION REPORT BY DATE " + date
    const tableDataResponse = applicants?.map((item, index) => {
      const firstName = item?.firstName
      const middleName = item?.middleName
      const lastName = item?.lastName
      const phoneNumber = item?.phoneNumber
      const email = item?.email
      const isPaid = item?.isPaid ? "Yes" : "No"
      const formFourIndexNumber = item?.formFourIndexNumber

      return [
        (index + 1).toString(),
        firstName,
        middleName,
        lastName,
        formFourIndexNumber,
        phoneNumber,
        email,
        isPaid
      ]
    })
    ApplicantListByDatePdfGenerator(tableDataResponse, date, title)
    setLoading(false)
  }
  // Form validation
  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Summary Report">
      <Row>
        <Col lg={12}>

          <Card>
            <CardBody>

              {applicantReportLoading ? (
                <PlaceHolderLoader type="table" columSize={12} rows={5} />
              ) : (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >


                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>S/No</Th>
                          <Th data-priority="0">Application date</Th>
                          <Th data-priority="1">Total applicant</Th>
                          <Th data-priority="3">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {applicantReport?.items?.map((applicant, index) => (
                          <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{applicant?.date}</Td>
                            <Td>{applicant?.count}</Td>
                            <Td>
                              <button onClick={() => {
                                downloadList(applicant?.applicants, applicant?.date)
                              }} type="button" disabled={loading}
                                      className="btn btn-sm btn-primary">{loading === true ? "Please wait..." : "Export applicant(s)"}</button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>

        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default ApplicantReportByDate