//REGISTE
import { gql } from '@apollo/client';


const GET_CAMPUS = gql`
query GetCampus($pagination: PaginationInput!) {
  getCampuses(pagination: $pagination){
    status
    code
    message
    data{
      items{
         id
        uid
        name
        code
        description
      }
      totalCount
    }
  }
}
`;

export { GET_CAMPUS }