import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
} from "reactstrap"
import * as Yup from "yup"
import { CREATE_PROGRAM_COURSE_ASSESSMENT } from "../Mutations/ProgramCourseAssessmentMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE } from "../Queries/ProgramCourseAssessmentQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import Select from "react-select"
import { ProgramCourseAssessmentContext } from "./ProgramCourseAssessmentList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_PROGRAMS } from "Modules/Programs/Queries/ProgramQuaries"
import { GET_COURSES } from "Modules/Courses/Queries/CourseQueries"
import { GET_COURSE_CATEGORIES } from "Modules/CourseCategories/Queries/CourseCategoriesQueries"
import { GET_PROGRAM_SEMISTERS } from "Modules/ProgramSemister/Queries/ProgramSemisterQueries"
import { GET_PROGRAM_COURSES } from "Modules/ProgramCourses/Queries/ProgramCoursesQueries"
import { GET_EXAM_CATEGORIES } from "Modules/ExamCategories/Queries/ExamCategoryQueries"
//import { ProgramCourseDetailContext}      from "Modules/ProgramSemister/Views/ProgramCourseDetails"

const ProgramCourseAssessmentModal = () => {
  const {
    resultRow,
    updateProgramCourseAssessmentData,
    setUpdatedProgramCourseAssessmentData,
  } = useContext(ProgramCourseAssessmentContext)

  const [ProgramCourse] = useMutation(CREATE_PROGRAM_COURSE_ASSESSMENT, {
    client: registrationGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      //fetchData()
    }, 1000)
  }, [])

  const {
    loading: courseCategoryLoading,
    error: courseCategoryError,
    data: courseCategoryData,
  } = useQuery(GET_EXAM_CATEGORIES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  let courseCategoryArray = courseCategoryData?.getExamCategories?.data

  console.log(courseCategoryArray)

  const query = GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      examCategoryUid: updateProgramCourseAssessmentData
        ? updateProgramCourseAssessmentData.examCategoryUid
        : "",
      maximumScore: updateProgramCourseAssessmentData
        ? updateProgramCourseAssessmentData.maximumScore
        : "",
      minimumExams: updateProgramCourseAssessmentData
        ? updateProgramCourseAssessmentData.minimumExams
        : "",
      canExceedMinimumBy: updateProgramCourseAssessmentData
        ? updateProgramCourseAssessmentData.canExceedMinimumBy
        : 0,
    },

    validationSchema: Yup.object().shape({
      examCategoryUid: Yup.string().required(
        "Examination Category is required"
      ),
      maximumScore: Yup.string().required(
        "Maximum score of the assessment is required"
      ),
      minimumExams: Yup.string().required("minimum Exams is required"),
      // canExceedMinimumBy: Yup.string().required(
      //   "canExceed MinimumBy is required"
      // ),
    }),

    onSubmit: values => {
      ProgramCourse({
        variables: {
          inputs: [
            {
              ...values,
              uid: updateProgramCourseAssessmentData?.uid,
              programCourseUid: resultRow.uid,
            },
          ],
        },
        client: registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE,
            variables : {
                programCourseUid: resultRow?.uid,
            },
          },
        ],
        onCompleted: ({ registerProgramCourseAssessment }) => {
          if (registerProgramCourseAssessment.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdatedProgramCourseAssessmentData(false) // Close the modal
          } else {
            //resetForm()
            setModalOpen(true)
            setUpdatedProgramCourseAssessmentData(true) // Close the modal
            //setSubmit(false)
          }
          showToast(
            registerProgramCourseAssessment.message,
            registerProgramCourseAssessment.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          setModalOpen(false)
          setUpdatedProgramCourseAssessmentData(false)
          resetForm() // Reset the form
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Course Assessment
      </Button>
      <Modal
        size="md"
        isOpen={modalOpen || !!updateProgramCourseAssessmentData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramCourseAssessmentData
              ? "Edit Course Assessment"
              : "Add New Course Assessment"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <Row>
                <Col md="12" lg="12">
                  <div className="mb-3">
                    <label>Exam Category</label>
                    <Select
                      id="examCategoryUid"
                      name="examCategoryUid"
                      onChange={e => {
                        validation.setFieldValue("examCategoryUid", e.value)
                      }}
                      options={courseCategoryArray?.map(dp => ({
                        value: dp.uid,
                        label: dp.name,
                      }))}
                      defaultValue={
                        updateProgramCourseAssessmentData?.examCategoryUid
                      }
                      defaultInputValue={
                        updateProgramCourseAssessmentData?.examCategoryUid?.name
                      }
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Maximum Score/Contribution</label>
                    <Input
                      type="number"
                      name="maximumScore"
                      className="form-control"
                      id="maximumScore"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.maximumScore || ""}
                      invalid={
                        !!(
                          validation.touched.maximumScore &&
                          validation.errors.maximumScore
                        )
                      }
                    />
                    {validation.touched.maximumScore &&
                    validation.errors.maximumScore ? (
                      <FormFeedback type="invalid">
                        {validation.errors.maximumScore}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Total Number of Exams</label>
                    <Input
                      type="number"
                      name="minimumExams"
                      className="form-control"
                      id="minimumExams"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.minimumExams || ""}
                      invalid={
                        !!(
                          validation.touched.minimumExams &&
                          validation.errors.minimumExams
                        )
                      }
                    />
                    {validation.touched.minimumExams &&
                    validation.errors.minimumExams ? (
                      <FormFeedback type="invalid">
                        {validation.errors.minimumExams}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              {/* <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Can Exceed By</label>
                    <Input
                      type="number"
                      name="canExceedMinimumBy"
                      className="form-control"
                      id="canExceedMinimumBy"
                      min={0}
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.canExceedMinimumBy || ""}
                      invalid={
                        !!(
                          validation.touched.canExceedMinimumBy &&
                          validation.errors.canExceedMinimumBy
                        )
                      }
                    />
                    {validation.touched.canExceedMinimumBy &&
                    validation.errors.canExceedMinimumBy ? (
                      <FormFeedback type="invalid">
                        {validation.errors.canExceedMinimumBy}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row> */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdatedProgramCourseAssessmentData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ProgramCourseAssessmentModal
