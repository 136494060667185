import { gql } from "@apollo/client"

const CREATE_PROGRAM_COURSE_ASSESSMENT = gql`
mutation RegisterProgramCourseAssessment($inputs: [ProgramCourseAssessmentInput!]!) {
  registerProgramCourseAssessment (inputs: $inputs) {
  message
  status
  code
  data{
    items{
       uid,
      programCourse {
        uid
      }
      examCategory {
        uid
        name
        code
      }
      ,
      minimumExams,
      canExceedMinimumBy,
      maximumScore
    }
    totalCount
  }
}
}
`

const DELETE_PROGRAM_COURSE_ASSESSMENT = gql`
mutation removeAssessment($uid: String!) {
  removeProgramCourseAssessment(uid: $uid) {
    message
    code
    status
  }
}
`

export { CREATE_PROGRAM_COURSE_ASSESSMENT, DELETE_PROGRAM_COURSE_ASSESSMENT }