import jsPDF from "jspdf"
import "jspdf-autotable"
import showToast from "helpers/ToastHelper"

const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"

export const ApplicationRankingPDF = (tableDataResponse, title, programName, applicationType = "D") => {
  try {
    const doc = new jsPDF({
      orientation: "landscape"
    })
    const pageWidth = doc.internal.pageSize.getWidth()

    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 150, 20, { align: "center" })

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 150, 25, {
      align: "center"
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 150, 30, {
      align: "center"
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      150,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 150, 40, {
      align: "center"
    })

    doc.setLineWidth(0.5)
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text(title, 150, 50, {
      align: "center"
    })
    doc.line(15, 52, pageWidth - 15, 52) // Draw a line across the page width

    const textAboveTableHeight = 82 // Adjust based on your actual text height

    let header = null

    if (applicationType === "D") {
      header = [
        ["S/No",
          "Firstname",
          "MidName",
          "Surname",
          "S",
          "Index No.",
          "Points",
          "CSEE",
          "ACSEE",
          "Cat",
          "Status",
          "Reason"
        ]]
    } else {
      header = [
        ["S/No",
          "Firstname",
          "MidName",
          "Surname",
          "S",
          "Index No.",
          "Points",
          "CSEE",
          "Diploma",
          "Cat",
          "Status",
          "Reason"
        ]]
    }


    doc.autoTable({
      startY: 60,
      margin: { top: 5, left: 5, right: 5 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
      bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
      head: header,
      body: tableDataResponse,
      didDrawPage: (data) => {
        // Add page number at the bottom center
        const pageCount = doc.internal.getNumberOfPages()
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber
        const pageText = `Page ${pageNumber} of ${pageCount}`
        doc.setFontSize(10)
        const textWidth = doc.getTextWidth(pageText)
        doc.text(pageText, (pageWidth - textWidth) / 2, doc.internal.pageSize.getHeight() - 10)
      },
      columnStyles: applicationType === "D" ? {
        11: { cellWidth: 20 }, // 7th index corresponds to "form four index"
        5: { cellWidth: 35 }, // 7th index corresponds to "form four index"
        7: { cellWidth: 50 }, // 7th index corresponds to "CSEE"
        8: { cellWidth: 60 } // 8th index corresponds to "ACSEE"
      } : {
        11: { cellWidth: 20 }, // 7th index corresponds to "form four index"
        5: { cellWidth: 35 }, // 7th index corresponds to "form four index"
        7: { cellWidth: 50 } // 7th index corresponds to "CSEE"
      }
    })

    doc.save(replaceSpacesWithUnderscores(programName) + ".pdf")
  } catch (error) {
    showToast("Something went wrong, try again later! " + error, "info")
  }
}


export const ApplicantCountByProgramPDF = (tableDataResponse) => {
  try {
    const doc = new jsPDF({
      orientation: "landscape"
    })
    const pageWidth = doc.internal.pageSize.getWidth()

    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 150, 20, { align: "center" })

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 150, 25, {
      align: "center"
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 150, 30, {
      align: "center"
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      150,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 150, 40, {
      align: "center"
    })

    doc.setLineWidth(0.5)
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text("SELECTED APPLICANT COUNT BY PROGRAM 2024/2025", 150, 50, {
      align: "center"
    })
    doc.line(15, 52, pageWidth - 15, 52) // Draw a line across the page width

    const textAboveTableHeight = 82 // Adjust based on your actual text height

    let header = [
      ["S/No",
        "Program name",
        "Male",
        "Female",
        "Total"
      ]]

    let totalMale = 0;
    let totalFemale = 0;
    let grandTotal = 0;

    const combinedData = tableDataResponse;

// Calculate totals
    combinedData.forEach(row => {
      totalMale += row[2];    // Summing up the Male column
      totalFemale += row[3];  // Summing up the Female column
      grandTotal += row[4];   // Summing up the Total column
    });

// Create the total row with "-" in the "Program name" column
    const totalRow = ["TOTAL", "-", totalMale, totalFemale, grandTotal];

// Add the total row to the combined data
    combinedData.push(totalRow);

    doc.autoTable({
      startY: 60,
      margin: { top: 5, left: 5, right: 5 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
      bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
      head: header,
      body: combinedData,
      didDrawPage: () => {
        // Add page number at the bottom center
        const pageCount = doc.internal.getNumberOfPages();
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
        const pageText = `Page ${pageNumber} of ${pageCount}`;
        doc.setFontSize(10);
        const textWidth = doc.getTextWidth(pageText);
        doc.text(pageText, (doc.internal.pageSize.getWidth() - textWidth) / 2, doc.internal.pageSize.getHeight() - 10);
      }
    });
    doc.save("Application_report.pdf")
  } catch (error) {
    showToast("Something went wrong, try again later! " + error, "info")
  }
}


function replaceSpacesWithUnderscores(inputString) {
  return inputString.replace(/ /g, "_")
}