//REGISTE
import { gql } from '@apollo/client';


const GET_DISABILITIES = gql`
  query GetDisabilities($pagination: PaginationInput!) {
    getDisabilities(pagination: $pagination){
      status
      code
      message
      data {
        items {
          id
          uid
          name
      }
      totalCount
      }
    }
  }
`;

export { GET_DISABILITIES }