import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_USER } from "../Queries/UserQuery"
import { UPDATE_PROFILE } from "../Mutations/UserMutations"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StaffProfileContext } from "./StaffProfile"
import { GET_STAFF_TITLE } from "Modules/Lookups/StaffTitle/Queries/StaffTitleQueries"
import { GET_MARITAL_STATUSES } from "Modules/MaritalStatus/Queries/MaritalStatusesQueries"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { GET_DISABILITIES } from "Modules/Lookups/Dissability/Queries/DisabilityQueries"
import SpouseList from "./Spouse/SpouseList"
import { token } from "helpers/UrlHelper"

const PersonalInformation = ({ validation }) => {
  const { updateStaffData } =
    useContext(StaffProfileContext)

  const { toggleTab } = useContext(StaffProfileContext)

  const [Staff] = useMutation(UPDATE_PROFILE)

  const disability = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]

  const isTwoFactor = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ]

  const [hasDisabilities, setisDisabled] = useState([])
  const [married, setMarried] = useState()
  const [minDate, setMinDate] = useState(null)
  const [hasDisability, setHasDisabilities] = useState(updateStaffData ? updateStaffData.isDisabled : false);

  useEffect(() => {
    setisDisabled(disability);
    setHasDisabilities(updateStaffData ? updateStaffData.isDisabled : false);

    const today = new Date()
    const minDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    )
    setMinDate(minDate)
  }, [updateStaffData])

  const {
    loading: titleLoading,
    error: titleError,
    data: titleData,
  } = useQuery(GET_STAFF_TITLE, {
    skip: !updateStaffData,
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const {
    loading: maritalLoading,
    error: maritalError,
    data: maritalData,
  } = useQuery(GET_MARITAL_STATUSES, {
    skip: !updateStaffData,
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const {
    loading: disabilityLoading,
    error: disabilityError,
    data: disabilityData,
  } = useQuery(GET_DISABILITIES, {
    skip: !updateStaffData,
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const titles = titleData?.getTitles?.data?.items
  const maritalStatus = maritalData?.getMaritalStatuses?.data?.items
  const disabilities = disabilityData?.getDisabilities?.data?.items

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  // Function to format date as YYYY-MM-DD
  const formatDate = (dateStr) => {
    if (!dateStr) return "";
    const date = new Date(dateStr);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
    return formattedDate;
  };
 
  validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: updateStaffData ? updateStaffData.firstName : "",
      lastName: updateStaffData ? updateStaffData.lastName : "",
      phoneNumber: updateStaffData ? updateStaffData.phoneNumber : "",
      middleName: updateStaffData ? updateStaffData.middleName : "",
      titleUid: updateStaffData ? updateStaffData.title?.uid : "",
      placeOfBirth: updateStaffData ? updateStaffData.placeOfBirth : "",
      dateOfBirth: updateStaffData ? updateStaffData.dateOfBirth
        ? formatDate(updateStaffData.dateOfBirth)
        : "" : '',
      isTwoFactorEnabled: updateStaffData ? updateStaffData.isTwoFactorEnabled : "",
      maritalStatusUid: updateStaffData ? updateStaffData.maritalStatus?.uid : "",
      disability: updateStaffData ? updateStaffData.userDisabilities : [],
      isDisabled: updateStaffData ? updateStaffData.isDisabled : false,
    },
  

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      titleUid: Yup.string().required("Title is required"),
      lastName: Yup.string().required("Last name is required"),
      isDisabled: Yup.string().required("Field is required"),
      placeOfBirth: Yup.string().required("Place of birth is required"),
      dateOfBirth: Yup.string().required("Date of birth is required"),
      maritalStatusUid: Yup.string().required("Marital status is required"),
      isDisabled: Yup.string().required("Filed disability is required"),
      isTwoFactorEnabled: Yup.string().required("Filed is required"),
      phoneNumber: Yup.string()
        .min(10)
        .max(13)
        .required("Please Enter Valid Phone Number"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      const userUpdate = {
        phoneNumber: values.phoneNumber,
        placeOfBirth: values.placeOfBirth,
        dateOfBirth: values.dateOfBirth,
        maritalStatusUid: values.maritalStatusUid,
        titleUid: values.titleUid,
        disability: values.disability,
        isDisabled: values.isDisabled,
        isTwoFactorEnabled: values.isTwoFactorEnabled,
      }
      Staff({
        client: uaaGraphQLClient,
        variables: {
          input: { ...userUpdate, uid: updateStaffData?.uid },
        },
        refetchQueries: [
          {
            client: uaaGraphQLClient,
            skip: !token?.username,
            query: GET_USER,
            variables: {
              username: token?.username,
            },
          },
        ],

        onCompleted: ({ updateUserProfile }) => {
          //updateFunction(client.cache, { data: { updateUserProfiles } });
          if (updateUserProfile.code === 8000) {
            toggleTab(2)
            setSubmitting(false)
          } else {
            setSubmitting(false)
            console.log("Failed to update data")
          }
          showToast(
            updateUserProfile.message,
            updateUserProfile.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="mt-3 wizard">
      {updateStaffData && (
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="modal-body">
          <Row>
            <Col md="2" lg="2">
              <div className="mb-3">
                <label>Select title</label>
                <Select
                  onChange={e => {
                    validation.setFieldValue("titleUid", e.value)
                  }}
                  options={titles?.map(t => ({
                    value: t.uid,
                    label: t.name,
                  }))}
                  className="select2-selection"
                  defaultValue={updateStaffData?.titleUid}
                  defaultInputValue={updateStaffData?.title?.name}
                />
                <Input
                  name="titleUid"
                  placeholder="Select title"
                  type="hidden"
                  rows={2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.titleUid}
                  invalid={
                    !!(
                      validation.touched.titleUid && validation.errors.titleUid
                    )
                  }
                />
                {validation.touched.titleUid && validation.errors.titleUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.titleUid}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="4" lg="4">
              <div className="mb-3">
                <label htmlFor="firstName">First Name:</label>
                <Input
                  type="text"
                  name="firstName"
                  className="form-control"
                  id="firstName"
                  disabled="true"
                  placeholder="Enter First Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName}
                  invalid={
                    !!(
                      validation.touched.firstName &&
                      validation.errors.firstName
                    )
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6" lg="6">
              <div className="mb-3">
                <Label for="middleName">Middle Name</Label>
                <Input
                  type="text"
                  name="middleName"
                  className="form-control"
                  id="middleName"
                  placeholder="Enter middle name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.middleName}
                  invalid={
                    !!(
                      validation.touched.middleName &&
                      validation.errors.middleName
                    )
                  }
                />
                {validation.touched.middleName &&
                validation.errors.middleName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.middleName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="6" md="6">
              <div className="mb-3">
                <label htmlFor="lastName">Last Name:</label>
                <Input
                  type="text"
                  name="lastName"
                  className="form-control"
                  id="lastName"
                  disabled="true"
                  placeholder="Enter Last Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName}
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6" md="6">
              <div className="mb-3">
                <Label for="middlename">Phone Number</Label>
                <Input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  id="phoneNumber"
                  placeholder="Enter Phone Number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber}
                  invalid={
                    !!(
                      validation.touched.phoneNumber &&
                      validation.errors.phoneNumber
                    )
                  }
                />
                {validation.touched.phoneNumber &&
                validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phoneNumber}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" lg="6">
              <div className="mb-3">
                <label htmlFor="placeOfBirth">Place of Birth:</label>
                <Input
                  type="text"
                  name="placeOfBirth"
                  className="form-control"
                  id="placeOfBirth"
                  placeholder="Enter place of birth"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.placeOfBirth}
                  invalid={
                    !!(
                      validation.touched.placeOfBirth &&
                      validation.errors.placeOfBirth
                    )
                  }
                />
                {validation.touched.placeOfBirth &&
                validation.errors.placeOfBirth ? (
                  <FormFeedback type="invalid">
                    {validation.errors.placeOfBirth}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg="6" md="6">
              <div className="mb-3">
                <Label>Date Of Birth</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    // name="dob"
                    placeholder="dd M,yyyy"

                    options={{
                      maxDate: minDate,
                      altInput: true,
                      dateFormat: "Y-m-d",
                      enableTime: false,
                      time_24hr: true,
                      closeOnSelect: true,
                    }}
                    onChange={date => {
                      
                      const selectedDate = date[0] // Get the selected date object
                      const year = selectedDate.getFullYear()
                      const month = selectedDate.getMonth() + 1 // Months are 0-indexed
                      const day = selectedDate.getDate()

                      const formattedDate = `${year}-${
                        month < 10 ? "0" : ""
                      }${month}-${day < 10 ? "0" : ""}${day}`

                      validation.setFieldValue("dateOfBirth", formattedDate)
                    }}
                  />
                   {validation.touched.dateOfBirth && validation.errors.dateOfBirth ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dateOfBirth}
                  </FormFeedback>
                ) : null}
                </InputGroup>
                {/* <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    // name="dateOfBirth"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      minDate: minDate,
                      maxDate: "today",
                    }}
                    defaultValue={validation.values.dateOfBirth}
                    onChange={changedateOfBirth}
                  />
                </InputGroup> */}
                {/* <Input
                  name="dateOfBirth"
                  placeholder="Enter date of birth"
                  type="hidden"
                  rows={3}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dateOfBirth}
                  invalid={
                    !!(
                      validation.touched.dateOfBirth &&
                      validation.errors.dateOfBirth
                    )
                  }
                />
                {validation.touched.dateOfBirth &&
                validation.errors.dateOfBirth ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dateOfBirth}
                  </FormFeedback>
                ) : null} */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="6" lg="6">
              <Row>
                <Col
                  md={validation.values.isDisabled === true ? 4 : 12}
                  lg={validation.values.isDisabled === true ? 4 : 12}
                >
                  <div className="mb-3">
                    <label>Any disability?</label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("isDisabled", e.value)
                        setHasDisabilities(e.value);
                      }}
                      options={hasDisabilities}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={hasDisability}
                      defaultInputValue={updateStaffData?.disabilities?.name}
                    />
                    <Input
                      name="isDisabled"
                      placeholder=""
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.uid}
                    />
                    {validation.touched.isDisabled &&
                    validation.errors.isDisabled ? (
                      <FormFeedback type="invalid">
                        {validation.errors.isDisabled}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                {validation.values.isDisabled && (
                  <Col md="8" lg="8">
                    <div className="mb-3">
                      <label>Select Disability</label>
                      <Select
                        onChange={selectedOptions => {
                          const selectedValues = selectedOptions.map(
                            option => ({
                              disabilityUid: option.value,
                            })
                          )
                          validation.setFieldValue("disability", selectedValues)
                        }}
                        options={disabilities?.map(m => ({
                          value: m.uid,
                          label: m.name,
                        }))}
                        isMulti={true} // Enable multiple selections
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            position: "absolute",
                            zIndex: 9999,
                          }),
                        }}
                      />

                      <Input
                        name="disability" // Update the field name
                        placeholder="Select Disability Status"
                        type="hidden"
                        rows={2}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={JSON.stringify(validation.values.disability)} // Set the value as a JSON string
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col md="6" lg="6">
              <Row>
                <Col md="6" ld="6">
                  <div className="mb-3">
                    <label>Select Marital Status</label>
                    <Select
                      onChange={e => {
                        setMarried(e.label)
                        validation.setFieldValue("maritalStatusUid", e.value)
                      }}
                      options={maritalStatus?.map(m => ({
                        value: m.uid,
                        label: m.name,
                      }))}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={updateStaffData?.maritalStatusUid}
                      defaultInputValue={updateStaffData?.maritalStatus?.name}
                    />
                    <Input
                      name="maritalStatusUid"
                      placeholder="Select Marital Status"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.uid}
                      invalid={
                        !!(
                          validation.touched.maritalStatusUid &&
                          validation.errors.maritalStatusUid
                        )
                      }
                    />
                    {validation.touched.maritalStatusUid &&
                    validation.errors.maritalStatusUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.maritaUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" ld="6">
                  <div className="mb-3">
                    <label>Enable Two Factor Authentication?</label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("isTwoFactorEnabled", e.value)
                      }}
                      options={isTwoFactor}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      // defaultValue={updateStaffData?.isTwoFactorEnabled}
                      // defaultInputValue={updateStaffData?.isTwoFactorEnabled}
                    />
                    <Input
                      name="isTwoFactorEnabled"
                      placeholder=""
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.isTwoFactorEnabled}
                    />
                    {validation.touched.isDisabled &&
                    validation.errors.isDisabled ? (
                      <FormFeedback type="invalid">
                        {validation.errors.isDisabled}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {married === "Married" && (
            <Row>
              <Col md="12" lg="12">
                <SpouseList />
              </Col>
            </Row>
          )}
        </div>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="next">
                <SubmitButtonHelper
                  type="primary"
                  name="Submit & Stay here?.."
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
      )}
    </div>
  )
}
export default PersonalInformation
