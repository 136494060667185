import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_MEDIA_COVERAGE } from "../Mutations/MediaCoverageMutations"
import { GET_MEDIA_COVERAGES } from "../Queries/MediaCoverageQueries"
import { MediaCoverageContext } from "./MediaCoverage"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import Select from "react-select"

const MediaCoverageModal = () => {
  const { updateMediaCoverageData, setUpdateMediaCoverageData, staffUid } =
    useContext(MediaCoverageContext)

  const CoverageLevel = [
    { label: "Community", value: "Community" },
    { label: "Academic", value: "Academic" },
  ]

  const AudienceType = [
    { label: "Regional", value: "Regional" },
    { label: "National", value: "National" },
    { label: "International", value: "International" },
  ]

  const [MediaCoverage] = useMutation(ADD_UPDATE_MEDIA_COVERAGE)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_MEDIA_COVERAGES
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      mediaName: updateMediaCoverageData
        ? updateMediaCoverageData.mediaName
        : "",
      link: updateMediaCoverageData ? updateMediaCoverageData.link : "",
      uid: updateMediaCoverageData ? updateMediaCoverageData.uid : "",
      coverageLevel: updateMediaCoverageData
        ? updateMediaCoverageData.coverageLevel
        : "",
      audienceType: updateMediaCoverageData
        ? updateMediaCoverageData.audienceType
        : "",
      startDate: updateMediaCoverageData
        ? moment(updateMediaCoverageData.startDate).format("YYYY-MM-DD")
        : "",
      endDate: updateMediaCoverageData
        ? moment(updateMediaCoverageData.endDate).format("YYYY-MM-DD")
        : "",
    },

    validationSchema: Yup.object().shape({
      mediaName: Yup.string().required("Media Name is Required"),
      link: Yup.string().required("Link is required"),
      coverageLevel: Yup.string().required("Coverage Level is required"),
      audienceType: Yup.string().required("Audience Type Level is required"),
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date().required("End Date is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      MediaCoverage({
        client: asimsGraphQLClient,
        variables: {
          input: {
            ...values,
            uid: updateMediaCoverageData?.uid,
            startDate: new Date(values.startDate).toLocaleDateString("en-US"),
            endDate: new Date(values.endDate).toLocaleDateString("en-US"),
          },
        },
        refetchQueries: [
          {
            query: GET_MEDIA_COVERAGES,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerMediaCoverage }) => {
          console.log("Register Media Coverage: ", registerMediaCoverage)
          validation.resetForm()
          setModalOpen(false)
          setUpdateMediaCoverageData(false) // Close the modal
          if (registerMediaCoverage.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateMediaCoverageData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerMediaCoverage.message,
            registerMediaCoverage.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Media Coverage
      </Button>

      <Modal
        isOpen={modalOpen || !!updateMediaCoverageData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateMediaCoverageData
              ? "Edit Register Media Coverage"
              : "Add Register Media Coverage"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateMediaCoverageData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="mediaName" className="col-form-label">
                      Media Name:
                    </label>
                    <Input
                      type="text"
                      name="mediaName"
                      className="form-control"
                      id="mediaName"
                      placeholder="Media Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.mediaName || ""}
                      invalid={
                        !!(
                          validation.touched.mediaName &&
                          validation.errors.mediaName
                        )
                      }
                    />
                    {validation.touched.mediaName &&
                    validation.errors.mediaName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.mediaName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="link" className="col-form-label">
                      Link:
                    </label>
                    <Input
                      type="text"
                      name="link"
                      className="form-control"
                      id="link"
                      placeholder="Enter Link"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.link ||
                        updateMediaCoverageData?.link ||
                        ""
                      }
                      invalid={
                        !!(validation.touched.link && validation.errors.link)
                      }
                    />
                    {validation.touched.link && validation.errors.link ? (
                      <FormFeedback type="invalid">
                        {validation.errors.link}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="startDate" className="col-form-label">
                      Start Date:
                    </label>
                    <Input
                      type="date"
                      name="startDate"
                      className="form-control"
                      id="startDate"
                      placeholder="Enter Start Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      invalid={
                        !!(
                          validation.touched.startDate &&
                          validation.errors.startDate
                        )
                      }
                    />
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="endDate" className="col-form-label">
                      End Date:
                    </label>
                    <Input
                      type="date"
                      name="endDate"
                      className="form-control"
                      id="endDate"
                      placeholder="Enter End Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      invalid={
                        !!(
                          validation.touched.endDate &&
                          validation.errors.endDate
                        )
                      }
                    />
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Coverage Level:</label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("coverageLevel", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={updateMediaCoverageData?.coverageLevel}
                      defaultInputValue={updateMediaCoverageData?.coverageLevel}
                      options={CoverageLevel}
                      className="select2-selection"
                    />
                    <Input
                      name="coverageLevel"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.coverageLevel || ""}
                      invalid={
                        !!(
                          validation.touched.coverageLevel &&
                          validation.errors.coverageLevel
                        )
                      }
                    />
                    {validation.touched.coverageLevel &&
                    validation.errors.coverageLevel ? (
                      <FormFeedback type="invalid">
                        {validation.errors.coverageLevel}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Audience Type:</label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("audienceType", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={updateMediaCoverageData?.audienceType}
                      defaultInputValue={updateMediaCoverageData?.audienceType}
                      options={AudienceType}
                      className="select2-selection"
                    />
                    <Input
                      name="audienceType"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.audienceType || ""}
                      invalid={
                        !!(
                          validation.touched.audienceType &&
                          validation.errors.audienceType
                        )
                      }
                    />
                    {validation.touched.audienceType &&
                    validation.errors.audienceType ? (
                      <FormFeedback type="invalid">
                        {validation.errors.audienceType}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateMediaCoverageData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default MediaCoverageModal
