import React, {useContext} from "react";
import {Col, Form, Input, Label, Modal, Row} from "reactstrap";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import PropTypes from "prop-types";
import {EntryQualificationConext} from "../EntryQualification";

const CertificateResultConfirmation = (props) => {
    const {formik} = props
    const {openCertificateResultModel, setOpenCertificateResultModel, user} = useContext(EntryQualificationConext)

    // console.log(formik.values.results)
    return (
        <div className="text-sm-end mt-3">
            <Modal
                size="lg"
                isOpen={openCertificateResultModel}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Add Certificate Result Information
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setOpenCertificateResultModel(false)
                            // formik.restore()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        formik.handleSubmit()
                        return null
                    }}
                    encType="multipart/form-data" >
                    <div className="modal-body">
                        <div className="mb-3">
                            <Row>
                                <input type="hidden" name={"certificateTypeCode"} value={'CERT'}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}/>


                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <Label>Institution Name: <strong className="text-danger">*</strong></Label>

                                        <Input
                                            type="text"
                                            name="institutionName"
                                            className="form-control"
                                            id="institutionName"
                                            placeholder="Enter Institution Name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.institutionName || ""}
                                            invalid={
                                                !!(formik.touched.institutionName && formik.errors.institutionName)
                                            }
                                        />
                                        {formik.touched.institutionName && formik.errors.institutionName ? (
                                            <small
                                                className="text-danger"><b>{formik.errors.institutionName}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <Label>Institution Program: <strong className="text-danger">*</strong></Label>

                                        <Input
                                            type="text"
                                            name="institutionProgram"
                                            className="form-control"
                                            id="institutionProgram"
                                            placeholder="Enter Institution Program"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.institutionProgram || ""}
                                            invalid={
                                                !!(formik.touched.institutionProgram && formik.errors.institutionProgram)
                                            }
                                        />
                                        {formik.touched.institutionProgram && formik.errors.institutionProgram ? (
                                            <small className="text-danger"><b>{formik.errors.institutionProgram}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className="mb-3">
                                        <Label>Registration Number: <strong className="text-danger">*</strong></Label>
                                        <Input
                                            type="text"
                                            name="registrationNumber"
                                            className="form-control"
                                            id="registrationNumber"
                                            placeholder="Registration Number"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.registrationNumber}
                                            invalid={
                                                !!(formik.touched.registrationNumber && formik.errors.registrationNumber)
                                            }
                                        />
                                        {formik.touched.registrationNumber && formik.errors.registrationNumber ? (
                                            <small
                                                className="text-danger"><b>{formik.errors.registrationNumber}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className="mb-3">
                                        <Label>Completion Year: <strong className="text-danger">*</strong></Label>
                                        <Input
                                            type="number"
                                            name="completionYear"
                                            className="form-control"
                                            id="completionYear"
                                            min={1999}
                                            placeholder="Enter Completion Year"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.completionYear || ""}
                                            invalid={
                                                !!(formik.touched.completionYear && formik.errors.completionYear)
                                            }
                                        />
                                        {formik.touched.completionYear && formik.errors.completionYear ? (
                                            <small
                                                className="text-danger"><b>{formik.errors.completionYear}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className="mb-3">
                                        <Label>GPA: <strong className="text-danger">*</strong></Label>
                                        <Input
                                            type="number"
                                            step={0.1}
                                            name="gpa"
                                            className="form-control"
                                            id="gpa"
                                            min={2}
                                            max={5}
                                            placeholder="Enter GPA"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.gpa || ""}
                                            invalid={
                                                !!(formik.touched.gpa && formik.errors.gpa)
                                            }
                                        />
                                        {formik.touched.gpa && formik.errors.gpa ? (
                                            <small
                                                className="text-danger"><b>{formik.errors.gpa}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mb-3">
                                        <Label>Upload Certificate: <strong className="text-danger">*</strong></Label>

                                        <input
                                            id="certificateAttachment"
                                            className="form-control"
                                            name="certificateAttachment"
                                            // type="number"
                                            accept="application/pdf"
                                            type="file"
                                            onBlur={formik.handleBlur}
                                            onChange={(e) => {
                                                if (e.target.files && e.target.files.length > 0) {
                                                    const file = e.target.files[0];
                                                    const reader = new FileReader();

                                                    reader.onloadend = () => {
                                                        const base64String = reader.result;
                                                        formik.setFieldValue("certificateAttachment", base64String);
                                                    };

                                                    reader.readAsDataURL(file);
                                                }
                                            }}
                                        />
                                        {formik.touched.certificateAttachment && formik.errors.certificateAttachment ? (
                                            <small
                                                className="text-danger"><b>{formik.errors.certificateAttachment}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>

                        </div>


                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setOpenCertificateResultModel(false)
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={formik}/>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

CertificateResultConfirmation.propTypes = {
    formik: PropTypes.any.isRequired
}

export default CertificateResultConfirmation
