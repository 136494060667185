//REGISTE
import { gql } from '@apollo/client';

const GET_EXAM_CATEGORIES = gql`
query GetExamCategory  {
  getExamCategories {
     status
     code
     message
     data {
       uid
       name
       code
     }
   }  
 }
`
;

export { GET_EXAM_CATEGORIES }