import React, { createContext, useEffect, useState } from "react"
import { 
  Row, 
  Col, 
  Card,
  CardBody,
  CardSubtitle
} from "reactstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_PROGRAM_COURSE_ASSESSMENT, GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE } from "../Queries/ProgramCourseAssessmentQueries"
import { GET_PROGRAM_COURSE_ASSESSMENT_BY_COURSE } from "../Queries/ProgramCourseAssessmentQueries"
import { DELETE_PROGRAM_COURSE_ASSESSMENT } from "../Mutations/ProgramCourseAssessmentMutations"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import ProgramCourseAssessmentModal from "./ProgramCourseAssessmentModal"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import StaffCourseAllocationList from "Modules/ProgramSemister/Views/StaffCourseAllocationList";
import DropDownActionHelper from "helpers/DropDownActionHelper";
import ProgramActionHelper from "helpers/ProgramHelper";
import ProgramCoureAssessmentModal_1 from "./ProgramCoureAssessmentModal_1"
import { hasAuthority } from "../../../helpers/OtherHelpers"


const breadcrumbItem = [
  {
    title: "Program Semester",
    path: "/examination/program-semister",
    isActive: false,
  },
]



export const ProgramCourseAssessmentContext = createContext() //Create data to transfer to child modal

const ProgramCourseAssessmentList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedProgramCourseAssessment, setSelectedProgramCourseAssessment] = useState(null) //Get data for deleting
  const [updateProgramCourseAssessmentData, setUpdatedProgramCourseAssessmentData] = useState(null) //Get data for editing

  const location = useLocation();
  const navigate = useNavigate();
  
 if(!location.state?.resultRow) navigate('/program-semister')
 let { resultRow } = location.state; 

  
  
  const { loading, error, data, refetch } = useQuery(GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE, {
    client: registrationGraphQLClient,
    variables : {
        programCourseUid: resultRow?.uid,
    },
    //fetchPolicy: "cache-first",
  })

  const [
    searchProgramCourse,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE, {
    client: registrationGraphQLClient,
    variables : {
        programCourseUid: resultRow?.uid,
    },
  })

  useEffect(() => {
    if (!loading && data) {
      refetch();
      clearSearch(); // Clear the search query after refetching the data

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteProgramCourse] = useMutation(DELETE_PROGRAM_COURSE_ASSESSMENT, {
    client: registrationGraphQLClient,
    variables: { uid: selectedProgramCourseAssessment?.uid },
    refetchQueries: [
      {
        query: GET_PROGRAM_COURSE_ASSESSMENT_BY_PROGRAM_COURSE,
        variables : {
            programCourseUid: resultRow?.uid,
        },
      },
    ],
  })

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = staff => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [staff.uid]: !prevState[staff.uid],
    }))
  }

  const handledeleteProgramCourse = (assessment) => {
    setSelectedProgramCourseAssessment(assessment)
    showDeleteWarning(() => {
      performDataDeletion()
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteProgramCourse()
      showToast(
         response.data.removeProgramCourseAssessment.message, 
         response.data.removeProgramCourseAssessment.code === 8000 ? "success" : "error" )
    } catch (error) {
      showToast("Failed to delete", "error")
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchProgramCourse({
      variables: { pagination: { ...pagination, ...data } },
    })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }


  const programCourse = data?.getProgramCourseAssessmentByProgramCourseUid.data?.items || searchData?.getProgramCourseAssessmentByProgramCourseUid?.data?.items || []
  const searchedProgramCourse = searchData?.getProgramCourseAssessmentByProgramCourseUid?.data?.items
  const result = searchedProgramCourse ? searchedProgramCourse : programCourse
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = 10
  // const totalEntries = data?.getProgramCourseAssessments.data.totalCount || searchData?.getProgramCourseAssessments?.data?.items || 0  
  let contribution = 0; // Initialize contribution variable

  return (
    <ProgramCourseAssessmentContext.Provider
      value={{ 
        updateProgramCourseAssessmentData, 
        setUpdatedProgramCourseAssessmentData,
        resultRow 
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle={"Course Assessments: " + resultRow?.course?.name }
      >
        <Row>
        <Col lg={4}>
                    <Card>
                        <CardBody>
                            {/* <div className="table-responsive"> */}
                            <Table
                                id="tech-companies-1"
                                className="table "
                            >
                                    <tbody>
                                      <tr>
                                          <th>Programme: </th>
                                          <td>{`${resultRow?.programSemester?.program?.name}`}</td>
                                      </tr>
                                      <tr>
                                          <th>Academic Year:</th>
                                          <td>{resultRow?.programSemester?.academicYear?.name}</td>
                                      </tr>
                                      <tr>
                                          <th>Study Year:</th>
                                          <td>{resultRow?.programSemester?.studyYear}</td>
                                      </tr>
                                      <tr>
                                          <th>Semester:</th>
                                          <td>{resultRow?.programSemester?.semester}</td>
                                      </tr>
                                      <tr>
                                          <th>Course Ante:</th>
                                          <td>{resultRow?.course?.code}</td>
                                      </tr>                                    
                                      <tr>
                                          <th>Course:</th>
                                          <td>{resultRow?.course?.name}</td>
                                      </tr>
                                      <tr>
                                          <th>Credits:</th>
                                          <td>{resultRow?.credit}</td>
                                      </tr> 
                                    </tbody>                                   
                                  </Table>
                            {/* </div> */}
                        </CardBody>
                    </Card>
                </Col>
          <Col lg={8}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Searching..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <ProgramCourseAssessmentModal />
                      {/*<ProgramCoureAssessmentModal_1 />*/}
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !searchLoading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                          <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Assessment</Th>
                            <Th data-priority="1">Number of Examinations</Th>
                            {/* <Th data-priority="1">Can Exceed By</Th> */}
                            <Th data-priority="1">Contribution</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>

                        {result
                          .slice(startIndex, endIndex)
                          .map((resultRows, index) => {
                             if (resultRows?.programCourse?.uid===resultRow?.uid) {
                              contribution += resultRows?.maximumScore; // Accumulate the maximumScore values
                            return (
                              <Tr key={resultRows?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{resultRows?.examCategory?.name}</Td>
                                <Td>{resultRows?.minimumExams}</Td>
                                {/* <Td>{resultRows?.canExceedMinimumBy}</Td> */}
                                <Td>{resultRows?.maximumScore}</Td>
                                <Td>
                                  <ProgramActionHelper
                                    data={resultRows}
                                    onUpdate={hasAuthority('REMOVE_PROGRAM_COURSE_ASSESSMENT') && setUpdatedProgramCourseAssessmentData}
                                    onDelete={hasAuthority('REMOVE_PROGRAM_COURSE_ASSESSMENT') && handledeleteProgramCourse}
                                  />
                                </Td>
                              </Tr>
                            )
                             }
                          })}
                          {/* Display the contribution value at the end of the table */}
                          <Tr>
                            {/* <Td colSpan={2} style={{ textAlign:'right', verticalAlign: 'middle' }}><b></b></Td> */}
                            <Td colSpan={5} style={{ textAlign:'center', verticalAlign: 'middle' }}>
                            {/* <div className="progress">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div> */}
                              {(contribution && contribution<100) || contribution == 0 ? (
                                    <p>
                                      <div className="alert alert-danger alert-dismissible show mb-0" role="alert">
                                                                <i className="mdi mdi-alert-circle-outline me-2"></i>
                                                                Total Contribution: <b>{contribution}%</b>  (Contribution is less than 100%)
                                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                      </div>
                                    </p>
                              ) : (
                                  <p>
                                  <div className="alert alert-success alert-dismissible show mb-0" role="alert">
                                                          <i className="mdi mdi-alert-circle-outline me-2"></i>
                                                          Total Contribution: <b>{contribution}%</b>
                                                          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                  </div></p>
                              )
                              }
                              </Td> 
                          </Tr>
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      <StaffCourseAllocationList/>
      </LayoutHelper>
    </ProgramCourseAssessmentContext.Provider>
  )
}

export default ProgramCourseAssessmentList
