import React from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

const DropDownActionHelper = ({ data, onUpdate, onDelete }) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState)
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle color="" caret>
        <i className="fas fa-ellipsis-v" style={{ color: "green" }} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onUpdate(data)}>
          <i
            className="fas fa-pen"
            style={{ color: "green", marginRight: "10px" }}
          />
          <span>Edit</span>
        </DropdownItem>
        <DropdownItem onClick={() => onDelete(data)}>
          <i
            className="fas fa-trash"
            style={{ color: "red", marginRight: "10px" }}
          />
          <span>Delete</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropDownActionHelper
