// sweetAlertHelper.js
import Swal from 'sweetalert2';

// type can be (success, error, warning, info)
const SweetAlertHelper = (message, type = 'success') => {
  Swal.fire({
    title: 'Alert!',
    text: message,
    icon: type,
    confirmButtonText: 'OK',
  });
};

export default SweetAlertHelper;
