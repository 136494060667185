import React, { createContext, useEffect, useState } from "react"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { Button, Card, CardBody, CardSubtitle, Col, Row } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import ProgramActionHelper from "helpers/ProgramHelper"
import { GET_COURSE_ALLOCATION } from "../Queries/ProgramSemisterQueries"
import { GET_COURSE_ALLOCATION_BY_COURSE } from "../Queries/CourseAllocationQueries"
import { DELETE_COURSE_ALLOCATION } from "../Mutations/CourseAllocationMutations"
import {Link, useLocation, useNavigate} from "react-router-dom";
import { registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import showToast from "helpers/ToastHelper"
import { GET_USER_BY_UID, GET_STAFF }      from "Modules/Users/Queries/UserQuery"
export const StaffCourseAllocationContext = createContext() //Create data to transfer to child modal

const StaffCourseAllocationList = () => {

  const [selectedCourseAllocation, setSelectedCourseAllocation ] = useState(null) // for delete
  const [courseAllocate, setCourseAllocate] = useState(null) //for update

  const location = useLocation();
  const navigate = useNavigate();
   
  if(!location.state?.resultRow) navigate('/program-semister')
  let { resultRow } = location.state; 

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }   

  const { loading, error, data, refetch } = useQuery(GET_COURSE_ALLOCATION_BY_COURSE, {
    client: registrationGraphQLClient,
    variables: {
      programCourseUid: resultRow?.uid,
    },
  })

  const [
    searchProgramCourse,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_COURSE_ALLOCATION_BY_COURSE, {
    client: registrationGraphQLClient,
    variables : {
      programCourseUid: resultRow?.uid,
    }
  })  

  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
  } = useQuery(GET_STAFF, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })  

  let staffArray = staffData?.getStaffs?.data.items


  const [deleteCourseAllocation] = useMutation(DELETE_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: { uid: selectedCourseAllocation?.uid },
    refetchQueries: [
      {
        query: GET_COURSE_ALLOCATION_BY_COURSE,
        variables: {
          programCourseUid: resultRow?.uid,
          pagination: paginationHelper,
        },
      },
    ],
  })
 
  const handledeleteCourseAllocate = (allocating) => {
    setSelectedCourseAllocation(allocating)
    showDeleteWarning(() => {
    performDataDeletion()
  })
}

const performDataDeletion = async () => {
  try {
    const response = await deleteCourseAllocation()
    showToast(response.data.removeCourseAllocation.message, "success")
  } catch (error) {
    showToast("Failed to delete", "error")
  }
}


  const programCourse = data?.getCourseAllocationByProgramCourseUid.data?.items || searchData?.getCourseAllocationByProgramCourseUid?.data?.items || []
  const searchedProgramCourse = searchData?.getCourseAllocationByProgramCourseUid?.data?.items
  const result = searchedProgramCourse ? searchedProgramCourse : programCourse
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getCourseAllocationByProgramCourseUid.data.totalCount || searchData?.getCourseAllocationByProgramCourseUid?.data?.items || 0


  return (
    <StaffCourseAllocationContext.Provider
    value={{ 
      courseAllocate,
      setCourseAllocate
     }}
    >

      <Row>
        <Col lg={4}>
          <Card>
            <CardSubtitle className="mb-1 mt-4 text-center"> Allocated Instructor  
                    <Row>
                        <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                        </Col>
                        <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                            
                        </Col>                   
                    </Row>                      
            </CardSubtitle>
            <CardBody>
              <Table
                id="tech-companies-1"
                className="table-sm table-striped table-bordered"
              >
                <Thead>
                  <Tr>
                    <Th data-priority="1">S/N</Th>
                    <Th>Name</Th>
                    {/* <Th>Course Ante</Th>
                    <Th>Course</Th> */}
                    <Th data-priority="3">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                {result
                  .slice(startIndex, endIndex)
                  .map((resultRows, index) => {
                    let searchStaff = staffArray?.find((row) => row.uid === resultRows?.staffUid);
                    return (
                      <Tr key={resultRows?.uid}>
                        <Td>{startIndex + index + 1}</Td>
                        <Td>
                        {searchStaff ? (
                                `${searchStaff.pfNumber} -- ${searchStaff.user?.firstName} ${searchStaff.user?.middleName} ${searchStaff.user?.lastName}`
                              ) : (
                                'Staff Not Found'
                              )}
                        </Td>
                        {/* <Td>{resultRows?.programCourse?.course?.code}</Td>
                        <Td>{resultRows?.programCourse?.course?.name}</Td> */}
                        <Td>
                          <ProgramActionHelper
                            data={resultRows}
                            // onUpdate={setCourseAllocate}
                            onDelete={handledeleteCourseAllocate}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>                
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      </StaffCourseAllocationContext.Provider>
  )
}

export default StaffCourseAllocationList
