import React, { useContext, useRef, useState } from "react"
import { Button, Col, Form, FormFeedback, Input, Modal, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_ACADEMIC_EVENT } from "../Mutations/AcademicEventsMutations"
import { GET_ACADEMIC_EVENTS } from "../Queries/AcademicEventsQueries"
import { AcademicEventsContext } from "./AcademicEvents"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import moment from "moment"

const AcademicEventsModal = () => {
  const { updateAcademicEventsData, setUpdateAcademicEventsData, staffUid } =
    useContext(AcademicEventsContext)

  const AcademicEventCategory = [
    {
      label: "Conference",
      value: 1,
    },
    {
      label: "Workshop",
      value: 2,
    },
    {
      label: "Seminar",
      value: 3,
    },
    {
      label: "Symposium",
      value: 4,
    },
  ]

  const organizerRole = [
    {
      label: "PRESENTER",
      value: "PRESENTER",
    },
    {
      label: "ATTENDEE",
      value: "ATTENDEE",
    },
    {
      label: "EXHIBITIONIST",
      value: "EXHIBITIONIST",
    },
    {
      label: "PANELIST",
      value: "PANELIST",
    },
    {
      label: "KEYNOTE SPEAKER",
      value: "KEYNOTE_SPEAKER",
    },
    {
      label: "POSTER PRESENTER",
      value: "POSTER_PRESENTER",
    },
  ]

  const [AcademicEvents] = useMutation(ADD_UPDATE_ACADEMIC_EVENT)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_ACADEMIC_EVENTS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      uid: updateAcademicEventsData ? updateAcademicEventsData.uid : "",
      staffUid: updateAcademicEventsData
        ? updateAcademicEventsData.staffUid
        : staffUid,

      institution: updateAcademicEventsData
        ? updateAcademicEventsData.institution
        : "",
      organizer: updateAcademicEventsData
        ? updateAcademicEventsData.organizer
        : "",
      staffRole: updateAcademicEventsData
        ? updateAcademicEventsData.staffRole
        : "",
      categoryId: updateAcademicEventsData
        ? updateAcademicEventsData.categoryId
        : "",
      startDate: updateAcademicEventsData
        ? moment(updateAcademicEventsData.startDate).format("YYYY-MM-DD")
        : "",
      endDate: updateAcademicEventsData
        ? moment(updateAcademicEventsData.endDate).format("YYYY-MM-DD")
        : "",
      titleEvent: updateAcademicEventsData
        ? updateAcademicEventsData.titleEvent
        : "",
      city: updateAcademicEventsData ? updateAcademicEventsData.city : "",
      country: updateAcademicEventsData ? updateAcademicEventsData.country : "",
    },

    validationSchema: Yup.object().shape({
      institution: Yup.string().required("Institution is Required"),
      organizer: Yup.string().required("Organizer Name is required"),
      staffRole: Yup.string().required("Staff Role Name is required"),
      categoryId: Yup.string().required("Category Type is required"),
      startDate: Yup.string().required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
      titleEvent: Yup.string().required("Title Event is required"),
      city: Yup.string().required("city is required"),
      country: Yup.string().required("Country is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      console.log(values)

      if (values.uid === "") {
        delete values.uid
      }

      AcademicEvents({
        client: asimsGraphQLClient,
        variables: {
          input: values,
        },
        refetchQueries: [
          {
            query: GET_ACADEMIC_EVENTS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerAcademicEvents }) => {
          validation.resetForm()
          setModalOpen(false)
          setUpdateAcademicEventsData(false) // Close the modal
          if (registerAcademicEvents.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateAcademicEventsData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerAcademicEvents.message,
            registerAcademicEvents.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error.message)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Academic Event
      </Button>

      <Modal
        isOpen={modalOpen || !!updateAcademicEventsData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-country" id="staticBackdropLabel">
            {!!updateAcademicEventsData
              ? "EDIT ACADEMIC EVENT"
              : "ADD ACADEMIC EVENT"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateAcademicEventsData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div>
              <h5 className="form-details-header">Event Details</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="categoryId" className="col-form-label">
                      Category Type:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("categoryId", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={updateAcademicEventsData?.eventCategory?.id}
                      defaultInputValue={
                        updateAcademicEventsData?.inseventCategorytitutionType
                          ?.name
                      }
                      options={AcademicEventCategory}
                      className="select2-selection"
                    />
                    <Input
                      name="categoryId"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.categoryId || ""}
                      invalid={
                        !!(
                          validation.touched.categoryId &&
                          validation.errors.categoryId
                        )
                      }
                    />
                    {validation.touched.categoryId &&
                    validation.errors.categoryId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.categoryId}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="titleEvent" className="col-form-label">
                      Event Title:
                    </label>
                    <Input
                      type="text"
                      name="titleEvent"
                      className="form-control"
                      id="titleEvent"
                      placeholder="Enter Event Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.titleEvent || ""}
                      invalid={
                        !!(
                          validation.touched.titleEvent &&
                          validation.errors.titleEvent
                        )
                      }
                    />
                    {validation.touched.titleEvent &&
                    validation.errors.titleEvent ? (
                      <FormFeedback type="invalid">
                        {validation.errors.titleEvent}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="staffRole" className="col-form-label">
                      Role Type:
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("staffRole", e.value)
                      }}
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999,
                        }),
                      }}
                      defaultValue={
                        updateAcademicEventsData?.organizerRole?.value
                      }
                      defaultInputValue={
                        updateAcademicEventsData?.organizerRole?.label?.name
                      }
                      options={organizerRole}
                      className="select2-selection"
                    />
                    <Input
                      name="staffRole"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.staffRole || ""}
                      invalid={
                        !!(
                          validation.touched.staffRole &&
                          validation.errors.staffRole
                        )
                      }
                    />
                    {validation.touched.staffRole &&
                    validation.errors.staffRole ? (
                      <FormFeedback type="invalid">
                        {validation.errors.staffRole}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="organizer" className="col-form-label">
                      Organizer Name:
                    </label>
                    <Input
                      type="text"
                      name="organizer"
                      className="form-control"
                      id="organizer"
                      placeholder="Enter Organizer Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.organizer ||
                        updateAcademicEventsData?.organizer ||
                        ""
                      }
                      invalid={
                        !!(
                          validation.touched.organizer &&
                          validation.errors.organizer
                        )
                      }
                    />
                    {validation.touched.organizer &&
                    validation.errors.organizer ? (
                      <FormFeedback type="invalid">
                        {validation.errors.organizer}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="institution" className="col-form-label">
                      Institution:
                    </label>
                    <Input
                      type="text"
                      name="institution"
                      className="form-control"
                      id="institution"
                      placeholder="Enter Institution Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.institution || ""}
                      invalid={
                        !!(
                          validation.touched.institution &&
                          validation.errors.institution
                        )
                      }
                    />
                    {validation.touched.institution &&
                    validation.errors.institution ? (
                      <FormFeedback type="invalid">
                        {validation.errors.institution}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6"></Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Event Period</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="startDate" className="col-form-label">
                      Start Date:
                    </label>
                    <Input
                      type="date"
                      name="startDate"
                      className="form-control"
                      id="startDate"
                      placeholder="Enter Start Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.startDate || ""}
                      invalid={
                        !!(
                          validation.touched.startDate &&
                          validation.errors.startDate
                        )
                      }
                    />
                    {validation.touched.startDate &&
                    validation.errors.startDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.startDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="endDate" className="col-form-label">
                      End Date:
                    </label>
                    <Input
                      type="date"
                      name="endDate"
                      className="form-control"
                      id="endDate"
                      placeholder="Enter End Date"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.endDate || ""}
                      invalid={
                        !!(
                          validation.touched.endDate &&
                          validation.errors.endDate
                        )
                      }
                    />
                    {validation.touched.endDate && validation.errors.endDate ? (
                      <FormFeedback type="invalid">
                        {validation.errors.endDate}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <h5 className="form-details-header">Event Location</h5>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="country" className="col-form-label">
                      Country:
                    </label>
                    <Input
                      type="text"
                      name="country"
                      className="form-control"
                      id="country"
                      placeholder="Enter Title"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.country || ""}
                      invalid={
                        !!(
                          validation.touched.country &&
                          validation.errors.country
                        )
                      }
                    />
                    {validation.touched.country && validation.errors.country ? (
                      <FormFeedback type="invalid">
                        {validation.errors.country}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label htmlFor="city" className="col-form-label">
                      City
                    </label>
                    <Input
                      type="text"
                      name="city"
                      className="form-control"
                      id="city"
                      placeholder="Enter Speciality"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.city || ""}
                      invalid={
                        !!(validation.touched.city && validation.errors.city)
                      }
                    />
                    {validation.touched.city && validation.errors.city ? (
                      <FormFeedback type="invalid">
                        {validation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateAcademicEventsData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default AcademicEventsModal
