import { Link } from "react-router-dom"
import React from "react"

const PrincipleLinks = ({ hasAuthority }) => {
  return (
    <>
      {hasAuthority("VIEW_PRINCIPAL_MENU") && (
        <>
          {hasAuthority("VIEW_PUBLISH_RESULT_MENU") && (
            <li>
              <Link to="/examination/principal">
                <i className="bx bx-home-circle"></i>
                <span>Publish Provisional Results</span>
              </Link>
            </li>
          )}

          {hasAuthority("VIEW_PARTIAL_TRANSCRIPT") && (
            <li>
              <Link to="/examination/principal/partial-transcript">
                <i className="bx bx-home-circle"></i>
                <span> Partial Transcript</span>
              </Link>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default PrincipleLinks
