import React, { useState } from "react"
import {
  Alert, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import {
  onlineApplicationGraphQLClient, uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import PlaceHolderLoader from "../../helpers/PlaceHolderLoader"
import { GET_APPLICANT_DETAILS } from "../Applicant/Queries/ApplicantQueries"
import {
  GET_ACTIVE_ADMISSION_WINDOW
} from "../OnlineApplication/AccountRegistration/Queries/AcountRegistrationQueries"
import { ENABLE_EDIT_APPLICAMT } from "../ApplicationWindow/Mutations/ApplicationWindowMutation"
import showToast from "../../helpers/ToastHelper"
import { GET_USER } from "../Users/Queries/UserQuery"
import {
  PUSH_REGISTERED_APPLICANT
} from "../OnlineApplication/AccountRegistration/Mutations/AccountRegistrationMutations"
import { useFormik } from "formik"
import * as Yup from "yup"
import SubmitButtonHelper from "../../helpers/SubmitButtonHelper"

const OnlineApplicationDashboard = () => {
  const [pushRegisteredApplicant] = useMutation(PUSH_REGISTERED_APPLICANT,
    { client: onlineApplicationGraphQLClient })
  const navigate = useNavigate()
  const location = useLocation()
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data
  const [enableEditLoading, setEnableEditLoading] = useState(false)
  const [enableEdit] = useMutation(ENABLE_EDIT_APPLICAMT, { client: onlineApplicationGraphQLClient })


  const { loading: userLoading, error: staffError, data: userData } = useQuery(GET_USER, {
    client: uaaGraphQLClient,
    skip: location?.pathname !== "/applicant/dashboard",
    variables: {
      username: ExtractUserAuth?.username
    },
    fetchPolicy: "network-only"
  })

  const user = userData?.getUserByUsername?.data

  const { loading: applicantLoading, error: applicantError, data: applicantData } = useQuery(GET_APPLICANT_DETAILS, {
    client: onlineApplicationGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username || location?.pathname !== "/applicant/dashboard",
    variables: {
      userUid: ExtractUserAuth?.uid
    },
    fetchPolicy: "network-only"
  })

  const {
    loading: admissionWindowLoading,
    error: admissionWindowError,
    data: admissionWindowData
  } = useQuery(GET_ACTIVE_ADMISSION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: {
      applicationLevelCode: "BSC"
    },
    fetchPolicy: "network-only"
  })


  const window = admissionWindowData?.getActiveAdmissionWindow?.data
  const applicant = applicantData?.getApplicantDetails.data

  const extractUsername = (username) => {
    return username.split("/").slice(0, 3).join("/")
  }

  const capitalizeText = (text) => {
    if (!text) return ""
    return text.toLowerCase().charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }


  const pushApplicantFormirkValidator = useFormik({
    enableReinitialize: true,
    initialValues: {
      juniorIdentificationNumber: ""
    },
    validationSchema: Yup.object().shape({
      juniorIdentificationNumber: capitalizeText(user?.country?.name) !== "Tanzania" && Yup.string().required("Junior / Identification Number is required")
    }),

    onSubmit: (values, { setSubmitting }) => {
      const payload = {
        firstName: user?.firstName,
        middleName: user?.middleName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        gender: user?.gender,
        countryId: user?.country?.id,
        formFourIndexNumber: extractUsername(user?.username),
        tanzanian: capitalizeText(user?.country?.name) === "Tanzania" ? true : false,
        userUid: user?.uid,
        juniorIdentificationNumber: values.juniorIdentificationNumber
      }
      pushRegisteredApplicant({
        variables: {
          inputs: {
            ...payload
          }
        },
        refetchQueries: [
          {
            query: GET_APPLICANT_DETAILS,
            variables: {
              userUid: ExtractUserAuth?.uid
            }
          }
        ], onCompleted: ({ pushRegisteredApplicant }) => {
          if (pushRegisteredApplicant?.code === 8000) {
            setSubmitting(false)
            showToast("Successfully applicant information restored!", "success")
          }else{
            setSubmitting(false);
          }
        },
        onError: ({ error }) => {
          setSubmitting(false)
          showToast("Something went wrong: " + error, "error")
        }
      })
    }
  })

  const enableEditing = () => {
    setEnableEditLoading(true)
    enableEdit({
      variables: {
        applicantUid: applicant?.uid
      },
      onCompleted: ({ enableEditApplication }) => {
        setEnableEditLoading(false)
        if (enableEditApplication.code === 8000) {
          navigate("/applicant/program-selection")
        } else {
          return
        }
        showToast(
          enableEditApplication.message,
          enableEditApplication.code === 8000 ? "success" : "error"
        )
      }
    })
  }

  return (
    <div className="container">
      {location?.pathname === "/applicant/dashboard" && (
        <Card>
          <CardBody>
            <Row>
              {(applicantLoading || admissionWindowLoading || userLoading) ? (
                <Col md={12}>
                  <PlaceHolderLoader rows={4} type="table" columSize={12} />
                </Col>
              ) : (
                <>
                  {
                    window ? (
                      <>
                        <Col md={12}>
                          <h3>
                            Welcome:{" "}
                            {user && (
                              <>
                                {user?.firstName +
                                  " " +
                                  user?.middleName +
                                  " " +
                                  user?.lastName}
                              </>
                            )}
                          </h3>
                          <br />
                          {applicant && applicant?.submissionStatus === false ? (
                            <Link
                              to="/applicant/profile-information"
                              className="btn btn-sm btn-primary mt-4"
                            >
                              <span>Click here to proceed online application</span>
                            </Link>
                          ) : applicant && applicant?.submissionStatus === true ? (
                            <Row>
                              <Col md={12} className="alert alert-success">
                                <b>You have successfully submitted the application.
                                  Please wait for selection!</b>
                                <br />
                                <button disabled={enableEditLoading} onClick={enableEditing}
                                        className="btn btn-primary btn-sm">
                                  {enableEditLoading ? (
                                    <>Please wait...</>
                                  ) : (
                                    <>Do you want to edit your information? click here</>
                                  )}
                                </button>
                              </Col>
                            </Row>
                          ) : (!applicant) && (
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                pushApplicantFormirkValidator.handleSubmit()
                                return null
                              }}
                            >
                              <div className="alert alert-info"><h3>IMPORTANT NOTE!</h3></div>
                              {
                                capitalizeText(user?.country?.name) !== "Tanzania" && (
                                  <div className="modal-body mt-4">
                                    <div className="mb-3">
                                      <Label>Senior/Junior Identification Number (Ordinary level)</Label>
                                      <Input
                                        name="juniorIdentificationNumber"
                                        placeholder="Select Region"
                                        type="text"
                                        rows={2}
                                        onChange={pushApplicantFormirkValidator.handleChange}
                                        onBlur={pushApplicantFormirkValidator.handleBlur}
                                        invalid={
                                          !!(
                                            pushApplicantFormirkValidator.touched.juniorIdentificationNumber &&
                                            pushApplicantFormirkValidator.errors.juniorIdentificationNumber
                                          )
                                        }
                                      />
                                      {pushApplicantFormirkValidator.touched.juniorIdentificationNumber &&
                                      pushApplicantFormirkValidator.errors.juniorIdentificationNumber ? (
                                        <FormFeedback type="invalid">
                                          {pushApplicantFormirkValidator.errors.juniorIdentificationNumber}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              }
                              <SubmitButtonHelper name="Please click here to proceed" type="primary"
                                                  formik={pushApplicantFormirkValidator} />
                            </Form>
                          )}
                        </Col>
                      </>
                    ) : (
                      <Alert color="danger">
                        {admissionWindowData?.getActiveAdmissionWindow?.message}
                      </Alert>
                    )
                  }
                </>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
    </div>
  )
}
export default OnlineApplicationDashboard