//REGISTE
import { gql } from "@apollo/client"

const GET_PROGRAM_REQUIREMENTS = gql`
  query GetProgramSubjectRequirements {
    getProgramSubjectRequirements {
      status
      code
      message
      data {
        items {
          uid
          programCapacityUid
          qualificationMethodNode {
            uid
            name
          }
          subjectNode {
            uid
            name
          }
          gradePointNode {
            uid
            gradePoint
          }
          alternativeSubjectNode {
            uid
            name
            level
          }
          alternativeGradePointNode {
            uid
            grade
            gradePoint
          }
          entryCategoryCode
          mustPass
          hasAlternativeSubject
        }
        totalCount
      }
    }
  }
`


const GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID = gql`
    query GetProgramSubjectRequirementByProgramCapacityUid($uid:String!){
        getProgramSubjectRequirementByProgramCapacityUid(uid:$uid){
            status
            code
            message
            data{
                items{
                    uid
                    programCapacityUid
                    qualificationMethodNode {
                        uid
                        name
                    }
                    subjectNode {
                        uid
                        name
                    }
                    gradePointNode {
                        uid
                        gradePoint
                    }
                    alternativeSubjectNode {
                        uid
                        name
                        level
                    }
                    alternativeGradePointNode {
                        uid
                        grade
                        gradePoint
                    }
                    entryCategoryCode
                    mustPass
                    hasAlternativeSubject
                }
                totalCount
            }
        }
    }
`;


export { GET_PROGRAM_REQUIREMENTS, GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID }
