import React, { useContext, useRef, useState } from "react"
import { Button, Form, FormFeedback, Modal } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_OTHER_EXPERIENCES } from "../Mutations/OtherExperienceMutations"
import { GET_OTHER_EXPERIENCES } from "../Queries/OtherExperienceQueries"
import { OtherExperienceContext } from "./OtherExperience"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"

const OtherExperiencesModal = () => {
  const { updateOtherExperienceData, setUpdateOtherExperienceData, staffUid } =
    useContext(OtherExperienceContext)

  const [OtherExperience] = useMutation(ADD_UPDATE_OTHER_EXPERIENCES)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_OTHER_EXPERIENCES
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      description: updateOtherExperienceData
        ? updateOtherExperienceData.description
        : "",
      otherExperienceExtracurricularHobby: updateOtherExperienceData
        ? updateOtherExperienceData.otherExperienceExtracurricularHobby
        : "",
      staffUid: updateOtherExperienceData
        ? updateOtherExperienceData.staffUid
        : staffUid,
      uid: updateOtherExperienceData ? updateOtherExperienceData.uid : "",
    },

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Description is Required"),
      otherExperienceExtracurricularHobby: Yup.string().required(
        "Other Experience/Extracurricular Hobby is required",
      ),
    }),

    onSubmit: (values, { setSubmitting }) => {
      OtherExperience({
        client: asimsGraphQLClient,
        variables: {
          input: {
            ...values,
            uid: updateOtherExperienceData?.uid,
            staffUid: staffUid,
          },
        },
        refetchQueries: [
          {
            query: GET_OTHER_EXPERIENCES,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerOtherExperience }) => {
          console.log("register Other Experience: ", registerOtherExperience)
          validation.resetForm()
          setModalOpen(false)
          setUpdateOtherExperienceData(false) // Close the modal
          if (registerOtherExperience.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateOtherExperienceData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerOtherExperience.message,
            registerOtherExperience.code === 8000 ? "success" : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Experience
      </Button>

      <Modal
        isOpen={modalOpen || !!updateOtherExperienceData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateOtherExperienceData
              ? "Edit Other Experience"
              : "Add Other Experience"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateOtherExperienceData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="description" className="col-form-label">
                Description:
              </label>
              <textarea
                type="text"
                name="description"
                className="form-control"
                id="description"
                placeholder="Enter Description"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                onInvalid={
                  !!(
                    validation.touched.description &&
                    validation.errors.description
                  )
                }
              />
              {validation.touched.organizationName &&
              validation.errors.organizationName ? (
                <FormFeedback type="invalid">
                  {validation.errors.organizationName}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label
                htmlFor="otherExperienceExtracurricularHobby"
                className="col-form-label"
              >
                Other Experience/Extracurricular Hobby:
              </label>
              <textarea
                type="text"
                name="otherExperienceExtracurricularHobby"
                className="form-control"
                id="otherExperienceExtracurricularHobby"
                placeholder="Enter Other Experience/Extracurricular Hobby"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.otherExperienceExtracurricularHobby ||
                  updateOtherExperienceData?.otherExperienceExtracurricularHobby ||
                  ""
                }
                onInvalid={
                  !!(
                    validation.touched.otherExperienceExtracurricularHobby &&
                    validation.errors.otherExperienceExtracurricularHobby
                  )
                }
              />
              {validation.touched.otherExperienceExtracurricularHobby &&
              validation.errors.otherExperienceExtracurricularHobby ? (
                <FormFeedback type="invalid">
                  {validation.errors.otherExperienceExtracurricularHobby}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateOtherExperienceData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default OtherExperiencesModal
