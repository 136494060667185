import React, { useEffect, useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  CardSubtitle,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { createContext } from "react"
import { useNavigate } from "react-router-dom"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import { FORWARD_INSTRUCTOR_COURSE_RESULT } from "Modules/Instructor/Mutations/InstructorMutations"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_INSTRUCTOR_COURSE_ALLOCATION } from "Modules/Instructor/Queries/InstructorCoursesQueries"
import {
  showForwardingAlert,
} from "helpers/DeleteWaringHelper"
import { forwardStatus } from "helpers/ForwardStatus"

const breadcrumbItem = [
  {
    title: "Forward Results",
    path: "",
    isActive: true,
  },
]

export const ForwardResultsContext = createContext()

const ForwardResults = () => {
  const [courseAllocate, setCourseAllocate] = useState(null),
    navigate = useNavigate() //for update
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState(null)
  const [selectedUids, setSelectedUids] = useState([])
  const [courseResponse, setCourseResponse] = useState([]);

  const [forwardIntrsuctorCoureResultMutation] = useMutation(
    FORWARD_INSTRUCTOR_COURSE_RESULT,
    { client: registrationGraphQLClient }
  )

  const semesterOptions = Array.from({ length: 10 }, (_, index) => ({
    value: `${index + 1}`,
    label: `Semester ${index + 1}`, // Semesteter Option from 1-10
  }))

  const handleCheckboxChange = event => {
    //
    const { id, checked } = event.target
    if (checked) {
      setSelectedUids(prevUids => [...prevUids, id])
    } else {
      setSelectedUids(prevUids => prevUids.filter(uid => uid !== id))
    }
  }

  const [
    refetchQuery,
    { loading: courseLoading, error: errorCourseFetch, data: courseResult },
  ] = useLazyQuery(GET_INSTRUCTOR_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        semester: parseInt(payload?.semester),
        academicYearUid: payload?.academicYearUid,
      },
    },
    fetchPolicy: "network-only"
  })

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  const {
    loading: academicYearLoading,
    error: academicYearError,
    data: academicYear,
    refetch: acRef,
  } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
    fetchPolicy: "network-only"
  })

  const [
    { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
  ] = useLazyQuery(GET_ACADEMICYEARS, { client: registrationGraphQLClient })

  let academicYears = academicYear?.getAcademicYears?.data?.items
  if (searchYear?.getAcademicYears?.data)
    academicYears = searchYear?.getAcademicYears?.data?.items

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      academicYearUid: "",
      semester: "",
    },

    validationSchema: Yup.object().shape({
      semester: Yup.string().required("Semester is required"),
      academicYearUid: Yup.string().required("Academic year is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setPayload(values)

      refetchQuery({
        variables: {
          inputs: {
            semester: parseInt(values.semester),
            academicYearUid: values.academicYearUid,
          },
        },
        fetchPolicy: "network-only"
      })

      if (!courseLoading) {
        setSubmitting(false)
      }
    },
  })

  const forwardResult = () => {
    if (selectedUids.length > 0) {
      showForwardingAlert(() => {
        setLoading(true)
        forwardIntrsuctorCoureResultMutation({
          variables: {
            programCourseUids: selectedUids,
          },
          refetchQueries: [
            {
              query: GET_INSTRUCTOR_COURSE_ALLOCATION,
              variables: {
                inputs: {
                  semester: parseInt(payload.semester),
                  academicYearUid: payload.academicYearUid,
                },
              },
              fetchPolicy: "network-only"
            },
          ],
          onCompleted: ({ forwardInstructorCourseResult }) => {
            if (forwardInstructorCourseResult.code === 8000) {
              showToast(forwardInstructorCourseResult.message, "success")
              setLoading(false)
              setSelectedUids([])
            } else {
              showToast(
                forwardInstructorCourseResult.message,
                forwardInstructorCourseResult.code === 8000
                  ? "success"
                  : "error"
              )
              setLoading(false)
            }
          },
          onError: error => {
            setLoading(false)
            console.error("Mutation error:", error)
          },
        })
      })
    } else {
      showToast("No any course selected for forwarding result", "error")
    }
  }
  
 useEffect(() => {
    if (!courseLoading && courseResult) {
      setCourseResponse(courseResult?.getInstructorSemesterCourseAllocation?.data || []);
    }
  }, [courseLoading, courseResult]);

  return (
    <ForwardResultsContext.Provider
      value={{
        setCourseAllocate,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Examination Result and Forwarding"
      >
        {academicYearLoading && (
          <PlaceHolderLoader columSize={12} type={"table"} rows={5} />
        )}
        <>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <Row>
                      <Col lg={6}>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label>Academic Year</Label>
                              <Select
                                // value={selectedGroup}
                                onChange={e => {
                                  if (e) {
                                    validation.setFieldValue(
                                      "academicYearUid",
                                      e.value
                                    )
                                  } else {
                                    validation.setFieldValue(
                                      "academicYearUid",
                                      ""
                                    )
                                  }
                                }}
                                options={academicYears?.map(year => ({
                                  value: year.uid,
                                  label: year.name,
                                }))}
                                className="select2-selection"
                                isLoading={
                                  academicYearLoading || yearSearchLoading
                                }
                                isClearable={true}
                              />
                              <Input
                                name="academicYearUid"
                                type="hidden"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.academicYearUid}
                                invalid={
                                  !!(
                                    validation.touched.academicYearUid &&
                                    validation.errors.academicYearUid
                                  )
                                }
                              />
                              {validation.touched.academicYearUid &&
                              validation.errors.academicYearUid ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.academicYearUid}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label>Semester</Label>
                              <Select
                                // value={selectedGroup}
                                name="semester"
                                onChange={e => {
                                  validation.setFieldValue("semester", e.value)
                                }}
                                styles={{
                                  menu: base => ({
                                    ...base,
                                    position: "absolute",
                                    zIndex: 9999,
                                  }),
                                }}
                                options={semesterOptions}
                                className="select2-selection"
                                // defaultValue={updateProgramSemisterData?.studyYear}
                                // defaultInputValue={updateProgramSemisterData?.studyYear}
                              />
                              <Input
                                name="semester"
                                type="hidden"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.semester || 1}
                                invalid={
                                  !!(
                                    validation.touched.semester &&
                                    validation.errors.semester
                                  )
                                }
                              />
                              {validation.touched.semester &&
                              validation.errors.semester ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.semester}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div>
                          <SubmitButtonHelper
                            type={"primary"}
                            name={"Submit"}
                            formik={validation}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardSubtitle className="mb-1 mt-4 card-title">
                    <h5>Allocated Courses</h5>
                    <Button
                      disabled={selectedUids.length <= 0 ? true : false}
                      color="primary"
                      className="btn btn-sm  mb-2"
                      onClick={() => {
                        forwardResult()
                      }}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm mr-3"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="ml-4">Please wait...</span>
                        </>
                      ) : (
                        <>
                          <i className="fa fa-arrow-alt-circle-right"></i>{" "}
                          Forward result
                        </>
                      )}
                    </Button>
                  </CardSubtitle>

                  <div className=" table-responsive">
                    {courseLoading ? (
                      <PlaceHolderLoader rows={4} type="table" columSize={12} />
                    ) : (
                      <Table
                        id="tech-companies-1"
                        className="table-sm table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th>Check</Th>
                            <Th>Program</Th>
                            <Th>Course Ante</Th>
                            <Th>Course</Th>
                            <Th>Status</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {courseResponse &&
                            courseResponse
                              .slice(startIndex, endIndex)
                              .map((resultRows, index) => (
                                <Tr key={resultRows?.uid}>
                                  <Td>{startIndex + index + 1}</Td>
                                  <Td>
                                    <div
                                      className="form-check"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {resultRows?.forwardStatus !== 0 || resultRows?.forwardStatus == null ? (
                                        <>
                                          {forwardStatus(
                                            resultRows?.forwardStatus
                                          )}
                                        </>
                                      ) : (
                                        <Input
                                          onChange={handleCheckboxChange}
                                          id={resultRows?.uid}
                                          name={resultRows?.uid}
                                          type="checkbox"
                                          color="primary"
                                          className="primary"
                                          style={{
                                            width: 25,
                                            height: 25,
                                            padding: 0,
                                            borderColor: "black",
                                            marginRight: 10,
                                            verticalAlign: "middle",
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Td>
                                  <Td>
                                    {resultRows?.programSemester?.program?.name}
                                  </Td>
                                  <Td>{resultRows?.course?.code}</Td>

                                  <Td>{resultRows?.course?.name}</Td>
                                  <Td>
                                    {resultRows?.forwardStatus === 0 ? (
                                      <>Instructor</>
                                    ) : resultRows?.forwardStatus === 1 ? (
                                      <>HOD</>
                                    ) : resultRows?.forwardStatus === 2 ? (
                                      <>Principal</>
                                    ) : resultRows?.forwardStatus === 3 ? (
                                      <>Published(Provisional)</>
                                    ) : (
                                      resultRows?.forwardStatus === 4 && (
                                        <>Published(Senate)</>
                                      )
                                    )}
                                  </Td>
                                </Tr>
                              ))}
                        </Tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      </LayoutHelper>
    </ForwardResultsContext.Provider>
  )
}

export default ForwardResults
