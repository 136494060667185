import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label
} from "reactstrap"
import * as Yup from "yup"
import { CREATE_PROGRAM_COURSE } from "../Mutations/ProgramCoursesMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_PROGRAM_COURSES } from "../Queries/ProgramCoursesQueries"
import {
  createUpdateFunction,
  paginationHelper
} from "helpers/Functions/GraphqlUpdateFunction"
import Select from "react-select"
import { ProgramCoursesContext } from "./ProgramCoursesList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_PROGRAMS } from "Modules/Programs/Queries/ProgramQuaries"
import { GET_COURSES } from "Modules/Courses/Queries/CourseQueries"
import { GET_COURSE_CATEGORIES } from "Modules/CourseCategories/Queries/CourseCategoriesQueries"
import { GET_PROGRAM_SEMISTERS } from "Modules/ProgramSemister/Queries/ProgramSemisterQueries"

const ProgramCoursesModal = () => {
  const { updateProgramCourseData, setUpdatedProgramCourseData } = useContext(ProgramCoursesContext)

  const [ProgramCourse] = useMutation(CREATE_PROGRAM_COURSE, {
    client: registrationGraphQLClient
  })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const [semesters, setSemesters] = useState([])
  const [studyYear, setStudyYear] = useState([])

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      fetchData()
    }, 1000)
  }, [])

  const fetchData = () => {
    // Fetch academic year and study year from api
    setStudyYear([
      { label: "Year 1", value: 1 },
      { label: "Year 2", value: 2 },
      { label: "Year 3", value: 3 },
      { label: "Year 4", value: 4 },
      { label: "Year 5", value: 5 }
    ])
  }

  const { loadingacyear, erroracyear, data } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper
    }
  })

  const {
    loading: programLoading,
    error: programtError,
    data: programData
  } = useQuery(GET_PROGRAMS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper
    }
  })

  const programsArray = programData?.getPrograms?.data?.items

  const {
    loading: programSemisterLoading,
    error: programSemisterError,
    data: programSemisterData
  } = useQuery(GET_PROGRAM_SEMISTERS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper
    }
  })

  const programSemisterArray = programSemisterData?.getProgramSemesters?.data?.items

  const {
    loading: courseLoading,
    error: courseError,
    data: courseData
  } = useQuery(GET_COURSES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper
    }
  })

  const coursesArray = courseData?.getCourses?.data?.items

  const {
    loading: courseCategoryLoading,
    error: courseCategoryError,
    data: courseCategoryData
  } = useQuery(GET_COURSE_CATEGORIES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper
    }
  })

  const courseCategoryArray = courseCategoryData?.getCourseCategories?.data?.items

  if (erroracyear) return showToast("Failed to fetch Academic Years", "error")
  const academicyears = data?.getAcademicYears?.data.items

  const query = GET_PROGRAM_COURSES
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assignmentHours: updateProgramCourseData ? updateProgramCourseData.assignmentHours : "",
      independentStudyHours: updateProgramCourseData ? updateProgramCourseData.independentStudyHours : "",
      practicalHours: updateProgramCourseData ? updateProgramCourseData.practicalHours : "",
      lectureHours: updateProgramCourseData ? updateProgramCourseData.lectureHours : "",
      seminarHours: updateProgramCourseData ? updateProgramCourseData.seminarHours : "",
      credit: updateProgramCourseData ? updateProgramCourseData.credit : "",
      programSemesterUid: updateProgramCourseData ? updateProgramCourseData.programSemesterUid : "",
      courseCategoryUid: updateProgramCourseData ? updateProgramCourseData.courseCategoryUid : "",
      courseUid: updateProgramCourseData ? updateProgramCourseData.courseUid : ""
    },
    validationSchema: Yup.object().shape({
      programSemesterUid: Yup.string().required("Program Course name is required"),
      courseUid: Yup.string().required("Course offeres is required"),
      courseCategoryUid: Yup.string().required("Course Category is required"),
      credit: Yup.string().required("Course Credits/Units is required"),
      lectureHours: Yup.string().required("Lecture Hours is required")
    }),
    onSubmit: values => {
      ProgramCourse({
        variables: {
          inputs: [{ ...values, uid: updateProgramCourseData?.uid }]
        },
        client: registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_PROGRAM_COURSES,
            variables: {
              pagination: paginationHelper
            }
          }
        ],
        onCompleted: ({ registerProgramCourse }) => {
          if (registerProgramCourse.code === 8000) {
            resetForm()
            setModalOpen(false)
            setUpdatedProgramCourseData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdatedProgramCourseData(true) // Close the modal
          }
          showToast(
            registerProgramCourse.message,
            registerProgramCourse.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          console.error("Mutation error:", error)
        }
      })
    }
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Program Course
      </Button>
      <Modal
        size="lg"
        isOpen={modalOpen || !!updateProgramCourseData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramCourseData
              ? "Edit Program Course"
              : "Add New Program Course"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">

            <div>
              <Row>
                <Col md="6" lg="6">

                  <div className="mb-3">
                    <label>Academic Year</label>
                    <Select
                      onChange={e => {
                        //validation.setFieldValue("academicYearUid", e.value)
                      }}
                      options={academicyears?.map(dp => ({
                        value: dp.uid,
                        label: dp.name
                      }))}
                      defaultValue={updateProgramCourseData?.programSemester?.academicYear?.uid}
                      defaultInputValue={updateProgramCourseData?.programSemester?.academicYear?.name}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Course Category</label>
                    <Select
                      id="courseCategoryUid"
                      name="courseCategoryUid"
                      onChange={e => {
                        validation.setFieldValue("courseCategoryUid", e.value)
                      }}
                      options={courseCategoryArray?.map(dp => ({
                        value: dp.uid,
                        label: dp.name
                      }))}
                      defaultValue={updateProgramCourseData?.courseCategoryUid}
                      defaultInputValue={updateProgramCourseData?.courseCategory?.name}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                    <label>Programme</label>
                    <Select
                      onChange={e => {
                        //validation.setFieldValue("program", e.value)
                      }}
                      //name="program"
                      options={programsArray?.map(pr => ({
                        value: pr.uid,
                        label: pr.name + " (" + pr.code + ")"
                      }))}
                      defaultValue={updateProgramCourseData?.programSemester?.program?.uid}
                      defaultInputValue={updateProgramCourseData?.programSemester?.program?.name}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col md="3" lg="3">
                  <div className="mb-3">
                    <label>Credits</label>
                    <Input
                      type="number"
                      name="credit"
                      className="form-control"
                      id="credit"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.credit || ""}
                      invalid={
                        !!(
                          validation.touched.credit &&
                          validation.errors.credit
                        )
                      }
                    />
                    {validation.touched.credit &&
                    validation.errors.credit ? (
                      <FormFeedback type="invalid">
                        {validation.errors.credit}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="3" lg="3">
                  <div className="mb-3">
                    <label htmlFor="code" className="col-form-label">
                      Independent Hours
                    </label>
                    <Input
                      type="number"
                      name="independentStudyHours"
                      className="form-control"
                      id="independentStudyHours"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.independentStudyHours || ""}

                      invalid={
                        !!(
                          validation.touched.independentStudyHours &&
                          validation.errors.independentStudyHours
                        )
                      }
                    />
                    {validation.touched.independentStudyHours &&
                    validation.errors.independentStudyHours ? (
                      <FormFeedback type="invalid">
                        {validation.errors.independentStudyHours}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

              </Row>

              <Row>
                <Col md="6" lg="6">

                  <div className="mb-3">
                    <label>Programme Semester</label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("programSemesterUid", e.value)
                      }}
                      options={programSemisterArray?.map(pr => ({
                        value: pr.uid,
                        label: pr.program.name + " (" + pr.academicYear.name + ")" + " (" + pr.semester + ")"
                      }))}
                      defaultValue={updateProgramCourseData?.programSemesterUid}
                      defaultInputValue={updateProgramCourseData?.programSemester?.program?.name}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                    <Input
                      name="programSemesterUid"
                      type="hidden"
                      //onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.programSemesterUid || ""}
                      //value={updateProgramCourseData ? updateProgramCourseData.programSemesterUid : ""}
                      invalid={!!(validation.touched.programSemesterUid && validation.errors.programSemesterUid)}
                    />
                    {validation.touched.programSemesterUid && validation.errors.programSemesterUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.programSemesterUid}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="3" lg="3">
                  <div className="mb-3">
                    <label>Lecture Hours</label>
                    <Input
                      type="number"
                      name="lectureHours"
                      className="form-control"
                      id="lectureHours"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lectureHours || ""}
                      invalid={
                        !!(
                          validation.touched.lectureHours &&
                          validation.errors.lectureHours
                        )
                      }
                    />
                    {validation.touched.lectureHours &&
                    validation.errors.lectureHours ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lectureHours}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="3" lg="3">
                  <div className="mb-3">
                    <label>Seminar Hours</label>
                    <Input
                      type="number"
                      name="seminarHours"
                      className="form-control"
                      id="seminarHours"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.seminarHours || ""}
                      invalid={
                        !!(
                          validation.touched.seminarHours &&
                          validation.errors.seminarHours
                        )
                      }
                    />
                    {validation.touched.seminarHours &&
                    validation.errors.seminarHours ? (
                      <FormFeedback type="invalid">
                        {validation.errors.seminarHours}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6" lg="6">

                  <div className="mb-3">
                    <label>Course</label>
                    <Select
                      name="courseUid"
                      id="courseUid"
                      onChange={e => {
                        validation.setFieldValue("courseUid", e.value)
                      }}
                      options={coursesArray?.map(pr => ({
                        value: pr.uid,
                        label: pr.name + " (" + pr.code + ")"
                      }))}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col md="3" lg="3">
                  <div className="mb-3">
                    <label>Practical Hours</label>
                    <Input
                      type="number"
                      name="practicalHours"
                      className="form-control"
                      id="practicalHours"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.practicalHours || ""}
                      invalid={
                        !!(
                          validation.touched.practicalHours &&
                          validation.errors.practicalHours
                        )
                      }
                    />
                    {validation.touched.practicalHours &&
                    validation.errors.practicalHours ? (
                      <FormFeedback type="invalid">
                        {validation.errors.practicalHours}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md="3" lg="3">
                  <div className="mb-3">
                    <label>Assignment Hours</label>
                    <Input
                      type="number"
                      name="assignmentHours"
                      className="form-control"
                      id="assignmentHours"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.assignmentHours || ""}
                      invalid={
                        !!(
                          validation.touched.assignmentHours &&
                          validation.errors.assignmentHours
                        )
                      }
                    />
                    {validation.touched.assignmentHours &&
                    validation.errors.assignmentHours ? (
                      <FormFeedback type="invalid">
                        {validation.errors.assignmentHours}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" lg="6">
                  <div className="mb-3">
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdatedProgramCourseData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}
export default ProgramCoursesModal