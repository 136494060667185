import React, {useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {limit} from "../../../../helpers/UrlHelper";
import {GET_ALL_PROGRAMS, GET_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_STATUS, GET_STATUS_NAME} from "../../../Lookups/Status/Queries/StatusQueries";
import ToastHelper from "../../../../helpers/ToastHelper";
import {GET_STUDENT_LIST_REPORT} from "../Queries/StudentReportQueries";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../helpers/PaginationHelper";
import {GenerateTablePDF} from "../../../../helpers/PdfTableHelper";

const breadcrumbItem =  [
    {
        title: 'Student Report',
        path: '',
        isActive: true,
    },
]
const StudentChangeProgramProcessedReport = () => {

    const pagination = { offset: 0, limit: 20, search: null }

    const [pageSize, setPageSize] = useState(20),
        [allowClick, setAllowClick] = useState(false),
        [currentPage, setCurrentPage] = useState(0)

    const handleClick = data => {
        if(allowClick){
            searchStudent({ variables: {
                    inputData:{
                        statusCode:validation.values.statusCode,
                        studyYear:validation.values.studyYear,
                        gender:validation.values.gender,
                        programUid:validation.values.programUid,
                        offset: data.selected,
                        limit: 20
                    }
                } })
        }

            setCurrentPage(data.selected)
    }


    const { loading:programLoading, error:programError, data:programData } = useQuery(GET_ALL_PROGRAMS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })


    const [
        searchPrograms,
        { loading: programSearchLoading, error: searchError, data: searchProgram },
    ] = useLazyQuery(GET_ALL_PROGRAMS, {client: registrationGraphQLClient})

    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })
    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items

    let programs = programData?.getAllPrograms?.data?.items
    if (searchProgram?.getAllPrograms?.data)
        programs = searchProgram?.getAllPrograms?.data?.items

    const {loading: statusLoading,error: statusError,
        data: statusData,
        refetch:statusRef,
    } = useQuery(GET_STATUS_NAME, {
        client: uaaGraphQLClient,
    })
    const [
        searchStatus,
        { loading: statusSearchLoading, error: statusSearchError, data: searchStatusData },
    ] = useLazyQuery(GET_STATUS, {client: uaaGraphQLClient})

    const [
        searchStudent,
        { loading: searchStudentLoading, error: searchStudentError, data: searchStudentData },
    ] = useLazyQuery(GET_STUDENT_LIST_REPORT, {client: uaaGraphQLClient,fetchPolicy:"no-cache"})

    let status = statusData?.getStatusName.data?.items
    // if (searchStatusData?.getProgramCategories?.data)
    //     status = searchStatusData?.getStatuses.data?.items

    let students = searchStudentData?.getRegisteredStudents?.data?.items
    let totalCount = students ? searchStudentData?.getRegisteredStudents?.data?.totalCount : 0
    // console.log(students)
    // const [programSave] = useMutation(CREATE_PROGRAM,{client:registrationGraphQLClient})

    const [
        downloadStudent,
        { loading: downloadStudentLoading, error: downloadStudentError, data: downloadStudentData },
    ] = useLazyQuery(GET_STUDENT_LIST_REPORT, {client: uaaGraphQLClient,fetchPolicy:"no-cache"})


    const search = (value,type) => {
        let data = {
            search: value,
        }
        if (value.length >= 2) {
            if(type === 'academicYear'){
                searchAcademicYear({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }else if (type === 'program'){
                searchPrograms({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }else if (type === 'status'){
                searchStatus({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }

        }
    }

    const downloadList = () => {
        let title = ''
        if(validation.values.programUid){
            const program =  programs.find(i => i.uid === validation.values.programUid)
            title += program.name+' ('+program.code+') '
        }
        if(validation.values.statusCode) title += validation.values.statusCode
        if(validation.values.studyYear) title += ' Year '+validation.values.studyYear
        if(validation.values.gender) title += ' '+validation.values.gender
        title += ' Students Report'
        downloadStudent({ variables: {
                inputData:{
                    statusCode:validation.values.statusCode,
                    studyYear:validation.values.studyYear,
                    gender:validation.values.gender,
                    programUid:validation.values.programUid,
                    offset: 0,
                    limit: 10000000
                }
            } })
            .then((response) => {
                // Handle the response
                // console.log(response)
                if(response.data.getRegisteredStudents?.status === true){
                    const std = response.data.getRegisteredStudents.data.items
                    const columns = ['S/No','Student Name', 'Registration number','Sex','Phone Number', 'Old Registration Number'];
                    let studentData = []
                    if(std){

                        let counter = 1
                        for (let f = 0; f < std?.length; f++){
                            let s = std[f]

                            if(s.oldRegistrationNumber){
                                studentData.push([
                                    counter,s.firstName+' '+s.lastName,s.registrationNumber,s.gender,s.phoneNumber,s.oldRegistrationNumber
                                ])
                                counter++
                            }
                            
                        }
                    }
                    // console.log(studentData)
                    GenerateTablePDF(columns,studentData,title)
                }

            })
            .catch((error) => {
                // Handle errors
                console.error('Mutation error:', error);
            });
        // GenerateTablePDF(columns,studentData,'Student Reports')
    }

    //
    const  studyYear =  [
        { label: "Year 1", value: 1 },
        { label: "Year 2", value: 2 },
        { label: "Year 3", value: 3 },
        { label: "Year 4", value: 4 },
        { label: "Year 5", value: 5 },
    ]

    const  gender =  [
        { label: "MALE", value: 'Male'},
        { label: "FEMALE", value: 'Female'},
    ]



    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            programUid: "",
            statusCode:"",
            studyYear:0,
            gender:"",
            citizenship:"",
            disabilityUid:"",
            academicYearUid:""
        },
        validationSchema: Yup.object().shape({
            // programUid: Yup.string().required("Program is required"),
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            // ToastHelper("Waiting for Backend", "info")
            searchStudent({ variables: {
                    inputData:{
                        statusCode:values.statusCode,
                        studyYear:values.studyYear,
                        gender:values.gender,
                        programUid:values.programUid,
                        offset: 0,
                        limit: 20

                    }
                } })
                .then((response) => {
                    // Handle the response
                    setSubmitting(false)
                    setAllowClick(true)
                })
                .catch((error) => {
                    setSubmitting(false)
                    // Handle errors
                    console.error('Mutation error:', error);
                });
            // ToastHelper("Service requested successful", "success")
            //navigate('/Students/Payments')
        },

    });

    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student List Reports">
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={3}>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                name="academic_year"
                                                onChange={(e) => {
                                                    validation.setFieldValue('academicYearUid',e.value)

                                                }}
                                                onInputChange={e => {
                                                    search(e,'academicYear')
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.uid,
                                                    label: year.name,
                                                }))}
                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYearUid || ""}
                                                invalid={
                                                    !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                }
                                            />
                                            {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYearUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg={5}>
                                        <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                            <Label>Program</Label>
                                            <Select
                                                // value={selectedMulti2}
                                                styles={{
                                                    menu: base => ({
                                                        ...base,
                                                        position: "absolute",
                                                        zIndex: 9999
                                                    })
                                                }}
                                                onChange={e => {
                                                    validation.setFieldValue("programUid", e.value)
                                                }}
                                                onInputChange={e => {
                                                    search(e,'program')
                                                }}
                                                options={programs?.map(pro => ({
                                                    value: pro.uid,
                                                    label: pro.name + " (" + pro.code + ")",
                                                }))}
                                                className="select2-selection"
                                                isLoading={programLoading || programSearchLoading}
                                            />
                                            <Input
                                                name="programUid"
                                                placeholder=""
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.programUid || ""}
                                                invalid={
                                                    !!(
                                                        validation.touched.programUid &&
                                                        validation.errors.programUid
                                                    )
                                                }
                                            />
                                            {validation.touched.programUid &&
                                            validation.errors.programUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.programUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={2}>

                                        <div className="mb-3">
                                            <Label>Study Year</Label>
                                            <Select
                                                onChange={(e) => {
                                                    validation.setFieldValue('studyYear',e.value)
                                                }}
                                                options={studyYear}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="studyYear"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.studyYear || ""}
                                                invalid={
                                                    !!(validation.touched.studyYear && validation.errors.studyYear)
                                                }
                                            />
                                            {validation.touched.studyYear && validation.errors.studyYear ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.studyYear}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                    <Col lg={2}>
                                        <div className="mb-3">
                                            <Label>Status</Label>
                                            <Select
                                                // value={selectedGroup}
                                                name="statusCode"
                                                onChange={(e) => {
                                                    validation.setFieldValue('statusCode',e.value)

                                                }}
                                                // onInputChange={e => {
                                                //     search(e,'status')
                                                // }}
                                                options={status?.map(st => ({
                                                    value: st.name,
                                                    label: st.name,
                                                }))}
                                                className="select2-selection"
                                                isLoading={statusLoading}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.statusCode || ""}
                                                invalid={
                                                    !!(validation.touched.statusCode && validation.errors.statusCode)
                                                }
                                            />
                                            {validation.touched.statusCode && validation.errors.statusCode ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.statusCode}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col lg={2}>

                                        <div className="mb-3">
                                            <Label>Gender</Label>
                                            <Select
                                                onChange={(e) => {
                                                    validation.setFieldValue('gender',e.value)
                                                }}
                                                options={gender}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="gender"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.gender || ""}
                                                invalid={
                                                    !!(validation.touched.gender && validation.errors.gender)
                                                }
                                            />
                                            {validation.touched.gender && validation.errors.gender ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.gender}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                    <div className="float-end">
                                        <SubmitButtonHelper type={'primary'} name={'Submit'} formik={validation} />
                                    </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>

            </Row>

            <Row>
                <Col lg={12}>

                    <Card>
                        <CardBody>
                            {
                                students && <Button className="btn btn-success btn-sm" onClick={downloadList} disabled={downloadStudentLoading}>
                                    {
                                        downloadStudentLoading ?
                                            <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Please wait..</>
                                            : <><i className="fa fa-file-pdf"></i> Generate PDF</>
                                    }
                                    </Button>

                            }

                            {searchStudentLoading ? (
                                <PlaceHolderLoader type="table" columSize={12} rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >



                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th data-priority="0">Registration No</Th>
                                                    <Th data-priority="1">Full Name</Th>
                                                    <Th data-priority="3">Sex</Th>
                                                    <Th data-priority="3">E-Mail</Th>
                                                    <Th data-priority="3">Phone Number</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {students?.map((std, index) => (
                                                    <Tr key={std?.uid}>
                                                        <Td>{(currentPage * 20) + index + 1}</Td>
                                                        <Td>{std?.registrationNumber}</Td>
                                                        <Td>{`${std?.firstName} ${std?.middleName !== undefined ? std?.middleName:''} ${std?.lastName}`}</Td>
                                                        <Td>{std?.gender}</Td>
                                                        <Td>{std?.email}</Td>
                                                        <Td>{std?.phoneNumber}</Td>
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}

                            <PaginationHelper totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} pageItemNumber={students?.length} handleClick={handleClick} />
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default StudentChangeProgramProcessedReport;