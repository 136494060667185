import { Link } from "react-router-dom"
import React from "react"

// VIEW_OAS_MODULE
const ApplicantLinks = ({ hasAuthority }) => {
  return (
    <>
      {/*{*/}
      {/*  hasAuthority("VIEW_OAS_MODULE") && (*/}
          <>

            <li>
              <Link to="/applicant/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant/profile-information">
                <i className="bx bx-user-circle"></i>
                <span>Update Information</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant/application-method">
                <i className="bx bx-door-open"></i>
                <span>Application Method</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant/payment">
                <i className="bx bx-dollar"></i>
                <span>Payments</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant/entry-qualification">
                <i className="bx bx-door-open"></i>
                <span>Entry Qualification</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant/program-selection">
                <i className="bx bx-list-check"></i>
                <span>Program selection</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant/confirmation">
                <i className="bx bx-check"></i>
                <span>Confirmation</span>
              </Link>
            </li>

          </>
      {/*  )*/}
      {/*}*/}
    </>
  )
}

export default ApplicantLinks
