import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Label,
  Alert,
} from "reactstrap"
import * as Yup from "yup"
import { CREATE_COURSE_ALLOCATION } from "../Mutations/CourseAllocationMutations"
import { COURSE_ALLOCATION } from "../Mutations/ProgramSemisterMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_COURSE_ALLOCATION } from "../Queries/CourseAllocationQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import Select from "react-select"
import { StaffCourseAllocationBySemesterContext } from "./StaffCourseAllocationBySemesterList"
import { StaffCourseAllocationContext } from "./StaffCourseAllocationList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_STAFF }      from "Modules/Users/Queries/UserQuery"


const CourseAllocationModal = () => {

  const { courseAllocate, setCourseAllocate } = useContext(StaffCourseAllocationBySemesterContext) 
  const [courseAllocation] = useMutation(CREATE_COURSE_ALLOCATION, 
    {
      client: registrationGraphQLClient
    })

  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

 

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  useEffect(() => {
    setTimeout(() => {
        fetchData()
    }, 1000)
  }, [])

  const fetchData = () => {
      //     fetch academic year and study year from api

  }  

  const {
    loading: courseCategoryLoading,
    error: courseCategoryError,
    data: staffData,
  } = useQuery(GET_STAFF, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

   // if (catError) return showToast("Failed to fetch Program Categories", "error")
   let staffArray = staffData?.getStaffs?.data?.items

   const validationStaffAllocation = useFormik({
   // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      programCourseUid: courseAllocate? courseAllocate?.programCourse?.uid : "",
      staffUid: "",
    },

    validationSchema: Yup.object().shape({
      staffUid: Yup.string().required("Staff name is required")
    }),

    onSubmit: values => {

      console.log(values)
      return
         
      courseAllocation({
        variables: {
          inputs: {
            ...values
          }
        },
        client: registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_COURSE_ALLOCATION,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerCourseAllocations }) => {
          if (registerCourseAllocations.code === 8000) {
            resetForm()
            setModalOpen(false)
            setCourseAllocate(false) // Close the modal
          } else {
            setModalOpen(true)
            setCourseAllocate(true) // Close the modal
            //resetForm()
          }
          showToast(
            registerCourseAllocations.message,
            registerCourseAllocations.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      {/* <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Course Allocation
      </Button> */}
      <Modal
        size="lg"
        isOpen={modalOpen || !!courseAllocate}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!courseAllocate
              ? "Edit Course Allocation"
              : ""}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
              onSubmit={e => {
                e.preventDefault()
                validationStaffAllocation.handleSubmit()
                return null
              }}
        >
          <div className="modal-body">

          <div>
            <Row>
              <Col lg={12}>
                <Alert color="info">
                  <p>Allocate by selecting below list of  and courses</p>
                  <p>Course: {courseAllocate?.programCourse?.course?.code}</p>
                </Alert>
              </Col>
            </Row>            
            <Row>
            <Col md="12" lg="12">
                      <div className="mb-3">
                        <label>Instructor name</label>
                        <Select
                          name="staffUid"
                          id="staffUid"
                          onChange={e => {
                            validationStaffAllocation.setFieldValue("staffUid", e.value)
                          }}
                          options={staffArray?.map(staff => ({
                            value: staff.uid,
                            label: staff?.user?.firstName + " " + staff?.user?.lastName
                          }))}
                          className="select2-selection"
                          styles={{
                            menu: base => ({
                              ...base,
                              position: "absolute",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </Col>
              </Row>
              </div>
          </div>
          <div className="modal-footer">
          <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    setModalOpen(false)
                    setCourseAllocate(false)
                    resetForm() // Reset the form
                  }}
                >
                  Close
                </button>
                <SubmitButtonHelper
                  name="Submit"
                  type="primary"
                  formik={validationStaffAllocation}
                />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default CourseAllocationModal
