//REGISTE
import { gql } from '@apollo/client';


const GET_DEACTIVATE_REASONS = gql`
  query GetDeactivationReasons($pagination: PaginationInput!) {
    getDeactivationReasons(pagination: $pagination){
      status
      code
      message
      data{
        items{
        id
        uid
        name
      }
      totalCount
      }
    }
  }
`;

export { GET_DEACTIVATE_REASONS }