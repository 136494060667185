import React, { useContext, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, Button, Modal
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation, useQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import {
  GET_APPLICANT_SELECTION
} from "../../OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries"
import { REMOVE_SELECTION } from "../../OnlineApplication/ProgramSelection/Mutations/ProgramSelectionMutations"
import { ProgramSelectionContext } from "../../OnlineApplication/ProgramSelection/Views/ProgramSelection"

const AppliedProgams = () => {

  const { windowData, applicant } = useContext(ProgramSelectionContext)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProgramCourse, setSelectedProgramCourse] = useState(null) //Get data for deleting

  const [deleteSelection] = useMutation(REMOVE_SELECTION, {
    client: onlineApplicationGraphQLClient,
    variables: { uid: selectedProgramCourse?.uid },
    refetchQueries: [
      {
        query: GET_APPLICANT_SELECTION,
        client: onlineApplicationGraphQLClient,
        variables: {
          applicantUid: applicant?.uid,
          admissionWindowUid: windowData?.uid
        }
      }
    ]
  })


  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = staff => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [staff.uid]: !prevState[staff.uid]
    }))
  }

  console.log(windowData)

  const { loading, error, data, refetch } = useQuery(GET_APPLICANT_SELECTION, {
    client: onlineApplicationGraphQLClient,
    skip: !windowData?.uid || !applicant?.uid,
    variables: {
      applicantUid: applicant?.uid,
      admissionWindowUid: windowData?.uid
    },
    fetchPolicy: "network-only"
  })

  const results = data?.getApplicantSelections?.data?.items

  const handledeleteProgramApplied = () => {
    showDeleteWarning(() => {
      performDataDeletion()
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteSelection()
      showToast(response.data.removeProgramCourse.message, "success")
    } catch (error) {
      showToast("Failed to delete", "error")
    }
  }


  return (
    <>
      <div>
        <i className="font-size-24" />{" "}
        <Button
          type="button"
          color="primary"
          className="btn btn-sm"
          onClick={() => setModalOpen(true)}
        >
          <i className="mdi mdi-eye-outline me-1" /> View Applied program(s)
        </Button>
      </div>
      <div className="text-sm-end mt-3">
        <Modal
          isOpen={modalOpen}
          backdrop={"static"}
          id="staticBackdrop"
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {`Program Selected by Applicant: ${applicant?.firstName + ' ' + applicant?.lastName}`}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalOpen(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body mt-4">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!loading && !error ? (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                            style={{ border: "2px solid #63a69f" }}
                          >
                            <Thead>
                              <Tr>
                                <Th data-priority="1">S/N</Th>
                                <Th data-priority="1">Programme Name</Th>
                                <Th data-priority="1">Choice Number</Th>
                                <Th data-priority="3">Action</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {results
                                ?.slice(startIndex, endIndex)
                                .map((resultRow, index) => (
                                  <Tr key={resultRow?.uid}>
                                    <Td>{startIndex + index + 1}</Td>
                                    <Td>{resultRow?.programCapacity?.programName}</Td>
                                    <Td>{resultRow?.choice}</Td>
                                    <Td>
                                      <Dropdown
                                        isOpen={dropdownOpen[resultRow.uid]}
                                        toggle={() => toggleDropdown(resultRow)}
                                      >
                                        <DropdownToggle color="primary" caret>
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            onClick={() => {
                                              setSelectedProgramCourse(resultRow)
                                              handledeleteProgramApplied()
                                            }}
                                          >
                                            <i className="fas fa-trash" style={{ color: "red", marginRight: "10px" }} />
                                            <span>Remove</span>
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </Dropdown>
                                    </Td>
                                  </Tr>
                                ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    ) : (
                      <PlaceHolderLoader columSize={12} type={"table"} rows={5} />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
              }}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default AppliedProgams
