import { Route, Routes } from "react-router-dom"
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard"
import Pages404 from "../../Modules/Utility/pages-404"
import React from "react"
import EntryQualification from "../../Modules/Applicant/Views/EntryQualification/EntryQualification"
import ApplicantPayment from "../../Modules/Applicant/Views/ApplicantPayment"
import UpdateQualifications from "../../Modules/Applicant/Views/UpdateQualifications"
import ApplicantProfileWizard from "../../Modules/Applicant/Views/ApplicantProfile/ApplicantProfileWizard"
import ApplicantConfirmation from "../../Modules/Applicant/Views/ApplicantConfirmation"
import ProgramSelection from "../../Modules/OnlineApplication/ProgramSelection/Views/ProgramSelection"
import AppliedPrograms from "../../Modules/ProgramCourses/Views/AppliedPrograms"
const ApplicantRoutes = () => {
  return (
    <Routes>
      <Route path={"dashboard"} element={<AsimsDashboard />} />
      <Route path="/entry-qualification" element={<EntryQualification />} />
      <Route path="/payment" element={<ApplicantPayment />} />
      <Route path="/application-method" element={<UpdateQualifications />} />
      <Route path="/profile-information" element={<ApplicantProfileWizard />} />
      <Route path="/confirmation" element={<ApplicantConfirmation />} />
      <Route path="/program-selection" element={<ProgramSelection />} />
      <Route path="/applied-programs" element={<AppliedPrograms />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default ApplicantRoutes