import { gql } from "@apollo/client";
const UPDATE_STUDENT_PROFILE = gql`
  mutation UpdateStudentProfile($input: StudentDtoUpdate!) {
    updateStudentProfile(input: $input) {
      status
      code
      message
    }
  }
`;
const UPDATE_STUDENT_CONTANTS = gql`
mutation UpdateStudentContact($input: StudentContactDtoUpdate!) {
  updateStudentContact(input: $input) {
    status
    code
    message
  }
}
`;

const UPDATE_USER_NEXT_OF_KINS = gql`
mutation UpdateUserNextOfKins($input:  UserNextOfKinsUpdate!) {
  updateUserNextOfKins(input: $input) {
    status
    code
    message
  }
}
`;
const UPDATE_STUDENT_DETAILS = gql`
mutation UpdateStudentDetails($input: StudentProfileDtoUpdate!){
  updateStudentDetails(input: $input){
    status
    code
    message
  }
 }
 `;
export {UPDATE_STUDENT_PROFILE, UPDATE_STUDENT_CONTANTS, UPDATE_USER_NEXT_OF_KINS,UPDATE_STUDENT_DETAILS}