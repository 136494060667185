import { gql } from "@apollo/client"

const CREATE_STAFF_TERM = gql`
  mutation RegisterStaffTerm($inputs: [StaffTermDtoInput!]!) {
    registerStaffTerms(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          uid
          name
        }
      }
    }
  }
`

const DELETE_STAFF_TERM = gql`
  mutation RemoveStaffTerm($uid: String!) {
    removeStaffTerm(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_STAFF_TERM, DELETE_STAFF_TERM }
