import { gql } from "@apollo/client"

const CREATE_COURSE_ALLOCATION = gql`
mutation registerCourseAllocation ($inputs : [CourseAllocationInput!]!) {
  registerCourseAllocations (inputs: $inputs){
    code
    status
    message
    data {
      items {
        uid
        staffUid
        programCourse {
          uid
          course {
            uid
            code
            name
          }
        }
      }
    }
  }
}
`

const DELETE_COURSE_ALLOCATION = gql`
mutation removeCourseAllocation($uid: String!) {
  removeCourseAllocation(uid: $uid) {
    message
    code
    status
  }
}
`

export { CREATE_COURSE_ALLOCATION, DELETE_COURSE_ALLOCATION }