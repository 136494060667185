import React, { createContext, useEffect, useState } from "react"
import { 
  Row, 
  Col, 
  Card,
  CardBody,
  CardSubtitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {Link, useLocation} from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_COURSE_ALLOCATION } from "../Queries/CourseAllocationQueries"
import { DELETE_COURSE_ALLOCATION } from "../Mutations/CourseAllocationMutations"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import CourseAllocationModal from "./CourseAllocationModal"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper";


const breadcrumbItem = [
  {
    title: "Program Semester",
    path: "/examination/program-semister",
    isActive: false,
  },
]



export const CourseAllocationContext = createContext() //Create data to transfer to child modal

const CourseAllocationList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedProgramCourseAssessment, setSelectedProgramCourseAssessment] = useState(null) //Get data for deleting
  const [updateProgramCourseAssessmentData, setUpdatedProgramCourseAssessmentData] =
    useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
    //fetchPolicy: "cache-first",
  })

  const [
    searchProgramCourse,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {
      refetch();
      clearSearch(); // Clear the search query after refetching the data

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteCourseAllocation] = useMutation(DELETE_COURSE_ALLOCATION, {
    client: registrationGraphQLClient,
    variables: { uid: selectedProgramCourseAssessment?.uid },
    refetchQueries: [
      {
        query: GET_COURSE_ALLOCATION,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = staff => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [staff.uid]: !prevState[staff.uid],
    }))
  }

  const handledeleteCourseAllocate = (allocating) => {
      setSelectedProgramCourseAssessment(allocating)
      showDeleteWarning(() => {
      performDataDeletion()
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteCourseAllocation()
      showToast(response.data.removeCourseAllocation.message, "success")
    } catch (error) {
      showToast("Failed to delete", "error")
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchProgramCourse({
      variables: { pagination: { ...pagination, ...data } },
    })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }


  const programCourse = data?.getCourseAllocations.data?.items || searchData?.getCourseAllocations?.data?.items || []
  const searchedProgramCourse = searchData?.getCourseAllocations?.data?.items
  const result = searchedProgramCourse ? searchedProgramCourse : programCourse
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getCourseAllocations.data.totalCount || searchData?.getCourseAllocations?.data?.items || 0

  const location = useLocation();
  let { resultRow } = location.state;

  return (
    <CourseAllocationContext.Provider
      value={{ 
        updateProgramCourseAssessmentData, 
        setUpdatedProgramCourseAssessmentData,
        resultRow 
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Course Allocation List"
      >
        <Row>
        <Col lg={4}>
                    <Card>
                        <CardBody>
                            {/* <div className="table-responsive"> */}
                            <Table
                                id="tech-companies-1"
                                className="table "
                            >
                                    <tbody>
                                    <tr>
                                        <th>Programme: </th>
                                        <td>{`${resultRow?.programSemester?.program?.name}`}</td>
                                    </tr>
                                    <tr>
                                        <th>Academic Year:</th>
                                        <td>{resultRow?.programSemester?.academicYear?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Study Year:</th>
                                        <td>{resultRow?.programSemester?.studyYear}</td>
                                    </tr>
                                    <tr>
                                        <th>Semester:</th>
                                        <td>{resultRow?.programSemester?.semester}</td>
                                    </tr>
                                    <tr>
                                        <th>Course:</th>
                                        <td>{resultRow?.course?.code}</td>
                                    </tr>                                    
                                    <tr>
                                        <th>Course Ante:</th>
                                        <td>{resultRow?.course?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Credits:</th>
                                        <td>{resultRow?.credit}</td>
                                    </tr> 
                                    </tbody>                                   
                                  </Table>
                            {/* </div> */}
                        </CardBody>
                    </Card>
                </Col>
          <Col lg={8}>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Searching..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <CourseAllocationModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !searchLoading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                          <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Course</Th>
                            <Th data-priority="1">Staff</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            .slice(startIndex, endIndex)
                            .map((resultRows, index) => (
                              <Tr key={resultRows?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{resultRows?.programCourse?.course?.name}</Td>
                                <Td>{resultRows?.staffUid}</Td>
                                <Td>
                                  <DropDownActionHelper
                                    data={resultRows}
                                    onUpdate={setUpdatedProgramCourseAssessmentData}
                                    onDelete={handledeleteCourseAllocate}
                                />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </CourseAllocationContext.Provider>
  )
}

export default CourseAllocationList
