import React from "react"
import { Route, Routes } from "react-router"
import ProgramSemisterList from "../Views/ProgramSemisterList"
import ProgramCourseDetails from "../Views/ProgramCourseDetails"
import Pages404 from "Modules/Utility/pages-404"

{/*import ViewProgram from "../../Programs/Views/ViewProgram";*/
}
import ProgramCourseAssessmentRoutes from "../../ProgramCourseAssessments/Routes/ProgramCourseAssessmentRoutes"

const ProgramSemisterRoutes = () => {

  console.log("Here is the component reach here")
  return (
    <Routes>
      <Route path="/view" element={<ProgramCourseDetails />} />
      <Route path="/course-assessments/*" element={<ProgramCourseAssessmentRoutes />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default ProgramSemisterRoutes