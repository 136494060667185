import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import InstructorResults from "../Views/Results/InstructorResults";
import UplodResults from "../Views/Results/UplodResults";
import ForwardResults from "../Views/Results/ForwardResults";
import DeleteResults from "../Views/Results/DeleteResults";
import CourseResults from "../Views/Results/CourseResults";
import InstructorCoursesList from "../Views/InstructorCoursesList";
import InstructorCourseDetail from "../Views/InstructorCourseDetail";
import CourseResult from "../Views/Results/CourseResult"
import ViewUploadedResultResponse from "../Views/ViewUploadedResultResponse";
import PreviousCourses from "../Views/PreviousCourses";
import StudyPostponementRequests from "../Views/AcademicAdvisor/StudyPostponementRequests";

const InstructorRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<InstructorResults />} />
            <Route path="/upload_result" element={<UplodResults />} />
            <Route path="/forward-results" element={<ForwardResults />} />
            <Route path="/course-results" element={<CourseResults />} />
            <Route path="/my-courses" element={<InstructorCoursesList />} />
            <Route path="/my_courses_previous" element={<PreviousCourses />} />
            <Route path="/view_submitted_result" index={true} element={<ViewUploadedResultResponse />} />
            <Route path="/course_allocations/:uid" element={<InstructorCourseDetail />} />
            <Route path="/delete-results" element={<DeleteResults />} />
            <Route path="/course-result" element={<CourseResult />} />
            <Route path="/study_postponement_requests" element={<StudyPostponementRequests />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default InstructorRoutes