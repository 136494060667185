import React, {useContext, useEffect, useState} from "react"
import { Modal, Form, Input, FormFeedback } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { CHANGE_PASSWORD } from "./ChangePasswordMutation"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { useNavigate } from "react-router"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import {ProfileMenuContext} from "./ProfileMenu";

const ChangePasswordModal = () => {
  const { changePasswordModalOpen, setChangePasswordModal } = useContext(ProfileMenuContext)
  const [changePassword] = useMutation(CHANGE_PASSWORD)
  const navigate = useNavigate();



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string()
    .required("New password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*\d).{6,}$/,
      "Password must contain at least one uppercase letter, one number, and be at least 6 characters long"
    ),
      confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),}),

    onSubmit: (values, {setSubmitting}) => {
      changePassword({
        client: uaaGraphQLClient,
        variables: {
          input: { oldPassword: values.oldPassword, newPassword: values.confirmPassword },
        },
        onCompleted: ({ changePassword }) => {
          //updateFunction(client.cache, { data: { changePassword } });
          if (changePassword.code === 8000) {
            validation.resetForm()
            setChangePasswordModal(false)
            navigate('/logout')
          } else {
            setSubmitting(false)
            setChangePasswordModal(true)
          }
          showToast(
            changePassword.message,
            changePassword.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const modalCancelled = () => {
    // navigate('/')
  }

  return (
    <div className="text-sm-end mt-3">
      <Modal isOpen={changePasswordModalOpen} backdrop={"static"} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Change Password
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setChangePasswordModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="oldPassword" className="col-form-label">
                Old Password:
              </label>
              <Input
                type="password"
                name="oldPassword"
                className="form-control"
                id="old-password"
                placeholder="Enter Old Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.oldPassword ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.oldPassword &&
                    validation.errors.oldPassword
                  )
                }
              />
              {validation.touched.oldPassword &&
              validation.errors.oldPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.oldPassword}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="col-form-label">
                New Password:
              </label>
              <Input
                type="password"
                name="newPassword"
                className="form-control"
                id="firstoldPassword"
                placeholder="Enter new password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.newPassword ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.newPassword &&
                    validation.errors.newPassword
                  )
                }
              />
              {validation.touched.newPassword &&
              validation.errors.newPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.newPassword}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="col-form-label">
                Confirm Password:
              </label>
              <Input
                type="password"
                name="confirmPassword"
                className="form-control"
                id="confirmPassword"
                placeholder="Confirm Password"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.confirmPassword ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.confirmPassword &&
                    validation.errors.confirmPassword
                  )
                }
              />
              {validation.touched.confirmPassword &&
              validation.errors.confirmPassword ? (
                <FormFeedback type="invalid">
                  {validation.errors.confirmPassword}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setChangePasswordModal(false)
                modalCancelled()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              type="primary"
              name="Change Password"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ChangePasswordModal
