import React from 'react'
import { Route, Routes } from 'react-router';
import HeadShipList from '../HeadShipList';

const HeadshipRoutes = () => {
  return (
    <Routes>
        <Route path="/"  element={<HeadShipList />} />
    </Routes>
  )
}
export default HeadshipRoutes;