import React, { createContext, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_OUTREACH_ACTIVITIES } from "../Queries/OutreachQueries"
import { useNavigate } from "react-router"
import { DELETE_OUTREACH_ACTIVITIES_HISTORY } from "../Mutations/OutreachMutations"
import { asimsGraphQLClient } from "../../../Authentication/ApolloClient"

const breadcrumbItem = [
  {
    title: "Research Grants",
    path: "",
    isActive: true,
  },
]

export const OutreachContext = createContext() //Create data to transfer to child modal

const Outreach = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const navigation = useNavigate()
  const [selectedOutreach, setSelectedOutreach] = useState(null) //Get data for deleting
  const [updateOutreachData, setUpdateOutreachData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_OUTREACH_ACTIVITIES, {
    client: asimsGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
  })

  const [
    searchOutreach,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_OUTREACH_ACTIVITIES, { client: asimsGraphQLClient })

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    setCurrentPage(selectedPage.selected)
  }

  const [deleteOutreach] = useMutation(DELETE_OUTREACH_ACTIVITIES_HISTORY, {
    client: asimsGraphQLClient,
    variables: { uid: selectedOutreach?.uid },
    refetchQueries: [
      {
        query: GET_OUTREACH_ACTIVITIES,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = consultancies => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [consultancies.uid]: !prevState[consultancies.uid],
    }))
  }

  const handleDeleteOutreach = () => {
    showDeleteWarning(() => {
      performOutreachDeletion().then(r => "")
    })
  }

  const performOutreachDeletion = async () => {
    try {
      const response = await deleteOutreach()
      showToast(response.data.removeOutreach.message, "success")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading && !searchLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchOutreach({
      variables: { pagination: { ...pagination, ...data } },
    }).then(r => "")
  }

  const searchQuery = () => {
    const value = formikSearch.values?.search || ""
    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchOutreach({
        variables: { pagination: { ...pagination, ...data } },
      })
    } else if (value?.length === 0) {
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    formikSearch.setFieldValue("search", "")
  }

  const Outreach = data?.getStaffSpecialAppointments?.data?.items
  const SearchedOutreach = searchData?.getStaffSpecialAppointments?.data?.items

  const results = SearchedOutreach ? SearchedOutreach : Outreach
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getStaffSpecialAppointments?.data?.totalCount ||
    searchData?.getStaffSpecialAppointments?.data?.items ||
    0

  return (
    <OutreachContext.Provider
      value={{
        updateOutreachData,
        setUpdateOutreachData,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Special Appointments List"
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right"></Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">staffUid</Th>
                            <Th data-priority="1">Responsibilities</Th>
                            <Th data-priority="1">Appointment Type</Th>
                            <Th data-priority="1">Appointing Office</Th>
                            <Th data-priority="1">Start Date</Th>
                            <Th data-priority="1">End Date</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results
                            ?.slice(startIndex, endIndex)
                            .map((Outreach, index) => (
                              <Tr key={Outreach?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{Outreach?.staffUid}</Td>
                                <Td>{Outreach?.responsibilities}</Td>
                                <Td>{Outreach?.appointmentType?.name}</Td>
                                <Td>{Outreach?.appointingOffice?.name}</Td>
                                <Td>{Outreach?.startDate}</Td>
                                <Td>{Outreach?.endDate}</Td>
                                <Td>
                                  <Dropdown
                                    isOpen={dropdownOpen[Outreach.uid]}
                                    toggle={() => toggleDropdown(Outreach)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateOutreachData(Outreach)
                                        }}
                                      >
                                        <i
                                          className="fas fa-pen"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedOutreach(Outreach)
                                          handleDeleteOutreach()
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </OutreachContext.Provider>
  )
}

export default Outreach
