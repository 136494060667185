import { gql } from "@apollo/client"

const GET_MOODLE_QUIZES_BY_COURSE = gql`
  query GetMoodleQuizzesByCourse($inputs: MoodleGetQuizzesInput!) {
    getMoodleQuizzesByCourse(inputs: $inputs) {
      status
      code
      message
      data {
        quizzes {
          id
          coursemodule
          course
          name
          intro
          introformat
          introfiles
        }
      }
    }
  }
`

const GET_MOODLE_GRADING_METHODS = gql`
  query GetMoodleGradingMethod {
    getMoodleGradingMethod {
      status
      code
      message
      data {
        id
        name
      }
    }
  }
`

const GET_MOODLE_MOODLE_USERS_ATTEMMPTS_ON_QUIZ = gql`
  query GetMoodleUsersAttemptsOnQuiz($inputs: MoodleUsersAttemptsOnQuizInput!) {
    getMoodleUsersAttemptsOnQuiz(inputs: $inputs) {
      status
      code
      message
      data {
        registrationNumber
        fullName
        moodleId
        grade
      }
    }
  }
`

export { GET_MOODLE_QUIZES_BY_COURSE, GET_MOODLE_GRADING_METHODS, GET_MOODLE_MOODLE_USERS_ATTEMMPTS_ON_QUIZ }
