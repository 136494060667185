import React, { useContext, useRef, useState, useEffect } from "react"
import { Modal, Form, Input, FormFeedback, Button, Label } from "reactstrap"
import * as Yup from "yup"
import { CREATE_ROOM_ALLOCATION } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useLazyQuery, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import showToast from "helpers/ToastHelper"
import { GET_HOSTELS, GET_HOSTEL_BLOCKS, GET_BLOCK_ROOMS, GET_COUNTRY, GET_VACANT_ROOM_CAPACITIES } from "../Queries/AccommodationQueries"
import { RoomAllocationContext } from "./RoomAllocationList"
import { GET_STUDENTS } from "Modules/Students/Queries/StudentsQueries"
import { GET_CURRENT_ACADEMICYEAR } from "Modules/AcademicYears/Queries/AcademicYearsQueries"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { accomodationGraphQLClient, registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"

const RoomAllocationModal = () => {
  const { updateRoomAllocationData, setUpdateRoomAllocationData } = useContext(RoomAllocationContext)
  const [create_room_allocation] = useMutation(CREATE_ROOM_ALLOCATION, {client: accomodationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const [processRequestsModalOpen, setProcessRequestsModalOpen] = useState(false)
  const formRef = useRef()

  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_COUNTRY
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const { loading: academicYearLoading, error: academicYearError, data: academicYearData, refetch: refe } = useQuery(GET_CURRENT_ACADEMICYEAR, {
    client:registrationGraphQLClient,
    fetchPolicy: 'cache-first'
  })
  const academic_year = academicYearData?.getActiveAcademicYear?.data

  const [
    searchStudents,
      { loading: studentsLoading, error: studentsError, data: students },
  ] = useLazyQuery(GET_STUDENTS, {client: uaaGraphQLClient})

  const searchedStudents = students?.getStudents?.data?.items?? []

  const search = (value, type) => {
      let data = {
          search: value,
      }
      if (value.length > 2) {
          if(type === 'searchStudents'){
              searchStudents({
                  variables: { pagination: { ...pagination, ...data } },
              })
          }
      }
  }

  // Selected options for hostel, block & rooms
  const [selectedHostelUid, setSelectedHostelUid] = useState(null)
  const [selectedBlockUid, setSelectedBlockUid] = useState(null)
  const [selectedRoom, setSelectedRoom] = useState(null)

  // Conditionally fetched options for blocks & rooms
  const [blocks_options, setBlocksOptions] = useState([]);
  const [rooms_options, setRoomsOptions] = useState([]);

  const { loading: hostels_loading, error: hostels_error, data: hostels_data, refetch: hostels_ref } = useQuery(GET_HOSTELS, {
    client:accomodationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: loading_blocks, error: error_blocks, data:data_blocks, refetch: refetch_blocks } = useQuery(GET_HOSTEL_BLOCKS, {
    client:accomodationGraphQLClient,
    skip: !(modalOpen && selectedHostelUid?.value),
    variables: {
      hostelUid: selectedHostelUid?.value,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: loading_rooms, error: error_rooms, data:data_rooms, refetch: refetch_rooms } = useQuery(GET_VACANT_ROOM_CAPACITIES, {
    client:accomodationGraphQLClient,
    skip: !selectedBlockUid?.value,
    variables: {
      input: {
        blockUid: selectedBlockUid?.value,
        academicYear: academic_year?.name
      }
    },
    fetchPolicy: 'cache-first',
  })

  const hostels_options = hostels_data?.getHostels.data?.items

  // Hooks to triger options quering on dependant options change
  useEffect(() => {
    if(selectedHostelUid?.value){
      const fetchData = async () => {
        try {
          await refetch_blocks();
        } catch (error) {
          console.error('Error fetching blocks:', error);
        }
      };

      fetchData();
    }
  }, [selectedHostelUid])

  // When blocks fetched
  useEffect(() => {
    const block_items = data_blocks?.getBlocksByHostel.data?.items
    if(block_items?.length){
      setBlocksOptions(block_items?.map(block => ({
        value: block.uid,
        label: block.name + ' ['+ block.code +']'
      })))

    }
  }, [data_blocks])

  // When block selected
  useEffect(() => {
    if(selectedBlockUid?.value){
      const fetchData = async () => {
        try {
          await refetch_rooms();
        } catch (error) {
          console.error('Error fetching rooms:', error);
        }
      };

      fetchData();
    }
  }, [selectedBlockUid])

  // When rooms fecthed
  useEffect(() => {
    if(data_rooms?.getVacantRoomCapacities.data?.items?.length){
      
      const rooms = data_rooms?.getVacantRoomCapacities.data?.items?.map(roomCapacity => ({
        value: roomCapacity.uid,
        label: roomCapacity.room.code + ' ('+roomCapacity.vacant+')',
      }))

      if(updateRoomAllocationData?.roomCapacity){
        setSelectedRoom({
          value: updateRoomAllocationData?.roomCapacity.uid,
          label: updateRoomAllocationData?.roomCapacity.room.code
        })
      }

      setRoomsOptions(rooms)

    }
  }, [data_rooms])

  const handleModalOpened = () => {
    // Reset previous options
    setRoomsOptions([])
    setBlocksOptions([])

    // For hostel
    if(updateRoomAllocationData?.room?.block?.hostel && hostels_options?.length){
      const hostel = hostels_options.filter((hostel) => hostel.uid == updateRoomAllocationData.room.block.hostel?.uid)
      
      if(hostel?.length){
        setSelectedHostelUid({
          value: hostel[0].uid,
          label: hostel[0].name + ' ['+ hostel[0].code +']'
        })
      }
    } else {
      setSelectedHostelUid(null)
    }

    // Set rooms
    if(updateRoomAllocationData?.room?.uid){
      setSelectedRoom(updateRoomAllocationData?.room.uid)
    } else {
      setSelectedRoom(null)
    }
    
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // academicYear: updateRoomAllocationData ? updateRoomAllocationData.academicYear : academic_year?.name,
      studentUid: updateRoomAllocationData ? updateRoomAllocationData.studentUid : "",
      roomCapacityUid: updateRoomAllocationData ? updateRoomAllocationData.room : "",
    },

    validationSchema: Yup.object().shape({
      studentUid: Yup.string().required("You must selected a student"),
      roomCapacityUid: Yup.string().required("You must select a room")
    }),

    onSubmit: (values, {setSubmitting}) => {
      create_room_allocation({
        variables: {
          inputs: [{ ...values, uid: updateRoomAllocationData?.uid }],
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_COUNTRY,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerCountries }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (registerCountries.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateRoomAllocationData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateRoomAllocationData(true) // Close the modal
            setSubmitting(false)
          }
          showToast(
            registerCountries.message,
            registerCountries.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">

      {/*<Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="fas fa-bolt me-1" />
        Process Requests
      </Button> */}
        
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Allocate
      </Button>

      <Modal
        isOpen={modalOpen || !!updateRoomAllocationData}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateRoomAllocationData ? "Edit Room Allocation" : "New Room Allocation"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="row">
              <div className="mb-1">
                <Label>
                  Student <span className="text-danger">*</span>: 
                </Label>
                <Select
                    name="studentUid"
                    placeholder="Search by registration number"
                    required
                    onChange={(e) => {
                        validation.setFieldValue('studentUid',e.value)
                    }}
                    onInputChange={e => {
                        search(e,'searchStudents')
                    }}
                    options={searchedStudents?.map(student => ({
                        value: student.uid,
                        label: student.user.firstName+
                                ' '+student.user.middleName+
                                ' '+student.user.lastName+
                                ' ['+student.user.username+']',
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    isLoading={studentsLoading}
                />
              </div>
              <div className="col-6">
                
              </div>
            </div>
            <div className="mb-1">
              <label htmlFor="academicYear" className="col-form-label">
                Academic Year <span className="text-danger">*</span>:
              </label>
              <Input
                type="text"
                name="academicYear"
                className="form-control"
                id="academicYear"
                placeholder="Enter Academic Year"
                value={academic_year?.name}
                required
                disabled
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.academicYear && validation.errors.academicYear
                    ? true
                    : false
                }
              />
              {validation.touched.academicYear && validation.errors.academicYear ? (
                <FormFeedback type="invalid">
                  {validation.errors.academicYear}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-1">
              <label htmlFor="hostel" className="col-form-label">
                Hostel <span className="text-danger">*</span>:
              </label>
              <Select
                id="hostel"
                placeholder="Select Hostel"
                value={selectedHostelUid}
                onChange={selectedOption => {
                  setBlocksOptions(null)
                  setRoomsOptions(null)
                  setSelectedBlockUid(null)
                  setSelectedRoom(null)
                  setSelectedHostelUid(selectedOption)
                }}
                options={hostels_options?.map(hostel => ({
                  value: hostel.uid,
                  label: hostel.name + ' ['+ hostel.code +']',
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
              {validation.touched.hostel && validation.errors.hostel ? (
                <FormFeedback type="invalid">
                  {validation.errors.hostel}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-1">
              <label htmlFor="block" className="col-form-label w-100">
                Block <span className="text-danger">*</span>:
                {loading_blocks? <i className="fas fa-spinner fa-spin" style={{float: 'right'}}></i> : ''}
              </label>
              
              <Select
                id="block"
                placeholder="Select Block"
                isDisabled={!blocks_options?.length}// || updateRoomAllocationData}
                value={selectedBlockUid}
                onChange={selectedOption => {
                  setRoomsOptions([])
                  setSelectedRoom(null)
                  setSelectedBlockUid(selectedOption)
                }}
                options={blocks_options}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
              {validation.touched.block && validation.errors.block ? (
                <FormFeedback type="invalid">
                  {validation.errors.block}
                </FormFeedback>
              ) : null}

            </div>

            <div className="mb-1">
              <label htmlFor="roomCapacityUid" className="col-form-label w-100">
                Room <span className="text-danger">*</span>:
                {loading_rooms? <i className="fas fa-spinner fa-spin" style={{float: 'right'}}></i> : ''}
              </label>
              <Select
                id="roomCapacityUid"
                name="roomCapacityUid"
                placeholder="Select Room"
                required
                isDisabled={!rooms_options?.length || updateRoomAllocationData}
                value={
                  updateRoomAllocationData?.room? 
                  {
                    value: updateRoomAllocationData?.room.uid,
                    label: updateRoomAllocationData?.room.code
                  } : selectedRoom
                }
                onChange={selectedOption => {
                  setSelectedRoom(selectedOption)
                  validation.setFieldValue("roomCapacityUid", selectedOption.value)
                }}
                options={rooms_options}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
              {validation.touched.roomCapacityUid && validation.errors.roomCapacityUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.roomCapacityUid}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateRoomAllocationData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>

    </div>
  )
}

export default RoomAllocationModal
