import { gql } from "@apollo/client"

export const CREATE_COUNTRY = gql`
mutation RegisterCountries($inputs: [CountryDtoInput!]!) {
  registerCountries (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items{
        id
        uid
        name
        code
        dialCode
     }
    }
  }
}
`
export const DELETE_COUNTRY = gql`
mutation RemoveCountries($uid: String!) {
  removeCountry(uid: $uid) {
    message
    code
    status
  }
}
`
export const CREATE_HOSTELS = gql`
mutation registerHostels($inputs: [HostelDtoInput!]!) {
  registerHostels (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items{
        id
        uid
        name
        code
        description
        campuses
        programs
      }
    }
  }
}
`
export const ACTIVATE_HOSTEL = gql`
mutation activateHostel($uid: String!) {
  activateHostel(uid: $uid) {
    message
    code
    status
  }
}
`
export const DELETE_HOSTEL = gql`
mutation removeHostel($uid: String!) {
  removeHostel(uid: $uid) {
    message
    code
    status
  }
}
`
export const CREATE_BLOCK = gql`
mutation registerBlocks($inputs: [BlockDtoInput!]!) {
  registerBlocks (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items{
        uid
        name
        code
        description
        isAvailable
        hostel {
          id
          uid
          name
          code
        }
      }
    }
  }
}
`
export const ACTIVATE_BLOCK = gql`
mutation activateBlock($uid: String!) {
  activateBlock(uid: $uid) {
    message
    code
    status
  }
}
`
export const DELETE_BLOCK = gql`
mutation removeBlock($uid: String!) {
  removeBlock(uid: $uid) {
    message
    code
    status
  }
}
`
export const CREATE_ROOM = gql`
mutation registerRooms($inputs: [RoomDtoInput!]!) {
  registerRooms (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items {
        id
        uid
        code
        description
        floorNumber
        isAvailable
        block {
          id
          uid
          name
          code
          description
          floorCount
          roomCount
          isAvailable
          hostel {
            id
            uid
            name
            code
            location
            description
            isAvailable
            roomCount
            availableRoomCount
            blockCount
            availableBlockCount
            campuses
            programs
          }
        }
      }
    }
  }
}
`
export const GENERATE_ROOMS = gql`
mutation generateRooms($input: RoomGenerateDtoInput!) {
  generateRooms (input: $input) {
    status
    code
    message
    data {
      totalCount
      items {
        id
        uid
        code
        description
        floorNumber
        isAvailable
        block {
          id
          uid
          name
          code
          description
          floorCount
          roomCount
          isAvailable
          hostel {
            id
            uid
            name
            code
            location
            description
            isAvailable
            roomCount
            availableRoomCount
            blockCount
            availableBlockCount
            campuses
            programs
          }
        }
      }
    }
  }
}
`
export const ACTIVATE_ROOM = gql`
mutation activateRoom($uid: String!) {
  activateRoom(uid: $uid) {
    message
    code
    status
  }
}
`
export const DELETE_ROOM = gql`
mutation removeRoom($uid: String!) {
  removeRoom(uid: $uid) {
    message
    code
    status
  }
}
`
export const CREATE_ROOM_CAPACITIES = gql`
mutation registerRoomCapacities($input: RoomCapacityDtoInput!) {
  registerRoomCapacities (input: $input) {
    status
    code
    message
  }
}
`
export const REMOVE_ROOM_CAPACITY = gql`
mutation removeRoomCapacity($uid: String!) {
  removeRoomCapacity (uid: $uid) {
    status
    code
    message
  }
}
`
export const RESERVE_ROOM = gql`
mutation markRoomCapacityReserved($uid: String!) {
  markRoomCapacityReserved(uid: $uid) {
    message
    code
    status
  }
}
`
export const CREATE_ROOM_ALLOCATION = gql`
mutation registerRoomAllocations($inputs: [RoomAllocationDtoInput!]!) {
  registerRoomAllocations (inputs: $inputs) {
    status
    code
    message
  }
}
`
export const CREATE_ROOM_REQUEST = gql`
mutation registerRoomRequests($input: RoomRequestDtoInput!) {
  registerRoomRequests (input: $input) {
    status
    code
    message
  }
}
`
export const CANCEL_ROOM_REQUEST = gql`
mutation cancelRoomRequest($input: CancelRoomRequestDtoInput!) {
  cancelRoomRequest (input: $input) {
    status
    code
    message
    data
  }
}
`
export const STUDENT_BOARDING = gql`
mutation registerBoarding($input: BoardingDtoInput!) {
  registerBoarding (input: $input) {
    status
    code
    message
  }
}
`
export const CHANGE_ROOM_ALLOCATION = gql`
mutation changeRoomAllocation($input: RoomAllocationChangeInput!) {
  changeRoomAllocation (input: $input) {
    status
    code
    message
  }
}
`
export const REGISTER_ROOM_ALLOCATION = gql`
mutation registerRoomAllocations($input: RoomAllocationDtoInput!) {
  registerRoomAllocations (input: $input) {
    status
    code
    message
  }
}
`
// export { 
//   CREATE_COUNTRY, DELETE_COUNTRY, CREATE_HOSTELS, DELETE_HOSTEL, CREATE_BLOCK, DELETE_BLOCK }
