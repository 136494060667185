import React, { createContext, useEffect, useState } from "react"

import {
  Row,
  Col

} from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { limit } from "../../../helpers/UrlHelper"
import { useLocation, useNavigate } from "react-router-dom"
import { useLazyQuery, useQuery } from "@apollo/client"
import {
  uaaGraphQLClient
} from "../../Authentication/ApolloClient"
import { GET_STUDENTS } from "../Queries/StudentsQueries"
import DataTable from "helpers/DataTable"
import { ThreeDots } from "react-loader-spinner"
import { TemporaryCardSingleStudent } from "helpers/TemporaryCardSingleStudent"
import showToast from "helpers/ToastHelper"

const breadcrumbItem = [
  {
    title: "Student List",
    path: "",
    isActive: true
  }
]
export const StudentListContext = createContext() //Create data to transfer to child modal

const StudentsList = () => {
  const location = useLocation()
  const [studentData, setStudentData] = useState(false),
    [currentPage, setCurrentPage] = useState(0),
    [offset, setOffset] = useState(0),
    [search, setSearch] = useState(null),
    navigate = useNavigate()
  const pagination = { offset: currentPage, limit: 10, search: null }

  const [getStudents, { loading, error, data }] = useLazyQuery(GET_STUDENTS, {
    client: uaaGraphQLClient,
    variables: {
      pagination: pagination
    }
  })

  const [getStudentsExcel, { loading: loadingExcel, error: excelError, data: ExcelData }] = useLazyQuery(GET_STUDENTS, {
    client: uaaGraphQLClient,
    variables: {}
  })


  function printPDF(student) {


    if (student && student.status === "CONTINUING") {
      let title = ""
      const columns = ["Student Name", "Registration number", "Sex", "Phone Number", "Status"]
      let studentData = []

      // Process the single student data
      studentData.push([
        student.fullName + " ", student.registrationNumber, student.gender, student.phoneNumber, student.status
      ])

      TemporaryCardSingleStudent(columns, studentData, title, [], "p", student.registrationNumber)
    } else {
      showToast("Student is not registered", "error", "Error")
    }
  }

  const searchValues = {
    search: location?.state?.search
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required("")
  })
  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema
  })

  const columns = [
    { key: "registrationNumber", label: "Registration Number" },
    { key: "fullName", label: "Full Name" },
    { key: "gender", label: "Sex" },
    { key: "phoneNumber", label: "Phone Number" },
    { key: "status", label: "Status" },
    {
      key: "isActive",
      label: "Is Active",
      renderer: (std, index) => (
        std?.isActive ? (
          <i className="fas fa-check" style={{ color: "green" }}></i>
        ) : (
          <i className="fas fa-times" style={{ color: "red" }}></i>
        )
      )
    }
  ]

  const actions = [
    {
      icon: "fas fa-user-edit", name: "Edit", color: "primary",
      isShowing: (std) => true,
      callback: (std) => navigate("/admission/students/update", {
        state: { student_uid: std.uid, search: formikSearch.values.search }
      })
    },
    {
      icon: "fas fa-th-list", name: "Details", color: "info",
      isShowing: (std) => true,
      callback: (std) => navigate("/admission/students/view", {
        state: { student: std, search: formikSearch.values.search }
      })
    },
    {
      icon: "fas fa-download",
      name: "Print temporary ID",
      color: "danger",
      isShowing: (std) => true,
      callback: (std) => {
        printPDF(std)
      }
    }
  ]

  const exportExel = () => {
    getStudentsExcel({
      variables: {
        pagination: { offset: 0, limit: 1000000, search: "" }
      },
      onCompleted: ({ getStudents }) => {
        const students = getStudents?.data?.items

        const data = students.map(student => [
          { value: student.uid },
          { value: student.registrationNumber },
          { value: student.fullName },
          { value: student.gender },
          { value: student.phoneNumber },
          { value: student.status },
          { value: student.isActive.toString() }  // Convert boolean to string
        ]);
      }
    })
  }

  return (
    <StudentListContext.Provider value={{ studentData, setStudentData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student List">
        <Row>
          <Col>
            <button disabled={loadingExcel} onClick={() => {
              exportExel()
            }} className="btn btn-primary btn-sm m-2">
              {
                loadingExcel ? "Please wait..." : "Export to excel"
              }
            </button>

            <DataTable
              columns={columns}
              data={data ? data?.getStudents?.data?.items : []}
              totalEntries={data ? data?.getStudents?.data?.totalCount : 0}
              totalResults={data ? data?.getStudents?.data?.searchCount : 0}// weka searchCount
              fetchData={getStudents}
              useQueryFunction={true}
              pageLimit={10}
              currentPage={offset}
              searchText={search}
              setCurrentPage={setOffset}
              setSearchText={setSearch}
              searchableLength={7}
              actions={actions}
              isLoading={loading}
              // buttons={[
              //   {name: "Create New", color: "warning", icon: "fas fa-plus"}
              // ]}
              isError={!loading && !data?.getStudents?.data?.searchCount}// weka searchCount
              loadingComponent={
                <ThreeDots color="green" height={40} width={"100%"} wrapperClass="mb-5" />
              }
              errorComponent={
                <div className="text-center mt-5 mb-5">
                  <img width={"30%"} src="/static/media/error-img.352b9ab6d7d633e85325.png" alt=""
                       className="img-fluid"></img>
                  <br />
                  <br />
                  <strong>No Student available!</strong>
                  <a
                    className="card-link text-primary ms-2"
                    onClick={e => {
                      e.preventDefault()
                      getStudents()
                    }}
                  >
                    Try again
                  </a>
                </div>
              }
            />
          </Col>
        </Row>
      </LayoutHelper>
    </StudentListContext.Provider>
  )
}

export default StudentsList
