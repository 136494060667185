// export default DistrictList;
import React, { createContext, useEffect, useState } from "react"
import {
  Row, Col, Card, CardBody, CardSubtitle, Dropdown,
  DropdownToggle,
  DropdownMenu, DropdownItem
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { useNavigate } from "react-router-dom"
import StudentSupervisorModal from "./StudentSupervisorModal"

const breadcrumbItem = [
  {
    title: "Research Supervisor's Student",
    path: "",
    isActive: true
  }
]

export const StudentSupervisorContext = createContext() //Create data to transfer to child modal

const StudentSupervisor = () => {

  const [updateStudentSupervisor, setUpdateStudentSupervisor] = useState()
  const [updateStudentSupervisorData, setStudentSupervisorData] = useState(null) //Get data for editing
  const [examinerData, setExaminerData] = useState(null)
  const State =
    {
      "name": "noble",
      "uid": "hac;aka88ala45akakak"
    }

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = pro => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [pro.uid]: !prevState[pro.uid]
    }))
  }

  const navigate = useNavigate() // navigator to other page

  const handleDeleteState = (state) => {
    console.log(state)
  }

  return (
    <StudentSupervisorContext.Provider
      value={{
        updateStudentSupervisor,
        setUpdateStudentSupervisor
      }}
    >
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Supervisor's Student Information">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      {/* <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={``}
                            placeholder="Search..."
                            // {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form> */}
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <StudentSupervisorModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th data-priority="1">S/N</Th>
                          <Th data-priority="1">First Name</Th>
                          <Th data-priority="1">Middle Name</Th>
                          <Th data-priority="1">Surname</Th>
                          <Th data-priority="1">Phone</Th>
                          <Th data-priority="1">Email</Th>
                          <Th data-priority="1">Status</Th>
                          <Th data-priority="3">Action</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>1</Td>
                          <Td>Shariff</Td>
                          <Td>H</Td>
                          <Td>Masoud</Td>
                          <Td>0710101111</Td>
                          <Td>@noble.co.tz</Td>
                          <Td>Internal</Td>
                          <Td>
                            <Dropdown
                              isOpen={dropdownOpen[State.uid]}
                              toggle={() => toggleDropdown(State)}
                            >
                              <DropdownToggle color="" caret>
                                <i
                                  className="fas fa-ellipsis-v"
                                  style={{ color: "green" }}
                                />
                              </DropdownToggle>
                              <DropdownMenu
                              >
                                <DropdownItem
                                  onClick={() => {
                                    setExaminerData(State)
                                  }}
                                >
                                  <i
                                    className="fas fa-edit"
                                    style={{
                                      color: "blue",
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>Edit</span>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    handleDeleteState(State)
                                  }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    style={{
                                      color: "red",
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>Delete</span>
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    navigate("/examiners/students", {
                                      state: { stateData: State }
                                    })
                                  }}
                                >
                                  <i
                                    className="mdi mdi-eye-outline"
                                    style={{
                                      color: "green",
                                      marginRight: "10px"
                                    }}
                                  />
                                  <span>Allocate Students</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </div>
                  {/*
                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row> */}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </StudentSupervisorContext.Provider>
  )
}

export default StudentSupervisor
