import { color } from "d3-color"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import MoodleUrlComponent from "./MoodleUrlComponent"

const OddSemisterCourseList = ({ courseData }) => {
  return (
    <React.Fragment>
      <Col xl={4} xs={6} sm={6} md={4} lg={4}>
        <Card className="text-center primary">
          <CardBody>
            {/* <div className="alert alert-info font-weight-bolder"> */}
            <Link
              to={`/examination/instructor/course_allocations/${courseData?.uid}`}
              className="text-dark"
            >
              <h5 className="font-size-15 mb-1">
                {/* <Link
                to={`/instructor/course_allocations/${courseData?.uid}`}
                className="text-dark"
              > */}
                <b>{courseData?.programCourse?.course?.code}</b>
                {/* </Link> */}
              </h5>

              {/* <Link
                to={`/instructor/course_allocations/${courseData?.uid}`}
                className="text-dark"
              > */}
              <p className="text-muted">
                {courseData?.programCourse?.course?.name}
              </p>
              {/* </Link>


            <Link
                to={`/instructor/course_allocations/${courseData?.uid}`}
                className="text-dark"
            > */}
              <p className="text-dark">
                {/* <b> */}
                {courseData?.programCourse?.programSemester?.program?.name}
                {/* </b> */}
              </p>
            </Link>

            {courseData?.programCourse?.course?.moodleId !== null ? (
              <p className="text-muted">
                <div>
                  <MoodleUrlComponent
                    key={courseData?.programCourse?.course?.moodleId}
                    moodleId={courseData?.programCourse?.course?.moodleId}
                  />
                </div>

                {/* <b><a rel="noreferrer" target="_blank" href={MoodleCourseURLData?.data}>View Course in Moodle</a></b> */}
              </p>
            ) : (
              <p style={{ color: "red" }}>Not yet linked with Moodle</p>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default OddSemisterCourseList
