import {useLazyQuery, useQuery} from "@apollo/client";
import {
    GENERATE_ACADEMIC_UNIT_RESULTS_XLS,
    GENERATE_SEMESTER_EXAMINATION_RESULTS_XLS
} from "../Queries/ResultReportQueries";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {useFormik} from "formik";
import * as Yup from "yup";
import {DownloadExcelFile} from "../../../../helpers/DownloadExcelFile";
import showToast from "../../../../helpers/ToastHelper";
import React, {useState} from "react";
import {GET_ALL_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
const breadcrumbItem = [
    {
        title: "Academic Unit Result",
        path: "",
        isActive: true,
    },
]
const AcademicUnitResult = () => {
    const [generateReport] = useLazyQuery(GENERATE_ACADEMIC_UNIT_RESULTS_XLS,
        {
            client: registrationGraphQLClient
        })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            programUid: "",
            academicYearUid: "",
            // semester:"",
            yearOfStudy:""
        },
        validationSchema: Yup.object().shape({
            programUid: Yup.string().required("Program is required"),
            academicYearUid: Yup.string().required("Academic year is required"),
            // semester: Yup.number().required("Semester is required"),
            yearOfStudy: Yup.number().required("Study Year is required"),
            // staffUid: Yup.array().required("Staff is required").min(1, 'Select at least one Staff')
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            generateReport({
                variables: {
                    programUid: values.programUid,
                    academicYearUid: values.academicYearUid,
                    semester: values.semester,
                    yearOfStudy: values.yearOfStudy,
                },
                fetchPolicy: "no-cache",
                client: registrationGraphQLClient,
                onCompleted: ({ generateAcademicUnitResults }) => {
                    if (generateAcademicUnitResults?.code === 8000) {
                        DownloadExcelFile(generateAcademicUnitResults?.data?.base64Data,generateAcademicUnitResults?.data?.fileName)
                    }
                    showToast(
                        generateAcademicUnitResults.message,
                        generateAcademicUnitResults.code === 8000 ? "success" : "error"
                    )
                    setSubmitting(false)
                },
                onError: error => {
                    console.error("Mutation error:", error)
                    setSubmitting(false)
                },
            })

        },

    });
    const [yearOfStudy,setYearOfStudy] = useState([])



    const searchValues = {
        search: "",
    }
    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })
    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })

    const [
        searchPrograms,
        { loading: programSearchLoading, error: searchError, data: searchProgram },
    ] = useLazyQuery(GET_ALL_PROGRAMS, {client: registrationGraphQLClient})

    const pagination = { offset: 0, limit: 5000, search: null }

    const { loading:programLoading, error:programError, data:programData } = useQuery(GET_ALL_PROGRAMS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })


    let programs = programData?.getAllPrograms?.data?.items
    if (searchProgram?.getAllPrograms?.data)
        programs = searchProgram?.getAllPrograms?.data?.items


    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })
    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items


    const search = (value,type) => {
        // console.log(type)
        // console.log(validation.values.programUid)
        let data = {
            search: value,
        }
        if (type === 'program'){
            if(value !== undefined){
                if(value.length < 4) return false
                searchPrograms({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }
        }
    }



    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Academic Unit Result">
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                            <Label>Program</Label>
                                            <Select
                                                // value={selectedMulti2}
                                                styles={{
                                                    menu: base => ({
                                                        ...base,
                                                        position: "absolute",
                                                        zIndex: 9999
                                                    })
                                                }}
                                                onChange={e => {
                                                    if (e) {
                                                        validation.setFieldValue("programUid", e.value)
                                                        let study_duration = []
                                                        setYearOfStudy([])
                                                        // console.log(e.value)
                                                        let value = e.duration
                                                        for (let i = 1; i < value + 1; i++) {
                                                            study_duration.push({label: 'Year ' + i, value: i})
                                                        }
                                                        setYearOfStudy(study_duration)
                                                    } else {
                                                        validation.setFieldValue("programUid", "")
                                                    }
                                                }}
                                                onInputChange={e => {
                                                    search(e, 'program')
                                                }}

                                                options={programs?.map(pro => ({
                                                    value: pro.uid,
                                                    duration: pro.duration,
                                                    label: pro.name + " (" + pro.registrationCode + ")",
                                                }))}
                                                className="select2-selection"
                                                isClearable={true}
                                                isLoading={programLoading || programSearchLoading}
                                            />
                                            <Input
                                                name="programUid"
                                                placeholder=""
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.programUid}
                                                invalid={
                                                    !!(
                                                        validation.touched.programUid &&
                                                        validation.errors.programUid
                                                    )
                                                }
                                            />
                                            {validation.touched.programUid &&
                                            validation.errors.programUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.programUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                onChange={(e) => {
                                                    if (e) {
                                                        validation.setFieldValue("academicYearUid", e.value)
                                                    } else {
                                                        validation.setFieldValue("academicYearUid", "")
                                                    }
                                                }}
                                                onInputChange={e => {
                                                    search(e, 'academicYear')
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.uid,
                                                    label: year.name,
                                                }))}

                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYearUid}
                                                invalid={
                                                    !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                }
                                            />
                                            {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYearUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Study Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                // name="yearOfStudy"
                                                onChange={(e) => {
                                                    validation.setFieldValue('yearOfStudy', e.value)
                                                }}
                                                options={yearOfStudy}
                                                className="select2-selection"
                                            />
                                            <Input
                                                name="yearOfStudy"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.yearOfStudy || ""}
                                                invalid={
                                                    !!(validation.touched.yearOfStudy && validation.errors.yearOfStudy)
                                                }
                                            />
                                            {validation.touched.yearOfStudy && validation.errors.yearOfStudy ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.yearOfStudy}
                                                </FormFeedback>
                                            ) : null}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="float-end">
                                            <SubmitButtonHelper type={'primary'} name={'Submit'}
                                                                formik={validation}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </LayoutHelper>
    )
}

export default AcademicUnitResult