// export default HostelList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useNavigate } from "react-router-dom"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning, showWaringConf } from "helpers/DeleteWaringHelper"
import { GET_HOSTELS } from "../Queries/AccommodationQueries"
import { ACTIVATE_HOSTEL, DELETE_HOSTEL } from "../Mutations/AccommodationMutations"
import HostelModal from "./HostelModal"

import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {uaaGraphQLClient, accomodationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"


const breadcrumbItem = [
  {
    title: "Hostel List",
    path: "",
    isActive: true,
  },
]

export const HostelContext = createContext() //Create data to transfer to child modal

const HostelList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedHostel, setSelectedHostel] = useState(null) //Get data for deleting
  const [updateHostel, setUpdateHostel] = useState(null) //Get data for editing

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = hostel => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [hostel.uid]: !prevState[hostel.uid],
    }))
  }

  const { loading, error, data, refetch } = useQuery(GET_HOSTELS, {
    client:accomodationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const [searchCountries,{ loading: searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_HOSTELS,{
    client:accomodationGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {
      // console.log("DataReceived", data)

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected;
   // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteHostel] = useMutation(DELETE_HOSTEL, {
    client: accomodationGraphQLClient,
    variables: { uid: selectedHostel?.uid },
    refetchQueries: [
      {
        query: GET_HOSTELS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const [activateHostel] = useMutation(ACTIVATE_HOSTEL, {
    client: accomodationGraphQLClient,
    variables: { uid: selectedHostel?.uid },
    refetchQueries: [
      {
        query: GET_HOSTELS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const navigate = useNavigate();

  const showBlockList = (hostel) => {
    navigate('/accommodation/hostels/blocks', {state: {hostel: hostel}});
  }

  const changeAvailability = (hostel) => {
    setSelectedHostel(hostel)
    const title = hostel?.isAvailable? 'Close Hostel' : 'Open Hostel'
    const text = 'Are you sure to ' + (hostel?.isAvailable? 'close' : 'open') + ' this hostel?'
    showWaringConf(async () => {
        try {
          const response = await activateHostel()
          showToast(response?.data?.activateHostel.message, response?.data?.activateHostel.code === 8000 ? "success" : 'error');
        } catch (error) {
          console.error("Error deleting a hostel:", error)
        }
      }, title, text,'warning', hostel?.isAvailable? 'Close' : 'Open',
      () => {
          // console.log("Canceled")
      }
    )
  }

  const handleDeleteHostel = (deleteData) => {
    setSelectedHostel(deleteData)
    showDeleteWarning(() => {
      performHostelDeletion()
    })
  }

  const performHostelDeletion = async () => {
    try {
      const response = await deleteHostel()
      showToast(response.data.removeHostel.message, response.data.removeHostel.code === 8000 ? "success" : 'error');
    } catch (error) {
      console.error("Error deleting hostel:", error)
    }
  }

  if (loading) {
    return (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
    )
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchCountries({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }
  const dataArrray = data?.getHostels.data?.items
  const searchedData = searchData?.getHostels?.data?.items
  const result = searchedData ? searchedData : dataArrray
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getHostels?.data?.totalCount || searchData?.getCountries?.data?.items || 0

  const HTMLTableCell = ({ htmlContent }) => (
    <td dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );

  return (
    <HostelContext.Provider 
        value={{ 
              updateHostel, 
              setUpdateHostel 
              }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Hostel List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <HostelModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      // className="table-responsive mb-0"
                      className="mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table-sm table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="8">S/N</Th>
                            <Th data-priority="1">Code</Th>
                            <Th data-priority="2">Name</Th>
                            <Th data-priority="3">Description</Th>
                            <Th data-priority="9">Location</Th>
                            <Th data-priority="7">Dedicated For</Th>
                            <Th data-priority="6">Blocks</Th>
                            <Th data-priority="5">Rooms</Th>
                            <Th data-priority="4">Availability</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result?.slice(startIndex, endIndex)
                            .map((hostel, index) => (
                              <Tr key={hostel?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{hostel?.code}</Td>
                                <Td>{hostel?.name}</Td>
                                <Td>{hostel?.description}</Td>
                                <Td>{hostel?.location}</Td>
                                <Td>{
                                        (hostel?.campuses?.length ?? 0)? 
                                        'Campuses: '+hostel.campuses.join(', ') 
                                        : 
                                        (hostel?.programs?.length ?? 0)? 
                                        'Programs: '+hostel.programs.join(', ')
                                        :
                                        'None'
                                    }
                                </Td>
                                <Td>{
                                      hostel?.blockCount?
                                      hostel?.availableBlockCount+'/'+hostel?.blockCount
                                      :
                                      'None'
                                    }
                                </Td>
                                <Td>{
                                      hostel?.roomCount? 
                                      hostel?.availableRoomCount+'/'+hostel?.roomCount
                                      :
                                      'None'
                                    }
                                </Td>
                                <HTMLTableCell htmlContent={
                                    hostel?.isAvailable? 
                                    '<span class="btn btn-rounded btn-sm btn-info">Available</span>' 
                                    : 
                                    '<span class="btn btn-rounded btn-sm btn-warning">Unvailable</span>'
                                }/>
                                <Td>
                                  <Dropdown
                                    isOpen={dropdownOpen[hostel.uid]}
                                    toggle={() => toggleDropdown(hostel)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateHostel(hostel)
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeAvailability(hostel)
                                        }}
                                      >
                                        <i
                                          className={
                                            hostel?.isAvailable? 'fas fa-lock' : 'fas fa-lock-open'
                                          }
                                          style={{
                                            color: hostel?.isAvailable? "orange" : 'blue',
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>
                                          {
                                            hostel?.isAvailable? 'Close' : 'Open'
                                          }
                                        </span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          showBlockList(hostel)
                                        }}
                                      >
                                        <i
                                          className="fas fa-building"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Blocks</span>
                                      </DropdownItem>
                                      
                                      <DropdownItem
                                        onClick={() => {
                                          handleDeleteHostel(hostel)
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </HostelContext.Provider>
  )
}

export default HostelList
