import React, { createContext, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useNavigate } from "react-router-dom"
import ProgramCapacityModal from "./ProgramCapacityModal"
import { GET_PROGRAM_CAPACITY } from "../Queries/ProgramAdmissionQueries"
import { DELETE_PROGRAM_ADMISSION_CAPACITY } from "../Mutations/ProgramAdmissionMutations"

export const AdmissionCapacityListContext = createContext(undefined) //Create data to transfer to child modal

const ProgramCapacityList = () => {

  const breadcrumbItem = [
    {
      title: "Program requirements",
      path: "",
      isActive: true
    }
  ]


  const [pageSize, setPageSize] = useState(limit)
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const [selectedAdmissionCapacity, setSelectedAdmissionCapacity] = useState(null) //Get data for deleting
  const [updateAdmissionCapacityData, setUpdateAdmissionCapacityData] = useState(null) //Get data for editing
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = campus => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [campus.uid]: !prevState[campus.uid]
    }))
  }

  const searchValues = {
    search: ""
  }

  const navigate = useNavigate()

  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required("")
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    }
  })

  const { loading, error, data, refetch } = useQuery(GET_PROGRAM_CAPACITY, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })

  const programAdmission = data?.getProgramCapacities?.data?.items

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteAdmissionCapacityList] = useMutation(DELETE_PROGRAM_ADMISSION_CAPACITY, {
    client: onlineApplicationGraphQLClient,
    variables: { uid: selectedAdmissionCapacity?.uid },
    refetchQueries: [
      {
        query: GET_PROGRAM_CAPACITY,
        variables: {
          pagination: paginationHelper
        }
      }
    ]
  })

  const handleDeleteAdmissionCapacityList = () => {
    showDeleteWarning(() => {
      performAdmissionCapacityListDeletion().then(r => true)
    })
  }

  const performAdmissionCapacityListDeletion = async () => {
    try {
      const response = await deleteAdmissionCapacityList()
      showToast(
        response.data.removeCountry.message,
        response.data.removeCountry.code === 8000 ? "success" : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getProgramCapacities?.data?.totalCount || 0
  return (
    <AdmissionCapacityListContext.Provider
      value={{ updateAdmissionCapacityData, setUpdateAdmissionCapacityData }}
    >
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Program Capacities">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-12 col-sm-12 col-md-12 float-right">
                      <ProgramCapacityModal />
                    </Col>
                  </Row>
                </CardSubtitle>
                {
                  loading && (
                    <PlaceHolderLoader rows={4} type="table" columSize={12} />
                  )
                }

                {!loading && !error && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="2">Program Name</Th>
                            <Th data-priority="3">Admission Year</Th>
                            <Th data-priority="4">Maximum Equivalent Students</Th>
                            <Th data-priority="4">Maximum Direct Students</Th>
                            <Th data-priority="4">Capacity</Th>
                            <Th data-priority="5">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {programAdmission
                            ?.slice(startIndex, endIndex)
                            .map((list, index) => (
                              <Tr key={list?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{list?.programName}</Td>
                                <Td>{list?.admissionYear?.name}</Td>
                                <Td>{list?.capacityEquivalent}</Td>
                                <Td>{list?.capacityDirect}</Td>
                                <Td>{list?.capacity}</Td>
                                <Td>
                                  <Dropdown
                                    isOpen={dropdownOpen[list.uid]}
                                    toggle={() => toggleDropdown(list)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateAdmissionCapacityData(list)
                                        }}
                                      >
                                        <i
                                          className="fas fa-pen"
                                          style={{
                                            color: "green",
                                            marginRight: "10px"
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedAdmissionCapacity(list)
                                          handleDeleteAdmissionCapacityList()
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px"
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                      <DropdownItem
                                            onClick={() => {
                                              navigate("/admission/application/program-requirements",{state:{program:list}})
                                            }}>
                                          <i className="mdi mdi-eye-outline" style={{color: 'green', marginRight: '10px'}}  />
                                          <span>Program Requirement</span>
                                        </DropdownItem>   
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </AdmissionCapacityListContext.Provider>
  )
}

export default ProgramCapacityList
