import React, { createContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import ReactPaginate from "react-paginate"
import { useFormik } from "formik"
import { Link } from "react-router-dom"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { paginationHelper } from "../../../helpers/Functions/GraphqlUpdateFunction"
import showToast from "../../../helpers/ToastHelper"
import { showDeleteWarning } from "../../../helpers/DeleteWaringHelper"
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader"
import { GET_COURSES } from "../Queries/CourseQueries"
import { DELETE_COURSE } from "../Mutations/CourseMutations"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import { limit } from "../../../helpers/UrlHelper"
import PaginationHelper from "../../../helpers/PaginationHelper"
import CourseFormModel from "./CourseFormModel"
import {LoaderPage} from "../../../helpers/LoaderPage";

const breadcrumbItem = [
  {
    title: "Courses List",
    path: "",
    isActive: true,
  },
]

export const CourseContext = createContext()
const CourseList = () => {
  const [courseUid, setCourseUid] = useState(null),
    [courseData, setCourseData] = useState(null),
    [pageSize, setPageSize] = useState(limit),
    [currentPage, setCurrentPage] = useState(0),
    navigate = useNavigate()

  const pagination = { offset: currentPage, limit: limit, search: null }
  const { loading, error, data } = useQuery(GET_COURSES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: pagination,
    },
  })
  const [
    searchQuery,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_COURSES, { client: registrationGraphQLClient })

  // if (error) return showToast("Failed to fetch Programs", "error")
  let courses = data?.getCourses?.data?.items
  let totalCount = data?.getCourses?.data?.totalCount
  if (searchData?.getCourses?.data?.items) {
    courses = searchData?.getCourses?.data?.items
    totalCount = searchData?.getCourses?.data?.totalCount
  }
  // return 1
  const handleClick = data => {
    setCurrentPage(data.selected)
  }

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })
  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
  })
  const search = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchQuery({ variables: { pagination: { ...pagination, ...data } } })
    }
  }
  const [finalizeDelete] = useMutation(DELETE_COURSE, {
    client: registrationGraphQLClient,
    variables: { uid: courseUid },
    refetchQueries: [
      {
        query: GET_COURSES,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })
  const confirmDelete = async () => {
    try {
      const response = await finalizeDelete()
      showToast(response.data.removeCourse.message, "success")
      // console.log("User deleted successfully:", response)
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  const handleDelete = () => {
    if (courseUid !== "") {
      showDeleteWarning(() => {
        confirmDelete()
        setCourseUid(false)
      })
    } else {
      showToast("Please Select Program to delete", "warning")
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = course => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [course.uid]: !prevState[course.uid],
    }))
  }

  if(loading|| searchLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Courses"} description={"Fetching Courses List"} />



  return (
    <CourseContext.Provider value={{ courseData, setCourseData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Courses List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={search}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                          {!searchLoading && (
                            <span className="bx bx-search-alt" />
                          )}
                          {searchLoading && (
                            <span className="bx bx-search-alt bx-spin" />
                          )}
                        </div>
                      </form>
                    </Col>

                    <Col sm="6">
                      <CourseFormModel />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th>S/No</Th>
                            <Th>Name</Th>
                            <Th>Code</Th>
                            <Th>Is Offered ?</Th>
                            <Th>Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {courses?.map((pro, index) => (
                            <Tr key={pro?.uid}>
                              <Td>{currentPage * pageSize + index + 1}</Td>
                              <Td>{pro?.name}</Td>
                              <Td>{pro?.code}</Td>
                              <Td>{pro?.offered ? "YES" : "NO"}</Td>
                              <Td>
                                <Dropdown
                                  isOpen={dropdownOpen[pro.uid]}
                                  toggle={() => toggleDropdown(pro)}
                                >
                                  <DropdownToggle color="" caret>
                                    <i
                                      className="fas fa-ellipsis-v"
                                      style={{ color: "blue" }}
                                    />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => {
                                        setCourseData(pro)
                                      }}
                                    >
                                      <i
                                        className="fas fa-user-edit"
                                        style={{
                                          color: "blue",
                                          marginRight: "10px",
                                        }}
                                      />
                                      <span>Edit</span>
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        setCourseUid(pro.uid)
                                        handleDelete()
                                      }}
                                    >
                                      <i
                                        className="fas fa-trash"
                                        style={{
                                          color: "red",
                                          marginRight: "10px",
                                        }}
                                      />
                                      <span>Delete</span>
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        navigate("/examination/courses/view", {
                                          state: { course: pro },
                                        })
                                      }}
                                    >
                                      <i
                                        className="fas fa-th-list"
                                        style={{
                                          color: "primary",
                                          marginRight: "10px",
                                        }}
                                      />
                                      <span>Detail</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>

                    <PaginationHelper
                      currentPage={currentPage}
                      pageItemNumber={courses.length}
                      totalCount={totalCount}
                      pageSize={pageSize}
                      handleClick={handleClick}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </CourseContext.Provider>
  )
}

export default CourseList
