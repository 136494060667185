import React from "react";
import { Route, Routes } from "react-router";
import EntryCategoryList from "../Views/EntryCategoryList";
import Pages404 from "Modules/Utility/pages-404";

const EntryCategoryRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<EntryCategoryList />} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    )
}

export default EntryCategoryRoutes