import React from "react";
import { Route, Routes } from "react-router-dom"
import Pages404 from "../../Utility/pages-404"
import UsersList from "../Views/UsersList";
import UserForm from "../Views/UserForm";
import StaffProfile from "../Views/StaffProfile";
import UserDetail from "../Views/UserDetail";
import ChangePasswordModal from "components/CommonForBoth/TopbarDropdown/ChangePasswordModal";
import AssignPermission from "Modules/Groups/Views/AssignPermission";
import StudentEmailRecovery from "../../Students/Views/Recovery/StudentEmailRecovery"

const UserRoutes = (props) => {
  return (
    <Routes>
      <Route path="/"  element={<UsersList />} />
      <Route path="/create"  element={<UserForm/>} />
      <Route path="/user-profile"  element={<StaffProfile />} />
      <Route path="/user-detail"  element={<UserDetail />} />
      <Route path="/recover-account-password"  element={<StudentEmailRecovery />} />
      <Route path="/change-password"  element={<ChangePasswordModal />} />
      <Route path="/assign-permissions/:groupId"  element={<AssignPermission />} />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  );
};
export default UserRoutes;
