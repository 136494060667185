import React, {createContext, useState} from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, DropdownToggle, DropdownMenu, DropdownItem, Dropdown, CardTitle,
} from "reactstrap"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import {GET_FEE_STRUCTURE} from "../../Queries/Payment/StudentPaymentQueries";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {useLazyQuery, useQuery} from "@apollo/client";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import showToast from "../../../../helpers/ToastHelper";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";

const breadcrumbItem =  [
    {
        title: 'Fee Structure',
        path: '',
        isActive: true,
    },
]

const StudentFeeStructure = () => {
    const userData = JSON.parse(localStorage.getItem("authUser"))
    const dataInfo = userData?.data
    // const logedIn = LogedInStudent()

    const { loading,error, data } = useQuery(GET_FEE_STRUCTURE, {
        client:registrationGraphQLClient,
        skip:!dataInfo?.student?.programmeUid,
        variables: {
            inputs:{
                programUid:dataInfo?.student?.programmeUid,
                yearOfStudy:dataInfo?.student?.studyYear,
                studentStatus:dataInfo?.student?.status?.name,
                countrycode:dataInfo?.country?.dialCode
            }
        }
    })
    const [
        searchQuery,
        { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_FEE_STRUCTURE, {client: registrationGraphQLClient})

    const feeStructures = data?.getFeeStructure?.data
    // if(!feeStructures){
    //     return showToast('Fee structure not found', "info")
    // }
    if (error) {
        return showToast(error, "error")
    }
    return (
        <LayoutHelper pageTitle={'Fee Structure'} breadcrumbItem={breadcrumbItem} >
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <h5>{`FEE STRUCTURE`}</h5>
                            </CardTitle>

                            {loading  ? (
                                <PlaceHolderLoader columSize={12} type="table" rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                    >
                                        <Table
                                            className="table table-striped table-bordered"
                                        >
                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th>Fee Name</Th>
                                                    <Th>Amount</Th>
                                                    <Th>Min Amount</Th>
                                                    <Th>Currency</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {feeStructures?.map((fee, index) => (
                                                        <Tr key={`key-${index}`}>
                                                            <Td>{index+1}</Td>
                                                            <Td>{fee?.name}</Td>
                                                            <Td className="text-end">{fee?.amount.toLocaleString()}</Td>
                                                            <Td className="text-end">{fee?.minAmount.toLocaleString()}</Td>
                                                            <Td>{fee?.currency}</Td>
                                                        </Tr>
                                                    ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}
export default StudentFeeStructure;
