import React, {createContext} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {GET_STUDENT_SEMINAR_TYPE} from "../../Students/Views/Dissertation/Queries/StudentDissertationQueries";
import {useMutation, useQuery} from "@apollo/client";
import PlaceHolderLoader from "../../../helpers/PlaceHolderLoader";
import {CREATE_STUDENT_SEMINAR} from "../../Lookups/SeminarType/Mutations/SeminarTypeMutations";
import showToast from "../../../helpers/ToastHelper";
import {showWaringConf} from "../../../helpers/DeleteWaringHelper";
import { format } from 'date-fns';
import {NONE} from "draft-js/lib/SampleDraftInlineStyle";
import {limit} from "../../../helpers/UrlHelper";
import {GET_ALL_STUDENT_SEMINARS} from "../Queries/DesignationQueries";
import StudentSeminarForm from "./Forms/StudentSeminarForm";
import {GET_PROGRAM} from "../../Programs/Queries/ProgramQuaries";

const breadcrumbItem = [
    {
        title: "Dissertation",
        path: "/dissertation",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]
const ViewStudentSeminar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    if(!location.state) navigate('/dissertation')
    const { sem } = location.state
    const pagination = { offset: 0, limit: limit, search: null }
    const [submit] = useMutation(CREATE_STUDENT_SEMINAR)
    const { loading, error, data, refetch } = useQuery(GET_STUDENT_SEMINAR_TYPE, {
        client: registrationGraphQLClient,
        skip:!sem?.studentUid,
        variables: {
            inputs:{
                studentUid:sem?.studentUid,
                seminarTypeUid:sem?.seminarTypes?.uid
            },
        },
    })

    // const { loading:programLoad, error:programError, data:programData } = useQuery(GET_PROGRAM, {
    //     client:registrationGraphQLClient,
    //     skip: !student?.programmeUid,
    //     variables: {
    //         uid: student?.programmeUid,
    //     },
    // })


    if (loading) {
        return <PlaceHolderLoader columSize={12} type={"rows"} rows={2} />
    }


    const studentSeminarType = data?.getStudentSeminarsByStudentUid?.data
    const seminar = studentSeminarType ? studentSeminarType[0] : false
    const title = `${sem?.seminarTypes?.name} - Title:  ${sem?.title}`
    const handleSubmitSeminarType = () => {
        let type = seminar.status === 1 ? 'Seminar Date':'Seminar Marks Score'
        showWaringConf(() => {
                confirmSubmit()
            },`Submit ${type}`,`Are you sure you need to submit ${type}? Student will view submitted results`,'warning','Submit',
            () => {
                // setPayType(null)
            })
    }

    // console.log(seminar)
    const confirmSubmit = async () => {
        // console.log("Sejdvdv "+seminar?.seminarTypes?.uid)
        // console.log()
        // return false
        try {
            await submit(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs:{
                            title: seminar.title,
                            description: seminar.description,
                            status:seminar?.status+1,
                            uid:seminar.uid,
                            // isPass:seminar?.isPass,
                            seminarDate:seminar?.seminarDate,
                            // seminarMarks:seminar?.seminarMarks,
                            seminarTypesUid:seminar?.seminarTypes?.uid,
                            studentUid:seminar?.studentUid
                        }
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_SEMINAR_TYPE,
                            variables: {
                                inputs:{
                                    studentUid:seminar?.studentUid,
                                    seminarTypeUid:seminar?.seminarTypes?.uid
                                },
                            },
                        },
                        {
                            client: registrationGraphQLClient,
                            query: GET_ALL_STUDENT_SEMINARS,
                            variables: {
                                pagination: pagination,
                            },
                        },
                    ],
                    onCompleted: ({registerStudentSeminar}) => {
                        if (registerStudentSeminar.code === 8000) {
                            showToast(registerStudentSeminar.message, "success")
                        } else {
                            showToast(
                                registerStudentSeminar.message,
                                registerStudentSeminar.code === 8000 ? "success" : "error"
                            )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }

    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`${title}`} >
            {studentSeminarType &&
                <>
                    {seminar.status === 1  && <StudentSeminarForm seminar={seminar} />}
                    {seminar.status === 2 && <StudentSeminarForm seminar={seminar} />}
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center text-uppercase"><h3>Title: {seminar.title}</h3></CardTitle>
                                    <CardSubtitle>
                                        <ul style={{listStyleType:"none",marginLeft:'-3%',fontSize:'120%'}}>
                                            <li>
                                                <strong>Student Name: </strong> <span className="badge bg-success"> {sem?.fullName}</span>
                                            </li>
                                            <li>
                                                <strong>Registration No: </strong> <span className="badge bg-success"> {sem?.registrationNumber}</span>
                                            </li>
                                            <li>
                                                <strong>Status:</strong>
                                                {seminar.status === 0 && <span className="badge bg-danger"> Un submitted/Pending ...</span>}
                                                {seminar.status === 1 && <span className="badge bg-danger"> Seminar Date Allocation Pending..</span>}
                                                {seminar.status === 2 && <span className="badge bg-danger"> Seminar Assessment Score Pending..</span>}
                                                {seminar.status === 3 && <span className="badge bg-success"> Seminar Results Published</span>}
                                            </li>
                                            <li>
                                                <strong>Seminar Date: </strong> <span className="badge bg-success"> {seminar?.seminarDate ? format(new Date(seminar?.seminarDate), 'MMMM dd, yyyy'):''}</span>
                                            </li>
                                            {seminar.status >= 2 &&
                                                <>
                                                    <li>
                                                        <strong>Seminar Score: </strong> <span className="badge bg-success"> {seminar?.seminarMarks}</span>
                                                    </li>
                                                    <li>
                                                        <strong>Seminar Passed ?: </strong> <span className={`badge bg-${seminar?.isPass ? 'success':'danger'}`}> {seminar?.isPass ? 'YES':'NO'}</span>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </CardSubtitle>
                                    <hr/>
                                    <div style={{
                                        maxHeight:'700px', overflow: 'auto'
                                    }} dangerouslySetInnerHTML={{ __html: seminar.description }}/>
                                </CardBody>
                                <CardFooter>
                                    {seminar?.status === 1 && seminar?.seminarDate && <Button onClick={() => handleSubmitSeminarType()} className="btn btn-success float-start">Submit {seminar?.status === 1 ? 'Seminar Date':'Seminar Score'}</Button>}
                                    {seminar?.status === 2 && seminar?.seminarMarks !== 0 && <Button onClick={() => handleSubmitSeminarType()} className="btn btn-success float-start">Submit {seminar?.status === 1 ? 'Seminar Date':'Seminar Score'}</Button>}
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
            {/*<CourseLeaningOutcomes course={course} />*/}
        </LayoutHelper>
    );
}

export default ViewStudentSeminar;