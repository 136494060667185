import Pages404 from "Modules/Utility/pages-404";
import React from "react";
import { Route, Routes } from "react-router";
import DistrictList from '../Views/DistrictList'

const DistrictRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<DistrictList />} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    )
}

export default DistrictRoutes;