//REGISTE
import { gql } from '@apollo/client';

const STUDENT_GET_ALL_PROGRAMS = gql`
  query GetAllPrograms($pagination: PaginationInput!) {
    getAllPrograms(pagination: $pagination){
        status
        code
        message
        data{
          items{
            uid
            code
            name
            shortName
            tcuCode
            nacteCode
            duration
            registrationCode
            departmentUid
            programCategory{
              uid
              name
              shortName
            }
          }
          totalCount
        }
  }
}
`

const GET_CATEGORY_PROGRAMS_BY_PROGRAM_UID = gql`
  query GetProgramsOnProgramCategory($programUid: String!) {
    getProgramsOnProgramCategory(programUid: $programUid){
        status
        code
        message
        data{
          items{
            uid
            code
            name
            shortName
            tcuCode
            nacteCode
            duration
            registrationCode
            departmentUid
            programCategory{
              uid
              name
              shortName
            }
          }
          totalCount
        }
  }
}
`


const GET_STUDENT_CHANGE_PROGRAM_REQUEST = gql`
  query GetStudentChangePrograms($studentUid: String!) {
       getStudentChangePrograms(studentUid:$studentUid){
        message
        status
        code
        data{
           uid, currentRegistrationNumber,reason
          academicYear{
            name
          }
          currentProgram{
            uid,name
          }
          newProgram{
            uid, name
          }
          approveStatus, approveRemark
        }
      }
}
`

const GET_STUDENT_STUDY_POSTPONEMENT_REQUEST = gql`
  query GetStudentPostponementRequest($studentUid: String!) {
    getStudentPostponementRequest(studentUid:$studentUid){
        message
        status
        code
        data{
           uid, currentRegistrationNumber,reason
          academicYear{
            name
          }
          currentProgram{
            uid,name
          }
          newProgram{
            uid, name
          }
          approveStatus, approveRemark
        }
      }
}
`

const GET_STUDY_POSTPONEMENT_REQUESTS = gql`
  query GetStudyPostponementRequests($pagination: PaginationInput!) {
    getStudyPostponementRequests(pagination:$pagination){
        message
        status
        code
        data{
           uid, currentRegistrationNumber,reason
          academicYear{
            name
          }
          currentProgram{
            uid,name
          }
          newProgram{
            uid, name
          }
          approveStatus, approveRemark
        }
      }
}
`
const GET_APPROVAL_HISTORY = gql`
query GetApprovalHistory($requestId: ID!) {
  getApprovalHistory(requestId: $requestId) {
    message
    status
    code
    data{
      approvalId
      requestId
      approverId
      action
      timestamp
    }
   
  }
}
` 

const GET_EXAM_POSTPONEMENT_REQUESTS = gql`
  query GetExamPostponementRequests($pagination: PaginationInput!) {
    getExamPostponementRequests(pagination:$pagination){
        message
        status
        code
        data{
           uid, currentRegistrationNumber,reason
          academicYear{
            name
          }
          currentProgram{
            uid,name
          }
          newProgram{
            uid, name
          }
          approveStatus, approveRemark
        }
      }
}
`
const GET_EXAM_POSTPONEMENT = gql`
  query GetExamPostponement($studentUid: String!) {
      getExamPostponement(studentUid:$studentUid){
        message
        status
        code
        data{
           uid 
        }
      }
}
`

export { GET_CATEGORY_PROGRAMS_BY_PROGRAM_UID,GET_STUDENT_CHANGE_PROGRAM_REQUEST,  GET_STUDENT_STUDY_POSTPONEMENT_REQUEST, GET_EXAM_POSTPONEMENT,GET_STUDY_POSTPONEMENT_REQUESTS, GET_EXAM_POSTPONEMENT_REQUESTS, GET_APPROVAL_HISTORY}
