import { gql } from "apollo-boost";

const GET_CERTIFICATE_TYPES = gql `
query GetCertificateType($pagination: PaginationInput!) {
    getCertificateTypes(pagination: $pagination){
      status
      code
      message
      data{
        items{
           id
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`

export { GET_CERTIFICATE_TYPES };