import React from 'react'
import {Route, Routes} from "react-router-dom";
import AcademicYearList from "../Views/AcademicYearsList";
import Pages404 from "../../Utility/pages-404";
const AcademicYearsRoutes = () => {
    return (
        <Routes>
            <Route  path="/" element={<AcademicYearList />}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default AcademicYearsRoutes