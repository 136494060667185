import React, { useState, useEffect, createContext } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    NavItem,
    NavLink,
    TabContent
} from "reactstrap"
import classnames from "classnames"
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import LayoutHelper from "helpers/LayoutHelper"
import {onlineApplicationGraphQLClient, uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {LoaderPage} from "../../../../helpers/LoaderPage";
import UpdateApplicantInformation from "./UpdateApplicantInformation";
import {GET_APPLICANT_DETAILS} from "../../Queries/ApplicantQueries";
import ApplicantNextOfKin from "./ApplicantNextOfKin";

export const ApplicantProfileWizardContext = createContext() // Create data to transfer to child modal

const ApplicantProfileWizard = () => {
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [payLoad, setPayload] = useState({})

    const renderStepContent = tabId => {
        switch (tabId) {
            case 1:
                return <UpdateApplicantInformation />
            case 2:
                return <ApplicantNextOfKin />
                // return <EmergencyContactDetails />
            default:
                return null
        }
    }

    const breadcrumbItem = [
        {
            title: "Update My Information",
            path: "",
            isActive: true
        }
    ]

    const handleSubmit = values => {}
    const toggleTab = tab => {
        handleSubmit()
        if (activeTab !== tab) {
            const modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 2) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    const  ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data

    const { loading:applicantLoading, error, data:applicantData } = useQuery(GET_APPLICANT_DETAILS, {
        client: onlineApplicationGraphQLClient,
        skip: !ExtractUserAuth?.uid,
        variables:{
            userUid: ExtractUserAuth?.uid
        },
        nextFetchPolicy: 'network-only'
    })
    const applicant = applicantData?.getApplicantDetails.data
    if(applicantLoading || !applicant) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Applicant"} description={"Fetching Applicant Details"} />
    return (
        <ApplicantProfileWizardContext.Provider
            value={{
                toggleTab,
                activeTab,
                passedSteps,
                applicant,
                setPayload,
                payLoad
            }}
        >
            <LayoutHelper
                breadcrumbItem={breadcrumbItem}
                pageTitle={"Online Application"}
            >
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <div className="wizard">
                                    <div className="steps clearfix">
                                        <ul style={{ whiteSpace: "nowrap" }}>
                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 1
                                                })}
                                            >
                                                <NavLink
                                                    className={classnames({
                                                        current: activeTab === 1
                                                    })}
                                                    onClick={() => toggleTab(1)}
                                                    disabled={!(passedSteps || []).includes(1)}
                                                >
                                                    <span className="number">1.</span> Basic Details
                                                </NavLink>
                                            </NavItem>
                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 2
                                                })}
                                            >
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 2
                                                    })}
                                                    onClick={() => toggleTab(2)}
                                                    disabled={!(passedSteps || []).includes(2)}
                                                >
                                                    <span className="number">2.</span> Emergency
                                                    Contact Details
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                    </div>
                                    <div className="content clearfix">
                                        <TabContent activeTab={activeTab} className="body">
                                            {renderStepContent(activeTab)}
                                        </TabContent>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </LayoutHelper>
        </ApplicantProfileWizardContext.Provider>
    )
}

export default ApplicantProfileWizard
