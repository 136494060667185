import { gql } from "@apollo/client"

const DELETE_PROFESSIONAL_BODY_MEMBERSHIP = gql`
  mutation deleteProfessionalMembership($deletedBy: Int!, $uid: String!) {
    deleteProfessionalMembership(deletedBy: $deletedBy, uid: $uid) {
      code
      message
      status
      data {
        endDate
        id
        membershipNumber
        organizationName
        staffUid
        startDate
        uid
      }
    }
  }
`

const ADD_UPDATE_PROFESSIONAL_MEMBERSHIP = gql`
  mutation addUpdateProfessionalMembership(
    $input: ProfessionalMembershipInput!
  ) {
    addUpdateProfessionalMembership(input: $input) {
      code
      status
      message
      data {
        endDate
        id
        membershipNumber
        organizationName
        staffUid
        startDate
        uid
      }
    }
  }
`

export {
  DELETE_PROFESSIONAL_BODY_MEMBERSHIP,
  ADD_UPDATE_PROFESSIONAL_MEMBERSHIP,
}
