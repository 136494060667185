import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"
import { Table, Tbody, Th, Thead, Tr } from "react-super-responsive-table"

const PlaceHolderLoader = props => {
  const { columSize, rows = 1, buttonShown = false, type = "rows" } = props
  return (
    <Col lg={columSize}>
      <Card className="card shadow-none border mb-0" aria-hidden="true" style={{marginTop: '5%'}}>
        <CardBody>
          {(() => {
            const data = []
            for (let i = 0; i < rows; i++) {
              if (type === "rows") {
                data.push(
                  <div key={i}>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                      <span className="placeholder col-8"></span>
                    </p>
                  </div>
                )
              } else if (type === "table") {
                data.push(
                  <div key={i}>
                    <Table className="table table-striped">
                      <Tr className="placeholder-glow">
                        <Th className="placeholder col-2">0</Th>
                        <Th className="placeholder col-3">0</Th>
                        <Th className="placeholder col-7">0</Th>
                      </Tr>
                      <Tr>
                        <Th colSpan="3"></Th>
                      </Tr>
                      <Tr className="placeholder-glow">
                        <Th className="placeholder col-3">Registration No</Th>
                        <Th className="placeholder col-2">Sex</Th>
                        <Th className="placeholder col-7">Sex</Th>
                      </Tr>
                      <Tr>
                        <Th colSpan="3"></Th>
                      </Tr>
                      <Tr className="placeholder-glow">
                        <Th className="placeholder col-7">Registration No</Th>
                        <Th className="placeholder col-3">Sex</Th>
                        <Th className="placeholder col-2">Sex</Th>
                      </Tr>
                    </Table>
                  </div>
                )
              }
            }
            return data
          })()}
          {buttonShown ? (
            <Link
              to="#"
              className="btn btn-primary disabled placeholder col-2"
            ></Link>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

PlaceHolderLoader.propTypes = {
  columSize: PropTypes.number.isRequired,
  buttonShown: PropTypes.bool,
  type: PropTypes.oneOf(["table", "rows"]),
  rows: PropTypes.oneOf([1, 2, 3, 4, 5]),
}

export default PlaceHolderLoader
