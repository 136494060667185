import React, { useState, useEffect, createContext } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { GET_STUDENT, GET_USER } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import StudentPersonalInformation from "./StudentPersonalInformation"
import RegistrationPayment from "./Registration/RegistrationPayment"
import StudentNextOfKinDetails from "./Registration/StudentNextOfKinDetails"
import StudentContactDetails from "./Registration/StudentContactDetails"
import StudentAccommodationSelection from "./Registration/StudentAccommodationSelection"
import StudentFinalConfirmation from "./Registration/StudentFinalConfirmation"
import LayoutHelper from "helpers/LayoutHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { token } from "helpers/UrlHelper"

export const StudentProfileContext = createContext() // Create data to transfer to child modal

const SelfRegistration = () => {
  const [updateStudentData, setUpdateStudentData] = useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const renderStepContent = tabId => {
    switch (tabId) {
      case 1:
        return <RegistrationPayment />
      case 2:
        return <StudentPersonalInformation />
      case 3:
        return <StudentContactDetails />
      case 4:
        return <StudentNextOfKinDetails />
      case 5:
        return <StudentAccommodationSelection />
      case 6:
        return <StudentFinalConfirmation />
      default:
        return null
    }
  }

  const breadcrumbItem = [
    {
      title: "Self registration",
      path: "",
      isActive: true,
    },
  ]

  const toggleTab = tab => {
    handleSubmit()
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    const studentDetails = userDetails?.getUserByUsername?.data // extract student details

    setUpdateStudentData(studentDetails)
  }, [userDetails])

  const studentStatus = updateStudentData?.student?.status?.name

  const handleSubmit = values => {}

  return (
    <StudentProfileContext.Provider
      value={{
        updateStudentData,
        setUpdateStudentData,
        toggleTab,
        handleSubmit,
        activeTab,
        passedSteps,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle={
          studentStatus == "UNREGISTERED"
            ? "Self Registration"
            : studentStatus == "CONTINUING"
            ? "Update My information"
            : ""
        }
      >
        <div className="page-content">
          <div className="mt-1">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard">
                      <div className="steps clearfix">
                        <ul style={{ whiteSpace: "nowrap" }}>
                          <NavItem
                            className={classnames({
                              current: activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => toggleTab(1)}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Payments
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 2,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => toggleTab(2)}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> Basic Details
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 3,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => toggleTab(3)}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3.</span> Contacts Detail
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 4,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              onClick={() => toggleTab(4)}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">4.</span> Next Of Kin
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 5,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 5,
                              })}
                              onClick={() => toggleTab(5)}
                              disabled={!(passedSteps || []).includes(5)}
                            >
                              <span className="number">5.</span> Hostel
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 6,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 6,
                              })}
                              onClick={() => toggleTab(6)}
                              disabled={!(passedSteps || []).includes(6)}
                            >
                              <span className="number">6.</span> Confirm
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {renderStepContent(activeTab)}
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </LayoutHelper>
    </StudentProfileContext.Provider>
  )
}

export default SelfRegistration
