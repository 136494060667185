// export default MyCourseList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import { GET_CAMPUS } from "Modules/Campus/Queries/CampusQueries"
import CourseList from "./CourseList"
import { Link } from "react-router-dom"
import OddSemisterCourseList from "./OddSemisterCourseList"
import EvenSemisterCourseList from "./EvenSemisterCourseList"
import { GET_USER } from "Modules/Users/Queries/UserQuery"
import { getLoggedInUser } from "helpers/fakebackend_helper"
import LogedInUser from "Modules/Authentication/LogedInUser"
import { GET_STAFF_COURSE_ALLOCATION_BY_ACADEMIC_YEAR_SEMESTER } from "../Queries/InstructorCoursesQueries"

const breadcrumbItem = [
  {
    title: "My Course List",
    path: "",
    isActive: true,
  },
]

const InstructorCoursesList = () => {
  // Fetch staff info
  const {
    loading: staffLoading,
    error: staffError,
    data: staffData,
  } = useQuery(GET_USER, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
  })

  const staffUid = staffData?.getUserByUsername?.data?.staff?.uid

  console.log("Staff dataaaaaa", staffData?.getUserByUsername?.data?.staff?.uid)

  // Fetch staff allocated even courses
  const {
    loading: evenCourseLoading,
    error: evenCourseError,
    data: evenCourseData,
  } = useQuery(GET_STAFF_COURSE_ALLOCATION_BY_ACADEMIC_YEAR_SEMESTER, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        staffUid: staffUid ? staffUid : "",
        semester: 2,
        isCurrent: 1,
      },
    },
    fetchPolicy: "no-cache",
  })

  // Fetch staff allocated even courses
  const {
    loading: oddCourseLoading,
    error: oddCourseError,
    data: oddCourseData,
  } = useQuery(GET_STAFF_COURSE_ALLOCATION_BY_ACADEMIC_YEAR_SEMESTER, {
    client: registrationGraphQLClient,
    variables: {
      inputs: {
        staffUid: staffUid ? staffUid : "-1",
        semester: 1,
        isCurrent: 1,
      },
    },
  })

  const staffEvenSemesterCourses =
    evenCourseData?.getStaffCourseAllocationByAcademicYearSemesters?.data

  const staffOddSemesterCourses =
    oddCourseData?.getStaffCourseAllocationByAcademicYearSemesters?.data

  if (staffLoading || evenCourseLoading || oddCourseLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="My Courses List">
      <Row>
        <Col>
          <ul className="verti-timeline list-unstyled">
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle"></i>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="font-size-14 mb-1">ODD SEMESTER</h6>
                  <p className="text-muted">
                    <p className="text-muted mb-0">
                      <Row>
                        {staffOddSemesterCourses != "" ? (
                          <>
                            {staffOddSemesterCourses?.map((course, index) => (
                              <OddSemisterCourseList
                                courseData={course}
                                key={course?.uid}
                              />
                            ))}
                          </>
                        ) : (
                          <Row>
                            <Col col={12}>
                              <div className="alert alert-info font-weight-bolder">{`No any course allocated to teach for Even semester!`}</div>
                            </Col>
                          </Row>
                        )}
                      </Row>
                    </p>
                  </p>
                </div>
              </div>
            </li>
            <li className="event-list">
              <div className="event-timeline-dot">
                <i className="bx bx-right-arrow-circle"></i>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="font-size-14 mb-1">EVEN SEMESTER</h6>
                  <p className="text-muted">
                    <Link to="#!">
                      <p className="text-muted mb-0">
                        <Row>
                          {staffEvenSemesterCourses != "" ? (
                            <>
                              {staffEvenSemesterCourses?.map(
                                (course, index) => (
                                  <OddSemisterCourseList
                                    courseData={course}
                                    key={course?.uid}
                                  />
                                )
                              )}
                            </>
                          ) : (
                            <Row>
                              <Col col={12}>
                                <div className="alert alert-info font-weight-bolder">{`No any course allocated to teach for Even semester!`}</div>
                              </Col>
                            </Row>
                          )}
                        </Row>
                      </p>
                    </Link>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    </LayoutHelper>
  )
}

export default InstructorCoursesList
