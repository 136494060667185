import { gql } from "@apollo/client"

const CREATE_SPONSOR = gql`
  mutation RegisterSponsors($inputs: [SponsorDtoInput!]!) {
    registerSponsors(inputs: $inputs) {
      status
      code
      message
      data {
        items{
          id
          uid
          name
          code
       }
       totalCount
      }
    }
  }
`

const DELETE_SPONSOR = gql`
  mutation RemoveSponsor($uid: String!) {
    removeSponsor(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_SPONSOR, DELETE_SPONSOR }
