//REGISTE
import { gql } from '@apollo/client';

const GET_ACADEMICYEARS = gql`
  query GetAcademicYear($pagination: PaginationInput!) {
    getAcademicYears(pagination: $pagination){
      status
      code
      message
      data {
        items{
        uid
        name
        status
        startDate
        endDate
      }
      totalCount
      }
    }
  }
`

const GET_CURRENT_ACADEMICYEAR = gql`
  query getActiveAcademicYear {
    getActiveAcademicYear {
      code
      status
      message
      data {
        uid
        name
        status
        startDate
        endDate
      }
    }
  }
`

export { GET_ACADEMICYEARS, GET_CURRENT_ACADEMICYEAR }