import React, { useContext, useEffect, useState } from "react"
import { Form, Input, FormFeedback, Row, Col, Label } from "reactstrap"
import * as Yup from "yup"
import { useFormik, Field } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { Link } from "react-router-dom"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StudentProfileContext } from "../SelfRegistration"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { limit } from "helpers/UrlHelper"
import { UPDATE_ACCOMMODATION_STATUS } from "Modules/Students/Mutations/StudentAccommodationStatusInputQueries"
import { GET_ACCOMMODATION_STATUS } from "Modules/Students/Queries/Accommodation/StudentAccommodationQueries"

const StudentAccommodationSelection = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const { updateStudentData, setUpdateStudentData } = useContext(
    StudentProfileContext
  )

  const { toggleTab, handleSubmit } = useContext(StudentProfileContext)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [Student] = useMutation(UPDATE_ACCOMMODATION_STATUS, {
    client: uaaGraphQLClient,
  })


  const {
    loading: accommodationLoading,
    error: accommodationError,
    data: accommodationData,
    refetch: accommodationRefetch,
  } = useQuery(GET_ACCOMMODATION_STATUS, {
    client: uaaGraphQLClient,
    variables: {
      input: {
        userUid: updateStudentData?.uid,
        studyYear: parseInt(updateStudentData?.student?.studyYear),
      },
    },
  })

  const accommodationStatus = accommodationData?.getAccommodationStatus?.data

  // console.log("Accommodation", accommodationStatus)

  const [selectedValue, setSelectedValue] = useState(
    accommodationStatus?{
      value: true,
      label: 'Yes'
    } : {
      value: false,
      label: 'No'
    }
  )
  // const [selectedLabel, setSelectedLabel] = useState(
  //   accommodationStatus ? "Yes" : "No"
  // )

  // useEffect(() => {
  //   setSelectedLabel(selectedValue ? "Yes" : "No");
  // }, [selectedValue])

  // console.log(accommodationData)
  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(6) // Move to the next tab
    } else {
      toggleTab(5) // Move to the next tab
    }
  }

  const handlePrevious = () => {
    toggleTab(4) // Move to the previous tab
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      status: accommodationStatus ? accommodationStatus : null,
      studyYear: updateStudentData
        ? parseInt(updateStudentData?.student?.studyYear)
        : null,
      userId: updateStudentData?.uid,
    },

    validationSchema: Yup.object().shape({
      status: Yup.string().nullable().required("Please choose option"),
    }),

    onSubmit: (values, { resetForm }) => {
      const postData = {
        status: values.status,
        studyYear: updateStudentData
          ? parseInt(updateStudentData?.student?.studyYear)
          : null,
        userUid: updateStudentData?.uid,
      }
      Student({
        client: uaaGraphQLClient,
        variables: {
          input: { ...postData },
        },
        refetchQueries: [
          {
            client: uaaGraphQLClient,
            query: GET_ACCOMMODATION_STATUS,
            variables: {
              input: {
                userUid: updateStudentData?.uid,
                studyYear: parseInt(updateStudentData?.student?.studyYear),
              },
            },
          },
        ],

        onCompleted: ({ updateAccommodationStatus }) => {
          if (updateAccommodationStatus.code === 8000) {
            // console.log("Data updated successsfully")
            toggleTab(6)
            // setIsSubmitted(true)
          } else {
            console.log("Failed to update data")
          }
          showToast(
            updateAccommodationStatus.message,
            updateAccommodationStatus.code === 8000 ? "success" : "error"
          )
          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })

  const accomodationOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ]

  console.log(validation.values.status)
  return (
    <div className="mt-3 wizard">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <Row>
          <Col lg="12">
            <div className="mb-3">
              <Label>Do you want Accomodation ?</Label>
              <select
                className="form-control"
                name="status"
                onChange={
                  (e) => {
                    validation.setFieldValue('status',!!parseInt(e.target.value))
                    console.log(validation.values.status)
                  }
                }
                onBlur={validation.handleBlur}
                >
                  <option> --Select--</option>
                  <option value="1" selected={validation.values.status == true} >Yes</option>
                  <option value="0"  selected={validation.values.status == false}>No</option>
                </select>

              {validation.touched.status && validation.errors.status ? (
                <FormFeedback type="invalid">
                  {validation.errors.status}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="previous">
                <Link to="#" onClick={handlePrevious}>
                  {`< Previous`}
                </Link>
              </li>
              <li className="next" onClick={handleNext}>
                <SubmitButtonHelper
                  type="primary"
                  name="Next >"
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default StudentAccommodationSelection
