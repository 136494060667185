import { gql } from "@apollo/client"

const CREATE_APPLICANT_CERTIFICATE = gql`
    mutation RegisterApplicantCertificateResults($inputs: ApplicantCertificateInput!) {
        registerApplicantCertificateResults(inputs: $inputs) {
            status
            code
            message
        }
    }
`

const UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD = gql`
    mutation UpdateApplicantLevelAndQualificationMethod($inputs: ApplicantLevelQualificationUpdateInput!) {
        updateApplicantLevelAndQualificationMethod(inputs:$inputs){
            status
            code
            message
            data{
                uid
                firstName
                lastName
            }
        }
    }
`

const UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD_BY_ADMISSION_OFFICER = gql`
    mutation UpdateApplicantLevelAndQualificationMethodByAdmissionOfficer($inputs: ApplicantLevelQualificationUpdateInput!) {
        updateApplicantLevelAndQualificationMethodByAdmissionOfficer(inputs:$inputs){
            status
            code
            message
            data{
                uid
                firstName
                lastName
            }
        }
    }
`

const GENERATE_APPLICANT_CONTROL_NUMBER = gql`
    mutation GenerateApplicantControlNumber($inputs: ControlNumberReqInput!) {
        generateApplicantControlNumber(inputs:$inputs){
            status
            code
            message
            data
        }
    }
`

const DELETE_COURSE_LEANING_OUTCOME = gql`
    mutation RemoveCourseLearnOutcome($uid: String!) {
        removeCourseLearnOutcome(uid: $uid) {
            message
            code
            status
        }
    }
`

export {
  CREATE_APPLICANT_CERTIFICATE,
  UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD_BY_ADMISSION_OFFICER,
  UPDATE_APPLICANT_LEVEL_AND_QUALIFICATION_METHOD,
  GENERATE_APPLICANT_CONTROL_NUMBER
}