import React from "react"
import {Button} from "reactstrap"
import PropTypes from "prop-types";


const SubmitButtonHelper = props => {
    const {type,name,formik,btnSize = '', value = ''} = props
    return (
        <Button
            value={value}
            type="submit"
            name={"sub"}
            className={btnSize}
            disabled={formik.isSubmitting || !formik.isValid}
            color={type}
        >
            {
                formik.isSubmitting && <><i className="fas fa-spinner fa-spin font-size-16 align-middle me-2"></i> Please wait..</>
            }
            {
                !formik.isSubmitting && <>{name}</>
            }
        </Button>
    );
}
SubmitButtonHelper.propTypes = {
    type: PropTypes.oneOf(['warning','danger','primary','success']).isRequired,
    name: PropTypes.string.isRequired,
    btnSize: PropTypes.string,
    formik:PropTypes.any.isRequired
};

export default SubmitButtonHelper;