import { limit } from "helpers/UrlHelper";

export function createUpdateFunction(query, variables, registerData) {
    return function update(cache, { data: { registerCampuses } }) {
      const existingData = cache.readQuery({
        query: query,
        variables: variables,
      });
      const updatedData = {
        getCampuses: {
          ...existingData.getCampuses,
          data: [...existingData.getCampuses.data.campuses, registerCampuses[registerData]],
        },
      };
      cache.writeQuery({
        query: query,
        variables: variables,
        data: updatedData,
      });
    };
  }
  export const paginationHelper = {
        offset: 0,
        limit: limit,
        search: null
  }