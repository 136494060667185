import React, { useState } from "react";
import { Button, Row, Col } from "reactstrap";
import SignatureCanvas from 'react-signature-canvas';
import LayoutHelper from "helpers/LayoutHelper";
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import {useLazyQuery, useMutation, useQuery} from "@apollo/client"
import { SAVE_SIGNATURE } from "../Mutations/SignaturePadMutation"; // Import your mutation file
import {GET_STUDENT, GET_USER_DOCUMENT} from "Modules/Users/Queries/UserQuery"

const breadcrumbItem = [
  {
    title: "My Signature",
    path: "",
    isActive: true,
  },
];

// function SignaturePad() {
const SignaturePad = props => {
    const [update, setUpdate] = useState(false)
  const [url, setUrl] = useState();
  const [sign, setSign] = useState();
  const [saveSignatureMutation] = useMutation(SAVE_SIGNATURE); // Replace with your mutation

  const handleClear = () => {
    sign.clear();
    setUrl('');
  };

  const handleGenerate = () => {
    const signatureBase64 = sign.getTrimmedCanvas().toDataURL('image/png').split(',')[1];
    setUrl(signatureBase64);
    saveSignature(signatureBase64);
  };


  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip: !JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })

  const student = userDetails?.getUserByUsername?.data


    const { loading,error,data } = useQuery(GET_USER_DOCUMENT, {
        client: uaaGraphQLClient,
        variables: {
            userUid: student?.uid,
            documentType: 'signature'
        },
        fetchPolicy: "no-cache",
        skip:!student?.uid
    })
  const saveSignature = async (signatureBase64) => {
    try {
      // Perform your mutation here, passing the signatureBase64 as a variable
    //   alert(signatureBase64)

    const userData = {
        userUid: student.uid,
        file: signatureBase64,
    }


      const result = await saveSignatureMutation({
        client: uaaGraphQLClient,   
        variables: {
            uploadInput: userData,
        },
      });
        await searchImage({
            variables: {
                userUid: student?.uid,
                documentType: 'signature'
            },
        })

    } catch (error) {
      // Handle errors
      console.error("Mutation Error:", error);
    }
  };
    let [
        searchImage,
        { loading: imageLoad, error: imageError, data: searchData },
    ] = useLazyQuery(GET_USER_DOCUMENT, {client: uaaGraphQLClient})


  let photo = ''
  if(data?.getUserDocuments?.data?.base64doc)
      photo = "data:image/jpeg;base64," +data?.getUserDocuments?.data?.base64doc
  if(searchData?.getUserDocuments?.data?.base64doc)
      photo = "data:image/jpeg;base64," +searchData?.getUserDocuments?.data?.base64doc

    return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="My Signature">
        <Row>
            <Col className="d-flex justify-content-center">
                {loading ? <i className="bx bx-loader fa-5x bx-spin align-middle"></i> :
                    <img
                        src={photo}

                        alt=""
                        style={{maxWidth: '100%'}}
                        className="img-thumbnail"
                    />
                }
            </Col>


        </Row>
        <Row>
            <Col className="d-flex justify-content-center">


                        <ul className="verti-timeline list-unstyled">
                            <li className="event-list">
                                <div className="event-timeline-dot">
                                    <i className="bx bx-right-arrow-circle"></i>
                                </div>
                                <div className="text-center">
                                    <div style={{border: "2px solid black", width: 500, height: 200}}>
                                        <SignatureCanvas
                                            canvasProps={{
                                                width: 500,
                                                height: 200,
                                                className: 'sigCanvas',
                                                style: { touchAction: 'none', backgroundColor: 'white' },
                                            }}
                                            ref={(data) => setSign(data)}
                                        />
                                    </div>
                                    <br/>
                                    <div className="d-flex justify-content-center">
                                        <Button
                                            type="submit"
                                            className="mr-2"
                                            color="light"
                                            onClick={handleClear}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            type="submit"
                                            className=""
                                            color="primary"
                                            onClick={handleGenerate}
                                        >
                                            Save Signature
                                        </Button>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </Col>
        </Row>
    </LayoutHelper>
    );
}

export default SignaturePad;
