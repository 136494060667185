import PropTypes from "prop-types"
import React, { useState } from "react"
import {
    Row,
    Col,
    Alert,
    Card,
    CardBody,
    Container,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import sualogo from "../../assets/images/logo/sua.png"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import {sr2URL} from "../../helpers/UrlHelper";
import SubmitButtonHelper from "../../helpers/SubmitButtonHelper";
import toastHelper from "../../helpers/ToastHelper";


const ControlNumberPortal = () => {
    const formatNumber = (number) => (number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))

    const navigate = useNavigate()

    const [controlNumbers, setControlNumbers] = useState([])
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            registration_number: "",
        },
        validationSchema: Yup.object({
            registration_number: Yup.string().required("Please Enter Registration"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            setControlNumbers([])
            // console.log(controlNumbers)
            fetch(sr2URL + "billing/get_control_numbers", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ registration_number: validation.values.registration_number }),
            }).then(response => response.json()).then(data => {
                if(data.status !== 200){
                    toastHelper(data.message,'error')
                }else if (data.status === 200){
                    setControlNumbers(data.data)
                }
                setSubmitting(false)
            })
                .catch((error) => {
                    // alert("wewwrw")
                    // toastHelper('An error occurred while making the request.','error')
                    console.error('Error submitting data:', error);
                    setSubmitting(false)
                });


            // dispatch(userForgetPassword(values, props.history))
        },
    })

    const { forgetError, forgetSuccessMsg } = useSelector(state => ({
        forgetError: state.ForgetPassword.forgetError,
        forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    }))


    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={10} xl={8}>
                            <Card
                                className="overflow-hidden"
                                style={{
                                    border: "2px solid #d5e3d5",
                                }}
                            >
                                <div
                                    className="text-center"
                                    style={{
                                        backgroundColor: "#d5e3d5",
                                    }}
                                >
                                    <Row>
                                        <Col xs={12}>
                                            <div className="text-primary p-2">
                                                <img src={sualogo} height={100} alt="" className=" " />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <div>
                                                <h2 style={{ fontFamily: "Roboto" }}>SUA-ESB</h2>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        {forgetError && forgetError ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {forgetError}
                                            </Alert>
                                        ) : null}
                                        {forgetSuccessMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        {controlNumbers.length > 0 &&

                                            <div className="table-responsive">
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>S/No</th>
                                                        <th>Fee Name</th>
                                                        <th>Academic Year</th>
                                                        <th>Amount</th>
                                                        <th>Currency</th>
                                                        <th>Control Number</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {controlNumbers?.map((c, index) => (
                                                        <tr key={c.billid}>
                                                            <td>{index + 1}</td>
                                                            <td>{c.fee_name}</td>
                                                            <td>{c.academic_year}</td>
                                                            <td style={{textAlign: 'right'}}>{formatNumber(c.amount)}</td>
                                                            <td>{c.currency}</td>
                                                            <td>{c.control_number}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        }
                                        <Form
                                            className="form-horizontal mt-4"
                                            onSubmit={e => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <div className="mb-3">
                                                <Label className="form-label">Registration Number</Label>
                                                <Input
                                                    name="registration_number"
                                                    className="form-control"
                                                    placeholder="Enter Registration Number"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.registration_number || ""}
                                                    invalid={
                                                        !!(validation.touched.registration_number && validation.errors.registration_number)
                                                    }
                                                />
                                                {validation.touched.registration_number && validation.errors.registration_number ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.registration_number}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                            <Row className="mb-3">
                                                <Col className="text-end">
                                                    <SubmitButtonHelper
                                                        type="primary"
                                                        name="Submit"
                                                        formik={validation}
                                                    />
                                                </Col>

                                            </Row>
                                        </Form>
                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    © {new Date().getFullYear()} © Sokoine University of
                                    Agriculture. <br /> All rights reserved
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default ControlNumberPortal
