import { gql } from '@apollo/client';


const CREATE_REGION = gql`
mutation RegisterRegion($inputs: [RegionDtoInput!]!) {
  registerRegions (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items{
        id
        uid
        name
        code
        country {
          id
          uid
          code
          name
        }
     }
    }
  }
}
`


const DELETE_REGION = gql`
  mutation removeRegions($uid: String!) {
    removeRegion(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_REGION, DELETE_REGION }