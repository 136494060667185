import React, {useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {limit} from "../../../../helpers/UrlHelper";
import {GET_ALL_PROGRAMS, GET_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_STATUS, GET_STATUS_NAME} from "../../../Lookups/Status/Queries/StatusQueries";
import ToastHelper from "../../../../helpers/ToastHelper";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PaginationHelper from "../../../../helpers/PaginationHelper";
import {GenerateTablePDF} from "../../../../helpers/PdfTableHelper";
import {GET_NHIF_STUDENT_LIST} from "../Queries/StudentReportQueries";

const breadcrumbItem =  [
    {
        title: 'NHIF Students Report',
        path: '',
        isActive: true,
    },
]
const StudentNHIFReport = () => {

    const pagination = { offset: 0, limit: 10000, search: null }

    const { loading:reportLoading, error:reportError, data:reportData } = useQuery(GET_NHIF_STUDENT_LIST, {
        client: uaaGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })


    let data = reportData?.getNhifStudentsReport?.data?.items

    console.log(data)

    const downloadList = () => {
        let title = `First Year Undergraduate Students NHIF Report`
        let programData = []
        const columns = ['S/No','Student Name','Registration Number', 'Form Four Index Number','NHIF Registration Status', 'NHIF Control Number Status'];
        if(data){
            for (let f = 0; f < data?.length; f++){
                let s = data[f]
                programData.push([
                    f+1, s?.Surname + ", " + s?.FirstName + " " + s?.MiddleName ,s?.AdmissionNo,s?.FormFourIndexNo,s?.NHIFMessage, s?.NHIFCNMessage
                ])
            }

        }
        // console.log(studentData)
        GenerateTablePDF(columns,programData,title,[],'l')
    }
    // Form validation
    const [approved, setApproved] = useState(false)

    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="NHIF Student List">
            <Row>
                <Col lg={12}>

                    <Card>
                        <CardBody>
                            <Button className="btn btn-success btn-sm font-weight-bold" onClick={downloadList}>
                                    <><i className="fa fa-file-pdf"></i> Generate PDF</>
                            </Button>

                           

                            {reportLoading ? (
                                <PlaceHolderLoader type="table" columSize={12} rows={5} />
                            ) : (
                                <div className="table-rep-plugin">
                                    <div
                                        className="table-responsive mb-0"
                                        data-pattern="priority-columns"
                                    >



                                        <Table
                                            id="tech-companies-1"
                                            className="table table-striped table-bordered"
                                        >

                                            <Thead>
                                                <Tr>
                                                    <Th>S/No</Th>
                                                    <Th data-priority="0">Student Name</Th>
                                                    <Th data-priority="1">Registration Number</Th>
                                                    <Th data-priority="3">Form Four Index Number</Th>
                                                    <Th data-priority="3">NHIF Registration Status</Th>
                                                    <Th data-priority="3">NHIF Control Number Status</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {data?.map((std, index) => (
                                                    <Tr key={index}>
                                                        <Td>{index + 1}</Td>
                                                        <Td>{std?.Surname + ", " + std?.FirstName + " " + std?.MiddleName}</Td>
                                                        <Td>{std?.AdmissionNo}</Td>
                                                        <Td>{std?.FormFourIndexNo}</Td>
                                                        <Td>{std?.NHIFMessage}</Td>
                                                        <Td>{std?.NHIFCNMessage}</Td>
                                                        
                                                    </Tr>
                                                ))}
                                            </Tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default StudentNHIFReport;