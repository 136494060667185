// export default ApplicationWindowList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, Button, Alert } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import ApplicationWindowModal from "./ApplicationWindowModal"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { GET_APPLICATION_WINDOW } from "../Queries/ApplicationWindowQuery"
import { DELETE_APPLICATION_WINDOW } from "../Mutations/ApplicationWindowMutation"
import moment from "moment"

const breadcrumbItem = [
  {
    title: "Application Window List",
    path: "",
    isActive: true
  }
]

export const ApplicationWindowContext = createContext(undefined) //Create data to transfer to child modal

const ApplicationWindowList = () => {
  const searchValues = {
    search: ""
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required("")
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    }
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedApplicationWindow, setSelectedApplicationWindow] = useState(null) //Get data for deleting
  const [updateApplicationWindowData, setUpdateApplicationWindowData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_APPLICATION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })

  const [
    searchApplicationWindows,
    { loading: searchLoading, error: searchError, data: searchData }
  ] = useLazyQuery(GET_APPLICATION_WINDOW, {
    client: onlineApplicationGraphQLClient
  })

  useEffect(() => {
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteApplicationWindow] = useMutation(DELETE_APPLICATION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: { uid: selectedApplicationWindow?.uid },
    refetchQueries: [
      {
        query: GET_APPLICATION_WINDOW,
        variables: {
          pagination: paginationHelper
        }
      }
    ]
  })

  const handleDeleteApplicationWindow = delApplicationWindow => {
    setSelectedApplicationWindow(delApplicationWindow)
    showDeleteWarning(() => {
      performApplicationWindowDeletion().then(r => console.log(r))
    })
  }

  const performApplicationWindowDeletion = async () => {
    try {
      const response = await deleteApplicationWindow()
      showToast(
        response.data.removeAdmissionWindow.message,
        response.data.removeAdmissionWindow.code === 8000 ? "success" : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  // const handleStatusToggle = async (uid, currentStatus) => {
  //   try {
  //
  //     const response = await updateStatus({
  //       variables: { uid, status: !currentStatus }
  //     })
  //
  //     // Optional: Check the response to ensure the status update was successful
  //     if (response.data.updateApplicationWindowStatus.success) {
  //       console.log("Status updated successfully")
  //     } else {
  //       console.error("Failed to update status")
  //     }
  //   } catch (error) {
  //     console.error("Error updating status:", error)
  //   }
  // }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search
    }
    // Update the search query variables and refetch the data
    searchApplicationWindows({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }
  const ApplicationWindows = data?.getAdmissionWindows.data?.items
  const searchedApplicationWindows = searchData?.getAdmissionWindows?.data?.items
  const result = searchedApplicationWindows ? searchedApplicationWindows : ApplicationWindows
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getAdmissionWindows.data?.totalCount ||
    searchData?.getAdmissionWindows?.data?.items ||
    0

  // function isExpired(enddate) {
  //   const currentDate = new Date()
  //   const endDate = new Date(enddate)
  //   currentDate.setHours(0, 0, 0, 0)
  //   endDate.setHours(0, 0, 0, 0)
  //
  //   return currentDate > endDate
  // }

  function isDateInRange(startdate, enddate) {
  const currentDate = new Date();
  const startDate = new Date(startdate);
  const endDate = new Date(enddate);

  // Set the hours, minutes, seconds, and milliseconds to 0 for comparison
  currentDate.setHours(0, 0, 0, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  return currentDate >= startDate && currentDate <= endDate;
}

  return (
    <ApplicationWindowContext.Provider value={{ updateApplicationWindowData, setUpdateApplicationWindowData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Application Window List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <ApplicationWindowModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Batch No.</Th>
                            <Th data-priority="1">Admission Year</Th>
                            <Th data-priority="1">Level</Th>
                            <Th data-priority="1">Start Date</Th>
                            <Th data-priority="1">End Date</Th>
                            <Th>Description</Th>
                            <Th>Status</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            ?.slice(startIndex, endIndex)
                            .map((ApplicationWindow, index) => (
                              <Tr key={ApplicationWindow?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{ApplicationWindow?.batchNumber}</Td>
                                <Td>{ApplicationWindow?.admissionYear?.name}</Td>
                                <Td>{ApplicationWindow?.levelType?.code}</Td>
                                <Td>{moment(ApplicationWindow?.startDate).format("yyyy-MM-DD")}</Td>
                                <Td>{moment(ApplicationWindow?.endDate).format("yyyy-MM-DD")}</Td>
                                <Td>{ApplicationWindow?.description}</Td>
                                <Td>
                                  {
                                    !isDateInRange(ApplicationWindow?.startDate, ApplicationWindow.endDate) ? (
                                      <Alert color={`danger`}>Inactive</Alert>
                                    ) : (
                                      <Alert color={`primary`}>Active</Alert>
                                    )
                                  }
                                </Td>
                                {/* <Td>{ApplicationWindow?.status ? 'Active' : 'In-Active'}</Td> */}
                                <Td>
                                  <DropDownActionHelper
                                    data={ApplicationWindow}
                                    onUpdate={setUpdateApplicationWindowData}
                                    onDelete={handleDeleteApplicationWindow}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </ApplicationWindowContext.Provider>
  )
}

export default ApplicationWindowList
