import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_ACADEMICYEARS } from "../Mutations/AcademicYearsMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_ACADEMICYEARS } from "../Queries/AcademicYearsQueries"
import { createUpdateFunction, paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {registrationGraphQLClient, uaaGraphQLClient} from "Modules/Authentication/ApolloClient"

import Select from "react-select"
import { AcademicYearContext } from "./AcademicYearsList"

  const AcademicYearModal = () => {
  const { updateAcademicYearData, setUpdateAcademicYearData } = useContext(AcademicYearContext)
  const [academicyear] = useMutation(CREATE_ACADEMICYEARS)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_ACADEMICYEARS;
  const variables = { pagination: paginationHelper };
  const registerData = 'data';
  
  const updateFunction =  createUpdateFunction(query, variables, registerData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateAcademicYearData ? updateAcademicYearData.name : "",
      //status: updateAcademicYearData ? updateAcademicYearData.status : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      //status: Yup.string().required("Status is required"),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

   
  onSubmit: values => {
    academicyear({
      client: registrationGraphQLClient,
      variables: {
        inputs: [{ ...values, uid: updateAcademicYearData?.uid }],
      },
      refetchQueries: [
        {
          query: GET_ACADEMICYEARS,
          client:registrationGraphQLClient,
          variables: {
            pagination: paginationHelper,
          },
        },
      ],
      onCompleted: ({ registerAcademicYear }) => {
        //updateFunction(client.cache, { data: { registerAcademicYear } });
        if (registerAcademicYear.code === 8000) {
          resetForm();
          setModalOpen(false);
          setUpdateAcademicYearData(false); // Close the modal
        } else {
          setModalOpen(true);
          setUpdateAcademicYearData(true); // Close the modal
        }
        showToast(
          registerAcademicYear.message,
          registerAcademicYear.code === 8000 ? "success" : "error"
        );
      },
      onError: error => {
        // Handle errors
        console.error("Mutation error:", error);
      },
    });
  },
  })
  return (
      <div className="text-sm-end mt-3">
        <Button
          type="button"
          color="primary"
          className="btn mb-2 me-2 pull-right"
          onClick={() => setModalOpen(true)}
        >
          <i className="mdi mdi-plus-circle-outline me-1" />
          New Academic Year
        </Button>
        <Modal
          isOpen={modalOpen || !!updateAcademicYearData}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {!!updateAcademicYearData ? "Edit Academic Year" : "Add New Academic Year"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalOpen(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Name:
                </label>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  placeholder="Enter Academic Year Name (2023/2024)"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || updateAcademicYearData?.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="startDate" className="col-form-label">
                Start Date:
                </label>
                <Input
                  type="date"
                  name="startDate"
                  className="form-control"
                  id="startDate"
                  placeholder="Enter start Date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.startDate || updateAcademicYearData?.startDate || ""}
                  invalid={
                    validation.touched.startDate && validation.errors.startDate
                      ? true
                      : false
                  }
                />
                {validation.touched.startDate && validation.errors.startDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.startDate}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="endDate" className="col-form-label">
                End Date:
                </label>
                <Input
                  type="date"
                  name="endDate"
                  className="form-control"
                  id="endDate"
                  placeholder="Enter End Date"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.endDate || updateAcademicYearData?.endDate || ""}
                  invalid={
                    validation.touched.endDate && validation.errors.endDate
                      ? true
                      : false
                  }
                />
                {validation.touched.endDate && validation.errors.endDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.endDate}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="status" className="col-form-label">
                  Status:
                </label>

                     <Select
                          // value={selectedMulti2}
                          onChange={(e) => {
                            validation.setFieldValue('status',e.value)
                          }}
                          options={[
                            {
                              label:"Current",
                              value:1
                            },
                            {
                              label:"Not Current",
                              value:0
                            }
                          ]}
                          className="select2-selection"
                      />

                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">
                    {validation.errors.status}
                  </FormFeedback>
                ) : null}
              </div>

            </div>

            
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModalOpen(false)
                  setUpdateAcademicYearData(false)
                  resetForm() // Reset the form
                }}
              >
                Close
              </button>
              <Button type="submit" className="btn btn-btn" color="primary">
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
  )
}

export default AcademicYearModal
