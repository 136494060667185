import React from 'react';
import { MDBCol, MDBRow } from "mdb-react-ui-kit"

const EsbFooter = () => {
  return (
    <MDBRow>
      <MDBCol md={12}>
        <div className="text-center">
          <p className="mb-0">
            <small> © 1984 - {new Date().getFullYear()} Sokoine University of Agriculture. <br/> All rights reserved!</small>
          </p>
        </div>
      </MDBCol>
    </MDBRow>
  )
}
export default EsbFooter