import { gql } from "@apollo/client"

const GET_MEDIA_COVERAGES_BY_STAFF_UID = gql`
  query getAcademicQualificationsByStaffUid($staffUid: String!) {
    getAcademicQualificationsByStaffUid(staffUid: $staffUid) {
      code
      data {
        admissionYear
        approvalDate
        approvalStatus
        approvalStatusReason
        approvedBy
        awardCertificate
        cityOfAward
        completionYear
        countryOfAward
        degreeAwardName
        gpa
        institutionName
        institutionType
        specialization
        staffClass
        staffUid
        tcuCertificate
        tcuVerificationNumber
        thesisDissertationTitle
        uid
        qualificationLevel {
          code
          name
          uid
        }
      }
      message
      status
    }
  }
`

const GET_RESEARCH_GRANTS = gql`
  query getGrantTeamsPaginated($pagination: PaginationInput!) {
    getGrantTeamsPaginated(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          staffUid
          uid
          researchGrant {
            amount
            endDate
            funder
            hostInstitution
            projectCode
            startDate
            studyArea
            subtitle
            title
            uid
          }
          roleType {
            code
            id
            name
            uid
            roleCategory {
              uid
              name
              code
            }
          }
        }
      }
    }
  }
`

export { GET_RESEARCH_GRANTS, GET_MEDIA_COVERAGES_BY_STAFF_UID }
