
import React from "react";
import { Route, Routes } from "react-router";
import Group from "../Views/GroupList";

const GroupRoute = (props) => {

    return (
        <Routes>
            <Route path="/" element={<Group />} />
        </Routes>
    );

}

export default GroupRoute;