import { gql } from "@apollo/client"

const GET_ACADEMIC_EVENTS = gql`
  query getAcademicEventsPaginated($pagination: PaginationInput!) {
    getAcademicEventsPaginated(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          city
          country
          endDate
          id
          institution
          organizer
          staffUid
          startDate
          title
          uid
          eventCategory {
            code
            id
            name
            uid
          }
        }
      }
    }
  }
`

export { GET_ACADEMIC_EVENTS }
