
import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import ApplicantAccountRegistration from "../Views/ApplicantAccountRegistration";
import Undergraduate from "../Views/Undergraduate";
import Postgraduate from "../Views/Postgraduate";
import ProgramView from "../Views/ProgramView";

const OnlineApplicationRoutes = (props) => {

    return (
        <Routes>
            <Route index={true} path="/application" element={<ApplicantAccountRegistration />} />
            <Route path="/application/undergraduate" element={<Undergraduate/>}/>
            <Route path="/application/postgraduate" element={<Postgraduate/>}/>
            <Route path="/application/program-view" element={<ProgramView/>}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );

}

export default OnlineApplicationRoutes;