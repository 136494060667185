//REGISTE
import { gql } from '@apollo/client';


const VIEW_COURSE_WORKS = gql`
  query GetStudentActiveSemesterCourseWorkResults($input: StudentCourseWorkInput!) {
    getStudentActiveSemesterCourseWorkResults(input: $input){
      status
      code
      message
      data{
          courseCode
          courseName
          courseWorkType{
            type
            scores{
              score
              overallMarks
              assessmentNumber
            }
          }
      }
    }
  }
`;

export { VIEW_COURSE_WORKS }