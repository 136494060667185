import React from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown, Label, Input, FormFeedback, Form,
} from "reactstrap"

import {Table, Thead, Tbody, Tr, Th, Td, td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
    registrationGraphQLClient, uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import { limit } from "../../../../helpers/UrlHelper"
import PaginationHelper from "../../../../helpers/PaginationHelper"
import {GET_ALL_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {GET_PROGRAM_SEMESTER_YEAR, GET_STAFFS} from "../../../ProgramSemister/Queries/ProgramSemisterQueries";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {GET_PROGRAM_COURSE_BY_SEMESTER_WITH_HEADSHIP} from "../../../ProgramSemister/Queries/ProgramCourseQueries";
import {
    GET_COURSE_ALLOCATION,
    GET_STAFF_UID_COURSE_ALLOCATION_BY_PROGRAM_COURSE_UID
} from "../../../ProgramSemister/Queries/CourseAllocationQueries";
import ProgramActionHelper from "../../../../helpers/ProgramHelper";
import {COURSE_ALLOCATION} from "../../../ProgramSemister/Mutations/ProgramSemisterMutations";
import showToast from "../../../../helpers/ToastHelper";
import {
    GET_EXAM_RESULT_SUMMARIES,
    GET_RESULTS,
  } from "../Queries/StudentReportQueries"

const breadcrumbItem = [
    {
        title: "Exam Result Summary",
        path: "",
        isActive: true,
    },
]

const StudentExaminationResultsSummary = () => {


    // console.log(staffs)
    const checkValueInArrays = (uid) => {
        const st =  staffData?.getStaffs?.data?.items?.find(i => i.uid === uid)
        console.log(st)
        if(st) return `${st?.user?.firstName} ${st?.user?.middleName} ${st?.user?.lastName}`
        return ""
    };
    // const staffUidArray = allocations?.map(item => item.staffUid);

    const programCourseId = ''

    const {
    loading: resultLoading,
    error: resultError,
    data: resultData,
    refetch: resultRefeching,
    } = useQuery(GET_RESULTS, {
        client: registrationGraphQLClient,
        variables: {
            searchCriteria: {
              programCourseId: programCourseId ? programCourseId.toString() : '',
            },
          },
        fetchPolicy: "network-only",
    })

    const {
    loading: resultSummaryLoading,
    error: resultSummaryError,
    data: resultSummaryData,
    refetch: resultSummaryRefeching,
    } = useQuery(GET_EXAM_RESULT_SUMMARIES, {
    client: registrationGraphQLClient,
    variables: {
        searchCriteria: {
            programCourseId: programCourseId ? programCourseId.toString() : '',
        },
    },
    fetchPolicy: "network-only",
    })



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            programUid: "",
            academicYearUid: "",
            semesterUid:"",
            courseUid:"",
            staffUid:""
        },
        validationSchema: Yup.object().shape({
            programUid: Yup.string().required("Program is required"),
            courseUid: Yup.string().required("Course is required"),
            // staffUid: Yup.array().required("Staff is required").min(1, 'Select at least one Staff')
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            const transformedData = values.staffUid.map(staffUid => ({
                programCourseUid: values.courseUid,
                staffUid,
            }))
            courseAllocation({
                variables: {
                    inputs: transformedData,
                },
                client: registrationGraphQLClient,
                onCompleted: ({ registerCourseAllocations }) => {
                    if (registerCourseAllocations.code === 8000) {
                        searchAllocation({
                            variables: {
                                pagination: paginationHelper,
                                programCourseUid: values.courseUid,
                            },
                        })
                    }
                    showToast(
                        registerCourseAllocations.message,
                        registerCourseAllocations.code === 8000 ? "success" : "error"
                    )
                    setSubmitting(false)
                },
                onError: error => {
                    console.error("Mutation error:", error)
                    setSubmitting(false)
                },
            })
           
        },

    });

    const [courseAllocation] = useMutation(COURSE_ALLOCATION, {
        client: registrationGraphQLClient,
    })


    const searchValues = {
        search: "",
    }
    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })
    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })

    const [
        searchPrograms,
        { loading: programSearchLoading, error: searchError, data: searchProgram },
    ] = useLazyQuery(GET_ALL_PROGRAMS, {client: registrationGraphQLClient})

    const pagination = { offset: 0, limit: 20, search: null }

    const { loading:programLoading, error:programError, data:programData } = useQuery(GET_ALL_PROGRAMS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })


    let programs = programData?.getAllPrograms?.data?.items
    if (searchProgram?.getAllPrograms?.data)
        programs = searchProgram?.getAllPrograms?.data?.items


    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })
    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items


    let [
        searchSemesters,
        { loading: semesterLoading, error: searchSemError, data: semesterData },
    ] = useLazyQuery(GET_PROGRAM_SEMESTER_YEAR, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    let semesters = []

    let courses = []
    if (semesterData?.getProgramSemesters?.data)
        semesters = semesterData?.getProgramSemesters?.data?.items


    const search = (value,type) => {
        // console.log(type)
        // console.log(validation.values.programUid)
        let data = {
            search: value,
        }
        if (type === 'program'){
            console.log(value)
            if(value !== undefined){
                if(value.length < 4) return false
                semesters = []
                courses = []
                searchPrograms({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }

        }else if (type === 'semester'){
            if(validation.values.academicYearUid === '') {
                searchSemesters({
                    variables: {pagination: {...pagination}, programUid: value},
                })
            }else{
                searchSemesters({
                    variables: { pagination: { ...pagination }, academicYearUid: validation.values.academicYearUid,programUid:value },
                })
            }
        }else if (type === 'academicYear' && validation.values.programUid){
            if(value !== undefined) {
                if (value.length < 4) return false
                if(value === ''){
                    searchSemesters({
                        variables: { pagination: { ...pagination }, programUid: validation.values.programUid },
                    })
                }else {
                    searchSemesters({
                        variables: { pagination: { ...pagination }, programUid: validation.values.programUid,academicYearUid:value },
                    })
                }
            }
        }else if(type === 'courses'){
            // console.log(value)
            // console.log(type)
            searchCourse({
                variables: { programSemisterUid: value },
            })
        }else if(type === 'allocatedStaff'){
            searchAllocation({
                variables: {
                    pagination: paginationHelper,
                    programCourseUid: value,
                },
            })
            searchStaff()

        }
    }

    let [
        searchCourse,
        { loading: courseLoading, error: courseError, data: courseData },
    ] = useLazyQuery(GET_PROGRAM_COURSE_BY_SEMESTER_WITH_HEADSHIP, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    courses = courseData?.getProgramCourseByProgramSemesterUidWithHeadship?.data

    let [
        searchAllocation,
        { loading: allocationLoading, error: allocationError, data: allocationData },
    ] = useLazyQuery(GET_STAFF_UID_COURSE_ALLOCATION_BY_PROGRAM_COURSE_UID, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    let [
        searchStaff,
        { loading: staffLoading, error: staffError, data: staffData },
    ] = useLazyQuery(GET_STAFFS, {
        client: uaaGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
        fetchPolicy:'cache-first'
    })
    let staffs = staffData?.getStaffs?.data?.items

    let allocations = allocationData?.getCourseAllocationByProgramCourseUid?.data?.items

    // console.log(staffs)
    if(staffs && allocations){
        staffs = staffs?.filter(
            staff => !allocations.some(selected => selected.staffUid === staff.uid)
        )
        // console.log(staffs)
    }
    // console.log(allocations)
    // console.log(allocations)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Examination Summary Report">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return false
                                    }}
                                >
                                    <Row>
                                        <Col lg={3}>
                                            <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                                <Label>Program</Label>
                                                <Select
                                                    // value={selectedMulti2}
                                                    styles={{
                                                        menu: base => ({
                                                            ...base,
                                                            position: "absolute",
                                                            zIndex: 9999
                                                        })
                                                    }}
                                                    onChange={e => {
                                                        if (e) {
                                                            validation.setFieldValue("programUid", e.value)
                                                            search(e.value, 'semester')
                                                        } else {
                                                            validation.setFieldValue("programUid", "")
                                                            semesterData = []
                                                        }
                                                    }}
                                                    onInputChange={e => {
                                                        search(e, 'program')
                                                    }}

                                                    options={programs?.map(pro => ({
                                                        value: pro.uid,
                                                        label: pro.name + " (" + pro.code + ")",
                                                    }))}
                                                    className="select2-selection"
                                                    isClearable={true}
                                                    isLoading={programLoading || programSearchLoading}
                                                />
                                                <Input
                                                    name="programUid"
                                                    placeholder=""
                                                    type="hidden"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.programUid}
                                                    invalid={
                                                        !!(
                                                            validation.touched.programUid &&
                                                            validation.errors.programUid
                                                        )
                                                    }
                                                />
                                                {validation.touched.programUid &&
                                                validation.errors.programUid ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.programUid}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label>Academic Year</Label>
                                                <Select
                                                    // value={selectedGroup}
                                                    onChange={(e) => {
                                                        if (e) {
                                                            validation.setFieldValue("academicYearUid", e.value)
                                                        } else {
                                                            validation.setFieldValue("academicYearUid", "")
                                                        }
                                                    }}
                                                    onInputChange={e => {
                                                        search(e, 'academicYear')
                                                    }}
                                                    options={academicYears?.map(year => ({
                                                        value: year.uid,
                                                        label: year.name,
                                                    }))}

                                                    className="select2-selection"
                                                    isLoading={academicYearLoading || yearSearchLoading}
                                                    isClearable={true}
                                                />
                                                <Input
                                                    name="academicYearUid"
                                                    type="hidden"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.academicYearUid}
                                                    invalid={
                                                        !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                    }
                                                />
                                                {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.academicYearUid}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                           
                                            <div className="mb-3">
                                                <Label>Program Semesters</Label>
                                                <Select
                                                    onChange={e => {
                                                        if (e) {
                                                            validation.setFieldValue("semesterUid", e.value)
                                                            search(e.value, 'courses')
                                                        } else {
                                                            validation.setFieldValue("semesterUid", "")
                                                            courseData = []
                                                        }
                                                    }}


                                                    options={semesters?.map(sem => ({
                                                        value: sem.uid,
                                                        label: "YEAR - " + sem?.studyYear + " SEMESTER - " + sem.semester + " (" + sem?.academicYear?.name + ")",
                                                    }))}
                                                    className="select2-selection"
                                                    isLoading={semesterLoading}
                                                    isClearable={true}
                                                />
                                                <Input
                                                    name="semesterUid"
                                                    type="hidden"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.semesterUid || ""}
                                                    invalid={
                                                        !!(validation.touched.semesterUid && validation.errors.semesterUid)
                                                    }
                                                />
                                                {validation.touched.semesterUid && validation.errors.semesterUid ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.semesterUid}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label>Courses</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        if (e) {
                                                            validation.setFieldValue('courseUid', e.value)
                                                            search(e.value, 'allocatedStaff')
                                                        } else {
                                                            validation.setFieldValue('courseUid', "")
                                                            // search(e.value, 'allocatedStaff')
                                                            allocations = []
                                                        }
                                                    }}
                                                    options={courses?.map(course => ({
                                                        value: course.uid,
                                                        label: course?.name + " (" + course?.code + ")",
                                                    }))}
                                                    className="select2-selection"
                                                    isLoading={courseLoading}
                                                    isClearable={true}
                                                />
                                                <Input
                                                    name="courseUid"
                                                    type="hidden"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.courseUid}
                                                    invalid={
                                                        !!(validation.touched.courseUid && validation.errors.courseUid)
                                                    }
                                                />
                                                {validation.touched.courseUid && validation.errors.courseUid ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.courseUid}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <div className="float-end">
                                                <SubmitButtonHelper type={'primary'} name={'Submit'}
                                                                    formik={validation}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    {/* <Col lg={12}>
                        {allocationLoading ? <Card><CardBody className={'text-center'}><i className="bx bx-loader fa-5x bx-spin align-middle"></i></CardBody></Card>:''}
                        {allocations  && !allocationLoading ?
                            <Card>
                        <CardSubtitle className="mb-1 mt-4 text-center">Instructor name and Allocated Course</CardSubtitle>
                                <CardBody>
                                    <Table
                                        id="tech-companies-1"
                                        className="table-sm table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th data-priority="1">S/N</Th>
                                                <Th>Name</Th>
                                                <Th data-priority="3">Action</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {allocations?.map((a, index) => (
                                                <Tr key={a.uid}>
                                                    <Td align="center">{index+1}</Td>
                                                    <Td>{checkValueInArrays(a.staffUid)}</Td>
                                                    <Td> </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        :''}
                    </Col> */}

                </Row>
        </LayoutHelper>
    )
}

export default StudentExaminationResultsSummary
