
import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import PublishExamBySenateResult from "../Views/PublishExamBySenateResult";

const SenateRoutes = (props) => {

    return (
        <Routes>
            <Route path="/publish_exam_results" element={<PublishExamBySenateResult/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );

}

export default SenateRoutes;