// export default GroupList;
import React, { createContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { GET_GROUPS } from "../Queries/GroupQueries"
import GroupModal from "./GroupModal"
import { DELETE_GROUPS } from "../Mutations/GroupMutations"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useNavigate } from "react-router"
import AssignPermission from "./AssignPermission"

const breadcrumbItem = [
  {
    title: "Group List",
    path: "",
    isActive: true,
  },
]

export const GroupContext = createContext() //Create data to transfer to child modal

const GroupList = () => {

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const navigation = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState(null) //Get data for deleting
  const [updateGroupData, setUpdateGroupData] = useState(null) //Get data for editing
  const [assignPermission, setAssignPermission] = useState(null) //Assign Permision

  const { loading, error, data, refetch } = useQuery(GET_GROUPS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    // onSubmit: (values, { setStatus, setSubmitting }) => {
    //   console.log("Requesting Page no ")
    // },
  })

  const [
    searchGroupes,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_GROUPS, { client: uaaGraphQLClient })

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    setCurrentPage(selectedPage.selected)
  }

  const [deleteGroup] = useMutation(DELETE_GROUPS, {
    client: uaaGraphQLClient,
    variables: { uid: selectedGroup?.uid },
    refetchQueries: [
      {
        query: GET_GROUPS,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = group => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [group.uid]: !prevState[group.uid],
    }))
  }

  const handleDeleteGroup = () => {
    showDeleteWarning(() => {
      performUserDeletion()
    })
  }

  const performUserDeletion = async () => {
    try {
      const response = await deleteGroup()
      showToast(response.data.removeGroup.message, "success")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading && !searchLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchGroupes({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values?.search || ""
    if (value.length >= 3) {
      let data = {
        search: formikSearch.values.search,
      }
      searchGroupes({ variables: { pagination: { ...pagination, ...data } } })
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }

  const Groupes = data?.getGroups?.data?.items
  const SearchedGroups = searchData?.getGroups?.data?.items

  const results = SearchedGroups ? SearchedGroups : Groupes
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getGroups?.data?.totalCount || searchData?.getGroups?.data?.items || 0

  return (
    <GroupContext.Provider value={{ updateGroupData, setUpdateGroupData, assignPermission, setAssignPermission }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Groups List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <GroupModal />
                      <AssignPermission />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Code</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results?.slice(startIndex, endIndex)
                            .map((Group, index) => (
                              <Tr key={Group?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{Group?.name}</Td>
                                <Td>{Group?.code}</Td>
                                <Td>
                                  <Dropdown
                                    isOpen={dropdownOpen[Group.uid]}
                                    toggle={() => toggleDropdown(Group)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i className="fas fa-ellipsis-v" style={{color: 'green'}} />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateGroupData(Group)
                                        }}
                                      >
                                        <i
                                          className="fas fa-pen"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedGroup(Group)
                                          handleDeleteGroup()
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          setAssignPermission(Group)
                                        }}
                                      >
                                        <i
                                          className="fas fa-users"
                                          style={{
                                            color: "blue",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Assign Permisions</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </GroupContext.Provider>
  )
}

export default GroupList
