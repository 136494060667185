import ReactPaginate from "react-paginate";
import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";

const PaginationHelper = (props) => {
    const  {totalCount,currentPage,pageSize,handleClick,pageItemNumber} = props
    let reminder = 0;
    if((currentPage * pageSize) > 0) reminder = pageSize-pageItemNumber
    // console.log(`${currentPage * pageSize}`)
    return (
        <Row>
            <Col className="col-lg-6 fw-bold text-gray-700">
                Showing { totalCount > 0 ? (currentPage* pageSize)+1:0} to {totalCount > 0 ? Math.ceil(((currentPage * pageSize)+pageItemNumber))-reminder:0} of {totalCount} entries
            </Col>
            <Col
                className="col-lg-6 pull-right"
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <ReactPaginate
                    onPageChange={handleClick}
                    breakLabel={"---"}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    containerClassName={"pagination"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    previousClassName={"page-link"}
                    previousLabel={"<"}
                    nextLabel={">"}
                    activeClassName={"active"}
                    breakLinkClassName={"page-link"}
                    initialPage={currentPage}
                />
            </Col>
        </Row>
    )
}
PaginationHelper.propTypes = {
    totalCount: PropTypes.number.isRequired,
    pageSize : PropTypes.number.isRequired,
    currentPage : PropTypes.number.isRequired,
    handleClick: PropTypes.any.isRequired,
    pageItemNumber: PropTypes.number.isRequired
}

export default PaginationHelper;