import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
import RegionList from "../Views/RegionList";

const RegionRoute = () => {

    return (
        <Routes>
            <Route path="/" element={<RegionList />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )

}

export default RegionRoute;