import React, { useContext, useRef, useState } from "react";
import { Modal, Form, Input, FormFeedback, Button, Row, Col, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import showToast from "helpers/ToastHelper";
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction";
import SubmitButtonHelper from "helpers/SubmitButtonHelper";
import { useMutation, useQuery } from "@apollo/client";
import { onlineApplicationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient";
import Select from "react-select";
import { GET_KINSHIP } from "Modules/Lookups/Kinship/Queries/KinshipQueries";
import { ApplicantNextOfKinContext } from "./ApplicantNextOfKin";
import { REGISTER_APPLICANT_NEXT_OF_KINS } from "../../../OnlineApplication/ProgramSelection/Mutations/ProgramSelectionMutations";
import { GET_APPLICANT_NEXT_OF_KINS } from "../../../OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries";

const ApplicantNextOfKinModal = () => {
    const { updateNextOfKinData, updateStudentData, setUpdateNextOfKinData, kinship, toggleTab, activeTab, applicant } = useContext(ApplicantNextOfKinContext);
    const [modalOpen, setModalOpen] = useState(false);

    const [registerEmergencyContact] = useMutation(REGISTER_APPLICANT_NEXT_OF_KINS, {
        client: onlineApplicationGraphQLClient
    });

    const formRef = useRef();

    const resetForm = () => {
        if (formRef.current) {
            formRef.current.resetForm();
        }
    };

    const { loading: kinShipLoading, error: kinShipError, data: kinShipData } = useQuery(GET_KINSHIP, {
        client: uaaGraphQLClient,
        variables: {
            pagination: paginationHelper
        }
    });

    let kinShipArray = kinShipData?.getKinships?.data?.items;

    const formikValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullName: updateNextOfKinData ? updateNextOfKinData.fullName : "",
            kinshipName: "",
            applicantUid: applicant?.uid,
            phoneNumber: updateNextOfKinData ? updateNextOfKinData.phoneNumber : "",
            kinshipUid: updateNextOfKinData ? updateNextOfKinData.kinshipUid : null
        },
        validationSchema: Yup.object().shape({
            fullName: Yup.string().required("Full name is required"),
            phoneNumber: Yup.string().required("Phone number is required"),
            kinshipUid: Yup.string().required("Kinship is required")
        }),
        onSubmit: (values, { setSubmitting }) => {
            registerEmergencyContact({
                variables: {
                    inputs: [{ ...values, uid: updateNextOfKinData?.uid }]
                },
                refetchQueries: [
                    {
                        query: GET_APPLICANT_NEXT_OF_KINS,
                        variables: {
                            applicantUid: applicant?.uid
                        }
                    }
                ],
                onCompleted: ({ addApplicantNextOfKins }) => {
                    if (addApplicantNextOfKins.code === 8000) {
                        formikValidation.resetForm();
                        setModalOpen(false);
                        setUpdateNextOfKinData(false); // Close the modal
                    } else {
                        setModalOpen(true);
                        setUpdateNextOfKinData(true); // Keep the modal
                    }
                    showToast(
                      addApplicantNextOfKins.message,
                      addApplicantNextOfKins.code === 8000 ? "success" : "error"
                    );
                    formikValidation.resetForm();
                },
                onError: error => {
                    // Handle errors
                    setSubmitting(false);
                    console.error("Mutation error:", error);
                }
            });
        }
    });

    const handleRelationshipChange = (selectedOption) => {
        formikValidation.setFieldValue("kinshipName", selectedOption?.name);
    };

    return (
      <div className="text-sm-end mt-3">
          <Button
            type="button"
            color="primary"
            className="btn mb-2 me-2 btn-sm pull-right"
            onClick={() => setModalOpen(true)}
          >
              <i className="mdi mdi-plus-circle-outline me-1" />
              Add emergency contact
          </Button>
          <Modal
            isOpen={modalOpen}
            backdrop={"static"}
            id="staticBackdrop"
            size="lg"
          >
              <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                      {!!updateNextOfKinData ? "Edit Next of Kin" : "Next of Kin"}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setModalOpen(false);
                        setUpdateNextOfKinData(false);
                        resetForm(); // Reset the form
                    }}
                    aria-label="Close"
                  ></button>
              </div>
              <Form
                onSubmit={e => {
                    e.preventDefault();
                    formikValidation.handleSubmit();
                    return null;
                }}
              >
                  <div className="modal-body">
                      <Row>
                          <Col md={6}>
                              <div className="mt-3">
                                  <label htmlFor="fullName" className="col-form-label">
                                      Full Name:
                                  </label>
                                  <Input
                                    type="text"
                                    name="fullName"
                                    className="form-control"
                                    id="fullName"
                                    placeholder="Enter Name"
                                    onChange={formikValidation.handleChange}
                                    onBlur={formikValidation.handleBlur}
                                    value={
                                      formikValidation.values.fullName ||
                                      updateNextOfKinData?.fullName ||
                                      ""
                                    }
                                    invalid={
                                        !!(
                                          formikValidation.touched.fullName &&
                                          formikValidation.errors.fullName
                                        )
                                    }
                                  />
                                  {formikValidation.touched.fullName &&
                                  formikValidation.errors.fullName ? (
                                    <FormFeedback type="invalid">
                                        {formikValidation.errors.fullName}
                                    </FormFeedback>
                                  ) : null}
                              </div>
                          </Col>
                          <Col md={6}>
                              <div className="mt-3">
                                  <label>Relationship</label>
                                  <Select
                                    onChange={(e) => {
                                        handleRelationshipChange(kinShipArray.find(k => k?.uid === e.value));
                                        formikValidation.setFieldValue("kinshipUid", e.value);
                                    }}
                                    name="kinshipUid"
                                    options={kinShipArray?.map(pr => ({
                                        value: pr.uid,
                                        label: pr.name
                                    }))}
                                    defaultValue={updateNextOfKinData?.uid}
                                    defaultInputValue={updateNextOfKinData?.name}
                                    className="select2-selection"
                                    styles={{
                                        menu: base => ({
                                            ...base,
                                            position: "absolute",
                                            zIndex: 9999
                                        })
                                    }}
                                  />
                                  <Input
                                    type="hidden"
                                    name="kinshipUid"
                                    className="form-control"
                                    id="kinshipUid"
                                    onChange={formikValidation.handleChange}
                                    onBlur={formikValidation.handleBlur}
                                    invalid={
                                        !!(
                                          formikValidation.touched.kinshipUid && formikValidation.errors.kinshipUid
                                        )
                                    }
                                    value={
                                      formikValidation.values.kinshipUid ||
                                      updateNextOfKinData?.kinshipUid ||
                                      ""
                                    }
                                  />
                                  {formikValidation.touched.kinshipUid && formikValidation.errors.kinshipUid ? (
                                    <FormFeedback type="invalid">
                                        {formikValidation.errors.kinshipUid}
                                    </FormFeedback>
                                  ) : null}
                              </div>
                          </Col>
                      </Row>
                      <div className="mb-3">
                          <Row>
                              <Col md={6}>
                                  <label htmlFor="phoneNumber" className="col-form-label">
                                      Phone number:
                                  </label>
                                  <Input
                                    type="text"
                                    name="phoneNumber"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholder="Enter phone number"
                                    onChange={formikValidation.handleChange}
                                    onBlur={formikValidation.handleBlur}
                                    value={
                                      formikValidation.values.phoneNumber ||
                                      updateNextOfKinData?.phoneNumber ||
                                      ""
                                    }
                                    invalid={
                                        !!(
                                          formikValidation.touched.phoneNumber &&
                                          formikValidation.errors.phoneNumber
                                        )
                                    }
                                  />
                                  {formikValidation.touched.phoneNumber &&
                                  formikValidation.errors.phoneNumber ? (
                                    <FormFeedback type="invalid">
                                        {formikValidation.errors.phoneNumber}
                                    </FormFeedback>
                                  ) : null}
                              </Col>
                              <Col md={6}>
                                  <Input
                                    type="hidden"
                                    name="userUid"
                                    className="form-control"
                                    id="userUid"
                                    onChange={formikValidation.handleChange}
                                    onBlur={formikValidation.handleBlur}
                                    value={
                                      formikValidation.values.userUid ||
                                      updateNextOfKinData?.userUid ||
                                      ""
                                    }
                                  />
                              </Col>
                          </Row>
                      </div>
                  </div>
                  <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                            setModalOpen(false);
                            setUpdateNextOfKinData(false);
                            formikValidation.resetForm();
                        }}
                      >
                          Close
                      </button>
                      <SubmitButtonHelper
                        name="Submit"
                        type="primary"
                        formik={formikValidation}
                      />
                  </div>
              </Form>
          </Modal>
      </div>
    );
};

export default ApplicantNextOfKinModal;
