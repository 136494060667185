import React, {createContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import {CREATE_STUDENT_INTENTION_TO_SUBMIT} from "../Mutations/StudentDissertationMutations";
import {useMutation, useQuery} from "@apollo/client";
import {GET_STUDENT_INTENTION_TO_SUBMIT, GET_STUDENT_MANU_SCRIPT} from "../Queries/StudentDissertationQueries";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import PlaceHolderLoader from "../../../../../helpers/PlaceHolderLoader";
import {format} from "date-fns";
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import AlertHelper from "../../../../../helpers/AlertHelper";
import IntentionToSubmitForm from "./IntentionToSubmitForm";


const breadcrumbItem = [
    {
        title: "Dissertation",
        path: "/students/dissertation",
        isActive: false,
    },    {
        title: "View Intention To Submit",
        path: "#",
        isActive: true,
    },
]

export const ViewIntentionToSubmitContext = createContext()
const ViewIntentionToSubmit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    if(!location.state) navigate('/dissertation')
    const [displayError,setDisplayError] = useState('');
    const [haseError,setHaseError] = useState(false);

    const { seminars,student,setting } = location.state
    const [submit] = useMutation(CREATE_STUDENT_INTENTION_TO_SUBMIT)
    const passedSeminars = seminars?.filter((ar) => ar?.isPass === true);
    let passedSeminarNo = 0
    if(passedSeminars) passedSeminarNo = passedSeminars.length

    // console.log(student)
    const { loading:intentionLoading, error:intentionError, data:intentionData } = useQuery(GET_STUDENT_INTENTION_TO_SUBMIT, {
        client:registrationGraphQLClient,
        skip: !student?.uid,
        variables: {
            studentUid: student?.uid,
        },
    })

    const { loading, error, data } = useQuery(GET_STUDENT_MANU_SCRIPT, {
        client:registrationGraphQLClient,
        skip: !student?.uid,
        variables: {
            studentUid: student?.uid,
        },
    })

    const manuScript = data?.getStudentManuscriptByStudentUid?.data
    let intention = intentionData?.getIntentionToSubmitByStudentUid?.data
    if(intention) intention = intention[0]

    useEffect(() => {
        //     define number of Seminars
        if(passedSeminarNo < setting?.minimumSeminars){
            if(manuScript){
                setDisplayError('')
                setHaseError(false)
            }else{
                setDisplayError(`You must have at least ${setting?.minimumManuscripts} submitted Manuscript/paper, Unfortunately you have not submitted any manuscript`)
                setHaseError(true)
            }
        }else{
            setDisplayError(`You must have at least ${setting?.minimumSeminars} Passed Seminars, ${passedSeminarNo} passed seminar detected for you`)
            setHaseError(true)
        }
    },[haseError,displayError])

    if (loading || intentionLoading || !student?.uid) {
        return <PlaceHolderLoader columSize={12} type={"rows"} rows={2} />
    }





    // console.log(setting)
    /*
    const handleSubmitSeminarType = () => {
        let type = seminar.status === 1 ? 'Seminar Date':'Seminar Marks Score'
        showWaringConf(() => {
                confirmSubmit()
            },`Submit ${type}`,`Are you sure you need to submit ${type}? Student will view submitted results`,'warning','Submit',
            () => {
                // setPayType(null)
            })
    }


    const confirmSubmit = async () => {
        try {
            await submit(
                {
                    client: registrationGraphQLClient,
                    variables: {
                        inputs:{
                            title: seminar.title,
                            description: seminar.description,
                            status:seminar?.status+1,
                            uid:seminar.uid,
                            isPass:seminar?.isPass,
                            seminarDate:seminar?.seminarDate,
                            seminarMarks:seminar?.seminarMarks,
                            seminarTypesUid:seminar?.seminarTypes?.uid,
                            studentUid:seminar?.studentUid
                        }
                    },
                    refetchQueries: [
                        {
                            client: registrationGraphQLClient,
                            query: GET_STUDENT_SEMINAR_TYPE,
                            variables: {
                                inputs:{
                                    studentUid:seminar?.studentUid,
                                    seminarTypeUid:seminar?.seminarTypes?.uid
                                },
                            },
                        },
                        {
                            client: registrationGraphQLClient,
                            query: GET_ALL_STUDENT_SEMINARS,
                            variables: {
                                pagination: pagination,
                            },
                        },
                    ],
                    onCompleted: ({registerStudentSeminar}) => {
                        if (registerStudentSeminar.code === 8000) {
                            showToast(registerStudentSeminar.message, "success")
                        } else {
                            showToast(
                                registerStudentSeminar.message,
                                registerStudentSeminar.code === 8000 ? "success" : "error"
                            )
                        }
                    },
                    onError: error => {
                        // Handle errors
                        console.error("Mutation error:", error)
                    },
                }
            )
            // console.log("User deleted successfully:", response)
        } catch (error) {
            console.error("error:", error)
        }
    }
*/
    // console.log(intention)
    return(
        <ViewIntentionToSubmitContext.Provider value={{ intention }}>
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`Intention To Submit`} >
            {intention &&
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <CardTitle className="text-center text-uppercase"><h3>Title: {intention.title}</h3></CardTitle>
                                <CardSubtitle>
                                    <ul style={{listStyleType:"none",marginLeft:'-3%',fontSize:'120%'}}>
                                        <li>
                                            <strong>Status:</strong>
                                            {intention.status === 0 && <span className="badge bg-danger"> Un submitted/Pending ...</span>}
                                            {intention.status === 1 && <span className="badge bg-danger"> Seminar Date Allocation Pending..</span>}
                                            {intention.status === 2 && <span className="badge bg-danger"> Seminar Assessment Score Pending..</span>}
                                            {intention.status === 3 && <span className="badge bg-success"> Seminar Results Published</span>}
                                        </li>
                                        <li>
                                            <strong>Submitted Date: </strong> <span className="badge bg-success"> {intention?.submittedDate ? format(new Date(intention?.submittedDate), 'MMMM dd, yyyy'):''}</span>
                                        </li>
                                    </ul>
                                </CardSubtitle>
                                {/*<hr/>*/}
                                {/*<div style={{*/}
                                {/*    maxHeight:'700px', overflow: 'auto'*/}
                                {/*}} dangerouslySetInnerHTML={{ __html: intention.description }}/>*/}
                            </CardBody>
                            <CardFooter>
                                {/*{intention?.status === 0 && <Button onClick={() => handleSubmitSeminarType()} className="btn btn-success float-start">Submit {seminar?.status === 1 ? 'Seminar Date':'Seminar Score'}</Button>}*/}
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            }

            {
                !intention && haseError ?
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <AlertHelper type={'danger'} message={displayError} />
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
                    :
                    (
                        !intention && !haseError  &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="text-center">
                                        <IntentionToSubmitForm student={student} />
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    )
            }
            {/*<CourseLeaningOutcomes course={course} />*/}
        </LayoutHelper>
        </ViewIntentionToSubmitContext.Provider>
    );
}

export default ViewIntentionToSubmit;