import React, { useState, useEffect, createContext } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  TabContent
} from "reactstrap"
import classnames from "classnames"
import { GET_USER } from "../Queries/UserQuery"
import { useQuery } from "@apollo/client"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import PersonalInformation from "./PersonalInformaiton"
import EmploymentDetail from "./EmploymentDetail"
import NextOfKinList from "./NextOfKin/NextOfKinList"
import ChildrenList from "./Children/ChildrenList"
import LanguageProficiencyList from "./LanguageProficiency/LanguageProficiencyList"
import StaffProfileImage from "./StaffProfileImage";
import {LoaderPage} from "../../../helpers/LoaderPage";

const breadcrumbItem = [
  {
    title: "User Profile",
    path: "",
    isActive: true,
  }
  ]

export const StaffProfileContext = createContext() // Create data to transfer to child modal

const StaffProfile = () => {
  const [updateStaffData, setUpdateStaffData] = useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const toggleTab = tab => {
    handleSubmit()
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const {
    loading: staffLoading,
    error: staffError,
    data: userData,
  } = useQuery(GET_USER, {
    fetchPolicy: "no-cache",
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data.username,
    },
  })
  useEffect(() => {
    // var body = document.body
    // body.classList.toggle("vertical-collpsed")
    if (userData) {
      setUpdateStaffData(userData?.getUserByUsername?.data)
    }
  }, [staffLoading, staffError, userData])

  const handleSubmit = values => {
    // console.log("ValuesSubmitted", values)
  }

  if(staffLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"User Profile"} description={"Fetching User Details"} />

  const renderStepContent = tabId => {
    switch (tabId) {
      case 1:
        return <PersonalInformation />
      case 2:
        return <EmploymentDetail />
      case 3:
        return <ChildrenList />
      case 4:
        return <NextOfKinList />
      case 5:
        return <LanguageProficiencyList />
      default:
        return null
    }
  }


  return (
    <StaffProfileContext.Provider
      value={{
        updateStaffData,
        setUpdateStaffData,
        toggleTab,
        handleSubmit,
        activeTab,
        passedSteps,
      }}
    >
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="wizard">
                <div className="steps clearfix">
                  <ul style={{ whiteSpace: "nowrap" }}>
                    <NavItem
                        className={classnames({
                          current: activeTab === 1,
                        })}
                    >
                      <NavLink
                          className={classnames({
                            current: activeTab === 1,
                          })}
                          onClick={() => toggleTab(1)}
                          disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">1.</span> Personal
                        Information
                      </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({
                          current: activeTab === 2,
                        })}
                    >
                      <NavLink
                          className={classnames({
                            active: activeTab === 2,
                          })}
                          onClick={() => toggleTab(2)}
                          // disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">2.</span> Employment
                        Detail
                      </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({
                          current: activeTab === 3,
                        })}
                    >
                      <NavLink
                          className={classnames({
                            active: activeTab === 3,
                          })}
                          onClick={() => toggleTab(3)}
                          // disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">3.</span> Children Detail
                      </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({
                          current: activeTab === 4,
                        })}
                    >
                      <NavLink
                          className={classnames({
                            active: activeTab === 4,
                          })}
                          onClick={() => toggleTab(4)}
                          // disabled={!(passedSteps || []).includes(5)}
                      >
                        <span className="number">4.</span> Next of Kin
                      </NavLink>
                    </NavItem>
                    <NavItem
                        className={classnames({
                          current: activeTab === 5,
                        })}
                    >
                      <NavLink
                          className={classnames({
                            active: activeTab === 5,
                          })}
                          onClick={() => toggleTab(5)}
                          // disabled={!(passedSteps || []).includes(6)}
                      >
                        <span className="number">5.</span> Language
                        Proficiency
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    {renderStepContent(activeTab)}
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </StaffProfileContext.Provider>
  )
}

export default StaffProfile
