import React, { useState, createContext } from "react"
import { Link } from "react-router-dom"

const HelpDesk = () => {

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-12 mb-4">
          <h3>Hotline Numbers</h3>
        </div>
        <div className="col-12 col-md-12 col-lg-12 mb-3">
          <div className="table table-responsive">
            <table className="table table-striped table-bordered"
                   style={{ border: "2px solid #63a69f" }}
            >
              <thead>
              <tr>
                <th>S/No</th>
                <th>Phone Number</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1</td>
                <td><a href="https://wa.me/0737462229?text=Hello" className="bt btn-link text-dark">0737 46 22 29</a></td>
              </tr>
              <tr>
                <td>2</td>
                <td><a href="https://wa.me/0737462230?text=Hello" className="bt btn-link text-dark">0737 46 22 30</a></td>
              </tr>
              <tr>
                <td>3</td>
                <td><a href="https://wa.me/0733091180?text=Hello" className="bt btn-link text-dark">0733 09 11 80</a></td>
              </tr>
              <tr>
                <td>4</td>
                <td><a href="https://wa.me/0733091181?text=Hello" className="bt btn-link text-dark">0733 09 11 81</a></td>
              </tr>
              </tbody>
            </table>
            <p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSeH4IocibWejN1uIzGIRzaEZ3GarkgBXSQiT1XVL06zYQUhXA/viewform?pli=1"
                target="_blank" rel="noopener noreferrer">
                If you have any query, please click here
              </a></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpDesk