import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { CREATE_COUNTRY } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_COUNTRY } from "../Queries/AccommodationQueries"
import { AccommodationYearContext } from "./AccommodationYearList"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const AccommodationYearModal = () => {
  const { updateAccommodationYear, setUpdateAccommodationYear } = useContext(AccommodationYearContext)
  const [campus] = useMutation(CREATE_COUNTRY, {client: uaaGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_COUNTRY
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      academicYearUid: updateAccommodationYear ? updateAccommodationYear.name : "",
      semester: updateAccommodationYear ? updateAccommodationYear.code : "",
    },

    validationSchema: Yup.object().shape({
      academicYearUid: Yup.string().required("academic Year is required"),
      semester: Yup.string().required("Semester is required e.g Tz.."),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, {setSubmit}) => {
      campus({
        variables: {
          inputs: [{ ...values, uid: updateAccommodationYear?.uid }],
        },
        client:uaaGraphQLClient,
        refetchQueries: [
          {
            query: GET_COUNTRY,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerCountries }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (registerCountries.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateAccommodationYear(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateAccommodationYear(true) // Close the modal
            setSubmit(false)
          }
          showToast(
            registerCountries.message,
            registerCountries.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Accommodation Year
      </Button>
      <Modal
        isOpen={modalOpen || !!updateAccommodationYear}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateAccommodationYear ? "Edit Accommodation Year" : "Add New Accommodation Year"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="academicYearUid" className="col-form-label">
              Academic Year:
              </label>
              <Input
                type="text"
                name="academicYearUid"
                className="form-control"
                id="academicYearUid"
                placeholder="Academic Year Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.academicYearUid || ""}
                invalid={
                  validation.touched.academicYearUid && validation.errors.academicYearUid
                    ? true
                    : false
                }
              />
              {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.academicYearUid}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="semester" className="col-form-label">
                Semester
              </label>
              <Input
                type="text"
                name="semester"
                className="form-control"
                id="semester"
                placeholder="semester"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.semester || ""}
                invalid={
                  validation.touched.semester && validation.errors.semester
                    ? true
                    : false
                }
              />
              {validation.touched.semester && validation.errors.semester ? (
                <FormFeedback type="invalid">
                  {validation.errors.semester}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateAccommodationYear(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default AccommodationYearModal
