export const CourseWeights = () => {
  const weights = [] // assign empty array for storing course weight
  for (let i = 1; i <= 100; i++) {
    weights.push({ value: i, label: parseInt(`${i}`) }) // Mark out Of
  }
  return weights // Return the array after the loop
}


export const ExcelSortingFormat = () => {
  const excelSorting = [] 
  excelSorting.push({ value: 'REGNO', label: 'Registration Numbers' })
  excelSorting.push({ value: 'NAME', label: 'Student Names' })
  return excelSorting // Return the array after the loop
}

export const courseMarkedOut = () => {

  const markedOut = [] // Options for Mark out

  for (let i = 1; i <= 100; i++) {
    markedOut.push({ value: i, label: parseInt(`${i}`) }) // Mark out Of
  }
  return markedOut
}
