import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, InputGroup, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";

import {useFormik} from "formik";
import * as Yup from "yup";
import {useMutation} from "@apollo/client";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import {GET_STUDENT_INTENTION_TO_SUBMIT} from "../Queries/StudentDissertationQueries";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import {ViewIntentionToSubmitContext} from "./ViewIntentionToSubmit";
import {CREATE_STUDENT_INTENTION_TO_SUBMIT} from "../Mutations/StudentDissertationMutations";

const IntentionToSubmitForm = props => {
    const {intention} = useContext(ViewIntentionToSubmitContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [register] = useMutation(CREATE_STUDENT_INTENTION_TO_SUBMIT)
    const  {student} = props
// Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            studentUid:student?.uid,
            // description:paperData?.description,
            title:intention?.title,
            plagiarismReport:intention?.plagiarismReport,
            plagiarismStatus:intention?.plagiarismStatus,
            plagiarismPercentage:intention?.plagiarismPercentage,
            status:intention ? intention.status : 0
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Title is required"),
            plagiarismStatus: Yup.number().required("Plagiarism Status is required"),
            plagiarismPercentage:Yup.number().min(0,'Minimum percentage is 0').max(100,'Maximum percentage is 100').required("Plagiarism Percentage is required"),
            plagiarismReport: Yup.string().required("Plagiarism Report is required"),
        }),
        onSubmit: (values,{setSubmitting,resetForm}) => {
            register({
                client: registrationGraphQLClient,
                variables: {
                    inputs: [{ ...values,uid:intention?.uid }],
                },
                refetchQueries: [
                    {
                        query: GET_STUDENT_INTENTION_TO_SUBMIT,
                        variables:{
                            studentUid: student?.uid,
                        }
                    },
                ],
                onCompleted: ({ registerIntentionToSubmit }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (registerIntentionToSubmit.code === 8000) {
                        // setPaperData(false)
                        resetForm()
                        setSubmitting(false)
                        setModalOpen(false)
                    } else {
                        // showToast(
                        //     registerStudentManuscript.message,
                        //     registerStudentManuscript.code === 8000 ? "success" : "error"
                        // )
                        setSubmitting(false)
                    }
                },
                onError: error => {
                    setSubmitting(false)
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
            // navigate('/Students/Payments/queries')
        }
    });

    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add New Thesis
            </Button>
            <Modal
                isOpen={modalOpen}
                backdrop={"static"}
                size={'lg'}
                id="staticBackdrop"
                // size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!intention
                            ? `Edit Thesis`
                            : `Add New Thesis`}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <div className="modal-body">
                        <Row className="row mb-3">
                            <Col lg={12}>
                                <label htmlFor="name" className="col-form-label">
                                     Title
                                </label>
                                <Input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    id="firstname"
                                    placeholder={`Enter Thesis Title`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.title
                                    }
                                    invalid={!!(validation.touched.title && validation.errors.title)}
                                />
                                {validation.touched.title && validation.errors.title ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.title}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col lg={6}>
                                <Label>Plagiarism Status</Label>
                                <Select
                                    // value={selectedMulti2}
                                    onChange={e => {
                                        validation.setFieldValue("plagiarismStatus", e.value)
                                    }}
                                    className="select2-selection"
                                    styles={{
                                        menu: base => ({
                                            ...base,
                                            position: "absolute",
                                            zIndex: 9999
                                        })
                                    }}

                                    options={[
                                        {
                                            label:"Checked",
                                            value:1
                                        },
                                        {
                                            label:"Not Checked",
                                            value:0
                                        }
                                    ]}
                                    defaultValue={validation.values.plagiarismStatus}
                                    // inputValue={}
                                    defaultInputValue={intention?.value === 1 && 'Checked' || intention?.value === 0 &&'Not Checked' || ''}
                                />
                                <Input
                                    name="plagiarismStatus"
                                    placeholder=""
                                    type="hidden"
                                    rows={3}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.plagiarismStatus}
                                    invalid={
                                        !!(
                                            validation.touched.plagiarismStatus &&
                                            validation.errors.plagiarismStatus
                                        )
                                    }
                                />
                                {validation.touched.plagiarismStatus &&
                                validation.errors.plagiarismStatus ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.plagiarismStatus}
                                    </FormFeedback>
                                ) : null}
                            </Col>

                            <Col lg={6}>
                                <label htmlFor="seminarMarks" className="col-form-label">
                                    Plagiarism Percentage
                                </label>
                                <Input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="plagiarismPercentage"
                                    className="form-control"
                                    id="plagiarismPercentage"
                                    placeholder={`Enter Score Marks`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.plagiarismPercentage
                                    }
                                    invalid={
                                        !!(validation.touched.plagiarismPercentage && validation.errors.plagiarismPercentage)
                                    }
                                />
                                {validation.touched.plagiarismPercentage && validation.errors.plagiarismPercentage ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.plagiarismPercentage}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mb-3">

                            <Col lg={6}>
                                <label htmlFor="seminarMarks" className="col-form-label">
                                    Plagiarism Report
                                </label>
                                <Input
                                    type="textarea"
                                    rows={5}
                                    name="plagiarismReport"
                                    className="form-control"
                                    id="plagiarismReport"
                                    placeholder={`Write plagiarism Report`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.plagiarismReport
                                    }
                                    invalid={
                                        !!(validation.touched.plagiarismReport && validation.errors.plagiarismReport)
                                    }
                                />
                                {validation.touched.plagiarismReport && validation.errors.plagiarismReport ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.plagiarismReport}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                // setPaperData(false)
                                validation.resetForm() // Reset the form
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
IntentionToSubmitForm.propTypes = {
    student: PropTypes.any.isRequired
}
export default IntentionToSubmitForm;