import { gql } from "@apollo/client"

const CREATE_DISABILITY = gql`
  mutation RegisterDisabilities($inputs: [DisabilityDtoInput!]!) {
    registerDisabilities(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_DISABILITY = gql`
  mutation RemoveDisability($uid: String!) {
    removeDisability(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_DISABILITY, DELETE_DISABILITY }
