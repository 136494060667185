import {Route, Routes} from "react-router-dom";
import Pages404 from "../../Modules/Utility/pages-404";
import React from "react";
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard";
import ControlNumbers from "../../Modules/Students/Views/Payments/ControlNumbers";
import StudentPaymentServices from "../../Modules/Students/Views/Payments/PaymentServices";
import StudentFinancialStatement from "../../Modules/Students/Views/Payments/StudentFinanciaStatement";
import StudentFeeStructure from "../../Modules/Students/Views/Payments/StudentFeeStructure";
import FinanceQueries from "../../Modules/Students/Views/Payments/FinanceQueries";
import FinanceQueryCreate from "../../Modules/Students/Views/Payments/FinanceQueryCreate";

const FinanceRoutes = () => {
    return (
        <Routes>
            <Route path="/control_number" element={<ControlNumbers />} />
            <Route path="/services" element={<StudentPaymentServices />} />
            <Route path="/statement" element={<StudentFinancialStatement />} />
            <Route path="/fee_structure" element={<StudentFeeStructure />} />
            <Route path="/queries" element={<FinanceQueries />} />
            <Route path="/queries/create" element={<FinanceQueryCreate />} />
            <Route path="dashboard" element={<AsimsDashboard />} />
            <Route path="*" element={<Pages404 />} />
            {/* Add more student-related routes here */}
        </Routes>
    );
}

export default FinanceRoutes;