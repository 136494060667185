
import { gql } from "@apollo/client";

const SEND_STUDENT_TO_LDAP = gql`
  mutation SendStudentToLdap($username:  String!) {
    sendStudentToLdap(username: $username) {
      status
      code
      message
    }
  }
`;

export { SEND_STUDENT_TO_LDAP }

