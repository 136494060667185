import "toastr/build/toastr.min.css";
import toastr from "toastr";
import Swal from "sweetalert2";
const showToast = function(message, toastType, title = '') {
  //position class
  let positionClass = "toast-top-right";
  toastr.options = {
    positionClass: positionClass,
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: null,
    debug: null,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    showDuration: 300,
    hideDuration: 1000
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (toastType === "warning") toastr.warning(message, title);
  else if (toastType === "error") toastr.error(message, title);
  else if (toastType === "success") toastr.success(message, title);
  else toastr.info(message, title);
}
export default showToast;

// const trushWarning = () => {
//   Swal.fire({
//     title: 'Are you sure?',
//     text: 'You are about to delete the user. This action cannot be undone.',
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#dc3545',
//     cancelButtonColor: '#6c757d',
//     confirmButtonText: 'Delete',
//   })
// }

// export default trushWarning;