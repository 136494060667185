import React, { useContext, useEffect, useRef, useState } from "react"
import {Modal, Form, Input, FormFeedback, Button, Col, Label, Row} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"

import { ThesisSettingContext } from "./ThesisSettingList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import {CREATE_THESIS_SETTING} from "../Mutations/ThesisSettingMutations";
import {GET_THESIS_SETTINGS} from "../Queries/ThesisSettingQueries";
import {GET_PROGRAM_CATEGORIES} from "../../../ProgramCategories/Queries/ProgramCategoryQueries";
import Select from "react-select";
const ThesisSettingModal = () => {

  const { updateThesisSettingData, setUpdateThesisSettingData } =
    useContext(ThesisSettingContext)
  const [create] = useMutation(CREATE_THESIS_SETTING)
  const [modalOpen, setModalOpen] = useState(false)

  const {
    loading: catLoading,
    error: catError,
    data: catData,
  } = useQuery(GET_PROGRAM_CATEGORIES, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  let categories = catData?.getProgramCategories?.data?.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      programCategoryUid: updateThesisSettingData ? updateThesisSettingData?.programCategory?.uid : "",
      lifeSpan: updateThesisSettingData ? updateThesisSettingData.lifeSpan : "",
      minimumManuscripts: updateThesisSettingData ? updateThesisSettingData.minimumManuscripts : "",
      minimumSeminars: updateThesisSettingData ? updateThesisSettingData.minimumSeminars : "",
      seminarPassMarks: updateThesisSettingData ? updateThesisSettingData.seminarPassMarks : "",
    },
    validationSchema: Yup.object().shape({
      programCategoryUid: Yup.string().required("Program Category is required"),
      lifeSpan: Yup.number().min(1,"It Should not be less than 1").max(10,"it should not be more than 10").required("Intention Life Span is required"),
      minimumManuscripts: Yup.number().min(1,"It Should not be less than 1").max(10,"it should not be more than 10").required("Minimum Manuscripts  Number is required"),
      seminarPassMarks: Yup.number().min(1,"It Should not be less than 1").max(100,"it should not be more than 100").required("Seminar Pass Marks Number is required"),
      minimumSeminars: Yup.number().min(1,"It Should not be less than 1").max(10,"it should not be more than 10").required("Minimum Seminars Number is required"),
    }),
    onSubmit: (values, {setSubmitting,resetForm}) => {
      create({
        client:registrationGraphQLClient,
        variables: {
          inputs: { ...values, uid: updateThesisSettingData?.uid },
        },
        refetchQueries: [
          {
            client: registrationGraphQLClient,
            query: GET_THESIS_SETTINGS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        skip: !modalOpen,
        onCompleted: ({ registerIntentionToSubmitRequirement }) => {
          //updateFunction(client.cache, { data: { registerStatuss } });
          if (registerIntentionToSubmitRequirement.code === 8000) {
            setModalOpen(false)
            setUpdateThesisSettingData(false) // Close the modal
            validation.resetForm()
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)

          console.error("Mutation error:", error)
        },
      })
    },
  })
  
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Thesis Setting
      </Button>
      <Modal
        isOpen={modalOpen || !!updateThesisSettingData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateThesisSettingData
              ? "Edit Thesis Setting"
              : "Add New Thesis Setting"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateThesisSettingData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col lg="6" className="mt-2">
                  <Label>Program Category</Label>
                  <Select
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                      // value={selectedMulti2}
                      onChange={e => {
                        // validation.values.programCategoryUid =e.value
                        validation.setFieldValue(
                            "programCategoryUid",
                            e.value
                        )
                      }}
                      defaultValue={validation.values.programCategoryUid}
                      defaultInputValue={updateThesisSettingData?.programCategory?.name}
                      options={categories?.map(cat => ({
                        value: cat.uid,
                        label: cat.name
                      }))}
                      className="select2-selection"
                      isLoading={catLoading}
                  />
                  <Input
                      name="programCategoryUid"
                      placeholder=""
                      type="hidden"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.programCategoryUid || ""}
                      invalid={
                        !!(
                            validation.touched.programCategoryUid &&
                            validation.errors.programCategoryUid
                        )
                      }
                  />
                  {validation.touched.programCategoryUid &&
                  validation.errors.programCategoryUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.programCategoryUid}
                      </FormFeedback>
                  ) : null}
              </Col>

              <Col lg={6}>
                <label htmlFor="name" className="col-form-label">
                    Minimum Manuscript/Paper
                </label>
                <Input
                    type="number"
                    name="minimumManuscripts"
                    className="form-control"
                    id="minimumManuscripts"
                    placeholder="Enter Minimum Manuscript"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                        validation.values.minimumManuscripts
                    }
                    invalid={!!(validation.touched.minimumManuscripts && validation.errors.minimumManuscripts)}
                />
                {validation.touched.minimumManuscripts && validation.errors.minimumManuscripts ? (
                    <FormFeedback type="invalid">
                      {validation.errors.minimumManuscripts}
                    </FormFeedback>
                ) : null}
              </Col>

              <Col lg={6}>
                <label htmlFor="name" className="col-form-label">
                  Minimum Seminars
                </label>
                <Input
                    type="number"
                    name="minimumSeminars"
                    className="form-control"
                    id="minimumSeminars"
                    placeholder="Enter Thesis Minimum Seminars"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.minimumSeminars
                    }
                    invalid={!!(validation.touched.minimumSeminars && validation.errors.minimumSeminars)}
                />
                {validation.touched.minimumSeminars && validation.errors.minimumSeminars ? (
                    <FormFeedback type="invalid">
                      {validation.errors.minimumSeminars}
                    </FormFeedback>
                ) : null}
              </Col>

              <Col lg={6}>
                <label htmlFor="name" className="col-form-label">
                  Seminar Pass Marks
                </label>
                <Input
                    type="number"
                    name="seminarPassMarks"
                    className="form-control"
                    id="seminarPassMarks"
                    placeholder="Enter Seminar Pass Mark"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.seminarPassMarks
                    }
                    invalid={!!(validation.touched.seminarPassMarks && validation.errors.seminarPassMarks)}
                />
                {validation.touched.seminarPassMarks && validation.errors.seminarPassMarks ? (
                    <FormFeedback type="invalid">
                      {validation.errors.seminarPassMarks}
                    </FormFeedback>
                ) : null}
              </Col>

              <Col lg={6}>
                <label htmlFor="name" className="col-form-label">
                  Intention Life Span (years)
                </label>
                <Input
                    type="number"
                    name="lifeSpan"
                    className="form-control"
                    id="lifeSpan"
                    placeholder="Enter Life span"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.lifeSpan
                    }
                    invalid={!!(validation.touched.lifeSpan && validation.errors.lifeSpan)}
                />
                {validation.touched.lifeSpan && validation.errors.lifeSpan ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lifeSpan}
                    </FormFeedback>
                ) : null}
              </Col>

            </Row>



          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateThesisSettingData(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ThesisSettingModal
