import React, { useContext, useEffect, useState } from "react"
import { Form, Input, FormFeedback, Row, Col, Label, Button } from "reactstrap"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { Link } from "react-router-dom"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useParams } from "react-router"
import { TeachingAssessmentProfileContext } from "./TeachingCourseAssessmentParent"
import { GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT } from "Modules/TeachingAssessment/Queries/TeachingAssessmentQueries"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT } from "Modules/TeachingAssessment/Mutations/TeachingAssessmentMutations"
import { useFormik } from "formik"

const PartOne = () => {
  const { uid } = useParams()

  const { updateTeachingAssessmentData } = useContext(
    TeachingAssessmentProfileContext
  )

  const { toggleTab } = useContext(TeachingAssessmentProfileContext)
  const [isSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const [registerCourseAssessment] = useMutation(
    REGISTER_STUDENT_PROGRAM_COURSE_ASSESSMENT,
    {
      client: registrationGraphQLClient,
    }
  )

  const {
    loading: courseAssessmentLoading,
    error: courseAssessmentError,
    data: courseAssessmentData,
  } = useQuery(GET_STUDENT_PROGRAM_COURSE_ASSESSMENT_RESULT, {
    client: registrationGraphQLClient,
    variables: {
      studentCourseRegistrationUid: uid ? uid : "",
      questionNo: 1,
    },
    skip: !uid,
    fetchPolicy: "network-only",
  })

  const questionOneRes =
    courseAssessmentData?.getStudentProgramCourseAssessmentResult?.data ?? {}

  const [outlineProvided, setOutlineProvided] = useState(
    questionOneRes?.answer !== null && questionOneRes?.answer !== "undefined"
      ? true
      : false
  )

  const validation = useFormik({
    initialValues: {
      payload: {},
    },
  })
  const handleCheckboxChange = event => {
    setOutlineProvided(event.target.value)
  }

  useEffect(() => {
    const questionOneRes = courseAssessmentData?.getStudentProgramCourseAssessmentResult?.data ?? {};
    setOutlineProvided(questionOneRes?.answer ?? '');
  }, [courseAssessmentData]);

  const handleNext = () => {
    setLoading(true)
    registerCourseAssessment({
      variables: {
        inputs: {
          answer: outlineProvided,
          questionNo: 1,
          studentCourseRegistrationUid: uid,
        },
      },
      onCompleted: ({ registerStudentProgramCourseAssessmentResult }) => {
        if (registerStudentProgramCourseAssessmentResult.code === 8000) {
          setOutlineProvided(false)
          showToast(
            registerStudentProgramCourseAssessmentResult.message,
            "success"
          )
          setLoading(false)
          toggleTab(2) // Move to the next tab
        } else {
          showToast(
            registerStudentProgramCourseAssessmentResult.message,
            registerStudentProgramCourseAssessmentResult.code === 8000
              ? "success"
              : "error"
          )
          setLoading(false)
          toggleTab(1) // Move to the next tab
        }
      },
      onError: error => {
        setLoading(false)
        console.error("Mutation error:", error)
      },
    })
  }

  return (
    <div className="mt-3 wizard">
      {courseAssessmentLoading ? (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label className="font-weight-bolder">
                  <b>1.0</b> Was a course outline provided at the beginning of the
                    course?
                  </Label>
                  <div>
                    <Label>
                      <Input
                        type="radio"
                        value="Yes"
                        defaultChecked={questionOneRes?.answer === "Yes"}
                        name="qn1"
                        onChange={handleCheckboxChange}
                      />{" "}
                      Yes
                    </Label>
                    <br />
                    <Label>
                      <Input
                        type="radio"
                        value="No"
                        name="qn1"
                        defaultChecked={questionOneRes?.answer === "No"}
                        onChange={handleCheckboxChange}
                      />{" "}
                      No
                    </Label>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="wizard">
            <div className="actions">
              {outlineProvided && (
                <ul>
                  <li className="previous">
                    <Link to="#" onClick={handleNext}>
                      {loading ? <>Please wait..</> : <>{`Next >`}</>}
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </Form>
      )}
    </div>
  )
}
export default PartOne
