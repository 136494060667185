import { gql } from "@apollo/client"

const CREATE_COUNTRY = gql`
mutation RegisterCountries($inputs: [CountryDtoInput!]!) {
  registerCountries (inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items{
        id
        uid
        name
        code
        dialCode
     }
    }
  }
}
`

const DELETE_COUNTRY = gql`
mutation RemoveCountries($uid: String!) {
  removeCountry(uid: $uid) {
    message
    code
    status
  }
}
`

export { CREATE_COUNTRY, DELETE_COUNTRY }
