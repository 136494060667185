import React from "react";
import { Routes, Route } from "react-router-dom";
import Pages404 from "Modules/Utility/pages-404";
import Dissertation from "../Views/Dissertation";
import ViewDissertation from "../Views/ViewDissertation";
import DissertationPaperRoutes from "./DissertationPaperRoutes";
import ViewIntentionToSubmit from "../Views/ViewIntentionToSubmit";

const DissertationRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Dissertation/>} />
            <Route path="view" element={<ViewDissertation/>} />
            <Route path="thesis" element={<ViewIntentionToSubmit/>} />
            <Route path="papers/*" element={<DissertationPaperRoutes/>} />
            <Route path="*" element={<Pages404/>} />

        </Routes>
    );
}

export default DissertationRoutes;