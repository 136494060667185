import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import {StudentChangeProgramContext} from "./StudentChangeProgram";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {STUDENT_CHANGE_PROGRAM} from "../../Mutations/StudentApplicationMutations";
import {limit} from "../../../../helpers/UrlHelper";
import {
    GET_CATEGORY_PROGRAMS_BY_PROGRAM_UID,
    GET_STUDENT_CHANGE_PROGRAM_REQUEST
} from "../../Queries/Application/StudentApplicationQueries";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../../helpers/ToastHelper";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
const ChangeProgramModel = props => {
    const {type,setType} = useContext(StudentChangeProgramContext)

    const [changeProgramRequest] = useMutation(STUDENT_CHANGE_PROGRAM)
    const  {student} = props
    const pagination = { offset: 0, limit: limit, search: null }

    const { loading:programLoading, error:programError, data:programData } = useQuery(GET_CATEGORY_PROGRAMS_BY_PROGRAM_UID, {
        client: registrationGraphQLClient,
        skip:!student?.programmeUid,
        variables: {
            programUid: student?.programmeUid,
        },
    })
    let programs = programData?.getProgramsOnProgramCategory?.data?.items
    if(programs) programs = programs?.filter((ar) => ar?.uid !== student?.programmeUid )
    // console.log()
// Form validation
    const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            newProgramUid: '',
            studentUid:student?.uid,
            currentRegistrationNumber:student?.registrationNumber,
            currentProgramUid:student?.programmeUid,
            reason:'',
        },
        validationSchema: Yup.object().shape({
            newProgramUid: Yup.string().required("Program is required"),
            reason: Yup.string().required("Reason is required"),
        }),
        onSubmit: (values,{setSubmitting,resetForm}) => {
            changeProgramRequest({
                client: registrationGraphQLClient,
                variables: {
                    input: values,
                },
                refetchQueries: [
                    {
                        query: GET_STUDENT_CHANGE_PROGRAM_REQUEST,
                        variables:{
                            studentUid: student?.uid,
                        }
                    },
                ],
                onCompleted: ({ studentRequestChangeProgram }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (studentRequestChangeProgram.code === 8000) {
                        showToast(studentRequestChangeProgram.message, "success")
                        setType(false)
                        resetForm()
                        setSubmitting(false)
                    } else {
                        showToast(
                            studentRequestChangeProgram.message,
                            studentRequestChangeProgram.code === 8000 ? "success" : "error"
                        )
                        setSubmitting(false)
                    }
                },
                onError: error => {
                    setSubmitting(false)
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
            // navigate('/Students/Payments/queries')
        }
    });

    // const search = (value,type) => {
    //     let data = {
    //         search: value,
    //     }
    //     if (value.length >= 2) {
    //         if (type === 'program'){
    //             searchPrograms({
    //                 variables: { pagination: { ...pagination, ...data } },
    //             })
    //         }
    //
    //     }
    // }

    // console.log(student)
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setType(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Request Change Program
            </Button>
            <Modal
                isOpen={type}
                backdrop={"static"}
                id="staticBackdrop"
                // size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {"Request Change Program"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setType(false)
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validationType.handleSubmit();
                        return false;
                    }}>
                    <div className="modal-body">
                        <Row>
                        <div className="col-lg-12 col-12 mb-3">
                            <Label>Change Program To</Label>
                            <Select
                                // value={selectedMulti2}
                                styles={{
                                    menu: base => ({
                                        ...base,
                                        position: "absolute",
                                        zIndex: 9999
                                    })
                                }}
                                onChange={e => {
                                    validationType.setFieldValue("newProgramUid", e.value)
                                }}
                                // onInputChange={e => {
                                //     search(e,'program')
                                // }}
                                options={programs?.map(pro => ({
                                    value: pro.uid,
                                    label: pro.name + " (" + pro.code + ")",
                                }))}
                                className="select2-selection"
                                isLoading={programLoading || !student?.programmeUid}
                            />
                            <Input
                                name="newProgramUid"
                                placeholder="Enter yourAddresses"
                                type="hidden"
                                rows={3}
                                onChange={validationType.handleChange}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.program || ""}
                                invalid={
                                    !!(
                                        validationType.touched.newProgramUid &&
                                        validationType.errors.newProgramUid
                                    )
                                }
                            />
                            {validationType.touched.newProgramUid &&
                            validationType.errors.newProgramUid ? (
                                <FormFeedback type="invalid">
                                    {validationType.errors.newProgramUid}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="col-lg-12 col-12 mb-3">
                            <Label className="form-label">Reason For Program Change</Label>
                            <Input
                                name="reason"
                                rows={5}
                                placeholder="Enter Description"
                                type="textarea"
                                onChange={validationType.handleChange}
                                onBlur={validationType.handleBlur}
                                value={validationType.values.reason || ""}
                                invalid={
                                    !!(validationType.touched.reason && validationType.errors.reason)
                                }
                            />
                            {validationType.touched.reason && validationType.errors.reason ? (
                                <FormFeedback type="invalid">{validationType.errors.reason}</FormFeedback>
                            ) : null}
                        </div>
                            <Col lg={12}>
                                <Button id='closeBtn' type={'button'} onClick={
                                    event => {
                                        setType(false)
                                    }
                                } >Cancel</Button>

                                {' '}
                                <SubmitButtonHelper type={'primary'} name={'Submit Request'} formik={validationType} />

                            </Col>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
ChangeProgramModel.propTypes = {
    student: PropTypes.any.isRequired
}
export default ChangeProgramModel;