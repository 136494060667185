// export default NextOfKinList;
import React, { createContext, useContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, CardFooter } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation, useQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import NextOfKinModal from "./NextOfKinModal"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StaffProfileContext } from "../StaffProfile"
import { GET_NEXT_OF_KIN_BY_STAFF } from "Modules/Users/Queries/UserQuery"
import { DELETE_STAFF_NEXT_OF_KIN } from "Modules/Users/Mutations/UserMutations"
import { Link } from "react-router-dom"
import DropDownActionHelper from "helpers/DropDownActionHelper"

export const NextOfKinContext = createContext() //Create data to transfer to child modal

const NextOfKinList = () => {
  const { updateStaffData, setUpdateStaffData } =
    useContext(StaffProfileContext)
  const { toggleTab, handleSubmit } = useContext(StaffProfileContext)
  const [selectedNextOfKin, setSelectedNextOfKin] = useState(null) //Get data for deleting
  const [updateNextOfKinData, setUpdateNextOfKinData] = useState(null) //Get data for editing

  //alert('zzzz'+updateStaffData?.uid)
  const { loading, error, data } = useQuery(GET_NEXT_OF_KIN_BY_STAFF, {
    client: uaaGraphQLClient,
    variables: {
      userUid: updateStaffData?.uid,
    },
    fetchPolicy: "cache-first",
  })

  const handleNext = () => {
    toggleTab(5) // Move to the previous tab
  }

  const handlePrevious = () => {
    toggleTab(3) // Move to the previous tab
  }

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const [deleteNextOfKin] = useMutation(DELETE_STAFF_NEXT_OF_KIN, {
    client: uaaGraphQLClient,
    variables: { uid: selectedNextOfKin?.uid },
    refetchQueries: [
      {
        query: GET_NEXT_OF_KIN_BY_STAFF,
        variables: {
          userUid: updateStaffData?.uid,
        },
      },
    ],
  })

  const handleDeleteNextOfKin = (nexOfKin) => {
    setSelectedNextOfKin(nexOfKin)
    showDeleteWarning(() => {
      performNextOfKinDeletion()
    })
  }

  const performNextOfKinDeletion = async () => {
    try {
      const response = await deleteNextOfKin()
      showToast(response.data.removeUserNextOfKin.message,
                response.data.removeUserNextOfKin.code === 8000 ? "success" : "error")
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error) {
    return showToast(error)
  }

  const result = data?.getUserNextOfKins.data

  return (
    <NextOfKinContext.Provider
      value={{ updateNextOfKinData, setUpdateNextOfKinData }}
    >
      <div>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-12 col-sm-12 col-md-12 pull-right">
                      <NextOfKinModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && (
                  <div className="table-rep-plugin">
                    <div
                      className="mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Full Name</Th>
                            <Th data-priority="1">Kinship</Th>
                            <Th data-priority="1">Place of Domicile</Th>
                            <Th data-priority="1">Phone</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result?.map((NextOfKinObject, index) => (
                            <Tr key={NextOfKinObject?.uid}>
                              <Td>{index + 1}</Td>
                              <Td>{NextOfKinObject?.fullName}</Td>
                              <Td>{NextOfKinObject?.fullName}</Td>
                              <Td>{NextOfKinObject?.placeOfDomicile}</Td>
                              <Td>{NextOfKinObject?.phoneNumber}</Td>
                              <Td>{NextOfKinObject?.email}</Td>
                              <Td>
                                <DropDownActionHelper 
                                  data={NextOfKinObject}
                                  onUpdate={setUpdateNextOfKinData}
                                  onDelete={handleDeleteNextOfKin}
                                />
                                
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>
                <div className="wizard">
                  <div className="actions">
                    <ul>
                      <li className="previous" color="info">
                        <Link to="#" onClick={handlePrevious}>
                          Previous
                        </Link>
                      </li>
                      <li className="next">
                        <Link to="#" onClick={handleNext}>
                          Submit & Continue..
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </NextOfKinContext.Provider>
  )
}

export default NextOfKinList
