import React from "react";
import { Route, Routes } from "react-router-dom"
import Pages404 from "../../Modules/Utility/pages-404"
import StudentsList from "../../Modules/Students/Views/StudentsList";
import SelfRegistration from "../../Modules/Students/Views/SelfRegistration";
import MyProfile from "../../Modules/Students/Views/Profile/MyProfile";
import StudentProfileUpdate from "../../Modules/Students/Views/Profile/StudentProfileUpdate";
import StudentPaymentRoutes from "../../Modules/Students/Routes/StudentPaymentRoutes";
import StudentRegistrationRoutes from "../../Modules/Students/Routes/StudentRegistrationRoutes";
import StudentApplicationRoutes from "../../Modules/Students/Routes/StudentApplicationRoutes";
import StudentExamResults from "../../Modules/Students/Views/Examinations/StudentExamResults";
import SingleStudentExamResults from "../../Modules/Students/Views/Examinations/SingleStudentExamResults";
import StudentCoursework from "../../Modules/Students/Views/Examinations/StudentCoursework";
import ViewStudent from "../../Modules/Students/Views/ViewStudent";
//update student information for testing
import StudentUpdateInformation from "../../Modules/Students/Views/StudentUpdateInformation";
import UpdateStudentDetails from "../../Modules/Students/Views/UpdateStudentDetails";
import DissertationRoutes from "../../Modules/Students/Views/Dissertation/Routes/DissertationRoutes";
import StudentMoodleCourses from "../../Modules/Students/Views/Registration/StudentMoodleCourses";
import StudentEmailRecovery from "../../Modules/Students/Views/Recovery/StudentEmailRecovery";
import StudentAccountSwitch from "../../Modules/Students/Views/Recovery/StudentAccountSwitch";
import StudentCancelProgramChange from "../../Modules/Students/Views/Recovery/StudentCancelProgramChange";
import ExamTicket from "../../Modules/Students/Views/Examinations/ExamTicket";
import PartialTranscript from "Modules/Transcripts/Views/PartialTranscript";
import TeachingCourseAssessmentParent from "Modules/TeachingAssessment/Views/Questions/TeachingCourseAssessmentParent";
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard";
import TeachingAssessmentRoutes from "../../Modules/TeachingAssessment/Routes/TeachingAssessmentRoutes";
import NhifRoutes from "../../Modules/Nhif/Routes/NhifRoutes";
import SignaturePadRoutes from "../../Modules/SignaturePad/Routes/SignaturePadRoutes";


const StudentsRoutes = () => {
  // console.log(history)
  return (
    <Routes>
        <Route path= "/dashboard" element={<AsimsDashboard />} />
        <Route path="/moodle-courses" element={<StudentMoodleCourses />} />
        <Route index={true} path="/registration/*" element={<StudentRegistrationRoutes />} />
        <Route index={true} path="/applications/*" element={<StudentApplicationRoutes />} />
        <Route index={true} path="/teaching_assessment/*" element={<TeachingAssessmentRoutes />} />
        <Route index={true} path="/nhif/*" element={<NhifRoutes />} />
        <Route index={true} path="/dissertation/*" element={<DissertationRoutes />} />
        <Route index={true} path="/update-profile" element={<SelfRegistration />} />
        <Route index={true} path="/exam_ticket" element={<ExamTicket />} />
        <Route index={true} path="/profile" element={<StudentUpdateInformation />} />
        <Route index={true} path="/assessment" element={<TeachingCourseAssessmentParent/>}/>
        <Route index={true} path="/coursework" element={<StudentCoursework/>} />
        <Route index={true} path="/examinations" element={<StudentExamResults/>}/>
          <Route index={true} path="/signaturepad/*" element={<SignaturePadRoutes />} />
        <Route path="*" element={<Pages404 />} />
    </Routes>
  );
};
export default StudentsRoutes;
