import React from "react"

import { Card, CardBody, Col, Row } from "reactstrap"
import { Link, useLocation } from "react-router-dom"

import { GET_STUDENT } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import {
  uaaGraphQLClient
} from "Modules/Authentication/ApolloClient"
import { jwtDecode } from "jwt-decode"
import PlaceHolderLoader from "../../helpers/PlaceHolderLoader"
const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
const userData = accessToken ? jwtDecode(accessToken) : null
const userAuthorities = userData?.authorities || []
const hasAuthority = authority => userAuthorities.includes(authority)

const ApplicantDashboard = () => {
  const location = useLocation()
  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    skip:
      !JSON.parse(localStorage.getItem("authUser"))?.data?.username ||
      location?.pathname !== "/applicant/profile-information",
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username
    },
    fetchPolicy: "network-only"
  })

  const student = userDetails?.getUserByUsername?.data


  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  return (
    <div className="container">
      <h1>Dashboard</h1>
      {location?.pathname === "/applicant/profile-information" && (
        <Card>
          <CardBody>
            <Row>
              {userLoading && (
                <Col md={12}>
                  <PlaceHolderLoader rows={4} type="table" columSize={12} />
                </Col>
              )}
              {
                !userLoading && (
                  <Col md={12}>
                    <h3>
                      Welcome:{" "}
                      {student?.firstName +
                        " " +
                        student?.middleName +
                        " " +
                        student?.lastName}
                    </h3>
                    <br />
                    <Link
                      to="/teaching-assessment/courses"
                      className="btn btn-sm btn-primary mt-4"
                    >
                      <span>Click here to start course assessment</span>
                    </Link>
                  </Col>
                )
              }
            </Row>
          </CardBody>
        </Card>
      )
      }
    </div>
  )
}
export default ApplicantDashboard
