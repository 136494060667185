import React, { useContext, useState } from "react"
import {Modal, Form, Input, FormFeedback, Button, Label, Col, Row} from "reactstrap"
import * as Yup from "yup"
import {useFormik} from "formik";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import {useMutation} from "@apollo/client";
import {CREATE_APPLICANT_CERTIFICATE} from "../../../Mutations/ApplicantMutation";
import showToast from "../../../../../helpers/ToastHelper";
import {onlineApplicationGraphQLClient} from "../../../../Authentication/ApolloClient";
import {GET_APPLICANT_CERTIFICATE_RESULT} from "../../../Queries/ApplicantQueries";
import PropTypes from "prop-types";


const ResultTypeModel = (props) => {
    const {user} = props
    const [modalOpen, setModalOpen] = useState(false)
    const [create] = useMutation(CREATE_APPLICANT_CERTIFICATE)
    const [formRows, setFormRows] = useState([{ id: 1 }]);
    const [rows1, setrows1] = useState([{ id: 1 }]);

    const onAddFormRow = () => {
        const modifiedRows = [...formRows];
        modifiedRows.push({ id: modifiedRows.length + 1 });
        setFormRows(modifiedRows);
    };

    const onDeleteFormRow = id => {
        // console.log(id)
        if (id !== 0) {
            const indexToRemove = id - 1
            let modifiedRows = [...formRows];
            modifiedRows = modifiedRows.filter(x => x["id"] !== id);
            setFormRows(modifiedRows);
            const newSubjects = validation.values.results.filter((_, index) => index !== indexToRemove);
            // console.log(newSubjects)
            validation.setFieldValue('results', newSubjects);
        }
    };
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            indexNumber: "",
            certificateTypeCode: "",
            institutionName: "",
            institutionProgram: "",
            completionYear: "",
            certificateAttachment: "",
            results: [{ subjectName: '', grade: '', resultStatus: '',subjectCode:'INTERNATIONAL' }]
        },
        validationSchema: Yup.object().shape({
            indexNumber: Yup.string().required("Index Number is required"),
            results: Yup.array().required('Required'),
            certificateTypeCode: Yup.string().required("Certificate Type is required"),
            institutionName: Yup.string().required("Institution Name is required"),
            institutionProgram: Yup.string().required("Institution Program is required"),
            completionYear: Yup.number().required("Completion Year is required"),
            certificateAttachment: Yup.string().required("Certificate Attachment is required"),
        }),

        onSubmit: (values, {setSubmitting, resetForm }) => {
            values.certificateAttachment = ""
            create({
                client: onlineApplicationGraphQLClient,
                variables: {
                    inputs: { ...values },
                },
                onCompleted: ({ registerApplicantCertificateResults }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (registerApplicantCertificateResults.code === 8000) {
                        setModalOpen(false)
                        setSubmitting(false)
                        validation.resetForm()
                    } else {
                        setModalOpen(true)
                        setSubmitting(false)
                    }
                    showToast(
                        registerApplicantCertificateResults.message,
                        registerApplicantCertificateResults.code === 8000 ? "success" : "error"
                    )
                },
                refetchQueries: [
                    {
                        variables: {
                            userUid: user?.uid
                        },
                        query: GET_APPLICANT_CERTIFICATE_RESULT,
                    },
                ],
                onError: error => {
                    setSubmitting(false)
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
        },
    })

    // console.log(validation.values.results)
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Applicant Certificate
            </Button>
            <Modal
                size="lg"
                isOpen={modalOpen}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        Add Applicant Certificate
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                    encType="multipart/form-data" >
                    <div className="modal-body">
                        <div className="mb-3">
                            <Row>
                                <Col lg={4} md={4}>
                                    <div className="mb-3">
                                        <Label>Result Type: <strong className="text-danger">*</strong></Label>
                                        <select
                                            id="certificateTypeCode"
                                            name="certificateTypeCode"
                                            className="form-control"
                                            value={validation.values.certificateTypeCode}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        >
                                            <option value="" label="-- Select --"/>
                                            <option value="junior" label="Junior"/>
                                            <option value="senior" label="Senior"/>
                                        </select>
                                        {validation.touched.certificateTypeCode && validation.errors.certificateTypeCode ? (
                                            <small
                                                className="text-danger"><b>{validation.errors.certificateTypeCode}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className="mb-3">
                                        <Label>Completion Year:: <strong className="text-danger">*</strong></Label>
                                        <Input
                                            type="number"
                                            name="completionYear"
                                            className="form-control"
                                            id="completionYear"
                                            min={1999}
                                            placeholder="Enter Completion Year"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.completionYear || ""}
                                            invalid={
                                                !!(validation.touched.completionYear && validation.errors.completionYear)
                                            }
                                        />
                                        {validation.touched.completionYear && validation.errors.completionYear ? (
                                            <small
                                                className="text-danger"><b>{validation.errors.completionYear}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className="mb-3">
                                        <Label>Index Number: <strong className="text-danger">*</strong></Label>
                                        <Input
                                            type="text"
                                            name="indexNumber"
                                            className="form-control"
                                            id="indexNumber"
                                            placeholder="Enter Index Number"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.indexNumber || ""}
                                            invalid={
                                                !!(validation.touched.indexNumber && validation.errors.indexNumber)
                                            }
                                        />
                                        {validation.touched.indexNumber && validation.errors.indexNumber ? (
                                            <small
                                                className="text-danger"><b>{validation.errors.indexNumber}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <Label>Institution Name: <strong className="text-danger">*</strong></Label>

                                        <Input
                                            type="text"
                                            name="institutionName"
                                            className="form-control"
                                            id="institutionName"
                                            placeholder="Enter Institution Name"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.institutionName || ""}
                                            invalid={
                                                !!(validation.touched.institutionName && validation.errors.institutionName)
                                            }
                                        />
                                        {validation.touched.institutionName && validation.errors.institutionName ? (
                                            <small
                                                className="text-danger"><b>{validation.errors.institutionName}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className="mb-3">
                                        <Label>Institution Program: <strong className="text-danger">*</strong></Label>

                                        <Input
                                            type="text"
                                            name="institutionProgram"
                                            className="form-control"
                                            id="institutionProgram"
                                            placeholder="Enter Institution Program"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.institutionProgram || ""}
                                            invalid={
                                                !!(validation.touched.institutionProgram && validation.errors.institutionProgram)
                                            }
                                        />
                                        {validation.touched.institutionProgram && validation.errors.institutionProgram ? (
                                            <small className="text-danger"><b>{validation.errors.institutionProgram}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <div className="mb-3">
                                        <Label>Upload Certificate: <strong className="text-danger">*</strong></Label>

                                        <input
                                            id="certificateAttachment"
                                            className="form-control"
                                            name="certificateAttachment"
                                            // type="number"
                                            accept="application/pdf"
                                            type="file"
                                            onBlur={validation.handleBlur}
                                            onChange={(e) => {
                                                if (e.target.files && e.target.files.length > 0) {
                                                    validation.setFieldValue("certificateAttachment", e.target.files[0])
                                                }
                                            }}
                                        />
                                        {validation.touched.certificateAttachment && validation.errors.certificateAttachment ? (
                                            <small
                                                className="text-danger"><b>{validation.errors.certificateAttachment}</b></small>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>

                            {(formRows || []).map((formRow, key) => (
                                <Row key={key}>
                                    <Col lg={4} className="mb-3">
                                        <Label>Subject: <strong className="text-danger">*</strong></Label>
                                        <input
                                            className="form-control"
                                            list={`datalistOptions${key}`}
                                            id={`exampleDataList${key}`}
                                            name={`results.${key}.subjectName`}
                                            placeholder="Type to search..."
                                            // value={validation.values.results[key].subjectName}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}

                                               // value={validation.values.subject}
                                               // onChange={validation.handleChange}
                                               // onBlur={validation.handleBlur}
                                        />
                                        <datalist id={`datalistOptions${key}`} >
                                            <option value="Basic Mathematics"/>
                                            <option value="Geography"/>
                                            <option value="Chemistry"/>
                                            <option value="Histroy"/>
                                            <option value="Pysics"/>
                                        </datalist>
                                    </Col>

                                    <input
                                        className="form-control"
                                        type="hidden"
                                        id={`subjectCode${key}`}
                                        name={`results.${key}.subjectCode`}
                                        value={"INTERNATIONAL"}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur} />

                                    <Col lg={3} className="mb-3">
                                        <Label>Grade: <strong className="text-danger">*</strong></Label>
                                        <select
                                            id={`grade${key}`}
                                            name={`results.${key}.grade`}
                                            className="form-control"
                                            // value={validation.values.results[key].grade}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        >
                                            <option value="" label="-- Select --"/>
                                            <option value="A" label="A"/>
                                            <option value="B" label="B"/>
                                        </select>
                                    </Col>

                                    <Col lg={3} className="mb-3">
                                        <Label>General Remark: <strong className="text-danger">*</strong></Label>
                                        <select
                                            id={`resultStatus${key}`}
                                            className="form-control"
                                            name={`results.${key}.resultStatus`}
                                            // value={validation.values.results[key].grade}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                        >
                                            <option value="" label="-- Select --"/>
                                            <option value="PASS" label="PASS"/>
                                            <option value="FAIL" label="FAIL"/>
                                        </select>
                                    </Col>

                                    <Col lg={2} className="align-self-center">
                                        <div className="d-grid">
                                            <button
                                                type="button"
                                                className="btn btn-soft-danger"
                                                onClick={() => onDeleteFormRow(key)}
                                            >
                                                <i className="mdi mdi-delete-outline mdi-18px" />
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            ))}

                            <input
                                type="button"
                                className="btn btn-success mt-3 mt-lg-0"
                                value="Add"
                                onClick={() => {
                                    validation.setFieldValue(`results.${formRows.length}.subjectCode`, 'INTERNATIONAL')
                                    // console.log(formRows.length)
                                    onAddFormRow()
                                }}
                            />
                        </div>


                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                validation.resetForm()
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation}/>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

ResultTypeModel.propTypes = {
    user: PropTypes.any.isRequired
}

export default ResultTypeModel
