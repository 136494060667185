
import { gql } from "@apollo/client"



const SAVE_SIGNATURE = gql `
  mutation UploadSignature($uploadInput: SignatureUploadInput!){
    uploadSignature(uploadInput: $uploadInput){
      message
      code
      status
    }
  }
`

export { SAVE_SIGNATURE }
