import React, {createContext, useEffect, useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Button, Card, CardBody, CardSubtitle, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import RegisterCourses from "./RegisterCourses";
import ShowRegisterdCourses from "./ShowRegisterdCourses";
import {useQuery} from "@apollo/client";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {limit} from "../../../../helpers/UrlHelper";
import {LogedInStudent} from "../../LogedInStudent";
import showToast from "../../../../helpers/ToastHelper";
import {
    GET_STUDENT_COURSE_TO_REGISTER
} from "../../Queries/Registration/StudentSemesterRegistrationQuery";
const breadcrumbItem =  [
    {
        title: 'Examination Registration',
        path: '',
        isActive: true,
    },
]

export const StudentSemesterRegistrationContext = createContext();
const StudentSemesterRegistration = () => {
    const userData = JSON.parse(localStorage.getItem("authUser"))
    const dataInfo = userData?.data
    const study_year = dataInfo?.student?.studyYear === 0 ? dataInfo?.student?.studyYear+1:dataInfo?.student?.studyYear

    // const { loading:acYearLoading, error:ecYearError, data } = useQuery(GET_ACADEMICYEARS, {
    //     client:registrationGraphQLClient,
    //     variables: {
    //         pagination:pagination,
    //     }
    // })
    const { loading:loading, error:error, data:fetchCourses } = useQuery(GET_STUDENT_COURSE_TO_REGISTER, {
        client:registrationGraphQLClient,
        skip: !dataInfo?.student?.uid || !dataInfo?.student?.programmeUid,
        fetchPolicy: "no-cache",
        variables: {
            input: {
                studyYear: study_year,
                semester:2,
                // registrationNumber:student?.registrationNumber,
                studentUid:dataInfo?.student?.uid,
                programUid:dataInfo?.student?.programmeUid
            },
        },
    })


    if(loading) return <PlaceHolderLoader columSize={12} buttonShown={true} rows={5} />

    let courses = fetchCourses?.getStudentCourseToRegister?.data?.courseToRegister
    let registered = fetchCourses?.getStudentCourseToRegister?.data?.courseRegistered

    return (
            <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Course Registration">
                <RegisterCourses loginData={dataInfo} courses={courses} courseRegistered={registered} />
        </LayoutHelper>
    );
}

export default StudentSemesterRegistration;