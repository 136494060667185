// export default StateList;
import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_CAMPUS } from "Modules/Campus/Queries/CampusQueries"
import { DELETE_CAMPUS } from "Modules/Campus/Mutations/CampusMutations"
import StateModal from "Modules/State/Views/StateModal"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import { GET_STATE } from "../Queries/StateQueries"
import { DELETE_STATE } from "../Mutation/StateMutation"

const breadcrumbItem = [
  {
    title: "State List",
    path: "",
    isActive: true,
  },
]

export const StateContext = createContext() //Create data to transfer to child modal

const StateList = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedState, setSelectedState] = useState(null) //Get data for deleting
  const [updateStateData, setUpdateStateData] = useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_STATE, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const [
    searchStates,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_CAMPUS, {
    client: uaaGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteState] = useMutation(DELETE_STATE, {
    client: registrationGraphQLClient,
    variables: { uid: selectedState?.uid },
    refetchQueries: [
      {
        query: GET_STATE,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteState = delState => {
    setSelectedState(delState)
    showDeleteWarning(() => {
      performStateDeletion()
    })
  }

  const performStateDeletion = async () => {
    try {
      const response = await deleteState()
      showToast(
        response.data.removeState.message,
        response.data.removeState.code === 8000 ? "success" : "error"
      )
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchStates({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) {
      //refetch data after clearing search value
      clearSearch()
      fetchData() //update hook state
    }
  }

  const clearSearch = () => {
    //Function to clear search from formik
    formikSearch.setFieldValue("search", "")
  }
  const States = data?.getStates.data?.items
  const searchedStates = searchData?.getStates?.data?.items
  const result = searchedStates ? searchedStates : States
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getStates?.data?.totalCount || searchData?.getStates?.data?.items || 0

  return (
    <StateContext.Provider value={{ updateStateData, setUpdateStateData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="State List">
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <StateModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Label</Th>
                            <Th>Description</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            .slice(startIndex, endIndex)
                            .map((State, index) => (
                              <Tr key={State?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{State?.label}</Td>
                                <Td>{State?.description}</Td>
                                <Td>
                                <DropDownActionHelper
                                    data={State}
                                    onUpdate={setUpdateStateData}
                                    onDelete={handleDeleteState}
                                  />
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </StateContext.Provider>
  )
}

export default StateList
