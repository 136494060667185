import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import {useMutation, useQuery} from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
    paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { GradePointContext } from "./GradePointList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {onlineApplicationGraphQLClient} from "Modules/Authentication/ApolloClient"
import Select from "react-select";
import {CREATE_GRADE_POINT} from "../Mutations/GradePointMutations";
import {GET_GRADE_POINTS, GET_QUALIFICATION_METHODS} from "../Queries/GradePointQueries";

const GradePointModal = () => {
    const { updateGradePointData, setUpdateGradePointData } = useContext(GradePointContext)
    const [GradePoint] = useMutation(CREATE_GRADE_POINT)
    const [modalOpen, setModalOpen] = useState(false);


    const { loading, error, data } = useQuery(GET_QUALIFICATION_METHODS, {
        client:onlineApplicationGraphQLClient,
        skip: !modalOpen,
        variables: {},
    });

    const qualificationMethods = data?.getQualificationMethod?.data;


    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            grade: updateGradePointData ? updateGradePointData.grade : "",
            gradePoint: updateGradePointData ? updateGradePointData.gradePoint : "",
            qualificationMethodUid: updateGradePointData ? updateGradePointData.qualificationMethod?.uid : "",
        },

        validationSchema: Yup.object().shape({
            grade: Yup.string().required("Grade name is required"),
            gradePoint: Yup.string().required("Grade Point is required"),
            qualificationMethodUid: Yup.string().required("Grade Point short name is required"),
        }),

        onSubmit: (values, { resetForm, setSubmitting }) => {
            GradePoint({
                client: onlineApplicationGraphQLClient,
                variables: {
                    inputs: {...values, uid: updateGradePointData?.uid},
                },
                refetchQueries: [
                    {
                        query: GET_GRADE_POINTS,
                        variables: {
                            pagination: paginationHelper,
                        },
                    },
                ],
                onCompleted: ({registerGradePoint}) => {
                    if (registerGradePoint.code === 8000) {
                        validation.resetForm()
                        setModalOpen(false)
                        setUpdateGradePointData(false) // Close the modal
                    } else {
                        setModalOpen(true)
                        setUpdateGradePointData(false)
                        resetForm(false)
                    }
                },
                onError: error => {
                    // Handle errors
                    setSubmitting(false);
                    console.error("Mutation error:", error)
                },
            }).then(r => console.log(r))
        },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Grade
            </Button>
            <Modal
                isOpen={modalOpen || !!updateGradePointData}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!updateGradePointData ? "Edit Grade" : "Add New Grade"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="name" className="col-form-label">
                                Grade Name:
                            </label>
                            <Input
                                type="text"
                                name="grade"
                                className="form-control"
                                id="firstname"
                                placeholder="Enter Grade Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.grade || ""}
                                invalid={
                                    !!(validation.touched.grade && validation.errors.grade)
                                }
                            />
                            {validation.touched.grade && validation.errors.grade ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.grade}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="code" className="col-form-label">
                                Grade Point:
                            </label>
                            <Input
                                type="number"
                                name="gradePoint"
                                className="form-control"
                                id="firstname"
                                placeholder="Grade point"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gradePoint || ""}
                                invalid={
                                    !!(validation.touched.gradePoint && validation.errors.gradePoint)
                                }
                            />
                            {validation.touched.gradePoint && validation.errors.gradePoint ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.gradePoint}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="status" className="col-form-label">
                                Qualification Method
                            </label>
                            <Select
                                onChange={e => {
                                    validation.setFieldValue("qualificationMethodUid", e.value)
                                }}
                                options={qualificationMethods?.map(year => ({
                                    value: year.uid,
                                    label: year.name,
                                }))}
                                className="select2-selection"
                                styles={{
                                    menu: base => ({
                                        ...base,
                                        position: "absolute",
                                        zIndex: 9999
                                    })
                                }}
                                defaultValue={validation.values.qualificationMethodUid}
                                defaultInputValue={updateGradePointData?.qualificationMethod?.name}
                            />
                            <Input
                                name="qualificationMethodUid"
                                placeholder="Select qualfification year"
                                type="hidden"
                                rows={2}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.qualificationMethodUid || ""}
                                invalid={
                                    !!(validation.touched.qualificationMethodUid && validation.errors.qualificationMethodUid)
                                }
                            />
                            {validation.touched.qualificationMethodUid && validation.errors.qualificationMethodUid ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.qualificationMethodUid}
                                </FormFeedback>
                            ) : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setUpdateGradePointData(false)
                                validation.resetForm()
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default GradePointModal
