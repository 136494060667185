import { gql } from "@apollo/client"

const CREATE_PERMISSIONS = gql`
  mutation RegisterPermissions($inputs: [PermissionInput!]!) {
    registerPermissions(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_PERMISSIONS = gql`
  mutation RemovePermission($uid: String!) {
    removePermission(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_PERMISSIONS, DELETE_PERMISSIONS }
