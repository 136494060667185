import { gql } from "@apollo/client"

const REGISTER_APPLICANT = gql`
    mutation RegisterApplicant($inputs: ApplicantInput!) {
        registerApplicant(inputs: $inputs) {
            status
            code
            message
            data {
                email
                firstName
                middleName
                lastName
                phoneNumber
                gender
                tanzanian
                username
                password
            }
        }
    }
`

const PUSH_REGISTERED_APPLICANT = gql`
    mutation PushRegisteredApplicant($inputs: PushApplicantInput!){
        pushRegisteredApplicant(inputs: $inputs){
            status
            code
            message
        }
    }
`

export { REGISTER_APPLICANT, PUSH_REGISTERED_APPLICANT }