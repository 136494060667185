import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"

import { SeminarTypeContext } from "./SeminarTypeList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import {GET_SEMINAR_TYPE} from "../../../Students/Views/Dissertation/Queries/StudentDissertationQueries";
import {CREATE_SEMINAR_TYPE} from "../Mutations/SeminarTypeMutations";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";


const SeminarTypeModal = () => {

  const { updateSeminarTypeData, setupdateSeminarTypeData } =
    useContext(SeminarTypeContext)
  const [create] = useMutation(CREATE_SEMINAR_TYPE)
  const [modalOpen, setModalOpen] = useState(false)




  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateSeminarTypeData ? updateSeminarTypeData.name : "",
      rank: updateSeminarTypeData ? updateSeminarTypeData.rank : "",
      description: updateSeminarTypeData ? updateSeminarTypeData.description : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Seminar Type name is required"),
      rank: Yup.string().required("Seminar Rank name is required"),
      description: Yup.string().required("Seminar Description name is required"),

    }),

    onSubmit: (values, {setSubmitting,resetForm}) => {
      create({
        client:registrationGraphQLClient,
        variables: {
          inputs: { ...values, uid: updateSeminarTypeData?.uid },
        },
        refetchQueries: [
          {
            client: registrationGraphQLClient,
            query: GET_SEMINAR_TYPE,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        skip: !modalOpen,
        onCompleted: ({ registerSeminarType }) => {
          //updateFunction(client.cache, { data: { registerStatuss } });
          if (registerSeminarType.code === 8000) {
            setModalOpen(false)
            setupdateSeminarTypeData(false) // Close the modal
            validation.resetForm()
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }

          // console.log('sfasfasfsa')
          showToast(
              registerSeminarType.message,
              registerSeminarType.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Seminar Type
      </Button>
      <Modal
        isOpen={modalOpen || !!updateSeminarTypeData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateSeminarTypeData
              ? "Edit Seminar Type"
              : "Add New Seminar Type"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setupdateSeminarTypeData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Seminar Type Name
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Seminar Type Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || updateSeminarTypeData?.name || ""
                }
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Seminar Type Description
              </label>
              <Input
                  type="textarea"
                  name="description"
                  className="form-control"
                  id="description"
                  rows={5}
                  placeholder="Enter Seminar Type Description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                      validation.values.description || updateSeminarTypeData?.description || ""
                  }
                  invalid={
                    validation.touched.description && validation.errors.description
                        ? true
                        : false
                  }
              />
              {validation.touched.description && validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Seminar Type Rank
              </label>
              <Input
                  type="number"
                  name="rank"
                  className="form-control"
                  id="rank"
                  placeholder="Enter Seminar Type Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                      validation.values.rank || updateSeminarTypeData?.rank || ""
                  }
                  invalid={
                    validation.touched.rank && validation.errors.rank
                        ? true
                        : false
                  }
              />
              {validation.touched.rank && validation.errors.rank ? (
                  <FormFeedback type="invalid">
                    {validation.errors.rank}
                  </FormFeedback>
              ) : null}
            </div>



          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setupdateSeminarTypeData(false)
                validation.resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default SeminarTypeModal
