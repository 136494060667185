//REGISTE
import { gql } from '@apollo/client';

const GET_ADMISSION_YEARS = gql`
  query GetAdmissionYear($pagination: PaginationInput!) {
    getAdmissionYears(pagination: $pagination){
      status
      code
      message
      data {
        items{
        uid
        name
        status
        startDate
        endDate
        numberOfChoices
      }
      totalCount
      }
    }
  }
`

const GET_CURRENT_ADMISSION_YEAR = gql`
    query getActiveAdmissionYear {
        getActiveAdmissionYear {
            code
            status
            message
            data {
                uid
                name
                status
                startDate
                endDate
                numberOfChoices
            }
        }
    }
`

export { GET_CURRENT_ADMISSION_YEAR }