import { gql } from "@apollo/client";

const GENERATE_PARTIAL_TRANSCRIPT_XLS = gql`
  query GeneratePartialTranscript($registrationNumber: String!) {
    generatePartialTranscript(registrationNumber: $registrationNumber){
        status
        code
        message
        data{
          base64Data
          fileName
        }
    }
  }
`



export { GENERATE_PARTIAL_TRANSCRIPT_XLS }
