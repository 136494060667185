import { gql } from "@apollo/client"

const CREATE_BYLAW = gql`
  mutation RegisterByLaw($inputs: ByLawInput!) {
    registerByLaw(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          uid
          name
          code
          status
          startDate
          endDate
       }
      }
    }
  }
`

const DELETE_ByLAW = gql`
  mutation RemoveByLaw($uid: String!) {
    removeByLaw(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_BYLAW, DELETE_ByLAW }
