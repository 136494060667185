import React from "react"
import { Route, Routes } from "react-router-dom"
import Pages404 from "../../Utility/pages-404"
import AsimsDashboard from "../views/asimsDashboard"
import EmploymentHistory from "../EmployementHistory/views/EmploymentHistory"
import Awards from "../Awards/views/Awards"
import AcademicQualifications from "../AcademicQualifications/views/AcademicQualifications"
import SpecialAppointments from "../SpecialAppointments/views/SpecialAppointments"
import Outreach from "../Outreach/views/Outreach"
import Consultancies from "../Consultancies/views/Consultancies"
import MediaCoverage from "../MediaCoverage/views/MediaCoverage"
import Publications from "../Publications/views/publications"
import AcademicEvents from "../Events/views/AcademicEvents"
import ResearchGrants from "../ResearchGrants/views/ResearchGrants"
import ProfessionalAndTechnicalSkills from "../ProfessionalAndTechnicalSkills/views/ProfessionalAndTechnicalSkills"
import OtherExperience from "../OtherExperience/views/OtherExperience"
import ProfessionalBodyMembership from "../ProfessionalBodyMembership/views/ProfessionalBodyMembership"
import Reviewer from "../ReviewerEditorRoles/views/Reviewer"

const AsimsRoutes = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<AsimsDashboard />} />
      <Route path="/employement-history" element={<EmploymentHistory />} />
      <Route path="/awards" element={<Awards />} />
      <Route path="/appointments" element={<SpecialAppointments />} />
      <Route path="/outreach-activities" element={<Outreach />} />
      <Route path="/consultancies" element={<Consultancies />} />
      <Route path="/media-coverage" element={<MediaCoverage />} />
      <Route path="/publications" element={<Publications />} />
      <Route path="/reviewer-roles" element={<Reviewer />} />
      <Route path="/academic-events" element={<AcademicEvents />} />
      <Route path="/research-grants" element={<ResearchGrants />} />
      <Route path="/other-experiences" element={<OtherExperience />} />
      <Route
        path="/professional-memberships"
        element={<ProfessionalBodyMembership />}
      />
      <Route
        path="/professional-skills"
        element={<ProfessionalAndTechnicalSkills />}
      />
      <Route
        path="/academic-qualifications"
        element={<AcademicQualifications />}
      />
      <Route path="*" element={<Pages404 />} />
    </Routes>
  )
}

export default AsimsRoutes
