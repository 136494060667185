import React, { useContext, useRef, useState } from "react"
import { Button, Form, FormFeedback, Input, Modal } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { ADD_UPDATE_PROFESSIONAL_MEMBERSHIP } from "../Mutations/ProfessionalBodyMembershipMutations"
import { GET_PROFESSIONAL_MEMBERSHIP } from "../Queries/ProfessionalBodyMembershipQueries"
import { ProfessionalBodyMembershipContext } from "./ProfessionalBodyMembership"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { asimsGraphQLClient } from "Modules/Authentication/ApolloClient"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

const ProfessionalBodyMembershipModal = () => {
  const {
    updateProfessionalBodyMembershipData,
    setUpdateProfessionalBodyMembershipData,
    staffUid,
  } = useContext(ProfessionalBodyMembershipContext)

  const [ProfessionalBodyMembership] = useMutation(
    ADD_UPDATE_PROFESSIONAL_MEMBERSHIP,
  )
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_PROFESSIONAL_MEMBERSHIP
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      organizationName: updateProfessionalBodyMembershipData
        ? updateProfessionalBodyMembershipData.organizationName
        : "",
      membershipNumber: updateProfessionalBodyMembershipData
        ? updateProfessionalBodyMembershipData.membershipNumber
        : "",
      staffUid: updateProfessionalBodyMembershipData
        ? updateProfessionalBodyMembershipData.staffUid
        : staffUid,
      uid: updateProfessionalBodyMembershipData
        ? updateProfessionalBodyMembershipData.uid
        : "",
      startDate: updateProfessionalBodyMembershipData
        ? moment(updateProfessionalBodyMembershipData.startDate).format(
            "YYYY-MM-DD",
          )
        : "",
      endDate: updateProfessionalBodyMembershipData
        ? moment(updateProfessionalBodyMembershipData.endDate).format(
            "YYYY-MM-DD",
          )
        : "",
    },

    validationSchema: Yup.object().shape({
      organizationName: Yup.string().required("Organization Name is Required"),
      membershipNumber: Yup.string().required("Membership Number is required"),
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date().required("End Date is required"),
    }),

    onSubmit: (values, { setSubmitting }) => {
      ProfessionalBodyMembership({
        client: asimsGraphQLClient,
        variables: {
          input: {
            ...values,
            uid: updateProfessionalBodyMembershipData?.uid,
            staffUid: staffUid,
            startDate: new Date(values.startDate).toLocaleDateString("en-US"),
            endDate: new Date(values.endDate).toLocaleDateString("en-US"),
          },
        },
        refetchQueries: [
          {
            query: GET_PROFESSIONAL_MEMBERSHIP,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerProfessionalBodyMembership }) => {
          console.log(
            "Register Professional Body Membership: ",
            registerProfessionalBodyMembership,
          )
          validation.resetForm()
          setModalOpen(false)
          setUpdateProfessionalBodyMembershipData(false) // Close the modal
          if (registerProfessionalBodyMembership.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateProfessionalBodyMembershipData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
          }
          showToast(
            registerProfessionalBodyMembership.message,
            registerProfessionalBodyMembership.code === 8000
              ? "success"
              : "error",
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      }).then(r => "")
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Professional Body Membership
      </Button>

      <Modal
        isOpen={modalOpen || !!updateProfessionalBodyMembershipData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProfessionalBodyMembershipData
              ? "Edit Professional Body Membership"
              : "Add New Professional Body Membership"}
          </h5>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateProfessionalBodyMembershipData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="organizationName" className="col-form-label">
                Organization Name:
              </label>
              <Input
                type="text"
                name="organizationName"
                className="form-control"
                id="organizationName"
                placeholder="Enter Organization Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.organizationName || ""}
                invalid={
                  !!(
                    validation.touched.organizationName &&
                    validation.errors.organizationName
                  )
                }
              />
              {validation.touched.organizationName &&
              validation.errors.organizationName ? (
                <FormFeedback type="invalid">
                  {validation.errors.organizationName}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="membershipNumber" className="col-form-label">
                Membership Number:
              </label>
              <Input
                type="text"
                name="membershipNumber"
                className="form-control"
                id="membershipNumber"
                placeholder="Enter Membership Number"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.membershipNumber ||
                  updateProfessionalBodyMembershipData?.membershipNumber ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.membershipNumber &&
                    validation.errors.membershipNumber
                  )
                }
              />
              {validation.touched.membershipNumber &&
              validation.errors.membershipNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.membershipNumber}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="startDate" className="col-form-label">
                Start Date:
              </label>
              <Input
                type="date"
                name="startDate"
                className="form-control"
                id="startDate"
                placeholder="Enter Start Date"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.startDate || ""}
                invalid={
                  !!(
                    validation.touched.startDate && validation.errors.startDate
                  )
                }
              />
              {validation.touched.startDate && validation.errors.startDate ? (
                <FormFeedback type="invalid">
                  {validation.errors.startDate}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="endDate" className="col-form-label">
                End Date:
              </label>
              <Input
                type="date"
                name="endDate"
                className="form-control"
                id="endDate"
                placeholder="Enter End Date"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.endDate || ""}
                invalid={
                  !!(validation.touched.endDate && validation.errors.endDate)
                }
              />
              {validation.touched.endDate && validation.errors.endDate ? (
                <FormFeedback type="invalid">
                  {validation.errors.endDate}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateProfessionalBodyMembershipData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ProfessionalBodyMembershipModal
