import { gql } from "@apollo/client"

const GET_CONSULTANCIES = gql`
  query getStaffConsultancies($pagination: PaginationInput!) {
    getStaffConsultancies(pagination: $pagination) {
      status
      message
      code
      data {
        totalCount
        items {
          staffUid
          uid
          consultancy {
            client
            consultancyClientLetter
            consultancyRegistrationLetter
            description
            endDate
            location
            startDate
            title
            uid
          }
          roleType {
            code
            id
            name
            uid
            roleCategory {
              code
              name
              uid
            }
          }
        }
      }
    }
  }
`

export { GET_CONSULTANCIES }
