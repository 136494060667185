import React, {useState} from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, Input, FormFeedback, Form, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap"
import {useLazyQuery, useMutation} from "@apollo/client";
import {uaaGraphQLClient} from "../../../Authentication/ApolloClient";
import {GET_STUDENT_BY_USERNAME, GET_STUDENT_BY_USERNAME_LIKE} from "../../../Users/Queries/UserQuery";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {useFormik} from "formik";
import * as Yup from "yup";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";
import {
    RECOVER_USER_EMAIL,
    UPDATE_PROGRAM_CHANGE_CANCEL
} from "../../../Users/Mutations/UserMutations";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import showToast from "../../../../helpers/ToastHelper";
import {showDeleteWarning, showWaringConf} from "../../../../helpers/DeleteWaringHelper";

const breadcrumbItem = [
    {
        title: "Student Account Switch",
        path: "",
        isActive: true,
    },
]
const StudentCancelProgramChange = () => {
    const [postRecover] = useMutation(RECOVER_USER_EMAIL, {client:uaaGraphQLClient})
    const [reset,setReset] = useState(false)
    // const useS
    const [
        searchStudent,
        { loading: searchLoading, error: searchError, data: searchData },
    ] = useLazyQuery(GET_STUDENT_BY_USERNAME_LIKE, { client: uaaGraphQLClient,fetchPolicy:'no-cache' })
    let users = searchData?.getUsersByUserNameLike?.data.items
    // console.log(user)
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            username: "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required("Username is required")
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            // ToastHelper("Waiting for Backend", "info")
            searchStudent({ variables: {
                    username: values.username
                } })
                .then((response) => {
                    // Handle the response
                    setSubmitting(false)
                })
                .catch((error) => {
                    setSubmitting(false)
                    // Handle errors
                    console.error('Mutation error:', error);
                });
            // ToastHelper("Service requested successful", "success")
            //navigate('/Students/Payments')
        },

    });
    const updateValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            activateUid: "",
            deactivateUid: "",
        },
        validationSchema: Yup.object().shape({
            activateUid: Yup.string().required("Phone Number year is required"),
            deactivateUid: Yup.string().required("E-mail year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {

        },

    });

    const [finalizeShift] = useMutation(UPDATE_PROGRAM_CHANGE_CANCEL, {
        client: uaaGraphQLClient,
    })

    const confirmShift = () => {
        finalizeShift({ variables: {
                inputs: {
                    activeUid: updateValidation.values.activateUid,
                    deactivateUid: updateValidation.values.deactivateUid
                },
            }
        })
            .then((response) => {
                // console.log(response)
                // Handle the response
                showToast(response.data.updateUsersActiveStatus.message, response.data.status ? "success":"error")
                setReset(false)
                searchStudent({ variables: {
                        username: validation.values.username
                    }})
                    .then((response) => {
                        // Handle the response
                       validation.setSubmitting(false)
                    })
                    .catch((error) => {
                        validation.setSubmitting(false)
                        // Handle errors
                        console.error('Mutation error:', error);
                    });
            })
            .catch((error) => {
                setReset(false)
                // Handle errors
                // console.error('Mutation error:', error);
            });
    }
    const checkNotSame = () => {
        let deactivateUid = ""
        for (let i = 0; i < users?.length; i++) {
            const u = users[i]
             if(u.userUid !== updateValidation.values.activateUid) deactivateUid = u.userUid
        }
        return deactivateUid
    }
    const handleShift = (uid) => {
        if (uid !== "") {
            updateValidation.values.activateUid = uid
            updateValidation.values.deactivateUid = checkNotSame()
            showWaringConf(() => {
                setReset(true)
                confirmShift()
            }, 'Cancel Program Change', 'Are you sure that you need to cancel program change for this student?', 'warning', 'Switch', () => {
                // showToast("Process Terminated", "info")
            })
        } else {
            showToast("Please Select Account to cancel program change", "warning")
        }
    }
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const toggleDropdown = std => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [std.userUid]: !prevState[std.userUid],
        }))
    }

    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Cancel Program Change">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle className="mb-1">
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return null
                                    }}
                                >
                                    <Row>
                                        <Col className="col-lg-6 col-12">
                                            <label htmlFor="name" className="col-form-label">
                                                Username:
                                            </label>
                                            <Input
                                                type="text"
                                                name="username"
                                                className="form-control"
                                                id="username"
                                                placeholder="Enter Username"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.username}
                                                invalid={
                                                    validation.touched.username && validation.errors.username
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.username && validation.errors.username ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.username}
                                                </FormFeedback>
                                            ) : null}

                                        </Col>
                                        <Col className="col-lg-2 col-12">
                                            <br/><br/>
                                            <SubmitButtonHelper
                                                name="Submit"
                                                type="primary"
                                                formik={validation}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </CardSubtitle>


                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {searchLoading ? <PlaceHolderLoader type="table" columSize={12} rows={3} />
                                : <>

                                    {users &&
                                        <div className="table-rep-plugin">
                                            <div
                                                className="mb-0"
                                            >
                                                <table className="table table-striped table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>S/No</th>
                                                            <th>Username</th>
                                                            <th>Registration No</th>
                                                            <th>Full Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {users.map((user, index) => (
                                                            <tr key={user.userUid}>
                                                                <td>{index+1}</td>
                                                                <td>{user.userName}</td>
                                                                <td>{user?.registrationNumber}</td>
                                                                <td>{`${user?.firstName} ${user?.middleName !== undefined ? user?.middleName:''} ${user?.lastName}`}</td>
                                                                <td>
                                                                    {reset ? <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> ...</>
                                                                        :
                                                                        <Dropdown
                                                                            isOpen={dropdownOpen[user.userUid]}
                                                                            toggle={() => toggleDropdown(user)}
                                                                        >
                                                                            <DropdownToggle color="" caret>
                                                                                <i
                                                                                    className="fas fa-ellipsis-v"
                                                                                    style={{ color: "green" }}
                                                                                />
                                                                            </DropdownToggle>
                                                                            <DropdownMenu>
                                                                                <DropdownItem
                                                                                    onClick={() => {
                                                                                        handleShift(user.userUid)
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        className="bx bxs-user-pin"
                                                                                        style={{
                                                                                            color: "red",
                                                                                            marginRight: "10px",
                                                                                        }}
                                                                                    />
                                                                                    <span>Cancel Program Change</span>
                                                                                </DropdownItem>
                                                                            </DropdownMenu>
                                                                        </Dropdown>}
                                                                </td>
                                                            </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}

export default StudentCancelProgramChange
