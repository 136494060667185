import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { PermissionContext } from "./PermissionList"
import { CREATE_PERMISSIONS } from "../Mutations/PermissionMutation"
import { GET_PERMISSIONS } from "../Queries/PermissionQueries"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const PermissionModal = () => {
  const { updatePermissionData, setUpdatePermissionData } =
    useContext(PermissionContext)
  const [Permission] = useMutation(CREATE_PERMISSIONS)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_PERMISSIONS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updatePermissionData ? updatePermissionData.name : "",
      code: updatePermissionData ? updatePermissionData.code : "",
      description: updatePermissionData ? updatePermissionData.description : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Permission name is required"),
      code: Yup.string().required("Permission code is required"),
      description: Yup.string().required("Permission description is required"),
    }),

    onSubmit: (values, {setSubmitting}) =>  {
      Permission({
        client: uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updatePermissionData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_PERMISSIONS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerPermissions }) => {
          //updateFunction(client.cache, { data: { registerPermissions } });
          console.log("DS", registerPermissions);
          console.log("Validation", validation);
          if (registerPermissions.code === 8000) {
            validation.resetForm();
            setModalOpen(false)
            setUpdatePermissionData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false);
          }
          showToast(
            registerPermissions.message,
            registerPermissions.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Permission
      </Button>
      <Modal
        isOpen={modalOpen || !!updatePermissionData}
        scrollable={true}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updatePermissionData
              ? "Edit Permission"
              : "Add New Permission"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdatePermissionData(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || ""
                }
                invalid={
                  !!(validation.touched.name && validation.errors.name)
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Permission Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="code"
                placeholder="Enter Code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.code || ""
                }
                invalid={
                  !!(validation.touched.code && validation.errors.code)
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="description" className="col-form-label">
                Permission Description:
              </label>
              <textarea
                type="text"
                name="description"
                className="form-control"
                id="description"
                placeholder="Enter Description"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.description || ""
                }
                onInvalid={
                  !!(validation.touched.description && validation.errors.description)
                }
              />
              {validation.touched.description && validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdatePermissionData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default PermissionModal
