import { gql } from "@apollo/client"

const DELETE_AWARDS = gql`
  mutation deleteAcademicAward($uid: String!, $deletedBy: Int!) {
    deleteAcademicAward(uid: $uid, deletedBy: $deletedBy) {
      status
      code
      message
      data {
        uid
        id
        staffUid
        name
        description
        awardingBoard {
          uid
          name
          code
        }
        awardYear
      }
    }
  }
`

const ADD_UPDATE_AWARDS = gql`
  mutation AddUpdateAcademicAward($inputs: AwardInput!) {
    addUpdateAcademicAward(input: $inputs) {
      status
      code
      message
      data {
        uid
        id
        staffUid
        name
        description
        awardingBoard {
          uid
          name
          code
        }
        awardYear
      }
    }
  }
`

export { DELETE_AWARDS, ADD_UPDATE_AWARDS }
