import React from "react";
import { Route, Routes } from "react-router";
import Pages404 from "Modules/Utility/pages-404";
import WorkflowList from "../Views/WorkflowList";
import TransitionMeta from "../Views/TransitionMeta";

const WorkflowRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<WorkflowList />} />
            <Route path="/transition_meta" index={true} element={<TransitionMeta />} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    )
}

export default WorkflowRoutes