//REGISTE
import { gql } from "@apollo/client"

const GET_GROUPS = gql`
  query GetGroups($pagination: PaginationInput!) {
    getGroups(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`

const GET_ASSIGNED_PERMISSION = gql`
  query GetGroupPermissions($uid: String!) {
    getGroupPermissions(uid: $uid) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
      }
    }
  }
`

const GET_USER_ASSIGNED_PERMISSION = gql`
  query GetUserPermissions($userUid: String!) {
    getUserPermissions(userUid: $userUid) {
      status
      code
      message
      data {
        authorities
      }
    }
  }
`

const GET_NOT_ASSIGNED_PERMISSION = gql`
  query GetUnassignedGroupPermissions($uid: String!) {
    getUnassignedGroupPermissions(uid: $uid) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
      }
    }
  }
`

export {
  GET_GROUPS,
  GET_ASSIGNED_PERMISSION,
  GET_NOT_ASSIGNED_PERMISSION,
  GET_USER_ASSIGNED_PERMISSION
}
