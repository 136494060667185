import { gql } from "@apollo/client"

const GET_MEDIA_COVERAGES_BY_STAFF_UID = gql`
  query getAcademicQualificationsByStaffUid($staffUid: String!) {
    getAcademicQualificationsByStaffUid(staffUid: $staffUid) {
      code
      data {
        admissionYear
        approvalDate
        approvalStatus
        approvalStatusReason
        approvedBy
        awardCertificate
        cityOfAward
        completionYear
        countryOfAward
        degreeAwardName
        gpa
        institutionName
        institutionType
        specialization
        staffClass
        staffUid
        tcuCertificate
        tcuVerificationNumber
        thesisDissertationTitle
        uid
        qualificationLevel {
          code
          name
          uid
        }
      }
      message
      status
    }
  }
`

const GET_PROFESSIONAL_AND_TECHNICAL_SKILLS = gql`
  query getStaffProfessionalTechnicalSkills($pagination: PaginationInput!) {
    getStaffProfessionalTechnicalSkills(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          anyOtherInformation
          name
          staffUid
          technologyName
          uid
        }
      }
    }
  }
`

export {
  GET_PROFESSIONAL_AND_TECHNICAL_SKILLS,
  GET_MEDIA_COVERAGES_BY_STAFF_UID,
}
