import React, {createContext, useEffect, useState} from "react";
import LayoutHelper from "../../../../helpers/LayoutHelper";
import {Button, Card, CardBody, CardSubtitle, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import {GET_INSTRUCTOR_ALLOCATED_COURSES} from "../../Queries/InstructorCoursesQueries";
import {useQuery} from "@apollo/client";
import {limit} from "../../../../helpers/UrlHelper";
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader";

const breadcrumbItem =  [
    {
        title: 'Results Upload',
        path: '',
        isActive: true,
    },
]

export const InstructorResultsContext = createContext();
const InstructorResults = () => {
    const staff = JSON.parse(localStorage.getItem("authUser"))?.data

    console.log(staff)
    return 1
    const pagination = { offset: 0, limit: limit, search: null }
    // const { loading:acYearLoading, error:ecYearError, data } = useQuery(GET_ACADEMICYEARS, {
    //     client:registrationGraphQLClient,
    //     variables: {
    //         pagination:pagination,
    //     }
    // })
    const { loading, error, data:fetchCourses, refetch } = useQuery(GET_INSTRUCTOR_ALLOCATED_COURSES, {
        client:registrationGraphQLClient,
        variables: {
            input: {
                studyYear: study_year,
                semester:1,
                registrationNumber:student?.registrationNumber,
                academicYearUid:"",
                programUid:student?.programmeUid
            },
        }
    })
    let courses = fetchCourses?.getStudentProgramCourse?.data
    // let studyYear = []
    // const academicYears = data?.getAcademicYears?.data.items
    // Form validation
    // const validationType = useFormik({
    //     // enableReinitialize : use this flag when initial values needs to be changed
    //     enableReinitialize: true,
    //
    //     initialValues: {
    //         studyYear: "",
    //         semester:"",
    //         registrationNumber:student?.registrationNumber,
    //         academicYearUid:"",
    //         programUid:student?.programmeUid
    //     },
    //     validationSchema: Yup.object().shape({
    //         studyYear: Yup.string().required("Study year is required"),
    //         semester: Yup.string().required("Semester is required"),
    //         academicYearUid: Yup.string().required("Academic year is required"),
    //     }),
    //     onSubmit: (values,{setSubmitting,resetForm}) => {
    //         fetchCourses({
    //             variables: {
    //                 input: { ...values},
    //             },
    //             onCompleted: ({ getStudentProgramCourse }) => {
    //                 if (getStudentProgramCourse.code === 8000) {
    //                     setCourses(getStudentProgramCourse?.data)
    //                     setSubmitting(false)
    //                     // resetForm()
    //                     // setModalOpen(false)
    //                     // setUpdateProgramCategoryData(false) // Close the modal
    //                 } else {
    //                     setCourses([])
    //                     // setSubmitting(false)
    //                     setSubmitting(false)
    //                 }
    //                 showToast(
    //                     getStudentProgramCourse.message,
    //                     getStudentProgramCourse.code === 8000 ? "success" : "error"
    //                 )
    //                 resetForm()
    //             },
    //             onError: error => {
    //                 setSubmitting(false)
    //                 // Handle errors
    //                 console.error("Mutation error:", error)
    //             },
    //         })
    //
    //         // payment({ variables: values })
    //         //     .then((response) => {
    //         //         // Handle the response
    //         //         console.log('Mutation response:', response);
    //         //     })
    //         //     .catch((error) => {
    //         //         // Handle errors
    //         //         console.error('Mutation error:', error);
    //         //     });
    //         // ToastHelper("Service requested successful", "success")
    //         //navigate('/Students/Payments')
    //     },
    // });

    // const study_year = student?.studyYear === 0 ?student?.studyYear+1:student?.studyYear
    // // const year = []
    // for (let i = 0; i < study_year; i++) {
    //     studyYear.push({label:`Year ${i+1}`,value:i+1})
    // }

    // console.log(studyYear)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Course Registration">
            {
                loading ? <PlaceHolderLoader columSize={12} buttonShown={true} rows={5} /> : ""
                    // <RegisterCourses student={student} courses={courses} />


                // courses.length === 0 || validationType.isSubmitting ?
                //     <Row>
                //         <Col lg={5}>
                //             <Card>
                //                 <CardBody>
                //                     <Form
                //                         onSubmit={e => {
                //                             e.preventDefault()
                //                             validationType.handleSubmit()
                //                             return false}}>
                //                         <div className="mb-3">
                //                             <Label>Academic Year</Label>
                //                             <Select
                //                                 // value={selectedGroup}
                //                                 isClearable={true}
                //                                 name="academicYearUid"
                //                                 onChange={(e) => {
                //                                     validationType.setFieldValue('academicYearUid',e?.value)
                //                                     // console.log(e?.value)
                //                                 }}
                //                                 options={academicYears?.map(year => ({
                //                                     value: year.uid,
                //                                     label: year.name,
                //                                 }))}
                //                                 className="select2-selection"
                //                             />
                //                             <Input
                //                                 name="academicYearUid"
                //                                 type="hidden"
                //                                 onChange={validationType.handleChange}
                //                                 onBlur={validationType.handleBlur}
                //                                 value={validationType.values.academicYearUid || ""}
                //                                 invalid={
                //                                     !!(validationType.touched.academicYearUid && validationType.errors.academicYearUid)
                //                                 }
                //                             />
                //                             {validationType.touched.academicYearUid && validationType.errors.academicYearUid ? (
                //                                 <FormFeedback type="invalid">
                //                                     {validationType.errors.academicYearUid}
                //                                 </FormFeedback>
                //                             ) : null}
                //                         </div>
                //                         <div className="mb-3">
                //                             <Label>Study Year</Label>
                //                             <Select
                //                                 // value={selectedGroup}
                //                                 // name="studyYear"
                //                                 isClearable={true}
                //                                 onChange={(e) => {
                //                                     validationType.setFieldValue('studyYear',e?.value)
                //                                     let sem = []
                //                                     setSemesters([])
                //                                     // console.log(e.value)
                //                                     let value =e.value*2
                //                                     for (let i = (e.value*2) - 1; i < value+1; i++) {
                //                                         sem.push({label:'Semseter '+i,value:i})
                //                                     }
                //                                     setSemesters(sem)
                //                                 }}
                //                                 options={studyYear}
                //                                 className="select2-selection"
                //                             />
                //                             <Input
                //                                 name="studyYear"
                //                                 type="hidden"
                //                                 onChange={validationType.handleChange}
                //                                 onBlur={validationType.handleBlur}
                //                                 value={validationType.values.studyYear || ""}
                //                                 invalid={
                //                                     !!(validationType.touched.studyYear && validationType.errors.studyYear)
                //                                 }
                //                             />
                //                             {validationType.touched.studyYear && validationType.errors.studyYear ? (
                //                                 <FormFeedback type="invalid">
                //                                     {validationType.errors.studyYear}
                //                                 </FormFeedback>
                //                             ) : null}
                //                         </div>
                //                         <div className="mb-3">
                //                             <Label>Semester</Label>
                //                             <Select
                //                                 isClearable={true}
                //                                 name="semester"
                //                                 onChange={(e) => {
                //                                     validationType.setFieldValue('semester',e?.value)
                //
                //                                 }}
                //                                 options={semesters}
                //                                 className="select2-selection"
                //                             />
                //                             <Input
                //                                 name="semester"
                //                                 type="hidden"
                //                                 onChange={validationType.handleChange}
                //                                 onBlur={validationType.handleBlur}
                //                                 value={validationType.values.semester || ""}
                //                                 invalid={
                //                                     !!(validationType.touched.semester && validationType.errors.semester)
                //                                 }
                //                             />
                //                             {validationType.touched.semester && validationType.errors.semester ? (
                //                                 <FormFeedback type="invalid">
                //                                     {validationType.errors.semester}
                //                                 </FormFeedback>
                //                             ) : null}
                //                         </div>
                //                         <div className="row">
                //                             <div className="col-6">
                //                                 <div className="square-switch">
                //                                     <input
                //                                         type="checkbox"
                //                                         id="square-switch1"
                //                                         className="switch"
                //                                         defaultChecked={isRegistering}
                //                                         onChange={() =>
                //                                             setIsRegistering(!isRegistering)
                //                                         }
                //                                     />
                //                                     <label
                //                                         htmlFor="square-switch1"
                //                                         data-on-label="Reg"
                //                                         data-off-label="Shw"
                //                                     />
                //                                 </div>
                //                             </div>
                //                             <div className="col-6">
                //                                 <Label>{isRegistering ? "Register Course":"Show Registered Courses"}</Label>
                //                             </div>
                //                         </div>
                //                         <div className="text-end">
                //                             <SubmitButtonHelper type={'primary'} name={'submit'} formik={validationType} />
                //                         </div>
                //                     </Form>
                //                 </CardBody>
                //             </Card>
                //         </Col>
                //     </Row>:
            }

        </LayoutHelper>
    );
}

export default InstructorResults;