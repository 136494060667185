import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import {StudentExamRegistrationContext} from "../../Registration/StudentExamRegistration";
import {limit} from "../../../../../helpers/UrlHelper";
import {useMutation} from "@apollo/client";
import {CREATE_EXAM_POSTPONEMENT} from "../../../Mutations/StudentExamRegistrationMutations";
import * as Yup from "yup";
import {useFormik} from "formik";
import showToast from "../../../../../helpers/ToastHelper";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";


const ExamPostponementForm = () => {
    const {exam,setExam} = useContext(StudentExamRegistrationContext)
    const pagination = { offset: 0, limit: limit, search: null }
    const [modalOpen, setModalOpen] = useState(false)
    const [saveExamPostponement] = useMutation(CREATE_EXAM_POSTPONEMENT)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            examUid: exam?.uid,
            reason: ''
        },

        validationSchema: Yup.object().shape({
            reason: Yup.string().required("Postponement Reason is required")
            // description: Yup.number().required("Program Duration is required"),
        }),
        onSubmit: (values,{setSubmitting,resetForm}) => {
            saveExamPostponement({
                client:registrationGraphQLClient,
                variables: {
                    inputs: { ...values },
                },
                onCompleted: ({ registerExamPostponement }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (registerExamPostponement.code === 8000) {
                        showToast(registerExamPostponement.message, "success")
                        setModalOpen(false)
                        setExam(false)
                        validation.resetForm()
                    } else {
                        showToast(
                            registerExamPostponement.message,
                            registerExamPostponement.code === 8000 ? "success" : "error"
                        )
                    }
                },
                onError: error => {
                    // Handle errors
                    console.error("Mutation error:", error);
                },
            });
        },
    })

    // console.log(modalOpen)
    return (
        <div className="text-sm-end mt-3">

            <span onClick={() => setModalOpen(true)}>

                <i
                    className="mdi mdi-update"
                    style={{
                        color: "blue",
                        marginRight: "10px",
                    }}
                /> Postpone Exam</span>
            <Modal
                isOpen={modalOpen || exam}
                backdrop={"static"}
                id="staticBackdrop"
                // size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                       Exam Postponement Form
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <Row>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label for="learningOutcome">
                                        Learning Outcome Name
                                    </Label>
                                    <Input
                                        type="textarea"
                                        className="form-control"
                                        id="learningOutcome"
                                        rows={5}
                                        name="learningOutcome"
                                        placeholder="Enter Course Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.reason}
                                        invalid={
                                            !!(validation.touched.reason && validation.errors.reason)
                                        }
                                    />
                                    {
                                        validation.touched.reason && validation.errors.reason ? (
                                            <FormFeedback type="invalid">{validation.errors.reason}</FormFeedback>
                                        ): null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setExam(false)
                                validation.resetForm();
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default ExamPostponementForm;