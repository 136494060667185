const { gql } = require("apollo-boost")

const LOGIN_MUTATION = gql`
  mutation Login($input: LoginDtoInput!) {
    login(input: $input) {
      ... on LoginError {
        message
        status
        code
      }
      ... on LoginSuccess {
        accessToken
        refreshToken
        tokenType
        code
        status
        data {
          uid
          firstName
          middleName
          username
          lastName
          phoneNumber
          email
          isVerified
          country {
            code
            name
            dialCode
            uid
          }
          student {
            uid
            programmeUid
            studyYear
            registrationNumber
            status {
              name
              uid
            }
          }
        }
      }
    }
  }
`

const VERIFY_OTP_MUTATION = gql`
  mutation Login($input: OTPInput!) {
    verifyUserOtp(input: $input) {
      ... on LoginError {
        message
        status
        code
      }
      ... on LoginSuccess {
        accessToken
        refreshToken
        tokenType
        code
        status
        data {
          firstName
          middleName
          username
          lastName
          email
          authorities
          isVerified
          country {
            code
            name
            uid
          }
          student {
            programmeUid
            studyYear
            registrationNumber
            status {
              name
              uid
            }
          }
        }
      }
    }
  }
`

const LOGOUT_MUTATION = gql`
  mutation Logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken) {
      status
      code
      message
    }
  }
`

const REFRESH_MUTATION = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
    }
  }
`

export {
  LOGIN_MUTATION,
  VERIFY_OTP_MUTATION,
  LOGOUT_MUTATION,
  REFRESH_MUTATION,
}
