import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Label, InputGroup } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { StaffContext } from "./UsersList"
import Select from "react-select"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { ASSIGN_HEADSHIP } from "../Mutations/UserMutations"
import {
  GET_HEADSHIP_ORGANS,
  GET_HEADSHIP_TYPE,
} from "../../Headships/Queries/HeadshipQueries"
import Flatpickr from "react-flatpickr"
import { GET_GROUPS } from "Modules/Groups/Queries/GroupQueries"
import { limit } from "helpers/UrlHelper"

const AssignHeadShipModel = () => {
  const { headShipUser, setHeadShipUser } = useContext(StaffContext)
  // console.log(headShipUser)
  const [assignHeadship] = useMutation(ASSIGN_HEADSHIP)
  const [modalOpen, setModalOpen] = useState(false)
  const [defaultInputValue, setDefaultInputValue] = useState("")
  const pagination = { offset: 0, limit: limit, search: null }

  const formRef = useRef()
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }
  const { loading, error, data } = useQuery(GET_HEADSHIP_TYPE, {
    client: uaaGraphQLClient,
  })

  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
  } = useQuery(GET_GROUPS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  let organs = []

  const [
    searchOrgans,
    { loading: searchOrgan, error: organsError, data: organData },
  ] = useLazyQuery(GET_HEADSHIP_ORGANS, { client: uaaGraphQLClient })
  if (organData?.getHeadshipOrigins?.data)
    organs = organData?.getHeadshipOrigins?.data
  const searchOrg = value => {
    setDefaultInputValue("")
    let data = {
      search: value,
    }
    if (value) {
      searchOrgans({
        variables: {
          headshipType: value.value,
        },
      })
    }
  }
  // if (error) return showToast("Failed to fetch Reasons", "error")
  const headships = data?.getHeadshipType?.data
  const groups = groupData?.getGroups?.data?.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      userUid: headShipUser?.user?.uid,
      originUid: [],
      endDate: "",
      headship: "",
      groupUid: "",
      startDate: "",
    },
    validationSchema: Yup.object().shape({
      headship: Yup.string().required("Headship Type is required"),
      userUid: Yup.string().required("User is required"),
      originUid: Yup.array().required("Origin is required"),
      groupUid: Yup.string().required("Group is required"),
      startDate: Yup.string().min(1).required("Start Date is required"),
      endDate: Yup.string().required("End Date is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      //
      if (Array.isArray(values.originUid)) {
        values.originUid = JSON.stringify(values.originUid)
      }

      const postingData = {
        userUid: headShipUser?.user?.uid,
        originUid: JSON.parse(values.originUid),
        endDate: values.endDate,
        headship: values.headship,
        groupUid: values.groupUid,
        startDate: values.startDate,
      }
      assignHeadship({
        client: uaaGraphQLClient,
        variables: {
          inputs: { ...postingData },
        },
        onCompleted: ({ assignUserHeadship }) => {
          setSubmitting(false)
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (assignUserHeadship.code === 8000) {
            showToast(assignUserHeadship.message, "success")
            setHeadShipUser(null)
            setModalOpen(false)
          } else {
            showToast(
              assignUserHeadship.message,
              assignUserHeadship.code === 8000 ? "success" : "error"
            )
          }
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Modal isOpen={!!headShipUser} backdrop={"static"} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {`Assign Headship to ${headShipUser?.user?.firstName} ${headShipUser?.user?.lastName}`}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setHeadShipUser(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label>Select Headship Type</label>
              <Select
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                // value={selectedMulti2}
                onChange={e => {
                  // validation.values.programCategoryUid =e.value
                  validation.setFieldValue("headship", e?.value || "")
                  validation.setFieldValue("originUid", "")
                  setDefaultInputValue("")
                  organs = []
                  searchOrg(e)
                  // console.log(defaultInputValue)
                }}
                isClearable={true}
                options={headships?.map(hdp => ({
                  value: hdp,
                  label: hdp,
                }))}
                className="select2-selection"
              />
              <Input
                name="headship"
                placeholder="Select Headship"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.headship || ""}
                invalid={
                  !!(validation.touched.headship && validation.errors.headship)
                }
              />
              {validation.touched.headship && validation.errors.headship ? (
                <FormFeedback type="invalid">
                  {validation.errors.headship}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label>Select Headship Organs</label>
              <Select
                onChange={selectedOptions => {
                  selectedOptions.map(option => {
                    validation.setFieldValue("originUid", [
                      ...validation.values.originUid,
                      option.value,
                    ])
                  })
                }}
                isLoading={searchOrgan}
                options={organs?.map(org => ({
                  value: org.uid,
                  label: org.name,
                }))}
                isMulti={true} // Enable multiple selections
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
              />

              <Input
                name="originUid"
                placeholder="Select Reason"
                type="hidden"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.originUid}
                invalid={
                  !!(
                    validation.touched.originUid && validation.errors.originUid
                  )
                }
              />
              {validation.touched.originUid && validation.errors.originUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.originUid}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label>Select User Group</label>
              <Select
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                onChange={e => {
                  validation.setFieldValue("groupUid", e?.value)
                  setDefaultInputValue("")
                }}
                isClearable={true}
                options={groups?.map(group => ({
                  value: group.uid,
                  label: group.name,
                }))}
                className="select2-selection"
              />
              <Input
                name="groupUid"
                placeholder="Select Group"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.groupUid || ""}
                invalid={
                  !!(validation.touched.groupUid && validation.errors.groupUid)
                }
              />
              {validation.touched.groupUid && validation.errors.groupUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.groupUid}
                </FormFeedback>
              ) : null}
            </div>

            <div className="row">
              <div className="col-6 form-group mb-3">
                <Label>Starting Date</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    // name="dob"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y h:i K",
                      dateFormat: "Y-m-d H:i",
                      enableTime: true,
                      time_24hr: true,
                      closeOnSelect: true,
                    }}
                    onChange={date => {
                      // const formattedDate = date[0].toISOString().slice(0, 19).replace("T", " ");
                      const selectedDate = date[0] // Get the selected date object
                      const year = selectedDate.getFullYear()
                      const month = selectedDate.getMonth() + 1 // Months are 0-indexed
                      const day = selectedDate.getDate()
                      const hours = selectedDate.getHours()
                      const minutes = selectedDate.getMinutes()
                      const seconds = selectedDate.getSeconds()

                      // Format the date and time as YYYY-MM-DD HH:mm:ss
                      const formattedDate = `${year}-${
                        month < 10 ? "0" : ""
                      }${month}-${day < 10 ? "0" : ""}${day} ${
                        hours < 10 ? "0" : ""
                      }${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
                        seconds < 10 ? "0" : ""
                      }${seconds}`

                      validation.setFieldValue("startDate", formattedDate)
                    }}
                  />
                </InputGroup>
                <Input
                  name="startDate"
                  placeholder="Enter your Addresses"
                  type="hidden"
                  rows={3}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.startDate || ""}
                  invalid={
                    !!(
                      validation.touched.startDate &&
                      validation.errors.startDate
                    )
                  }
                />
                {validation.touched.startDate && validation.errors.startDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.startDate}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="col-6 form-group mb-3">
                <Label>Ending Date</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    // name="dob"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y h:i K",
                      dateFormat: "Y-m-d H:i",
                      enableTime: true,
                      time_24hr: true,
                      closeOnSelect: true,
                    }}
                    onChange={date => {
                      
                      const selectedDate = date[0] // Get the selected date object
                      const year = selectedDate.getFullYear()
                      const month = selectedDate.getMonth() + 1 // Months are 0-indexed
                      const day = selectedDate.getDate()
                      const hours = selectedDate.getHours()
                      const minutes = selectedDate.getMinutes()
                      const seconds = selectedDate.getSeconds()

                      // Format the date and time as YYYY-MM-DD HH:mm:ss
                      const formattedDate = `${year}-${
                        month < 10 ? "0" : ""
                      }${month}-${day < 10 ? "0" : ""}${day} ${
                        hours < 10 ? "0" : ""
                      }${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
                        seconds < 10 ? "0" : ""
                      }${seconds}`

                      validation.setFieldValue("endDate", formattedDate)
                    }}
                  />
                </InputGroup>
                <Input
                  name="endDate"
                  placeholder="Enter your Addresses"
                  type="hidden"
                  rows={3}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.endDate || ""}
                  invalid={
                    !!(validation.touched.endDate && validation.errors.endDate)
                  }
                />
                {validation.touched.endDate && validation.errors.endDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.endDate}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                validation.resetForm()
                setModalOpen(false)
                setHeadShipUser(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              type="primary"
              name={"Submit"}
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default AssignHeadShipModel
