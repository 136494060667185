import { gql } from "@apollo/client"

const CREATE_CERTIFICATE_TYPES = gql`
  mutation RegisterCertificateType($inputs: [CertificateTypeDtoInput!]!) {
    registerCertificateTypes(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
        }
      }
    }
  }
`

const DELETE_CERTIFICATE_TYPES = gql`
  mutation RemoveCertificateType($uid: String!) {
    removeCertificateType(uid: $uid) {
      code
      message
      status
    }
  }
`

export { CREATE_CERTIFICATE_TYPES, DELETE_CERTIFICATE_TYPES }
