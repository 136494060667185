import { gql } from "apollo-boost"

// const GET_RESULTS_OLD = gql`
//   query GetResults(
//     $searchCriteria: ExamCourseWorkSearchCriteria!
//   ){
//     getExamCourseWorkResults(
//       searchCriteria: $searchCriteria
//     ){
//       status
//       code
//       data {
//         studentUid
//         score
//         weight
//         source
//         assessmentNumber
//         examCategoryId
//         overallMarks
//         programCourse {
//           uid
//           course {
//             uid
//             code
//             name
//           }

//           programCourseAssessments{
//             examCategory{
//               id
//               name
//               code
//             }
//           }
//         }
//       }
//     }
//   }
// `


const GET_RESULTS = gql`
  query GetResults(
    $searchCriteria: ExamCourseWorkSearchCriteria!
  ){
    getExamCourseWorkResults(
      searchCriteria: $searchCriteria
    ){
      status
      code
      data {
        studentUid
        examCategoryId
        score
        weight
        registrationNumber
        assessmentNumber
        examCategoryCode
        examCategoryName
        firstName
        middleName
        lastName
        source
        overallMarks
        
      }
    }
  }
`


const GET_EXAM_RESULT_SUMMARIES = gql`
  query GetExamResultSummarie(
    $searchCriteria: ExamResultSummarySearchCriteria!
  ) {
    getExamResultSummaries(searchCriteria: $searchCriteria) {
      status
      code
      message
      data {
        gender
        programCourseId
        studentUid
        registrationNumber
        firstName
        middleName
        lastName
        courseCode
        courseName
        cwPractical
        cwTheory
        cwScore
        ueOral
        ueScore
        ueTheory
        uePractical
        totalScore
        grade
        credit
        gradePoint
        programUid
        gradeRemark
      }
    }
  }
`
export { GET_RESULTS, GET_EXAM_RESULT_SUMMARIES }
