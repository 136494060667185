import React, { createContext, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { GET_PROGRAM_CATEGORIES } from "../Queries/ProgramCategoryQueries"
import { DELETE_PROGRAM_CATEGORY } from "../Mutations/ProgramCategoryMutations"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import ProgrammeCategoryModal from "./ProgrammeCategoryModal"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"

const breadcrumbItem = [
  {
    title: "Program Category List",
    path: "",
    isActive: true,
  },
]

export const ProgramCategoryContext = createContext() //Create data to transfer to child modal

const ProgrammeCategories = () => {
  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedProgramCategory, setSelectedProgramCategory] = useState(null) //Get data for deleting
  const [updateProgramCategoryData, setUpdateProgramCategoryData] =
    useState(null) //Get data for editing

  const { loading, error, data, refetch } = useQuery(GET_PROGRAM_CATEGORIES, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const [
    searchProgramCategory,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_PROGRAM_CATEGORIES, {
    client: registrationGraphQLClient,
  })

  useEffect(() => {
    if (!loading && data) {
      console.log("DataReceived", data)
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected
    // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteProgramCategory] = useMutation(DELETE_PROGRAM_CATEGORY, {
    client: registrationGraphQLClient,
    variables: { uid: selectedProgramCategory?.uid },
    refetchQueries: [
      {
        query: GET_PROGRAM_CATEGORIES,
        variables: {
          pagination: paginationHelper,
        },
      },
    ],
  })

  const handleDeleteProgramCategory = () => {
    showDeleteWarning(() => {
      performProgramCategoryDeletion()
    })
  }

  const performProgramCategoryDeletion = async () => {
    try {
      const response = await deleteProgramCategory()
      showToast(response.data.removeProgramCategory.message, "success")
    } catch (error) {
      showToast("Failed to delete", "error")
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchProgramCategory({
      variables: { pagination: { ...pagination, ...data } },
    })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    console.log("SearchedInput", value)
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    }
  }

  const ProgramCategory = data?.getProgramCategories.data?.items
  const searchedProgramCategory = searchData?.getProgramCategories?.data?.items
  const result = searchedProgramCategory
    ? searchedProgramCategory
    : ProgramCategory
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getProgramCategories.data.totalCount ||
    searchData?.getProgramCategories?.data?.items ||
    0

  return (
    <ProgramCategoryContext.Provider
      value={{ updateProgramCategoryData, setUpdateProgramCategoryData }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Program Category List"
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <ProgrammeCategoryModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Name</Th>
                            <Th data-priority="1">Short Name</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            .slice(startIndex, endIndex)
                            .map((category, index) => (
                              <Tr key={category?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{category?.name}</Td>
                                <Td>{category?.shortName}</Td>
                                <Td>
                                  <button
                                    className="btn btn-sm btn-primary"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      setUpdateProgramCategoryData(category)
                                    }}
                                  >
                                    <i className="bx bxs-edit"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      setSelectedProgramCategory(category)
                                      handleDeleteProgramCategory()
                                    }}
                                  >
                                    <i className="bx bxs-trash"></i>
                                  </button>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </ProgramCategoryContext.Provider>
  )
}

export default ProgrammeCategories
