import { gql } from '@apollo/client';


const ASSIGN_HEADSHIP = gql`
  mutation AssignUserHeadship($inputs: UserActivateDeactivateDtoInput!) {
    assignUserHeadship(inputs: $inputs) {
        status
        code
        message
    }
  }
`

const UPDATE_HEADSHIP = gql`
  mutation UpdateData($id: ID!, $value: String!) {
    updateData(id: $id, value: $value) {
      id
      name
      email
      phone
    }
  }
`

const DELETE_HEADSHIP = gql`
  mutation deleteHEADSHIP($id: ID!) {
    deleteHEADSHIP(id: $id) {
      id
      name
      email
      phone
    }
  }
`

const ACTIVATE_DEACTIVATE_HEADSHIP = gql`
  mutation ActivateDeactivateUserHeadship($inputs:  HeadshipInput!) {
    activateDeactivateUserHeadship(inputs: $inputs) {
      status
      code
      message
      data {
        uid
      }
    }
  }
`

export { ASSIGN_HEADSHIP, UPDATE_HEADSHIP, DELETE_HEADSHIP, ACTIVATE_DEACTIVATE_HEADSHIP }