import React, {useState} from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle, Input, FormFeedback, Form, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap"
import * as Yup from "yup";
import {useLazyQuery, useMutation} from "@apollo/client";
import {registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {GENERATE_PARTIAL_TRANSCRIPT_XLS} from "../Queries/TranscriptQueries";
import showToast from "../../../helpers/ToastHelper";
import LayoutHelper from "../../../helpers/LayoutHelper";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {useFormik} from "formik";
import {DownloadExcelFile} from "../../../helpers/DownloadExcelFile";
import {sr2URL} from "../../../helpers/UrlHelper";
import toastHelper from "../../../helpers/ToastHelper";
const breadcrumbItem = [
    {
        title: "Student Partial Transcript",
        path: "",
        isActive: true,
    },
]
const PartialTranscript = () => {
    const [reset,setReset] = useState(false)
    // const useS
    const [generateTranscript] = useLazyQuery(GENERATE_PARTIAL_TRANSCRIPT_XLS,
        {
            client: registrationGraphQLClient
        })

    // console.log(user)
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            registrationNumber: "",
        },
        validationSchema: Yup.object().shape({
            registrationNumber: Yup.string().required("Registration Number is required")
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            fetch(sr2URL + "students/payment_status?registration_number="+values.registrationNumber+"&service=partial_transcript&balance="+true, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                // body: JSON.stringify({ registration_number: registration_number }),
            }).then(response => response.json()).then(data => {
                if (data.status !== 200) {
                    toastHelper(data.message, 'error')
                    setSubmitting(false)
                } else if (data.status === 200) {
                    generateTranscript({
                        variables: {
                            registrationNumber: values.registrationNumber
                        },
                        fetchPolicy: "no-cache",
                        client: registrationGraphQLClient,
                        onCompleted: ({generatePartialTranscript}) => {
                            // console.log(generatePartialTranscript)
                            if (generatePartialTranscript?.code === 8000) {
                                // console.log(generatePartialTranscript?.data)
                                DownloadExcelFile(generatePartialTranscript?.data?.base64Data, generatePartialTranscript?.data?.fileName)
                            }
                            showToast(
                                generatePartialTranscript.message,
                                generatePartialTranscript.code === 8000 ? "success" : "error"
                            )
                            setSubmitting(false)
                        },
                        onError: error => {
                            console.error("Mutation error:", error)
                            setSubmitting(false)
                        },
                    })
                }
            })
            // return false
            // ToastHelper("Waiting for Backend", "info")
            // ToastHelper("Service requested successful", "success")
            //navigate('/Students/Payments')
        },

    });

    // console.log(validation.errors)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Generate partial transscript">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle className="mb-1">
                                <Form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        validation.handleSubmit()
                                        return null
                                    }}
                                >
                                    <Row>
                                        <Col className="col-lg-6 col-12">
                                            <label htmlFor="name" className="col-form-label">
                                                Registration Number:
                                            </label>
                                            <Input
                                                type="text"
                                                name="registrationNumber"
                                                className="form-control"
                                                id="registrationNumber"
                                                placeholder="Enter Registration Number"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.registrationNumber}
                                                invalid={
                                                    validation.touched.registrationNumber && validation.errors.username
                                                        ? true
                                                        : false
                                                }
                                            />
                                            {validation.touched.registrationNumber && validation.errors.registrationNumber ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.registrationNumber}
                                                </FormFeedback>
                                            ) : null}

                                        </Col>
                                        <Col className="col-lg-2 col-12">
                                            <br/><br/>
                                            <SubmitButtonHelper
                                                name="Submit"
                                                type="primary"
                                                formik={validation}
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </CardSubtitle>


                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}

export default PartialTranscript
