import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_CAMPUS } from "Modules/Campus/Queries/CampusQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {
  registrationGraphQLClient,
  uaaGraphQLClient,
} from "Modules/Authentication/ApolloClient"
import {
  CREATE_TRANSITION_META_WORKFLOW,
  CREATE_WORKFLOW,
} from "../Mutation/WorkFlowMutation"
import {
  GET_TRANSITION_META_BY_WORKFLOW,
  GET_WORKFLOW,
} from "../Queries/WorkflowQueries"
import { TransitionMetaContext } from "./TransitionMeta"
import { GET_STATE } from "Modules/State/Queries/StateQueries"
import Select from "react-select"
import { GET_PERMISSIONS } from "Modules/Permissions/Queries/PermissionQueries"
import { GET_GROUPS } from "Modules/Groups/Queries/GroupQueries"

const TransitionMetaModal = () => {
  const { updateTransitionMetaData, setUpdateTransitionMetaData, stateData } =
    useContext(TransitionMetaContext)
  const [transitionMeta] = useMutation(CREATE_TRANSITION_META_WORKFLOW)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_CAMPUS
  const variables = { pagination: paginationHelper }
  const registerData = "data"
  const limit = 100
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const [selectedStateSource, setSelectedStateSource] = useState(null)

  const { loading, error, data, refetch } = useQuery(GET_STATE, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const destinationStateContextData =
      updateTransitionMetaData?.destinationState,
    sourceStateContextData = updateTransitionMetaData?.sourceState

  const {
    loading: groupsLoading,
    error: groupsError,
    data: groupsData,
    refetch: refetchGroup,
  } = useQuery(GET_GROUPS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const {
    loading: permissionLoading,
    error: permissionError,
    data: permissionData,
    refetch: refetchPermission,
  } = useQuery(GET_PERMISSIONS, {
    client: uaaGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })

  const permisionResults = permissionData?.getPermissions?.data?.items,
    groupResults = groupsData?.getGroups?.data?.items

  const states = data?.getStates?.data?.items

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      sourceStateUid: sourceStateContextData ? sourceStateContextData?.uid : "",
      destinationStateUid: destinationStateContextData
        ? destinationStateContextData?.uid
        : "",
      workflowUid: stateData ? stateData?.uid : "",
      permissions: [],
      groups: [],
    },

    validationSchema: Yup.object().shape({
      sourceStateUid: Yup.string().required("Source state is required"),
      groups: Yup.array().min(1, "Select at least one group"),
      // permissions: Yup.array().min(1, "Select at least one permission"),
      destinationStateUid: Yup.string().required(
        "Destination state is required"
      ),
    }),

    onSubmit: values => {
      transitionMeta({
        client: registrationGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateTransitionMetaData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_TRANSITION_META_BY_WORKFLOW,
            variables: {
              workflowUid: stateData?.uid,
            },
          },
        ],
        onCompleted: ({ registerTransitionMetas }) => {
          //updateFunction(client.cache, { data: { registerWorkflows } });
          if (registerTransitionMetas.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateTransitionMetaData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateTransitionMetaData(false)
            validation.resetForm()
          }
          showToast(
            registerTransitionMetas.message,
            registerTransitionMetas.code === 8000 ? "success" : "error"
          )
          validation.resetForm()
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Transition Meta
      </Button>
      <Modal
        isOpen={modalOpen || !!updateTransitionMetaData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateTransitionMetaData
              ? "Edit Transition Meta"
              : "Add New Transition Meta"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label>Select State source</label>
              <Select
                name="sourceStateUid"
                onChange={e => {
                  validation.setFieldValue("sourceStateUid", e.value)
                  setSelectedStateSource(e.value)
                }}
                options={states?.map(m => ({
                  value: m?.uid,
                  label: m?.label,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                defaultValue={sourceStateContextData?.uid}
                defaultInputValue={sourceStateContextData?.label}
              />
              <Input
                name="source_state"
                placeholder="Select Marital Status"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values?.uid || sourceStateContextData?.uid || ""
                }
                invalid={
                  !!(
                    validation.touched.sourceStateUid &&
                    validation.errors.sourceStateUid
                  )
                }
              />
              {validation.touched.sourceStateUid &&
              validation.errors.sourceStateUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.sourceStateUid}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label>Select Destination source</label>
              <Select
                name="destinationStateUid"
                onChange={e => {
                  validation.setFieldValue("destinationStateUid", e.value)
                }}
                options={states
                  ?.map(
                    m =>
                      selectedStateSource !== m?.uid && {
                        value: m?.uid,
                        label: m?.label,
                      }
                  )
                  .filter(Boolean)}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
                defaultValue={destinationStateContextData?.uid}
                defaultInputValue={destinationStateContextData?.label}
              />
              <Input
                name="destinationStateUid"
                placeholder="Select Marital Status"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values?.uid ||
                  destinationStateContextData?.uid ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.destinationStateUid &&
                    validation.errors.destinationStateUid
                  )
                }
              />
              {validation.touched.destinationStateUid &&
              validation.errors.destinationStateUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.destinationStateUid}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3">
              <label>Select Groups</label>
              <Select
                name="groups"
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions.map(
                    option => option.value
                  )
                  validation.setFieldValue("groups", selectedValues)
                }}
                options={groupResults?.map(dp => ({
                  value: dp?.code,
                  label: dp.name,
                }))}
                isMulti={true} // Enable multiple selections
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
              />
              <Input
                name="groups" // Update the field name
                placeholder="Select Marital Status"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={JSON.stringify(validation.values.groups || [])} // Set the value as a JSON string
              />
            </div>

            <div className="mb-3">
              <label>Select Permision</label>
              <Select
                name="permissions"
                onChange={selectedOptions => {
                  const selectedValues = selectedOptions.map(
                    option => option.value
                  )
                  validation.setFieldValue("permissions", selectedValues)
                }}
                options={permisionResults?.map(dp => ({
                  value: dp?.code,
                  label: dp.name,
                }))}
                isMulti={true} // Enable multiple selections
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
              />
              <Input
                name="permissions" // Update the field name
                placeholder="Select Marital Status"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={JSON.stringify(validation.values.permissions || [])} // Set the value as a JSON string
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateTransitionMetaData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default TransitionMetaModal
