import React from "react";
import { Navigate, Route, Routes } from "react-router-dom"
import Pages404 from "../../Utility/pages-404"
import StudentSemesterRegistration from "../Views/Registration/StudentSemesterRegistration";
import StudentExamRegistration from "../Views/Registration/StudentExamRegistration";
import StudentMoodleCourses from "../Views/Registration/StudentMoodleCourses";


const StudentRegistrationRoutes = () => {
    // console.log(history)
    return (
        <Routes>
            <Route path="/examination"  element={<StudentExamRegistration />} />
            <Route path="/semester"  element={<StudentSemesterRegistration />} />
            <Route path="/students/moodle-courses" element={<StudentMoodleCourses />} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    );
};
export default StudentRegistrationRoutes;
