import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row, Col } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { CREATE_STAFF_TERM } from "Modules/StaffTerms/Mutations/StaffTermsMutations"
import { GET_INSTRUCTORS } from "../Queries/ExaminerQueries"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { StudentExaminerContext } from "./StudentExaminer"

const StudentExaminerModal = () => {

  const { updateStudentExaminer, setUpdateStudentExaminer } =
    useContext(StudentExaminerContext)
  const [StaffTerm] = useMutation(CREATE_STAFF_TERM, {
    client: uaaGraphQLClient,
  })

    const { loading: instructorLoading, error: instructorError, data: instructorData, refetch } = useQuery(GET_INSTRUCTORS, {
    client:uaaGraphQLClient,
    fetchPolicy: 'cache-first'
  })

  const instructors = instructorData?.getInstructors?.data;

  console.log("instructor mbanga: ", instructors)


  const [assigningOption, setAssigningOption] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const externalExaminer = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      middlename: "",
      surname: "",
      phone: "",
      email: "",
      pfnumber: "",
      supervisorType: "external"
    },

    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("Supervisor first name is required"),
      middlename: Yup.string().required("Supervisor middle name is required"),
      surname: Yup.string().required("Supervisor surname is required"),
      email: Yup.string().required("Supervisor email is required"),
      phone: Yup.string().required("Supervisor phone is required"),
      pfnumber: Yup.string().required("Supervisor PF number is required"),
    }),

      onSubmit: (values, {resetForm}) => {

        console.log(values)
        return
        StaffTerm({
          client: uaaGraphQLClient,
          variables: {
            inputs: [{ ...values, uid: updateStudentExaminer?.uid }],
          },
          refetchQueries: [
            {
              query: GET_STAFF_TERMS,
              variables: {
                pagination: paginationHelper,
              },
            },
          ],
          onCompleted: ({ registerStaffTerms }) => {
            if (registerStaffTerms.code === 8000) {
              resetForm();
              setModalOpen(false);
              setUpdateStudentExaminer(false); // Close the modal
            } else {
              setModalOpen(true);
              setUpdateStudentExaminer(true); // Close the modal
            }
            showToast(
              registerStaffTerms.message,
              registerStaffTerms.code === 8000 ? "success" : "error"
            );
          },
          onError: error => {
            // Handle errors
            console.error("Mutation error:", error);
          },
        });
      },
  })

  const internalExaminer = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userUid: [],
      supervisorType: "internal"
    },

    validationSchema: Yup.object().shape({
        userUid: Yup.array().required("Supervisor first name is required"),
    }),

      onSubmit: (values, {resetForm}) => {

        console.log(values)
        return;
        StaffTerm({
          client: uaaGraphQLClient,
          variables: {
            inputs: [{ ...values, uid: updateStudentExaminer?.uid }],
          },
          refetchQueries: [
            {
              query: GET_STAFF_TERMS,
              variables: {
                pagination: paginationHelper,
              },
            },
          ],
          onCompleted: ({ registerStaffTerms }) => {
            if (registerStaffTerms.code === 8000) {
              resetForm();
              setModalOpen(false);
              setUpdateStudentExaminer(false); // Close the modal
            } else {
              setModalOpen(true);
              setUpdateStudentExaminer(true); // Close the modal
            }
            showToast(
              registerStaffTerms.message,
              registerStaffTerms.code === 8000 ? "success" : "error"
            );
          },
          onError: error => {
            // Handle errors
            console.error("Mutation error:", error);
          },
        });
      },
  })

  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Allocate new Students
      </Button>
      <Modal
        isOpen={modalOpen || !!updateStudentExaminer}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateStudentExaminer
              ? "Edit Student Examiner "
              : "Student Examiner Registration"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
                setModalOpen(false)
                setUpdateStudentExaminer(false)
                internalExaminer.resetForm()
                externalExaminer.resetForm()
                setAssigningOption(null)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Row>
        <Col lg={12}>
            <Form
            onSubmit={e => {
                e.preventDefault()
                internalExaminer.handleSubmit()
                return null
            }}
            >
            <div className="modal-body">
                <div className="mb-3">
                <Col lg="12">
            <div className="mb-3">
                <label>Select Student's Registration number</label>
                <Select

                name="userUid"
                onChange={selectedOptions => {
                    const selectedValues = selectedOptions.map(option => ({
                    userUid: option.value,
                    }))
                    internalExaminer.setFieldValue("userUid", selectedValues)
                }}
                options={instructors?.map(staff => ({
                    value: staff?.user?.uid,
                    label: staff?.user?.firstName + " " + staff?.user?.lastName                      ,
                }))}
                isMulti={true} // Enable multiple selections
                className="select2-selection"
                styles={{
                    menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                    }),
                }}
                />
                <Input
                name="userUid" // Update the field name
                placeholder="Select Marital Status"
                type="hidden"
                rows={2}
                onChange={internalExaminer.handleChange}
                onBlur={internalExaminer.handleBlur}
                value={JSON.stringify(
                    internalExaminer.values.userUid || []
                )} // Set the value as a JSON string
                />
            </div>
            </Col>
                </div>
            </div>
            <div className="modal-footer">
                <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                    setModalOpen(false)
                    setUpdateStudentExaminer(false)
                    internalExaminer.resetForm()
                    externalExaminer.resetForm()
                    setAssigningOption(null)
                }}
                >
                Close
                </button>
                <SubmitButtonHelper
                name="Submit"
                type="primary"
                formik={internalExaminer}
                />
            </div>
            </Form>
        </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default StudentExaminerModal
