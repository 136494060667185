import React, { createContext, useEffect, useState } from "react"
import LayoutHelper from "../../../helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import { useLocation, useNavigate } from "react-router-dom"
import { Card, CardBody, CardSubtitle, Col, Row } from "reactstrap"
import { Table, Thead, Tr, Th, Td } from "react-super-responsive-table"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import { limit } from "helpers/UrlHelper"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import ProgramCoursesModal from "./ProgramCoursesModal"
import { GET_PROGRAM_COURSE_BY_SEMESTER } from "Modules/ProgramSemister/Queries/ProgramCourseQueries"
import { DELETE_PROGRAM_COURSE } from "Modules/ProgramCourses/Mutations/ProgramCoursesMutations"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import ProgramActionHelper from "helpers/ProgramHelper"
import StaffCourseAllocationBySemesterList from "./StaffCourseAllocationBySemesterList"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jwtDecode } from "jwt-decode"

const breadcrumbItem = [
  {
    title: "Program List",
    path: "/programs",
    isActive: false
  }
]

export const ProgramCourseDetailContext = createContext()

const ProgramCourseDetails = () => {
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const [selectedProgramCourse, setSelectedProgramCourse] = useState(null)
  const [updateProgramCourseData, setUpdateProgramCourseData] = useState(null)
  const [courseAllocate, setCourseAllocate] = useState(null)
  const [programSemesterUid, setProgramSemesterUid] = useState(null)

  const location = useLocation()
  const navigate = useNavigate()

  // Ensure hooks are called unconditionally
  const searchValues = {
    search: ""
  }

  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required("")
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema
  })

  // Conditionally navigate after hooks
  if (!location.state?.programSemester) {
    navigate("/examination/programs/program-semister")
  }

  let { programSemester } = location.state

  const { loading, error, data, refetch } = useQuery(
    GET_PROGRAM_COURSE_BY_SEMESTER,
    {
      client: registrationGraphQLClient,
      variables: {
        programSemisterUid: programSemester?.uid
      }
    }
  )

  const [
    searchProgramCourse,
    { loading: searchLoading, error: searchError, data: searchData }
  ] = useLazyQuery(GET_PROGRAM_COURSE_BY_SEMESTER, {
    client: registrationGraphQLClient,
    variables: {
      programSemisterUid: programSemester?.uid
    }
  })

  useEffect(() => {
    if (!loading && data) {
      refetch()
      clearSearch()
    }
  }, [loading, data])

  const handleClick = (selectedPage) => {
    const page = selectedPage.selected
    fetchData(page)
    setCurrentPage(selectedPage.selected)
  }

  const [deleteProgramCourse] = useMutation(DELETE_PROGRAM_COURSE, {
    client: registrationGraphQLClient,
    variables: { uid: selectedProgramCourse?.uid },
    refetchQueries: [
      {
        query: GET_PROGRAM_COURSE_BY_SEMESTER,
        variables: {
          programSemisterUid: programSemester?.uid
        }
      }
    ]
  })

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const getAssessment = semester => {
    navigate("/examination/programs/program-semester/course-assessments", { state: { resultRow: semester } })
  }
  const getCourseAllocation = semester => {
    navigate("/examination/course-allocation", { state: { resultRow: semester } })
  }

  const handledeleteProgramCourse = (courses) => {
    setSelectedProgramCourse(courses)
    showDeleteWarning(() => {
      performDataDeletion()
    })
  }

  const performDataDeletion = async () => {
    try {
      const response = await deleteProgramCourse()
      showToast(response.data.removeProgramCourse.message, "success")
    } catch (error) {
      showToast("Failed to delete", "error")
    }
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page)
    let data = {
      search: formikSearch.values.search
    }
    searchProgramCourse({
      variables: { pagination: { ...pagination, ...data } }
    })
  }

  const clearSearch = () => {
    formikSearch.setFieldValue("search", "")
  }

  const programCourse =
    data?.getProgramCourseByProgramSemesterUid?.data?.items ||
    searchData?.getProgramCourseByProgramSemesterUid?.data?.items ||
    []
  const searchedProgramCourse =
    searchData?.getProgramCourseByProgramSemesterUid?.data?.items
  const result = searchedProgramCourse ? searchedProgramCourse : programCourse
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize

  return (
    <ProgramCourseDetailContext.Provider
      value={{
        updateProgramCourseData,
        setUpdateProgramCourseData,
        programSemester,
        courseAllocate,
        setCourseAllocate
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle={`Course Structure : ${programSemester?.program?.name}`}
      >
        <Row>
          <Col lg={4}>
            <Card>
              <CardBody>
                <Table id="tech-companies-1" className="table ">
                  <tbody>
                  <tr>
                    <th>Academic Year:</th>
                    <td>{programSemester?.academicYear.name}</td>
                  </tr>
                  <tr>
                    <th>Study Year:</th>
                    <td>{programSemester?.studyYear}</td>
                  </tr>
                  <tr>
                    <th>Semester:</th>
                    <td>{programSemester?.semester}</td>
                  </tr>
                  <tr>
                    <th>Core Credits:</th>
                    <td>{programSemester?.coreCredits}</td>
                  </tr>
                  <tr>
                    <th>Elective Credits:</th>
                    <td>{programSemester?.electiveCredits}</td>
                  </tr>
                  {" "}
                  <tr>
                    <th>Total Credits:</th>
                    <td>
                      {programSemester?.electiveCredits +
                        programSemester?.coreCredits}
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>

          <Col lg={8}>
            <Card>
              <CardSubtitle className="mb-1">
                <Row>
                  <Col className="col-lg-6 col-sm-6 col-md-6 pull-right"></Col>
                  <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                    <ProgramCoursesModal />
                  </Col>
                </Row>
              </CardSubtitle>

              <CardBody>
                <Table
                  id="tech-companies-1"
                  className="table-sm table-striped table-bordered"
                >
                  <Thead>
                    <Tr>
                      <Th data-priority="1">S/N</Th>
                      <Th>Course</Th>
                      <Th>Type</Th>
                      <Th>Credit</Th>
                      <Th data-priority="3">Action</Th>
                    </Tr>
                  </Thead>
                  <tbody>
                  {result
                    .slice(startIndex, endIndex)
                    .map((resultRow, index) => (
                      <Tr key={resultRow?.uid}>
                        <Td>{startIndex + index + 1}</Td>
                        <Td>{resultRow?.course?.code}</Td>
                        <Td>{resultRow?.courseCategory?.name}</Td>
                        <Td>{resultRow?.credit}</Td>
                        <Td>
                          <ProgramActionHelper
                            data={resultRow}
                            onUpdate={hasAuthority("REMOVE_PROGRAM_COURSE") && setUpdateProgramCourseData}
                            onDelete={hasAuthority("REMOVE_PROGRAM_COURSE") && handledeleteProgramCourse}
                            onAssessement={getAssessment}
                            onAllocation={setCourseAllocate}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <StaffCourseAllocationBySemesterList />
          </Col>
        </Row>
      </LayoutHelper>
    </ProgramCourseDetailContext.Provider>
  )
}

export default ProgramCourseDetails
