import { gql } from "@apollo/client"

const CREATE_STUDY_POSTPONEMENT_REQUEST = gql`
 mutation CreateStudyPostponementRequest($input: CreateStudyPostponementInput!) {
  createStudyPostponementRequest(input: $input) {
    code
    message
    data {
      uid
      reason
      description
      attachedFile
      status
    }
  }
}
`

const DELETE_STUDY_POSTPONEMENT = gql`
  mutation DeleteStudyPostponement($uid: String!) {
    deleteStudyPostponement(uid: $uid) {
      message
      code
      status
    }
  }
`

const UPDATE_STUDY_POSTPONEMENT = gql`
  mutation UpdateStudyPostponement($uid: String!) {
    updateStudyPostponement(uid: $uid) {
      message
      code
      status
    }
  }
`

const EXAM_POSTPONEMENT = gql`
  mutation StudentExamPostponement($inputs: [Input!]!) {
    registerCampuses(inputs: $inputs) {
      code
      message
      data {
        uid
        reason
      }
    }
  }
`

const STUDENT_CHANGE_PROGRAM = gql`
  mutation StudentRequestChangeProgram($input: StudentProgramChangeInput!) {
    studentRequestChangeProgram(input: $input) {
        status
        code
        message
    }
  }
`


const DELETE_ = gql`
  mutation RemoveCampus($uid: String!) {
    removeCampus(uid: $uid) {
      message
      code
      status
    }
  }
`
const SEND_TO_ADVISOR = gql`
  mutation SendToAdvisor($uid: String!) {
    sendToAdvisor(uid: $uid) {
      message
      code
      status
    }
  }
`

export {CREATE_STUDY_POSTPONEMENT_REQUEST,
  EXAM_POSTPONEMENT,
  STUDENT_CHANGE_PROGRAM,
  UPDATE_STUDY_POSTPONEMENT,
  DELETE_STUDY_POSTPONEMENT,
  SEND_TO_ADVISOR
  }
