import { color } from "d3-color"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import MoodleUrlComponent from "Modules/Instructor/Views/MoodleUrlComponent"
import { Link } from "react-router-dom"

const StudentCourses = ({ courseData }) => {
  const maxLenght = 25
  const truncateContent = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }
  return (
    <React.Fragment>
      <Row>
        <Col xl={12} xs={12} sm={12} md={12} lg={12}>
          <Card className="text-center primary">
            <CardBody>
              {/* 
          <Link
                to={`/instructor/course_allocations/${courseData?.uid}`}
                className="text-dark"
              > */}
              <h5 className="font-size-15 mb-0">
                <b>{courseData?.programCourse?.course?.code}</b>
              </h5>

              <p className="text-muted">
                {truncateContent(
                  courseData?.programCourse?.course?.name,
                  maxLenght
                )}
              </p>
              {/* <p className="text-dark">
                {truncateContent(courseData?.programCourse?.programSemester?.program?.name, maxLenght)}
              </p> */}
              {/* </Link> */}
              <Link
                to={{
                  pathname: `/teaching-assessment/course/${courseData?.uid}`,
                  state: courseData,
                }}
                className="btn btn-sm btn-primary"
              >
                Assess course
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default StudentCourses
