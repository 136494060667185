import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { CREATE_STAFF_TERM } from "../Mutations/StaffTermsMutations"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_STAFF_TERMS } from "../Queries/StaffTermsQueries"
import { createUpdateFunction, paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { StaffTermContext } from "./StaffTermsList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const StaffTermModal = () => {
  const { updateStaffTermData, setUpdateStaffTermData } = useContext(StaffTermContext)
  const [StaffTerm] = useMutation(CREATE_STAFF_TERM,{
    client: uaaGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_STAFF_TERMS;
  const variables = { pagination: paginationHelper };
  const registerData = 'data';
  
  const updateFunction =  createUpdateFunction(query, variables, registerData);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateStaffTermData ? updateStaffTermData.name : "",
      code: updateStaffTermData ? updateStaffTermData.code : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Staff Term name is required"),
      code: Yup.string().required("Staff Term Code is required"),
    }),

   
  onSubmit: (values, {resetForm}) => {
    StaffTerm({
      client: uaaGraphQLClient,
      variables: {
        inputs: [{ ...values, uid: updateStaffTermData?.uid }],
      },
      refetchQueries: [
        {
          query: GET_STAFF_TERMS,
          variables: {
            pagination: paginationHelper,
          },
        },
      ],
      onCompleted: ({ registerStaffTerms }) => {
        if (registerStaffTerms.code === 8000) {
          resetForm();
          setModalOpen(false);
          setUpdateStaffTermData(false); // Close the modal
        } else {
          setModalOpen(true);
          setUpdateStaffTermData(true); // Close the modal
        }
        showToast(
          registerStaffTerms.message,
          registerStaffTerms.code === 8000 ? "success" : "error"
        );
      },
      onError: error => {
        // Handle errors
        console.error("Mutation error:", error);
      },
    });
  },
  })
  return (
      <div className="text-sm-end mt-3">
        <Button
          type="button"
          color="primary"
          className="btn mb-2 me-2 pull-right"
          onClick={() => setModalOpen(true)}
        >
          <i className="mdi mdi-plus-circle-outline me-1" />
          New Staff Term
        </Button>
        <Modal
          isOpen={modalOpen || !!updateStaffTermData}
          backdrop={"static"}
          id="staticBackdrop"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {!!updateStaffTermData ? "Edit Staff Term" : "Add New Staff Term"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalOpen(false)
              }}
              aria-label="Close"
            ></button>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return null
            }}
          >
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name" className="col-form-label">
                  Name:
                </label>
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || updateStaffTermData?.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="code" className="col-form-label">
                  Code:
                </label>
                <Input
                  type="text"
                  name="code"
                  className="form-control"
                  disabled={!!updateStaffTermData ? true : false}
                  id="code"
                  placeholder="Enter Code"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.code || updateStaffTermData?.code || ""}
                  invalid={
                    !!(validation.touched.code && validation.errors.code)
                  }
                />
                {validation.touched.code && validation.errors.code ? (
                  <FormFeedback type="invalid">
                    {validation.errors.code}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModalOpen(false)
                  setUpdateStaffTermData(false)
                }}
              >
                Close
              </button>
              <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
            </div>
          </Form>
        </Modal>
      </div>
  )
}

export default StaffTermModal
