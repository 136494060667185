import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import showToast from "helpers/ToastHelper"
const sua_logo = process.env.PUBLIC_URL + "/logo.jpeg"


export const ApplicantListByDatePdfGenerator = (
  tableDataResponse,
  date,
  title
) => {
  try {
    const doc = new jsPDF({
      orientation: "landscape",
    })
    const pageWidth = doc.internal.pageSize.getWidth();
    // Load both images before creating the PDF
    doc.setFontSize(13)
    doc.setFont("Roboto", "bold")
    doc.text("SOKOINE UNIVERSITY OF AGRICULTURE", 150, 20, { align: "center" }) // Adjust position as needed

    doc.setFontSize(10)
    doc.setFont("Roboto", "bold")
    doc.text("OFFICE OF THE DEPUTY VICE CHANCELLOR (ARC)", 150, 25, {
      align: "center",
    })

    doc.setFontSize(8)
    doc.setFont("Roboto", "bold")
    doc.text("P.O. Box 3000, CHUO KIKUU, MOROGORO TANZANIA", 150, 30, {
      align: "center",
    })

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text(
      "Tel. +255-23-2603511/4, Dir. +255-23-2603236; Fax. +255-23-2604652)",
      150,
      35,
      { align: "center" }
    )

    doc.setFontSize(7)
    doc.setFont("Roboto", "bold")
    doc.text("E-mail: dvc@sua.ac.tz or admission@sua.ac.tz)", 150, 40, {
      align: "center",
    })

    doc.setLineWidth(0.5);
    doc.setFontSize(15)
    doc.setFont("Roboto", "bold")
    doc.text(title, 150, 50, {
      align: "center",
    })
    doc.line(15, 52, pageWidth - 15, 52); // Draw a line across the page width

    const textAboveTableHeight = 82 // Adjust based on your actual text height

    doc.autoTable({
      startY: 60,
      margin: { top: 5, left: 5, right: 5 },
      headStyles: { fillColor: [41, 128, 185], textColor: 255, lineColor: [0, 0, 0], lineWidth: 0.5 },
      bodyStyles: { textColor: 0, lineColor: [0, 0, 0], lineWidth: 0.5 },
      head: [["S/No", "First Name", "Middle Name", "Last Name", "Form four index No.", "Phone number", "Email", "Is paid?"]], // Headers
      body: tableDataResponse, // Table data
    });

    doc.save("Application_report_by_date_"+date+ ".pdf")
  } catch (error) {
    showToast("Something went wrong try again later! " + error, "info")
  }
}
