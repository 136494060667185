import { gql } from "@apollo/client"

const DELETE_SPECIAL_APPOINTMENT = gql`
  mutation RemoveStaffSpecialAppointment($uid: String!) {
    removeStaffSpecialAppointment(uid: $uid) {
      status
      code
      message
      data
    }
  }
`

const ADD_UPDATE_STAFF_SPECIAL_APPOINTMENT = gql`
  mutation RegisterStaffSpecialAppointment(
    $inputs: [StaffSpecialAppointmentInput!]!
  ) {
    registerStaffSpecialAppointment(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items {
          uid
          staffUid
          startDate
          endDate
          appointmentLetter
          responsibilities
          appointmentType {
            uid
            name
            code
          }
          appointingOffice {
            uid
            name
            code
          }
          appointmentTitle {
            code
            name
            uid
          }
        }
      }
    }
  }
`

export { DELETE_SPECIAL_APPOINTMENT, ADD_UPDATE_STAFF_SPECIAL_APPOINTMENT }
