//REGISTE
import { gql } from "@apollo/client"

const GET_COUNTRY = gql`
  query getCountries($pagination: PaginationInput!) {
    getCountries(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
          code
          dialCode
        }
        totalCount
      }
    }
  }
`

export { GET_COUNTRY }
