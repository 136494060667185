import React from "react";
import { Route, Routes } from "react-router-dom";
import StudentSeminers from "../Views/StudentSeminers";
import Pages404 from "../../Utility/pages-404";
import ViewStudentSeminar from "../Views/ViewStudentSeminar";
const DissertationRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<StudentSeminers />} />
            <Route path="/view" element={<ViewStudentSeminar />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default DissertationRoutes;