import React, { useContext, useEffect, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, InputGroup } from "reactstrap"
import Select from "react-select"
import * as Yup from "yup"
import { CREATE_COUNTRY, CREATE_ROOM_CAPACITIES } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import showToast from "helpers/ToastHelper"
import { GET_BLOCK_ROOMS, GET_HOSTELS, GET_HOSTEL_BLOCKS, GET_ROOM_CAPACITIES } from "../Queries/AccommodationQueries"
import { RoomCapacityContext } from "./RoomCapacityList"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient, registrationGraphQLClient, accomodationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"
import { GET_PROGRAM_CATEGORIES } from "Modules/ProgramCategories/Queries/ProgramCategoryQueries"

const RoomCapacityModal = () => {
  const { updateRoomCapacityData, setUpdateRoomCapacityData } = useContext(RoomCapacityContext)
  const [create_room_capacities] = useMutation(CREATE_ROOM_CAPACITIES, {client: accomodationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const [isReserved, setReserved] = useState(false);
  const formRef = useRef()

  // selected options for program category
  const [selectedProgramCategory, setSelectedProgramCategory] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);

  // Selected options for hostel, block & rooms
  const [selectedHostelUid, setSelectedHostelUid] = useState(null)
  const [selectedBlockUid, setSelectedBlockUid] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState(null)

  // Conditionally fetched options for blocks & rooms
  const [blocks_options, setBlocksOptions] = useState([]);
  const [rooms_options, setRoomsOptions] = useState([]);

  // Options fetching queries
  const [currentPage, setCurrentPage] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const { loading: academic_years_loading, error: academic_years_error, data: academic_years_data, refetch: refe } = useQuery(GET_ACADEMICYEARS, {
    client:registrationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })
  
  const { loading: program_category_loading, error: program_category_error, data: program_category_data, refetch: program_cat_ref } = useQuery(GET_PROGRAM_CATEGORIES, {
    client:registrationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: hostels_loading, error: hostels_error, data: hostels_data, refetch: hostels_ref } = useQuery(GET_HOSTELS, {
    client:accomodationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: loading_blocks, error: error_blocks, data:data_blocks, refetch: refetch_blocks } = useQuery(GET_HOSTEL_BLOCKS, {
    client:accomodationGraphQLClient,
    skip: !(modalOpen && selectedHostelUid?.value),
    variables: {
      hostelUid: selectedHostelUid?.value,
    },
    fetchPolicy: 'cache-first'
  })

  const { loading: loading_rooms, error: error_rooms, data:data_rooms, refetch: refetch_rooms } = useQuery(GET_BLOCK_ROOMS, {
    client:accomodationGraphQLClient,
    skip: !selectedBlockUid?.value,
    variables: {
      blockUid: selectedBlockUid?.value,
    },
    fetchPolicy: 'cache-first',
  })
  
  // Onetime fetched options for academic year, program categories & hostels
  const academic_year_options = academic_years_data?.getAcademicYears.data?.items
  const program_category_options = program_category_data?.getProgramCategories.data?.items
  const hostels_options = hostels_data?.getHostels.data?.items

  // Defined options for gender
  const gender_options = [
    { label: "Male", value: "Male"},
    { label: "Female", value: "Female"},
  ];

  // Hooks to triger options quering on dependant options change
  useEffect(() => {
    if(selectedHostelUid?.value){
      const fetchData = async () => {
        try {
          await refetch_blocks();
        } catch (error) {
          console.error('Error fetching blocks:', error);
        }
      };

      fetchData();
    }
  }, [selectedHostelUid])

  // When blocks fetched
  useEffect(() => {
    const block_items = data_blocks?.getBlocksByHostel.data?.items
    if(block_items?.length){
      setBlocksOptions(block_items?.map(block => ({
        value: block.uid,
        label: block.name + ' ['+ block.code +']'
      })))

    }
  }, [data_blocks])

  // When block selected
  useEffect(() => {
    if(selectedBlockUid?.value){
      const fetchData = async () => {
        try {
          await refetch_rooms();
        } catch (error) {
          console.error('Error fetching rooms:', error);
        }
      };

      fetchData();
    }
  }, [selectedBlockUid])

  // When rooms fecthed
  useEffect(() => {
    if(data_rooms?.getRoomsByBlock.data?.items?.length){
      const roomOpts = [{
        value: 'ALL_ROOMS',
        label: 'Select All Rooms'
      }]

      const rooms = data_rooms?.getRoomsByBlock.data?.items?.map(room => ({
        value: room.uid,
        label: room.code,
      }))

      if(updateRoomCapacityData?.room){
        setSelectedRooms([{
          value: updateRoomCapacityData?.room.uid,
          label: updateRoomCapacityData?.room.code
        }])
      }

      roomOpts.push(...rooms)

      setRoomsOptions(roomOpts)

    }
  }, [data_rooms])
  
  // A callback when modal opened to initialize values of selected RoomCapacity
  const handleModalOpened = () => {
    // Reset previous options
    setRoomsOptions([])
    setBlocksOptions([])

    // For academic year
    if(updateRoomCapacityData?.academicYear && academic_year_options?.length){
      const ac = academic_year_options.filter((ac) => ac.name == updateRoomCapacityData.academicYear)
      
      if(ac?.length){
        setSelectedYear({
          value: ac[0].name,
          label: ac[0].name
        })
      }
    } else {
      setSelectedYear(null)
    }
    
    // For program capacity
    if(updateRoomCapacityData?.programCategory && program_category_options?.length){
      const pc = program_category_options.filter((pc) => pc.shortName == updateRoomCapacityData.programCategory)
      
      if(pc?.length){
        setSelectedProgramCategory({
          value: pc[0].shortName,
          label: pc[0].name + ' ['+ pc[0].shortName +']'
        })
      }
    } else {
      setSelectedProgramCategory(null)
    }
    
    // For gender
    if(updateRoomCapacityData?.gender){
      const gender = gender_options.filter((gender) => gender.value == updateRoomCapacityData.gender)
      
      if(gender?.length){
        setSelectedGender(gender[0])
      }
    } else {
      setSelectedGender(null)
    }

    // For hostel
    if(updateRoomCapacityData?.room?.block?.hostel && hostels_options?.length){
      const hostel = hostels_options.filter((hostel) => hostel.uid == updateRoomCapacityData.room.block.hostel?.uid)
      
      if(hostel?.length){
        setSelectedHostelUid({
          value: hostel[0].uid,
          label: hostel[0].name + ' ['+ hostel[0].code +']'
        })
      }
    } else {
      setSelectedHostelUid(null)
    }

    // Set rooms
    if(updateRoomCapacityData?.room?.uid){
      validation.setFieldValue("rooms", [updateRoomCapacityData?.room.uid])
    }
    
    // Set isReserved
    if(updateRoomCapacityData){
      validation.setFieldValue("isReserved", updateRoomCapacityData.isReserved)
      setReserved(updateRoomCapacityData.isReserved)
    }
    
  }

  // A callback to reset form
  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      rooms: updateRoomCapacityData ? updateRoomCapacityData.rooms : [],
      academicYear: updateRoomCapacityData ? updateRoomCapacityData.academicYear : "",
      programCategory: updateRoomCapacityData ? updateRoomCapacityData.programCategory : "",
      gender: updateRoomCapacityData ? updateRoomCapacityData.gender : "",
      isReserved: updateRoomCapacityData ? updateRoomCapacityData.isReserved : false,
      capacity: updateRoomCapacityData ? updateRoomCapacityData.capacity : 4
    },

    validationSchema: Yup.object().shape({
      // rooms: Yup.array().length().required("At least one room is required"),
      academicYear: Yup.string().required("Academic Year is required"),
      programCategory: Yup.string().required("Program Category is required"),
      gender: Yup.string().required("Gender is required"),
      capacity: Yup.string().required("Room Capacity is required"),
    }),

    onSubmit: (values, {setSubmitting}) => {
      create_room_capacities({
        variables: {
          input: { ...values, uid: updateRoomCapacityData?.uid },
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_ROOM_CAPACITIES,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerRoomCapacities }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (registerRoomCapacities.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateRoomCapacityData(false) // Close the modal
          } else {
            setModalOpen(true)
            // setUpdateRoomCapacityData(true) // Close the modal
            setSubmitting(false)
          }
          showToast(
            registerRoomCapacities.message,
            registerRoomCapacities.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      {/* <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="fas fa-bolt me-1" />
        Generate
      </Button> */}

      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        Add Room Capacity
      </Button>
      <Modal
        isOpen={modalOpen || !!updateRoomCapacityData}
        onOpened={handleModalOpened}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateRoomCapacityData ? "Edit Room Capacity" : "Add New Room Capacity"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            
            <div className={!updateRoomCapacityData? 'row' : ''}>
              <div className={!updateRoomCapacityData? 'col-6' : ''}>

                {updateRoomCapacityData?
                  <div className="mb-1">
                    <label htmlFor="rooms" className="col-form-label">
                      Room Code <span className="text-danger">*</span>:
                    </label>
                    <Input
                      type="number"
                      step="1"
                      name="rooms"
                      value={updateRoomCapacityData?.room?.uid}
                      placeholder={updateRoomCapacityData?.room?.code}
                      disabled={true}
                      className="form-control"
                      required
                    />
                  </div>
                  : ''
                }

                <div className="mb-1">
                  <label htmlFor="academicYear" className="col-form-label">
                    Academic Year <span className="text-danger">*</span>:
                  </label>
                
                  <Select
                    id="academicYear"
                    name="academicYear"
                    placeholder="Select Academic Year"
                    value={selectedYear}
                    required
                    onChange={selectedOption => {
                      setSelectedYear(selectedOption)
                      validation.setFieldValue("academicYear", selectedOption.value)
                    }}
                    options={academic_year_options?.map(year => ({
                      value: year.name,
                      label: year.name,
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.academicYear && validation.errors.academicYear ? (
                    <FormFeedback type="invalid">
                      {validation.errors.academicYear}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-1">
                  <label htmlFor="programCategory" className="col-form-label">
                    Program Category <span className="text-danger">*</span>:
                  </label>
                
                  <Select
                    id="programCategory"
                    name="programCategory"
                    required
                    placeholder="Select Program Category"
                    value={selectedProgramCategory}
                    onChange={selectedOption => {
                      setSelectedProgramCategory(selectedOption)
                      validation.setFieldValue("programCategory", selectedOption.value)
                    }}
                    options={program_category_options?.map(pc => ({
                      value: pc.shortName,
                      label: pc.name + ' ['+ pc.shortName +']',
                    }))}

                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                  />
                  {validation.touched.programCategory && validation.errors.programCategory ? (
                    <FormFeedback type="invalid">
                      {validation.errors.programCategory}
                    </FormFeedback>
                  ) : null}
                </div>              

                <div className="row">
                  <div className="col-6 mb-1">
                    <label htmlFor="gender" className="col-form-label">
                      Gender <span className="text-danger">*</span>:
                    </label>
                    <Select
                      id="gender"
                      name="gender"
                      required
                      placeholder="Select Gender"
                      value={selectedGender}
                      onChange={selectedOption => {
                        setSelectedGender(selectedOption)
                        validation.setFieldValue("gender", selectedOption.value)
                      }}
                      options={gender_options}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                    />
                    {validation.touched.gender && validation.errors.gender ? (
                      <FormFeedback type="invalid">
                        {validation.errors.gender}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="col-6 mb-1">
                    <label htmlFor="capacity" className="col-form-label">
                      Room Capacity <span className="text-danger">*</span>:
                    </label>
                    <Input
                      type="number"
                      step="1"
                      name="capacity"
                      className="form-control"
                      id="capacity"
                      required
                      placeholder="Enter Room Capacity"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.capacity || ""}
                      invalid={
                        validation.touched.capacity && validation.errors.capacity
                          ? true
                          : false
                      }
                    />
                    {validation.touched.capacity && validation.errors.capacity ? (
                      <FormFeedback type="invalid">
                        {validation.errors.capacity}
                      </FormFeedback>
                    ) : null}
                  </div>
                </div> 

                {
                  !updateRoomCapacityData?
                    <div className="mb-1">
                      <label htmlFor="hostel" className="col-form-label">
                        Hostel <span className="text-danger">*</span>:
                      </label>
                      <Select
                        id="hostel"
                        placeholder="Select Hostel"
                        value={selectedHostelUid}
                        onChange={selectedOption => {
                          setBlocksOptions(null)
                          setRoomsOptions(null)
                          setSelectedBlockUid(null)
                          setSelectedRooms(null)
                          setSelectedHostelUid(selectedOption)
                        }}
                        options={hostels_options?.map(hostel => ({
                          value: hostel.uid,
                          label: hostel.name + ' ['+ hostel.code +']',
                        }))}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />
                      {validation.touched.hostel && validation.errors.hostel ? (
                        <FormFeedback type="invalid">
                          {validation.errors.hostel}
                        </FormFeedback>
                      ) : null}
                    </div>
                  : ''
                }

                {
                  !updateRoomCapacityData?
                    <div className="mb-1">
                    {/* <label htmlFor="block" className="col-form-label">
                      Block <span className="text-danger">*</span>:
                      {loading_blocks? <i className="fas fa-spinner fa-spin"></i> : ''}
                    </label> */}

                      <label htmlFor="block" className="col-form-label w-100">
                        Block <span className="text-danger">*</span>:
                        {loading_blocks? <i className="fas fa-spinner fa-spin" style={{float: 'right'}}></i> : ''}
                      </label>
                      
                      <Select
                        id="block"
                        placeholder="Select Block"
                        isDisabled={!blocks_options?.length}// || updateRoomCapacityData}
                        value={selectedBlockUid}
                        onChange={selectedOption => {
                          setRoomsOptions([])
                          setSelectedRooms([])
                          setSelectedBlockUid(selectedOption)
                        }}
                        options={blocks_options}
                        className="select2-selection"
                        styles={{
                          menu: base => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                      />
                      {validation.touched.block && validation.errors.block ? (
                        <FormFeedback type="invalid">
                          {validation.errors.block}
                        </FormFeedback>
                      ) : null}

                    </div>
                  : ''
                }
              </div>
              
              {
                !updateRoomCapacityData?
                <div className="col-6">
                  <div className="mb-1">
                    <label htmlFor="rooms" className="col-form-label w-100">
                      Rooms <span className="text-danger">*</span>:
                      {loading_rooms? <i className="fas fa-spinner fa-spin" style={{float: 'right'}}></i> : ''}
                    </label>
                    <Select
                      id="rooms"
                      name="rooms"
                      placeholder="Select Room"
                      isMulti
                      required
                      isDisabled={!rooms_options?.length || updateRoomCapacityData}
                      value={
                        updateRoomCapacityData?.room? 
                        [{
                          value: updateRoomCapacityData?.room.uid,
                          label: updateRoomCapacityData?.room.code
                        }] : selectedRooms
                      }
                      onChange={selectedOptions => {
                        let selectedAll = false
                        const selected = []
                        const selectedValues  = selectedOptions.map(
                          (option) => {
                            selected.push({
                              value: option.value,
                              label: option.label
                            })

                            selectedAll |= option.value == 'ALL_ROOMS'

                            return option.value
                          }
                        )

                        if (selectedAll) {
                          // Select All rooms is selected
                          const allRooms = rooms_options.filter((option) => option.value !== "ALL_ROOMS")
                          setSelectedRooms(allRooms);
                          validation.setFieldValue("rooms", allRooms.map((option) => option.value ))
                        } else {
                          setSelectedRooms(selected || [])
                          validation.setFieldValue("rooms", selectedValues || [])
                        }
                      }}
                      options={rooms_options}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                    />
                    {validation.touched.roomUid && validation.errors.roomUid ? (
                      <FormFeedback type="invalid">
                        {validation.errors.roomUid}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="form-check form-switch mt-3" >
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="isReserved"
                      onClick={e => {
                        validation.setFieldValue('isReserved', !isReserved)
                        setReserved(!isReserved)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isReserved"
                    >
                      Reserve selected rooms for manual allocation
                    </label>
                  </div>
                </div>
                : ''
              }

              {updateRoomCapacityData?
                <div className="mt-2">
                  <div className="form-check form-switch mt-3" >
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id="isReserved"
                      onClick={e => {
                        validation.setFieldValue('isReserved', !isReserved)
                        setReserved(!isReserved)
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isReserved"
                    >
                      Reserve this room
                    </label>
                  </div>  
                </div>
                : ''
              }
                
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateRoomCapacityData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default RoomCapacityModal
