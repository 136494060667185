import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, InputGroup, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";

import {useFormik} from "formik";
import * as Yup from "yup";
import {DissertationPapersContext} from "./DissertationPapers";
import {useMutation} from "@apollo/client";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import {GET_STUDENT_MANU_SCRIPT} from "../Queries/StudentDissertationQueries";
import {CREATE_STUDENT_DISSERTATION_PAPER} from "../Mutations/StudentDissertationMutations";
import showToast from "../../../../../helpers/ToastHelper";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";
import Select from "react-select";
import Flatpickr from "react-flatpickr";

const DissertationPaperModel = props => {
    const {paperData,setPaperData} = useContext(DissertationPapersContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [register] = useMutation(CREATE_STUDENT_DISSERTATION_PAPER)
    const  {student} = props
// Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            studentUid:student?.uid,
            description:paperData?.description,
            title:paperData?.title,
            publicationDate:paperData?.publicationDate,
            publicationStatus:paperData?.publicationStatus
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Title is required"),
            description: Yup.string().required("Description is required"),
            publicationStatus:Yup.string().required("Publication Status is required"),
            publicationDate: Yup.string().required("Publication Date is required"),
        }),
        onSubmit: (values,{setSubmitting,resetForm}) => {
            register({
                client: registrationGraphQLClient,
                variables: {
                    inputs: [{ ...values,uid:paperData?.uid }],
                },
                refetchQueries: [
                    {
                        query: GET_STUDENT_MANU_SCRIPT,
                        variables:{
                            studentUid: student?.uid,
                        }
                    },
                ],
                onCompleted: ({ registerStudentManuscript }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (registerStudentManuscript.code === 8000) {
                        setPaperData(false)
                        resetForm()
                        setSubmitting(false)
                        setModalOpen(false)
                    } else {
                        // showToast(
                        //     registerStudentManuscript.message,
                        //     registerStudentManuscript.code === 8000 ? "success" : "error"
                        // )
                        setSubmitting(false)
                    }
                },
                onError: error => {
                    setSubmitting(false)
                    // Handle errors
                    console.error("Mutation error:", error)
                },
            })
            // navigate('/Students/Payments/queries')
        }
    });

    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Add New Paper
            </Button>
            <Modal
                isOpen={modalOpen || !!paperData}
                backdrop={"static"}
                size={'lg'}
                id="staticBackdrop"
                // size="lg"
            >

                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!paperData
                            ? `Edit Paper`
                            : `Add New Paper`}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            setPaperData(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <div className="modal-body">
                        <Row className="row mb-3">
                            <Col lg={12}>
                                <label htmlFor="name" className="col-form-label">
                                    Paper Title
                                </label>
                                <Input
                                    type="text"
                                    name="title"
                                    className="form-control"
                                    id="firstname"
                                    placeholder={`Enter Paper Title`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={
                                        validation.values.title
                                    }
                                    invalid={!!(validation.touched.title && validation.errors.title)}
                                />
                                {validation.touched.title && validation.errors.title ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.title}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col lg={12}>
                                <Label className="form-label">Paper  Abstract</Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={paperData?.description ? paperData?.description:""}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        validation.setFieldValue('description',editor.getData())
                                    }}
                                />

                                <Input
                                    type="hidden"
                                    name="description"
                                    className="form-control"
                                    id="description"
                                    rows={4}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description}
                                    invalid={!!(validation.touched.description && validation.errors.description)}
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.description}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col lg={6}>
                                <Label>Publication Status</Label>
                                <Select
                                    // value={selectedMulti2}
                                    onChange={e => {
                                        validation.setFieldValue("publicationStatus", e.value)
                                    }}
                                    className="select2-selection"
                                    styles={{
                                        menu: base => ({
                                            ...base,
                                            position: "absolute",
                                            zIndex: 9999
                                        })
                                    }}

                                    options={[
                                        {
                                            label:"Published",
                                            value:'published'
                                        },
                                        {
                                            label:"Publishable",
                                            value:'publishable'
                                        },
                                        {
                                            label:"Accepted",
                                            value:'accepted'
                                        }
                                    ]}
                                    defaultValue={paperData?.publicationStatus}
                                    // inputValue={}
                                    defaultInputValue={paperData?.publicationStatus}
                                />
                                <Input
                                    name="publicationStatus"
                                    placeholder=""
                                    type="hidden"
                                    rows={3}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.publicationStatus}
                                    invalid={
                                        !!(
                                            validation.touched.publicationStatus &&
                                            validation.errors.publicationStatus
                                        )
                                    }
                                />
                                {validation.touched.publicationStatus &&
                                validation.errors.publicationStatus ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.publicationStatus}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                            <Col lg={6}>
                                <Label>Publication Date</Label>
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        // name="seminarDate"
                                        value={validation.values.publicationDate}
                                        placeholder="dd M,yyyy"
                                        options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                        }}
                                        onChange={date => validation.setFieldValue('publicationDate', date[0])}

                                    />
                                </InputGroup>
                                <Input
                                    name="publicationDate"
                                    placeholder="Select"
                                    type="hidden"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.publicationDate || ""}
                                    invalid={
                                        !!(validation.touched.publicationDate && validation.errors.publicationDate)
                                    }
                                />
                                {validation.touched.publicationDate && validation.errors.publicationDate ? (
                                    <FormFeedback type="invalid">{validation.errors.publicationDate}</FormFeedback>
                                ) : null}
                            </Col>
                        </Row>

                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setPaperData(false)
                                validation.resetForm() // Reset the form
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
DissertationPaperModel.propTypes = {
    student: PropTypes.any.isRequired
}
export default DissertationPaperModel;