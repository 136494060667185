import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_REGION, GET_REGIONS_BY_COUNTRY } from "../Queries/RegionQueries"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { RegionContext } from "./RegionList"
import Select from "react-select"
import { GET_COUNTRY } from "Modules/Country/Queries/CountryQueries"
import { CREATE_REGION } from "../Mutations/RegionMutations"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const RegionModal = (props) => {
  const { updateRegionData, setUpdateRegionData } =
    useContext(RegionContext)
  const [region] = useMutation(CREATE_REGION, {client:uaaGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  let  regionToUpdate = updateRegionData? updateRegionData[0] : "";

  let country = updateRegionData ? updateRegionData[1] : "";

  let countryCode = props.countryInfo;



  // console.log("Data ndwiii", updateRegionData)

  const { loading, error, data } = useQuery(GET_COUNTRY, {
    client:uaaGraphQLClient,
    skip: !modalOpen,
    variables: {
      pagination: paginationHelper,
    },
  })

  if (error) return showToast("Failed to fetch Countries", "error")
  const units = data?.getCountries?.data.items
  // console.log("Units", units)
  //setUnits(data?.getUnits?.data.items)

  const query = GET_REGION
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: regionToUpdate ? regionToUpdate.name : "",
      code: regionToUpdate ? regionToUpdate.code : "",
      countryUid: country && country.country ? country.country : countryCode,
    },

    validationSchema: Yup.object().shape({
      //   countryUid: Yup.string().required("College is required"),
      name: Yup.string().required("Region name is required"),
      code: Yup.string().required("Region short code is required"),
      //   description: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values) => {
      
      console.log("RegionForm", values)

      region({
        variables: {
          inputs: [{ ...values, uid: regionToUpdate?.uid }],
        },
        client: uaaGraphQLClient,
        refetchQueries: [
          {
            query: GET_REGIONS_BY_COUNTRY,
            variables: {
              countryUid: countryCode,
            },
          },
        ],
        onCompleted: ({ registerRegions }) => {
          //updateFunction(client.cache, { data: { registerRegion } });
          //alert('Here--');
          if (registerRegions.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateRegionData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateRegionData(true) // Close the modal
          }
          showToast(
            registerRegions.message,
            registerRegions.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          validation.resetForm()
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Region
      </Button>
      <Modal
        isOpen={modalOpen || !!updateRegionData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateRegionData ? "Edit Region" : "Add New Region"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateRegionData(false)
              resetForm() // Reset the form
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">

            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || ""
                }
                invalid={
                  !!(validation.touched.name && validation.errors.name)
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="code"
                placeholder="Short Code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.code || regionToUpdate?.code || ""
                }
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateRegionData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default RegionModal
