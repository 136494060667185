import React from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import UserManualIcon from "assets/images/svg/application-documents.png"


import "assets/scss/services.scss"

const UserManuals = () => {
  const [menu, setMenu] = React.useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block d-none d-md-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          User Manuals
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem>
            <img className="services-image-icon" src={UserManualIcon} alt="" />
            ASIMS USER MANUAL
          </DropdownItem>
          <DropdownItem>
            <img className="services-image-icon" src={UserManualIcon} alt=""/>
            ACCOMMODATION USER MANUAL
          </DropdownItem>
        </DropdownMenu>


      </Dropdown>
    </React.Fragment>
  )
}

export default UserManuals
