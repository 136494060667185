import React from "react";
import { Navigate, Route, Routes } from "react-router-dom"
import Pages404 from "../../Utility/pages-404"
// import RequestChangeProgramForm from "../Views/Application/RequestChangeProgramForm";
import StudentChangeProgram from "../Views/Application/StudentChangeProgram";
import StudentStudyPostponement from "../Views/Application/StudyPostponement/StudentStudyPostponement";
import StudentExamPostponement from "../Views/Application/ExamPostponement/StudentExamPostponement";
const StudentApplicationRoutes = () => {
    // console.log(history)
    return (
        <Routes>
            <Route path="/change_program"  element={<StudentChangeProgram />} />
            <Route path="/postponement"  element={<StudentStudyPostponement/>} />
            <Route path="/exam_postponement"  element={<StudentExamPostponement/>} />
            <Route path="*" element={<Pages404 />} />
        </Routes>
    );
};
export default StudentApplicationRoutes;
