//REGISTE
import { gql } from '@apollo/client';

const GET_SEMINAR_TYPE = gql`
query GetSeminarTypes ($pagination: PaginationInput!){
  getSeminarTypes (pagination:$pagination) {
    status
    code
    message
    data {
      items {
        uid
        description
        name
        rank
      }
      totalCount
    }
  }
}
`;

const GET_STUDENT_MANU_SCRIPT = gql`
query GetStudentManuscriptByStudentUid ($studentUid: String!){
  getStudentManuscriptByStudentUid (studentUid: $studentUid){
    status
    code
    message
    data {
      uid
      title
      publicationDate
      description
      status
      publicationStatus
    }
  }
}`;

const GET_STUDENT_INTENTION_TO_SUBMIT = gql`
query GetIntentionToSubmitByStudentUid ($studentUid: String!){
  getIntentionToSubmitByStudentUid (studentUid: $studentUid){
    status
    code
    message
    data {
        uid
        studentUid
        title
        submissionDate
        plagiarismReport
        plagiarismStatus
        plagiarismPercentage
        status
    }
  }
}`;

const GET_STUDENT_SEMINAR_TYPE = gql`
query getStudentSeminarsByStudentUid ($inputs: StudentSeminarsInputNode!){
    getStudentSeminarsByStudentUid(inputs: $inputs){
        status
        code
        message
        data{
          uid
          title
          description
          studentUid
          seminarDate
          isPass
          seminarMarks
          status
          seminarTypes{
            uid
            description
            name
            rank
          }
          
        }
  }
}
`;

export { GET_SEMINAR_TYPE,GET_STUDENT_SEMINAR_TYPE,GET_STUDENT_MANU_SCRIPT,GET_STUDENT_INTENTION_TO_SUBMIT }