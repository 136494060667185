import React from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import "assets/scss/services.scss"
import {ServicesCards} from "../../EsbDashboardLayout/ESBServices";
import PropTypes from "prop-types";
import {useNavigate} from "react-router";
import StudentIdIcon from "../../../assets/images/svg/id.png";

const Services = (props) => {
  const {activePath, pathName} = props
  const [menu, setMenu] = React.useState(false)
  const navigate = useNavigate()
  const link = ServicesCards.filter(card => card.link.startsWith(`/${activePath}/`));
  // console.log(link)
  const filteredLinks = link.length > 0 ? link[0]: {
    image: StudentIdIcon,
    link: pathName,
    title: activePath,
    shortName: activePath
  }

  // console.log(filteredLinks)
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img className="services-image-icon-sm"  src={filteredLinks.image} alt="" /> {filteredLinks.shortName} SERVICE
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        {/*<DropdownMenu className="dropdown-menu-end">*/}
        {/*  {ServicesCards.map((card, index) => {*/}
        {/*    if (card.authority && !hasAuthority(card.authority)) {*/}
        {/*      return null;*/}
        {/*    }*/}
        {/*    return (*/}
        {/*        <DropdownItem style={{*/}
        {/*          color: filteredLinks.link === card.link ? "#005000": "",*/}
        {/*          backgroundColor: filteredLinks.link === card.link ? "#D5E3D5":""*/}
        {/*        }} key={index+1} onClick={() => {*/}
        {/*          navigate(card.link)*/}
        {/*        }}>*/}
        {/*          <img className="services-image-icon"  src={card.image} alt="" />*/}
        {/*          <strong>{card.shortName}</strong>*/}
        {/*        </DropdownItem>*/}
        {/*    );*/}
        {/*  })}*/}

        {/*</DropdownMenu>*/}


      </Dropdown>
    </React.Fragment>
  )
}
Services.propTypes = {
  activePath: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired
}
export default Services
