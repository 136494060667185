import React, { createContext, useEffect, useState } from "react"
import {Link, useNavigate} from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody, CardTitle, CardSubtitle, Button
} from "reactstrap"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import LayoutHelper from "helpers/LayoutHelper"
import {limit} from "../../../../../helpers/UrlHelper";
import {useQuery} from "@apollo/client";
import {GET_SEMINAR_TYPE, GET_STUDENT_SEMINAR_TYPE} from "../Queries/StudentDissertationQueries";
import {registrationGraphQLClient} from "../../../../Authentication/ApolloClient";
import PlaceHolderLoader from "../../../../../helpers/PlaceHolderLoader";
import {LogedInStudent} from "../../../LogedInStudent";
import {GET_PROGRAM} from "../../../../Programs/Queries/ProgramQuaries";
import AlertHelper from "../../../../../helpers/AlertHelper";
import {GET_THESIS_SETTING_BY_CATEGORY} from "../../../../Lookups/ThesisSettng/Queries/ThesisSettingQueries";
const breadcrumbItem = [
  {
    title: "Dissertation",
    path: "",
    isActive: true,
  },
]

export const DissertationContext = createContext()
const Dissertation = () => {
  const [dissertation, setDissertationData] = useState(null),
    [pageSize, setPageSize] = useState(limit),
    [currentPage, setCurrentPage] = useState(0),
    navigate = useNavigate(), pagination = { offset: 0, limit: limit, search: null }

  const { loading, error, data, refetch } = useQuery(GET_SEMINAR_TYPE, {
    client: registrationGraphQLClient,
    variables: {
      pagination,
    },
  })



  const {student} = LogedInStudent()

  const { loading:studentSemLoading, error:studentSemError, data:studentSemData } = useQuery(GET_STUDENT_SEMINAR_TYPE, {
    client: registrationGraphQLClient,
    skip:!student?.uid,
    variables: {
      inputs:{
        studentUid:student?.uid
      },
    },
  })


  const { loading:programLoad, error:programError, data:programData } = useQuery(GET_PROGRAM, {
    client:registrationGraphQLClient,
    skip: !student?.programmeUid,
    variables: {
      uid: student?.programmeUid,
    },
  })


  const program = programData?.getProgram?.data

  const { loading:settingsLoad, error:settingsError, data:settingData } = useQuery(GET_THESIS_SETTING_BY_CATEGORY, {
    client:registrationGraphQLClient,
    skip: !program?.programCategory?.uid,
    variables: {
      categoryUid: program?.programCategory?.uid,
    },
  })



  if (loading || programLoad || !program?.programCategory?.uid || !student?.programmeUid || settingsLoad) {
    return <PlaceHolderLoader rows={5} type="rows" columSize={12} />
  }
  const studentSeminarType = studentSemData?.getStudentSeminarsByStudentUid?.data
  const results = data?.getSeminarTypes.data?.items
  const settings = settingData?.getIntentionToSubmitRequirementByCategory.data
  const openBtn = (status) => {
    if(studentSeminarType?.length > 0){
      let c = studentSeminarType?.filter((ar) => ar?.seminarTypes?.rank === status & ar?.isPass === true);
      if(c.length > 0) return true
      return false
    }else return false
  }
  if(!settings) return (
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Dissertation">
      <Card>
        <CardBody>
          <AlertHelper type={'danger'} message={`${program?.programCategory?.name} level is not involved in this page, if its a problem please contact your head of department for mor help`} />
        </CardBody>
      </Card>
      </LayoutHelper>)

  const setting = settings[0]

  // console.log(setting)
  // if(program.some(pro => pro?.programCategory?.name === 'Masters' || '') )
  // if (allowedCat.every((key) => program?.some((pro) => program?.programCategory?.name === key)))
  // console.log(program?.programCategory?.name)
  return (
    <DissertationContext.Provider value={{ dissertation, setDissertationData }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Dissertation">
        {results &&
          <>
            <h4>Seminars</h4>
            <Row>
              {results?.map((st, index) => (
                  <Col key={st.uid} lg={4} sm={12} md={4}>
                    <Card>
                      <CardBody>
                        <CardTitle>{st.name}</CardTitle>
                        <CardSubtitle>{st.description}</CardSubtitle>
                        {
                          index === 0 ?
                              <div>
                                <br/>
                                <button  onClick={() => {
                                  navigate("/student/dissertation/view", {
                                    state: { type: st,student:student }
                                  })
                                }} className="btn btn-primary btn-sm">View</button>
                              </div>
                              :
                              <div>
                                {openBtn(st.rank-1) ?
                                    <div>
                                      <br/>
                                      <button  onClick={() => {
                                        navigate("/student/dissertation/view", {
                                          state: { type: st,student:student }
                                        })
                                      }} className="btn btn-primary btn-sm">View</button>
                                    </div>
                                    :
                                    <>
                                      <span className="placeholder w-75"></span>
                                      <br/>
                                      <Link
                                          to="#"
                                          className="btn btn-primary btn-sm disabled placeholder col-2"
                                      ></Link>
                                    </>}
                              </div>}
                      </CardBody>
                    </Card>
                  </Col>
              ))}


            </Row>
          </>
        }

        <Row>
          <Col lg={4}>
            <Card>
              <CardBody>
                <CardTitle>Papers/Manual Scripts</CardTitle>
                <CardSubtitle>Open to view attached manual scripts/papers</CardSubtitle>
                <div>
                  <br/>
                  <button  onClick={() => {
                    navigate("/student/dissertation/papers", {
                      state: { student:student }
                    })
                  }} className="btn btn-info btn-sm">View</button>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <CardTitle>Intention To Submit </CardTitle>
                <CardSubtitle>Open And View submitted thesis</CardSubtitle>
                <div>
                  <br/>
                  <button  onClick={() => {
                    navigate("/student/dissertation/thesis", {
                      state: { student:student,seminars:studentSeminarType,program:program,setting:setting }
                    })
                  }} className="btn btn-info btn-sm">View</button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </DissertationContext.Provider>
  )
}

export default Dissertation
