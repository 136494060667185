//REGISTE
import { gql } from "@apollo/client"
import { forwardStatus } from 'helpers/ForwardStatus';

const GET_ = gql`
  query GetUsers {
    getUsers {
      data {
        id
        name
      }
      status
      code
      message
    }
  }
`

const GET_INSTRUCTOR_ALLOCATED_COURSES = gql`
  query GetStaffCourseAllocation($inputs: StaffAllocationInputNode!) {
    getStaffCourseAllocation(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        programCourseUid
        programCourse
        programCourse {
          course {
            uid
            code
            name
          }
          courseCategory {
            uid
            name
          }
          programSemester {
            semester
            program {
              uid
              code
              name
            }
            academicYear {
              name
            }
          }
        }
      }
    }
  }
`

const GET_INSTRUCTOR_COURSE_ALLOCATION = gql`
  query GetInstructorSemesterCourseAllocation(
    $inputs: InstructorSemesterCourseAllocationInputNode!
  ) {
    getInstructorSemesterCourseAllocation(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        forwardStatus
        course {
          uid
          name
          code
        }
        programSemester {
          studyYear
          academicYear {
            name
          }
          semester
          program {
            name
          }
        }
      }
    }
  }
`

const GET_STUDENT_COURSE_ALLOCATIONS = gql`
  query GetAllocationStudents(
    $allocationUid: String!
    $assessmentNumber: Int!
    $examCategoryId: Int!
    $outOff: Int!
  ) {
    getAllocationStudents(
      allocationUid: $allocationUid
      assessmentNumber: $assessmentNumber
      examCategoryId: $examCategoryId
      outOff: $outOff
    ) {
      status
      message
      code
      data {
        registrationNumber
        fullName
        uid
        score
      }
    }
  }
`

const GET_COURSE_ALLOCATION = gql`
  query GetCourseAllocation($uid: String!) {
    getCourseAllocation(uid: $uid) {
      status
      code
      message
      data {
        programCourse {
          forwardStatus
          id
          uid
          course {
            uid
            moodleId
            name
            code
            name
            offered
          }
          programSemester {
            semester
            academicYear {
              name
              startDate
              endDate
            }
            studyYear
          }
          programCourseAssessments {
            minimumExams
            canExceedMinimumBy
            maximumScore
            examCategory {
              uid
              id
              name
              code
            }
          }
        }
      }
    }
  }
`
const GET_STAFF_COURSE_ALLOCATION_BY_ACADEMIC_YEAR_SEMESTER = gql`
  query GetStaffCourseAllocationByAcademicYearSemester(
    $inputs: StaffCourseAllocationBySemesterInputs!
  ) {
    getStaffCourseAllocationByAcademicYearSemesters(inputs: $inputs) {
      status
      code
      message
      data {
        uid
        staffUid
        programCourse {
          uid
          moodleId
          course {
            uid
            name
            code
            name
            offered
            moodleId
          }

          programSemester {
            program {
              code
              name
              shortName
            }

            semester
            academicYear {
              name
              startDate
              endDate
            }
            studyYear
          }
        }
      }
    }
  }
`

const GET_COURSE_MOODLE_URL = gql`
  query getMoodleUrl($inputs: MoodleGetUrlInput!) {
    getMoodleUrl(inputs: $inputs) {
      message
      status
      code
      data
    }
  }
`

const DELETE_STUDENT_RESULT_SUMMARY = gql`
  mutation removeExamResultSummary($input: DeleteResultDtoInput!) {
    removeExamResultSummary(input: $input) {
      message
      code
      status
    }
  }
`

export {
  GET_INSTRUCTOR_ALLOCATED_COURSES,
  GET_STUDENT_COURSE_ALLOCATIONS,
  GET_COURSE_ALLOCATION,
  GET_STAFF_COURSE_ALLOCATION_BY_ACADEMIC_YEAR_SEMESTER,
  GET_COURSE_MOODLE_URL,
  DELETE_STUDENT_RESULT_SUMMARY,
  GET_INSTRUCTOR_COURSE_ALLOCATION,
}
