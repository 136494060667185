import {useMutation, useQuery} from "@apollo/client";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {GET_STUDENT} from "../../Users/Queries/UserQuery";
import {useFormik} from "formik";
import {GET_CONTROL_NUMBER, GET_FEE_STRUCTURE} from "../../Students/Queries/Payment/StudentPaymentQueries";
import showToast from "../../../helpers/ToastHelper";
import {LoaderPage} from "../../../helpers/LoaderPage";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Card, CardBody, CardSubtitle, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {GET_MARITAL_STATUSES} from "../../MaritalStatus/Queries/MaritalStatusesQueries";
import {paginationHelper} from "../../../helpers/Functions/GraphqlUpdateFunction";
import {UPDATE_STUDENT_PROFILE} from "../../Students/Mutations/UpdateStudentMutation";

const breadcrumbItem = [
    {
        title: "Self registration",
        path: "",
        isActive: true,
    },
]

const SelfRegistrationMyInformation = () => {
    const navigate = useNavigate()
    const {
        loading: userLoading,
        error: userError,
        data: userDetails,
        refetch,
    } = useQuery(GET_STUDENT, {
        client: uaaGraphQLClient,
        variables: {
            username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
        },
        fetchPolicy: "network-only",
    })


    const user = userDetails?.getUserByUsername?.data

    const {
        loading: maritalLoading,
        error: maritalError,
        data: maritalData,
    } = useQuery(GET_MARITAL_STATUSES, {
        client: uaaGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })

    const maritalStatus = maritalData?.getMaritalStatuses?.data?.items

    const [update] = useMutation(UPDATE_STUDENT_PROFILE, {
        client: uaaGraphQLClient,
    })
    const {
        loading: loadingFeeStructureControlNumbers,
        error: errorFeeStructure,
        data: feeStructureControlNumber,
        refetch: refetchControlNUmbers,
    } = useQuery(GET_CONTROL_NUMBER, {
        client: registrationGraphQLClient,
        skip:
            !user?.student?.registrationNumber,
        variables: {
            registrationNumber: user?.student?.registrationNumber,
        },
        fetchPolicy: "network-only",
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            isDisabled: user ? user?.isDisabled : null,
            nhifNumber: user?.student?.nhifNumber,
            firstName: user ? user?.firstName : "",
            middleName: user ? user?.middleName : "",
            lastName: user ? user?.lastName : "",
            email: user ? user?.email : "",
            nin: user ? user?.nin : "",
            phoneNumber: user ? user?.phoneNumber : "",
            dateOfBirth: user ? user?.dateOfBirth : "",
            placeOfBirth: user ? user?.placeOfBirth : "",
            disability: user ? user?.disability : "",
            maritalStatusUid: user
                ? user?.maritalStatus?.uid
                : "",
        },

        validationSchema: Yup.object().shape({
            maritalStatusUid: Yup.string().required("Marital status is required"),
            phoneNumber: Yup.string()
                .min(10)
                .max(13)
                .nullable()
                .required("Please Enter Valid Phone Number"),
            nin: Yup.string().nullable().required("NIDA is required").max(20).min(20),
            dateOfBirth: Yup.date().nullable().required("Date of Birth is required"),
            placeOfBirth: Yup.string()
                .nullable()
                .required("Place of Birth is required"),
            isDisabled: Yup.string().nullable().required("Please choose option"),
        }),

        onSubmit: (values, { resetForm, setSubmitting }) => {
            // console.log(values.nhifNumber)
            const postData = {
                disability: studentDissability ? values.disability : null,
                nin: values.nin,
                nhifNumber: values.nhifNumber,
                phoneNumber: values.phoneNumber,
                dateOfBirth: values.dateOfBirth,
                placeOfBirth: values.placeOfBirth,
                maritalStatusUid: values.maritalStatusUid,
                isDisabled: values.isDisabled,
            }
            update({
                client: uaaGraphQLClient,
                variables: {
                    input: { ...postData, userUid: user?.uid },
                },
                onCompleted: ({ updateStudentProfile }) => {

                    showToast(
                        updateStudentProfile.message,
                        updateStudentProfile.code === 8000 ? "success" : "error"
                    )
                    setSubmitting(false)
                    if (updateStudentProfile.code === 8000) {
                        navigate('/self-registration/contact-information')
                    }

                },
                onError: error => {
                    // Handle errors
                    setSubmitting(false)
                    console.error("Mutation error:", error)
                },
            })
        },
    })
    const [selectedValue, setSelectedValue] = useState(user?.isDisabled || false);
    const [selectedLabel, setSelectedLabel] = useState(user?.isDisabled ? "Yes" : "No");
    const [studentDissability, setStudentDissability] = useState(false)

    const feeWithStructureControlNumber =
        feeStructureControlNumber?.getControlNumbers?.data



    if(userLoading) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching User Details"} />
    if(loadingFeeStructureControlNumbers) return <LoaderPage breadcrumbItem={breadcrumbItem} title={"Registration"} description={"Fetching Control Number Information"} />
    if(!feeWithStructureControlNumber) navigate("/self-registration/payments")


    // console.log("Fees", fees)
    // console.log("controls", feeWithStructureControlNumber)
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={'My Information'}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardSubtitle>
                                <h4>Update Information's</h4>
                            </CardSubtitle>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return null
                                }}
                            >
                                <div className="modal-body">
                                    <Row>
                                        <Col md="4" lg="4">
                                            <div className="mb-3">
                                                <label htmlFor="firstName">First Name:</label>
                                                <Input
                                                    type="text"
                                                    name="firstName"
                                                    readOnly
                                                    className="form-control"
                                                    id="firstName"
                                                    placeholder="Enter First Name"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.firstName ||
                                                        user?.firstName ||
                                                        ""
                                                    }
                                                    invalid={
                                                        !!(
                                                            validation.touched.firstName &&
                                                            validation.errors.firstName
                                                        )
                                                    }
                                                />
                                                {validation.touched.firstName && validation.errors.firstName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.firstName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md="4" lg="4">
                                            <div className="mb-3">
                                                <Label for="middleName">Middle Name</Label>
                                                <Input
                                                    type="text"
                                                    name="middleName"
                                                    readOnly
                                                    className="form-control"
                                                    id="middleName"
                                                    placeholder="Enter middle name"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.middleName ||
                                                        user?.middleName ||
                                                        ""
                                                    }
                                                    invalid={
                                                        !!(
                                                            validation.touched.middleName &&
                                                            validation.errors.middleName
                                                        )
                                                    }
                                                />
                                                {validation.touched.middleName &&
                                                validation.errors.middleName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.middleName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col lg="4" md="4">
                                            <div className="mb-3">
                                                <label htmlFor="lastName">Last Name:</label>
                                                <Input
                                                    type="text"
                                                    readOnly
                                                    name="lastName"
                                                    className="form-control"
                                                    id="lastName"
                                                    placeholder="Enter Last Name"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.lastName ||
                                                        user?.lastName ||
                                                        ""
                                                    }
                                                />
                                                {validation.touched.lastName && validation.errors.lastName ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.lastName}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="4" md="4">
                                            <div className="mb-3">
                                                <Label for="middlename">Phone Number</Label>
                                                <Input
                                                    type="text"
                                                    name="phoneNumber"
                                                    className="form-control"
                                                    id="phoneNumber"
                                                    placeholder="Enter Phone Number"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.phoneNumber ||
                                                        user?.phoneNumber ||
                                                        ""
                                                    }
                                                    invalid={
                                                        !!(
                                                            validation.touched.phoneNumber &&
                                                            validation.errors.phoneNumber
                                                        )
                                                    }
                                                />
                                                {validation.touched.phoneNumber &&
                                                validation.errors.phoneNumber ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.phoneNumber}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md="4" lg="4">
                                            <div className="mb-3">
                                                <label>Select Marital Status</label>
                                                <Select
                                                    name="maritalStatusUid"
                                                    onChange={e => {
                                                        validation.setFieldValue("maritalStatusUid", e.value)
                                                    }}
                                                    options={maritalStatus?.map(m => ({
                                                        value: m?.uid,
                                                        label: m?.name,
                                                    }))}
                                                    className="select2-selection"
                                                    styles={{
                                                        menu: base => ({
                                                            ...base,
                                                            position: "absolute",
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    defaultValue={user?.maritalStatus?.uid}
                                                    defaultInputValue={user?.maritalStatus?.name}
                                                />
                                                <Input
                                                    name="maritalStatusUid"
                                                    placeholder="Select Marital Status"
                                                    type="hidden"
                                                    rows={2}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values?.uid ||
                                                        user?.maritalStatus?.uid ||
                                                        ""
                                                    }
                                                    invalid={
                                                        !!(
                                                            validation.touched.maritalStatusUid &&
                                                            validation.errors.maritalStatusUid
                                                        )
                                                    }
                                                />
                                                {validation.touched.maritalStatusUid &&
                                                validation.errors.maritalStatusUid ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.maritalStatusUid}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg="4">
                                            <div className="mb-3">
                                                <label htmlFor="email">Email</label>
                                                <Input
                                                    type="text"
                                                    name="email"
                                                    readOnly
                                                    className="form-control"
                                                    id="email"
                                                    placeholder="Enter email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.email || user?.email || ""
                                                    }
                                                    invalid={
                                                        !!(validation.touched.email && validation.errors.email)
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.email}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="4" lg="4">
                                            <div className="mb-3">
                                                <label htmlFor="placeOfBirth">Place of Birth:</label>
                                                <Input
                                                    type="text"
                                                    name="placeOfBirth"
                                                    className="form-control"
                                                    id="placeOfBirth"
                                                    placeholder="Enter place of birth"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.placeOfBirth ||
                                                        user?.placeOfBirth ||
                                                        ""
                                                    }
                                                    invalid={
                                                        !!(
                                                            validation.touched.placeOfBirth &&
                                                            validation.errors.placeOfBirth
                                                        )
                                                    }
                                                />
                                                {validation.touched.placeOfBirth &&
                                                validation.errors.placeOfBirth ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.placeOfBirth}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg="4" md="4">
                                            <div className="mb-3">
                                                <Label>Date Of Birth</Label>
                                                <Input
                                                    type="date"
                                                    name="dateOfBirth"
                                                    className="form-control"
                                                    id="dateOfBirth"
                                                    placeholder="Enter start Date"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.dateOfBirth ||
                                                        user?.dateOfBirth ||
                                                        ""
                                                    }
                                                    invalid={
                                                        validation.touched.dateOfBirth &&
                                                        validation.errors.dateOfBirth
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.dateOfBirth &&
                                                validation.errors.dateOfBirth ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.dateOfBirth}
                                                    </FormFeedback>
                                                ) : null}

                                            </div>
                                        </Col>


                                        <Col lg="4">
                                            <div className="mb-3">
                                                <Label className="form-label">NHIF Membership No.</Label>
                                                <Input
                                                    name="nhifNumber"
                                                    placeholder="Enter NHIF membership number"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.nhifNumber || ""}
                                                    invalid={
                                                        !!(validation.touched.nhifNumber && validation.errors.nhifNumber)
                                                    }
                                                />
                                                {validation.touched.nhifNumber && validation.errors.nhifNumber ? (
                                                    <FormFeedback
                                                        type="invalid">{validation.errors.nhifNumber}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>

                                        <Col lg="4">
                                            <div className="mb-3">
                                                <Label>NIDA Number</Label>
                                                <Input
                                                    type="text"
                                                    name="nin"
                                                    className="form-control"
                                                    id="text"
                                                    placeholder="Enter Your NIDA Number"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.nin || ""}
                                                    invalid={
                                                        validation.touched.nin && validation.errors.nin
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.nin && validation.errors.nin ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.nin}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label>Do you have any disability or dissabilities?</Label>
                                                <Select
                                                    name="isDisabled"
                                                    onChange={(e) => {
                                                        validation.setFieldValue("isDisabled", e.value)
                                                        setStudentDissability(e.value)
                                                        setSelectedValue(e.value)
                                                    }}
                                                    options={[
                                                        {value: true, label: "Yes"},
                                                        {value: false, label: "No"},
                                                    ]}
                                                    className="select2-selection"
                                                    styles={{
                                                        menu: base => ({
                                                            ...base,
                                                            position: "absolute",
                                                            zIndex: 9999,
                                                        }),
                                                    }}
                                                    value={{value: selectedValue, label: selectedLabel}}
                                                />
                                                <Input
                                                    name="isDisabled"
                                                    placeholder="Select Region"
                                                    type="hidden"
                                                    rows={2}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={
                                                        validation.values.isDisabled ||
                                                        user?.isDisabled ||
                                                        ""
                                                    }
                                                    invalid={
                                                        !!(
                                                            validation.touched.isDisabled &&
                                                            validation.errors.isDisabled
                                                        )
                                                    }
                                                />
                                                {validation.touched.isDisabled &&
                                                validation.errors.isDisabled ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.isDisabled}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col>
                                        {studentDissability === true && (
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <label>Select Disability</label>
                                                    <Select
                                                        onChange={selectedOptions => {
                                                            const selectedValues = selectedOptions.map(option => ({
                                                                disabilityUid: option.value,
                                                            }))
                                                            validation.setFieldValue("disability", selectedValues)
                                                        }}
                                                        options={disabilities?.map(dp => ({
                                                            value: dp?.uid,
                                                            label: dp?.name,
                                                        }))}
                                                        isMulti={true} // Enable multiple selections
                                                        className="select2-selection"
                                                        styles={{
                                                            menu: base => ({
                                                                ...base,
                                                                position: "absolute",
                                                                zIndex: 9999,
                                                            }),
                                                        }}
                                                    />
                                                    <Input
                                                        name="disability" // Update the field name
                                                        placeholder="Select Marital Status"
                                                        type="hidden"
                                                        rows={2}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={JSON.stringify(
                                                            validation.values.disability ||
                                                            user?.uid ||
                                                            []
                                                        )} // Set the value as a JSON string
                                                    />
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                                <div className="wizard">
                                    <div className="actions">
                                        <ul>
                                            <SubmitButtonHelper
                                                type="primary"
                                                name="Submit"
                                                formik={validation}
                                            />
                                        </ul>
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}
export default SelfRegistrationMyInformation