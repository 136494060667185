import PropTypes from "prop-types";
import {Button, Col, Form, FormFeedback, Input, Label, Modal, Row} from "reactstrap";
import React, {useContext, useState} from "react";
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper";
import {useNavigate} from "react-router-dom";
import {registrationGraphQLClient, uaaGraphQLClient} from "../../Authentication/ApolloClient";
import {useFormik} from "formik";
import * as Yup from "yup";
import showToast from "../../../helpers/ToastHelper";
import {CourseLeaningOutcomesContext} from "./CourseLeaningOutcomes";
import {CREATE_COURSE_LEANING_OUTCOME} from "../Mutations/CourseMutations";
import {GET_COURSE_LEARNIG_OUTCOMES} from "../Queries/CourseQueries";
import {limit} from "../../../helpers/UrlHelper";
import {useMutation} from "@apollo/client";

const CourseOutcomeFormModel = props => {
    const {outcomeData,setOutcomeData} = useContext(CourseLeaningOutcomesContext)
    const {courseUid} = props
    const pagination = { offset: 0, limit: limit, search: null }
    const [modalOpen, setModalOpen] = useState(false)
    let outcome = outcomeData
    const [saveCourseOutcome] = useMutation(CREATE_COURSE_LEANING_OUTCOME)

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            courseUid: courseUid,
            learningOutcome: outcome?.learningOutcome
        },

        validationSchema: Yup.object().shape({
            learningOutcome: Yup.string().required("Course Learning Outcome is required")
            // description: Yup.number().required("Program Duration is required"),
        }),
        onSubmit: values => {
            saveCourseOutcome({
                client:registrationGraphQLClient,
                variables: {
                    inputs: { ...values,uid:outcome?.uid },
                },
                refetchQueries: [
                    {
                        query: GET_COURSE_LEARNIG_OUTCOMES,
                        variables: {
                            courseUid: courseUid,
                        },
                    },
                ],
                onCompleted: ({ registerCourseLearnOutcome }) => {
                    //updateFunction(client.cache, { data: { registerCampuses } });
                    if (registerCourseLearnOutcome.code === 8000) {
                        showToast(registerCourseLearnOutcome.message, "success")
                        setModalOpen(false)
                        setOutcomeData(false)
                        validation.resetForm()
                    } else {
                        showToast(
                            registerCourseLearnOutcome.message,
                            registerCourseLearnOutcome.code === 8000 ? "success" : "error"
                        )
                    }
                },
                onError: error => {
                    // Handle errors
                    console.error("Mutation error:", error);
                },
            });
        },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                <i className="mdi mdi-plus-circle-outline me-1" />
                New Learning Outcome
            </Button>
            <Modal
                isOpen={modalOpen || !!outcome}
                backdrop={"static"}
                id="staticBackdrop"
                // size="lg"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!outcome ? "Edit Course" : "Add New Course"}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <Row>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label for="learningOutcome">
                                        Learning Outcome Name
                                    </Label>
                                    <Input
                                        type="textarea"
                                        className="form-control"
                                        id="learningOutcome"
                                        rows={5}
                                        name="learningOutcome"
                                        placeholder="Enter Course Name"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.learningOutcome}
                                        invalid={
                                            !!(validation.touched.learningOutcome && validation.errors.learningOutcome)
                                        }
                                    />
                                    {
                                        validation.touched.learningOutcome && validation.errors.learningOutcome ? (
                                            <FormFeedback type="invalid">{validation.errors.learningOutcome}</FormFeedback>
                                        ): null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setOutcomeData(false)
                                validation.resetForm();
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

CourseOutcomeFormModel.propTypes = {
    courseUid:PropTypes.string.isRequired
}

export default CourseOutcomeFormModel;