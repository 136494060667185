import { gql } from "@apollo/client"

const CREATE_PROGRAM_CATEGORY = gql`
  mutation RegisterProgramCategory($inputs: [ProgramCategoryInput!]!) {
    registerProgramCategory(inputs: $inputs) {
      status
      code
      message
      data {
        items{
          uid
          name
          shortName
        }
        totalCount
      }
    }
  }
`

const DELETE_PROGRAM_CATEGORY = gql`
  mutation RemoveProgramCategory($uid: String!) {
    removeProgramCategory(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_PROGRAM_CATEGORY, DELETE_PROGRAM_CATEGORY }
