import React from "react";
import { Route, Routes } from "react-router";
import CertificateTypeList from "../Views/CertificateTypeList";
import Pages404 from "Modules/Utility/pages-404";

const CertificateTypeRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CertificateTypeList />}/>
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default CertificateTypeRoutes