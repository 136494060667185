import React, { useState, useEffect, createContext } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
} from "reactstrap"
import classnames from "classnames"
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery"
import { useQuery } from "@apollo/client"
import LayoutHelper from "helpers/LayoutHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { token } from "helpers/UrlHelper"
import PartOne from "./QuestionOne"
import PartTwo from "./QuestionTwo"
import PartThree from "./QuestionThree"
import { useLocation } from "react-router-dom"
import PartFour from "./QuestionFour"
import PartFive from "./QuestionFive"
import QuestionSix from "./QuestionSix"
import QuestionSeven from "./QuestionSeven"
import QuestionEightAndNine from "./QuestionEightAndNine"
import QuestionTen from "./QuestionTen"

export const TeachingAssessmentProfileContext = createContext() // Create data to transfer to child modal

const TeachingCourseAssessmentParent = props => {
  const location = useLocation()

  const additionalData = location.state

  console.log(additionalData)

  const [updateTeachingAssessmentData, setUpdateTeachingAssessmentData] =
    useState(null)
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])

  const renderStepContent = tabId => {
    switch (tabId) {
      case 1:
        return <PartOne />
      case 2:
        return <PartTwo />
      case 3:
        return <PartThree />
      case 4:
        return <PartFour />
      case 5:
        return <PartFive />
      case 6:
        return <QuestionSix />
      case 7:
        return <QuestionSeven />
      case 8:
        return <QuestionEightAndNine />
      
      case 10:
        return <QuestionTen />
      default:
        return null
    }
  }

  const breadcrumbItem = [
    {
      title: "Teaching course Assessment",
      path: "",
      isActive: true,
    },
  ]

  const toggleTab = tab => {
    handleSubmit()
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 10) {
        setactiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    refetch,
  } = useQuery(GET_STUDENT, {
    client: uaaGraphQLClient,
    variables: {
      username: JSON.parse(localStorage.getItem("authUser"))?.data?.username,
    },
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    const studentDetails = userDetails?.getUserByUsername?.data // extract student details

    setUpdateTeachingAssessmentData(studentDetails)
  }, [userDetails])

  const handleSubmit = values => {}

  return (
    <TeachingAssessmentProfileContext.Provider
      value={{
        updateTeachingAssessmentData,
        setUpdateTeachingAssessmentData,
        toggleTab,
        handleSubmit,
        activeTab,
        passedSteps,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Teaching Assessment"
      >
        <div className="">
          <div className="">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="wizard">
                      <div
                        className="steps clearfix"
                        style={{ display: "none" }}
                      >
                        <ul style={{ whiteSpace: "nowrap" }}>
                          <NavItem
                            className={classnames({
                              current: activeTab === 1,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                current: activeTab === 1,
                              })}
                              onClick={() => toggleTab(1)}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> PART 1:
                              ASSESSMENT ON STUDENT LEARNING
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 2,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 2,
                              })}
                              onClick={() => toggleTab(2)}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> PART 2:
                              TEACHING AND CONTINUOUS COURSE ASSESSMENT{" "}
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 3,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 3,
                              })}
                              onClick={() => toggleTab(3)}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3.</span> Part 3
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 4,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 4,
                              })}
                              onClick={() => toggleTab(4)}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">4.</span> Part 4
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 5,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 5,
                              })}
                              onClick={() => toggleTab(5)}
                              disabled={!(passedSteps || []).includes(5)}
                            >
                              <span className="number">5.</span> Part 5
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 6,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 6,
                              })}
                              onClick={() => toggleTab(6)}
                              disabled={!(passedSteps || []).includes(6)}
                            >
                              <span className="number">6.</span> Part 6
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 7,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 7,
                              })}
                              onClick={() => toggleTab(7)}
                              disabled={!(passedSteps || []).includes(7)}
                            >
                              <span className="number">7.</span> Part 7
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 8,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 8,
                              })}
                              onClick={() => toggleTab(8)}
                              disabled={!(passedSteps || []).includes(8)}
                            >
                              <span className="number">8.</span> Part 8
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({
                              current: activeTab === 10,
                            })}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTab === 10,
                              })}
                              onClick={() => toggleTab(10)}
                              disabled={!(passedSteps || []).includes(10)}
                            >
                              <span className="number">10.</span> Part 10
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          {renderStepContent(activeTab)}
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </LayoutHelper>
    </TeachingAssessmentProfileContext.Provider>
  )
}

export default TeachingCourseAssessmentParent
