import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_DISTRICT_BY_REGION } from "../Queries/DistrictQueries"
import { DistrictContext } from "./DistrictList"
import { CREATE_DISTRICT } from "../Mutations/DistrictMutations"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const DistrictModal = (props) => {

  const { updateDistrictData, setUpdateDistrictData } =
    useContext(DistrictContext)
  const [district] = useMutation(CREATE_DISTRICT,{
    client:uaaGraphQLClient,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  let  districtToUpdate = updateDistrictData? updateDistrictData[0] : "";

  let region = updateDistrictData ? updateDistrictData[1] : "";

  let regionUid = props.regionUid;


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: districtToUpdate ? districtToUpdate?.name : "",
      code: districtToUpdate ? districtToUpdate?.code : "",
      //description: updateDistrictData ? updateDistrictData.description : "",
      regionUid: region ? region?.regionUid : "",
      regionUid: region && region.regionUid ? region.regionUid : regionUid,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Region name is required"),
      code: Yup.string().required("Region short code is required"),
      //   description: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, {setResubmit}) => {
      // console.log("DistrictForm", values)
      // return
      district({
        client:uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateDistrictData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_DISTRICT_BY_REGION,
            variables: {
              regionUid: regionUid,
            },
          },
        ],
        onCompleted: ({ registerDistricts }) => {
          //updateFunction(client.cache, { data: { registerDistricts } });
          //alert('ffff'+registerDistricts.code);
          if (registerDistricts.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateDistrictData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateDistrictData(true) // Close the modal
            setResubmit(false)
          }
          showToast(
            registerDistricts.message,
            registerDistricts.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New District
      </Button>
      <Modal
        isOpen={modalOpen || !!updateDistrictData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateDistrictData ? "Edit District" : "Add New District"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setUpdateDistrictData(false)
              resetForm() // Reset the form
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">

            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.name || ""
                }
                invalid={
                  !!(validation.touched.name && validation.errors.name)
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="code"
                placeholder="Short Code"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.code || ""
                }
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateDistrictData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default DistrictModal