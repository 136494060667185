import React, { useEffect, useState } from "react"
import LayoutHelper from "helpers/LayoutHelper"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import {
    Button,
    Card,
    CardBody,
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
    CardSubtitle,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { createContext } from "react"
import { useNavigate } from "react-router-dom"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import { GET_ACADEMICYEARS } from "Modules/AcademicYears/Queries/AcademicYearsQueries"

import { forwardStatus } from "helpers/ForwardStatus"
import {accomodationGraphQLClient, registrationGraphQLClient} from "../../Authentication/ApolloClient";
import {DownloadExcelFile} from "../../../helpers/DownloadExcelFile";
import {GENERATE_ALLOCATION_PAYMENT_REPORT_XLS} from "../Queries/AccommodationQueries";

const breadcrumbItem = [
    {
        title: "Room allocation payment status",
        path: "",
        isActive: true,
    },
]

export const ForwardResultsContext = createContext()

const RoomAllocationPaymentStatus = () => {
    const {
        loading: academicYearLoading,
        error: academicYearError,
        data: academicYear,
        refetch: acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
        fetchPolicy: "network-only",
    })

    const [
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, { client: registrationGraphQLClient })

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items

    let [
        generateReport,
        { loading: resultLoading, error: resultError, data: resultData },
    ] = useLazyQuery(GENERATE_ALLOCATION_PAYMENT_REPORT_XLS, {
        client: accomodationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            academicYear: "",
        },
        validationSchema: Yup.object().shape({
            academicYear: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            generateReport({
                variables: {
                    academicYear: values.academicYear,
                },
                onCompleted: ({ getRoomAllocationPaymentReportsByAcademicYear }) => {
                    showToast(
                        getRoomAllocationPaymentReportsByAcademicYear.message,
                        getRoomAllocationPaymentReportsByAcademicYear.code === 8000 ? "success" : "error"
                    )
                    if (
                        getRoomAllocationPaymentReportsByAcademicYear.code === 8000) {
                        DownloadExcelFile(getRoomAllocationPaymentReportsByAcademicYear?.data?.base64Data,getRoomAllocationPaymentReportsByAcademicYear?.data?.fileName)
                    }
                    setSubmitting(false)
                },
                onError: error => {
                    console.error("Mutation error:", error)
                    setSubmitting(false)
                },
            })

        },
    })

    return (
        <LayoutHelper
            breadcrumbItem={breadcrumbItem}
            pageTitle="Room allocation payment status"
        >
            <Row>
                <Col md={6}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                onChange={e => {
                                                    if (e) {
                                                        validation.setFieldValue(
                                                            "academicYear",
                                                            e.value
                                                        )
                                                    } else {
                                                        validation.setFieldValue("academicYear", "")
                                                    }
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.name,
                                                    label: year.name,
                                                }))}
                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="academicYear"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYear}
                                                invalid={
                                                    !!(
                                                        validation.touched.academicYear &&
                                                        validation.errors.academicYear
                                                    )
                                                }
                                            />
                                            {validation.touched.academicYear &&
                                            validation.errors.academicYear ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYear}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-end">
                                            <SubmitButtonHelper type={'primary'} name={'Submit'}
                                                                formik={validation}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}
export default RoomAllocationPaymentStatus
