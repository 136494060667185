import { gql } from "apollo-boost"

const GET_STAFF_TERMS = gql`
  query GetStaffTerm($pagination: PaginationInput!) {
    getStaffTerms(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`

export { GET_STAFF_TERMS }
