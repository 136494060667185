import { gql } from "@apollo/client"

const GET_PROFESSIONAL_MEMBERSHIP = gql`
  query getProfessionalMembershipsPaginated($pagination: PaginationInput!) {
    getProfessionalMembershipsPaginated(pagination: $pagination) {
      code
      message
      status
      data {
        totalCount
        items {
          endDate
          id
          membershipNumber
          organizationName
          staffUid
          startDate
          uid
        }
      }
    }
  }
`

export { GET_PROFESSIONAL_MEMBERSHIP }
