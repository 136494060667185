// export default NextOfKinList;
import React, { createContext, useContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  CardFooter
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation, useQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning } from "helpers/DeleteWaringHelper"
import LanguageProficiencyModal from "./LanguageProficiencyModal"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { DELETE_STAFF_LANGUAGES } from "Modules/Users/Mutations/UserMutations"
import { StaffProfileContext } from "../StaffProfile"
import { GET_STAFF_LANGUAGES } from "Modules/Users/Queries/UserQuery"
import { Link } from "react-router-dom"
import DropDownActionHelper from "helpers/DropDownActionHelper"

export const LanguageProficiencyContext = createContext() //Create data to transfer to child modal

const NextOfKinList = () => {
  const { updateStaffData } =
    useContext(StaffProfileContext)

  const { toggleTab } = useContext(StaffProfileContext)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = staff => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [staff.uid]: !prevState[staff.uid],
    }))
  }

  const handlePrevious = () => {
    toggleTab(4) // Move to the previous tab
  }

  const [selectedStaffLanguage, setSelectedStaffLanguage] = useState(null) //Get data for deleting
  const [updateStaffLanguageData, setUpdateStaffLanguageData] = useState(null) //Get data for editing

  //alert('zzzz'+updateStaffData?.uid)
  const { loading, error, data } = useQuery(GET_STAFF_LANGUAGES, {
    client: uaaGraphQLClient,
    variables: {
      userUid: updateStaffData?.uid,
    },
    fetchPolicy: "cache-first",
  })

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])


  const [deleteStaffLanguage] = useMutation(DELETE_STAFF_LANGUAGES, {
    client: uaaGraphQLClient,
    variables: { uid: selectedStaffLanguage?.uid },
    refetchQueries: [
      {
        query: GET_STAFF_LANGUAGES,
        variables: {
          userUid: updateStaffData?.uid,
        },
      },
    ],
  })

  const handleDeleteStaffLanguage = (language) => {
    setSelectedStaffLanguage(language)
    showDeleteWarning(() => {
      performStaffLanguageDeletion()
    })
  }

  const performStaffLanguageDeletion = async () => {
    try {
      const response = await deleteStaffLanguage()
      showToast(response.data.removeStaffLanguage.message, response.data.removeStaffLanguage.code === 8000 ? "success" : 'error')
      console.log("User deleted successfully:", response)
    } catch (error) {
      console.error("Error deleting user:", error)
    }
  }

  if (loading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error) {
    return showToast(error, "error")
  }

  const result = data?.getStaffLanguages.data;

  return (
    <LanguageProficiencyContext.Provider
      value={{ updateStaffLanguageData, setUpdateStaffLanguageData }}
    >
      <div>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-12 col-sm-12 col-md-12 pull-right">
                      <LanguageProficiencyModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && (
                  <div className="table-rep-plugin">
                    <div
                      className="mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Language</Th>
                            <Th data-priority="1">Proficiency</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result?.map((StaffLanguageObject, index) => (
                            <Tr key={StaffLanguageObject?.uid}>
                              <Td>{index + 1}</Td>
                              <Td>{StaffLanguageObject?.language}</Td>
                              <Td>{StaffLanguageObject?.proficiency}</Td>
                              <Td>
                                <DropDownActionHelper
                                data={StaffLanguageObject}
                                onUpdate={setUpdateStaffLanguageData}
                                onDelete={handleDeleteStaffLanguage}
                                 />
                                
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter>
                <div className="wizard">
                  <div className="actions">
                    <ul>
                      <li className="previous" color="info">
                        <Link to="#" onClick={handlePrevious}>
                          Previous
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </LanguageProficiencyContext.Provider>
  )
}

export default NextOfKinList
