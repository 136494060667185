import { Link } from "react-router-dom"
import React from "react"

const AdmissionLinks = ({ hasAuthority }) => {
  return (
    <>
      <li>
        <Link to="/admission/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </Link>
      </li>


      {hasAuthority("VIEW_ADMISSION_MENU") && (
        <>
          {/*{hasAuthority("VIEW_ADMISSION_PROGRAM_CHANGE_MENU") && (*/}
          {hasAuthority("VIEW_ACADEMIC_YEAR_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-badge"></i>
                <span>ACADEMIC YEAR SETUP</span>
              </Link>
              <ul className="sub-menu">
                {hasAuthority("VIEW_ACADEMIC_YEAR_MENU") && (
                  <li>
                    <Link to="/admission/academicyears">Academic Year</Link>
                  </li>
                )}


                {hasAuthority("VIEW_ACADEMIC_YEAR_SEMESTER_MENU") && (
                  <li>
                    <Link to="/admission/semesters">Semesters</Link>
                  </li>
                )}


              </ul>
            </li>

          )}

          {hasAuthority("VIEW_PROGRAM_CATEGORY_MENU") && (
            <li>
              <Link to="/admission/programme-categories">
                <i className="bx bx-list-check"></i>
                <span>Programme Categories</span>
              </Link>
            </li>
          )}
          {/*{hasAuthority("VIEW_STUDENT__MENU") && (*/}
          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-group"></i>
                <span>Students Panel</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admission/students">Student List</Link>
                </li>
                <li>
                  <Link to="/admission/switch_account">Switch Student Account</Link>
                </li>
                <li>
                  <Link to="/admission/force_core_registration">Force Course Reg</Link>
                </li>
                {hasAuthority("VIEW_RECOVER_STUDENT_PASSWORD_MENU") && (
                  <li>
                    <Link to="/admission/recover_student_account">Recover Account</Link>
                  </li>
                )}
                {hasAuthority("VIEW_RECOVER_STUDENT_PASSWORD_MENU") && (
                  <li>
                    <Link to="/admission/recover-applicant-password">Applicant Account</Link>
                  </li>
                )}
              </ul>
            </li>

          )}


          {/*{hasAuthority("VIEW_ONLINE_APPLICATION_MENU") && (*/}
          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-badge"></i>
                <span>Online Application</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admission/application/admission-year">Admission Year</Link>
                </li>
                <li>
                  <Link to="/admission/application-window">Application Window</Link>
                </li>
                {hasAuthority("VIEW_ENTRY_CATEGORY_MENU") && (
                  <li>
                    <Link to="/admission/entry-categories">Entry Categories</Link>
                  </li>
                )}
                {hasAuthority("VIEW_CERTIFICATE_TYPE_MENU") && (
                  <li>
                    <Link to="/admission/certificate-types">Certificate Types</Link>
                  </li>
                )}
                <li>
                  <Link to="/admission/grade-points">Grade Points</Link>
                </li>
                <li>
                  <Link to="/admission/tcu_send_enrollment">Send Enrollment</Link>
                </li>
                <li>
                  <Link to="/admission/application/applicants">Applicant Status</Link>
                </li>
                <li>
                  <Link to="/admission/application/subjects">Subject Definition</Link>
                </li>
                <li>
                  <Link to="/admission/application/program-capacity">Program Capacity</Link>
                </li>
              </ul>
            </li>

          )}

          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-badge"></i>
                <span>Application Report(s)</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admission/application/ranking">Application ranking</Link>
                </li>
                <li>
                  <Link to="/admission/application/confirmation">Applicant Confirmation</Link>
                </li>
                <li>
                  <Link to="/admission/selections">Application selections</Link>
                </li>
                <li>
                  <Link to="/admission/applications-report">Application Reports</Link>
                </li>
                <li>
                  <Link to="/admission/application-report-by-date">Applicant Reports By Date</Link>
                </li>
                <li>
                  <Link to="/admission/application-submission-status-report">Submission Status</Link>
                </li>
              </ul>
            </li>

          )}
          {/*{hasAuthority("VIEW_ADMISSION_PROGRAM_CHANGE_MENU") && (*/}
          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-badge"></i>
                <span>Program Change</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admission/cancel_program_change">Cancel Program Change</Link>
                </li>


              </ul>
            </li>

          )}


          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
            <Link to="/#" className="has-arrow">
                <i className="bx bxs-user-badge"></i>
                <span>Exam & Courses</span>
              </Link>
              <ul className="sub-menu">
                {/*{hasAuthority("VIEW_EXAM_CATEGORY_MENU") && (*/}
                <li>
                  <Link to="/admission/exam-category">Exam Category</Link>
                </li>
                {/*)}*/}

              </ul>
            </li>

          )}

          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-wallet"></i>
                <span>Transcripts</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admission/partial-transcript">Partial</Link>
                </li>
              </ul>
            </li>

          )}

          {hasAuthority("VIEW_ADMISSION_MENU") && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-wallet"></i>
                <span>Lookup Settings</span>
              </Link>
              <ul className="sub-menu">
                {hasAuthority("VIEW_STATUS_TYPE_MENU") && (
                  <li>
                    <Link to="/admission/lookups/status-types">Status type</Link>
                  </li>
                )}
                {hasAuthority("VIEW_STATUS_MENU") && (
                  <li>
                    <Link to="/admission/lookups/status">Status</Link>
                  </li>
                )}
              </ul>
            </li>

          )}


          <li>
            <Link to="/admission/signaturepad">
              <i className="bx bx-pen"></i>
              <span>Submit Signature</span>
            </Link>
          </li>


        </>
      )}
    </>
  )
}
export default AdmissionLinks
