import { gql } from "@apollo/client"

const GET_ADMISSION_YEARS = gql`
    query GetAdmissionYear($pagination: PaginationInput!) {
        getAdmissionYears(pagination: $pagination){
            status
            code
            message
            data {
                items{
                    uid
                    name
                    status
                    startDate
                    endDate
                    numberOfChoices
                }
                totalCount
            }
        }
    }
`

const GET_PROGRAM_CAPACITY = gql`
    query GetPCapacity($pagination: PaginationInput!){
        getProgramCapacities(pagination:$pagination){
            status
            message
            code
            data{
                totalCount
                items{
                    uid
                    capacity
                    programUid
                    programName
                    capacityDirect
                    capacityEquivalent
                    programCategoryCode
                    totalOLevelPassess
                    totalALevelPassess
                    totalOLevelScienceSubjects
                    totalALevelScienceSubjects
                    minimumOlevelPoints
                    description
                    minimumAlevelPoints
                    admissionYear{
                        uid
                        name
                    }
                }
            }

        }
    }

`

const GET_LEVEL_TYPES = gql`
    query GetLevels{
        getLevelTypes{
            status
            code
            message
            data{
                items{
                    uid
                    name
                    code
                }
            }
        }
    }
`
export { GET_ADMISSION_YEARS, GET_PROGRAM_CAPACITY, GET_LEVEL_TYPES }