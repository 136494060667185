import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
import ProgramCourseAssessmentList from "../Views/ProgramCourseAssessmentList";

const ProgramCoursesRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<ProgramCourseAssessmentList />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )

}

export default ProgramCoursesRoutes;