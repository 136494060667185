import {useQuery} from "@apollo/client";
import {GET_STUDENT} from "../Users/Queries/UserQuery";
import {uaaGraphQLClient} from "../Authentication/ApolloClient";

export const LogedInStudent = () => {
    const {
        loading: userLoading,
        error: userError,
        data: Student,
    } = useQuery(GET_STUDENT, {
        client: uaaGraphQLClient,
        variables: {
            username: JSON.parse(localStorage.getItem("authUser"))?.data.username,
        },
        fetchPolicy:"cache-first"
    })
    return {
        student : Student?.getUserByUsername?.data?.student,
        other : Student?.getUserByUsername?.data
    }


}

