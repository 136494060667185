
import { gql } from "@apollo/client"

const REGISTER_NHIF = gql`
  mutation registerNhif($studentUid: String!) {
    registerNhif(studentUid: $studentUid) {
      status
      code
      message
      data
    }
  }
`


const GET_NHIF_CONTROL_NUMBER = gql`
  mutation getNhifControlNumber($studentUid: String!) {
    getNhifControlNumber(studentUid: $studentUid) {
      status
      code
      message
      data
    }
  }
`

export { REGISTER_NHIF, GET_NHIF_CONTROL_NUMBER }
