import { gql } from "@apollo/client"

const DELETE_ACADEMIC_EVENT = gql`
  mutation DeleteAcademicEvent($uid: String!, $deletedBy: Int!) {
    deleteAcademicEvent(uid: $uid, deletedBy: $deletedBy) {
      status
      code
      message
      data {
        uid
        id
        staffUid
        title
        eventCategory {
          uid
          id
          name
          code
        }
        academicStaffRole
        startDate
        endDate
        organizer
        institution
        city
        country
      }
    }
  }
`

const ADD_UPDATE_ACADEMIC_EVENT = gql`
  mutation AddUpdateAcademicEvent($input: AcademicEventsInput!) {
    addUpdateAcademicEvent(input: $input) {
      status
      code
      message
      data {
        uid
        id
        staffUid
        title
        eventCategory {
          uid
          id
          name
          code
        }
        academicStaffRole
        startDate
        endDate
        organizer
        institution
        city
        country
      }
    }
  }
`

export { DELETE_ACADEMIC_EVENT, ADD_UPDATE_ACADEMIC_EVENT }
