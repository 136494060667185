import { gql } from "@apollo/client"

const GET_BANKS = gql`
  query GetBanks($pagination: PaginationInput!) {
    getBanks(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          uid
          name
          code
        }
        totalCount
      }
    }
  }
`

export { GET_BANKS }
