import React, {createContext, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import LayoutHelper from "../../../../../helpers/LayoutHelper";
import PropTypes from "prop-types";
import ConceptNoteFormModel from "./ConceptNoteFormModel";

const breadcrumbItem = [
    {
        title: "Dissertation",
        path: "/students/dissertation",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]

export const ConceptNoteContext = createContext() //Create data to transfer to child modal
const ConceptNote = (props) => {
    const {studentSeminarType} = props
    const location = useLocation();
    const navigate = useNavigate();
    const [conceptNoteData, setConceptNoteData] = useState(studentSeminarType)
    if(!location.state?.type) navigate('/dissertation')
    let { type,student } = location.state;

    return(
        <ConceptNoteContext.Provider value={{ conceptNoteData, setConceptNoteData,type,student }}>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <CardSubtitle className="mb-1">
                                <Row>
                                    <Col className="col-lg-6 col-md-6 col-sm-6">
                                        {`${type.description}`}
                                    </Col>
                                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                                        <ConceptNoteFormModel />
                                    </Col>
                                </Row>
                            </CardSubtitle>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </ConceptNoteContext.Provider>
    );
}

ConceptNote.propTypes = {
    studentSeminarType : PropTypes.any.isRequired
}
export default ConceptNote;