import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  FormFeedback,
  Input,
  Row
} from "reactstrap"
import ChartSection from "./ChartSection"
import StatisticsApplications from "./StatisticsApplications"
import { jwtDecode } from "jwt-decode"
import { GET_ADMISSION_YEARS } from "Modules/OnlineApplication/AdmissionProgramCapacity/Queries/ProgramAdmissionQueries"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useQuery } from "@apollo/client"
import { limit } from "helpers/UrlHelper"
import Select from "react-select"
import { useFormik } from "formik"
import { GE_SUMMARY_REPORT } from "Modules/OnlineApplication/ProgramSelection/Queries/ProgramSelectionQueries"
import StudentDashboard from "Modules/Dashboard/StudentDashboard"
import ApplicantDashboard from "../../Dashboard/ApplicantDashboard"
import SelfRegistrationDashboard from "../../Dashboard/SelfRegistrationDashboard"
import OnlineApplicationDashboard from "../../Dashboard/OnlineApplicationDashboard"

const AsimsDashboard = () => {
  const accessToken = JSON.parse(localStorage.getItem("authUser"))?.accessToken
  const userData = accessToken ? jwtDecode(accessToken) : null
  const userAuthorities = userData?.authorities || []

  const hasAuthority = authority => userAuthorities.includes(authority)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const [admissionYear, setAdmissionYear] = useState(null)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const userAuth = JSON.parse(localStorage.getItem("authUser"))

  const obj = userAuth?.data

  // console.log(obj.uid)

  // const fullname = obj?.firstName + " " + obj?.lastName + ", REGNO: [" + obj?.student.registrationNumber +"], STATUS: [" + obj?.student.status.name + "]";
  const fullname = obj?.firstName + " " + obj?.lastName

  const { loading, error, data, refetch } = useQuery(GET_ADMISSION_YEARS, {
    client: onlineApplicationGraphQLClient,
    skip: !hasAuthority("VIEW_ADMISSION_MODULE"),
    variables: {
      pagination
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      setAdmissionYear(data?.getAdmissionYears?.data?.items[0]?.uid)
    }
  })

  const {
    loading: loadingDashboard,
    error: errorDash,
    data: dataDash,
    refetch: refetchSummary
  } = useQuery(GE_SUMMARY_REPORT, {
    client: onlineApplicationGraphQLClient,
    skip: !hasAuthority("VIEW_ADMISSION_MODULE") || !admissionYear,
    variables: {
      inputData: {
        admissionYearUid: admissionYear
      }
    },
    fetchPolicy: "network-only"
  })

  const dataArray = data?.getAdmissionYears?.data?.items

  useEffect(() => {
  }, [admissionYear])

  const summary = dataDash?.getApplicantSummaryReport?.data

  document.title = "Dashboard"
  const chartsData = [
    {
      id: 1,
      title: "Applications",
      totatApplicants: summary?.totalApplicants,
      male: summary?.applicantsInGender?.male,
      female: summary?.applicantsInGender?.female,
      perstangeValue: "100",
      bagdeColor: "success",
      seriesData: [
        {
          name: "New Application",
          data: [summary?.applicantsInGender?.male ? summary?.applicantsInGender?.male : 0, summary?.applicantsInGender?.female ? summary?.applicantsInGender?.female: 0]
        }
      ],
      color: "[\"--bs-success\", \"--bs-transparent\"]"
    },
    {
      id: 2,
      title: "Undergraduate",
      totatApplicants: summary?.totalUndergraduate,
      male: summary?.undergraduateInGender?.male,
      female: summary?.undergraduateInGender?.female,
      perstangeValue: summary?.undergraduatePercentage,
      bagdeColor: "success",
      seriesData: [
        {
          name: "New Application",
          data: [summary?.undergraduateInGender?.male ? summary?.undergraduateInGender?.male : 0, summary?.undergraduateInGender?.female ? summary?.undergraduateInGender?.female : 0]
        }
      ],
      color: "[\"--bs-success\", \"--bs-transparent\"]"
    },
    {
      id: 3,
      title: "Postgraduate",
      totatApplicants: summary?.totalPostgraduate,
      male: summary?.postgraduateInGender?.male,
      female: summary?.postgraduateInGender?.female,
      perstangeValue: summary?.postgraduatePercentage,
      bagdeColor: "success",
      seriesData: [
        {
          name: "Total Approved",
          data: [summary?.postgraduateInGender?.male ? summary?.postgraduateInGender?.male : 0, summary?.postgraduateInGender?.female ? summary?.postgraduateInGender?.female : 0]
        }
      ],
      color: "[\"--bs-success\", \"--bs-transparent\"]"
    },
    {
      id: 4,
      title: "Certificate",
      totatApplicants: summary?.totalCertificate,
      male: summary?.certificateInGender?.male,
      female: summary?.certificateInGender?.female,
      perstangeValue: summary?.postgraduatePercentage,
      bagdeColor: "success",
      seriesData: [
        {
          name: "Total Approved",
          data: [summary?.certificateInGender?.male ? summary?.certificateInGender?.male : 0, summary?.certificateInGender?.female ? summary?.certificateInGender?.female : 0]
        }
      ],
      color: "[\"--bs-success\", \"--bs-transparent\"]"
    },
    {
      id: 4,
      title: "Diploma",
      totatApplicants: summary?.totalDiploma,
      male: summary?.diplomaInGender?.male,
      female: summary?.diplomaInGender?.female,
      perstangeValue: summary?.postgraduatePercentage,
      bagdeColor: "success",
      seriesData: [
        {
          name: "Total Approved",
          data: [summary?.diplomaInGender?.male ? summary?.diplomaInGender?.male : 0, summary?.diplomaInGender?.female ? summary?.diplomaInGender?.female : 0]
        }
      ],
      color: "[\"--bs-success\", \"--bs-transparent\"]"
    },
    {
      id: 4,
      title: "Bachelor",
      totatApplicants: summary?.totalBachelor,
      male: summary?.bachelorInGender?.male,
      female: summary?.bachelorInGender?.female,
      perstangeValue: summary?.postgraduatePercentage,
      bagdeColor: "success",
      seriesData: [
        {
          name: "Total Approved",
          data: [summary?.bachelorInGender?.male ? summary?.bachelorInGender?.male : 0, summary?.bachelorInGender?.female ? summary?.bachelorInGender?.female : 0]
        }
      ],
      color: "[\"--bs-success\", \"--bs-transparent\"]"
    }

    // {
    //   id: 5,
    //   title: "Total Rejected",
    //   totatApplicants: "12,487",
    //   male: "",
    //   female: "",
    //   perstangeValue: " 20.63",
    //   bagdeColor: "danger",
    //   istrendingArrow: true,
    //   seriesData: [
    //     {
    //       name: "Total Rejected",
    //       data: [32, 22, 7, 55, 20, 45, 36, 20],
    //     },
    //   ],
    //   color: '["--bs-danger", "--bs-transparent"]',
    // },
  ]

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      chartType: ""
    }
  })

  return (
    <React.Fragment>
      <div className="page-content">
        {loading || loadingDashboard ? (
          <div className="mt-3">Loading...</div>
        ) : (
          <Container fluid>
            {hasAuthority("VIEW_STUDENT_DASHBOARD") && <StudentDashboard />}
            {hasAuthority("VIEW_ADMISSION_MODULE") && (
              <>
                Welcome {fullname}
                <CardTitle>Admission year</CardTitle>
                <div className="mb-3 mt-3">
                  <Select
                    onChange={e => {
                      validation.setFieldValue("chartType", e.value)
                      setAdmissionYear(e.value)
                    }}
                    options={dataArray?.map(year => ({
                      value: year.uid,
                      label: year?.name
                    }))}
                    className="select2-selection"
                    defaultInputValue={dataArray ? dataArray[0].name : ""}
                    defaultValue={dataArray ? dataArray[0].uid : ""}
                  />
                  <Input
                    name="chartType"
                    type="hidden"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.chartType || ""}
                    invalid={
                      !!(
                        validation.touched.chartType &&
                        validation.errors.chartType
                      )
                    }
                  />
                  {validation.touched.chartType &&
                  validation.errors.chartType ? (
                    <FormFeedback type="invalid">
                      {validation.errors.chartType}
                    </FormFeedback>
                  ) : null}
                </div>
                <ChartSection chartsData={chartsData} />
                <Row>
                  <StatisticsApplications />
                </Row>
              </>
            )}
          </Container>
        )}
        {!hasAuthority("VIEW_DASHBOARD_MENU") && (
          <Container>
            <ApplicantDashboard />
            <SelfRegistrationDashboard />
          </Container>
        )}
        {
          hasAuthority("VIEW_OAS_MODULE") && (
            <OnlineApplicationDashboard />
          )
        }
      </div>
    </React.Fragment>
  )
}

export default AsimsDashboard
