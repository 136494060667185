import { gql } from "@apollo/client"

const CREATE_ENTRY_CATEGORY = gql`
  mutation RegisterEntryCategory($inputs: [EntryCategoryDtoInput!]!) {
    registerEntryCategories(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
          code
       }
      }
    }
  }
`

const DELETE_ENTRY_CATEGORY = gql`
  mutation RemoveEntryCategory($uid: String!) {
    removeEntryCategory(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_ENTRY_CATEGORY, DELETE_ENTRY_CATEGORY }
