import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Row } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { onlineApplicationGraphQLClient } from "Modules/Authentication/ApolloClient"

import Select from "react-select"
import { ApplicationWindowContext } from "./ApplicationWindowList"
import { GET_APPLICATION_WINDOW } from "../Queries/ApplicationWindowQuery"
import {
  CREATE_APPLICATION_WINDOW,
  UPDATE_APPLICATION_WINDOW
} from "../Mutations/ApplicationWindowMutation"
import moment from "moment"
import {
  GET_ADMISSION_YEARS,
  GET_LEVEL_TYPES
} from "../../OnlineApplication/AdmissionProgramCapacity/Queries/ProgramAdmissionQueries"
import SubmitButtonHelper from "../../../helpers/SubmitButtonHelper"

const ApplicationWindowModal = () => {
  const { updateApplicationWindowData, setUpdateApplicationWindowData } =
    useContext(ApplicationWindowContext)
  const [ApplicationWindow] = useMutation(
    updateApplicationWindowData?.uid
      ? UPDATE_APPLICATION_WINDOW
      : CREATE_APPLICATION_WINDOW
  )
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const { loading, error, data } = useQuery(GET_ADMISSION_YEARS, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen,
    variables: {
      pagination: paginationHelper
    }
  })

  const { loading: levelLoading, error: levelError, data: levelData } = useQuery(GET_LEVEL_TYPES, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen
  })

  const admissionYears = data?.getAdmissionYears?.data?.items
  const levelList = levelData?.getLevelTypes?.data?.items

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      batchNumber: updateApplicationWindowData
        ? updateApplicationWindowData.batchNumber
        : "",
      levelTypeUid: updateApplicationWindowData
        ? updateApplicationWindowData?.levelType?.uid
        : "",
      description: updateApplicationWindowData
        ? updateApplicationWindowData.description
        : "",
      startDate: updateApplicationWindowData
        ? moment(updateApplicationWindowData.startDate).format("yyyy-MM-DD")
        : "",
      endDate: updateApplicationWindowData
        ? moment(updateApplicationWindowData.endDate).format("yyyy-MM-DD")
        : "",
      admissionYearUid: updateApplicationWindowData
        ? updateApplicationWindowData.admissionYear?.uid
        : ""
    },

    validationSchema: Yup.object().shape({
      batchNumber: Yup.number()
        .min(1, "Batch number must be at least 1")
        .max(9, "Batch number must be at most 9")
        .required("Batch number is required"),
      startDate: Yup.string().required("Start date is required"),
      endDate: Yup.string().required("End date is required"),
      description: Yup.string().required("Description is required"),
      admissionYearUid: Yup.string().required("Description year is required"),
      levelTypeUid: Yup.string().required("Application level code is required")
    }),

    onSubmit: (values, { setSubmitting }) => {
      const batchNumber = parseInt(values.batchNumber)
      const code = batchNumber + "_" + values.admissionYearUid
      ApplicationWindow({
        client: onlineApplicationGraphQLClient,
        variables: {
          inputs: {
            ...values,
            batchNumber,
            code,
            uid: updateApplicationWindowData?.uid
          }
        },
        refetchQueries: [
          {
            query: GET_APPLICATION_WINDOW,
            client: onlineApplicationGraphQLClient,
            variables: {
              pagination: paginationHelper
            }
          }
        ],
        onCompleted: ({ registerAdmissionWindow, updateAdmissionWindow }) => {
          if (
            updateAdmissionWindow
              ? updateAdmissionWindow?.code === 8000
              : registerAdmissionWindow?.code === 8000
          ) {
            resetForm()
            setModalOpen(false)
            setUpdateApplicationWindowData(false) // Close the modal
          } else {
            setModalOpen(true)
            setSubmitting(false)
            setUpdateApplicationWindowData(true) // Close the modal
          }
        },
        onError: error => {
          setSubmitting(false)
          console.error("Mutation error:", error)
        }
      }).then(r => console.log(r))
    }
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Application Window
      </Button>
      <Modal
        isOpen={modalOpen || !!updateApplicationWindowData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateApplicationWindowData
              ? "Edit Application Window"
              : "Add New Application Window"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="name" className="col-form-label">
                    Batch Number:
                  </label>
                  <Input
                    type="number"
                    name="batchNumber"
                    className="form-control"
                    id="batchNumber"
                    min="1"
                    max="9"
                    placeholder="Enter Batch number eg. 1, 2"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.batchNumber ||
                      updateApplicationWindowData?.batchNumber ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.batchNumber &&
                        validation.errors.batchNumber
                      )
                    }
                  />
                  {validation.touched.batchNumber &&
                  validation.errors.batchNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.batchNumber}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Admission Year
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("admissionYearUid", e.value)
                    }}
                    options={admissionYears?.map(year => ({
                      value: year.uid,
                      label: year.name
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                    defaultValue={validation.values.admissionYearUid}
                    defaultInputValue={
                      updateApplicationWindowData?.admissionYear?.name
                    }
                  />
                  <Input
                    name="admissionYearUid"
                    placeholder="Select Admission year"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.admissionYearUid || ""}
                    invalid={
                      !!(
                        validation.touched.admissionYearUid &&
                        validation.errors.admissionYearUid
                      )
                    }
                  />
                  {validation.touched.admissionYearUid &&
                  validation.errors.admissionYearUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.admissionYearUid}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="startDate" className="col-form-label">
                    Start Date
                  </label>
                  <Input
                    type="date"
                    name="startDate"
                    className="form-control"
                    id="startDate"
                    placeholder="Enter start Date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      moment(validation.values.startDate).format(
                        "yyyy-MM-DD"
                      ) || ""
                    }
                    invalid={
                      !!(
                        validation.touched.startDate &&
                        validation.errors.startDate
                      )
                    }
                  />
                  {validation.touched.startDate &&
                  validation.errors.startDate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.startDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="endDate" className="col-form-label">
                    End Date:
                  </label>
                  <Input
                    type="date"
                    name="endDate"
                    className="form-control"
                    id="endDate"
                    placeholder="Enter End Date"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={
                      moment(validation.values.endDate).format("yyyy-MM-DD") ||
                      ""
                    }
                    invalid={
                      !!(
                        validation.touched.endDate && validation.errors.endDate
                      )
                    }
                  />
                  {validation.touched.endDate && validation.errors.endDate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.endDate}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>

            <Row>
              <div className="col-lg-12">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Application level
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("levelTypeUid", e.value)
                    }}
                    options={levelList?.map(level => ({
                      label: level?.name,
                      value: level?.uid
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                    defaultValue={updateApplicationWindowData?.levelType?.uid}
                    defaultInputValue={
                      updateApplicationWindowData?.levelType?.name
                    }
                  />
                  <Input
                    name="levelTypeUid"
                    placeholder="Select Admission year"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.levelTypeUid || ""}
                    invalid={
                      !!(
                        validation.touched.levelTypeUid &&
                        validation.errors.levelTypeUid
                      )
                    }
                  />
                  {validation.touched.applicationLevelCode &&
                  validation.errors.applicationLevelCode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.applicationLevelCode}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Row>

            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description:
              </label>
              <textarea
                className="form-control"
                name="description"
                id="message-text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.description || ""}
                onInvalid={() => {
                  !!(
                    validation.touched.description &&
                    validation.errors.description
                  )
                }}
              />
              {validation.touched.description &&
              validation.errors.description ? (
                <FormFeedback type="invalid">
                  {validation.errors.description}
                </FormFeedback>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateApplicationWindowData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ApplicationWindowModal
