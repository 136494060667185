import React, { createContext, useEffect, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { asimsGraphQLClient } from "../../../Authentication/ApolloClient"
import { GET_ACADEMIC_QUALIFICATIONS_BY_STAFF_UID } from "../Queries/AcademicQualifications"
import { DELETE_EMPLOYMENT_HISTORY } from "../Mutations/AcademicQualifications"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import showToast from "../../../../helpers/ToastHelper"
import * as Yup from "yup"
import { useFormik } from "formik"
import { showDeleteWarning } from "../../../../helpers/DeleteWaringHelper"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import ReactPaginate from "react-paginate"

const breadcrumbItem = [
  {
    title: "Academic Qualifications List",
    path: "",
    isActive: true,
  },
]

const pageSize = 10
export const AcademicQualificationsContext = createContext() //Create data to transfer to child modal

const AcademicQualifications = () => {
  const staffUid = "2ac0f17a-08f9-42fd-af72-7a7a8bcdcbfd"
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedAcademicQualifications, setSelectedAcademicQualifications] =
    useState(null)
  const [
    updateAcademicQualificationsData,
    setUpdateAcademicQualificationsData,
  ] = useState(null)
  const [assignPermission, setAssignPermission] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { loading, error, data, reFetch } = useQuery(
    GET_ACADEMIC_QUALIFICATIONS_BY_STAFF_UID,
    {
      client: asimsGraphQLClient,
      variables: {
        staffUid,
      },
      fetchPolicy: "cache-first",
    },
  )

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
  })

  const [
    searchAcademicQualifications,
    { loading: searchLoading, error: searchError, data: searchData },
  ] = useLazyQuery(GET_ACADEMIC_QUALIFICATIONS_BY_STAFF_UID, {
    client: asimsGraphQLClient,
  })

  const searchQuery = () => {
    const value = formikSearch.values?.search || ""
    if (value.length >= 3) {
      searchAcademicQualifications({
        variables: {
          staffUid,
          search: value,
        },
      }).then(r => "")
    } else if (value?.length === 0) {
      clearSearch()
      fetchData()
    }
  }

  useEffect(() => {
    if (!loading && data) {
    }
  }, [loading, data])

  const handleClick = selectedPage => {
    setCurrentPage(selectedPage.selected)
  }

  const [deleteAcademicQualifications] = useMutation(
    DELETE_EMPLOYMENT_HISTORY,
    {
      client: asimsGraphQLClient,
      variables: {
        uid: selectedAcademicQualifications
          ? selectedAcademicQualifications.uid
          : null,
      },
      refetchQueries: [
        {
          query: GET_ACADEMIC_QUALIFICATIONS_BY_STAFF_UID,
          variables: {
            staffUid: staffUid,
          },
        },
      ],
    },
  )

  const toggleDropdown = academicQualifications => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [academicQualifications.uid]: !prevState[academicQualifications.uid],
    }))
  }

  const handleDeleteAcademicQualifications = () => {
    showDeleteWarning(() => {
      performAcademicQualificationsDeletion().then(r => "")
    })
  }

  const performAcademicQualificationsDeletion = async () => {
    try {
      const response = await deleteAcademicQualifications()
      if (response) {
        showToast("Academic Qualifications Deleted Successfully", "success")
      } else {
        showToast("Failed to Delete Academic Qualifications", "error")
      }
    } catch (error) {
      showToast(`Error: ${error}`, "error")
    }
  }

  if (loading && !searchLoading) {
    return <PlaceHolderLoader rows={4} type="table" columSize={12} />
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = () => {
    let data = {
      search: formikSearch.values.search,
    }
    searchAcademicQualifications({ variables: { staffUid } }).then(r => "")
  }

  const clearSearch = () => {
    formikSearch.setFieldValue("search", "")
  }

  const EmployerHistories = data?.getAcademicQualificationsByStaffUid?.data
  const SearchedAcademicQualifications =
    searchData?.getAcademicQualificationsByStaffUid?.data
  const results = SearchedAcademicQualifications
    ? SearchedAcademicQualifications
    : EmployerHistories
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries =
    data?.getAcademicQualificationsByStaffUid?.data?.totalCount ||
    searchData?.getAcademicQualificationsByStaffUid?.data?.items ||
    0

  return (
    <AcademicQualificationsContext.Provider
      value={{
        updateAcademicQualificationsData,
        setUpdateAcademicQualificationsData,
        assignPermission,
        setAssignPermission,
      }}
    >
      <LayoutHelper
        breadcrumbItem={breadcrumbItem}
        pageTitle="Academic Qualifications List"
      >
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="btn mb-2 me-2 pull-right"
                          onClick={() => ""}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          New Academic Qualifications
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="1">Qualification Name</Th>
                            <Th data-priority="1">GPA</Th>
                            <Th data-priority="1">Institution Name</Th>
                            <Th data-priority="1">Institution Type</Th>
                            <Th data-priority="1">Thesis Dissertation Title</Th>
                            <Th data-priority="1">City Of Award</Th>
                            <Th data-priority="1">TCU Verification Number</Th>
                            <Th data-priority="3">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {results
                            ?.slice(startIndex, endIndex)
                            .map((AcademicQualifications, index) => (
                              <Tr key={AcademicQualifications?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>
                                  {AcademicQualifications?.degreeAwardName}
                                </Td>
                                <Td>{AcademicQualifications?.gpa}</Td>
                                <Td>
                                  {AcademicQualifications?.institutionName}
                                </Td>
                                <Td>
                                  {AcademicQualifications?.institutionType}
                                </Td>
                                <Td>
                                  {
                                    AcademicQualifications?.thesisDissertationTitle
                                  }
                                </Td>
                                <Td>{AcademicQualifications?.cityOfAward}</Td>
                                <Td>
                                  {
                                    AcademicQualifications?.tcuVerificationNumber
                                  }
                                </Td>
                                <Td>
                                  <Dropdown
                                    isOpen={
                                      dropdownOpen[AcademicQualifications.uid]
                                    }
                                    toggle={() =>
                                      toggleDropdown(AcademicQualifications)
                                    }
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>

                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateAcademicQualificationsData(
                                            AcademicQualifications,
                                          )
                                        }}
                                      >
                                        <i
                                          className="fas fa-pen"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedAcademicQualifications(
                                            AcademicQualifications,
                                          )
                                          handleDeleteAcademicQualifications()
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </AcademicQualificationsContext.Provider>
  )
}

export default AcademicQualifications
