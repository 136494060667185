import React from "react";
import LayoutHelper from "../../../helpers/LayoutHelper";
import {Link, useLocation} from "react-router-dom";
import {Button, Card, CardBody, CardSubtitle, Col, Row} from "reactstrap";


const breadcrumbItem = [
    {
        title: "Student List",
        path: "/students",
        isActive: false,
    },    {
        title: "View",
        path: "",
        isActive: true,
    },
]

const ViewStudent = () => {
    const location = useLocation();
    let { student } = location.state;
    // console.log(student)
    return(
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={`${student.registrationNumber}`} >
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody className="border-bottom">
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 card-title flex-grow-1">{`${student?.user?.firstName} ${student?.user?.middleName} ${student?.user?.lastName}`}</h5>
                                <div className="flex-shrink-0">
                                    {/*<ProgramFormModel program={false} />*/}
                                    {/*<Link to="/programs/registration"  className="btn btn-primary me-1" title={'Add new Program'}>Add New <i className="bx bxs-plus-circle"/></Link>*/}
                                    {/*<Link to="/programs/registration" className="btn btn-success me-1" state={{program:program}} title={'Update Program'}>Update <i className="bx bxs-edit"></i></Link>*/}
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th scope="col">Student Name: </th>
                                        <td scope="col">{`${student?.user?.firstName} ${student?.user?.middleName} ${student?.user?.lastName}`}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Registration Number:</th>
                                        <td>{student.registrationNumber}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Sex:</th>
                                        <td>{student?.user?.gender}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Phone Number:</th>
                                        <td>{student?.user?.phoneNumber}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">E-mail:</th>
                                        <td>{student?.user?.email}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Department: </th>
                                        <td>{`${student?.user?.department?.name} (${student?.user?.department?.code})`}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Country: </th>
                                        <td>{`${student?.user?.country?.name} (${student?.user?.country?.code})`}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    );
}

export default ViewStudent;