import React, { createContext, useContext, useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Label,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { GENERATE_ALLOCATION_XLS } from "../Mutations/InstructorMutations"
import { base64toBlob } from "./base64toBlob"
import { InstructorCourseDetailsContext } from "./InstructorCourseDetail"
import { CourseWeights, courseMarkedOut, ExcelSortingFormat } from "./CourseWeight"

const CourseExcelDownloder = ({ isOpen, closeModal }) => {

  const { examCategoryID, examCategoryName, assessmentNumber, allocationUID } =
    useContext(InstructorCourseDetailsContext) // use context to retrieve data from Instructor

  const courseWeight = CourseWeights(); // course weight

  const markedOut = courseMarkedOut(); // Marked out 

  const excelFormat = ExcelSortingFormat();

  const [templateDownload] = useMutation(GENERATE_ALLOCATION_XLS, {
    client: registrationGraphQLClient,
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      outOff: "",
      assessmentWeight: 1,
      excelSorting: "REGNO",
      
    },

    validationSchema: Yup.object().shape({
      outOff: Yup.number().required("Out Of value is required"),
      assessmentWeight: Yup.number().required("Weight is required"),
      // excelSorting: Yup.number().required("Excel Sorting Option is required"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      try {
        // console.log("Data to post For Excel: ", assessmentNumber + " " + allocationUID + " " + examCategoryID + " " + examCategoryName + " " + values.outOff)
        // return
        const { data, error } = await templateDownload({
          variables: {
            allocationUid: allocationUID,
            outOff: values.outOff,
            examCategoryId: examCategoryID,
            assessmentWeight: parseInt(values.assessmentWeight),
            assessmentNumber: assessmentNumber,
            excelSorting: values.excelSorting,
          },
        })

        const base64Data = data?.generateAllocationXlsTemplate?.base64Data

        if (base64Data) {
          const blob = base64toBlob(base64Data)
          const url = URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = url
          // link.setAttribute("download", "template.xlsx")
          link.setAttribute("download", examCategoryName + ".xlsx")
          closeModal()
          validation.resetForm()
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          URL.revokeObjectURL(url)
        } else {
          console.error("Base64 data not found in the response")
        }
      } catch (error) {
        showToast("Error while generating template:", error)
        console.error("Error while generating template:", error)
      }
      console.log(values)
    },
  })

  return (
    <Modal isOpen={isOpen || !!""} backdrop={"static"} id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {"Exam marked out of? "}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            closeModal()
            validation.resetForm()
          }}
          aria-label="Close"
        ></button>
      </div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="modal-body">
          <div className="mb-3">
            <label htmlFor="name" className="col-form-label">
              Select Mark Out Of
            </label>
            <Select
              id="outOff"
              name="outOff"
              onChange={e => {
                validation.setFieldValue("outOff", e.value)
              }}
              options={markedOut}
              className="select2-selection"
              styles={{
                menu: base => ({
                  ...base,
                  position: "absolute",
                  zIndex: 9999,
                }),
              }}
            />
            <Input
              type="hidden"
              name="outOff"
              className="form-control"
              id="firstname"
              placeholder="Enter Out of Mark"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.outOff || ""}
              invalid={
                validation.touched.outOff && validation.errors.outOff
                  ? true
                  : false
              }
            />
            {validation.touched.outOff && validation.errors.outOff ? (
              <FormFeedback type="invalid">
                {validation.errors.outOff}
              </FormFeedback>
            ) : null}
          </div>
          <div className="mb-3">
            <label htmlFor="assessmentWeight" className="col-form-label">
              Weight
            </label>
            <Select
              id="assessmentWeight"
              name="assessmentWeight"
              onChange={e => {
                validation.setFieldValue("assessmentWeight", e.value)
              }}
              options={courseWeight}
              className="select2-selection"
              styles={{
                menu: base => ({
                  ...base,
                  position: "absolute",
                  zIndex: 9999,
                }),
              }}
              defaultInputValue="1"
              defaultValue={parseInt(1)}
            />
            <Input
              type="hidden"
              name="assessmentWeight"
              className="form-control"
              id="assessmentWeight"
              placeholder="Enter Out of Mark"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.assessmentWeight || ""}
              invalid={
                validation.touched.assessmentWeight &&
                validation.errors.assessmentWeight
                  ? true
                  : false
              }
            />
            {validation.touched.assessmentWeight &&
            validation.errors.assessmentWeight ? (
              <FormFeedback type="invalid">
                {validation.errors.assessmentWeight}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <label htmlFor="excelSorting" className="col-form-label">
              Sort Excel By?
            </label>
            <Select
              id="excelSorting"
              name="excelSorting"
              onChange={e => {
                validation.setFieldValue("excelSorting", e.value)
              }}
              options={excelFormat}
              className="select2-selection"
              styles={{
                menu: base => ({
                  ...base,
                  position: "absolute",
                  zIndex: 9999,
                }),
              }}
            />
            <Input
              type="hidden"
              name="excelSorting"
              className="form-control"
              id="excelSorting"
              placeholder="Excel Sorting Selection"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.excelSorting || ""}
              invalid={
                validation.touched.excelSorting &&
                validation.errors.excelSorting
                  ? true
                  : false
              }
            />
            {validation.touched.excelSorting &&
            validation.errors.excelSorting ? (
              <FormFeedback type="invalid">
                {validation.errors.excelSorting}
              </FormFeedback>
            ) : null}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              closeModal()
              validation.resetForm()
            }}
          >
            Close
          </button>
          <SubmitButtonHelper
            name="Submit"
            type="primary"
            formik={validation}
          />
        </div>
      </Form>
    </Modal>
  )
}

export default CourseExcelDownloder
