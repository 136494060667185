import React from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    CardSubtitle,
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown, Label, Input, FormFeedback, Form,
} from "reactstrap"

import {Table, Thead, Tbody, Tr, Th, Td, td} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import * as Yup from "yup"
//Import Breadcrumb
import { useFormik } from "formik"
import LayoutHelper from "helpers/LayoutHelper"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {registrationGraphQLClient} from "../../../Authentication/ApolloClient";
import {GET_ALL_PROGRAMS} from "../../../Programs/Queries/ProgramQuaries";
import {GET_ACADEMICYEARS} from "../../../AcademicYears/Queries/AcademicYearsQueries";
import {GET_PROGRAM_SEMESTER_YEAR} from "../../../ProgramSemister/Queries/ProgramSemisterQueries";
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper";
import Select from "react-select";
import {GET_PROGRAM_COURSE_BY_SEMESTER_WITH_HEADSHIP} from "../../../ProgramSemister/Queries/ProgramCourseQueries";
import {GENERATE_SEMESTER_COURSE_RESULTS_XLS} from "../Queries/ResultReportQueries";
import {paginationHelper} from "../../../../helpers/Functions/GraphqlUpdateFunction";
import showToast from "../../../../helpers/ToastHelper";
import {DownloadExcelFile} from "../../../../helpers/DownloadExcelFile";


const breadcrumbItem = [
    {
        title: "Semester Course Results",
        path: "",
        isActive: true,
    },
]

const SemesterCourseResults = () => {
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            programUid: "",
            academicYearUid: "",
            semesterUid:"",
            courseUid:"",
        },
        validationSchema: Yup.object().shape({
            programUid: Yup.string().required("Program is required"),
            courseUid: Yup.string().required("Course is required"),
            semesterUid: Yup.string().required("Semester is required"),
            // staffUid: Yup.array().required("Staff is required").min(1, 'Select at least one Staff')
            // academicYearUid: Yup.string().required("Academic year is required"),
        }),
        onSubmit: (values,{setSubmitting}) => {
            getCourseResults({
                variables: {
                    programCourseUid: values.courseUid,
                },
                client: registrationGraphQLClient,
                onCompleted: ({ getSemesterCourseResults }) => {
                    showToast(
                        getSemesterCourseResults.message,
                        getSemesterCourseResults.code === 8000 ? "success" : "error"
                    )
                    if (
                        getSemesterCourseResults.code === 8000) {
                        DownloadExcelFile(getSemesterCourseResults?.data?.base64Data,getSemesterCourseResults?.data?.fileName)
                    }
                    setSubmitting(false)
                },
                onError: error => {
                    console.error("Mutation error:", error)
                    setSubmitting(false)
                },
            })

        },

    });

    let [
        getCourseResults,
        { loading: resultLoading, error: resultError, data: resultData },
    ] = useLazyQuery(GENERATE_SEMESTER_COURSE_RESULTS_XLS, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    const searchValues = {
        search: "",
    }

    const searchSummarySchema = Yup.object().shape({
        search: Yup.string().required(""),
    })

    const formikSearch = useFormik({
        initialValues: searchValues,
        validationSchema: searchSummarySchema,
    })

    const [
        searchPrograms,
        { loading: programSearchLoading, error: searchError, data: searchProgram },
    ] = useLazyQuery(GET_ALL_PROGRAMS, {client: registrationGraphQLClient})

    const pagination = { offset: 0, limit: 20, search: null }

    const { loading:programLoading, error:programError, data:programData } = useQuery(GET_ALL_PROGRAMS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: pagination,
        },
    })

    let programs = programData?.getAllPrograms?.data?.items
    if (searchProgram?.getAllPrograms?.data)
        programs = searchProgram?.getAllPrograms?.data?.items

    const {loading: academicYearLoading,error: academicYearError,
        data: academicYear,
        refetch:acRef,
    } = useQuery(GET_ACADEMICYEARS, {
        client: registrationGraphQLClient,
        variables: {
            pagination: paginationHelper,
        },
    })

    const [
        searchAcademicYear,
        { loading: yearSearchLoading, error: yearSearchError, data: searchYear },
    ] = useLazyQuery(GET_ACADEMICYEARS, {client: registrationGraphQLClient})

    let academicYears = academicYear?.getAcademicYears?.data?.items
    if (searchYear?.getAcademicYears?.data)
        academicYears = searchYear?.getAcademicYears?.data?.items


    let [
        searchSemesters,
        { loading: semesterLoading, error: searchSemError, data: semesterData },
    ] = useLazyQuery(GET_PROGRAM_SEMESTER_YEAR, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    let semesters = []

    let courses = []
    if (semesterData?.getProgramSemesters?.data)
        semesters = semesterData?.getProgramSemesters?.data?.items

    const search = (value,type) => {
        // console.log(type)
        // console.log(validation.values.programUid)
        let data = {
            search: value,
        }
        if (type === 'program'){
            // console.log(value)
            if(value !== undefined){
                if(value.length < 4) return false
                semesters = []
                courses = []
                searchPrograms({
                    variables: { pagination: { ...pagination, ...data } },
                })
            }
        }else if (type === 'semester'){
            if(validation.values.academicYearUid === '') {
                searchSemesters({
                    variables: {pagination: {...pagination}, programUid: value},
                })
            }else{
                searchSemesters({
                    variables: { pagination: { ...pagination }, academicYearUid: validation.values.academicYearUid,programUid:value },
                })
            }
        }else if (type === 'academicYear' && validation.values.programUid){
            if(value !== undefined) {
                if (value.length < 4) return false
                if(value === ''){
                    searchSemesters({
                        variables: { pagination: { ...pagination }, programUid: validation.values.programUid },
                    })
                }else {
                    searchSemesters({
                        variables: { pagination: { ...pagination }, programUid: validation.values.programUid,academicYearUid:value },
                    })
                }
            }
        }else if(type === 'courses'){
            // console.log(value)
            // console.log(type)
            searchCourse({
                variables: { programSemisterUid: value },
            })
        }
    }

    let [
        searchCourse,
        { loading: courseLoading, error: courseError, data: courseData },
    ] = useLazyQuery(GET_PROGRAM_COURSE_BY_SEMESTER_WITH_HEADSHIP, {
        client: registrationGraphQLClient,
        fetchPolicy:'no-cache'
    })

    // if (courseData?.getProgramCourseByProgramSemesterUid?.data)
    courses = courseData?.getProgramCourseByProgramSemesterUidWithHeadship?.data
    return (
        <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Course Results">
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                            <Label>Program</Label>
                                            <Select
                                                // value={selectedMulti2}
                                                styles={{
                                                    menu: base => ({
                                                        ...base,
                                                        position: "absolute",
                                                        zIndex: 9999
                                                    })
                                                }}
                                                onChange={e => {
                                                    if (e) {
                                                        validation.setFieldValue("programUid", e.value)
                                                        search(e.value, 'semester')
                                                    } else {
                                                        validation.setFieldValue("programUid", "")
                                                        semesterData = []
                                                    }
                                                }}
                                                onInputChange={e => {
                                                    search(e, 'program')
                                                }}

                                                options={programs?.map(pro => ({
                                                    value: pro.uid,
                                                    label: pro.name + " (" + pro.code + ")",
                                                }))}
                                                className="select2-selection"
                                                isClearable={true}
                                                isLoading={programLoading || programSearchLoading}
                                            />
                                            <Input
                                                name="programUid"
                                                placeholder=""
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.programUid}
                                                invalid={
                                                    !!(
                                                        validation.touched.programUid &&
                                                        validation.errors.programUid
                                                    )
                                                }
                                            />
                                            {validation.touched.programUid &&
                                            validation.errors.programUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.programUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label>Academic Year</Label>
                                            <Select
                                                // value={selectedGroup}
                                                onChange={(e) => {
                                                    if (e) {
                                                        validation.setFieldValue("academicYearUid", e.value)
                                                    } else {
                                                        validation.setFieldValue("academicYearUid", "")
                                                    }
                                                }}
                                                onInputChange={e => {
                                                    search(e, 'academicYear')
                                                }}
                                                options={academicYears?.map(year => ({
                                                    value: year.uid,
                                                    label: year.name,
                                                }))}

                                                className="select2-selection"
                                                isLoading={academicYearLoading || yearSearchLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="academicYearUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.academicYearUid}
                                                invalid={
                                                    !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                                }
                                            />
                                            {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.academicYearUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label>Program Semesters</Label>
                                            <Select
                                                onChange={e => {
                                                    if (e) {
                                                        validation.setFieldValue("semesterUid", e.value)
                                                        search(e.value, 'courses')
                                                    } else {
                                                        validation.setFieldValue("semesterUid", "")
                                                        courseData = []
                                                    }
                                                }}


                                                options={semesters?.map(sem => ({
                                                    value: sem.uid,
                                                    label: "YEAR - " + sem?.studyYear + " SEMESTER - " + sem.semester + " (" + sem?.academicYear?.name + ")",
                                                }))}
                                                className="select2-selection"
                                                isLoading={semesterLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="semesterUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.semesterUid || ""}
                                                invalid={
                                                    !!(validation.touched.semesterUid && validation.errors.semesterUid)
                                                }
                                            />
                                            {validation.touched.semesterUid && validation.errors.semesterUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.semesterUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label>Courses</Label>
                                            <Select
                                                onChange={(e) => {
                                                    if (e) {
                                                        validation.setFieldValue('courseUid', e.value)
                                                    } else {
                                                        validation.setFieldValue('courseUid', "")
                                                    }
                                                }}
                                                options={courses?.map(course => ({
                                                    value: course.uid,
                                                    label: course?.name + " (" + course?.code + ")",
                                                }))}
                                                className="select2-selection"
                                                isLoading={courseLoading}
                                                isClearable={true}
                                            />
                                            <Input
                                                name="courseUid"
                                                type="hidden"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.courseUid}
                                                invalid={
                                                    !!(validation.touched.courseUid && validation.errors.courseUid)
                                                }
                                            />
                                            {validation.touched.courseUid && validation.errors.courseUid ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.courseUid}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="float-end">
                                            <SubmitButtonHelper type={'primary'} name={'Submit'}
                                                                formik={validation}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </LayoutHelper>
    )
}

export default SemesterCourseResults
