import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "../../Utility/pages-404";
import StudentIntentionToSubmit from "../Views/StudentIntentionToSubmit";
const IntentionToSubmitRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<StudentIntentionToSubmit />} />
            {/*<Route path="/view" element={<ViewStudentSeminar />} />*/}
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default IntentionToSubmitRoutes;