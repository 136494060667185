import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button, Alert, Col, Row } from "reactstrap"
import * as Yup from "yup"
import { CREATE_PROGRAM_CHANGE } from "../Mutations/HoDMutations"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_ALL_PROGRAM_CHANGE } from "../Queries/HoDQueries"
import { HoDPostponeContext } from "./HoDPostpone"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"

const HoDPostponeModal = () => {

  const { updateProgramChangeData, setUpdateProgramChangeData } = useContext(HoDPostponeContext)
  const [campus] = useMutation(CREATE_PROGRAM_CHANGE, {client: registrationGraphQLClient})
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_ALL_PROGRAM_CHANGE
  const variables = {  }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateProgramChangeData ? updateProgramChangeData.name : "",

    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Remarks is required"),
      approve: Yup.string().required("Please Choose to Reject or Approve"),

      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, {setSubmit}) => {
      campus({
        variables: {
          inputs: [{ ...values, uid: updateProgramChangeData?.uid }],
        },
        client:registrationGraphQLClient,
        refetchQueries: [
          {
            query: GET_ALL_PROGRAM_CHANGE,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerStudentProgramChange }) => {
          //updateFunction(client.cache, { data: { registerCountries } });
          if (registerStudentProgramChange.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateProgramChangeData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateProgramChangeData(true) // Close the modal
            setSubmit(false)
          }
          showToast(
            registerStudentProgramChange.message,
            registerStudentProgramChange.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Modal
        size="lg"
        isOpen={modalOpen || !!updateProgramChangeData}
        backdrop={"static"}
        id="staticBackdrop"
      >
      
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramChangeData ? "Approve/Reject Progam Change" : "Add Progam Change"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>

        </div>
        <Alert color="info">
          <p><b>Registration Number:</b> {updateProgramChangeData?.currentRegistrationNumber}</p>
          <p><b>Full Name:</b> {updateProgramChangeData?.currentRegistrationNumber}</p>
          <p><b>Current Program:</b> {updateProgramChangeData?.currentProgram?.name}</p>
          <p><b>Requested Program:</b> {updateProgramChangeData?.newProgram?.name}</p>
          <p><b>Student Reason:</b> {updateProgramChangeData?.reason}</p>
          <p><b>Status:</b> {updateProgramChangeData?.approveStatus}</p>
        </Alert>          
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <Row>
            <Col md="12" lg="12">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="textarea"
                name="name"
                className="form-control"
                id="name"
                rows={5} // Specify the number of rows
                cols={40} // Specify the number of columns            
                placeholder="Enter Reason for Approval or Rejection"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            </Col>
            </Row>
            <Row>
            <Col md="6" lg="6">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Approve
              </label>
              <Input
                type="radio"
                name="approve"
                className="form-control"
                id="approve"
                placeholder="Approve"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />              
              {validation.touched.approve && validation.errors.approve ? (
                <FormFeedback type="invalid">
                  {validation.errors.approve}
                </FormFeedback>
              ) : null}
            </div>   
            </Col>
            <Col md="6" lg="6"> 
            <div className="mb-3">
              <label htmlFor="reject" className="col-form-label">
                Reject
              </label>
              <Input
                type="radio"
                name="approve"
                className="form-control"
                id="rejecapprovet"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
              />              
              {validation.touched.approve && validation.errors.approve ? (
                <FormFeedback type="invalid">
                  {validation.errors.approve}
                </FormFeedback>
              ) : null}
            </div> 
            </Col>
            </Row>              
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateProgramChangeData(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default HoDPostponeModal
