//REGISTE
import { gql } from "@apollo/client"

const GET_MARITAL_STATUSES = gql`
  query GetMaritalStatus($pagination: PaginationInput!) {
    getMaritalStatuses(pagination: $pagination) {
      status
      code
      message
      data {
        items {
          id
          uid
          name
        }
        totalCount
      }
    }
  }
`;

export { GET_MARITAL_STATUSES }