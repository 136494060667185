import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_WARDS } from "../Queries/WardQueries"
import { CREATE_WARD } from "../Mutations/WardMutation"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { GET_DISTRICT } from "Modules/Districts/Queries/DistrictQueries"
import { WardContext } from "./WardList"
import Select from "react-select"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {uaaGraphQLClient} from "Modules/Authentication/ApolloClient"


const WardModal = () => {
  const { updateWardData, setUpdateWardData } = useContext(WardContext)
  const [Ward] = useMutation(CREATE_WARD)
  const [modalOpen, setModalOpen] = useState(false)
  const query = GET_WARDS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const { loading, error, data } = useQuery(GET_DISTRICT, {
    client:uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const disttricts = data?.getDistricts?.data.items

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: updateWardData ? updateWardData.name : "",
      code: updateWardData ? updateWardData.code : "",
      districtUid: updateWardData ? updateWardData.districtUid : "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Ward name is required"),
      code: Yup.string().required("Ward short name is required"),
      districtUid: Yup.string().required("District is required"),
      //   desc: Yup.string().required("Programe TCU code is required"),
    }),

    onSubmit: (values, { setResubmitting }) => {
      Ward({
    client:uaaGraphQLClient,
        variables: {
          inputs: [{ ...values, uid: updateWardData?.uid }],
        },
        refetchQueries: [
          {
            query: GET_WARDS,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ registerWards }) => {
          if (registerWards.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateWardData(false) // Close the modal
          } else {
            setModalOpen(true)
            setUpdateWardData(true) // Close the modal
            setResubmitting(false)
          }
          showToast(
            registerWards.message,
            registerWards.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Ward
      </Button>
      <Modal
        isOpen={modalOpen || !!updateWardData}
        backdrop={"static"}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateWardData ? "Edit Ward" : "Add New Ward"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-3">
              <label>District</label>
              <Select
                onChange={e => {
                  validation.setFieldValue("districtUid", e.value)
                }}
                options={disttricts?.map(district => ({
                  value: district.uid,
                  label: district.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
              />
              <Input
                name="districtUid"
                placeholder="Select Region"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.districtUid ||
                  updateWardData?.districtUid ||
                  ""
                }
                invalid={
                  !!(
                    validation.touched.districtUid &&
                    validation.errors.districtUid
                  )
                }
              />
              {validation.touched.districtUid &&
              validation.errors.districtUid ? (
                <FormFeedback type="invalid">
                  {validation.errors.districtUid}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Name:
              </label>
              <Input
                type="text"
                name="name"
                className="form-control"
                id="firstname"
                placeholder="Enter Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
                invalid={
                  validation.touched.name && validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name && validation.errors.name ? (
                <FormFeedback type="invalid">
                  {validation.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="col-form-label">
                Code:
              </label>
              <Input
                type="text"
                name="code"
                className="form-control"
                id="firstname"
                placeholder="Short Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.code || ""}
                invalid={
                  validation.touched.code && validation.errors.code
                    ? true
                    : false
                }
              />
              {validation.touched.code && validation.errors.code ? (
                <FormFeedback type="invalid">
                  {validation.errors.code}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateWardData(false)
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default WardModal
