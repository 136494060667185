import React from "react"
import "assets/scss/card.scss"
import { Link } from "react-router-dom"

const Card = ({ card_image, title, link }) => {
  return (
    <div className="col-lg-3 col-md-3 col-12">
      <Link to={link}>
        <div className="card image-cards">
          <img src={card_image} className="card-img-top custom-card-img" alt="..." />
          <div className="card-body custom-card-body">
            <h5 className="card-title">{title}</h5>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
