import {Route, Routes} from "react-router-dom";
import AsimsDashboard from "../../Modules/ASIMS/views/asimsDashboard";
import Pages404 from "../../Modules/Utility/pages-404";
import React from "react";
import CampusRoutes from "../../Modules/Campus/Routes/CampusRoutes";
import DepartmentRoutes from "../../Modules/Departments/Routes/DepartmentRoutes";
import CollegeRoutes from "../../Modules/Colleges/Routes/CollegeRoutes";
import CountryRoutes from "../../Modules/Country/Routes/CountryRoutes";
import WardRoutes from "../../Modules/Wards/Routes/WardRoutes";
import LookupRoutes from "../../Modules/Lookups/Routes/LookupRoutes";
import RegionRoute from "../../Modules/Regions/Routes/RegionRoute";
import DistrictRoutes from "../../Modules/Districts/Routes/DistrictRoutes";
import MaritalStatusRoutes from "../../Modules/MaritalStatus/Routes/MaritalStatusRoutes";
import BankRoutes from "../../Modules/Banks/Routes/BankRoutes";
import SponsorRoutes from "../../Modules/Sponsors/Routes/SponsorRoutes";
import WorkflowRoutes from "../../Modules/Workflow/Routes/WorkflowRoutes";
import StateRoutes from "../../Modules/State/Routes/StateRoutes";
import SignaturePadRoutes from "../../Modules/SignaturePad/Routes/SignaturePadRoutes";

const SystemSettingRoutes = () => {
    return (
        <Routes>
            <Route path={"dashboard"} element={<AsimsDashboard /> } />
            <Route path={"/campuses/*"} element={<CampusRoutes /> } />
            <Route path={"/colleges/*"} element={<CollegeRoutes /> } />
            <Route path={"/departments/*"} element={<DepartmentRoutes /> } />
            <Route path={"/country/*"} element={<CountryRoutes /> } />
            <Route path={"/wards/*"} element={<WardRoutes /> } />
            <Route path={"/lookups/*"} element={<LookupRoutes /> } />
            <Route path={"/regions/*"} element={<RegionRoute /> } />
            <Route path={"/districts/*"} element={<DistrictRoutes /> } />
            <Route path={"/marital-status/*"} element={<MaritalStatusRoutes /> } />
            <Route path={"/banks/*"} element={<BankRoutes /> } />
            <Route path={"/sponsors/*"} element={<SponsorRoutes /> } />
            <Route path={"/workflow/*"} element={<WorkflowRoutes /> } />
            <Route path={"/state/*"} element={<StateRoutes /> } />
            <Route path={"/campuses/*"} element={<CampusRoutes /> } />
            <Route path={"/campuses/*"} element={<CampusRoutes /> } />
            <Route path={"/campuses/*"} element={<CampusRoutes /> } />
            <Route path={"/campuses/*"} element={<CampusRoutes /> } />
            <Route index={true} path="/signaturepad/*" element={<SignaturePadRoutes />} />




            <Route path="*" element={<Pages404/>} />
        </Routes>
    );
}

export default SystemSettingRoutes;