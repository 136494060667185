import PropTypes from "prop-types";
import React from "react";

import Breadcrumbs from "../components/Common/Breadcrumb"

const LayoutHelper = props => {
  const {breadcrumbItem,pageTitle } = props
  //meta title
  document.title = pageTitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem={breadcrumbItem} />
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};

LayoutHelper.propTypes = {
  pageTitle: PropTypes.string,
  breadcrumbItem: PropTypes.array
};

export default LayoutHelper;
