import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

import sualogo from "../../assets/images/logo/sua.png"
import showToast from "helpers/ToastHelper"
import { uaaRestUrl } from "helpers/UrlHelper"
import SubmitButtonHelper from "../../helpers/SubmitButtonHelper";
import toastHelper from "../../helpers/ToastHelper";
import EsbFooter from "../../components/EsbDashboardLayout/EsbFooter"

const ForgetPasswordPage = props => {

  // toastHelper('Go to Admission officers so that they will reset your password!!!','error')
  // return;
  
  const navigate = useNavigate()

  const [apiResponse, setApiResponse] = useState({
    success: false,
    error: null,
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values,{setSubmitting}) => {
      fetch(uaaRestUrl + "/user/reset/password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: validation.values.email }),
      }).then(response => response.json()).then(data => {
        if(data.status){
          navigate("/")
          showToast(
              data.message,
              data.code === 8000 ? "success" : "error"
          )
        }else{
          toastHelper(data.message,'error')
        }
        setSubmitting(false)
      })
      .catch((error) => {
        toastHelper('An error occurred while making the request.','error')
        console.error('Error submitting data:', error);
        setSubmitting(false)
      });


      // dispatch(userForgetPassword(values, props.history))
    },
    // onSubmit: values => {
    //
    //   dispatch(userForgetPassword(values, props.history))
    // },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden"
                style={{
                  border: "2px solid #d5e3d5",
                }}
              >
                <div
                  className="text-center"
                  style={{
                    backgroundColor: "#d5e3d5",
                  }}
                >
                  <Row>
                    <Col xs={12}>
                      <div className="text-primary p-2">
                        <img src={sualogo} height={100} alt="" className=" " />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <h2 style={{ fontFamily: "Roboto" }}>SUA-ESB</h2>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email Address</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            !!(validation.touched.email && validation.errors.email)
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <SubmitButtonHelper
                              type="primary"
                              name="Request Reset Link"
                              formik={validation}
                          />
                        </Col>

                      </Row>
                      {/* <Row>
                        <Col className="mb-3">
                          <div className="mt-4 text-center">
                            <Link to="/forgot-email" className="text-muted">
                              <i className="mdi mdi-lock me-1" />
                              Forgot your E-mail?
                            </Link>
                          </div>
                        </Col>
                      </Row> */}
                    </Form>
                  </div>

                </CardBody>
              </Card>
              <div className="text-center">
                <p>
                  <Link to="/login" className="font-weight-medium text-primary">
                    Go back to Login
                  </Link>
                </p>
                <EsbFooter/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
