import { Link } from "react-router-dom"
import React from "react"

const StudentLinks = ({ hasAuthority }) => {
  return (
    <>
      <li>
        <Link to="/student/dashboard">
          <i className="bx bx-home-circle"></i>
          <span>Dashboard</span>
        </Link>
      </li>

      {hasAuthority("VIEW_STUDENT_PROFILE_MENU") && (
        <>
          <li>
            <Link to="/student/moodle-courses">
              <i className="bx bx-laptop"></i>
              <span>Moodle</span>
            </Link>
          </li>
        </>
      )}

      {hasAuthority("VIEW_SEMESTER_REGISTRATION_MENU") && (
        <li>
          <Link to="/student/registration/semester">
            <i className="bx bx-calendar"></i>
            <span>Semester Registration</span>
          </Link>
        </li>
      )}

      {hasAuthority("VIEW_SEMESTER_REGISTRATION_MENU") && (
        <li>
          <Link to="/student/registration/examination">
            <i className="bx bx-pen"></i>
            <span>Exam Registration</span>
          </Link>
        </li>
      )}

      {hasAuthority("VIEW_SEMESTER_REGISTRATION_MENU") && (
        <li>
          <Link to="/student/exam_ticket">
            <i className="bx bx-id-card"></i>
            <span>Examination Ticket</span>
          </Link>
        </li>
      )}

      {hasAuthority("VIEW_SEMESTER_REGISTRATION_MENU") && (
        <li>
          <Link to="/student/update-profile">
            <i className="bx bx-up-arrow"></i>
            <span>Update My Information</span>
          </Link>
        </li>
      )}

    {hasAuthority("VIEW_CW_RESULTS_MENU") && (
        <li>
            <Link to="/student/coursework">
                <i className="bx bx-file"></i>
                <span>Coursework Results</span>
            </Link>
        </li>
    )}
    {hasAuthority("VIEW_EXAMINATION_REGISTRATION_MENU") && (
        <li>
            <Link to="/student/registration/examination">
                <i className="bx bx-edit-alt"></i>
                <span>Examination Registration</span>
            </Link>
        </li>
    )}


        {hasAuthority("VIEW_EXAM_RESULTS_MENU") && (
            <li>
                <Link to="/student/examinations">
                    <i className="bx bx-test-tube"></i>
                    <span>Exam Results</span>
                </Link>
            </li>
        )}

        {hasAuthority("VIEW_CHANGE_PROGRAM_MENU") && (
            <li>
                <Link to="/student/applications/change_program">
                    <i className="bx bx-transfer"></i>
                    <span>Change Programme</span>
                </Link>
            </li>
        )}

        {hasAuthority("VIEW_STUDY_POSTPONEMENT_MENU") && (
            <li>
                <Link to="/student/applications/postponement">
                    <i className="bx bx-time-five"></i>
                    <span>Study Postponement</span>
                </Link>
            </li>
        )}



      {hasAuthority("VIEW_STUDENT_TEACHING_ASSESSMENT_MENU") && (
        <li>
          <Link to="/student/teaching_assessment">
            <i className="bx bx-book"></i>
            <span>Teaching Assessment</span>
          </Link>
        </li>
      )}

      {hasAuthority("VIEW_DISSERTATION_MENU") && (
        <li>
          <Link to="/student/dissertation">
            <i className="bx bxs-file"></i>
            <span>Dissertation/Thesis</span>
          </Link>
        </li>
      )}

      {hasAuthority("VIEW_NHIF_MENU") && (
        <li>
          <Link to="/student/nhif">
            <i className="bx bx-id-card"></i>
            <span>NHIF Registration</span>
          </Link>
        </li>
      )}

      <li>
        <Link to="/student/signaturepad">
          <i className="bx bx-pen"></i>
          <span>Submit Signature</span>
        </Link>
      </li>
    </>
  )
}

export default StudentLinks
