import { color } from "d3-color"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Row, Col, Card, CardBody, CardSubtitle } from "reactstrap"
import { Link } from "react-router-dom"
import { GET_STUDENT_COURSE_TO_REGISTER } from "Modules/Students/Queries/Registration/StudentSemesterRegistrationQuery" 
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { useQuery } from "@apollo/client"
import { LogedInStudent } from "Modules/Students/LogedInStudent"
import LayoutHelper from "helpers/LayoutHelper"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import StudentCourses from "./StudentCourses"

const breadcrumbItem = [
  {
    title: "Teaching assessment",
    path: "",
    isActive: true,
  },
]

const TeachingAssessmentView = () => {
  const logedIn = LogedInStudent()
  const student = logedIn?.student
  const study_year =
    student?.studyYear === 0 ? student?.studyYear + 1 : student?.studyYear

  const {
    loading,
    error: error,
    data: fetchCourses,
  } = useQuery(GET_STUDENT_COURSE_TO_REGISTER, {
    client: registrationGraphQLClient,
    skip: !student?.uid || !student?.programmeUid,
    variables: {
      input: {
        studyYear: study_year,
        semester: 1,
        // registrationNumber:student?.registrationNumber,
        studentUid: student?.uid,
        programUid: student?.programmeUid,
      },
    },
  })

  // let allCourses = fetchCourses?.getStudentCourseToRegister?.data?.courseToRegister
  let registeredCourses =
    fetchCourses?.getStudentCourseToRegister?.data?.courseRegistered

  return (
    <LayoutHelper
      breadcrumbItem={breadcrumbItem}
      pageTitle="Courses List"
    >
      {/* <Row>
        <Col> */}
      {loading ? (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
      ) : (
        <Row>
          {registeredCourses != "" ? (
            <>
              {registeredCourses?.map((course, index) => (
                <Col xl={3} xs={3} sm={3} md={3} lg={3} key={index}>
                  {/* <Card className="text-center primary text-muted">
                        <CardBody> */}

                  <StudentCourses courseData={course} key={course?.uid} />

                  {/* </CardBody>
                      </Card>  */}
                </Col>
              ))}
            </>
          ) : (
            <Col md={12} sm={12}>
              <Card>
                <CardBody>
                  <div className="alert alert-danger">
                    You have not registered for any courses as of now. If you
                    encounter any problems, please contact your Head of
                    Department for further assistance
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}

      {/* {JSON.stringify(registeredCourses)} */}

      {/* {registeredCourses?.map((item, index) => (

            <div>
            <MoodleUrlComponent 
                key={item?.programCourse} 
                moodleId={item?.programCourse?.course?.moodleId} />       
            </div>


            ))} */}

      {/* </CardBody>
        </Card> */}
      {/* </Col>
   </Row> */}
    </LayoutHelper>
  )
}

export default TeachingAssessmentView
