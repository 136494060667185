import { gql } from "@apollo/client"

const GET_OUTREACH_BY_STAFF_UID = gql`
  query getOutreachByStaffUid($staffUid: String!) {
    getOutreachByStaffUid(staffUid: $staffUid) {
      data {
        outReach {
          description
          endDate
          objective
          startDate
          targetAudience
          uid
        }
        staffUid
      }
      code
      message
      status
    }
  }
`

const GET_OUTREACH_ACTIVITIES = gql`
  query getOutreachList {
    getOutreachList {
      code
      message
      status
      data {
        description
        endDate
        objective
        startDate
        targetAudience
        uid
      }
    }
  }
`

export { GET_OUTREACH_BY_STAFF_UID, GET_OUTREACH_ACTIVITIES }
