import React, { createContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardSubtitle,
  Button,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import LayoutHelper from "./../../../../helpers/LayoutHelper";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient";
import { showDeleteWarning } from "helpers/DeleteWaringHelper";
import { GET_STUDY_POSTPONEMENT_REQUESTS } from '../../../Students/Queries/Application/StudentApplicationQueries'
import { APPROVE_STUDY_POSTPONEMENT_REQUEST } from "Modules/Instructor/Mutations/InstructorMutations";
import { REJECT_STUDY_POSTPONEMENT_REQUEST } from "Modules/Instructor/Mutations/InstructorMutations";
import { GET_STUDENT } from "Modules/Users/Queries/UserQuery";
import { RECORD_APPROVAL_ACTION } from './../../Mutations/InstructorMutations'
import { token } from "helpers/UrlHelper";

 
const breadcrumbItem = [
  {
    title: 'Study Postponement Requests',
    path: '',
    isActive: true,
  },
];

export const StudyPostponementContext = createContext() //Create data to transfer to child modal

const StudyPostponementRequests = () => {
  const [studyPostponement, setStudyPostponement] = useState(null); //Get data for editing
  const [selectedRequest, setSelectedRequest] = useState(null) //Get data for deleting

  const {
    loading: userLoading,
    error: userError,
    data: userDetails,
    } = useQuery(GET_STUDENT, {client: uaaGraphQLClient,variables: {username: token?.username,},}) 
    const Student = userDetails?.getUserByUsername?.data;

  const { loading:requestsLoading, 
    error:requestsError, 
    data:requestsData, 
    refetch } = useQuery(GET_STUDY_POSTPONEMENT_REQUESTS, {
    client:uaaGraphQLClient,
    variables: {
      // pagination,
    },
    fetchPolicy: 'cache-first'
  })

  const [recordApprovalAction] = useMutation(RECORD_APPROVAL_ACTION, {
    client: uaaGraphQLClient,
    variables: { uid: selectedRequest?.uid },
    refetchQueries: [
      {
        query: GET_STUDENT_STUDY_POSTPONEMENT_REQUEST,
        variables: {
          studentUid: studentUid,
        },
      },
    ],
  });
  
  // const { loading, 
  //   error, 
  //   data, 
  //   refetch } = useQuery(GET_STUDENT, {
  //   client:uaaGraphQLClient,
  //   variables: {
  //     // pagination,
  //   },
  //   fetchPolicy: 'cache-first'
  // })

  useEffect(() => {
    if (!requestsLoading && requestsData) {

    }
  }, [requestsLoading, requestsData])


  const postponementRequests = [
    {
      id: 1,
      registrationNumber: 'BIT/D/2023/0001',
      postponementReason: 'Professional Development',
      pdfLink: '/path-to-attached-pdf',
      status: 'Pending Approval', // Example status
    },
    // Add more requests here
  ];

  const [approveRequest] = useMutation(APPROVE_STUDY_POSTPONEMENT_REQUEST, {
    client: uaaGraphQLClient,
    variables: { uid: selectedRequest?.uid },
    refetchQueries: [
      {
        query: GET_STUDY_POSTPONEMENT_REQUESTS,
        variables: {
          // studentUid: Student?.uid,
        },
      },
    ],
  })
  
  

  const handleApprovePostponement = (approveRequest) => {
    setSelectedRequest(approveRequest)
    
    // Perform the delete action using the id
    // Update your state or send a request to delete the record
    //console.log(`Deleting record with id ${approveRequest}`);
    // Example:
    // const updatedRequests = postponementRequests.filter((request) => request.id !== id);
    // setPostponementRequests(updatedRequests);
  };

  const [rejectRequest] = useMutation(REJECT_STUDY_POSTPONEMENT_REQUEST, {
    client: uaaGraphQLClient,
    variables: { uid: selectedRequest?.uid },
    refetchQueries: [
      {
        query: GET_STUDY_POSTPONEMENT_REQUESTS,
        variables: {
          // studentUid: Student?.uid,
        },
      },
    ],
  })
  
  const handleRejectPostponement = (rejectRequest) => {
    setSelectedRequest(rejectRequest)
    showDeleteWarning(() => {
      performPostponementDeletion()
    })
    
    // Perform the delete action using the id
    // Update your state or send a request to delete the record
   // console.log(`Deleting record with id ${deleteRequest}`);
    // Example:
    // const updatedRequests = postponementRequests.filter((request) => request.id !== id);
    // setPostponementRequests(updatedRequests);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = student => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [student.uid]: !prevState[student.uid],
    }))
  }

  
  return (

    // <StudyPostponementContext.Provider value={{ studyPostponement, setStudyPostponement }}>
   
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Study Postponement Requests">
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardSubtitle className="mb-1">
                <Row>
                  <Col className="col-6">
                  </Col>
                  {/* <Col className="col-6">
                    <div className="text-sm-end mt-3">
                      <StudentStudyPostponementModel />
                    </div>
                  </Col> */}
                </Row>
              </CardSubtitle>

              <div className="table-rep-plugin">
                <div className="mb-0" data-pattern="priority-columns">
                  <Table id="study-postponement-requests-table" className="table table-striped table-bordered">
                    <Thead>
                      <Tr>
                        <Th>S/No</Th>
                        <Th data-priority="0">Registration No.</Th>
                        <Th data-priority="1">Name</Th>
                        <Th data-priority="1">Postponement Reason</Th>
                        <Th data-priority="2">Attached PDF</Th>
                        <Th data-priority="3">Status</Th>
                        <Th data-priority="4">Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                    {/* fetch data from api */}
                    {/* {data && data.postponementRequests.map((request) => ( */}
                      {postponementRequests.map((request) => (
                        <Tr key={request.id}>
                          <Td>{request.id}</Td>
                          <Td>{request.registrationNumber}</Td>
                          <Td>{request.postponementReason}</Td>
                          <Td>{request.postponementReason}</Td>
                          <Td>
                            <a href={request.pdfLink} target="_blank" rel="noopener noreferrer">
                              View PDF
                            </a>
                          </Td>
                          <Td> {request.status} </Td>
                          <Td>
                                  <Dropdown
                                    isOpen={isDropdownOpen[request.uid]}
                                   toggle={() => toggleDropdown(request)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          handleApprovePostponement()
                                        }}
                                      >
                                        <i
                                          className="fas fa-check"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Approve</span>
                                      </DropdownItem>
                                      
                                      <DropdownItem
                                        onClick={() => {
                                          // setSelectedStaff(Staff)
                                          handleRejectPostponement()
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Reject</span>
                                      </DropdownItem>
                                      
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Modal show={showModel} onHide={() => setShowModel(false)}>
        <StudentStudyPostponementModel closeBtn={closeButton()} />
      </Modal> */}
    </LayoutHelper>
    // </StudyPostponementContext.Provider>
  );
};

StudyPostponementRequests.propTypes = {
  uuid: PropTypes.string,
};

export default StudyPostponementRequests;
