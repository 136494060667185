//REGISTE
import { gql } from "@apollo/client"

const GET_DISTRICT = gql`
query GetDistrict($pagination: PaginationInput!) {
  getDistricts(pagination: $pagination){
    status
    code
    message
    data{
      items{
      id
      uid
      name
      code
      region {
        id
        name
        code
        uid
      }
    }
    totalCount
    }
  }
}  
`;

const GET_DISTRICT_BY_REGION = gql`
  query GetDistrictsByRegion  ($regionUid: String!) {
    getDistrictsByRegion(regionUid: $regionUid) {
      status
      code
      message
      data {
        uid
        name
        code
      }
    }
  }
`

export { GET_DISTRICT, GET_DISTRICT_BY_REGION }