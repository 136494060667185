import ReactApexChart from "react-apexcharts"
import React, { useEffect, useState } from "react"

export const ApexChartApplication = ({ results, year }) => {

  const filteredResults = results?.filter(data => new Date(data.date) >= new Date("2024-07-15")) || [];

  const yAxisData = filteredResults.map(data => data.count);
  const xAxisData = filteredResults.map(data => data.date);

  const data = {

    series: [{
      name: "Applications",
      data: yAxisData
    }],
    options: {
      chart: {
        height: 350,
        type: "bar"
      },
      colors: ['#007bff'], // Primary color
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top" // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val + ""
        },
        offsetY: -20,
        colors: ["#FF4560"],
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },

      xaxis: {
        categories: xAxisData,
        position: "bottom",
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + ""
          }
        }

      },
      title: {
        text: "Admission year, "+year?.name,
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444"
        }
      }
    }


  }
  return (
    <div>
      <div id="chart">
        <ReactApexChart options={data.options} series={data.series} type="bar" height={350} />
      </div>
    </div>
  )
}
