import { gql } from "@apollo/client"

const DELETE_MEDIA_COVERAGES = gql`
  mutation addUpdateEmploymentHistory($input: EmploymentHistoryInput!) {
    addUpdateEmploymentHistory(input: $input) {
      code
      message
      status
      data {
        city
        country
        departmentName
        endDate
        id
        institutionName
        position
        responsibilities
        startDate
        uid
        institutionType {
          code
          id
          name
          uid
        }
      }
    }
  }
`

export { DELETE_MEDIA_COVERAGES }
