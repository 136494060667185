import React, { useContext, useEffect, useRef, useState } from "react"
import {Modal, Form, Input, FormFeedback, Button, Label} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"

import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import {registrationGraphQLClient} from "Modules/Authentication/ApolloClient"
import {ConceptNoteContext} from "./ConceptNote";
import {CREATE_STUDENT_SEMINAR} from "../../../../Lookups/SeminarType/Mutations/SeminarTypeMutations";
import {GET_STUDENT_SEMINAR_TYPE} from "../Queries/StudentDissertationQueries";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const ConceptNoteFormModel = () => {
    const { conceptNoteData, setConceptNoteData,type,student } =
        useContext(ConceptNoteContext)
    const [create] = useMutation(CREATE_STUDENT_SEMINAR)
    const [modalOpen, setModalOpen] = useState(false)



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            title: conceptNoteData ? conceptNoteData.title : "",
            description: conceptNoteData ? conceptNoteData.description : "",
            status:0,
            seminarTypesUid:type.uid,
            studentUid:student?.uid
        },

        validationSchema: Yup.object().shape({
            title: Yup.string().required(`${type?.name} Title is required`),
            description: Yup.string().required(`${type?.name} Abstract is required`),
        }),
        onSubmit: (values, {setSubmitting,resetForm}) => {
            create({
                client:registrationGraphQLClient,
                variables: {
                    inputs: { ...values, uid: conceptNoteData?.uid },
                },
                refetchQueries: [
                  {
                    client: registrationGraphQLClient,
                    query: GET_STUDENT_SEMINAR_TYPE,
                  variables: {
                      inputs:{
                          studentUid:student?.uid,
                          seminarTypeUid:type?.uid
                      },
                  },
                  },
                ],
                skip: !modalOpen,
                onCompleted: ({ registerStudentSeminar }) => {
                    if (registerStudentSeminar.code === 8000) {
                        setModalOpen(false)
                        validation.resetForm()
                    } else {
                        setModalOpen(true)
                        setSubmitting(false)
                    }
                    // console.log('sfasfasfsa')
                    // showToast(
                    //     registerStudentSeminar.message,
                    //     registerStudentSeminar.code === 8000 ? "success" : "error"
                    // )
                },
                onError: error => {
                    // Handle errors
                    console.error("Mutation error:", error)

                    setSubmitting(false)
                },
            })
        },
    })
    return (
        <div className="text-sm-end mt-3">
            <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2 pull-right"
                onClick={() => setModalOpen(true)}
            >
                {!conceptNoteData  ? <i className="mdi mdi-plus-circle-outline me-1" />:<i className="mdi mdi-update me-1" />}
                {!conceptNoteData  ? 'New':'Edit'} {type?.name}
            </Button>
            <Modal
                isOpen={modalOpen}
                backdrop={"static"}
                size={'lg'}
                id="staticBackdrop"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {!!conceptNoteData
                            ? `Edit ${type?.name}`
                            : `Add New ${type?.name}`}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            setModalOpen(false)
                            setConceptNoteData(false)
                            validation.resetForm()
                        }}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return null
                    }}
                >
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="name" className="col-form-label">
                                {type?.name} Title
                            </label>
                            <Input
                                type="text"
                                name="title"
                                className="form-control"
                                id="firstname"
                                placeholder={`Enter ${type?.name} Title`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                    validation.values.title || conceptNoteData?.title || ""
                                }
                                invalid={
                                    validation.touched.title && validation.errors.title
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.title && validation.errors.title ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.title}
                                </FormFeedback>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <Label className="form-label">{type?.name}  Abstract</Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={validation.values.description}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    validation.setFieldValue('description',editor.getData())
                                }}
                            />

                            <Input
                                type="hidden"
                                name="description"
                                className="form-control"
                                id="description"
                                rows={5}
                                placeholder={`Enter ${type?.name} Abstract`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                    validation.values.description || conceptNoteData?.description || ""
                                }
                                invalid={
                                    validation.touched.description && validation.errors.description
                                        ? true
                                        : false
                                }
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.description}
                                </FormFeedback>
                            ) : null}
                        </div>


                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                                setModalOpen(false)
                                setConceptNoteData(false)
                                validation.resetForm() // Reset the form
                            }}
                        >
                            Close
                        </button>
                        <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default ConceptNoteFormModel
