import React, { useContext, useState } from "react"
import { Modal, Form, Input, FormFeedback, Button } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import {
  paginationHelper
} from "helpers/Functions/GraphqlUpdateFunction"
import { ProgramRequirementContext } from "./ProgramRequirementList"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { onlineApplicationGraphQLClient, registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"
import { CREATE_PROGRAM_REQUIREMENT } from "../Mutations/ProgramRequirementMutation"
import {
  GET_PROGRAM_REQUIREMENTS,
  GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID
} from "../Queries/ProgramRequirementQueries"
import { GET_SUBJECTS_BY_QUALIFICATION } from "../../Subjects/Queries/SubjectQueries"
import { GET_GRADE_POINTS, GET_QUALIFICATION_METHODS } from "../../GradePoints/Queries/GradePointQueries"
import { limit } from "../../../helpers/UrlHelper"

const ProgramRequirementModal = ({ uid }) => {

  const { updateProgramRequirementData, setUpdateProgramRequirementData } = useContext(ProgramRequirementContext)
  const [ProgramRequirement] = useMutation(CREATE_PROGRAM_REQUIREMENT)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedQualificationCategory, setSelectedQualificationCategory] = useState(null)

  const { loading, error, data } = useQuery(GET_QUALIFICATION_METHODS, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen,
    variables: {}
  })
  const qualificationMethods = data?.getQualificationMethod?.data

  const { loading: subjectLoading, error: subjectError, data: subjectData } = useQuery(GET_SUBJECTS_BY_QUALIFICATION, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen || !selectedQualificationCategory,
    variables: { inputs: { level: selectedQualificationCategory } }
  })
  const subjects = subjectData?.getAllSubjectsByQualificationMethod?.data?.items

  const { loading: gradePointLoading, error: gradePointError, data: gradePointData } = useQuery(GET_GRADE_POINTS, {
    client: onlineApplicationGraphQLClient,
    skip: !modalOpen,
    variables: { pagination: { offset: 0, limit: 1000 } }
  })
  const gradePoints = gradePointData?.getGradePoints?.data?.items

  const qualificationMode = ["Direct", "Equivalent"]
  const subjectSpecifications = [
    { value: true, label: "YES" },
    { value: false, label: "NO" }
  ]

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      entryCategoryCode: updateProgramRequirementData ? updateProgramRequirementData.entryCategoryCode : "",
      qualificationMethodUid: updateProgramRequirementData ? updateProgramRequirementData.qualificationMethod?.uid : "",
      subjectUid: updateProgramRequirementData ? updateProgramRequirementData.subject?.uid : "",
      hasAlternativeSubject: updateProgramRequirementData ? updateProgramRequirementData.hasAlternativeSubject : "",
      alternativeSubjectUid: updateProgramRequirementData ? updateProgramRequirementData.alternativeSubjectUid : ""
    },

    validationSchema: Yup.object().shape({
      gradePointUid: Yup.string().required("Grade Point is required"),
      entryCategoryCode: Yup.string().required("Qualification mode is required"),
      qualificationMethodUid: Yup.string().required("Grade Point short name is required"),
      subjectUid: Yup.string().required("Subject is required"),
      mustPass: Yup.string().required("Must pass is required"),
      hasAlternativeSubject: Yup.string().required("Alternative option is required")
    }),

    onSubmit: (values, { resetForm, setSubmitting }) => {
      ProgramRequirement({
        client: onlineApplicationGraphQLClient,
        variables: {
          inputs: { ...values, programCapacityUid: uid, uid: updateProgramRequirementData?.uid }
        },
        refetchQueries: [
          {
            query: GET_PROGRAM_SUBJECT_REQUIREMENT_BY_PROGRAM_CAPACITY_BY_UID,
            variables: {
              uid: uid
            }
          }
        ],
        onCompleted: ({ registerProgramSubjectRequirement }) => {
          if (registerProgramSubjectRequirement.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setUpdateProgramRequirementData(false) // Close the modal
          } else {
            setSubmitting(false)
            setModalOpen(true)
            setUpdateProgramRequirementData(false)
          }
        },
        onError: error => {
          // Handle errors
          setSubmitting(false)
          console.error("Mutation error:", error)
        }
      }).then(r => console.log(r))
    }
  })
  return (
    <div className="text-sm-end mt-3">
      <Button
        type="button"
        color="primary"
        className="btn mb-2 me-2 pull-right"
        onClick={() => setModalOpen(true)}
      >
        <i className="mdi mdi-plus-circle-outline me-1" />
        New Program Requirement
      </Button>
      <Modal
        isOpen={modalOpen || !!updateProgramRequirementData}
        backdrop={"static"}
        size="lg"
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!updateProgramRequirementData ? "Edit Program Requirement" : "New Program Requirements"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              validation.resetForm()
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Entry Mode
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("entryCategoryCode", e.value)
                    }}
                    options={qualificationMode?.map(mode => ({
                      value: mode,
                      label: mode
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                  />
                  <Input
                    name="entryCategoryCode"
                    placeholder="Select qualfification mode"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.entryCategoryCode || ""}
                    invalid={
                      !!(validation.touched.entryCategoryCode && validation.errors.entryCategoryCode)
                    }
                  />
                  {validation.touched.entryCategoryCode && validation.errors.entryCategoryCode ? (
                    <FormFeedback type="invalid">
                      {validation.errors.entryCategoryCode}
                    </FormFeedback>
                  ) : null}
                </div>

              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Qualification Method
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("qualificationMethodUid", e.value)
                      setSelectedQualificationCategory(e.code)
                    }}
                    isLoading={loading}
                    options={qualificationMethods?.map(year => ({
                      code: year.code,
                      value: year.uid,
                      label: year.name
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                  />
                  <Input
                    name="qualificationMethodUid"
                    placeholder="Select level"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.qualificationMethodUid || ""}
                    invalid={
                      !!(validation.touched.qualificationMethodUid && validation.errors.qualificationMethodUid)
                    }
                  />
                  {validation.touched.qualificationMethodUid && validation.errors.qualificationMethodUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.qualificationMethodUid}
                    </FormFeedback>
                  ) : null}
                </div>

              </div>

            </div>

            <div className="row">

              <div className="col-lg-12">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Select Subject
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("subjectUid", e.value)
                    }}
                    options={subjects?.map(year => ({
                      value: year.uid,
                      label: year.name
                    }))}
                    isLoading={subjectLoading}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                  />
                  <Input
                    name="subjectUid"
                    placeholder="Select subject"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.subjectUid || ""}
                    invalid={
                      !!(validation.touched.subjectUid && validation.errors.subjectUid)
                    }
                  />
                  {validation.touched.subjectUid && validation.errors.subjectUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.subjectUid}
                    </FormFeedback>
                  ) : null}
                </div>

              </div>

            </div>

            <div className="row">

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Select Subject Grade Point
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("gradePointUid", e.value)
                    }}
                    options={gradePoints?.map(sp => ({
                      value: sp.uid,
                      label: sp.grade + " - With Point: " + sp.gradePoint
                    }))}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                  />
                  <Input
                    name="gradePointUid"
                    placeholder="Select subject grad epoint"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.gradePointUid || ""}
                    invalid={
                      !!(validation.touched.gradePointUid && validation.errors.gradePointUid)
                    }
                  />
                  {validation.touched.gradePointUid && validation.errors.gradePointUid ? (
                    <FormFeedback type="invalid">
                      {validation.errors.gradePointUid}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Must Pass ?
                  </label>
                  <Select
                    onChange={e => {
                      validation.setFieldValue("mustPass", e.value)
                    }}
                    options={subjectSpecifications}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                  />
                  <Input
                    name="mustPass"
                    placeholder="Select pass criteria"
                    type="hidden"
                    rows={2}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.mustPass || ""}
                    invalid={
                      !!(validation.touched.mustPass && validation.errors.mustPass)
                    }
                  />
                  {validation.touched.mustPass && validation.errors.mustPass ? (
                    <FormFeedback type="invalid">
                      {validation.errors.mustPass}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label htmlFor="status" className="col-form-label">
                    Has Alternative Subject?
                  </label>

                  <Select
                    onChange={(e) => {
                      validation.setFieldValue("hasAlternativeSubject", e.value)
                    }}
                    options={[
                      {
                        label: "YES",
                        value: true
                      },
                      {
                        label: "NO",
                        value: false
                      }
                    ]}
                    className="select2-selection"
                    styles={{
                      menu: base => ({
                        ...base,
                        position: "absolute",
                        zIndex: 9999
                      })
                    }}
                  />

                  {validation.touched.hasAlternativeSubject && validation.errors.hasAlternativeSubject ? (
                    <FormFeedback type="invalid">
                      {validation.errors.hasAlternativeSubject}
                    </FormFeedback>
                  ) : null}
                </div>

              </div>
            </div>
            {/*If user need to select alternative subject*/}
            {validation.values.hasAlternativeSubject && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="status" className="col-form-label">
                      Select Alternative Subject
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("alternativeSubjectUid", e.value)
                      }}
                      options={subjects?.map(year => ({
                        value: year.uid,
                        label: year.name
                      }))}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                    <Input
                      name="alternativeSubjectUid"
                      placeholder="Select alternative subject"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.alternativeSubjectUid || ""}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="status" className="col-form-label">
                      Alternative Subject Grade Point
                    </label>
                    <Select
                      onChange={e => {
                        validation.setFieldValue("alternativeGradePointUid", e.value)
                      }}
                      options={gradePoints?.map(sp => ({
                        value: sp.uid,
                        label: sp.grade + " -With point: " + sp.gradePoint
                      }))}
                      className="select2-selection"
                      styles={{
                        menu: base => ({
                          ...base,
                          position: "absolute",
                          zIndex: 9999
                        })
                      }}
                    />
                    <Input
                      name="alternativeGradePointUid"
                      placeholder="Select subject grade point"
                      type="hidden"
                      rows={2}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.alternativeGradePointUid || ""}

                    />
                  </div>

                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setModalOpen(false)
                setUpdateProgramRequirementData(false)
                validation.resetForm()
              }}
            >
              Close
            </button>
            <SubmitButtonHelper name="Submit" type="primary" formik={validation} />
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ProgramRequirementModal
