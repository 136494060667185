import React from "react";
import { Route, Routes } from "react-router";
import StaffTermsList from "../Views/StaffTermsList";
import Pages404 from "Modules/Utility/pages-404";

const StaffTermsRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<StaffTermsList />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}
export default StaffTermsRoutes