import { gql } from "@apollo/client"

const GET_ACTIVE_ADMISSION_WINDOW = gql`
    query GetActiveAdmissionWindow($applicationLevelCode:String!){
        getActiveAdmissionWindow(applicationLevelCode:$applicationLevelCode) {
            status
            message
            data{
                uid
                description
                batchNumber
                startDate
                endDate
                code
                admissionYear{
                    name
                    status
                    startDate
                    endDate
                }
            }
        }
    }`

const GET_NECTA_APPLICANT_PARTICULAR = gql`
    query GetNectaApplicantParticular($input: CandidateParticularRequestNode!) {
        getNectaApplicantParticular(input: $input) {
            status
            data {
                indexNumber
                firstName
                middleName
                lastName
                sex
                centerName
            }
            message
            code
        }
    }
`

export { GET_ACTIVE_ADMISSION_WINDOW, GET_NECTA_APPLICANT_PARTICULAR }