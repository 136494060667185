import React, { useContext, useEffect, useState } from "react"
import {
  Modal,
  Form,
  Input,
  FormFeedback,
  Label,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { useMutation } from "@apollo/client"
import { useNavigate } from 'react-router-dom';
import showToast from "helpers/ToastHelper"
import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import Select from "react-select"
import * as Yup from "yup"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { registrationGraphQLClient } from "Modules/Authentication/ApolloClient"
import { InstructorCourseDetailsContext } from "./InstructorCourseDetail"
import { CourseWeights, courseMarkedOut } from "./CourseWeight"
import UploadResultOnline from "./UploadResultOnline"
import { UPLOAD_EXAM_SCORE_RESULT_BY_EXCEL } from "../Mutations/InstructorMutations"
import UploadResultFromMoodleView from "./UploadResultFromMoodle/UploadResultFromMoodleView"

const UploadCourseResult = ({
  isOpenUploadResultModal,
  closeUploadResultModal,
}) => {
  const {
    examCategoryID,
    students,
    examCategoryName,
    assessmentNumber,
    allocationUID,
  } = useContext(InstructorCourseDetailsContext)

  const [excelUploaderMutation] = useMutation(
    UPLOAD_EXAM_SCORE_RESULT_BY_EXCEL,
    {
      client: registrationGraphQLClient,
    }
  )
  
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(limit)
  const [pageSize, setPageSize] = useState(0)
  const pagination = { offset: currentPage, limit: limit, search: null }
  const startIndex = currentPage * pageSize

  const uploadResultOptions = [
    { label: "1. Upload From Moodle", value: 1 },
    { label: "2. Upload By Excel", value: 2 },
    { label: "3. Online Upload", value: 3 },
  ]

  const [uploadResultType, setUploadResultType] = useState(null)

  const uploadResultByExcel = useFormik({
    enableReinitialize: true,

    initialValues: {
      base64File: "",
    },

    validationSchema: Yup.object().shape({
      base64File: Yup.string()
        .required("Please File is required")
        .test(
          "fileType",
          "Invalid file type format. Only xlsx file is required",
          value => {
            if (!value) {
              return false
            }
            const allowedExtensions = ["xlsx"] // required file format types
            const fileExtension = value.split(".").pop().toLowerCase()
            return allowedExtensions.includes(fileExtension)
          }
        ),
    }),

    onSubmit: values => {
      try {
        const fileInput = document.querySelector('input[name="base64File"]') // Get the file input element
        const file = fileInput.files[0] // Access the selected file

        if (file) {
          const reader = new FileReader()

          reader.onload = event => {
            const base64String = event.target.result.split(",")[1] // Extract the base64 part

            // Use Apollo Client's mutate function to send the mutation request
            excelUploaderMutation({
              variables: {
                base64File: base64String,
              },
            })
              .then(result => {
                // Handle the mutation result here
                const uploadScore = result.data.uploadScore

                console.log(uploadScore)

                if (uploadScore.code === 8000) {
                  closeUploadResultModal();
                  setUploadResultType(null)
                  uploadResultByExcel.resetForm();
                  showToast(uploadScore.message, "success")
                  navigate("/examination/instructor/view_submitted_result", {state: {uploadScore:uploadScore}})
                } else {
                  showToast(
                    uploadScore.message,
                    uploadScore.code === 8000 ? "success" : "error"
                  )
                  uploadResultByExcel.resetForm()
                }
              })
              .catch(error => {
                uploadResultByExcel.resetForm()
                console.error("Mutation error:", error)
              })
          }
          reader.readAsDataURL(file)
        }
      } catch (error) {
        console.error("An error occurred:", error)
      }
    },
  })

  return (
    <Modal
      isOpen={isOpenUploadResultModal || !!""}
      backdrop={"static"}
      size="lg"
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {"Exam result Upload "}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setUploadResultType(null)
            closeUploadResultModal()
            uploadResultByExcel.resetForm()
          }}
          aria-label="Close"
        ></button>
      </div>
      <Row>
        <Col md={12}>
          <p className="m-3">
            <div className="mb-3">
              <label htmlFor="name" className="col-form-label">
                Select Upload Type
              </label>
              <Select
                id="outOff"
                name="outOff"
                onChange={e => {
                  setUploadResultType(e.value)
                  uploadResultByExcel.resetForm()
                }}
                options={uploadResultOptions}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999,
                  }),
                }}
              />
            </div>
          </p>
        </Col>
      </Row>
      {uploadResultType == 2 ? (
        <div className="m-3">
          <div className="alert alert-info">
            <strong>
              In order to upload by Excel ensure you have downloaded template in
              the assessment area! 
              <Badge
                color="primary"
                className="btn btn-link text-white"
                onClick={() => {
                  closeUploadResultModal()
                  setUploadResultType(null)
                }}
              >
                Click Here to download Excel sheet
              </Badge>
            </strong>
          </div>
          <Form
            onSubmit={e => {
              e.preventDefault()
              uploadResultByExcel.handleSubmit()
              return false
            }}
            encType="multipart/form-data"
          >
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <Label>Select File To Upload</Label>
                  <Input
                    name="base64File"
                    type="file"
                    onChange={event => {
                      uploadResultByExcel.handleChange(event);
                      const selectedFile = event.target.files[0]

                      if (selectedFile) {
                        const reader = new FileReader()
                        reader.onload = e => {
                          const base64String = e.target.result.split(",")[1]
                          // Use the base64String for further processing or mutations
                        }
                        reader.readAsDataURL(selectedFile)
                      }
                    }}
                    onBlur={uploadResultByExcel.handleBlur}
                    invalid={
                      !!(
                        uploadResultByExcel.touched.base64File &&
                        uploadResultByExcel.errors.base64File
                      )
                    }
                  />

                  {uploadResultByExcel.touched.base64File &&
                  uploadResultByExcel.errors.base64File ? (
                    <FormFeedback type="invalid">
                      {uploadResultByExcel.errors.base64File}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      closeUploadResultModal()
                      setUploadResultType(null)
                      uploadResultByExcel.resetForm()
                    }}
                  >
                    Close
                  </button>
                  <SubmitButtonHelper
                    name="Submit"
                    type="primary"
                    formik={uploadResultByExcel}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      ) : uploadResultType == 1 ? (
          <UploadResultFromMoodleView />
      ) : (
        uploadResultType == 3 && <UploadResultOnline closeOnlineUpload={closeUploadResultModal} />
      )}
    </Modal>
  )
}
export default UploadCourseResult
