import React, { useContext, useRef, useState, useEffect } from "react"
import { Modal, Form, Input, FormFeedback, Button, Label } from "reactstrap"
import * as Yup from "yup"
import { CANCEL_ROOM_REQUEST } from "../Mutations/AccommodationMutations"
import { useFormik } from "formik"
import { useMutation, useLazyQuery, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { GET_STUDENT_ROOM_REQUESTS } from "../Queries/AccommodationQueries"
import { AccommodationListContext} from "./AccommodationList"
import { GET_STUDENTS } from "Modules/Students/Queries/StudentsQueries"

import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { accomodationGraphQLClient, registrationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import Select from "react-select"

const AccommodationModal = () => {
  const { studentInfo, setOpenCancelRoomModal,openCancelRoomModal, selectedRequest, setSelectedRequest } = useContext(AccommodationListContext
    )

  console.log(openCancelRoomModal)

  // console.log(selectedRequest)
  const [cancel_room_request] = useMutation(CANCEL_ROOM_REQUEST, {client: accomodationGraphQLClient})
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      reason: ""
    },

    validationSchema: Yup.object().shape({
      // uid: Yup.string().required("request to be cancelled must be submitted"),
      reason: Yup.string().required("Cancel reason must be stated")
    }),

    onSubmit: (values, {setSubmitting}) => {
      cancel_room_request({
        variables: {
          input: { ...values, roomRequestUid: selectedRequest?.uid },
        },
        client:accomodationGraphQLClient,
        refetchQueries: [
          {
            query: GET_STUDENT_ROOM_REQUESTS,
            variables: {
              studentUid: selectedRequest?.studentUid
            },
            fetchPolicy:'no-cache'
          },
        ],
        onCompleted: ({ cancelRoomRequest }) => {
          if (cancelRoomRequest.code === 8000) {
            validation.resetForm()
            setOpenCancelRoomModal(false)
          } else {
            setOpenCancelRoomModal(true)
            setSubmitting(false)
          }
          showToast(
              cancelRoomRequest.message,
              cancelRoomRequest.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          setSubmitting(false)
          if(!!process.env.REACT_APP_DEBUG_MODE){ 
            console.error("Mutation error:", error)
          }
        },
      })
    },
  })

  // console.log(selectedRequest)
  return (
    <div className="text-sm-end ms-auto">
    
      <a 
        className="card-link text-danger" 
        href="#" 
        onClick={() => setOpenCancelRoomModal(true)}
      >
        Cancel this request
      </a>
      
      <Modal
        isOpen={openCancelRoomModal}
        backdrop={"static"}
        id="staticBackdrop"
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {"Cancel Room Request"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setOpenCancelRoomModal(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
            <div className="mb-1">
              <Label>
                Request <span className="text-danger">*</span>: 
              </Label>
              <Input
                required
                placeholder="Request to be cancelled"
                value={selectedRequest?.student?.fullName+ " ( "+selectedRequest?.student?.registrationNumber+")"}
                disabled
              />
            </div>

            <div className="mb-1">
              <label htmlFor="reason" className="col-form-label">
                Cancel Reason <span className="text-danger">*</span>:
              </label>
              <Input
                type="textarea"
                name="reason"
                className="form-control"
                id="reason"
                placeholder="Enter cancel reason"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.reason || ""}
                invalid={
                  validation.touched.reason && validation.errors.reason
                    ? true
                    : false
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                setOpenCancelRoomModal(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper
              name="Submit"
              type="primary"
              formik={validation}
            />
          </div>
        </Form>
      </Modal>

    </div>
  )
}

export default AccommodationModal
