import React, { useContext, useEffect, useState } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Row,
  Col,
  Label,
  InputGroup, Alert
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import { onlineApplicationGraphQLClient, uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { ApplicantProfileWizardContext } from "./ApplicantProfileWizard"
import {
  UPDATE_APPLICANT_BIRTHDATE_AND_PHYSICAL_ADDRESS
} from "../../../OnlineApplication/ProgramSelection/Mutations/ProgramSelectionMutations"
import { GET_APPLICANT_DETAILS } from "../../Queries/ApplicantQueries"
import { Link } from "react-router-dom"
import moment from "moment/moment"

const breadcrumbItem = [
  {
    title: "My Profile",
    path: "/Students/Profile",
    isActive: false
  },
  {
    title: "Update",
    path: "",
    isActive: true
  }
]

const UpdateApplicantInformation = ({ validation }) => {
  const ExtractUserAuth = JSON.parse(localStorage.getItem("authUser"))?.data

  const {
    toggleTab,
    activeTab,
    passedSteps,
    applicant,
    setPayload,
    payLoad
  } = useContext(ApplicantProfileWizardContext)

  const [updateApplicantDetails] = useMutation(UPDATE_APPLICANT_BIRTHDATE_AND_PHYSICAL_ADDRESS, {
    client: onlineApplicationGraphQLClient
  })
  const handleNext = () => {
    // handleSubmit() // Call the handleSubmit function from the context
    if (isSubmitted) {
      toggleTab(2) // Move to the next tab
    } else {
      toggleTab(1) // Move to the next tab
    }
  }

  const [minDate, setMinDate] = useState(null)
  const [isSubmitted] = useState(false)

  const changedateOfBirth = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateOfBirth",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const onSubmit = e => {
    e.preventDefault()
    validation.handleSubmit()
    return null
  }
  validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: applicant ? applicant?.firstName : "",
      middleName: applicant ? applicant?.middleName : "",
      lastName: applicant ? applicant?.lastName : "",
      email: applicant ? applicant?.email : "",
      phoneNumber: applicant ? applicant?.phoneNumber : "",
      dateOfBirth: applicant?.birthDate,
      gender: applicant ? applicant?.gender : "",
      physicalAddress: applicant?.physicalAddress || ""
    },

    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(13, "Phone number must be valid")
        .max(13, "Phone number must be valid")
        .nullable()
        .required("Please enter a valid phone number")
        .matches(/^\+?(\d{10,13})$/, "Phone number must be valid")
        .test(
          "no-invalid-start",
          "Phone number must be valid!",
          value => !/^(\+25562|\+25561)/.test(value)),
      dateOfBirth: Yup.date().required("Date of Birth is required"),
      physicalAddress: Yup.string().required("This field is required")
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setPayload({
        phoneNumber: values.phoneNumber,
        dateOfBirth: values.dateOfBirth,
        physicalAddress: values.physicalAddress
      })
      const postData = {
        birthDate: values.dateOfBirth,
        physicalAddress: values.physicalAddress,
        applicantUid: applicant?.uid
      }
      updateApplicantDetails({
        variables: {
          ...postData
        },
        onCompleted: ({ updateApplicantBirthDateAndAddress }) => {
          //updateFunction(client.cache, { data: { registerCampuses } });
          if (updateApplicantBirthDateAndAddress.code === 8000) {
            toggleTab(2)
          } else {
            setSubmitting(false)
          }
          showToast(
            updateApplicantBirthDateAndAddress.message,
            updateApplicantBirthDateAndAddress.code === 8000 ? "success" : "error"
          )
        },

        refetchQueries: [
          {
            variables: {
              userUid: ExtractUserAuth?.uid
            },
            query: GET_APPLICANT_DETAILS
          }
        ],
        onError: (error) => {
          setSubmitting(false)
          console.log("Error", error)
        }
      })

    }
  })

  const formatedDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("D-M-Y");
  };

  const parseDate = (dateString) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").toDate();
  };


  return (
    <Row>
      <Col lg={12}>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <>
            <div className="modal-body">
              <Row>
                <Col md="4" lg="4">
                  <div className="mb-3">
                    <label htmlFor="firstName">First Name:</label>
                    <Input
                      type="text"
                      name="firstName"
                      readOnly
                      className="form-control"
                      id="firstName"
                      placeholder="Enter First Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={applicant?.firstName ? applicant?.firstName : ""}
                      invalid={
                        !!(
                          validation.touched.firstName &&
                          validation.errors.firstName
                        )
                      }
                    />
                    {validation.touched.firstName &&
                      validation.errors.firstName && (
                        <FormFeedback type="invalid">
                          {validation.errors.firstName}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col md="4" lg="4">
                  <div className="mb-3">
                    <Label for="middleName">Middle Name</Label>
                    <Input
                      type="text"
                      name="middleName"
                      readOnly
                      className="form-control"
                      id="middleName"
                      placeholder="Enter middle name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        applicant?.middleName ? applicant?.middleName : ""
                      }
                      invalid={
                        !!(
                          validation.touched.middleName &&
                          validation.errors.middleName
                        )
                      }
                    />
                    {validation.touched.middleName &&
                      validation.errors.middleName && (
                        <FormFeedback type="invalid">
                          {validation.errors.middleName}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="mb-3">
                    <label htmlFor="lastName">Last Name:</label>
                    <Input
                      type="text"
                      readOnly
                      name="lastName"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter Last Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={applicant?.firstName ? applicant?.lastName : ""}
                    />

                    {validation.touched.lastName &&
                      validation.errors.lastName && (
                        <FormFeedback type="invalid">
                          {validation.errors.lastName}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg="4" md="4">
                  <div className="mb-3">
                    <Label for="phoneNumber">Phone Number</Label>
                    <Input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="Enter Phone Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.phoneNumber ||
                        payLoad?.phoneNumber ||
                        ""
                      }
                      invalid={
                        !!(
                          validation.touched.phoneNumber &&
                          validation.errors.phoneNumber
                        )
                      }
                    />
                    {validation.touched.phoneNumber &&
                      validation.errors.phoneNumber && (
                        <FormFeedback type="invalid">
                          {validation.errors.phoneNumber}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col lg="4" md="4">
                  <div className="mb-3">
                    <Label for="phoneNumber">Gender</Label>
                    <Input
                      type="text"
                      readOnly
                      name="phoneNumber"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="Enter Phone Number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.gender}
                      invalid={
                        !!(
                          validation.touched.gender &&
                          validation.errors.gender
                        )
                      }
                    />
                    {validation.touched.gender &&
                      validation.errors.gender && (
                        <FormFeedback type="invalid">
                          {validation.errors.gender}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
                <Col lg="4">
                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      name="email"
                      readOnly
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.email || applicant?.email || ""
                      }
                      invalid={
                        !!(
                          validation.touched.email && validation.errors.email
                        )
                      }
                    />
                    {validation.touched.email && validation.errors.email && (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="6" md="6">
                  <div className="mb-3">
                    <Label>Date Of Birth</Label>
                    <InputGroup>
                      <Flatpickr
                        className="form-control d-block"
                        // name="dob"
                        placeholder="dd M,yyyy"
                        defaultValue={validation.values.dateOfBirth
                          ? formatedDate(validation.values.dateOfBirth)
                          : ""}
                        options={{
                          maxDate: minDate,
                          altInput: true,
                          dateFormat: "d-m-y",
                          enableTime: false,
                          time_24hr: true,
                          closeOnSelect: true,
                          defaultDate: validation.values.dateOfBirth
                            ? parseDate(validation.values.dateOfBirth)
                            : null,
                        }}
                        onChange={date => {

                          const selectedDate = date[0] // Get the selected date object
                          const year = selectedDate?.getFullYear()
                          const month = selectedDate?.getMonth() + 1 // Months are 0-indexed
                          const day = selectedDate?.getDate()

                          const formattedDate = `${year}-${
                            month < 10 ? "0" : ""
                          }${month}-${day < 10 ? "0" : ""}${day}`

                          validation.setFieldValue("dateOfBirth", formattedDate)
                        }}
                      />
                      {validation.touched.dateOfBirth && validation.errors.dateOfBirth ? (
                        <FormFeedback type="invalid">
                          {validation.errors.dateOfBirth}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                    {validation.touched.dateOfBirth &&
                    validation.errors.dateOfBirth ? (
                      <FormFeedback type="invalid">
                        {validation.errors.dateOfBirth}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6" md={6}>
                  <div className="mb-3">
                    <Label>Postal/Physical Address</Label>
                    <Input
                      type="textarea"
                      name="physicalAddress"
                      className="form-control"
                      id="physicalAddress"
                      placeholder="Enter your Postal or Physical Address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.physicalAddress || applicant?.physicalAddress || ""}
                      invalid={
                        validation.touched.physicalAddress &&
                        validation.errors.physicalAddress
                          ? true
                          : false
                      }
                    />
                    {validation.touched.physicalAddress &&
                      validation.errors.physicalAddress && (
                        <FormFeedback type="invalid">
                          {validation.errors.physicalAddress}
                        </FormFeedback>
                      )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="wizard">
              <div className="actions">
                <ul>
                  <li className="next" onClick={handleNext}>
                    <SubmitButtonHelper
                      type="primary"
                      name={"Next >"}
                      formik={validation}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </>
        </Form>
      </Col>
    </Row>
  )
}
export default UpdateApplicantInformation
