import React from "react";
import { Route, Routes } from "react-router";
import StaffCategoryList from "../Views/StaffCategoryList";
import Pages404 from "Modules/Utility/pages-404";

const StaffCategoryRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<StaffCategoryList/>} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default StaffCategoryRoutes