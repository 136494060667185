import React from "react";
import { Route, Routes } from "react-router-dom";
import Pages404 from "../../../../Utility/pages-404";
import DissertationPapers from "../Views/DissertationPapers";
import ViewDissertationPaper from "../Views/ViewDissertationPaper";
const DissertationPaperRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<DissertationPapers />} />
            <Route path="/view" element={<ViewDissertationPaper />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    )
}

export default DissertationPaperRoutes;