// export default BlockList;
import React, { createContext, useEffect, useState } from "react"
import {useLocation} from 'react-router-dom';
import { Row, Col, Card, CardBody, CardSubtitle, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { useNavigate } from "react-router-dom"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ReactPaginate from "react-paginate"
import LayoutHelper from "helpers/LayoutHelper"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import PlaceHolderLoader from "helpers/PlaceHolderLoader"
import showToast from "helpers/ToastHelper"
import { showDeleteWarning, showWaringConf } from "helpers/DeleteWaringHelper"
import { GET_HOSTEL_BLOCKS } from "../Queries/AccommodationQueries"
import { ACTIVATE_BLOCK, DELETE_BLOCK } from "../Mutations/AccommodationMutations"
import BlockModal from "./BlockModal"

import { limit } from "helpers/UrlHelper"
import { useFormik } from "formik"
import * as Yup from "yup"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import {uaaGraphQLClient, accomodationGraphQLClient} from "Modules/Authentication/ApolloClient"
import DropDownActionHelper from "helpers/DropDownActionHelper"

export const BlockContext = createContext() //Create data to transfer to child modal

const BlockList = () => {

  // Try to read parent hostel passed in
  const { state } = useLocation();
  const parentHostel = state?.hostel

  const breadcrumbItem = [
    {
      title: parentHostel? parentHostel.name+" Blocks" : "Block List",
      path: "",
      isActive: true,
    },
  ]

  const searchValues = {
    search: "",
  }
  const searchSummarySchema = Yup.object().shape({
    search: Yup.string().required(""),
  })

  const formikSearch = useFormik({
    initialValues: searchValues,
    validationSchema: searchSummarySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("Requesting Page no ")
    },
  })

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(limit)
  const pagination = { offset: currentPage, limit: limit, search: null }

  const [selectedBlock, setSelectedBlock] = useState(null) //Get data for deleting
  const [updateBlock, setUpdateBlock] = useState(null) //Get data for editing

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = block => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [block.uid]: !prevState[block.uid],
    }))
  }
  
  const { loading, error, data, refetch } = useQuery(GET_HOSTEL_BLOCKS, {
    client:accomodationGraphQLClient,
    variables: {
      hostelUid: parentHostel.uid,
    },
    fetchPolicy: 'cache-first'
  })

  const [searchCountries,{ loading: searchLoading, error: searchError, data: searchData },] = useLazyQuery(GET_HOSTEL_BLOCKS,{
    client:accomodationGraphQLClient,
    variables: {
      hostelUid: parentHostel.uid,
    },
  })

  useEffect(() => {
    if (!loading && data) {
      // console.log("DataReceived", data)

    }
  }, [loading, data])

  const handleClick = selectedPage => {
    const page = selectedPage.selected;
   // fetchData(page);
    setCurrentPage(selectedPage.selected)
  }

  const [deleteBlock] = useMutation(DELETE_BLOCK, {
    client: accomodationGraphQLClient,
    variables: { uid: selectedBlock?.uid },
    refetchQueries: [
      {
        query: GET_HOSTEL_BLOCKS,
        variables: {
          hostelUid: parentHostel.uid,
        },
      },
    ],
  })

  const [activateBlock] = useMutation(ACTIVATE_BLOCK, {
    client: accomodationGraphQLClient,
    variables: { uid: selectedBlock?.uid },
    refetchQueries: [
      {
        query: GET_HOSTEL_BLOCKS,
        variables: {
          hostelUid: parentHostel.uid,
        },
      },
    ],
  })

  const navigate = useNavigate();

  const showRoomList = (block) => {
    navigate('/accommodation/hostels/blocks/rooms', {state: {block: block}});
  }

  const changeAvailability = (block) => {
    setSelectedBlock(block)
    const title = block?.isAvailable? 'Close Block' : 'Open Block'
    const text = 'Are you sure to ' + (block?.isAvailable? 'close' : 'open') + ' this block?'
    showWaringConf(async () => {
        try {
          const response = await activateBlock()
          showToast(response?.data?.activateBlock.message, response?.data?.activateBlock.code === 8000 ? "success" : 'error');
        } catch (error) {
          console.error("Error deleting a block:", error)
        }
      }, title, text,'warning', block?.isAvailable? 'Close' : 'Open',
      () => {
          // console.log("Canceled")
      }
    )
  }

  const handleDeleteBlock = (deleteData) => {
    setSelectedBlock(deleteData)
    showDeleteWarning(() => {
      performBlockDeletion()
    })
  }

  const performBlockDeletion = async () => {
    try {
      const response = await deleteBlock()
      showToast(response?.data?.removeBlock.message, response?.data?.removeBlock.code === 8000 ? "success" : 'error');
    } catch (error) {
      console.error("Error deleting a block:", error)
    }
  }

  if (loading) {
    return (
        <PlaceHolderLoader rows={4} type="table" columSize={12} />
    )
  }
  if (error || searchError) {
    return showToast(error || searchError, "error")
  }

  const fetchData = (page = 0) => {
    setCurrentPage(page);
    let data = {
      search: formikSearch.values.search,
    }
    // Update the search query variables and refetch the data
    searchCountries({ variables: { pagination: { ...pagination, ...data } } })
  }

  const searchQuery = () => {
    const value = formikSearch.values.search
    if (value.length >= 3) {
      // setFetching(true);
      fetchData()
    } else if (value?.length === 0) { //refetch data after clearing search value
      clearSearch();
      fetchData(); //update hook state
    }
  }

  const clearSearch = () => { //Function to clear search from formik
    formikSearch.setFieldValue("search", "");
  }
  const dataArrray = data?.getBlocksByHostel.data?.items
  const searchedData = searchData?.getBlocksByHostel?.data?.items
  const result = searchedData ? searchedData : dataArrray
  const startIndex = currentPage * pageSize
  const endIndex = (currentPage + 1) * pageSize
  const totalEntries = data?.getBlocksByHostel.data.totalCount || searchData?.getBlocksByHostel?.data?.items || 0

  const HTMLTableCell = ({ htmlContent }) => (
    <td dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );

  return (
    <BlockContext.Provider 
        value={{ 
              updateBlock, 
              setUpdateBlock,
              parentHostel 
              }}>
      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle={parentHostel? parentHostel.name+" Blocks" : "Block List"}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardSubtitle className="mb-1">
                  <Row>
                    <Col className="col-lg-6 col-md-6 col-sm-6">
                      <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <input
                            type="text"
                            className="form-control"
                            onKeyUp={searchQuery}
                            placeholder="Search..."
                            {...formikSearch.getFieldProps("search")}
                          />
                        </div>
                      </form>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-md-6 pull-right">
                      <BlockModal />
                    </Col>
                  </Row>
                </CardSubtitle>

                {!loading && !error && !searchError && (
                  <div className="table-rep-plugin">
                    <div
                      // className="table-responsive mb-0"
                      className="mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table-sm table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">S/N</Th>
                            <Th data-priority="2">Code</Th>
                            <Th data-priority="3">Name</Th>
                            <Th data-priority="4">Description</Th>
                            {
                              parentHostel?
                              '' :
                              <Th data-priority="3">Hostel</Th>
                            }
                            <Th data-priority="3">Floors</Th>
                            <Th data-priority="3">Rooms</Th>
                            <Th data-priority="3">Availability</Th>
                            <Th data-priority="5">Action</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {result
                            ?.slice(startIndex, endIndex)
                            .map((block, index) => (
                              <Tr key={block?.uid}>
                                <Td>{startIndex + index + 1}</Td>
                                <Td>{block?.code}</Td>
                                <Td>{block?.name}</Td>
                                <Td>{block?.description}</Td>
                                {
                                  parentHostel?
                                  '' :
                                  <Td>{
                                    block?.hostel?
                                    block?.hostel?.name + ' [' + block?.hostel?.code + ']' : 'None'
                                  }</Td>
                                }
                                <Td>{block?.floorCount}</Td>
                                <Td>{
                                    block?.roomCount? 
                                    block?.roomCount
                                    :
                                    'None'
                                  }
                                </Td>
                                <HTMLTableCell htmlContent={
                                    block?.isAvailable? 
                                    '<span class="btn btn-rounded btn-sm btn-info">Available</span>' 
                                    : 
                                    '<span class="btn btn-rounded btn-sm btn-warning">Unvailable</span>'
                                }/>
                                <Td>
                                {/* <DropDownActionHelper
                                  data={block}
                                  onUpdate={setUpdateBlock}
                                  onDelete={handleDeleteBlock}
                                /> */}
                                  <Dropdown
                                    isOpen={dropdownOpen[block.uid]}
                                    toggle={() => toggleDropdown(block)}
                                  >
                                    <DropdownToggle color="" caret>
                                      <i
                                        className="fas fa-ellipsis-v"
                                        style={{ color: "green" }}
                                      />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={() => {
                                          setUpdateBlock(block)
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Edit</span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          changeAvailability(block)
                                        }}
                                      >
                                        <i
                                          className={
                                            block?.isAvailable? 'fas fa-lock' : 'fas fa-lock-open'
                                          }
                                          style={{
                                            color: block?.isAvailable? "orange" : 'blue',
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>
                                          {
                                            block?.isAvailable? 'Close' : 'Open'
                                          }
                                        </span>
                                      </DropdownItem>

                                      <DropdownItem
                                        onClick={() => {
                                          showRoomList(block)
                                        }}
                                      >
                                        <i
                                          className="fas fa-person-booth"
                                          style={{
                                            color: "green",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Rooms</span>
                                      </DropdownItem>
                                      
                                      <DropdownItem
                                        onClick={() => {
                                          handleDeleteBlock(block)
                                        }}
                                      >
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "red",
                                            marginRight: "10px",
                                          }}
                                        />
                                        <span>Delete</span>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </Td>
                              </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col className="col-lg-6 fw-bold text-gray-700">
                        Showing {startIndex + 1} to{" "}
                        {Math.min(endIndex, totalEntries)} of {totalEntries}{" "}
                        entries
                      </Col>
                      <Col
                        className="col-lg-6 pull-right"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ReactPaginate
                          onPageChange={handleClick}
                          breakLabel={"--"}
                          pageCount={Math.ceil(totalEntries / pageSize)}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          previousClassName={"page-link"}
                          previousLabel={"<"}
                          nextLabel={">"}
                          activeClassName={"active"}
                          breakLinkClassName={"page-link"}
                          initialPage={currentPage}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </LayoutHelper>
    </BlockContext.Provider>
  )
}

export default BlockList
