import React from "react"
import PropTypes from "prop-types";
import {Button, Col, FormFeedback, Label, Row} from "reactstrap";
import DiplomaQualification from "./DiplomaQualification";
import SubmitButtonHelper from "../../../../../helpers/SubmitButtonHelper";

const QualificationOptions = (props) => {
    const {validation, applicant} = props
    return (
        <Row>
            {validation.values.qualificationMethod &&
                <>
                    {validation.values.qualificationMethod === 'form-six' &&
                        <Col lg={12} className={"mt-2"}>
                            <label htmlFor="formSixIndexNumber">Form Six Index/Equivalent Number</label>
                            <input
                                type="text"
                                className="form-control"
                                name="formSixIndexNumber"
                                id="formSixIndexNumber"
                                placeholder="Enter Form Six Index Number / Equivalent Number"
                                value={validation.values.formSixIndexNumber}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                            />
                            {validation.touched.formSixIndexNumber && validation.errors.formSixIndexNumber ? (
                                <div>{validation.errors.formSixIndexNumber}</div>
                            ) : null}
                        </Col>
                    }
                    {validation.values.qualificationMethod === 'other-form-six' &&
                      <Col lg={12} className={"mt-2"}>
                          <label htmlFor="formSixIndexNumber">Other Form Six Index Number(Provate candidate results)</label>
                          <input
                            type="text"
                            className="form-control"
                            name="formSixIndexNumber"
                            id="formSixIndexNumber"
                            placeholder="Enter Form Six Index Number / Equivalent Number"
                            value={validation.values.formSixIndexNumber}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.formSixIndexNumber && validation.errors.formSixIndexNumber ? (
                            <div>{validation.errors.formSixIndexNumber}</div>
                          ) : null}
                      </Col>
                    }
                    {validation.values.qualificationMethod === 'form-four' &&
                        <Col lg={12} className={"mt-2"}>
                            <label htmlFor="formFourIndexNumber">Form Four Index/Equivalent Number</label>
                            <input
                                type="text"
                                className="form-control"
                                name="formFourIndexNumber"
                                id="formFourIndexNumber"
                                placeholder="Enter Form Four Index Number / Equivalent Number"
                                value={validation.values.formFourIndexNumber}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                            />
                            {validation.touched.formFourIndexNumber && validation.errors.formFourIndexNumber ? (
                                <div>{validation.errors.formFourIndexNumber}</div>
                            ) : null}
                        </Col>
                    }

                    {validation.values.qualificationMethod === 'other-form-four' &&
                      <Col lg={12} className={"mt-2"}>
                          <label htmlFor="formFourIndexNumber">Other Form Four Index Number</label>
                          <input
                            type="text"
                            className="form-control"
                            name="formFourIndexNumber"
                            id="formFourIndexNumber"
                            placeholder="Enter Form Four Index Number / Equivalent Number"
                            value={validation.values.formFourIndexNumber}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.formFourIndexNumber && validation.errors.formFourIndexNumber ? (
                            <div>{validation.errors.formFourIndexNumber}</div>
                          ) : null}
                      </Col>
                    }

                    {validation.values.qualificationMethod === 'diploma' &&
                        <>
                            <Col lg={12} className={"mt-2"}>
                                <label htmlFor="avnNumber">AVN Number/ equivalent AVN</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="avnNumber"
                                    id="avnNumber"
                                    placeholder="Enter AVN Number/ equivalent AVN"
                                    value={validation.values.avnNumber}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                />
                                {validation.touched.avnNumber && validation.errors.avnNumber ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.avnNumber}
                                    </FormFeedback>
                                ) : null}
                            </Col>
                            <DiplomaQualification applicant={applicant} validation={validation} />
                        </>
                    }
                </>
            }

        </Row>
    )
}

QualificationOptions.propTypes = {
    applicant: PropTypes.any.isRequired,
    validation: PropTypes.any.isRequired
};

export default QualificationOptions;