import React, { useState } from "react"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import { Card, CardBody, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import { useLazyQuery, useQuery } from "@apollo/client"
import { onlineApplicationGraphQLClient } from "../../../Authentication/ApolloClient"
import {
  GET_ADMISSION_YEARS,
  GET_PROGRAM_CAPACITY
} from "../../AdmissionProgramCapacity/Queries/ProgramAdmissionQueries"
import { GET_SELECTION_RANKINGS } from "../../ApplicationConfirmationAndSelections/Queries/ApplicationQueries"
import { GET_CURRENT_ADMISSION_YEAR } from "../../../AdmissionYear/Queries/AdmissionYearQueries"
import { GET_APPLICATION_WINDOW } from "../../../ApplicationWindow/Queries/ApplicationWindowQuery"
import showToast from "../../../../helpers/ToastHelper"
import { ApplicantCountByProgramPDF, ApplicationRankingPDF } from "../../../../helpers/ApplicationRankingPDF"
import { GET_APPLICANT_COUNT_BY_PROGRAM } from "../Queries/ApplicationReportQueries"

const breadcrumbItem = [
  {
    title: "Application Report",
    path: "",
    isActive: true
  }
]


const ApplicationListReport = () => {

  const pagination = { offset: 0, limit: 100000, search: null }
  const [programCapacity, setProgramCapacity] = useState(null)
  const [reportTypeSelected, setReportTypeSelected] = useState(null)
  const [isDownloading, setIsDownloading] = useState(false)

  const [refetchApplicantRanking, {
    loading: loadingApplicantBlob,
    error: applicantError,
    data: applicantDataBlob
  }] = useLazyQuery(GET_SELECTION_RANKINGS, {
    client: onlineApplicationGraphQLClient,
    variables: {},
    fetchPolicy: "network-only"
  })

  const {
    loading: loadingApplicantByProgram,
    error: errorOccured,
    data: applicantCountByProgramResults,
    refetch: refetchApplicantCountByProgram
  } = useQuery(GET_APPLICANT_COUNT_BY_PROGRAM, {
    skip: !isDownloading,
    client: onlineApplicationGraphQLClient,
    fetchPolicy: "network-only"
  })

  const fetchApplicantCountByProgram = () => {
    setIsDownloading(true)

    refetchApplicantCountByProgram().then((response) => {

      const data = response.data?.getApplicantCountByProgram?.data?.items

      const pdfData = data?.map((item, index) => {
        const programName = item?.programName
        const totalFemale = item?.countFemale
        const totalMale = item?.countMale
        const Total = item?.countTotal

        return [
          (index + 1).toString(),
          programName,
          totalMale,
          totalFemale,
          Total
        ]
      })

      console.log(pdfData)
      return ApplicantCountByProgramPDF(pdfData)
    })
  }


  const { loading, error, data, refetch } = useQuery(GET_PROGRAM_CAPACITY, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })

  const {
    loading: loadingAdmissionYear,
    error: errorAdmissionYear,
    data: currentAdmissionYear
  } = useQuery(GET_CURRENT_ADMISSION_YEAR, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })

  const currentAdmissionYearResult = currentAdmissionYear?.getActiveAdmissionYear?.data

  const batches = Array.from(
    { length: Math.min(currentAdmissionYearResult?.numberOfChoices) },
    (_, i) => ({
      label: `Batch ${i + 1}`,
      value: i + 1
    })
  )

  const {
    loading: admissionYearLoading,
    error: admissionYearError,
    data: admissionYearData
  } = useQuery(GET_ADMISSION_YEARS, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination
    },
    fetchPolicy: "cache-first"
  })

  const admissionYearResults = admissionYearData?.getAdmissionYears.data?.items // extract admission year

  const programAdmission = data?.getProgramCapacities?.data?.items // extract program capabilities

  const applicationType = [
    { label: "Direct", value: "D" },
    { label: "Equivalent", value: "E" }
  ]

  const reportType = [
    { label: "ALL", value: "ALL" },
    { label: "SELECTED", value: "SELECTED" },
    { label: "REJECTED", value: "REJECTED" }
  ]


  const reportOptions = [
    { label: "APPLICANT RANKING LIST", value: "RANKING" },
    { label: "APPLICANT COUNT BY PROGRAM", value: "COUNT" }
  ]

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      programCapacityUid: null,
      batchNumber: "",
      applicationType: "",
      reportType: "",
      admissionWindowUid: ""
    },
    validationSchema: Yup.object().shape({
      admissionWindowUid: Yup.string().required("Admission year is required"),
      batchNumber: Yup.string().required("Batch number is required"),
      applicationType: Yup.string().required("Application type is required"),
      reportType: Yup.string().required("Report type is required")
    }),
    onSubmit: (values, { setSubmitting }) => {
      if (values.programCapacityUid?.uid) {
        refetchApplicantRanking({
          variables: {
            inputData: {
              programCapacityUid: values.programCapacityUid?.uid,
              batchNumber: `${values.batchNumber}`,
              applicationType: values.applicationType,
              reportType: values.reportType,
              admissionWindowUid: values.admissionWindowUid
            }
          }
        })
          .then((response) => {
            if (response?.data?.getApplicantsRankingReport?.code === 8000) {

              showToast(response?.data?.getApplicantsRankingReport?.message, "success")

              const results = response?.data?.getApplicantsRankingReport?.data

              const title = values.reportType + " Applicant list for " + values?.programCapacityUid?.programName

              const programName = values?.programCapacityUid?.programName

              console.log(title)
              const pdfData = results?.items?.map((item, index) => {
                const firstName = item?.firstName
                const middleName = item?.middleName
                const lastName = item?.lastName
                const gender = item?.gender === "Male" ? "M" : item?.gender === "Female" && "F"
                const selectionStatus = item?.selectionStatus === true ? "Selected" : "Rejected"
                const formFourIndexNumber = item?.formFourIndexNumber
                const formSixIndexNumber = item?.formSixIndexNumber
                const applicantCategory = item?.applicantCategory
                const points = item?.points
                const rejectionReasons = item?.rejectionReasons
                const dateOfBirth = item?.dateOfBirth
                const cseeLevelResults = item?.cseeLevelResults
                let acseeLevelResults = item?.acseeLevelResults
                if (values.applicationType === "E") {
                  acseeLevelResults = item?.programmeName
                }

                return [
                  (index + 1).toString(),
                  firstName,
                  middleName,
                  lastName,
                  gender,
                  formFourIndexNumber,
                  points,
                  cseeLevelResults,
                  acseeLevelResults,
                  applicantCategory,
                  selectionStatus,
                  rejectionReasons
                ]
              })
              ApplicationRankingPDF(pdfData, title, programName, values.applicationType)
              setSubmitting(false)
            } else {
              setSubmitting(false)
              console.error("Base64 data not found in the response")
            }
          })
          .catch((error) => {
            setSubmitting(false)
            // Handle errors
            console.error("Mutation error:", error)
          })
      } else {
        showToast("Please choose program capacity to proceed!", "warning")
        setSubmitting(false)
      }
    }

  })

  const
    { loading: searchLoading, error: searchError, data: searchData }
      = useQuery(GET_APPLICATION_WINDOW, {
      client: onlineApplicationGraphQLClient,
      variables: {
        pagination
      }
    })
  const applicationWindows = searchData?.getAdmissionWindows.data?.items


  return (
    <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Application Reports">
      <div>

        <Row>
          <Col>
            <Card>

              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <Label>CHOOSE REPORT TYPE</Label>
                      <Select
                        // value={selectedGroup}
                        name="admissionWindowUid"
                        onChange={(e) => {
                          setReportTypeSelected(e.value)
                        }}
                        options={reportOptions}
                        className="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>
                {/* <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student List Reports"> */}
                {
                  reportTypeSelected === "RANKING" ? (
                    <Row>
                      <Col lg={12}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <Row>
                            <Col lg={3}>
                              <div className="mb-3">
                                <Label>Application Window</Label>
                                <Select
                                  // value={selectedGroup}
                                  name="admissionWindowUid"
                                  onChange={(e) => {
                                    validation.setFieldValue("admissionWindowUid", e.value)
                                    setProgramCapacity(validation?.values?.programCapacityUid?.programName)
                                  }}
                                  options={applicationWindows?.map(year => ({
                                    value: year.uid,
                                    label: year.description
                                  }))}
                                  className="select2-selection"
                                  isLoading={searchLoading}
                                />
                                <Input
                                  name="admissionYearUid"
                                  type="hidden"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.admissionWindowUid || ""}
                                  invalid={
                                    !!(validation.touched.admissionWindowUid && validation.errors.admissionWindowUid)
                                  }
                                />
                                {validation.touched.admissionWindowUid && validation.errors.admissionWindowUid ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.admissionWindowUid}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={5} CardBody>
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Label>Program Capacity</Label>
                                <Select
                                  // value={selectedMulti2}
                                  styles={{
                                    menu: base => ({
                                      ...base,
                                      position: "absolute",
                                      zIndex: 9999
                                    })
                                  }}
                                  onChange={e => {
                                    validation.setFieldValue("programCapacityUid", e.value)
                                  }}
                                  options={programAdmission?.map(pro => ({
                                    value: pro,
                                    label: pro.programName + " (" + pro.programCategoryCode + ")"
                                  }))}
                                  className="select2-selection"
                                  isLoading={loading}
                                />
                                <Input
                                  name="programCapacityUid"
                                  placeholder=""
                                  type="hidden"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.programCapacityUid || ""}
                                  invalid={
                                    !!(
                                      validation.touched.programCapacityUid &&
                                      validation.errors.programCapacityUid
                                    )
                                  }
                                />
                                {validation.touched.programCapacityUid &&
                                validation.errors.programCapacityUid ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.programCapacityUid}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={2}>

                              <div className="mb-3">
                                <Label>Application Level</Label>
                                <Select
                                  onChange={(e) => {
                                    validation.setFieldValue("applicationType", e.value)
                                  }}
                                  options={applicationType}
                                  className="select2-selection"
                                />
                                <Input
                                  name="applicationType"
                                  type="hidden"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.applicationType || ""}
                                  invalid={
                                    !!(validation.touched.applicationType && validation.errors.applicationType)
                                  }
                                />
                                {validation.touched.applicationType && validation.errors.applicationType ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.applicationType}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col lg={2}>
                              <div className="mb-3">
                                <Label>Batch number</Label>
                                <Select
                                  // value={selectedGroup}
                                  name="batchNumber"
                                  onChange={(e) => {
                                    validation.setFieldValue("batchNumber", e.value)

                                  }}
                                  // onInputChange={e => {
                                  //     search(e,'status')
                                  // }}
                                  options={batches}
                                  className="select2-selection"
                                  isLoading={loadingAdmissionYear}
                                />
                                <Input
                                  name="admissionYearUid"
                                  type="hidden"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.batchNumber || ""}
                                  invalid={
                                    !!(validation.touched.batchNumber && validation.errors.batchNumber)
                                  }
                                />
                                {validation.touched.batchNumber && validation.errors.batchNumber ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.batchNumber}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={2}>

                              <div className="mb-3">
                                <Label>Report Type</Label>
                                <Select
                                  onChange={(e) => {
                                    validation.setFieldValue("reportType", e.value)
                                  }}
                                  options={reportType}
                                  className="select2-selection"
                                />
                                <Input
                                  name="reportType"
                                  type="hidden"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.reportType || ""}
                                  invalid={
                                    !!(validation.touched.reportType && validation.errors.reportType)
                                  }
                                />
                                {validation.touched.reportType && validation.errors.reportType ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.reportType}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <div className="float-end">
                            <SubmitButtonHelper type={"primary"} name={"Submit"} formik={validation} />
                          </div>
                        </Form>
                      </Col>

                    </Row>
                  ) : reportTypeSelected === "COUNT" && (
                    <Row>
                      <Col lg={12}>
                        <button disabled={loadingApplicantByProgram} onClick={() => {
                          fetchApplicantCountByProgram()
                        }} className="btn btn-sm btn-success">
                          {
                            loadingApplicantByProgram ? (
                              <>Please wait...</>
                            ) : (
                              <>Click here to print general report</>
                            )
                          }
                        </button>
                      </Col>

                    </Row>
                  )
                }
              </CardBody>

            </Card>
          </Col>
        </Row>
      </div>
    </LayoutHelper>
  )
}

export default ApplicationListReport
