import { gql } from "apollo-boost";

const GET_PROGRAM_SEMISTERS = gql `
query GetProgramSemester($pagination: PaginationInput!, $programUid: String!, $academicYearUid: String) {
    getProgramSemesters(pagination: $pagination, programUid: $programUid, academicYearUid: $academicYearUid){
        status
        code
        message
        data{
          items{
            uid
            studyYear
            coreCredits
            electiveCredits
            program{
              name
              uid
              code
            }
            academicYear{
              name
              startDate
              endDate
              status
              uid
            }
            semester
            
          }
          totalCount
        }
      }
  }
`

const GET_PROGRAM_SEMESTER_YEAR = gql `
query GetProgramSemester($pagination: PaginationInput!, $programUid: String!, $academicYearUid: String) {
    getProgramSemesters(pagination: $pagination, programUid: $programUid, academicYearUid: $academicYearUid){
        status
        code
        message
        data{
          items{
            uid
            studyYear
            academicYear{
              name
              startDate
              endDate
              status
              uid
            }
            semester
          
          }
        }
      }
  }
`


const  GET_STAFFS= gql`
query getStaffs($pagination: PaginationInput!) {
  getStaffs(pagination: $pagination){
    status
    code
    message
    data{
      totalCount,
      items{
        pfNumber
        uid
        checkNumber
        staffTerm{
          uid
          name
          code
        }
        dateEmployed
        user{
          firstName
          lastName
          middleName
          department{
            name
            code
            description
            unit{
              uid
              name
              code
              description
              campus{
                uid
                name
                code
                description
              }
            }
          }
        }
      }
      
    }
    }
}
`
const  GET_STAFF_COURSE_ALLOCATION_BY_PROGRAM_COURSE_UID= gql`
query getStaffCourseAllocationByProgramSemesterUid ($programCourserUid : String!) {
  getCourseAllocationByProgramCourseUid (programCourserUid:$programCourserUid) {
    status
    code
    message
    data {
      totalCount
      items {
        uid
        staffUid
        programCourse {
          uid
          course {
            uid
            code
            name
          }
          courseCategory {
            uid
            name
          }
          programSemester {
            uid,
            program {
              uid
              name
              code
            }
            academicYear {
              uid
              name
            }
            studyYear
            semester
          }
          credit
        }
      }
    }
  }
}
`
const GET_COURSE_ALLOCATION = gql`
query GetProgramCoursesAssessment($pagination: PaginationInput!) {
  getCourseAllocations (pagination: $pagination){
    status
    code
    message
    data{
      items {
        uid
        staffUid
        programCourse {
          uid
          course {
            uid
            code
            name
          }
        }
      }
      totalCount
    }
  }
}
`;

export {GET_PROGRAM_SEMISTERS,GET_STAFFS,GET_STAFF_COURSE_ALLOCATION_BY_PROGRAM_COURSE_UID, GET_COURSE_ALLOCATION, GET_PROGRAM_SEMESTER_YEAR}