import { gql } from '@apollo/client';

const CREATE_EXAM_POSTPONEMENT = gql`
  mutation RegisterExamPostponement($inputs: ExamPostponementInput!) {
    registerExamPostponement(inputs: $inputs) {
        status
        code
        message
         data{
            uid
         }
    }
  }
`

export   {CREATE_EXAM_POSTPONEMENT}
