//REGISTE
import { gql } from '@apollo/client';


const GET_KINSHIP = gql`
  query GetKinships($pagination: PaginationInput!) {
    getKinships(pagination: $pagination){
      status
      code
      message
      data{
        items{
        id
        uid
        name
      }
      totalCount
      }
    }
  }
`;

export { GET_KINSHIP }