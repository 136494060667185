import React, { useContext, useRef, useState } from "react"
import { Modal, Form, Input, FormFeedback } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import {
  createUpdateFunction,
  paginationHelper,
} from "helpers/Functions/GraphqlUpdateFunction"
import { CREATE_ACTIVATION } from "../Mutations/UserMutations"
import { GET_DEACTIVATE_REASONS } from "Modules/Lookups/DeactivateReasons/Queries/DeactivateQueries"
import { GET_STAFF } from "../Queries/UserQuery"
import { StaffContext } from "./UsersList"
import Select from "react-select"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"

const ActivateModal = () => {
  const { activateUser, setActivateUser } =
    useContext(StaffContext)
  const [Activate] = useMutation(CREATE_ACTIVATION)
  const [modalOpen, setModalOpen] = useState(false)
  const formRef = useRef()

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm()
    }
  }

  const query = GET_DEACTIVATE_REASONS
  const variables = { pagination: paginationHelper }
  const registerData = "data"

  const updateFunction = createUpdateFunction(query, variables, registerData)

  const { loading, error, data } = useQuery(GET_DEACTIVATE_REASONS, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })
 

  if (error) return showToast("Failed to fetch Reasons", "error")
  const reasons = data?.getDeactivationReasons?.data?.items;

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      reason: "",
    },

    validationSchema: Yup.object().shape({
      reason: Yup.string().required("This field is required"),
    }),

    onSubmit: values => {
      Activate({
        client: uaaGraphQLClient,
        variables: {
          inputs: { ...values, userUid: activateUser?.user?.uid },
        },
        refetchQueries: [
          {
            query: GET_STAFF,
            variables: {
              pagination: paginationHelper,
            },
          },
        ],
        onCompleted: ({ activateDeactivateUser }) => {
          //updateFunction(client.cache, { data: { activateDeactivateUser } });
          if (activateDeactivateUser.code === 8000) {
            validation.resetForm()
            setModalOpen(false)
            setActivateUser(false) // Close the modal
          } else {
            setModalOpen(true)
            setActivateUser(true) // Close the modal
          }
          showToast(
            activateDeactivateUser.message,
            activateDeactivateUser.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="text-sm-end mt-3">
     
      <Modal
        isOpen={!!activateUser}
        backdrop={"static"}
        id="staticBackdrop"

      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {!!activateUser?.user?.isActive
              ? "Deactivate: "+activateUser?.user?.firstName+" "+activateUser?.user?.lastName
              : "Activate: "+activateUser?.user?.firstName+" "+activateUser?.user?.lastName}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setModalOpen(false)
              setActivateUser(false)
            }}
            aria-label="Close"
          ></button>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return null
          }}
        >
          <div className="modal-body">
          <div className="mb-3">
              <label>Select Reason</label>
              <Select
                onChange={e => {
                    console.log("Value selected", e)
                  validation.setFieldValue("reason", e.label)
                }}
                options={reasons?.map(unit => ({
                  value: unit.uid,
                  label: unit.name,
                }))}
                className="select2-selection"
                styles={{
                  menu: base => ({
                    ...base,
                    position: "absolute",
                    zIndex: 9999
                  })
                }}
              />
              <Input
                name="reason"
                placeholder="Select Reason"
                type="hidden"
                rows={2}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.uid || ""}
                invalid={
                  !!(validation.touched.reason && validation.errors.reason)
                }
              />
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                validation.resetForm()
                setModalOpen(false)
                setActivateUser(false)
                resetForm() // Reset the form
              }}
            >
              Close
            </button>
            <SubmitButtonHelper type="primary" name={activateUser?.user?.isActive ? "Deactivate" : "Activate"} formik={validation} />
            {/* <Button type="submit" className="btn btn-btn" color="primary">
              Submit
            </Button> */}
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default ActivateModal
