import { gql } from "@apollo/client"

const CREATE_WARD = gql`
  mutation RegisterWard($inputs: [WardDtoInput!]!) {
    registerWards(inputs: $inputs) {
      status
      code
      message
      data {
        items {
          name
          uid
          code
          district {
            name
            region {
              name
            }
          }
        }
      }
    }
  }
`

const DELETE_WARD = gql`
  mutation RemoveWard($uid: String!) {
    removeWard(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_WARD, DELETE_WARD }
