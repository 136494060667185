import { gql } from "@apollo/client"

const CREATE_DEACTIVATE_REASONS = gql`
  mutation RegisterDeactivationReason($inputs: [DeactivationReasonDtoInput!]!) {
    registerDeactivationReason(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_DEACTIVATE_REASONS = gql`
  mutation RemoveDeactivationReason($uid: String!) {
    removeDeactivationReason(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_DEACTIVATE_REASONS, DELETE_DEACTIVATE_REASONS }
