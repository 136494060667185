import React, { useContext } from "react"
import {
  Form,
  Input,
  FormFeedback,
  Label,
  Row,
  Col,
  InputGroup
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useMutation, useQuery } from "@apollo/client"
import showToast from "helpers/ToastHelper"
import Select from "react-select"
import { paginationHelper } from "helpers/Functions/GraphqlUpdateFunction"
import { CREATE_STAFF_ACADEMIC } from "../Mutations/UserMutations"
import { uaaGraphQLClient } from "Modules/Authentication/ApolloClient"
import { StaffProfileContext } from "./StaffProfile"
import { GET_STAFF_CATEGORIES } from "Modules/StaffCategories/Queries/StaffCategoryQueries"
import { Link } from "react-router-dom"
import { GET_DESIGNATION } from "Modules/Lookups/Designations/Queries/DesignationQueries"
import SubmitButtonHelper from "helpers/SubmitButtonHelper"
import { GET_STAFF_TERMS } from "Modules/StaffTerms/Queries/StaffTermsQueries"

const EmploymentDetail = () => {
  const { updateStaffData, setUpdateStaffData } =
    useContext(StaffProfileContext)

  const { toggleTab, handleSubmit } = useContext(StaffProfileContext)

  const [staff] = useMutation(CREATE_STAFF_ACADEMIC, {
    client: uaaGraphQLClient,
  })

  const handlePrevious = () => {
    toggleTab(1) // Move to the previous tab
  }

  const {
    loading: designationLoading,
    error: designationError,
    data: designationData,
  } = useQuery(GET_DESIGNATION, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const {
    loading: staffCategoryLoading,
    error: staffCategoryError,
    data: staffCategoryData,
  } = useQuery(GET_STAFF_CATEGORIES, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  const {
    loading: staffTermLoading,
    error: staffTermError,
    data: staffTermData,
  } = useQuery(GET_STAFF_TERMS, {
    client: uaaGraphQLClient,
    variables: {
      pagination: paginationHelper,
    },
  })

  if (designationError) return showToast("Failed to fetch designation", "error")
  const designations = designationData?.getDesignations?.data?.items

  if (staffCategoryError)
    return showToast("Failed to fetch staff category", "error")
  const staffCategories = staffCategoryData?.getStaffCategories?.data?.items

  if (staffTermError) return showToast("Failed to fetch staff terms", "error")
  const terms = staffTermData?.getStaffTerms?.data?.items

  const changeDOP = e => {
    const d = e[0]
    validation.setFieldValue(
      "dateEmployed",
      d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate()
    )
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      staffCategoryUid: updateStaffData
        ? updateStaffData?.staffCategoryUid
        : "",
      designationUid: updateStaffData ? updateStaffData?.staff?.designationUid : "",
      checkNumber: updateStaffData ? updateStaffData?.staff?.checkNumber : "",
      staffTermUid: updateStaffData ? updateStaffData?.staff?.staffTermUid : "",
      dateEmployed: updateStaffData ? updateStaffData?.staff?.dateEmployed : "",
    },

    validationSchema: Yup.object().shape({
      staffCategoryUid: Yup.string().required("Field is required"),
      dateEmployed: Yup.string().required("Date is required"),
      checkNumber: Yup.string().required("Check Number is required"),
      designationUid: Yup.string().required("Designation is required"),
      staffTermUid: Yup.string().required("Staff term is required"),
    }),

    onSubmit: values => {
      staff({
        client: uaaGraphQLClient,
        variables: {
          input: { ...values, userUid: updateStaffData?.uid },
        },
        onCompleted: ({ updateStaffProfile }) => {
          //updateFunction(client.cache, { data: { updateStaffProfiles } });
          if (updateStaffProfile.code === 8000) {
            toggleTab(3)
            console.log("Data updated successsfully")
          } else {
            console.log("Fsailed to update data")
          }
          showToast(
            updateStaffProfile.message,
            updateStaffProfile.code === 8000 ? "success" : "error"
          )
        },
        onError: error => {
          // Handle errors
          console.error("Mutation error:", error)
        },
      })
    },
  })
  return (
    <div className="mt-3">
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return null
        }}
      >
        <div className="modal-body">
          <Row>
            <Col md="6" lg="6">
              <div className="mb-3">
                <label htmlFor="checkNumber">Check Number:</label>
                <Input
                  type="text"
                  name="checkNumber"
                  className="form-control"
                  id="checkNumber"
                  placeholder="Enter Check Number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.checkNumber ||
                    updateStaffData?.checkNumber ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.checkNumber &&
                      validation.errors.checkNumber
                    )
                  }
                />
                {validation.touched.checkNumber &&
                validation.errors.checkNumber ? (
                  <FormFeedback type="invalid">
                    {validation.errors.checkNumber}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg="6" md="6">
              <div className="mb-3">
                <label>Select Designation</label>
                <Select
                  onChange={e => {
                    validation.setFieldValue("designationUid", e.value)
                  }}
                  options={designations?.map(d => ({
                    value: d?.uid,
                    label: d?.name,
                  }))}
                  className="select2-selection"
                  defaultValue={updateStaffData?.designation?.uid}
                  defaultInputValue={updateStaffData?.designation?.name}
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999,
                    }),
                  }}
                />
                <Input
                  name="designationUid"
                  placeholder="Select designationUid"
                  type="hidden"
                  rows={2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.designationUid ||
                    updateStaffData?.designationUid ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.designationUid &&
                      validation.errors.designationUid
                    )
                  }
                />
                {validation.touched.designationUid &&
                validation.errors.designationUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.designationUid}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

          </Row>

          <Row>
            <Col lg="6" md="6">
              <div className="mb-3">
                <Label>Date Of Employment</Label>
                <InputGroup>
                  <Flatpickr
                    className="form-control d-block"
                    // name="dob"
                    placeholder="dd M,yyyy"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      dateFormat: "Y-m-d",
                      maxDate: "today", 
                    }}
                    onChange={changeDOP}
                  />
                </InputGroup>
                <Input
                  name="dateEmployed"
                  placeholder="Enter date employed"
                  type="hidden"
                  rows={3}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address || ""}
                  invalid={
                    !!(
                      validation.touched.dateEmployed &&
                      validation.errors.dateEmployed
                    )
                  }
                />
                {validation.touched.dateEmployed &&
                validation.errors.dateEmployed ? (
                  <FormFeedback type="invalid">
                    {validation.errors.dateEmployed}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col lg="6" md="6">
              <div className="mb-3">
                <label>Select Staff Category</label>
                <Select
                  onChange={e => {
                    validation.setFieldValue("staffCategoryUid", e.value)
                  }}
                  options={staffCategories?.map(e => ({
                    value: e?.uid,
                    label: e?.name,
                  }))}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999,
                    }),
                  }}
                />
                <Input
                  name="staffCategoryUid"
                  placeholder="Select staff category"
                  type="hidden"
                  rows={2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values.staffCategoryUid ||
                    updateStaffData?.staffCategoryUid ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.staffCategoryUid &&
                      validation.errors.staffCategoryUid
                    )
                  }
                />
                {validation.touched.staffCategoryUid &&
                validation.errors.staffCategoryUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.staffCategoryUid}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" md="6">
              <div className="mb-3">
                <label>Select Term of Service</label>
                <Select
                  onChange={e => {
                    validation.setFieldValue("staffTermUid", e.value)
                  }}
                  options={terms?.map(t => ({
                    value: t?.uid,
                    label: t?.name,
                  }))}
                  className="select2-selection"
                  styles={{
                    menu: base => ({
                      ...base,
                      position: "absolute",
                      zIndex: 9999,
                    }),
                  }}
                />
                <Input
                  name="staffTermUidUid"
                  placeholder="Select terms of service"
                  type="hidden"
                  rows={2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={
                    validation.values?.uid ||
                    updateStaffData?.termsOfService?.uid ||
                    ""
                  }
                  invalid={
                    !!(
                      validation.touched.staffTermUidUid &&
                      validation.errors.staffTermUidUid
                    )
                  }
                />
                {validation.touched.staffTermUidUid &&
                validation.errors.staffTermUidUid ? (
                  <FormFeedback type="invalid">
                    {validation.errors.staffTermUidUid}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
        <div className="wizard">
          <div className="actions">
            <ul>
              <li className="previous">
                <Link to="#" onClick={handlePrevious}>
                  Previous
                </Link>
              </li>
              <li className="next">
                <SubmitButtonHelper
                  type="primary"
                  name="Submit & Continue.."
                  formik={validation}
                />
              </li>
            </ul>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default EmploymentDetail
