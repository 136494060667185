import { gql } from "@apollo/client"

const CREATE_KINSHIP = gql`
  mutation RegisterKinships($inputs: [KinshipDtoInput!]!) {
    registerKinships(inputs: $inputs) {
      status
      code
      message
      data {
        totalCount
        items{
          id
          uid
          name
       }
      }
    }
  }
`

const DELETE_KINSHIP = gql`
  mutation RemoveKinship($uid: String!) {
    removeKinship(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_KINSHIP, DELETE_KINSHIP }
