
import React from "react";
import { Route, Routes } from "react-router";
import SignaturePad from "../Views/SignaturePad";
import Pages404 from "Modules/Utility/pages-404";

const SignaturePadRoutes = (props) => {

    return (
        <Routes>
            <Route path="/" element={<SignaturePad />} />
            <Route path="*" element={<Pages404/>} />
        </Routes>
    );

}

export default SignaturePadRoutes;