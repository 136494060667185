import { gql } from "@apollo/client"

const CREATE_ACADEMICYEARS = gql`
mutation RegisterAcademicYear($inputs: [AcademicYearInput!]!) {
  registerAcademicYear(inputs: $inputs) {
    status
    code
    message
    data {
      totalCount
      items {
        uid
        name
        status
        startDate
        endDate
     }
    }
  }
}
`

const DELETE_ACADEMICYEARS = gql`
  mutation RemoveAcademicYear($uid: String!) {
    removeAcademicYear(uid: $uid) {
      message
      code
      status
    }
  }
`

export { CREATE_ACADEMICYEARS, DELETE_ACADEMICYEARS }
