import React, { createContext, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  CardSubtitle
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from "react-select"
import { useLazyQuery, useQuery } from "@apollo/client"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import DropDownActionHelper from "helpers/DropDownActionHelper"
import SubmitButtonHelper from "../../../../helpers/SubmitButtonHelper"
import LayoutHelper from "../../../../helpers/LayoutHelper"
import StudentModal from "../../../Reports/Students/Views/StudentModal"
import { GET_ACADEMICYEARS } from "../../../AcademicYears/Queries/AcademicYearsQueries"
import {
  onlineApplicationGraphQLClient,
  registrationGraphQLClient,
  uaaGraphQLClient
} from "../../../Authentication/ApolloClient"
import { GET_ALL_PROGRAMS } from "../../../Programs/Queries/ProgramQuaries"
import { GET_STATUS, GET_STATUS_NAME } from "../../../Lookups/Status/Queries/StatusQueries"
import {
  GET_STUDENT_LIST_REPORT,
  GET_STUDENT_LIST_REPORT_XLS
} from "../../../Reports/Students/Queries/StudentReportQueries"
import PlaceHolderLoader from "../../../../helpers/PlaceHolderLoader"
import PaginationHelper from "../../../../helpers/PaginationHelper"
import { GenerateTablePDF } from "../../../../helpers/PdfTableHelper"
import {
  paginationHelper
} from "helpers/Functions/GraphqlUpdateFunction"
import { limit } from "../../../../helpers/UrlHelper"
import { GET_APPLICATION_WINDOW } from "../../../ApplicationWindow/Queries/ApplicationWindowQuery"

const breadcrumbItem = [
  {
    title: "Application Ranking",
    path: "",
    isActive: true
  }
]

const truncateTitle = (title) => {
  const words = title.split(" ")

  if (words.length > 4) {
    const truncatedTitle = words.slice(0, 4).join(" ") + "..."
    return truncatedTitle
  }

  return title
}


export const StudentContext = createContext() //Create data to transfer to child modal

const ApplicationRanking = () => {

  const [updateStudentData, setUpdateStudentData] = useState(null) //Get data for editing


  const pagination = { offset: 0, limit: limit, search: null }

  const [pageSize, setPageSize] = useState(20),
    [allowClick, setAllowClick] = useState(false),
    [currentPage, setCurrentPage] = useState(0)

  const handleClick = data => {
    if (allowClick) {
      searchStudent({
        variables: {
          inputData: {
            batchNumber: validation.values.batchNumber,
            programUid: validation.values.programUid,
            offset: 100
          }
        }
      })
    }

    setCurrentPage(data.selected)
  }

  const { loading: programLoading, error: programError, data: programData } = useQuery(GET_ALL_PROGRAMS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: pagination
    }
  })


  const [
    searchPrograms,
    { loading: programSearchLoading, error: searchError, data: searchProgram }
  ] = useLazyQuery(GET_ALL_PROGRAMS, { client: registrationGraphQLClient })

  const {
    loading: academicYearLoading, error: academicYearError,
    data: academicYear,
    refetch: acRef
  } = useQuery(GET_ACADEMICYEARS, {
    client: registrationGraphQLClient,
    variables: {
      pagination: paginationHelper
    }
  })


  const { loading, error, data: batchesNumbers, refetch } = useQuery(GET_APPLICATION_WINDOW, {
    client: onlineApplicationGraphQLClient,
    variables: {
      pagination,
    },
    fetchPolicy: "cache-first",
  })
  const results = batchesNumbers?.getAdmissionWindows?.data?.items

  const [
    searchAcademicYear,
    { loading: yearSearchLoading, error: yearSearchError, data: searchYear }
  ] = useLazyQuery(GET_ACADEMICYEARS, { client: registrationGraphQLClient })

  let academicYears = academicYear?.getAcademicYears?.data?.items
  if (searchYear?.getAcademicYears?.data)
    academicYears = searchYear?.getAcademicYears?.data?.items

  let programs = programData?.getAllPrograms?.data?.items
  if (searchProgram?.getAllPrograms?.data)
    programs = searchProgram?.getAllPrograms?.data?.items

  const {
    loading: statusLoading, error: statusError,
    data: statusData,
    refetch: statusRef
  } = useQuery(GET_STATUS_NAME, {
    client: uaaGraphQLClient
  })
  const [
    searchStatus,
    { loading: statusSearchLoading, error: statusSearchError, data: searchStatusData }
  ] = useLazyQuery(GET_STATUS, { client: uaaGraphQLClient })

  const [
    searchStudent,
    { loading: searchStudentLoading, error: searchStudentError, data: searchStudentData }
  ] = useLazyQuery(GET_STUDENT_LIST_REPORT, { client: uaaGraphQLClient, fetchPolicy: "no-cache" })


  const [
    searchStudentXls,
    { loading: downloadStudentXlsLoading, error: downloadStudentXlsError, data: downloadStudentXlsData }
  ] = useLazyQuery(GET_STUDENT_LIST_REPORT_XLS, { client: uaaGraphQLClient, fetchPolicy: "no-cache" })


  let students = searchStudentData?.getRegisteredStudents?.data?.items
  let totalCount = students ? searchStudentData?.getRegisteredStudents?.data?.totalCount : 0

  const [
    downloadStudent,
    { loading: downloadStudentLoading, error: downloadStudentError, data: downloadStudentData }
  ] = useLazyQuery(GET_STUDENT_LIST_REPORT, { client: uaaGraphQLClient, fetchPolicy: "no-cache" })


  const search = (value, type) => {
    let data = {
      search: value
    }
    if (value.length >= 2) {
      if (type === "academicYear") {
        searchAcademicYear({
          variables: { pagination: { ...pagination, ...data } }
        })
      } else if (type === "program") {
        searchPrograms({
          variables: { pagination: { ...pagination, ...data } }
        })
      } else if (type === "status") {
        searchStatus({
          variables: { pagination: { ...pagination, ...data } }
        })
      }
    }
  }

  function base64toBlob(base64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(base64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  const downloadList = () => {
    let title = ""
    if (validation.values.programUid) {
      const program = programs.find(i => i.uid === validation.values.programUid)
      title += program.name + " (" + program.code + ") "
    }
    if (validation.values.batchNumber) title += " Year " + validation.values.batchNumber
    title += " Students Report"
    downloadStudent({
      variables: {
        inputData: {
          batchNumber: validation.values.batchNumber,
          programUid: validation.values.programUid,
          offset: 0,
          limit: 10000000
        }
      }
    })
      .then((response) => {
        // Handle the response
        // console.log(response)
        if (response.data.getRegisteredStudents?.status === true) {
          const std = response.data.getRegisteredStudents.data.items
          const columns = ["S/No", "Student Name", "Registration number", "Sex", "Phone Number", "Form Four Index Number", "Account Number"]
          let studentData = []
          if (std) {
            for (let f = 0; f < std?.length; f++) {
              let s = std[f]

              studentData.push([
                f + 1, s.firstName + " " + s.lastName, s.registrationNumber, s.gender, s.phoneNumber, s.formFourIndexNumber, s.accountNumber
              ])
            }
          }
          // console.log(studentData)
          GenerateTablePDF(columns, studentData, title, [], "l")
        }

      })
      .catch((error) => {
        // Handle errors
        console.error("Mutation error:", error)
      })
  }

  const downloadListXls = () => {

    searchStudentXls({
      variables: {
        inputData: {
          batchNumber: validation.values.batchNumber,
          programUid: validation.values.programUid,
          offset: 0,
          limit: 10000000
        }
      }
    })
      .then((response) => {
        if (response.data.getRegisteredStudentsXls?.status === true) {
          const studentsXls = response.data.getRegisteredStudentsXls.data.base64Data

          if (studentsXls) {
            const blob = base64toBlob(studentsXls)
            const url = URL.createObjectURL(blob)
            const link = document.createElement("a")
            link.href = url
            // link.setAttribute("download", "template.xlsx")
            link.setAttribute("download", "Student-List.xlsx")
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(url)
          } else {
            console.error("Base64 data not found in the response")
          }
        }
      }).catch((error) => {
      // Handle errors
      console.error("Mutation error:", error)
    })
    // GenerateTablePDF(columns,studentData,'Student Reports')
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      programUid: "",
      batchNumber: "",
      academicYearUid: ""
    },
    validationSchema: Yup.object().shape({
      programUid: Yup.string().required("Program is required"),
      academicYearUid: Yup.string().required("Academic year is required"),
      batchNumber: Yup.string().required("Batch number is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      console.log(values)
      setSubmitting(false)
      // ToastHelper("Waiting for Backend", "info")
      // searchStudent({
      //   variables: {
      //     inputData: {
      //       batchNumber: values.batchNumber,
      //       programUid: values.programUid,
      //       offset: 0,
      //       limit: 20
      //     }
      //   }
      // })
      //   .then((response) => {
      //     // Handle the response
      //     setSubmitting(false)
      //     setAllowClick(true)
      //   })
      //   .catch((error) => {
      //     setSubmitting(false)
      //     // Handle errors
      //     console.error("Mutation error:", error)
      //   })
    }

  })

  return (

    <StudentContext.Provider
      value={{ updateStudentData, setUpdateStudentData }}
    >

      <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Application Ranking">
        <div>

          <Row>
            <Col>
              <Card>

                <CardBody>
                  <CardSubtitle className="mb-1">
                    <Row>
                      <Col className="col-lg-12 col-sm-12 col-md-12 pull-right">
                        <StudentModal />
                      </Col>
                    </Row>
                  </CardSubtitle>
                  {/* <LayoutHelper breadcrumbItem={breadcrumbItem} pageTitle="Student List Reports"> */}
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <Row>
                              <Col lg={3}>
                                <div className="mb-3">
                                  <Label>Academic Year</Label>
                                  <Select
                                    // value={selectedGroup}
                                    name="academic_year"
                                    onChange={(e) => {
                                      validation.setFieldValue("academicYearUid", e.value)

                                    }}
                                    onInputChange={e => {
                                      search(e, "academicYear")
                                    }}
                                    options={academicYears?.map(year => ({
                                      value: year.uid,
                                      label: year.name
                                    }))}
                                    className="select2-selection"
                                    isLoading={academicYearLoading || yearSearchLoading}
                                  />
                                  <Input
                                    name="academicYearUid"
                                    type="hidden"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.academicYearUid || ""}
                                    invalid={
                                      !!(validation.touched.academicYearUid && validation.errors.academicYearUid)
                                    }
                                  />
                                  {validation.touched.academicYearUid && validation.errors.academicYearUid ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.academicYearUid}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col lg={5} CardBody>
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Label>Program</Label>
                                  <Select
                                    // value={selectedMulti2}
                                    styles={{
                                      menu: base => ({
                                        ...base,
                                        position: "absolute",
                                        zIndex: 9999
                                      })
                                    }}
                                    onChange={e => {
                                      validation.setFieldValue("programUid", e.value)
                                    }}
                                    onInputChange={e => {
                                      search(e, "program")
                                    }}
                                    options={programs?.map(pro => ({
                                      value: pro.uid,
                                      label: pro.name + " (" + pro.code + ")"
                                    }))}
                                    className="select2-selection"
                                    isLoading={programLoading || programSearchLoading}
                                  />
                                  <Input
                                    name="programUid"
                                    placeholder=""
                                    type="hidden"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.programUid || ""}
                                    invalid={
                                      !!(
                                        validation.touched.programUid &&
                                        validation.errors.programUid
                                      )
                                    }
                                  />
                                  {validation.touched.programUid &&
                                  validation.errors.programUid ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.programUid}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col lg={4}>

                                <div className="mb-3">
                                  <Label>Batch number</Label>
                                  <Select
                                    onChange={(e) => {
                                      validation.setFieldValue("batchNumber", e.value)
                                    }}
                                    options={results?.map(batch => ({
                                      value: batch?.batchNumber,
                                      label: batch?.batchNumber
                                    }))}
                                    className="select2-selection"
                                  />
                                  <Input
                                    name="batchNumber"
                                    type="hidden"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.batchNumber || ""}
                                    invalid={
                                      !!(validation.touched.batchNumber && validation.errors.batchNumber)
                                    }
                                  />
                                  {validation.touched.batchNumber && validation.errors.batchNumber ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.batchNumber}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                            </Row>

                            <div className="float-end">
                              <SubmitButtonHelper type={"primary"} name={"Submit"} formik={validation} />
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>

                  </Row>

                  <Row>
                    <Col lg={12}>

                      <Card>
                        <CardBody>
                          {
                            students && <Button className="btn btn-success btn-sm" onClick={downloadList}
                                                disabled={downloadStudentLoading}>
                              {
                                downloadStudentLoading ?
                                  <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Please
                                    wait..</>
                                  : <><i className="fa fa-file-pdf"></i> Generate PDF</>
                              }
                            </Button>

                          }

                          {
                            students && <Button className="btn btn-success btn-sm" onClick={downloadListXls}
                                                disabled={downloadStudentXlsLoading}>
                              {
                                downloadStudentLoading ?
                                  <><i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Please
                                    wait..</>
                                  : <><i className="fa fa-file-pdf"></i> Generate Excel</>
                              }
                            </Button>

                          }

                          {searchStudentLoading ? (
                            <PlaceHolderLoader type="table" columSize={12} rows={5} />
                          ) : (
                            <div className="table-rep-plugin">
                              <div
                                className="table-responsive mb-0"
                                data-pattern="priority-columns"
                              >
                                <Table
                                  id="tech-companies-1"
                                  className="table table-striped table-bordered"
                                >
                                  <Thead>
                                    <Tr>
                                      <Th>S/No</Th>
                                      <Th data-priority="0">Full Name</Th>
                                      <Th data-priority="3">Gender</Th>
                                      <Th data-priority="4">Nationality</Th>
                                      <Th data-priority="5">Impairment</Th>
                                      <Th data-priority="6">Date of Birth</Th>
                                      <Th data-priority="7">Form Four Index No</Th>
                                      <Th data-priority="8">Other Form Four Index No</Th>
                                      <Th data-priority="9">Program Name</Th>
                                      <Th data-priority="10">Action</Th>
                                      <Th data-priority="611">Diploma name</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {students?.map((std, index) => (
                                      <Tr key={std?.uid}>
                                        <Td>{(currentPage * 20) + index + 1}</Td>
                                        <Td>{std?.registrationNumber}</Td>
                                        <Td>{`${std?.firstName} ${std?.middleName !== undefined ? std?.middleName : ""} ${std?.lastName}`}</Td>
                                        <Td>{std?.gender}</Td>
                                        <Td>{std?.phoneNumber}</Td>
                                        <Td>{std?.formFourIndexNumber}</Td>
                                        <Td>{std?.accountNumber}</Td>
                                        <Td>
                                          <DropDownActionHelper
                                            data={std}
                                            onUpdate={setUpdateStudentData}
                                          />

                                        </Td>
                                      </Tr>
                                    ))}
                                  </Tbody>
                                </Table>
                              </div>
                            </div>
                          )}

                          <PaginationHelper totalCount={totalCount} currentPage={currentPage} pageSize={pageSize}
                                            pageItemNumber={students?.length} handleClick={handleClick} />
                        </CardBody>
                      </Card>

                    </Col>
                  </Row>
                  {/* </LayoutHelper> */}

                </CardBody>

              </Card>
            </Col>
          </Row>
        </div>
      </LayoutHelper>
    </StudentContext.Provider>
  )
}

export default ApplicationRanking
